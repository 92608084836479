"use client";

import React, { useEffect } from "react";

import { useResponsive } from "@/styles";
import { useParamState } from "@/services/Routing";
import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  useChatIdFromUrl,
} from "@/services/ChatServiceNew";

import ChatsPageMobile from "./ChatsPage.mobile";
import ChatsPageDesktop from "./ChatsPage.desktop";
import { ChatsPageProvider } from "./ChatsPage.context";
import { RESPONSIVE_BREAKPOINT } from "./ChatsPage.config";

const ChatsPage: React.FC = () => {
  const { screenWidth } = useResponsive();
  const { idFromUrl: channelId } = useChatIdFromUrl();
  const [channelTypeFilter, setChannelTypeFilter] = useParamState<string>(
    CHANNEL_TYPE_FILTER_URL_KEY,
    "",
    {
      parseJson: false,
    }
  );

  useEffect(() => {
    if (!channelId && !channelTypeFilter) {
      setChannelTypeFilter(ChannelType.Contract);
    }
  }, [channelId, channelTypeFilter, setChannelTypeFilter]);

  return (
    <ChatsPageProvider>
      {screenWidth < RESPONSIVE_BREAKPOINT ? (
        <ChatsPageMobile />
      ) : (
        <ChatsPageDesktop />
      )}
    </ChatsPageProvider>
  );
};

export default ChatsPage;
