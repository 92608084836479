import { useMemo } from "react";

import {
  getProfileCompletionPercentage,
  useOnboardStripe,
  useProfileData,
} from "@/services/UserService";
import { IActionCardProps } from "@/components/misc/ActionCard";
import { SITE_PATHS } from "@/config/routing";

import useOnboardingAction from "./useOnboardingAction";

function useUserActions() {
  const onboardAction = useOnboardingAction();
  const { isReady } = useOnboardStripe();
  const { profileData } = useProfileData();

  const profileCompletionPercentage = useMemo(
    () => (profileData ? getProfileCompletionPercentage({ profileData }) : 0),
    [profileData]
  );

  const actions = useMemo(() => {
    const actions: IActionCardProps[] = [];

    if (profileCompletionPercentage < 100) {
      actions.push({
        icon: "profile_star.svg",
        content:
          "Enhance your credibility and impress potential clients by completing your profile today!",
        color: "#F1F5F9",
        ctaProps: {
          children: "Complete profile",
          link: SITE_PATHS.EDIT_PROFILE_PAGE,
        },
      });
    }

    if (onboardAction) {
      actions.push(onboardAction);
    }

    return actions;
  }, [profileCompletionPercentage, onboardAction]);

  return {
    isLoading: !isReady,
    actions,
  };
}

export default useUserActions;
