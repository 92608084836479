"use client";

import styled from "@emotion/styled";

import RadioCardGroup from "@/components/input/RadioCardGroup";
import { screenLargerThan } from "@/styles";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  padding: 1rem;
  height: 100%;
`;

export const StyledPageWithHeaderLayout = styled(PageWithHeaderLayout)`
  ${screenLargerThan.tablet} {
    height: 40rem;
    max-height: calc(100vh - 4.5rem);
  }

  main > div {
    overflow: hidden;
  }
`;

export const StyledRadioCardGroup = styled(RadioCardGroup)`
  gap: 0.75rem;
  flex: 1;
  overflow: auto;
`;
