import React, { useCallback, useMemo } from "react";
import { useCalendar, useLocale } from "react-aria";
import { useCalendarState } from "react-stately";
import { createCalendar, CalendarDate } from "@internationalized/date";

import {
  StyledCalendarSection,
  StyledContainer,
  StyledHeader,
  StyledTitle,
} from "../Calendar.styles";
import { CalendarProps, SingleDateCalendarProps } from "../Calendar.types";
import { fromCalendarDate, toCalendarDate } from "../Calendar.utils";
import CalendarGrid from "./CalendarGrid";
import Navigation from "./Navigation/Navigation";

const SigleDateCalendar: React.FC<CalendarProps> = (props) => {
  const { type, className } = props;
  const { value: valueFromProps, onChange: onChangeFromProps } =
    props as SingleDateCalendarProps;
  const { locale } = useLocale();

  const isControlled = !!onChangeFromProps;

  const value = useMemo(() => {
    if (type !== "single-date" || !valueFromProps) {
      return null;
    }

    return toCalendarDate(valueFromProps);
  }, [valueFromProps, type]);

  //--------------------------

  const handleValueChange = useCallback(
    (calendarDate: CalendarDate) => {
      if (type !== "single-date" || !onChangeFromProps) {
        return null;
      }

      const date = fromCalendarDate(calendarDate);
      onChangeFromProps(date);
    },
    [onChangeFromProps, type]
  );

  //--------------------------

  const state = useCalendarState({
    value: isControlled ? value : undefined,
    onChange: isControlled ? handleValueChange : undefined,
    locale,
    createCalendar,
    minValue: toCalendarDate(new Date()),
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(
      {
        value,
      },
      state
    );

  //--------------------------

  return (
    <StyledContainer {...calendarProps} className={className}>
      <StyledHeader className="header">
        <StyledTitle>{title}</StyledTitle>

        <Navigation
          prevButtonProps={prevButtonProps}
          nextButtonProps={nextButtonProps}
        />
      </StyledHeader>

      <StyledCalendarSection>
        <CalendarGrid state={state} type="single-date" />
      </StyledCalendarSection>
    </StyledContainer>
  );
};

export default SigleDateCalendar;
