import React, { useCallback, useState } from "react";

import Button from "@/components/input/Button";
import { useAuth } from "@/services/Authentication";
import Share from "@/services/Share";
import Icon from "@/components/misc/Icon";
import { ANIMATION_CLASSNAMES } from "@/styles";

const Auth: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const { token } = useAuth();

  const handleCopy = useCallback(() => {
    Share.copyToClipboard(token);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [token]);

  return (
    <div className="container">
      <h3>
        <b>Token</b>
      </h3>
      <p className="mt-2" style={{ textWrap: "wrap", wordBreak: "break-all" }}>
        <small className="mt-2">{token}</small>
      </p>
      <Button className="px-3 mt-3 w-100" onClick={handleCopy}>
        {copied ? (
          <>
            Copied{" "}
            <Icon
              isSrcRelative
              className={ANIMATION_CLASSNAMES.FADE_IN}
              src="tick.svg"
              size="xs"
            />
          </>
        ) : (
          "Copy"
        )}
      </Button>
    </div>
  );
};

export default Auth;
