import lodashGet from "lodash/get";
import stripe from "stripe";

import { ApiResponse, IApiService } from "@/services/Api";
import { store, contractActions } from "@/services/Store";
import { appFeatures } from "@/config/services";

import {
  ContractCompleteDetails,
  ContractCreatePayload,
  ContractId,
  ContractListFilters,
  CreatedContractDetails,
  MilestoneCompleteDetails,
  MilestoneId,
  MilestoneWork,
  PaymentResponse,
  ShareOfferViaEmailParams,
} from "./ContractsService.types";
import {
  contractModelToCompleteDetails,
  getContractPageUrl,
  getOfferPageUrl,
  isContractAnOffer,
  sanitizeContractCompleteDetails,
} from "./utils";

class ContractsService {
  _apiService: IApiService;
  _stripeInstance: stripe | null = null;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;

    this.initStripe();
  }

  //------------------

  initStripe = () => {
    this._stripeInstance = new stripe(
      "sk_test_51O1HgYDBbafyrcLM76SVWUE8LI9ig4mLAMFY1yJoO55Zq7jTxiE3lGuOzg8W3TS7gtj6idxjebNJBa3gfupWPxsF00ZHiqZVJp"
    );
  };

  //------------------

  fetchContractOverview = () => {
    return this._apiService.get<
      ApiResponse<{
        data: null;
      }>
    >(`/contract`);
  };

  createContract = async (data: ContractCreatePayload) => {
    const createApiRes = await this._apiService.post<
      ApiResponse<CreatedContractDetails>
    >("/contract", data);

    const contractId = createApiRes.data.data.id;

    const contractApiRes = await this._apiService.get<
      ApiResponse<ContractCompleteDetails>
    >(`/contract/detail/${contractId}`);
    const contractDetails = sanitizeContractCompleteDetails(
      contractApiRes.data.data
    );

    store.dispatch(
      contractActions.addContracts({
        data: [contractDetails],
      })
    );

    return createApiRes;
  };

  getContractByID = async (id: string, useAuthToken = true) => {
    const fetchContract = (id: string, anon = false) => {
      const f = anon ? this._apiService.anonGet : this._apiService.get;

      return f<ApiResponse<ContractCompleteDetails>>(
        `/contract/detail/${id}`
      ).then((res) => {
        const contractDetails = sanitizeContractCompleteDetails(res.data.data);

        store.dispatch(
          contractActions.addContracts({
            data: [contractDetails],
            replaceData: true,
          })
        );

        return res;
      });
    };

    const authToken = this._apiService.getAuthToken();
    if (useAuthToken && authToken) {
      return fetchContract(id);
    }

    if (
      appFeatures.isSupported("CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE")
    ) {
      return fetchContract(id, true);
    }

    const otpApiRes = await this._apiService.anonGet<
      ApiResponse<{ vCode: number }>
    >(`/contract/detail/${id}`);

    const verifyApiRes = await this._apiService.anonPost<
      ApiResponse<{ token: string }>
    >(`/contract/detail/verify/${id}`, {
      v_code: otpApiRes.data.data.vCode,
    });

    const token = verifyApiRes.data.data.token;

    this._apiService.setAuthToken(token);

    return fetchContract(id);
  };

  //------------------------

  getContractListEndpoint = (params: ContractListFilters = {}) => {
    params = { ...params };

    if (!params.limit) {
      params.limit = 1000;
    }

    if (!params.offset) {
      params.offset = 0;
    }

    (params as any).limit = `${params.limit}`;
    (params as any).offset = `${params.offset}`;

    Object.keys(params).forEach((key) => {
      if (!params[key as keyof ContractListFilters]) {
        delete params[key as keyof ContractListFilters];
      }
    });

    const serializedParams = new URLSearchParams(params as any).toString();

    return `/contract?${serializedParams}`;
  };

  fetchContracts = (params: ContractListFilters = {}) => {
    const endpoint = this.getContractListEndpoint(params);

    return this._apiService
      .get<
        ApiResponse<
          | CreatedContractDetails[]
          | {
              count: number;
              rows: CreatedContractDetails[];
            }
        >
      >(endpoint)
      .then((res) => {
        const contractListFromApi = (lodashGet(res.data, "data.rows") ||
          lodashGet(res.data, "data") ||
          []) as CreatedContractDetails[];
        const contractList = contractListFromApi.map(
          contractModelToCompleteDetails
        );

        store.dispatch(
          contractActions.addContracts({
            data: contractList,
          })
        );

        return res;
      });
  };

  rejectContract = (
    id: String,
    data: { reason: string; description: string }
  ) => {
    return this._apiService
      .post<
        ApiResponse<{
          data: null;
        }>
      >(`/contract/reject/${id}`, {
        reject_header: data.reason,
        reject_reason: data.description,
      })
      .then((res) => {
        return res.data.code === 200;
      });
  };

  acceptContractOLD = async (data: ContractCompleteDetails) => {
    const { id: contractId, milestones } = data;

    const promise = await Promise.all(
      milestones.map(({ id: milestoneId }) =>
        this._apiService.post(
          `/contract/approve/${contractId}/milestone/${milestoneId}`
        )
      )
    );

    const rejected =
      !promise.length ||
      promise.some((p) => lodashGet(p, "response.status") !== 200);

    if (rejected) {
      return Promise.reject({});
    }

    return promise;
  };

  acceptContract = (
    data: ContractCompleteDetails,
    { email = "" }: { email?: string } = {}
  ) => {
    const { id: contractId, milestones } = data;
    const milestoneId = milestones[0].id;

    const isOffer = isContractAnOffer(data);
    const urlFunction = isOffer ? getOfferPageUrl : getContractPageUrl;

    const success_url =
      window.location.origin + urlFunction(contractId) + "&success=true&";
    const cancel_url =
      window.location.origin + urlFunction(contractId) + "&success=false&";

    const payload: any = {
      success_url,
      cancel_url,
    };

    if (appFeatures.isSupported("CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL")) {
      payload.email = email;
    }

    return this._apiService
      .post<ApiResponse<PaymentResponse>>(
        `/contract/approve/${contractId}/milestone/${milestoneId}`,
        payload
      )
      .then((res) => {
        const stripePaymentUrl = res.data.data.url;
        if (stripePaymentUrl) {
          window.location.href = stripePaymentUrl;
        } else {
          throw new Error();
        }
      });
  };

  //---------------------------

  fundContractMilestone = (
    contractId: ContractId,
    milestoneId: MilestoneId,
    { email = "" }: { email?: string } = {}
  ) => {
    const success_url =
      window.location.origin +
      getContractPageUrl(contractId) +
      "&success=true&";
    const cancel_url =
      window.location.origin +
      getContractPageUrl(contractId) +
      "&success=false&";

    const payload: any = {
      success_url,
      cancel_url,
    };

    if (appFeatures.isSupported("CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL")) {
      payload.email = email;
    }

    return this._apiService
      .post<ApiResponse<PaymentResponse>>(
        `/contract/approve/${contractId}/milestone/${milestoneId}`,
        payload
      )
      .then((res) => {
        const stripePaymentUrl = res.data.data.url;
        window.location.href = stripePaymentUrl;
      });
  };

  getStripeSessionFromSessionId = (sessionId: string) => {
    if (!this._stripeInstance) return Promise.reject();

    return this._stripeInstance.checkout.sessions.retrieve(sessionId);
  };

  //---------------------------

  pauseContract = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.post<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/pause/${contractId}/milestone/${milestoneId}`);
  };

  resumeContract = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.post<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/unpause/${contractId}/milestone/${milestoneId}`);
  };

  //---------------------------

  submitContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
    description: string;
    attachments: number[];
  }) => {
    const { contractId, milestoneId, attachments, description } = data;

    return this._apiService.post<ApiResponse<PaymentResponse>>(
      `/contract/${contractId}/milestone/${milestoneId}/submit`,
      { attachments, description }
    );
  };

  approveContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
  }) => {
    const { contractId, milestoneId } = data;

    return this._apiService.post<ApiResponse<PaymentResponse>>(
      `/contract/${contractId}/milestone/${milestoneId}/complete`
    );
  };

  getContractMilestone = (data: { milestoneId: number }) => {
    const { milestoneId } = data;

    return this._apiService.get<
      ApiResponse<MilestoneCompleteDetails & { work: MilestoneWork[] }>
    >(`/contract/milestone/${milestoneId}`);
  };

  startContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
  }) => {
    const { contractId, milestoneId } = data;

    return this._apiService.post<ApiResponse<PaymentResponse>>(
      `/contract/start/${contractId}/milestone/${milestoneId}`
    );
  };

  //---------------------------

  getFileSignedUrl = (fileKey: string) => {
    return this._apiService.anonPost<ApiResponse<{ url: string }>>(
      `/media/getPresignedUrl`,
      { key: fileKey }
    );
  };

  //---------------------------

  addOfferView = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.anonPost<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/${contractId}/milestone/${milestoneId}/view`);
  };

  //---------------------------

  cleanup = () => {
    store.dispatch(
      contractActions.addContracts({
        data: [],
        replaceList: true,
      })
    );
  };

  //---------------------------

  shareOfferViaEmail = (params: ShareOfferViaEmailParams) => {
    return this._apiService.post(`/contract/send`, params);
  };

  //---------------------------

  preApproveContract = (
    contractId: string,
    milestoneId: MilestoneId,
    userDetails: {
      userId?: number;
      email?: string;
      firstName?: string;
      lastName?: string;
    }
  ) => {
    return this._apiService.anonPost<
      ApiResponse<{
        data: null;
      }>
    >(
      `/contract/pre-approve/${contractId}/milestone/${milestoneId}`,
      userDetails
    );
  };
  //---------------------------

  getPaymentReleaseStatus = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService
      .get<
        ApiResponse<{
          fundingDate: string;
          paymentReleasableDate: string;
          wait: { days: number };
        }>
      >(`/contract/${contractId}/milestone/${milestoneId}/release-fund-status`)
      .then((res) => res.data.data);
  };
}

export default ContractsService;
