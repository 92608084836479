"use client";

import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import SpecializationForm, {
  FormValues,
} from "@/features/contracts/SpecializationForm";
import SpecializationCard from "@/features/user/SpecializationCard";
import { screenLargerThan } from "@/styles";

import { useProfileForm } from "../../ProfileForm.config";
// import PortfolioCard from "../PortfolioCard";
import DeleteConfirmation from "../DeleteConfirmation";

const StyledCardContainer = styled.div``;

const StyledButton = styled(Button)`
  ${screenLargerThan.widescreen} {
    min-height: 98px;
    border-radius: 1rem;
    background: var(--clr-background-25, #f6f8fa);
  }
`;

const SpecializationInput: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { values, setFieldValue, submitForm } = useProfileForm();
  const { specializations = [] } = values;

  const [editingIndex, setEditingIndex] = useState(-1);

  const [isSaving, setIsSaving] = useState(false);

  const formModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setEditingIndex(-1);
      }
    },
  });
  const [toDeleteItemIndex, setToDeleteItemIndex] = useState<null | number>(
    null
  );
  const deleteModalState = useModalState({
    onOpenChange: (open) => {
      if (!open) {
        setToDeleteItemIndex(null);
      }
    },
  });

  //---------------------------------------

  const addNewItem = useCallback(() => {
    const updated = [...specializations, {}];
    setFieldValue("specializations", updated);
    setEditingIndex(updated.length - 1);
    formModalState.open();
  }, [specializations, setFieldValue, formModalState]);

  // const handleEdit = useCallback(
  //   (index: number) => {
  //     setEditingIndex(index);
  //     formModalState.open();
  //   },
  //   [formModalState]
  // );

  const handleDelete = useCallback(() => {
    if (toDeleteItemIndex === null) {
      return;
    }

    const updated = [...specializations];
    updated.splice(toDeleteItemIndex, 1);
    setFieldValue("specializations", updated);
    deleteModalState.close();
    setTimeout(() => {
      submitForm();
    }, 100);
  }, [
    specializations,
    setFieldValue,
    toDeleteItemIndex,
    deleteModalState,
    submitForm,
  ]);

  const handleDeleteClick = useCallback(
    (index: number) => {
      setToDeleteItemIndex(index);
      deleteModalState.open();
    },
    [deleteModalState]
  );

  const handleUpdate = useCallback(
    ({ values }: { values: FormValues }) => {
      if (
        !values.name ||
        !values.experience_duration ||
        !values.experience_duration_unit
      ) {
        return;
      }

      setIsSaving(true);

      (async function () {
        try {
          setFieldValue(`specializations[${editingIndex}]`, values);
          setTimeout(() => {
            submitForm();
          }, 100);
        } catch {}
      })().then(() => {
        setIsSaving(false);
        formModalState.close();
      });
    },
    [setFieldValue, submitForm, editingIndex, formModalState]
  );

  const cardsJsx = specializations.map((specialization, i) => (
    <SpecializationCard
      key={i}
      useLink={false}
      specialization={specialization}
      className={"mb-4"}
      onDelete={() => {
        handleDeleteClick(i);
      }}
      onClick={() => {
        setEditingIndex(i);
        formModalState.open();
      }}
    />
  ));

  return (
    <>
      <div className={className}>
        <Label size="lg" as="label" htmlFor="timezone">
          Specializations
        </Label>

        <StyledCardContainer className="mt-3">
          {cardsJsx}

          <StyledButton
            variant="secondary"
            colorVariant="gray"
            className="w-100"
            onClick={addNewItem}
          >
            Add new
          </StyledButton>
        </StyledCardContainer>
      </div>

      <Modal
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        state={formModalState}
        mobileProps={{
          fullscreen: true,
          className: "p-0",
        }}
        desktopProps={{
          width: "768px",
        }}
      >
        <PageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Specializations" },
            backButtonProps: { onClick: formModalState.close },
          }}
        >
          <div className="px-3">
            {formModalState.isOpen && (
              <SpecializationForm
                initialData={specializations[editingIndex]}
                onSubmit={handleUpdate}
                ctaProps={{
                  className: "cta-button w-100 mt-4",
                  children: isSaving ? "Saving" : "",
                }}
              />
            )}
          </div>
        </PageWithHeaderLayout>
      </Modal>

      <Modal state={deleteModalState} desktopProps={{ width: "420px" }}>
        <DeleteConfirmation
          title="Delete specialization"
          description="Are you sure want to delete this specialization?"
          onCancel={deleteModalState.close}
          onDelete={handleDelete}
        />
      </Modal>
    </>
  );
};

export default SpecializationInput;
