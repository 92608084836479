import useSWR from "swr";

import { userService } from "@/config/services";

import { SubscriptionDuration } from "../UserService.types";

function useSubscriptionDetails() {
  const {
    data: subscriptionDetails,
    isLoading: loadingSubscriptionDetails,
    mutate,
  } = useSWR(
    "/subscription/details",
    () => userService.getPremiumSubscribtionDetails(),
    {
      errorRetryInterval: 4000,
      errorRetryCount: 10,
      revalidateOnMount: true,
    }
  );

  return {
    loadingSubscriptionDetails,
    refresh: () => mutate(),
    subscriptionDetails: subscriptionDetails
      ? {
          ...subscriptionDetails,
          isMonthlySubscription:
            subscriptionDetails.subscriptionPlanDuration ===
            SubscriptionDuration.Monthly,
          isYearlySubscription:
            subscriptionDetails.subscriptionPlanDuration ===
            SubscriptionDuration.Yearly,
        }
      : null,
    hasPremium: subscriptionDetails?.hasPremium || false,
  };
}

export default useSubscriptionDetails;
