"use client";

import styled from "@emotion/styled";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Button from "@/components/input/Button";
import { Label } from "@/components/Typography";
import { screenLargerThan } from "@/styles";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 1rem 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledImageTitleContent = styled(ImageTitleContent)`
  & > * {
    max-width: unset;
  }

  p {
    max-width: 38ch;
  }
`;

export const StyledSwitchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 19rem;
  margin-inline: auto;

  ${screenLargerThan.tablet} {
    max-width: 24rem;
  }
`;

export const StyledTag = styled(Label)`
  position: absolute;
  left: 100%;
  min-width: max-content;
  display: inline-block;
  padding: 0.125rem 0.25rem;
  color: #fff;
  font-weight: 700 !important;
  border: 1px solid var(--clr-primary-100, #5f57ff);
  color: var(--clr-primary-100, #5f57ff);
  background: var(--clr-primary-0, #f0efff);
  border-radius: 0.5rem;
`;

export const StyledCta = styled(Button)`
  display: block;
  max-width: 23rem;
  margin-inline: auto;
`;

export const StyledPlanButton = styled(Button)`
  position: relative;
`;
