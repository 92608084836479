import { useMemo } from "react";
import styled from "@emotion/styled";

import DetailsTable, { Item } from "@/components/data/DetailsTable";
import { CONTRACT_TYPE_CONFIG } from "@/services/ContractsService";
import { formatDate } from "@/utils/date";

import { useContractManagement } from "../ContractManagementPage.context";
import { StyledSectionHeading } from "../ContractManagementPage.styles";

const StyledContainer = styled.div`
  max-width: 26rem;
`;

const JobDetails: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails } = useContractManagement();

  const { contract_type } = contractDetails;

  const contractCreationDate = contractDetails?.created_at;
  const contractCreationDateFormatted = useMemo(
    () => (contractCreationDate ? formatDate(contractCreationDate) : "-"),
    [contractCreationDate]
  );

  const detailFields = useMemo(() => {
    if (!contract_type) {
      return [];
    }

    let fields: Item[] = [
      {
        label: "Offer Type",
        value: CONTRACT_TYPE_CONFIG[contract_type].info.title,
      },
      {
        label: "Created On",
        value: contractCreationDateFormatted,
      },
    ];

    return fields;
  }, [contract_type, contractCreationDateFormatted]);

  return (
    <StyledContainer className={className}>
      <StyledSectionHeading>Details</StyledSectionHeading>
      <DetailsTable items={detailFields} className="mt-2" />
    </StyledContainer>
  );
};

export default JobDetails;
