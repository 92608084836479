import React from "react";
import styled from "@emotion/styled";

import Button, { IButtonProps } from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { screenSmallerThan } from "@/styles";

const StyledButton = styled(Button)<{ $large?: boolean }>`
  --bg-color: #f0f0f0 !important;
  border-style: dashed;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  border-radius: 0.75rem !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25em;
  text-align: left;

  ${({ $large }) =>
    $large
      ? `
          padding-block: 1rem;
          min-height: 6.75rem;
        `
      : `
          padding-block: 1rem;
          min-height: 3.5rem;
        `}

  ${screenSmallerThan.tablet} {
    font-size: 0.865rem;
  }

  &:hover,
  &:active {
    --bg-color: #dbdaff !important;
  }

  &:active {
    box-shadow: 4px 4px 12px 0px #00000024 inset !important;
  }
`;

export interface IAddButtonProps extends IButtonProps {
  size?: "lg";
}

const AddButton: React.FC<IAddButtonProps> = (props) => {
  return (
    <StyledButton {...props} $large={props.size === "lg"}>
      <Icon isSrcRelative src="plus_round.svg" customSize="1.6rem" />{" "}
      {props.children}
    </StyledButton>
  );
};

export default AddButton;
