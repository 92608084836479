import { useMemo } from "react";

import { selectUserData, useAppSelector } from "@/services/Store";

function useWallet() {
  const userData = useAppSelector(selectUserData);

  const balance = useMemo(() => {
    const balanceString = userData?.wallet?.balance;
    let balance = 0;

    if (balanceString) {
      balance = +balanceString;
      // balance /= 100; //Balance is coming in cent from API
    }

    return balance;
  }, [userData?.wallet?.balance]);

  return {
    balance,
  };
}

export default useWallet;
