import React from "react";

import { ContractType } from "@/services/ContractsService";
import EditorField from "@/components/input/EditorFieldNew";

import { useContractForm } from "../../ContractForm.context";

const DescriptionField: React.FC<{
  className?: string;
  readOnly?: boolean;
}> = ({ className, readOnly = false }) => {
  const { values, setFieldValue, touched } = useContractForm();

  const isOneTimeContract = values.contract_type === ContractType.OneTime;
  const isMilestoneContract = values.contract_type === ContractType.Milestone;

  const title = values.title || "Job";
  const placeholder = isMilestoneContract
    ? "Add details about the overall job"
    : "Add details about the job";

  return (
    <EditorField
      showSaveButton
      focusOnMount={!readOnly}
      hasError={!!touched.description && !values.description}
      readOnly={readOnly}
      className={className}
      label="Job Description"
      placeholder={placeholder}
      value={{ markdown: values.description }}
      onChange={({ markdown }) => {
        setFieldValue("description", markdown);
        if (isOneTimeContract) {
          setFieldValue("milestones[0].description", markdown);
        }
      }}
      drawerTitle={title}
    />
  );
};

export default DescriptionField;
