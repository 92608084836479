import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";

import { StyledContainer } from "./Tag.styles";
import { useTagListInput } from "../../TagListInput.context";

export const TagListInputProvider: React.FC<{ index: number }> = ({
  index,
}) => {
  const { tags, deleteTag } = useTagListInput();
  const tag = tags[index];

  return (
    <StyledContainer>
      {tag}
      <Button
        variant="ghost"
        colorVariant="gray"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          deleteTag(index);
        }}
      >
        <Icon
          isSrcRelative
          src="cross.svg"
          colorVariant="gray"
          customSize="0.5rem"
        />
      </Button>
    </StyledContainer>
  );
};

export default TagListInputProvider;
