import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import {
  Package,
  useSubscriptionDetails,
  useUpdateProfile,
} from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { Body, Heading } from "@/components/Typography";
import Modal, { useModalState } from "@/components/misc/Modal";
import { FileObject } from "@/features/input/FilesInput";
import { mediaService } from "@/config/services";
import PackageCard from "@/features/PaypipeId/PackageCard";
import Slider from "@/features/PaypipeId/Slider";
import { generateRandomString } from "@/utils/string";
import DeleteConfirmation from "@/features/user/ProfileForm/components/DeleteConfirmation";
import { useToast } from "@/components/misc/Toast";
import { MAX_NON_PREMIUM_USER_PACKAGE_COUNT } from "@/services/UserService/UserService.config";
import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
  useResponsive,
} from "@/styles";
import PremiumPrompt from "@/features/PaypipeId/PremiumPrompt";

import EditPackage from "./EditPackageNew";
import AddButton from "./AddButton";

export const StyledContainer = styled.div`
  ${screenLargerThan.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > * {
      flex-grow: 1;
      max-width: 36rem;
    }
  }
`;

const StyledDescription = styled(Body)`
  color: #64748b;
`;

const StyledModal = styled(Modal)`
  ${screenSmallerThan.tablet} {
    padding: 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    overflow: auto;
  }

  ${screenSmallerThan.tablet} {
    height: ${getViewPortHeightCssString()};
    overflow: hidden;
    padding-block: 1rem;

    & > * {
      padding-inline: 1rem;
    }
  }
`;

export type EditingPackage = Package & { assets?: FileObject[] };

const PackagesSection: React.FC<{
  packages: Package[];
  canEdit?: boolean;
  className?: string;
}> = ({ packages: packagesFromProps, canEdit, className }) => {
  const { createToast } = useToast();
  const { hasPremium } = useSubscriptionDetails();
  const { isScreenSmallerThanTablet } = useResponsive();
  const { updateField, isProfileDataLoading } = useUpdateProfile();
  const [editingIndex, setEditingIndex] = useState<null | number>(null);
  const [packages, setPackages] = useState(packagesFromProps);
  const [editingPackageData, setEditingPackageData] =
    useState<EditingPackage | null>(null);
  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setIsSaving(false);
        setEditingIndex(null);
        setEditingPackageData(null);
      }
    },
  });
  const [isSaving, setIsSaving] = useState(false);

  const [deletionIndex, setDeletionIndex] = useState<null | number>(null);
  const deletionModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setDeletionIndex(null);
      }
    },
  });

  const premiumPromptModalState = useModalState();

  //-----------------------------

  const handleApply = useCallback(
    (values: EditingPackage) => {
      if (editingIndex === null) {
        modalState.close();
        return;
      }

      const updatedPackages = [...packages];

      setIsSaving(true);

      (async function () {
        try {
          const { assets = [] } = values;
          const payload = { ...values };

          const updatedAttatchments = await Promise.all(
            assets
              .filter(({ file, mediaFile }) => !!file || !!mediaFile)
              .map(({ file, mediaFile }) =>
                mediaFile
                  ? Promise.resolve(mediaFile)
                  : mediaService
                      .uploadFile(file as File)
                      .then((res) => res.data.data)
              )
          );
          payload.attachments = updatedAttatchments;
          delete payload.assets;

          if (editingIndex === -1) {
            updatedPackages.push(payload);
          } else {
            updatedPackages[editingIndex] = payload;
          }

          setPackages(updatedPackages);

          await updateField("packages", updatedPackages);
        } catch {}
      })().then(() => {
        setIsSaving(false);
        createToast({
          title: "Portfolio updated",
          timeoutInMilliseconds: 5000,
        });
        modalState.close();
      });
    },
    [packages, editingIndex, updateField, modalState, createToast]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const updatedPackages = [...packages];
      updatedPackages.splice(index, 1);
      setPackages(updatedPackages);
      updateField("packages", updatedPackages)
        .then(() => {
          createToast({
            title: "Portfolio deleted",
            timeoutInMilliseconds: 5000,
          });
        })
        .catch(() => {
          createToast({
            variant: "error",
            title: "Something went wrong, Portfolio not deleted.",
            timeoutInMilliseconds: 8000,
          });
          setPackages(packagesFromProps);
        });
    },
    [packages, updateField, createToast, packagesFromProps]
  );

  const handleAdd = useCallback(() => {
    if (!hasPremium && packages.length === MAX_NON_PREMIUM_USER_PACKAGE_COUNT) {
      premiumPromptModalState.open();
      return;
    }

    setEditingIndex(-1);
    setEditingPackageData({
      id: generateRandomString(),
      name: "",
      description: "",
      attachments: [],
      estimated_duration: 0,
      estimated_duration_unit: "Days",
      price: 0,
    } satisfies EditingPackage);
    modalState.open();
  }, [modalState, packages, hasPremium, premiumPromptModalState]);

  //-----------------------------

  if (isProfileDataLoading) {
    return <SplashScreen />;
  }

  const isCreatingNew = editingIndex === -1;
  const showAddButton = canEdit;

  return (
    <>
      <Modal state={deletionModalState}>
        {deletionIndex !== null && (
          <DeleteConfirmation
            title="Delete confirmation"
            description={`Are you sure want to delete "${packages[deletionIndex].name}" ?`}
            onCancel={deletionModalState.close}
            onDelete={() => {
              handleDelete(deletionIndex);
              deletionModalState.close();
            }}
          />
        )}
      </Modal>

      <StyledModal
        showCloseButton
        state={modalState}
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        width="978px"
        mobileProps={{ fullscreen: true }}
      >
        <StyledContent>
          <div className="header">
            <Heading size="lg" className="mb-2">
              {isCreatingNew ? "Add A New Package" : "Edit Package"}
            </Heading>
            <StyledDescription size="md" className="mb-4 description">
              Package together your most popular services for clients to
              purchase in one job.
            </StyledDescription>
          </div>

          <div className="content">
            {!!editingPackageData && (
              <EditPackage
                package={editingPackageData}
                onCancel={modalState.close}
                onSave={handleApply}
                cancelButtonProps={{
                  disabled: isSaving,
                }}
                saveButtonProps={{
                  disabled: isSaving,
                  children: isCreatingNew
                    ? isSaving
                      ? "Adding"
                      : "Add"
                    : isSaving
                    ? "Saving"
                    : "Save",
                }}
              />
            )}
          </div>
        </StyledContent>
      </StyledModal>

      <div className={className}>
        {!!packages.length && (
          <>
            {packages.length === 1 ||
            (!isScreenSmallerThanTablet && packages.length === 2) ? (
              <StyledContainer>
                {packages.map((p, i) => (
                  <PackageCard
                    key={i}
                    package={p}
                    className="mb-3"
                    canEdit={canEdit}
                    onDelete={() => {
                      setDeletionIndex(i);
                      deletionModalState.open();
                    }}
                    onEdit={() => {
                      setEditingIndex(i);
                      setEditingPackageData(p);
                      modalState.open();
                    }}
                    style={{ maxWidth: "480px" }}
                  />
                ))}
              </StyledContainer>
            ) : (
              <Slider>
                {packages.map((p, i) => (
                  <PackageCard
                    key={i}
                    package={p}
                    className="mb-3"
                    canEdit={canEdit}
                    onDelete={() => {
                      setDeletionIndex(i);
                      deletionModalState.open();
                    }}
                    onEdit={() => {
                      setEditingIndex(i);
                      setEditingPackageData(p);
                      modalState.open();
                    }}
                  />
                ))}
              </Slider>
            )}
          </>
        )}

        {showAddButton &&
          (packages.length ? (
            <AddButton className="mt-3" colorVariant="gray" onClick={handleAdd}>
              Add Package
            </AddButton>
          ) : (
            <AddButton size="lg" className="mt-3" onClick={handleAdd}>
              Describe service offerings with clear benefits, pricing and
              delivery time.
            </AddButton>
          ))}
      </div>

      {!hasPremium && (
        <Modal state={premiumPromptModalState} width="60rem">
          <PremiumPrompt onClose={premiumPromptModalState.close} />
        </Modal>
      )}
    </>
  );
};

export default PackagesSection;
