"use client";

import styled from "@emotion/styled";

import ContractPreview from "@/features/contracts/ContractPreview";
import { screenLargerThan } from "@/styles";

export const StyledPage = styled.div`
  height: 100%;
`;

export const StyledProposalStep = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${screenLargerThan.tablet} {
    padding-block: 2rem;
  }
`;

export const StyledContractPreview = styled(ContractPreview)`
  flex: 1;
  overflow: auto;
  padding-block: 1rem;

  ${screenLargerThan.tablet} {
    padding-inline: 1rem;
  }
`;

export const StyledProposalControls = styled.div`
  padding-bottom: 1rem;
`;

export const StyledProposalButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;

export const StyledSmallText = styled.p`
  font-size: 0.815rem;
  line-height: 160%;
  letter-spacing: -0.02em;
  margin: 0;
  font-weight: 400;
  color: var(--clr-text-500, #666d80);

  a {
    color: var(--clr-primary-100, #5f57ff) !important;
    font-weight: 500;
  }
`;
