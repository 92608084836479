"use client";

import React from "react";

import { appFeatures } from "@/config/services";
import EditPaypipeIDPage from "@/features/pages/user/EditPaypipeIdPageNew";
import EditProfilePageOld from "@/features/pages/user/EditProfilePageOld";

const EditProfilePage: React.FC = () => {
  if (appFeatures.isSupported("ACCOUNT.SHOW_NEW_PAYPIPE_ID_EDIT_PAGE")) {
    return <EditPaypipeIDPage />;
  }

  return <EditProfilePageOld />;
};

export default EditProfilePage;
