import { useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";

import useManageContract from "./useManageContract";

function useAutoFixContractUrl() {
  const { pathname } = useLocation();
  const { navigate } = useNavigate();
  const { searchParams } = useSearchParams();
  const { isLoading, isApproved, contractDetails } = useManageContract({
    useContractIdFromUrl: true,
  });

  // ----------------------------------

  useEffect(() => {
    if (isLoading || !contractDetails) {
      return;
    }

    let newPath = "";

    if (isApproved && pathname === SITE_PATHS.OFFERS_PAGE) {
      newPath = SITE_PATHS.CONTRACTS_PAGE;
    }

    if (!isApproved && pathname === SITE_PATHS.CONTRACTS_PAGE) {
      newPath = SITE_PATHS.OFFERS_PAGE;
    }

    if (newPath) {
      const search = searchParams
        .toString()
        .replaceAll("%3F", "?")
        .replaceAll("&?", "&");
      newPath += `?${search}`;

      navigate(newPath, true);
    }
  }, [isApproved, pathname, isLoading, navigate, contractDetails, searchParams]);

  // ----------------------------------

  return { isLoading };
}

export default useAutoFixContractUrl;
