"use client";

import React from "react";

import { selectUserData, useAppSelector } from "@/services/Store";
import { Body, Label } from "@/components/Typography";
import Avatar from "@/components/misc/Avatar";
import Icon from "@/components/misc/Icon";
import { SITE_PATHS } from "@/config/routing";

import {
  StyledContainer,
  StyledContentContainer,
  StyledLink,
} from "./UserDisplay.styles";

const UserDisplay: React.FC<{ className?: string; variantSmall?: boolean }> = ({
  className,
  variantSmall = false,
}) => {
  const userData = useAppSelector(selectUserData);

  return (
    <StyledContainer className={className}>
      <StyledLink to={SITE_PATHS.SETTINGS_PAGE} />

      <Avatar
        img={userData?.picture || userData?.owner_profile || ""}
        firstName={userData?.given_name || ""}
        lastName={userData?.family_name || ""}
        customSize="2.5rem"
      />

      {!variantSmall && (
        <StyledContentContainer>
          <Label size="md" className="capitalize">
            {userData?.given_name || ""} {userData?.family_name || ""}
          </Label>
          <Body size="sm" style={{ color: "#666D80" }}>
            @{userData?.paypipe_id || ""}
          </Body>
        </StyledContentContainer>
      )}

      {!variantSmall && (
        <Icon src="/assets/images/icons/chevron_right.svg" size="xxs" />
      )}
    </StyledContainer>
  );
};

export default UserDisplay;
