import styled from "@emotion/styled";

import ContractWorkInput from "@/features/contracts/ContractWorkInput";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden;
`;

export const StyledInput = styled(ContractWorkInput)`
  flex: 1;
  overflow: auto;
`;

export const StyledBottomSection = styled.div`
  padding: 1rem;
  border-top: 1px solid var(--clr-border-50, #eceff3);
`;

export const StyledContent = styled.p`
  font-size: 0.8125rem;
  line-height: 170%;
  color: var(--clr-text-900, #0d0d12);
  margin: 0;
  text-align: center;
`;
