"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  overflow: auto;
`;
