import { useCallback, useEffect, useMemo, useState } from "react";
import lodashGet from "lodash/get";
import dayjs from "dayjs";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import { useToast } from "@/components/misc/Toast";
import useCountdown from "@/hooks/useCountdown";
import Modal, { useModalState } from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import { Body } from "@/components/Typography";

import useContractDetails from "../useContractDetails";
import PaymentWait from "./comonents/PaymentWait";
import useReleasePaymentStatus from "../useReleasePaymentStatus";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useReviewContractMilestone(params: {
  contractId: string;
  milestoneId: number;
  showConfirmation?: boolean;
}) {
  const { contractId, milestoneId, showConfirmation = true } = params;
  const { createToast } = useToast();
  const modalState = useModalState();
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const {
    data: paymentReleaseStatus,
    isLoading: isLoadingPaymentReleaseStatus,
  } = useReleasePaymentStatus({ contractId, milestoneId });

  const [isApproving, setIsApproving] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const fundReleaseAllowedDate = useMemo(
    () =>
      paymentReleaseStatus?.paymentReleasableDate
        ? new Date(paymentReleaseStatus?.paymentReleasableDate)
        : null,
    [paymentReleaseStatus?.paymentReleasableDate]
  );

  const passedWaitDuration = useMemo(() => {
    if (!fundReleaseAllowedDate) {
      return true;
    }

    const passedWaitDuration = dayjs(fundReleaseAllowedDate) < dayjs();
    return passedWaitDuration;
  }, [fundReleaseAllowedDate]);

  const { remainingTimeInMs, updateTargetTime, start } = useCountdown({
    targetTime: fundReleaseAllowedDate,
    countUp: true,
    startOnMount: !passedWaitDuration,
  });

  const canApprove = passedWaitDuration || remainingTimeInMs >= 0;
  const showWaitPrompt = !canApprove;
  
  //-----------------------------

  const approve = useCallback(() => {
    if (showWaitPrompt) {
      modalState.open();
      return Promise.reject();
    }

    if (isApproving) {
      return Promise.reject();
    }

    setIsApproving(true);
    setIsApproved(false);

    return contractService
      .approveContractMilestone({ ...params })
      .then(() => {
        setIsApproved(true);
        createToast({
          title: "Payment released successfully.",
          variant: "success",
          timeoutInMilliseconds: 5000,
        });
      })
      .catch((err) => {
        setIsApproved(false);
        const message = lodashGet(err, "response.data.message");
        createToast({
          title: "Payment not released",
          description: message,
          variant: "error",
        });
      })
      .finally(() => {
        setIsApproving(false);
        reloadContractDetails();
      });
  }, [
    params,
    isApproving,
    reloadContractDetails,
    createToast,
    modalState,
    showWaitPrompt,
  ]);

  //-----------------------------

  useEffect(() => {
    if (fundReleaseAllowedDate) {
      updateTargetTime(fundReleaseAllowedDate);
      start();
    }
  }, [fundReleaseAllowedDate, updateTargetTime, start]);

  //-----------------------------
  const submitButtonText = isApproving
    ? "Releasing Payment..."
    : "Release Payment";

  const confirmationJsx = (
    <>
      <Body size="lg">Are you sure want to release payment?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={modalState.close}
        >
          Cancel
        </Button>
        <Button disabled={isApproving} onClick={approve}>
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </>
  );

  const jsx = (
    <Modal state={modalState} width={showWaitPrompt ? "560px" : "360px"}>
      {showWaitPrompt ? (
        <>
          <PaymentWait remainingTimeInMs={remainingTimeInMs} waitDays={paymentReleaseStatus?.wait?.days} />
          <Button className="mt-4 w-100" onClick={modalState.close}>
            Close
          </Button>
        </>
      ) : (
        confirmationJsx
      )}
    </Modal>
  );

  return {
    approve: showConfirmation ? modalState.open : approve,
    isApproved,
    isApproving,
    jsx,
    canApprove,
    loading: isLoadingPaymentReleaseStatus,
  };
}

export default useReviewContractMilestone;
