import React, { useMemo } from "react";

import Seo from "@/components/misc/Seo";
import { getOfferShareContent } from "@/services/ContractsService";

const OfferSeo: React.FC<{ contractId: string }> = ({ contractId }) => {
  const content = useMemo(() => {
    const content = getOfferShareContent({ contractId: contractId });

    return {
      ...content,
      text: `${content.text}\n\n${content.url}`,
    };
  }, [contractId]);

  return (
    <Seo
      pageTitle="Paypipe | Offer"
      title={content.title}
      description={content.text}
      image="https://paypipe-staging.cspaces.com/assets/images/share/share_offer.png"
    />
  );
};

export default OfferSeo;
