"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Form from "@/components/input/Form";
import { analytics, contractService } from "@/config/services";
import { useAuth } from "@/services/Authentication";
import { useHash } from "@/services/Routing";
import { useToast } from "@/components/misc/Toast";

import {
  AUTO_REJECT_HASH,
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from "./ContractRejectForm.config";
import {
  FormValues,
  IContractRejectFormProps,
} from "./ContractRejectForm.types";
import { ContractRejectFormProvider } from "./ContractRejectForm.context";

const ContractRejectForm: React.FC<IContractRejectFormProps> = (props) => {
  const { initialValues = INITIAL_VALUES, contractId, onChange } = props;
  const { login, isAuthenticated } = useAuth();
  const { getUrlWithHash, hash, removehash } = useHash();
  const [rejected, setRejected] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const { createToast } = useToast();

  const shouldAutoRejectRef = useRef(hash === AUTO_REJECT_HASH);

  //------------------------

  const handleReject = useCallback(
    (values: FormValues) => {
      if (isRejecting) return;

      setIsRejecting(true);

      const handleError = () => {
        createToast({
          title: "Something went wrong , Offer not rejected",
          variant: "error",
        });
        analytics.triggerRejectOfferEvent("reject_offer_error", { contractId });
      };

      contractService
        .rejectContract(contractId, values)
        .then((rejected) => {
          if (rejected) {
            setRejected(true);
            createToast({ title: "Offer rejected" });
            analytics.triggerRejectOfferEvent("reject_offer_success", {
              contractId,
            });
          } else {
            handleError();
          }
        })
        .catch(handleError)
        .finally(() => {
          setIsRejecting(false);
        });
    },
    [contractId, isRejecting, createToast]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (!isAuthenticated) {
        login({ redirectUrl: getUrlWithHash(AUTO_REJECT_HASH) });
        return;
      }

      if (isRejecting) return;

      setIsRejecting(true);

      handleReject(values);
    },
    [isRejecting, isAuthenticated, getUrlWithHash, handleReject, login]
  );

  //------------------------

  useEffect(() => {
    if (
      shouldAutoRejectRef.current &&
      !!initialValues.reason &&
      !!initialValues.description
    ) {
      shouldAutoRejectRef.current = false;
      removehash(true);
      handleReject(initialValues);
    }
  }, [contractId, initialValues, handleReject, removehash]);

  useEffect(() => {
    if (contractId) {
      analytics.triggerRejectOfferEvent("reject_offer", { contractId });
    }
  }, [contractId]);

  //------------------------

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      yupValidationSchema={VALIDATION_SCHEMA}
      initialValues={initialValues}
      onChange={onChange}
    >
      <ContractRejectFormProvider {...props} rejected={rejected} />
    </Form>
  );
};

export default ContractRejectForm;
