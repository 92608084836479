"use client";

import styled from "@emotion/styled";

import Card from "@/components/data/Card";
import Wysiwyg from "@/components/input/LexicalWysiwyg";

export const StyledContainer = styled.div`
  padding-block: 1rem;
  background: var(--clr-background-ukwn-8, #fbfbfb);
`;

export const StyledWysiwyg = styled(Wysiwyg)`
  &,
  .editor {
    background: transparent;
  }

  .editor > * {
    position: static;
  }
`;

export const StyledSectionHeading = styled.div`
  font-size: 1rem;
  line-height: 170%;
  font-weight: 600;
  color: var(--clr-text-ukwn-1, #171717);
`;

export const StyledContractDate = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledContractTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: -0.03em;
  color: var(--clr-text-900, #0d0d12);
  word-break: break-word;
`;

export const StyledContractValue = styled.div`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.03em;
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledMainDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledDataLabel = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledDataValue = styled.div`
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: var(--clr-text-900, #0d0d12);
  word-break: break-word;
`;

export const StyledSmallText = styled.p`
  font-size: 0.875rem;
  line-height: 160%;
  letter-spacing: -0.02em;
  margin: 0;
`;

//---------------- Desktop

export const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 1328px;
  margin-inline: auto;
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  & > * {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  & > *:first-child {
    flex: 3;

    & > *:last-child {
      flex: 1;
    }
  }

  & > *:last-child {
    flex: 2;

    & > *:last-child {
      flex: 1;
    }
  }
`;

export const StyledCtaCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCtaSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
