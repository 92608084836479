"use client";

import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 12px;
  line-height: 1rem;
  font-weight: 500;
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledDot = styled.div`
  ${squareSizing("4px")};
  background: var(--clr-background-ukwn-4, #DFE1E7);
  border-radius: 10rem;
`;
