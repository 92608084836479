"use client";

import React, { useState } from "react";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { SITE_PATHS } from "@/config/routing";
import Button from "@/components/input/Button";
import { Body, Label } from "@/components/Typography";
import TextField from "@/components/input/TextField";
import { useResponsive } from "@/styles";

import {
  StyledCount,
  StyledImage,
  StyledPage,
  StyledSmall,
} from "./AppRatingPage.styles";
import RatingInput from "./components/RatingInput";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";

const AppRatingPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const [content, setContent] = useState("");

  const charactersLeft = Math.max(0, 3000 - content.length);

  const contentJsx = (
    <StyledPage className="container">
      <StyledImage src="/assets/images/pages/rating/illustration.svg" alt="" />
      <Body
        className="text-center mt-3"
        size="md"
        style={{ color: "var(--clr-icon-text-200, #818898)" }}
      >
        Tell us your thoughts about the Paypipe app.
      </Body>

      <Label size="lg" className="mt-5">
        <StyledCount size="lg">1</StyledCount>&nbsp;&nbsp; How is your
        experience?
      </Label>
      <RatingInput className="mt-3" />

      <Label size="lg" className="mt-5">
        <StyledCount size="lg">2</StyledCount>&nbsp;&nbsp; Share a review about
        Paypipe
      </Label>
      <TextField
        textArea
        value={content}
        onChange={setContent}
        className="mt-3"
        useFloatingLabel={false}
        variant="background"
        placeholder="Write your review here"
      />
      <StyledSmall className="mt-2 mb-4">
        {charactersLeft} character left
      </StyledSmall>

      <Button className="w-100 mt-auto">Done</Button>
    </StyledPage>
  );

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          backButtonProps: { link: SITE_PATHS.SETTINGS_PAGE },
          titleProps: { children: "Rate our app" },
        }}
      >
        {contentJsx}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout headerProps={{ logo: { component: "Rate our app" } }}>
      {contentJsx}
    </DesktopPrimaryLayout>
  );
};

export default AppRatingPage;
