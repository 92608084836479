"use client";

import React from "react";

import { Body, Label } from "@/components/Typography";
import TimezoneInputComponent from "@/features/input/TimezoneInput";

import { useProfileForm } from "../../ProfileForm.config";

const TimezoneInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue, submitForm } = useProfileForm();

  return (
    <div className={className}>
      <Label size="lg" as="label" htmlFor="timezone">
        Time zone
      </Label>

      <Body size="sm" style={{ color: "#868C98" }}>
        If not added time zone will default to location detected if location
        services are turned on
      </Body>

      <TimezoneInputComponent
        className="mt-3"
        value={values.timezone}
        onChange={(value) => {
          setFieldValue("timezone", value);
          setTimeout(() => {
            submitForm();
          }, 100);
        }}
      />
    </div>
  );
};

export default TimezoneInput;
