import React from "react";

import { Label } from "@/components/Typography";

import { StyledContainer } from "./Tag.styles";
import { ITagProps } from "./Tag.types";
import { DEFAULT_COLOR_VARIANT, DEFAULT_VARIANT } from "./Tag.config";

const Tag: React.FC<ITagProps> = ({
  children,
  variant = DEFAULT_VARIANT,
  colorVariant = DEFAULT_COLOR_VARIANT,
  className,
  style,
  dotVariant,
}) => {
  return (
    <StyledContainer
      $variant={variant}
      $colorVariant={colorVariant}
      $dotVariant={dotVariant}
      className={className}
      style={style}
    >
      {dotVariant ? <Label size="sm">{children}</Label> : children}
    </StyledContainer>
  );
};

export default Tag;
