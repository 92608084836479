import { useCallback, useState } from "react";
import lodashSet from "lodash/set";

import { userService } from "@/config/services";

import { UpdateUserProfileParams, UserProfile } from "../UserService.types";
import useProfileData from "./useProfileData";
import { sanitizeProfileData } from "../UserService.utils";

function useUpdateProfile() {
  const { profileData, isProfileDataLoading, refresh, accountData } =
    useProfileData();
  const [isUpdating, setIsUpdating] = useState(false);

  const update = useCallback(
    (
      profileData: UserProfile,
      { accountData = {}, notify }: UpdateUserProfileParams = {}
    ) => {
      if (isUpdating) {
        return Promise.reject();
      }

      setIsUpdating(true);

      return userService
        .updateUserInfo(
          {
            details: JSON.stringify(sanitizeProfileData(profileData)),
            ...accountData,
          },
          {
            notify,
          }
        )
        .then(() => {
          refresh();
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [isUpdating, refresh]
  );

  const updateField = useCallback(
    (key: string, value: any, params?: UpdateUserProfileParams) => {
      let updated = { ...profileData };
      updated = lodashSet(updated, key, value);
      return update(updated, params);
    },
    [update, profileData]
  );

  const updateFields = useCallback(
    (profileData_: Partial<UserProfile>, params?: UpdateUserProfileParams) => {
      let updated = { ...profileData, ...profileData_ };
      return update(updated, params);
    },
    [update, profileData]
  );

  return {
    profileData,
    isProfileDataLoading,
    update,
    isUpdating,
    updateField,
    updateFields,
    accountData,
  };
}

export default useUpdateProfile;
