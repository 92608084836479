import React, { useEffect, useRef } from "react";

import Button from "@/components/input/Button";

import { useProfileForm } from "../../ProfileForm.config";

const SaveButton: React.FC<{ className?: string }> = ({ className }) => {
  const {
    submitForm,
    isUpdatingProfileData,
    values,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  } = useProfileForm();

  const skippedInitialValueRef = useRef(false);

  useEffect(() => {
    if (!skippedInitialValueRef.current) {
      skippedInitialValueRef.current = true;
      return;
    }

    setHasUnsavedChanges(true);
  }, [values, setHasUnsavedChanges]);

  return (
    <Button
      className={className}
      onClick={submitForm}
      disabled={!hasUnsavedChanges || isUpdatingProfileData}
    >
      {isUpdatingProfileData ? "Saving" : "Save"}
    </Button>
  );
};

export default SaveButton;
