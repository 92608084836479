"use client";

import Tag from "@/components/misc/Tag";
import { overflowEllipsis, screenLargerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledHeaderTitle = styled.div`
  ${overflowEllipsis()};
  width: calc(100% - 4rem);
  margin-inline: auto;
`;

export const StyledPage = styled.div`
  padding: 1rem;

  hr {
    opacity: 0.75;
  }

  ${screenLargerThan.widescreen} {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
    height: 100%;
    overflow: hidden;

    & > * {
      padding: 2rem;
    }

    & > *:first-child {
      min-width: 20rem;
      width: 20rem;
    }

    & > *:last-child {
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
    }
  }
`;

export const StyledUserSummarySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const StyledTag = styled(Tag)`
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  overflow: auto;
  gap: 1rem;
`;
