import useSWR from "swr";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";

import { userService } from "@/config/services";

const validatePaypipeIdLocally = (id: string) => {
  const errors: string[] = [];
  // if (id.length < 10) {
  //   errors.push("ID must be more than 10 characters");
  // }

  if (id.length > 20) {
    errors.push("ID must be less than 20 characters");
  }

  const invalidChar = id.split("").find((char) => {
    const regex = /[a-zA-Z0-9-_]/g;
    return !char.match(regex);
  });
  if (invalidChar) {
    errors.push(`Found invalid character "${invalidChar}" in ID`);
  }

  return {
    isValid: !errors.length,
    errors,
  };
};

const validatePaypipeIdFromApi = (id: string) =>
  userService.isPaypipeIdValid(id).then((res) => res.data.data);

const validatePaypipeId = async (id: string) => {
  const validationData = validatePaypipeIdLocally(id);
  if (!validationData.isValid) {
    return Promise.resolve(validationData);
  }

  return await validatePaypipeIdFromApi(id);
};

const DEFAULT_DATA = {
  errors: [] as string[],
  isValid: false,
};

function useIsValidPaypipeId({ id: idFromProps }: { id: string }) {
  const [id, setId] = useState("");

  const debouncedSetId = useMemo(() => debounce(setId, 500), []);

  const { data = DEFAULT_DATA, isLoading } = useSWR(
    id ? `user/validate-paypipe-id/${id}` : null,
    () => validatePaypipeId(id),
    {
      errorRetryInterval: 0,
      errorRetryCount: 5,
      dedupingInterval: 0,
      fallbackData: DEFAULT_DATA,
    }
  );

  useEffect(() => {
    debouncedSetId(idFromProps);
  }, [idFromProps, debouncedSetId]);

  return {
    data,
    isValidating: isLoading,
  };
}

export default useIsValidPaypipeId;
