import React from "react";

import { IButtonProps } from "@/components/input/Button";
import { ContractCompleteDetails } from "@/services/ContractsService";

import {
  StyledFeaturesSection,
  StyledContainer,
  StyledTopSection,
  StyledCtaButton,
  StyledCtaSection,
  StyledBottomSection,
  StyledLogo,
  StyledIllustrationBubble,
} from "./LandingScreen.styles";
import FeatureCards from "../FeatureCards";
import ContractDetails from "../ContractDetails";

const LandingScreen: React.FC<{
  ctaButtonProps: IButtonProps;
  contractDetails: ContractCompleteDetails;
}> = ({ ctaButtonProps, contractDetails }) => {
  return (
    <StyledContainer>
      <div>
        <StyledTopSection>
          <StyledLogo redirectToMainSite />
          <StyledIllustrationBubble src="/assets/images/pages/contracts/landing/bg_part.svg" />
        </StyledTopSection>
        <StyledBottomSection>
          <StyledFeaturesSection>
            <ContractDetails
              className="mt-2"
              contractDetails={contractDetails}
            />

            <hr />

            <FeatureCards className="mt-3" />
          </StyledFeaturesSection>

          <StyledCtaSection>
            <StyledCtaButton
              colorVariant="gradient-primary"
              {...ctaButtonProps}
            >
              View Offer
            </StyledCtaButton>
          </StyledCtaSection>
        </StyledBottomSection>
      </div>
    </StyledContainer>
  );
};

export default LandingScreen;
