import { Message, ReadStatus } from "@/features/chat/ChatRoom";
import { ContractStatus } from "@/services/ContractsService";

export const SHOW_DUMMY_CHAT = false;

export const MESSAGES: Message[] = [
  {
    userId: 1,
    content: "Hi there!",
    date: new Date(),
    readStatus: ReadStatus.Read,
  },
  {
    userId: 2,
    content: "Howdy, is there how is my project coming along?",
    date: new Date(),
    readStatus: ReadStatus.Read,
  },
  {
    userId: 2,
    content: "Just let me know!",
    date: new Date(),
    readStatus: ReadStatus.Read,
  },
  {
    userId: 1,
    content: "I need some assistance.",
    date: new Date(),
    files: [
      {
        name: "1",
        url: "https://plus.unsplash.com/premium_photo-1667480556783-119d25e19d6e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        name: "2",
        url: "https://images.unsplash.com/photo-1486020425824-a3da4762c3ca?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    ],
    readStatus: ReadStatus.Sent,
  },
  {
    userId: 1,
    content: "Help me to choose which to go with",
    date: new Date(),
    readStatus: ReadStatus.Sent,
  },
];

export const COLORS = {
  BLUE: "var(--clr-primary-100, #5f57ff)",
  ORANGE: "#D08824",
  RED: "#EA5B5B",
  GREEN: "#1DBF73",
  YELLOW: "#FFBE4C",
};

export const STATUS_CONFIG: Record<any, { color: string; label: string }> = {
  [ContractStatus.Published]: {
    color: COLORS.YELLOW,
    label: "Pending Acceptance",
  },
  [ContractStatus.PendingFunding]: {
    color: COLORS.YELLOW,
    label: "Pending Funding",
  },
  [ContractStatus.Archive]: {
    color: COLORS.YELLOW,
    label: "Archive",
  },
  [ContractStatus.Paused]: {
    color: COLORS.YELLOW,
    label: "Paused",
  },
  //-------------------------------
  [ContractStatus.Funded]: {
    color: COLORS.GREEN,
    label: "Paid",
  },
  [ContractStatus.Review]: {
    color: COLORS.GREEN,
    label: "Review",
  },
  [ContractStatus.Approved]: {
    color: COLORS.GREEN,
    label: "Paid",
  },
  [ContractStatus.Accepted]: {
    color: COLORS.GREEN,
    label: "In Progress",
  },
  [ContractStatus.Active]: {
    color: COLORS.GREEN,
    label: "In Progress",
  },
  [ContractStatus.Completed]: {
    color: COLORS.GREEN,
    label: "Completed",
  },
  //-------------------------------
  [ContractStatus.Dispute]: {
    color: COLORS.RED,
    label: "Disputed",
  },
  [ContractStatus.Reject]: {
    color: COLORS.RED,
    label: "Rejected",
  },
};


export const CHAT_DETAILS_HASH = '#chat-details';
export const CONTRACT_DETAILS_HASH = '#job-details';


export const LOCK_CHAT_THRESHOLD = 60 * 60 * 24 * 10  //10 days;
