import { useCallback, useState } from "react";

import { contractService } from "@/config/services";
import { useToast } from "@/components/misc/Toast";
import { useAuth } from "@/services/Authentication";

import useContractDetails from "./useContractDetails";

function useFundContractMilestone(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId } = params;
  const [loading, setLoading] = useState(false);
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const { createToast } = useToast();
  const { userData } = useAuth();

  const loggedInUserEmail = userData?.email || "";

  //------------------------------------------

  const fund = useCallback(() => {
    if (loading) {
      return Promise.reject();
    }

    setLoading(true);

    const { contractId, milestoneId } = params;

    return contractService
      .fundContractMilestone(contractId, milestoneId, {
        email: loggedInUserEmail,
      })
      .catch(() => {
        createToast({
          title: "Funding failed",
          description:
            "Something went wrong, We are having trouble processing the funding currently.",
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        reloadContractDetails();
      });
  }, [params, loading, reloadContractDetails, createToast, loggedInUserEmail]);

  return {
    fund,
    loading,
  };
}

export default useFundContractMilestone;
