import React, { useMemo } from "react";

import Icon from "@/components/misc/Icon";
import { useModalState } from "@/components/misc/Modal";
import FilesInput, { FileObject } from "@/features/input/FilesInput";
import { MediaFile, useFileList } from "@/services/MediaService";
import { getFileExtentionFromName } from "@/utils/files";

import {
  StyledButtonSmall,
  StyledFilesContainer,
  StyledMediaFileTile,
  StyledPrimaryHeading,
  StyledSectionHeadingContainer,
} from "../ChatRoomPage.styles";
import { useChatRoomPage } from "../ChatRoomPage.context";
import FilesDisplayLoading from "./FilesDisplay.loading";

const FilesDisplay: React.FC = () => {
  const { contractDetails } = useChatRoomPage();

  const allFilesModalState = useModalState();

  const { data: filesList, isLoading } = useFileList({
    contract_ids: contractDetails ? [contractDetails.id] : [],
  });

  const { imageFiles, otherFiles } = useMemo(() => {
    const imageFiles: MediaFile[] = [];
    const otherFiles: MediaFile[] = [];

    filesList.forEach((f) => {
      const extention = getFileExtentionFromName(f.url);
      const isImageFile = ["png", "jpeg", "jpg"].includes(extention || "");

      if (isImageFile) {
        imageFiles.push(f);
      } else {
        otherFiles.push(f);
      }
    });

    return {
      imageFiles,
      otherFiles,
    };
  }, [filesList]);

  const filesObjects: FileObject[] = useMemo(
    () => otherFiles.map((file) => ({ mediaFile: file } satisfies FileObject)),
    [otherFiles]
  );

  if (isLoading) {
    return <FilesDisplayLoading />;
  }

  return (
    <>
      {!!imageFiles.length && (
        <section>
          <StyledSectionHeadingContainer>
            <StyledPrimaryHeading>Photos</StyledPrimaryHeading>

            {imageFiles.length > 6 && (
              <StyledButtonSmall variant="ghost">
                View all
                <Icon isSrcRelative src="chevron_right.svg" customSize="10px" />
              </StyledButtonSmall>
            )}
          </StyledSectionHeadingContainer>
          <StyledFilesContainer className="mt-3">
            {imageFiles.map((file) => (
              <StyledMediaFileTile key={file.id} mediaId={file.id} />
            ))}
          </StyledFilesContainer>
        </section>
      )}

      {!!otherFiles.length && (
        <section className="mt-5">
          <StyledSectionHeadingContainer>
            <StyledPrimaryHeading>Shared files</StyledPrimaryHeading>

            {otherFiles.length > 6 && (
              <StyledButtonSmall
                variant="ghost"
                onClick={allFilesModalState.open}
              >
                View all
                <Icon isSrcRelative src="chevron_right.svg" customSize="10px" />
              </StyledButtonSmall>
            )}
          </StyledSectionHeadingContainer>

          <FilesInput
            viewMode
            showInput={false}
            className="mt-3"
            value={filesObjects}
            label="Attached files"
            displayFileCount={2}
            allFilesModelState={allFilesModalState}
          />
        </section>
      )}
    </>
  );
};

export default FilesDisplay;
