"use client";

import styled from "@emotion/styled";

import ContractForm from "@/features/contracts/ContractForm";
import { screenLargerThan, screenSmallerThan } from "@/styles";

export const StyledContractForm = styled(ContractForm)`
  ${screenSmallerThan.tablet} {
    height: 100%;
  }

  ${screenLargerThan.tablet} {
    max-width: 46.5rem;
    margin-left: 5rem;
  }
`;
