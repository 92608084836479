"use client";

import React from "react";

import { appFeatures } from "@/config/services";

import AboutMeInput from "./inputs/AboutMeInput";
import ProfessionInput from "./inputs/ProfessionInput";
import ExperienceInput from "./inputs/ExperienceInput/ExperienceInput";
import SkillInput from "./inputs/SkillInput";

const AboutSection: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <AboutMeInput />

      {appFeatures.isSupported("ACCOUNT.PROFESSIONS") && (
        <ProfessionInput className="mt-4" />
      )}

      {appFeatures.isSupported("ACCOUNT.EXPERIENCE") && (
        <ExperienceInput className="mt-4" />
      )}

      <SkillInput className="mt-4" />
    </div>
  );
};

export default AboutSection;
