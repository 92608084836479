import React from "react";

import { IButtonProps } from "@/components/input/Button";
import { ContractCompleteDetails } from "@/services/ContractsService";
import { useResponsive } from "@/styles";

import LandingScreenDesktop from "./LandingScreen.desktop";
import LandingScreenMobile from "./LandingScreen.mobile";

const LandingScreen: React.FC<{
  ctaButtonProps: IButtonProps;
  contractDetails: ContractCompleteDetails;
}> = ({ ctaButtonProps, contractDetails }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  if (isScreenSmallerThanTablet) {
    return (
      <LandingScreenMobile
        ctaButtonProps={ctaButtonProps}
        contractDetails={contractDetails}
      />
    );
  }

  return (
    <LandingScreenDesktop
      ctaButtonProps={ctaButtonProps}
      contractDetails={contractDetails}
    />
  );
};

export default LandingScreen;
