"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--clr-text-500, #666d80);
  letter-spacing: -0.02em;

  b {
    font-weight: 600;
    color: var(--clr-text-900, #0d0d12);
  }
`;
