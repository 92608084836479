"use client";

import React, { useMemo } from "react";

import { SITE_PATHS } from "@/config/routing";
import Button from "@/components/input/Button";
import {
  useSubscriptionDetails,
  useSubscriptionRedirect,
} from "@/services/UserService";
import { Body } from "@/components/Typography";
import SplashScreen from "@/features/pages/app/SplashScreen";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import { formatDate } from "@/utils/date";

import { StyledSucessPageContainer } from "./SubscriptionSuccess.styles";

const SubscriptionSuccess: React.FC = () => {
  const { loadingSubscriptionDetails, subscriptionDetails } =
    useSubscriptionDetails();
  const { subscriptionSucceeded } = useSubscriptionRedirect();

  const subscriptionEndDateFormatted = useMemo(
    () =>
      subscriptionDetails?.subscriptionEndDate
        ? formatDate(subscriptionDetails.subscriptionEndDate, "DD MMM YY")
        : "",
    [subscriptionDetails]
  );

  if (loadingSubscriptionDetails) {
    return <SplashScreen />;
  }

  const trialContent = (
    <>
      <ImageTitleContent
        className="content"
        title="Premium activated"
        content={
          <>
            <Body size="md">
              Welcome to the Premium club. You've unlocked lower fees, priority
              support and exclusive first access.
            </Body>

            <Body size="md" className="mt-2">
              Your free trial ends on: {subscriptionEndDateFormatted}
            </Body>
          </>
        }
        image="/assets/images/pages/contracts/payment_success.svg"
      />

      <div className="cta-section">
        <Button className="w-100" link={SITE_PATHS.HOME_PAGE}>
          Home
        </Button>
      </div>
    </>
  );

  const monthlyContent = (
    <>
      <ImageTitleContent
        className="content"
        title="Monthly Billing Confirmed"
        content={
          <>
            <Body size="md">
              You've locked your subscription in for the next 1 month and saved!
            </Body>

            {!!subscriptionEndDateFormatted && (
              <Body size="md" className="mt-2">
                Your subscription will renew on {subscriptionEndDateFormatted}
              </Body>
            )}
          </>
        }
        image="/assets/images/pages/contracts/payment_success.svg"
      />

      <div className="cta-section">
        <Button className="w-100" link={SITE_PATHS.HOME_PAGE}>
          Home
        </Button>
      </div>
    </>
  );

  const yearlyContent = (
    <>
      <ImageTitleContent
        className="content"
        title="Annual Billing Confirmed"
        content={
          <>
            <Body size="md">
              You've locked your subscription in for the next 12 months and
              saved!
            </Body>

            {!!subscriptionEndDateFormatted && (
              <Body size="md" className="mt-2">
                Your next subscription payment will be billed on:
                {subscriptionEndDateFormatted}
              </Body>
            )}
          </>
        }
        image="/assets/images/pages/contracts/payment_success.svg"
      />

      <div className="cta-section">
        <Button className="w-100" link={SITE_PATHS.HOME_PAGE}>
          Home
        </Button>
      </div>
    </>
  );

  const defaultContent = (
    <>
      <ImageTitleContent
        className="content"
        title="Annual Billing Confirmed"
        content={
          <>
            <Body size="md">You've locked your subscription and saved!</Body>

            {!!subscriptionEndDateFormatted && (
              <Body size="md" className="mt-2">
                Your next subscription payment will be billed on:
                {subscriptionEndDateFormatted}
              </Body>
            )}
          </>
        }
        image="/assets/images/pages/contracts/payment_success.svg"
      />

      <div className="cta-section">
        <Button className="w-100" link={SITE_PATHS.HOME_PAGE}>
          Home
        </Button>
      </div>
    </>
  );

  const content = subscriptionDetails?.isTrial
    ? trialContent
    : subscriptionDetails?.isMonthlySubscription
    ? monthlyContent
    : subscriptionDetails?.isYearlySubscription
    ? yearlyContent
    : defaultContent;

  if (subscriptionSucceeded && content) {
    return <StyledSucessPageContainer>{content}</StyledSucessPageContainer>;
  }

  return null;
};

export default SubscriptionSuccess;
