import { useCallback, useEffect } from "react";
import useSWR from "swr";

import { contractService } from "@/config/services";
import { useAppSelector, selectContract } from "@/services/Store";
import { SITE_PATHS } from "@/config/routing";
import { useAuth } from "@/services/Authentication";
import { useNavigate } from "@/services/Routing";

import useContractUrlId from "./useContractUrlId";
import { isContractIdValid } from "../utils";

export type Params = {
  contractId?: string;
  useContractIdFromUrl?: boolean;
  redirectIfNotFound?: boolean;
  useAuthToken?: boolean;
  skipFetchIfExists?: boolean;
};

function useContractDetails(params: Params = {}) {
  const { isAuthenticating } = useAuth();
  const {
    contractId: contractIdFromParams = "",
    useContractIdFromUrl = false,
    redirectIfNotFound = false,
    useAuthToken,
    skipFetchIfExists,
  } = params;

  const { navigate } = useNavigate();
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();
  const contractId = useContractIdFromUrl
    ? contractIdFromUrl
    : contractIdFromParams;

  const contractDetails = useAppSelector(selectContract(contractId)) || null;

  const { error, isLoading, mutate } = useSWR(
    skipFetchIfExists && !!contractDetails && isContractIdValid(contractId)
      ? null
      : !isAuthenticating && !!contractId
      ? `/contract/${contractId}`
      : null,
    () => contractService.getContractByID(contractId, useAuthToken)
  );

  const isUpdating = !!contractDetails && isLoading;
  const notFound = !contractDetails && !isLoading;

  //-----------------------

  const reload = useCallback(() => {
    mutate();
  }, [mutate]);

  //-----------------------

  useEffect(() => {
    if (!redirectIfNotFound || isAuthenticating) {
      return;
    }

    if (!contractId || notFound) {
      navigate(SITE_PATHS.CONTRACTS_PAGE, true);
    }
  }, [navigate, contractId, notFound, redirectIfNotFound, isAuthenticating]);

  //-----------------------

  return {
    contractDetails,
    error,
    isLoading,
    isUpdating,
    notFound,
    reload,
    contractId,
  };
}

export default useContractDetails;
