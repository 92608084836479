import React from "react";

import { StyledTable, StyledTr } from "./DetailsTable.styles";
import { IDetailsTableProps } from "./DetailsTable.types";

const DetailsTable: React.FC<IDetailsTableProps> = ({ items, className }) => {
  if (!items.length) {
    return null;
  }

  return (
    <StyledTable className={className}>
      <tbody>
        {items.map(
          (
            { label, value, valueProps = {}, labelProps = {}, border = true },
            i
          ) => (
            <StyledTr key={i} $border={border}>
              <td {...labelProps}>{label}</td>
              <td {...valueProps} style={valueProps?.style}>
                {value}
              </td>
            </StyledTr>
          )
        )}
      </tbody>
    </StyledTable>
  );
};

export default DetailsTable;
