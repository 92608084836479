"use client";

import styled from "@emotion/styled";

import ActionCard from "@/components/misc/ActionCard";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 2rem 1rem;
  height: 100%;
  overflow: auto;
`;

export const StyledActionCard = styled(ActionCard)`
  padding-inline: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
`;

export const StyledCardsContainer = styled.div`
  & > * {
    border-radius: 0 !important;
    border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9) !important;
    padding-inline: 0 !important;
  }
`;
