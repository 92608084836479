import React, { useMemo } from "react";

import { SITE_PATHS } from "@/config/routing";
import Icon from "@/components/misc/Icon";
import { Label } from "@/components/Typography";
import {
  ContractStatus,
  isContractCompleted,
  useContractList,
} from "@/services/ContractsService";
import { padText } from "@/utils/string";

import {
  StyledContainer,
  StyledField,
  StyledFieldsSection,
  StyledLabel,
  StyledLink,
  StyledComparisonSection,
  StyledTag,
} from "./ContractsOverview.styles";
import { IContractsOverviewProps } from "./ContractsOverview.types";
import { STATUS_LIST } from "./ContractsOverview.config";

const ContractsOverview: React.FC<IContractsOverviewProps> = ({
  className,
}) => {
  const { data: contractList } = useContractList({
    onlyJobs: true,
  });

  const statusList = useMemo(() => {
    const completedContractsCount = contractList.filter((contract) =>
      isContractCompleted(contract)
    ).length;
    const inProgressContractsCount =
      contractList.length - completedContractsCount;

    const statusList = STATUS_LIST.filter(({ show }) => show).map(
      (data: any) => {
        if (data.status === ContractStatus.Active) {
          data.count = inProgressContractsCount;
        }

        if (data.status === ContractStatus.Completed) {
          data.count = completedContractsCount;
        }

        return data;
      }
    );

    return statusList;
  }, [contractList]);

  return (
    <StyledContainer className={className}>
      {!!contractList.length && (
        <StyledComparisonSection>
          <StyledTag colorVariant="success">
            <Icon
              isSrcRelative
              src="arrow_up.svg"
              size="xxs"
              customSize="0.625rem"
            />
            {contractList.length * 100}%
          </StyledTag>
          vs last Quarter
        </StyledComparisonSection>
      )}

      <StyledFieldsSection>
        {statusList.map(({ status, label, show, count }) => {
          if (!show) {
            return null;
          }

          return (
            <StyledField key={status} $status={status}>
              <StyledLabel $status={status}>{label}</StyledLabel>
              <Label size="md">{count ? padText(count, 2, "0") : "-"}</Label>
            </StyledField>
          );
        })}
      </StyledFieldsSection>

      <StyledLink to={SITE_PATHS.CONTRACTS_PAGE}>
        View all
        <Icon
          isSrcRelative
          customSize="0.75rem"
          src="chevron_right.svg"
          colorVariant="black"
        />
      </StyledLink>
    </StyledContainer>
  );
};

export default ContractsOverview;
