import { createContext, useContext, useState } from "react";

import { useContractDetails } from "@/services/ContractsService";

import {
  IContractManagementContext,
  IContractManagementPageProps,
  Tab,
} from "./ContractManagementPage.types";
import { ContractPreviewLoading } from "@/features/contracts/ContractPreview";
import { useResponsive } from "@/styles";
import SplashScreen from "@/features/pages/app/SplashScreen";

const ContractManagementContext = createContext(
  {} as IContractManagementContext
);

export const useContractManagement = () =>
  useContext(ContractManagementContext);

export const ContractManagementProvider: React.FC<
  IContractManagementPageProps & { children?: React.ReactNode }
> = ({ children, layoutProps }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { contractDetails, isLoading } = useContractDetails({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
  });

  const [activeTab, setActiveTab] = useState<string>(Tab.Overview);

  if (!contractDetails || isLoading) {
    return isScreenSmallerThanTablet ? (
      <SplashScreen />
    ) : (
      <ContractPreviewLoading />
    );
  }

  return (
    <ContractManagementContext.Provider
      value={{ contractDetails, activeTab, setActiveTab, layoutProps }}
    >
      {children}
    </ContractManagementContext.Provider>
  );
};
