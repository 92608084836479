import useSWR from "swr";

import { userService } from "@/config/services";

function useSubscriptionPlans() {
  const { data = null, isLoading } = useSWR("/subscription/plans", () =>
    userService.getSubscriptionPlans().then((res) => res.data.data)
  );

  return {
    isLoading,
    data,
  };
}

export default useSubscriptionPlans;
