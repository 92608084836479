import { ContractStatus } from "@/services/ContractsService";

export const COLORS = {
  ORANGE: "#FFBE4C",
  RED: "#EA5B5B",
  GREEN: "#1DBF73",
};

export const STATUS_CONFIG: Record<
  any,
  { color: string; label: string; show?: boolean }
> = {
  [ContractStatus.Active]: {
    color: COLORS.ORANGE,
    label: "In progress",
    show: true,
  },
  [ContractStatus.Pending]: {
    color: COLORS.ORANGE,
    label: "Pending",
    // show: true,
  },
  [ContractStatus.Review]: {
    color: COLORS.ORANGE,
    label: "Review",
    // show: true,
  },
  [ContractStatus.Completed]: {
    color: COLORS.GREEN,
    label: "Completed",
    show: true,
  },
  [ContractStatus.Dispute]: {
    color: COLORS.RED,
    label: "Disputed",
    // show: true,
  },
  [ContractStatus.Accepted]: {
    color: COLORS.GREEN,
    label: "In progress",
  },
  [ContractStatus.Archive]: {
    color: "",
    label: "",
  },
  [ContractStatus.Paused]: {
    color: "",
    label: "",
  },
  [ContractStatus.Approved]: {
    color: "",
    label: "",
  },
  [ContractStatus.Reject]: {
    color: "",
    label: "",
  },
  [ContractStatus.Funded]: {
    color: "",
    label: "",
  },
};

export const STATUS_LIST = Object.entries(STATUS_CONFIG).map(
  ([status, config]) => ({
    status: status as ContractStatus,
    ...config,
  })
);
