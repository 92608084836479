import { IFormContext } from "@/components/input/Form";
import { FileObject } from "@/features/input/FilesInput";
import {
  ContractCreationFlow,
  ContractCreatePayload,
  ContractCompleteDetails,
} from "@/services/ContractsService";

export enum Step {
  ContractCreator = "ContractCreator",
  ContractType = "ContractType",
  ContractDetails = "ContractDetails",
  Review = "Review",
  PayoutOnboarding = "PayoutOnboarding",
}

export type ContractFormValues = ContractCreatePayload & {
  files: FileObject[];
  flow?: ContractCreationFlow;
  client_paypipe_id?: string;
};

export interface IContractFormProps {
  getFormRef?: (ref: ContractFormRef) => void;
  onStepChange?: (step: Step) => void;
  initialValues?: ContractFormValues;
  className?: string;
  onChange?: (values: ContractFormValues) => void;
}

export interface IContractFormContext extends IFormContext<ContractFormValues> {
  currentStep: Step;
  clientInputType: ClientInputType;
  setClientInputType: React.Dispatch<React.SetStateAction<ClientInputType>>;
  createdContractDetails: null | ContractCompleteDetails;
  checkIsStepCompleted: (step: Step) => boolean;
  gotToStep: (step: Step) => void;
  previousStep: () => void;
  nextStep: () => void;
  isLastStep: boolean;
  creatingContract: boolean;
}

export type ContractFormRef = {
  previousStep: () => void;
  nextStep: () => void;
};

export enum ClientInputType {
  PaypipeId = "PaypipeId",
  Email = "Email",
}
