"use client";

import React, { useMemo } from "react";

import Card from "@/components/data/Card";
import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import {
  filterOutBrokenContracts,
  useContractList,
  useContractUrlId,
} from "@/services/ContractsService";
import ContractPage from "@/features/pages/contract/ContractPage";
import { useParamState } from "@/services/Routing";

import {
  StyledContractCard,
  StyledContractCardLoading,
  StyledNoOffers,
  StyledPage,
} from "./OfferListPage.styles";

const OfferListPage: React.FC = () => {
  const [showAll] = useParamState("all", false);
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();

  const { data: contractList, isLoading } = useContractList({
    onlyOffers: !showAll,
  });

  const filteredContractlist = useMemo(
    () => filterOutBrokenContracts(contractList),
    [contractList]
  );

  const hasNoData = !filteredContractlist.length;

  if (contractIdFromUrl) {
    return <ContractPage />;
  }

  return (
    <MobilePrimaryLayout headerProps={{ logo: { component: "Offers" } }}>
      <StyledPage className="container">
        {isLoading ? (
          <Card>
            <StyledContractCardLoading />
            <StyledContractCardLoading />
            <StyledContractCardLoading />
          </Card>
        ) : hasNoData ? (
          <StyledNoOffers />
        ) : (
          <Card>
            {filteredContractlist.map((contract) => (
              <StyledContractCard key={contract.id} contract={contract} />
            ))}
          </Card>
        )}
      </StyledPage>
    </MobilePrimaryLayout>
  );
};

export default OfferListPage;
