import React from "react";

import Drawer, { IDrawerProps } from "@/components/misc/Drawer";
import ContractCreatorInput from "@/features/contracts/ContractCreatorSwitcher";
import {
  ContractCreatorType,
  getContractCreateUrl,
} from "@/services/ContractsService";
import { useNotifyForFeature } from "@/services/AppFeatures";

import {
  StyledBody,
  StyledButton,
  StyledContainer,
  StyledGradientButton,
  StyledHeading,
  StyledIcon,
} from "./CreateContractDrawer.styles";
import { CONTRACT_OPTIONS } from "./CreateContractDrawer.config";

const CreateContractDrawer: React.FC<Omit<IDrawerProps, "children">> = (
  props
) => {
  const { modalState: notifyModalState, jsx: notifyJsx } = useNotifyForFeature({
    featureName: "GENERATE_WITH_AI",
  });

  return (
    <>
      {notifyJsx}

      <Drawer {...props}>
        <StyledContainer>
          {false && <ContractCreatorInput />}

          <div className="px-3">
            <StyledHeading size="lg" className="mt-3">
              Create an offer
            </StyledHeading>
            <StyledBody size="md" className="mb-4">
              Choose the offer type that works for you
            </StyledBody>

            {CONTRACT_OPTIONS.map(({ title, value, disabled = false }) => (
              <StyledButton
                disabled={disabled}
                key={value}
                link={getContractCreateUrl({
                  flow: value,
                  creatorType: ContractCreatorType.Payee,
                })}
              >
                {title}
              </StyledButton>
            ))}

            <StyledGradientButton
              className="mt-4"
              onClick={notifyModalState.open}
            >
              <StyledIcon
                isSrcRelative
                src="star_4_p.svg"
                size="xs"
                colorVariant="black"
              />
              Generate with AI
            </StyledGradientButton>
          </div>
        </StyledContainer>
      </Drawer>
    </>
  );
};

export default CreateContractDrawer;
