"use client";

import styled from "@emotion/styled";
import lodashGet from "lodash/get";

import { squareSizing } from "@/utils/css";
import {
  ContractMilestoneStatus,
  ContractStatus,
} from "@/services/ContractsService";

import {
  COLORS,
  MILESTONE_STATUS_CONFIG,
  STATUS_CONFIG,
} from "./ContractStatus.config";

export const StyledContainer = styled.div<{
  $status: ContractStatus;
  $milestoneStatus?: ContractMilestoneStatus;
}>`
  display: inline-block;
  position: relative;
  font-size: 0.75rem;
  color: var(--clr-icon-text-200, #818898);
  padding: 0.5rem 1rem;
  padding-left: 2rem;
  border-radius: 10rem;
  border: 1px solid var(--clr-border-25, #f6f8fa);
  min-width: max-content;

  & > * {
    line-height: unset !important;
  }

  &:before {
    ${squareSizing("0.75rem")};
    content: "";
    position: absolute;
    top: 50%;
    left: 0.75rem;
    background: ${({ $status, $milestoneStatus }) => {
      const color =
        lodashGet(MILESTONE_STATUS_CONFIG, `[${$milestoneStatus}].color`, "") ||
        lodashGet(STATUS_CONFIG, `[${$status}].color`, COLORS.BLUE);
      return color;
    }};
    border-radius: 10rem;
    transform: translateY(-50%);
  }
`;
