"use client";

import { Label } from "@/components/Typography";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 1rem 1rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledImage = styled.img`
  width: 167px;
  height: auto;
  margin: 0 auto;
`;

export const StyledCount = styled(Label)`
  ${squareSizing("1.5rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--clr-primary-100, #5F57FF);
  color: var(--clr-text-white, #fff);
  border-radius: 10rem;
`;

export const StyledSmall = styled.small`
  display: block;
  font-size: 0.625rem;
  font-weight: 500;
  color: var(--clr-ukwn-3, #A4ACB9);
`;
