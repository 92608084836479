"use client";

import React from "react";

import { MediaFile, Package } from "@/services/UserService";
import { Body, Heading } from "@/components/Typography";
import { useFileSignedUrl } from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";

import {
  StyledContainer,
  StyledFlex,
  StyledImage,
  StyledLink,
  StyledSlider,
  StyledWysiwyg,
} from "./PackageCard.styles";

const PackageImage: React.FC<{
  file: MediaFile;
  className?: string;
}> = ({ file, className }) => {
  const { url } = useFileSignedUrl(file?.url || "");

  return (
    <StyledImage
      className={className}
      src={url}
      alt=""
      width={24}
      height={24}
    />
  );
};

const Slider: React.FC<{
  file: MediaFile;
  className?: string;
}> = ({ file, className }) => {
  const { url } = useFileSignedUrl(file?.url || "");

  return (
    <StyledSlider
      className={className}
      src={url}
      alt=""
      width={24}
      height={24}
    />
  );
};

const PackageCard: React.FC<{
  package: Package;
  className?: string;
  showSlider?: boolean;
  useLink?: boolean;
}> = ({ package: packageDetails, showSlider, className, useLink = true }) => {
  const {
    name,
    description,
    attachments = [],
    price,
    estimated_duration,
    estimated_duration_unit,
  } = packageDetails;
  const firstImage = attachments.length ? attachments[0] : null;

  const usePluralUnit = estimated_duration > 1;
  const durationUnit = estimated_duration_unit
    ? usePluralUnit
      ? estimated_duration_unit
      : estimated_duration_unit.substring(0, estimated_duration_unit.length - 1)
    : "";

  return (
    <StyledContainer className={className}>
      {useLink && <StyledLink to={`${SITE_PATHS.PROFILE_PAGE}/services/0`} />}

      {!!firstImage &&
        (showSlider ? (
          <Slider file={firstImage} />
        ) : (
          <PackageImage file={firstImage} />
        ))}

      <Heading size="md" className="mt-3">
        {name}
      </Heading>

      {description && (
        <Body
          size="md"
          className="mt-2"
          style={{ color: "var(--clr-icon-400, #818898)" }}
        >
          <StyledWysiwyg readOnly value={{ markdown: description }} />
        </Body>
      )}

      <StyledFlex className="mt-3">
        <div>
          <Body size="md" style={{ color: "var(--clr-icon-400, #818898)" }}>
            Price:
          </Body>
          <Heading size="sm" className="mt-1">
            ${price}
          </Heading>
        </div>

        <div>
          <Body size="md" style={{ color: "var(--clr-icon-400, #818898)" }}>
            Time:
          </Body>
          <Heading size="sm" className="mt-1">
            {estimated_duration} {durationUnit}
          </Heading>
        </div>
      </StyledFlex>
    </StyledContainer>
  );
};

export default PackageCard;
