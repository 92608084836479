import React from "react";

import Button from "@/components/input/Button";

import { IDateDisplayProps } from "./DateDisplay.types";
import {
  StyledContainer,
  StyledValue,
  StyledValueContainer,
} from "./DateDisplay.styles";
import Icon from "@/components/misc/Icon";

const DateDisplay: React.FC<IDateDisplayProps> = ({
  label,
  value,
  onClearClick,
  active = false,
}) => {
  return (
    <StyledContainer $active={active}>
      <div>{label}</div>
      <StyledValueContainer $active={active} className="mt-2">
        <StyledValue>{value}</StyledValue>
        {!!value && (
          <Button onClick={onClearClick} variant="ghost">
            <Icon isSrcRelative src="cross_2.svg" customSize="16px" />
          </Button>
        )}
      </StyledValueContainer>
    </StyledContainer>
  );
};

export default DateDisplay;
