import React from "react";

import Button from "@/components/input/Button";

import { analytics } from "@/config/services";

const Analytics: React.FC = () => {
  return (
    <div>
      <Button
        onClick={() => {
          analytics.triggerEvent({ event: "test" });
        }}
      >
        Test G-TAG Event
      </Button>
    </div>
  );
};

export default Analytics;
