import React from "react";

import Button from "@/components/input/Button";

import {
  StyledContainer,
  StyledImage,
  StyledHeading,
  StyledContent,
} from "./DeleteConfirmation.styles";

const DeleteConfirmation: React.FC<{
  title: string;
  description: string;
  onCancel: () => void;
  onDelete: () => void;
}> = ({ title, description, onCancel, onDelete }) => {
  return (
    <StyledContainer>
      <StyledImage src="/assets/images/pages/contracts/cancel.svg" />

      <StyledHeading className="mt-4">{title}</StyledHeading>
      <StyledContent className="mt-2">{description}</StyledContent>

      <Button colorVariant="danger" className="w-100 mt-4" onClick={onDelete}>
        Delete
      </Button>
      <Button
        variant="ghost"
        colorVariant="black"
        className="w-100"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </StyledContainer>
  );
};

export default DeleteConfirmation;
