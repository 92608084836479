import styled from "@emotion/styled";
import ScrollContainer from "react-indiana-drag-scroll";

export const StyledContainer = styled(ScrollContainer)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-block: 0.5rem;
  max-height: 7rem;
  overflow: auto;
`;

export const StyledInput = styled.input`
  flex: 1;
  height: 100%;
  background: transparent;
  min-width: 6rem;
  border: none;
  outline: none;
  font-size: 1rem;
  /* border: 1px dashed red; */

  &:placeholder-shown {
    font-size: inherit;
  }
`;
