import React from "react";

import { StyledContainer, StyledButton } from "./LogoutConfirmation.styles";
import { Label } from "@/components/Typography";
import { useAuth } from "@/services/Authentication";

const LogoutConfirmation: React.FC<{
  onCancel: () => void;
  onLogout: () => void;
}> = ({ onCancel }) => {
  const { logout } = useAuth();

  return (
    <StyledContainer>
      <Label size="lg" className="px-3">
        Are you sure you want to log out?
      </Label>

      <StyledButton
        variant="ghost"
        colorVariant="danger"
        className="mt-4"
        onClick={logout}
      >
        Logout
      </StyledButton>
      <StyledButton variant="ghost" colorVariant="gray" onClick={onCancel}>
        Cancel
      </StyledButton>
    </StyledContainer>
  );
};

export default LogoutConfirmation;
