import styled from "@emotion/styled";

import { screenLargerThan } from "@/styles";

export const StyledTitle = styled.div`
  margin: 0;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: var(--clr-primary-300, #201c6c);
  word-break: break-word;

  ${screenLargerThan.tablet} {
    font-size: 2.5rem;
    line-height: 4rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--clr-primary-300, #201c6c);

  & > * {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    ${screenLargerThan.tablet} {
      gap: 1rem;
    }
  }

  b {
    font-weight: 600;
  }

  ${screenLargerThan.tablet} {
    margin-top: 0.5rem;
    font-size: 1.875rem;
    gap: 1rem;
    line-height: 2.25rem;

    b {
      font-size: 1.75rem;
      font-weight: 600;
    }
  }
`;
