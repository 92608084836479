import React, { useCallback } from "react";
import lodashSet from "lodash/set";
import lodashGet from "lodash/get";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { useToast } from "@/components/misc/Toast";
import { UserProfile, useUpdateProfile } from "@/services/UserService";
import { Heading } from "@/components/Typography";

const StyledContainer = styled.div`
  padding-block: 2rem;

  li {
    display: flex;
    align-items: center;
    gap: 2rem;
    min-height: 60px;
    padding: 0.5rem 1rem;

    & > div {
      flex: 1;
    }

    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const Profile: React.FC = () => {
  const { createToast } = useToast();

  const { profileData, update: updateProfileData } = useUpdateProfile();

  const handleClearData = useCallback(
    ({
      label,
      key,
      dataRootKey = "featuresInterest",
    }: {
      label: string;
      key: string;
      dataRootKey?: string;
    }) => {
      const updatedProfileData = JSON.parse(
        JSON.stringify(profileData)
      ) as UserProfile;

      lodashSet(updatedProfileData, `misc[${dataRootKey}][${key}]`, {});

      return updateProfileData(updatedProfileData, { notify: false })
        .then(() => {
          createToast({
            title: `Cleared feedback data for ${label}`,
            timeoutInMilliseconds: 10000,
          });
        })
        .catch(() => {
          createToast({
            title: `Something went wrong, Cannot clear feedback data for ${label}`,
            timeoutInMilliseconds: 10000,
            variant: "error",
          });
        });
    },
    [createToast, profileData, updateProfileData]
  );

  return (
    <StyledContainer className="container">
      <Heading size="lg">Clear feedback data</Heading>
      <ol className="mt-4">
        {[
          {
            label: "Post offer create and share",
            key: "OFFER_FORM_AND_SHARE_FLOW",
            dataRootKey: "featuresFeedback",
          },
          {
            label: "Paypipe Premium",
            key: "PREMIUM_SUBSCRIPTION",
          },
          {
            label: "Paypipe ID",
            key: "PAYPIPE_ID",
          },
          {
            label: "Create offer as client",
            key: "CREATE_OFFER_AS_CLIENT",
          },
          {
            label: "Hourly offer",
            key: "HOURLY_OFFER",
          },
          {
            label: "Chat invite",
            key: "CHAT_INVITE",
          },
          {
            label: "Generate offer with AI",
            key: "GENERATE_WITH_AI",
          },
        ].map((item, i) => {
          const { label, key, dataRootKey = "featuresInterest" } = item;
          const featureData = lodashGet(
            profileData,
            `misc[${dataRootKey}][${key}]`,
            {}
          );
          const hasData =
            !!lodashGet(featureData, `notify`) ||
            !!lodashGet(featureData, `rating`) ||
            !!lodashGet(featureData, `content`);

          return (
            <li key={i}>
              <div>
                {i + 1}) {label}
              </div>
              {hasData ? (
                <Button
                  variant="secondary"
                  colorVariant="danger"
                  onClick={() => {
                    handleClearData(item);
                  }}
                >
                  Clear
                </Button>
              ) : (
                <span>Has no data</span>
              )}
            </li>
          );
        })}
      </ol>
    </StyledContainer>
  );
};

export default Profile;
