"use client";

import React, { useCallback, useState } from "react";

import PageWithHeaderLayoutProps from "@/features/layouts/PageWithHeaderLayout";
import ContractRejectForm, {
  ContractRejectFormRef,
  FormValues,
  INITIAL_VALUES,
} from "@/features/contracts/ContractRejectForm";
import {
  useContractDetails,
  useContractUrlId,
} from "@/services/ContractsService";
import { useParamState } from "@/services/Routing";
import { analytics } from "@/config/services";

import { StyledPage } from "./ContractRejectPage.styles";

const ContractRejectPage: React.FC = () => {
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();

  useContractDetails({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
    useAuthToken: false,
  });

  const [formRef, setFormRef] = useState({} as ContractRejectFormRef);

  const [formParamState, setFormParamState] = useParamState<FormValues>(
    "reject-form-state",
    INITIAL_VALUES
  );

  //-------------------

  const handleBackClick = useCallback(() => {
    if (contractIdFromUrl) {
      analytics.triggerRejectOfferEvent("reject_offer_cancel", {
        contractId: contractIdFromUrl,
      });
    }

    if (formRef) {
      formRef.previousStep();
    }
  }, [formRef, contractIdFromUrl]);

  //-------------------

  return (
    <PageWithHeaderLayoutProps
      headerProps={{
        titleProps: { children: "Reason for rejection" },
        backButtonProps: {
          onClick: handleBackClick,
        },
      }}
    >
      <StyledPage>
        <ContractRejectForm
          initialValues={formParamState}
          contractId={contractIdFromUrl}
          getFormRef={setFormRef}
          onChange={setFormParamState}
        />
      </StyledPage>
    </PageWithHeaderLayoutProps>
  );
};

export default ContractRejectPage;
