import React from "react";

import { useProfilePage } from "../ProfilePage.context";
import InfoDisplay from "./InfoDisplay";

const TimezoneDisplay: React.FC<{ className?: string }> = ({ className }) => {
  const { profileData } = useProfilePage();

  if (!profileData.timezone) {
    return null;
  }

  return (
    <InfoDisplay
      className={className}
      icon="clock_2.svg"
      value={profileData.timezone}
    />
  );
};

export default TimezoneDisplay;
