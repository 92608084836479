import React from "react";

import { Body } from "@/components/Typography";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import SpecializationCard from "@/features/user/SpecializationCard";

import { useProfilePage } from "../ProfilePage.context";

const SpecializationSection: React.FC = () => {
  const { profileData } = useProfilePage();
  const { specializations = [] } = profileData;

  if (!specializations.length) {
    return (
      <ImageTitleContent
        className="mt-5 mb-5"
        title="No Specialization"
        content={<>You don't have any specialization</>}
        image="/assets/images/pages/contracts/no_contracts.svg"
      />
    );
  }

  return (
    <div>
      <Body size="md" style={{ color: "var(--clr-icon-text-200, #818898)" }}>
        Specializations
      </Body>

      <div className="mt-3">
        {specializations.map((specialization, i) => (
          <SpecializationCard
            key={i}
            className="mt-4"
            specialization={specialization}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecializationSection;
