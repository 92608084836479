"use client";

import React, { useCallback } from "react";
import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import Button from "@/components/input/Button";
import { SUPPORTED_FILES_INPUT_ACCEPT_VALUE } from "@/services/MediaService";

import { StyledFileInput, StyledInputButton } from "../ChatRoom.styles";

const StyledHeading = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--clr-text-ukwn-2, #1a1d1f);
`;

const StyledDrawerContent = styled.div`
  position: relative;
  padding: 0 1.5rem 1rem 1.5rem;

  hr {
    opacity: 0.5;
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  margin-inline: -0.75rem;

  span {
    flex: 1;
    text-align: start;
  }
`;

const AttachmentButton: React.FC<{ onChange: (file: []) => void }> = ({
  onChange,
}) => {
  const drawerState = useDrawerState();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      onChange([...e.target.files]);
      drawerState.close();
    },
    [onChange, drawerState]
  );

  return (
    <>
      <StyledInputButton variant="ghost" onClick={drawerState.open}>
        <Icon size="sm" src="/assets/images/icons/clip_tilted.svg" />
      </StyledInputButton>

      <Drawer state={drawerState} canDrag={false}>
        <StyledDrawerContent>
          <StyledHeading>Add Files</StyledHeading>

          <StyledButton
            className="w-100 mt-3"
            variant="ghost"
            colorVariant="black"
          >
            <Icon isSrcRelative src="camera.svg" />
            <span>Camera</span>

            <StyledFileInput
              capture="environment"
              type="file"
              onChange={handleChange}
              accept="image/*;capture=camera"
            />
          </StyledButton>

          <hr />

          <StyledButton className="w-100" variant="ghost" colorVariant="black">
            <Icon isSrcRelative src="gallery.svg" />
            <span>Gallery</span>

            <StyledFileInput
              multiple
              accept="image/*"
              type="file"
              onChange={handleChange}
            />
          </StyledButton>

          <hr />

          <StyledButton className="w-100" variant="ghost" colorVariant="black">
            <Icon isSrcRelative src="file.svg" />
            <span>File</span>

            <StyledFileInput
              multiple
              type="file"
              onChange={handleChange}
              accept={SUPPORTED_FILES_INPUT_ACCEPT_VALUE}
            />
          </StyledButton>

          <hr />

          <Button
            className="w-100 mt-4"
            variant="secondary"
            colorVariant="gray"
            onClick={drawerState.close}
          >
            Cancel
          </Button>
        </StyledDrawerContent>
      </Drawer>
    </>
  );
};

export default AttachmentButton;
