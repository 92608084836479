import useSWR from "swr";

import { userService } from "@/config/services";

type Params = {
  email: string;
};

function useUserExists(params: Params) {
  const { email } = params;

  const { data, error, isLoading } = useSWR(
    !!email ? `/auth/user_exists?email=${email}` : null,
    () =>
      userService
        .checkWhetherUserExists({ email })
        .then((res) => res.data.data.exist)
  );

  const isUpdating = data !== undefined && isLoading;

  //-----------------------

  return {
    exists: data || null,
    error,
    isLoading,
    isUpdating,
  };
}

export default useUserExists;
