import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import { squareSizing } from "@/utils/css";

export const StyledTable = styled.table`
  --bg-color: rgba(255, 255, 255, 1);
  --clr-text: #110a57;

  position: relative;
  flex-direction: column;
  align-items: stretch;
  font-size: 12px;
  line-height: 18px;
  color: var(--clr-text);
  border-collapse: collapse;
  background: var(--bg-color);
  overflow: auto;
`;

export const StyledTHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 4;
  min-width: fit-content;
  border-radius: 0.5rem;
  background-color: var(--clr-background-25, #f6f8fa);
`;

export const StyledTh = styled.th`
  position: relative;
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  text-align: unset;
  color: var(--clr-neutral-solid-500, #666d80);
`;

export const StyledTr = styled.tr`
  display: flex;
  letter-spacing: 0.2px;
  font-size: 0.875rem;
`;

export const StyledTbody = styled.tbody`
  flex: 1;
  min-width: fit-content;

  tr {
    --clr-bg-row: transparent;
    border-bottom: 1px solid var(--clr-neutral-solid-25, #f6f8fa);

    &:hover {
      --clr-bg-row: #f2f2f2;
      color: var(--clr-text);
    }

    /* & td,
    & th {
      background-color: var(--clr-bg-row);
    } */
  }

  /* & > *:nth-child(even) {
    --clr-bg-row: #fbfbfb;
  } */
`;

export const StyledTd = styled.td<{ $fixed: boolean }>`
  position: relative;
  padding: 1rem 0.75rem;
  background: var(--bg-color);
  color: var(--clr-text-900, #0d0d12);

  ${({ $fixed }) =>
    $fixed
      ? `
          &:before {
            content: "";
            position: absolute;
            left: -2px;
            top: 0;
            height: 100%;
            width: 4px;
            background: inherit;
            z-index: -1;
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: rgba(0, 0, 0, 0.03);
          }
        `
      : ""}
`;

export const StyledColumnResizer = styled.div`
  position: absolute;
  right: -6px;
  top: 0;
  height: 100%;
  padding: 0 6px;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: col-resize;
  user-select: none;
  touch-action: none;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 4px;
    height: 100%;
    background: rgba(0, 0, 0, 0.06);
    transform: translateX(-50%);
  }

  &.isResizing &:after {
    background: var(--clr-primary);
  }
`;

export const StyledIconContainer = styled.div`
  ${squareSizing("2rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCellContent = styled(Label)`
  font-weight: 500 !important;
  color: var(--clr-neutral-solid-700, #272835);

  b {
    font-weight: 600;
    color: var(--clr-neutral-solid-900, #0d0d12);
  }
`;
