import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import { useAcceptUserChatRequest } from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";

const AcceptChatRequestButton: React.FC<IButtonProps & { userId: number }> = ({
  userId,
  children = "Accept & Reply",
  ...restProps
}) => {
  const { loggedInUserId } = useAuth();
  const { accept, isLoading } = useAcceptUserChatRequest({ userId });

  if (loggedInUserId === userId) {
    return null;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Button {...restProps} onClick={accept}>
      {children}
    </Button>
  );
};

export default AcceptChatRequestButton;
