"use client";

import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";

export const StyledTextField = styled(TextField)`
  & > input {
    pointer-events: none;
  }
`;

export const StyledDatesDisplay = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;
