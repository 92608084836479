import useSWR from "swr";
import lodashGet from "lodash/get";

import { useChatService } from "../ChatService.context";

function useConnectChannel(
  channelName: string,
  config: { useLastMessage?: boolean } = {}
) {
  const { ablyClient } = useChatService();

  const { data: channel } = useSWR(
    !!ablyClient && !!channelName ? `/chats/${channelName}/connect` : null,
    () => {
      if (!ablyClient) {
        return Promise.reject();
      }

      const channel = ablyClient.channels.get(
        channelName,
        config?.useLastMessage
          ? {
              params: { rewind: "1" },
            }
          : {}
      );

      return Promise.resolve(channel);
    },
    {
      initialData: { channel: null },
      errorRetryInterval: 10000,
      errorRetryCount: 5,
      dedupingInterval: 100000,
      revalidateOnFocus: false,
    }
  );

  const connectionState = lodashGet(channel, "connectionManager.state.state");
  const isConnected = !!connectionState && connectionState !== "closed";

  return {
    channel: channel || null,
    isConnected,
  };
}

export default useConnectChannel;
