"use client";

import React from "react";

import { selectUserData, useAppSelector } from "@/services/Store";
import { getUserFullName } from "@/services/UserService";

import { StyledText } from "./UserName.styles";

const UserName: React.FC<{ className?: string }> = ({ className }) => {
  const userData = useAppSelector(selectUserData);
  const username = userData ? getUserFullName(userData) : "";

  if (!username) {
    return null;
  }

  return <StyledText>{username}</StyledText>;
};

export default UserName;
