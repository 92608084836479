import React from "react";
import styled from "@emotion/styled";

import { Body } from "@/components/Typography";
import PackageCard from "@/features/user/PackageCard";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import { screenLargerThan, screenSmallerThan } from "@/styles";

import { useProfilePage } from "../ProfilePage.context";

const StyledCardContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`;

const StyledCard = styled(PackageCard)`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);

  ${screenSmallerThan.widescreen} {
    padding-bottom: 1rem;
    margin-inline: 1.5rem;
  }
`;

const PackagesSection: React.FC = () => {
  const { profileData } = useProfilePage();
  const { packages = [] } = profileData;

  if (!packages.length) {
    return (
      <ImageTitleContent
        className="mt-5 mb-5"
        title="No packages"
        content={<>You don't have any packages</>}
        image="/assets/images/pages/contracts/no_contracts.svg"
      />
    );
  }

  return (
    <div className="">
      <Body size="md" style={{ color: "var(--clr-icon-text-200, #818898)" }}>
        All the works
      </Body>

      <StyledCardContainer className="mt-3">
        {packages.map((packageData, i) => (
          <StyledCard key={i} package={packageData} />
        ))}
      </StyledCardContainer>
    </div>
  );
};

export default PackagesSection;
