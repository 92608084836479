import { useEffect } from "react";

import { chatService } from "@/config/services";

import { useChatService } from "../ChatService.context";
import useChatSummary from "./useChatSummary";

function useUnreadCount() {
  const { streamClient } = useChatService();

  const {
    data: { unreadCount },
    isLoading,
  } = useChatSummary();

  //----------------------

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.mark_read", (data) => {
        chatService.updateStoreState({
          unreadCount: data.total_unread_count || 0,
        });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient]);

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.message_new", (data) => {
        chatService.updateStoreState({
          unreadCount: data.total_unread_count || 0,
        });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient]);

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.mark_unread", (data) => {
        chatService.updateStoreState({
          unreadCount: data.total_unread_count || 0,
        });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient]);

  //----------------------

  return {
    unreadCount,
    isLoading,
  };
}

export default useUnreadCount;