import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { authReducer } from "./slices/authSlice";
import { contractReducer } from "./slices/contractSlice";
import { mediaReducer } from "./slices/mediaSlice";
import { userReducer } from "./slices/userSlice";
import { chatReducer } from "./slices/chatSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contract: contractReducer,
    media: mediaReducer,
    user: userReducer,
    chat: chatReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppState = ReturnType<typeof store.getState>;