import React, { Fragment } from "react";
import styled from "@emotion/styled";

import { Body } from "@/components/Typography";
import { EXPERIENCE_LEVEL_CONFIG } from "@/services/UserService";
import { useResponsive } from "@/styles";
import { appFeatures } from "@/config/services";
import Wysiwyg from "@/components/input/LexicalWysiwyg";

import { useProfilePage } from "../ProfilePage.context";
import SkillDisplay from "./SkillDisplay";
import SocialLinksDisplay from "./SocialLinksDisplay";
import TimezoneDisplay from "./TimezoneDisplay";
import LanguagesDisplay from "./LanguagesDisplay";

export const StyledWysiwyg = styled(Wysiwyg)`
  max-height: 20rem;

  &,
  .editor {
    background: transparent;
  }

  .editor > * {
    position: static;
  }
`;

const AboutSection: React.FC = () => {
  const { profileData } = useProfilePage();
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <div className="">
      <Body size="md" style={{ color: "var(--clr-icon-text-200, #818898)" }}>
        About me
      </Body>

      <StyledWysiwyg
        readOnly
        className="mt-2"
        value={{ markdown: profileData.about_me }}
      />

      {appFeatures.isSupported("ACCOUNT.PROFESSIONS") &&
        !!profileData.profession?.length && (
          <>
            <Body
              className="mt-3"
              size="md"
              style={{ color: "var(--clr-icon-text-200, #818898)" }}
            >
              Profession
            </Body>
            <Body size="md" style={{ color: "#000" }} className="mt-1">
              {profileData.profession.map((language, i) => (
                <Fragment key={i}>
                  {i !== 0 && ", "}
                  {language}&nbsp;
                </Fragment>
              ))}
            </Body>
          </>
        )}

      {appFeatures.isSupported("ACCOUNT.EXPERIENCE") &&
        !!profileData.experience_level && (
          <>
            <Body
              size="md"
              className="mt-3"
              style={{ color: "var(--clr-icon-text-200, #818898)" }}
            >
              Experience level
            </Body>
            <Body size="md" style={{ color: "#000" }} className="mt-1">
              {EXPERIENCE_LEVEL_CONFIG[profileData.experience_level].info.name}
            </Body>
          </>
        )}

      {isScreenSmallerThanTablet && <TimezoneDisplay className="mt-3" />}

      {isScreenSmallerThanTablet && <LanguagesDisplay className="mt-3" />}

      {isScreenSmallerThanTablet && !!profileData.skills.length && (
        <hr className="mt-3" />
      )}
      <SkillDisplay className={isScreenSmallerThanTablet ? "mt-3" : "mt-5"} />

      {isScreenSmallerThanTablet && !!profileData.links.length && (
        <hr className="mt-4" />
      )}
      <SocialLinksDisplay
        className={isScreenSmallerThanTablet ? "mt-3" : "mt-5"}
      />
    </div>
  );
};

export default AboutSection;
