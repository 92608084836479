"use client";

import React from "react";
import styled from "@emotion/styled";

import Button, { IButtonProps } from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";

const StyledButton = styled(Button)`
  ${squareSizing("2.25rem")};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10rem;
`;

const EditButton: React.FC<Omit<IButtonProps, "children">> = (props) => {
  return (
    <StyledButton {...props} colorVariant="white-primary">
      <Icon isSrcRelative src="edit.svg" size="xs" />
    </StyledButton>
  );
};

export default EditButton;
