import React, { useCallback, useMemo } from "react";
import { useLocale, useRangeCalendar } from "react-aria";
import { useRangeCalendarState } from "react-stately";
import { createCalendar, CalendarDate } from "@internationalized/date";

import {
  StyledCalendarSection,
  StyledContainer,
  StyledHeader,
  StyledTitle,
} from "../Calendar.styles";
import { CalendarProps, RangeCalendarProps } from "../Calendar.types";
import { fromCalendarDate, toCalendarDate } from "../Calendar.utils";
import CalendarGrid from "./CalendarGrid";
import Navigation from "./Navigation/Navigation";

const RangeCalendar: React.FC<CalendarProps> = (props) => {
  const { type, className } = props;
  const { rangeValue: valueFromProps, onRangeChange: onChangeFromProps } =
    props as RangeCalendarProps;

  const { locale } = useLocale();
  const ref = React.useRef(null);

  const isControlled = !!onChangeFromProps;

  const value = useMemo(() => {
    if (type !== "range" || !valueFromProps) {
      return null;
    }

    const { start, end } = valueFromProps;

    if (!start || !end) {
      return null;
    }

    return {
      // start: start ? toCalendarDate(start) : null,
      // end:  end ?toCalendarDate(end) : null,
      start: toCalendarDate(start),
      end: toCalendarDate(end),
    };
  }, [valueFromProps, type]);

  //--------------------------

  const handleValueChange = useCallback(
    ({ start, end }: { start: CalendarDate; end: CalendarDate }) => {
      if (type !== "range" || !onChangeFromProps) {
        return null;
      }

      onChangeFromProps({
        start: fromCalendarDate(start),
        end: fromCalendarDate(end),
      });
    },
    [onChangeFromProps, type]
  );

  //--------------------------

  const state = useRangeCalendarState({
    value: isControlled ? value || undefined : undefined,
    onChange: (isControlled ? handleValueChange : undefined) as any,
    locale,
    createCalendar,
    pageBehavior: "single",
    minValue: toCalendarDate(new Date()),
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(
      {
        value: value || undefined,
      },
      state,
      ref
    );

  //--------------------------

  return (
    <StyledContainer {...calendarProps} className={className} ref={ref}>
      <StyledHeader className="header">
        <StyledTitle>{title}</StyledTitle>

        <Navigation
          prevButtonProps={prevButtonProps}
          nextButtonProps={nextButtonProps}
        />
      </StyledHeader>

      <StyledCalendarSection>
        <CalendarGrid state={state} type="range" />
      </StyledCalendarSection>
    </StyledContainer>
  );
};

export default RangeCalendar;
