import React from "react";

import DotSeparated from "@/components/misc/DotSeparated";
import ShimmerLoader from "@/components/misc/ShimmerLoader";

import { StyledCard, StyledRow } from "./ContractCard.styles";

const ContractCard: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledCard className={className}>
      <StyledRow>
        <ShimmerLoader.Default style={{ width: "5rem", height: "1.5rem" }} />

        <ShimmerLoader.Circular size="1.5rem" />
      </StyledRow>

      <StyledRow className="mt-1">
        <ShimmerLoader.Default style={{ width: "10rem", height: "1.2rem" }} />

        <ShimmerLoader.Default style={{ width: "5rem", height: "1.75rem" }} />
      </StyledRow>

      <StyledRow className="mt-2 flex-wrap">
        <ShimmerLoader.Default
          style={{ width: "6rem", height: "2rem", borderRadius: "10rem" }}
        />

        <DotSeparated>
          <ShimmerLoader.Default style={{ width: "3rem", height: "1rem" }} />
          <ShimmerLoader.Default style={{ width: "3rem", height: "1rem" }} />
        </DotSeparated>
      </StyledRow>
    </StyledCard>
  );
};

export default ContractCard;
