import { Variant, ColorVariant } from "./Tag.types";

export const TAG_VARAINT_CONFIG: Record<
  ColorVariant,
  Record<
    Variant,
    {
      color: string;
      bgColor: string;
      borderColor: string;
    }
  >
> = {
  gray: {
    filled: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "var(--clr-background-25, #F6F8FA)",
      borderColor: "var(--clr-border-25, #F6F8FA)",
    },
    outlined: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-border-25, #F6F8FA)",
    },
  },

  success: {
    filled: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-success-500, #1dbf73)",
      borderColor: "var(--clr-success-500, #1dbf73)",
    },
    outlined: {
      color: "var(--clr-success-500, #1dbf73)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-success-500, #1dbf73)",
    },
  },

  primary: {
    filled: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-primary-100, #5f57ff)",
      borderColor: "var(--clr-primary-100, #5f57ff)",
    },
    outlined: {
      color: "var(--clr-primary-100, #5f57ff)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-primary-100, #5f57ff)",
    },
  },

  yellow: {
    filled: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-secondary-yellow-50, #fcda83)",
      borderColor: "var(--clr-border-25, #F6F8FA)",
    },
    outlined: {
      color: "var(--clr-secondary-yellow-50, #fcda83)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-border-25, #F6F8FA)",
    },
  },
};

export const DEFAULT_VARIANT: Variant = "outlined";

export const DEFAULT_COLOR_VARIANT: ColorVariant = "gray";
