import React from "react";

import {
  amIContractPayer,
  getContractManagePageUrl,
  useManageContract,
  useStripeSessionObject,
  useStripeSessionUrlId,
} from "@/services/ContractsService";
import { useSearchParams } from "@/services/Routing";
import { useAuth } from "@/services/Authentication";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";
import SplashScreen from "@/features/pages/app/SplashScreen";
import ContractPaymentSuccessPage from "@/features/pages/contract/ContractPaymentSuccessPage";
import { useUserExists } from "@/services/UserService";

const ContractPaymentRedirectPage: React.FC = () => {
  const {
    contractDetails,
    isLoading: isLoadingContractDetails,
    isPaymentRedirect,
    contractId,
  } = useManageContract({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
  });

  const { isAuthenticated } = useAuth();
  const { searchParams } = useSearchParams();

  const { stripeSessionIdFromUrl: stripeSessionId } = useStripeSessionUrlId();
  const { stripeSessionObject, isLoading: isLoadingStripeSessionObject } =
    useStripeSessionObject({
      sessionId: stripeSessionId,
    });

  const onboardingEmail = stripeSessionObject?.customer_details?.email || "";
  const { exists: userExistsAlready, isLoading: checkUserExistsAlready } =
    useUserExists({
      email: onboardingEmail,
    });

  //===================================

  if (
    isLoadingContractDetails ||
    !contractDetails ||
    isLoadingStripeSessionObject ||
    checkUserExistsAlready
  ) {
    return <SplashScreen />;
  }

  if (!isPaymentRedirect) {
    if (contractId) {
      return <Redirect replace path={getContractManagePageUrl(contractId)} />;
    }

    return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
  }

  //----------------

  const paymentSucceded = searchParams.get("success") === "true";
  const amIPayer = amIContractPayer(contractDetails);
  const showPaymentSuccess =
    paymentSucceded && ((isAuthenticated && amIPayer) || userExistsAlready);
  const showPaymentSuccessOnboarding =
    paymentSucceded && !isAuthenticated && !userExistsAlready;

  if (showPaymentSuccessOnboarding) {
    return (
      <Redirect keepSearch replace path={SITE_PATHS.CONTRACTS_ONBOARD_PAGE} />
    );
  }

  if (showPaymentSuccess) {
    return <ContractPaymentSuccessPage email={onboardingEmail} />;
  }

  return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
};

export default ContractPaymentRedirectPage;
