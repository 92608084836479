import * as yup from "yup";

import { FormValues } from "./SpecializationForm.types";

export const MIN_EXPERIENCE_VALUE = 1;
export const MAX_EXPERIENCE_VALUE = 100;

export const FORM_ERRORS = {
  MAX_EXPERIENCE_ERROR: "MAX_EXPERIENCE_ERROR",
  MIN_EXPERIENCE_ERROR: "MIN_EXPERIENCE_ERROR",
};

export const DEFAULT_FORM_VALUES = {
  name: "",
  experience_duration: 0,
  experience_duration_unit: "",
} satisfies FormValues;

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  experience_duration: yup
    .number()
    .min(MIN_EXPERIENCE_VALUE)
    .max(MAX_EXPERIENCE_VALUE, FORM_ERRORS.MAX_EXPERIENCE_ERROR)
  .required(),
  experience_duration_unit: yup.string().required(),
});
