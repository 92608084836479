import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Button from "@/components/input/Button";
import { Heading } from "@/components/Typography";

export const StyledContainer = styled.div`
  position: relative;
  border: 1px solid var(--clr-neutral-solid-200, #c1c7cf);
  border-radius: 1rem;
  padding: 1rem;
  color: var(--clr-text-600, #353849);
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const StyledDeleteButton = styled(Button)`
  position: absolute;
  top: 0.25rem;
  right: 1.15rem;
`;

export const StyledTitle = styled(Heading)`
  word-break: break-word;
`;
