import React, { useMemo } from "react";

import { Body, Display, Label } from "@/components/Typography";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { useSubscriptionPlans } from "@/services/UserService";

import {
  StyledCard,
  StyledContent,
  StyledCostContainer,
  StyledHeader,
  StyledOriginalCost,
  StyledPrimaryContent,
} from "./SubscriptionCard.styles";
import { ISubscriptionCardProps } from "./SubscriptionCard.types";
import {
  SUBSCRIPTION_FEATURES,
  SUBSCRIPTIONS_CONFIG,
  USE_STATIC_PRICES,
} from "../../SubscriptionPage.config";
import Feature from "../Feature";

const SubscriptionCard: React.FC<ISubscriptionCardProps> = ({
  subscriptionDuration,
  className,
}) => {
  const { data: plansData } = useSubscriptionPlans();

  const {
    unitText,
    billingText,
    discount: discountStatic = 0,
    cost: costStatic,
  } = SUBSCRIPTIONS_CONFIG[subscriptionDuration];

  const { cost: discountFromDB, discount: discountCalculated } = useMemo(() => {
    const data = {
      costBeforeDiscount: 0,
      discount: 0,
      cost: 0,
    };

    if (!plansData) {
      return data;
    }

    const { benefit = 0, price_cent } = plansData.plans[subscriptionDuration];

    data.cost = price_cent / 100;
    data.discount = benefit / 100;
    data.costBeforeDiscount = data.cost + data.discount;

    return data;
  }, [plansData, subscriptionDuration]);

  const discount = USE_STATIC_PRICES ? discountStatic : discountCalculated;
  const cost = USE_STATIC_PRICES ? costStatic : discountFromDB;
  const costBeforeDiscount = cost + discount;

  //-------------------------------------

  return (
    <StyledCard className={className}>
      <StyledHeader>
        <StyledPrimaryContent>
          <Label size="lg">$</Label>

          <Display size="lg">
            <StyledCostContainer>
              {!!discount && (
                <StyledOriginalCost size="lg">
                  <div className={ANIMATION_CLASSNAMES.FADE_IN_FROM_BOTTOM}>
                    ${costBeforeDiscount}
                  </div>
                </StyledOriginalCost>
              )}
              {cost}
            </StyledCostContainer>
          </Display>

          <Body size="lg">/{unitText}</Body>
        </StyledPrimaryContent>

        <Body size="md" style={{ color: "rgb(175, 171, 255)" }}>
          {billingText}
        </Body>
      </StyledHeader>

      <StyledContent>
        {SUBSCRIPTION_FEATURES.map((feature, i) => (
          <Feature
            key={i}
            {...feature}
            description={feature.short_description}
            className="mt-4"
          />
        ))}
      </StyledContent>
    </StyledCard>
  );
};

export default SubscriptionCard;
