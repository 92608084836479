import { removeDuplicatesPrimitives } from "@/utils/array";

export const SKILLS_TOOLS_OPTIONS = removeDuplicatesPrimitives([
  "A/B Testing Tools like Optimizely or VWO (A/BTT)",
  "A/B Testing Tools like Optimizely or VWO(A/B Testing Tools )",
  "A2 Hosting(A2 Hosting Managed WordPress Hosting )",
  "AWS CloudWatch(AWS CloudWatch )",
  "AWS Lambda(AWS Lambda )",
  "AWS(Amazon Web Services)",
  "AWeber Email Marketing Tool(AWEMT)",
  "AWeber(AWeber Email Automation Tool )",
  "ActiveCampaign Email Marketing Automation(AEMA)",
  "ActiveCampaign(ActiveCampaign Email Marketing Automation )",
  "ActiveCampaign(ActiveCampaign as an All-in-One Marketing Platform )",
  "Adobe Lightroom for Photo Editing (ALPE)",
  "Adobe Premiere Pro for Video Editing (APPE)",
  "Adobe XD",
  "Affinity Designer",
  "Ahrefs",
  "Airtable",
  "Airtable(Airtable)",
  "Akamai(Akamai CDN and Security Services)",
  "All in One SEO Pack(All in One SEO Plugin)",
  "Angular",
  "Animoto for Video Creation (AVC)",
  "AppDynamics(AppDynamics )",
  "Apple Mail(Apple Mail as a Communication Tool )",
  "Asana",
  "Asana(Project Planning Application )",
  "Asana(Task Gantt Charting Application",
  "Asana(Task Management Software)",
  "Asana(Task Tracking & Organization Application",
  "Asana(Task and Project Management App )",
  "Asana(Task and Project Management Software)",
  "Asana(Task and Project Planning App )",
  "Azure Functions(Azure Functions )",
  "Azure Monitor(Azure Monitor )",
  "Azure(Microsoft Azure)",
  "Back4App(Back4App )",
  "Basecamp",
  "Basecamp(Basecamp Planning App )",
  "Basecamp(Basecamp Project Management App )",
  "Basecamp(Basecamp Project Management Software)",
  "Basecamp(Basecamp Project Planning Software)",
  "Basecamp(Basecamp)",
  "Basecamp(Project Gantt Charting Application",
  "Basecamp(Project Planning Application )",
  "Basecamp(Task Tracking & Organization Application",
  "Behavioral Analysis Tools like Hotjar or Crazy Egg(Behavioral Analysis Tools )",
  "BigCommerce(BigCommerce eCommerce Platform)",
  "Bitbucket",
  "Blogger(Blogger Hosted Blogging Platform)",
  "Bluehost(Bluehost Managed WordPress Hosting )",
  "Bonsai",
  "Brightpearl(BP)",
  "Brightpearl(Brightpearl as an Inventory Management System )",
  "Broken Link Checker(Broken Link Checker Plugin)",
  "BrowserStack(BrowserStack)",
  "Bubbles",
  "Buffer",
  "Buffer Publish for Social Media Scheduling (BPSMS)",
  "BunnyCDN(BunnyCDN CDN Services)",
  "CRMNEXT(CRMNEXT)",
  "Caldera Forms(Caldera Forms Contact Form Builder)",
  "Canva",
  "Canva Video Editor for Simple Video Editing (CVESVE)",
  "Capsule CRM(Capsule)",
  "Capsule(Capsule as a Simple CRM )",
  "Cassandra(Cassandra )",
  "Chatfuel(Chatfuel Chatbot Development)",
  "ClickUp",
  "ClickUp(ClickUp)",
  "ClickUp(Project Planning Application )",
  "ClickUp(Task Management Software)",
  "ClickUp(Task Tracking & Organization Application",
  "ClickUp(Task and Project Management App )",
  "Clockify",
  "Cloudflare(Cloudflare CDN and Security Services)",
  "Cloudways(Cloudways Managed WordPress Hosting )",
  "CodePen(CodePen)",
  "CodeSandbox(CodeSandbox)",
  "Codecademy",
  "Coggle for Mind Mapping (CMM)",
  "Coggle(Coggle Mind Mapping App )",
  "Coggle(Coggle Mind Mapping Application",
  "Coggle(Coggle Mind Mapping Software)",
  "Coggle(Coggle)",
  "Comet Cache(Comet Cache Plugin)",
  "Confluence(Confluence)",
  "Confluence(Document Collaboration & Knowledge Sharing Tool",
  "Confluence(Document Collaboration Application )",
  "Confluence(Jira Documentation App )",
  "Confluence(Jira Documentation Software)",
  "Constant Contact",
  "Contact Form 7(Contact Form 7 Contact Form Builder)",
  "Content Snare for Client Content Management (CSCCM)",
  "ConvertKit Email Marketing Tool(CKEMT)",
  "ConvertKit(ConvertKit Email Marketing Tool )",
  "CorelDRAW",
  "Coursera",
  "Crazy Egg for Website Optimization Tools (CEWOT)",
  "CrossBrowserTesting(CrossBrowserTesting )",
  "DaVinci Resolve for Video Editing and Color Correction (DRVEC)",
  "Datadog(Datadog )",
  "Dialogflow(Dialogflow Conversational Interface Development)",
  "DigitalOcean(DigitalOcean )",
  "DigitalOcean(DigitalOcean Cloud Computing Service)",
  "Discord",
  "Django",
  "DreamHost(DreamHost Managed WordPress Hosting )",
  "Easy Digital Downloads(Easy Digital Downloads eCommerce Plugin)",
  "Elasticsearch(Elasticsearch )",
  "Evernote for Note-Taking (ENT)",
  "Evernote(Evernote Note-Taking App )",
  "Evernote(Evernote Note-Taking Application )",
  "Evernote(Note Taking & Organization Application",
  "Facebook Ads Manager for Social Media Advertising (FAMSA)",
  "Figma",
  "FilmoraGo for Mobile Video Editing (FGMVE)",
  "Final Cut Pro X for Video Editing (FCPX)",
  "Firebase(Firebase )",
  "Fiverr Marketplace for Digital Services (FMDS)",
  "Fiverr Workspace for Business Management Tools (FWBMT)",
  "Fly.io(Fly.io )",
  "Flywheel(Flywheel Managed WordPress Hosting)",
  "Formidable Forms(Formidable Forms Contact Form Builder)",
  "Freelo(Freelo Task Management Software)",
  "Freelo(Freelo Task Planning App )",
  "Freelo(Task Gantt Charting Application",
  "FreshBooks",
  "Freshdesk(Freshdesk Customer Support Software)",
  "Freshsales(Freshsales as a CRM )",
  "Freshsales(Freshsales)",
  "FullStory(FullStory )",
  "GIMP for Open-Source Image Editing (GOIE)",
  "GanttPRO(Gantt Chart Project Planning App )",
  "GanttPRO(Gantt Chart Project Planning Software)",
  "GanttPRO(GanttPRO)",
  "GanttPRO(Project Gantt Charting Application",
  "GetResponse Email Marketing Tool(GEMT)",
  "GetResponse(GetResponse Email Marketing Tool )",
  "Git(Git)",
  "GitHub",
  "GitLab",
  "Glitch(Glitch)",
  "Gmail(Gmail as a Communication Tool )",
  "Google Ads for Online Advertising Campaigns (GAOAC)",
  "Google Analytics",
  "Google Cloud Functions(Google Cloud Functions )",
  "Google Cloud Monitoring(Google Cloud Monitoring )",
  "Google Cloud Platform(Google Cloud Platform)",
  "Google Docs for Document Collaboration (GDC)",
  "Google Keep(Google Keep Note-Taking App )",
  "Google Keep(Google Keep Note-Taking Application )",
  "Google Keep(Note Taking & Organization Application",
  "Google Meet",
  "Google Search Console to Monitor Website Performance in Search Results(GSCMP)",
  "Google Search Console(Google Search Console to Monitor Website Performance in Search Results )",
  "Google Tag Manager to Manage Website Tags Easily(GTMWE)",
  "Google Tag Manager(Google Tag Manager to Manage Website Tags Easily )",
  "Grammarly for Writing Assistance (GWA)",
  "Gravity Forms(Gravity Forms Contact Form Builder)",
  "GreenGeeks(GreenGeeks Managed WordPress Hosting )",
  "GreenRope CRM for Small Businesses (GRCMB)",
  "Gusto",
  "Harlow",
  "Harpoon for Time Tracking and Invoicing (HTI)",
  "Harvest",
  "Help Scout(Help Scout Customer Support Software)",
  "Hemingway Editor for Writing Clarity (HEWC)",
  "Heroku(Heroku )",
  "HoneyBook",
  "Hootsuite",
  "Hootsuite Insights for Social Media Analytics (HISA)",
  "HostGator(HostGator Managed WordPress Hosting )",
  "Hostinger(Hostinger Managed WordPress Hosting )",
  "HubSpot CRM for Marketing and Sales Tools (HCRMST)",
  "HubSpot Marketing Hub(HMH)",
  "HubSpot(HubSpot as a CRM and Marketing Platform )",
  "HubSpot(HubSpot as an All-in-One Inbound Marketing Software )",
  "Illustrator",
  "InMotion Hosting(InMotion Hosting Managed WordPress Hosting )",
  "InVision",
  "Indy",
  "Infusionsoft by Keap(Infusionsoft by Keap as a Sales and Marketing Platform )",
  "Infusionsoft by Keap(Infusionsoft)",
  "Insightful for Productivity Tracking (IPT)",
  "Intercom(Intercom Customer Support Software)",
  "JSFiddle(JSFiddle)",
  "Jimdo(Jimdo Website Builder and eCommerce Platform)",
  "Jira",
  "Jira(Issue Tracking & Agile Development Tool",
  "Jira(Jira Project Management App )",
  "Jira(Jira Project Management Software)",
  "Jira(Jira)",
  "Jira(Project Tracking Application )",
  "KeyCDN(KeyCDN CDN Services)",
  "Khan Academy",
  "Kinsta(Kinsta Managed WordPress Hosting )",
  "Klaviyo Email Marketing Tool(KEMT)",
  "Klaviyo(Klaviyo Email Marketing Tool )",
  "Krisp.ai",
  "Laravel",
  "Lighthouse(Lighthouse )",
  "Lili Banking Service Designed for Freelancers (LBSDF)",
  "LinkedIn Ads Manager for B2B Advertising Campaigns (LAMC)",
  "LinkedIn Learning",
  "Linode(Linode )",
  "Linode(Linode Cloud Computing Service)",
  "Litespeed Cache(Litespeed Cache Plugin)",
  "Litmus(Litmus)",
  "LiveChat(LiveChat Customer Support Software)",
  "Loggly(Loggly )",
  "Logz.io(Logz.io )",
  "Loom",
  "Magento(Magento eCommerce Platform)",
  "MailChimp",
  "MailChimp Email Marketing Platform (MCEMP)",
  "Mailchimp(Mailchimp API to Send Emails Easily )",
  "MailerLite Email Marketing Tool(MLMT)",
  "Mailgun(Mailgun API to Send Emails Easily )",
  "Mailjet Email Marketing Tool(MJETMT)",
  "Mailjet(Mailjet Email Marketing Tool )",
  "ManyChat(ManyChat Chatbot Development)",
  "Marketo by Adobe(B2B Marketing Automation)(MBAA)",
  "Marketo(Marketo as a B2B Marketing Automation Platform )",
  "MaxCDN(MaxCDN CDN Services)",
  "Medium(Medium Hosted Blogging Platform)",
  "Mercurial(Mercurial)",
  "Microsoft Project(Microsoft Project Planning App )",
  "Microsoft Project(Microsoft Project Planning Software)",
  "Microsoft Project(Microsoft Project)",
  "Microsoft Project(Project Gantt Charting Application",
  "Microsoft Teams",
  "Microsoft Word for Document Creation (MWDC)",
  "MindMeister(Mind Mapping Application",
  "MindMeister(MindMeister Mind Mapping App )",
  "MindMeister(MindMeister Mind Mapping Software)",
  "MindMeister(MindMeister)",
  "Miro",
  "Miro(Collaborative Whiteboarding Application",
  "Miro(Miro Collaborative Whiteboarding App )",
  "Miro(Miro Collaborative Whiteboarding Software)",
  "Miro(Miro)",
  "Monday.com",
  "Monday.com(Monday.com Project Management App )",
  "Monday.com(Monday.com Project Management Software)",
  "Monday.com(Monday.com)",
  "Monday.com(Project Planning Application )",
  "Monday.com(Task Tracking & Organization Application",
  "MongoDB(MongoDB )",
  "Mouseflow(Mouseflow )",
  "Moxie for Freelance Business Management (MFBM)",
  "MySQL(MySQL )",
  "Netlify(Netlify )",
  "New Relic(New Relic )",
  "Ninja Forms(Ninja Forms Contact Form Builder)",
  "Node.js",
  "Notion",
  "Notion(Note Taking & Organization Application",
  "Notion(Notion Documentation and Collaboration Software)",
  "Notion(Notion Note-Taking App )",
  "Notion(Notion Note-Taking Application )",
  "Notion(Notion)",
  "Nozbe(Nozbe Task Management Software)",
  "Odoo(Odoo)",
  "OmniFocus(OmniFocus Task Management Software)",
  "Omnifocus(Omnifocus Task Management App )",
  "Omnifocus(Omnifocus Task Planning App )",
  "Omnifocus(Task Gantt Charting Application",
  "OneNote(Microsoft OneNote Note-Taking App )",
  "OneNote(Microsoft OneNote Note-Taking Application )",
  "OneNote(Note Taking & Organization Application",
  "OpenCart(OpenCart eCommerce Platform)",
  "Outlook(Outlook as a Communication Tool )",
  "Pardot by Salesforce(B2B Marketing Automation)(PSFA)",
  "PayPal",
  "Permits(Permits)",
  "Photoshop",
  "Pingdom(Pingdom )",
  "Pipedrive CRM Tool for Lead Management (PCTLM)",
  "Pipedrive(Pipedrive as a Sales CRM )",
  "Pixlr X for Online Photo Editing (PXOPE)",
  "Pluralsight",
  "Pomodoro Timer",
  "PostgreSQL(PostgreSQL )",
  "Postmark(Postmark API to Send Emails Easily )",
  "PrestaShop(PrestaShop eCommerce Platform)",
  "ProWritingAid for Editing (PWA)",
  "Procreate",
  "Productive",
  "ProtonMail(ProtonMail as a Secure Communication Tool )",
  "QuickBooks",
  "Rackspace(Rackspace Cloud Computing Service)",
  "Rank Math(Rank Math SEO Plugin)",
  "React",
  "Redirection(Redirection Plugin)",
  "Redis(Redis )",
  "Render(Render )",
  "Replit(Replit)",
  "RescueTime",
  "Ruby on Rails",
  "SEMrush",
  "SEO Yoast(Yoast SEO Plugin)",
  "SQLite(SQLite )",
  "SVN(SVN)",
  "Salesforce CRM(SFCRM)",
  "Salesforce(Salesforce as a CRM )",
  "Schema Pro(Schema Pro Plugin)",
  "Scrivener for Writing Management (SWM)",
  "SendGrid(SendGrid API to Send Emails Easily )",
  "SendinBlue Email Marketing Tool(SBEMT)",
  "SendinBlue(SendinBlue Email Marketing Tool )",
  "Sentry(Sentry )",
  "Seo Press(SEO Press Plugin)",
  "SessionCam(SessionCam )",
  "Shopify(Shopify eCommerce Platform)",
  "Simple 301 Redirects(Simple 301 Redirects Plugin)",
  "SimpleNote(Note Taking & Organization Application",
  "SimpleNote(SimpleNote Note-Taking App )",
  "SimpleNote(SimpleNote Note-Taking Application )",
  "SiteGround(SiteGround Managed WordPress Hosting )",
  "SiteLock(SiteLock Security Services)",
  "Sketch",
  "Skillshare",
  "Slack",
  "Smartsheet(Smartsheet)",
  "Snapseed for Mobile Photo Editing (SMPE)",
  "SolidGigs for Freelance Job Matching (SGFJM)",
  "SparkReceipt for Receipt Management (SRM)",
  "Squarespace(Squarespace Website Builder and eCommerce Platform)",
  "StackBlitz(StackBlitz)",
  "Storybird for Storytelling (SBS)",
  "Stripe",
  "Substack(Substack Newsletter Publishing Platform)",
  "SugarCRM(Sugar)",
  "SugarCRM(SugarCRM as an Open-Source CRM )",
  "SuiteCRM(Suite)",
  "SuiteCRM(SuiteCRM as an Open-Source CRM )",
  "TeamGantt(Gantt Chart Project Planning App )",
  "TeamGantt(Gantt Chart Project Planning Software)",
  "TeamGantt(Project Gantt Charting Application",
  "Teamwork(Task Gantt Charting Application",
  "Teamwork(Task and Project Management Software)",
  "Teamwork(Task and Project Planning App )",
  "Teamwork(Teamwork)",
  "ThirstyAffiliates(ThirstyAffiliates Affiliate Link Manager)",
  "Tidio(Tidio Live Chat Software)",
  "TikTok Ads Manager for TikTok Advertising Campaigns (TAMC)",
  "Todoist",
  "Todoist(Task Tracking & Organization Application",
  "Todoist(Todoist Task Management App )",
  "Todoist(Todoist Task Management Software)",
  "Todoist(Todoist Task Planning Application )",
  "Toggl Track",
  "Treehouse",
  "Trello",
  "Trello(Task Management Software)",
  "Trello(Task Tracking & Organization Application",
  "Trello(Trello Task Management App )",
  "Trello(Trello Task Planning Application )",
  "Trello(Trello)",
  "Trello(Trello)",
  "Tumblr(Tumblr Hosted Blogging Platform)",
  "UberSuggest by Neil Patel for SEO Tools and Keyword Research (UNPSEO)",
  "UberSuggest by Neil Patel(SEO Tools and Keyword Research )",
  "Udemy",
  "Ulysses for Writing App (UWA)",
  "UptimeRobot(UptimeRobot )",
  "UserTesting(UserTesting )",
  "VWO(VWO )",
  "Vercel(Vercel )",
  "Voiceflow(Voiceflow Voice App Development)",
  "Vue.js",
  "Vultr(Vultr )",
  "Vultr(Vultr Cloud Computing Service)",
  "W3 Total Cache(W3 Total Cache Plugin)",
  "WP Engine Managed WordPress Hosting (WPMWH)",
  "WP Engine(WP Engine Managed WordPress Hosting )",
  "WP Job Manager(WP Job Manager Job Board Plugin)",
  "WP Super Cache(WP Super Cache Plugin)",
  "WPEngine(WPEngine Managed WordPress Hosting)",
  "WPForms(WPForms Contact Form Builder)",
  "Wave Accounting",
  "WebPageTest(WebPageTest )",
  "Weebly(Weebly Website Builder and eCommerce Platform)",
  "Wix(Wix Website Builder and eCommerce Platform)",
  "WooCommerce(WooCommerce eCommerce Plugin)",
  "WordPress.com(WordPress.com Hosted Blogging Platform)",
  "Wrike",
  "Wrike(Project Gantt Charting Application",
  "Wrike(Project Planning Application )",
  "Wrike(Task Tracking & Organization Application",
  "Wrike(Wrike Planning App )",
  "Wrike(Wrike Project Management App )",
  "Wrike(Wrike Project Management Software)",
  "Wrike(Wrike Project Planning Software)",
  "Wrike(Wrike)",
  "XMind(XMind Mind Mapping App )",
  "XMind(XMind Mind Mapping Application",
  "XMind(XMind Mind Mapping Software)",
  "XMind(XMind)",
  "Yahoo Mail(Yahoo Mail as a Communication Tool )",
  "YouTube for Learning Videos (YFLV)",
  "Zapier",
  "ZenBusiness for LLC Creation Services (ZBLC)",
  "Zendesk(Zendesk Customer Support Software)",
  "Zoho CRM(ZCRM)",
  "Zoho Mail(Zoho Mail as an Alternative Communication Tool )",
  "Zoho(Zoho as an All-in-One Business Management Platform )",
  "Zoom",
  "edX",
  ".NET",
  "360-degree video",
  "3D Animation",
  "3D Design",
  "3D Model Maker",
  "3D Modelling",
  "3D Printing",
  "3D Rendering",
  "3ds Max",
  "4D",
  "Academic Writing",
  "Accounting",
  "ActionScript",
  "Active Directory",
  "Ad Planning / Buying",
  "Adobe Air",
  "Adobe Captivate",
  "Adobe Dreamweaver",
  "Adobe Fireworks",
  "Adobe Flash",
  "Adobe InDesign",
  "Adobe Lightroom",
  "Adobe LiveCycle Designer",
  "Adobe Premiere Pro",
  "Advertisement Design",
  "Advertising",
  "Aeronautical Engineering",
  "Aerospace Engineering",
  "Affiliate Marketing",
  "Afrikaans",
  "After Effects",
  "Agile Development",
  "Agronomy",
  "Air Conditioning",
  "Airbnb",
  "AJAX",
  "Albanian",
  "Algorithm",
  "Alibaba",
  "Amazon Fire",
  "Amazon Kindle",
  "Amazon Web Services",
  "AMQP",
  "Analytics",
  "Android",
  "Android Honeycomb",
  "Android Wear SDK",
  "Angular.js",
  "Animation",
  "Antenna Services",
  "Anything Goes",
  "Apache",
  "Apache Ant",
  "Apache Solr",
  "App Designer",
  "App Developer",
  "Appcelerator Titanium",
  "Apple Compressor",
  "Apple iBooks Author",
  "Apple Logic Pro",
  "Apple Motion",
  "Apple Safari",
  "Apple Watch",
  "Applescript",
  "Appliance Installation",
  "Appliance Repair",
  "Arabic",
  "Arduino",
  "Argus Monitoring Software",
  "Article Rewriting",
  "Article Submission",
  "Articles",
  "Artificial Intelligence",
  "Arts / Crafts",
  "AS400 / iSeries",
  "Asbestos Removal",
  "ASP",
  "ASP.NET",
  "Asphalt",
  "Assembly",
  "Asterisk PBX",
  "Astrophysics",
  "Attic Access Ladders",
  "Attorney",
  "Audio Production",
  "Audio Services",
  "Audit",
  "Augmented Reality",
  "AutoCAD",
  "Autodesk Inventor",
  "Autodesk Revit",
  "AutoHotkey",
  "Automotive",
  "Autotask",
  "Awnings",
  "Axure",
  "Azure",
  "backbone.js",
  "Balsamiq",
  "Balustrading",
  "Bamboo Flooring",
  "Banner Design",
  "Basque",
  "Bathroom",
  "Bengali",
  "Big Data",
  "BigCommerce",
  "Binary Analysis",
  "Biology",
  "Biotechnology",
  "Bitcoin",
  "Biztalk",
  "Blackberry",
  "Blog",
  "Blog Design",
  "Blog Install",
  "Bluetooth Low Energy (BLE)",
  "BMC Remedy",
  "Book Artist",
  "Book Writing",
  "Bookkeeping",
  "Boonex Dolphin",
  "Bootstrap",
  "Bosnian",
  "Bower",
  "BPO",
  "Brackets",
  "Brain Storming",
  "Branding",
  "Bricklaying",
  "Broadcast Engineering",
  "Brochure Design",
  "BSD",
  "Building",
  "Building Architecture",
  "Building Certifiers",
  "Building Consultants",
  "Building Designer",
  "Building Surveyors",
  "Bulgarian",
  "Bulk Marketing",
  "Business Analysis",
  "Business Cards",
  "Business Catalyst",
  "Business Coaching",
  "Business Intelligence",
  "Business Plans",
  "Business Writing",
  "Buyer Sourcing",
  "C Programming",
  "C# Programming",
  "C++ Programming",
  "CAD/CAM",
  "CakePHP",
  "Call Center",
  "Call Control XML",
  "Capture NX2",
  "Caricature / Cartoons",
  "Carpentry",
  "Carpet Repair / Laying",
  "Carports",
  "Cartography / Maps",
  "Carwashing",
  "CasperJS",
  "Cassandra",
  "Catalan",
  "Catch Phrases",
  "CATIA",
  "Ceilings",
  "Cement Bonding Agents",
  "CGI",
  "Chef Configuration Management",
  "Chemical Engineering",
  "Chordiant",
  "Christmas",
  "Chrome OS",
  "Cinema 4D",
  "Circuit Design",
  "Cisco",
  "Civil Engineering",
  "Classifieds Posting",
  "Clean Technology",
  "Cleaning Carpet",
  "Cleaning Domestic",
  "Cleaning Upholstery",
  "Climate Sciences",
  "CLIPS",
  "Clothesline",
  "Cloud Computing",
  "CMS",
  "Coating Materials",
  "COBOL",
  "Cocoa",
  "Codeigniter",
  "Coding",
  "Cold Fusion",
  "Columns",
  "Commercial Cleaning",
  "Commercials",
  "Communications",
  "Compliance",
  "Computer Graphics",
  "Computer Help",
  "Computer Security",
  "Concept Art",
  "Concept Design",
  "Concreting",
  "Construction Monitoring",
  "Content Writing",
  "Contracts",
  "Conversion Rate Optimisation",
  "Cooking / Recipes",
  "Cooking / Baking",
  "Copy Typing",
  "Copywriting",
  "Corporate Identity",
  "Courses",
  "Covers / Packaging",
  "CRE Loaded",
  "Creative Design",
  "Creative Writing",
  "CRM",
  "Croatian",
  "Cryptography",
  "Crystal Reports",
  "CS-Cart",
  "CSS",
  "CubeCart",
  "CUDA",
  "Customer Service",
  "Customer Support",
  "Czech",
  "Damp Proofing",
  "Danish",
  "Dari",
  "Dart",
  "Data Entry",
  "Data Mining",
  "Data Processing",
  "Data Science",
  "Data Warehousing",
  "Database Administration",
  "Database Development",
  "Database Programming",
  "DataLife Engine",
  "Dating",
  "DDS",
  "Debian",
  "Debugging",
  "Decking",
  "Decoration",
  "Delivery",
  "Delphi",
  "Demolition",
  "Design",
  "Desktop Support",
  "Digital Design",
  "Disposals",
  "Django",
  "DNS",
  "DOS",
  "DotNetNuke",
  "Drafting",
  "Drains",
  "Drones",
  "Drupal",
  "Dthreejs",
  "Dutch",
  "Dynamics",
  "eBay",
  "eBooks",
  "eCommerce",
  "Editing",
  "Education / Tutoring",
  "edX",
  "Elasticsearch",
  "eLearning",
  "eLearning Designer",
  "Electrical Engineering",
  "Electricians",
  "Electronic Forms",
  "Electronics",
  "Email Developer",
  "Email Handling",
  "Email Marketing",
  "Embedded Software",
  "Ember.js",
  "Employment Law",
  "Energy",
  "Engineering",
  "Engineering Drawing",
  "English (UK)",
  "English (US)",
  "English Grammar",
  "English Spelling",
  "Entrepreneurship",
  "ePub",
  "Equipment Hire",
  "Erlang",
  "ERP",
  "Estonian",
  "Etsy",
  "Event Planning",
  "Event Staffing",
  "Excavation",
  "Excel",
  "Express JS",
  "Expression Engine",
  "Extensions / Additions",
  "Face Recognition",
  "Facebook Marketing",
  "Fashion Design",
  "Fashion Modeling",
  "Fencing",
  "Feng Shui",
  "Fiction",
  "FileMaker",
  "Filipino",
  "Filmmaking",
  "Final Cut Pro",
  "Finale / Sibelius",
  "Finance",
  "Financial Analysis",
  "Financial Markets",
  "Financial Planning",
  "Financial Research",
  "Finite Element Analysis",
  "Finnish",
  "Firefox",
  "Flash 3D",
  "Flashmob",
  "Flex",
  "Floor Coatings",
  "Flooring",
  "Flow Charts",
  "Flyer Design",
  "Flyscreens",
  "Food Takeaway",
  "Format / Layout",
  "Fortran",
  "Forum Posting",
  "Forum Software",
  "FPGA",
  "Frames / Trusses",
  "Freelance",
  "FreelancerAPI",
  "FreeSwitch",
  "French",
  "French (Canadian)",
  "Fundraising",
  "Furniture Assembly",
  "Furniture Design",
  "Game Consoles",
  "Game Design",
  "Game Development",
  "GameSalad",
  "Gamification",
  "GarageBand",
  "Gardening",
  "Gas Fitting",
  "Genealogy",
  "General Labor",
  "General Office",
  "Genetic Engineering",
  "Geolocation",
  "Geology",
  "Geospatial",
  "Geotechnical Engineering",
  "German",
  "Ghostwriting",
  "GIMP",
  "Git",
  "Glass / Mirror / Glazing",
  "Golang",
  "Google Adsense",
  "Google Adwords",
  "Google Analytics",
  "Google App Engine",
  "Google Cardboard",
  "Google Chrome",
  "Google Cloud Storage",
  "Google Earth",
  "Google Maps API",
  "Google Plus",
  "Google SketchUp",
  "Google Web Toolkit",
  "Google Webmaster Tools",
  "Google Website Optimizer",
  "GoPro",
  "GPGPU",
  "GPS",
  "Grails",
  "Grant Writing",
  "Graphic Design",
  "Grease Monkey",
  "Greek",
  "Growth Hacking",
  "Grunt",
  "Guttering",
  "Hadoop",
  "Hair Styles",
  "Handyman",
  "Haskell",
  "HBase",
  "Health",
  "Heating Systems",
  "Hebrew",
  "Helpdesk",
  "Heroku",
  "Hindi",
  "Hire me",
  "History",
  "Hive",
  "Home Automation",
  "Home Design",
  "Home Organization",
  "HomeKit",
  "Hot Water",
  "House Cleaning",
  "Housework",
  "HP Openview",
  "HTML",
  "HTML5",
  "Human Resources",
  "Human Sciences",
  "Hungarian",
  "iBeacon",
  "IBM BPM",
  "IBM Tivoli",
  "IBM Websphere Transformation Tool",
  "Icon Design",
  "IIS",
  "IKEA Installation",
  "Illustration",
  "Illustrator",
  "Imaging",
  "iMovie",
  "Indonesian",
  "Industrial Design",
  "Industrial Engineering",
  "Infographics",
  "Inspections",
  "Instagram",
  "Installation",
  "Instrumentation",
  "Insurance",
  "Interior Design",
  "Interiors",
  "Internet Marketing",
  "Internet Research",
  "Internet Security",
  "Interpreter",
  "Interspire",
  "Intuit QuickBooks",
  "Inventory Management",
  "Investment Research",
  "Invitation Design",
  "Ionic Framework",
  "iPad",
  "iPhone",
  "ISO9001",
  "Italian",
  "ITIL",
  "J2EE",
  "J2ME",
  "Jabber",
  "Japanese",
  "Java",
  "JavaFX",
  "Javascript",
  "JD Edwards CNC",
  "JDF",
  "Jewellery",
  "Joomla",
  "Journalist",
  "jQuery / Prototype",
  "JSON",
  "JSP",
  "Kannada",
  "Kinect",
  "Kitchen",
  "Knockout.js",
  "Korean",
  "Label Design",
  "LabVIEW",
  "Landing Pages",
  "Landscape Design",
  "Landscaping",
  "Landscaping / Gardening",
  "Laravel",
  "LaTeX",
  "Latvian",
  "Laundry and Ironing",
  "Lawn Mowing",
  "Leads",
  "Leap Motion SDK",
  "Legal",
  "Legal Research",
  "Legal Writing",
  "LESS/Sass/SCSS",
  "Life Coaching",
  "Lighting",
  "Linear Programming",
  "Link Building",
  "Linkedin",
  "Linnworks Order Management",
  "LINQ",
  "Linux",
  "Lisp",
  "Lithuanian",
  "LiveCode",
  "Locksmith",
  "Logistics / Shipping",
  "Logo Design",
  "Lotus Notes",
  "Lua",
  "Mac OS",
  "Macedonian",
  "Machine Learning",
  "Magento",
  "Magic Leap",
  "Mailchimp",
  "Mailwizz",
  "Make Up",
  "Makerbot",
  "Malay",
  "Malayalam",
  "Maltese",
  "Management",
  "Manufacturing",
  "Manufacturing Design",
  "Map Reduce",
  "MariaDB",
  "Market Research",
  "Marketing",
  "Marketplace Service",
  "Materials Engineering",
  "Mathematics",
  "Matlab and Mathematica",
  "Maya",
  "Mechanical Engineering",
  "Mechatronics",
  "Medical",
  "Medical Writing",
  "Metatrader",
  "MeteorJS",
  "Metro",
  "Microbiology",
  "Microcontroller",
  "Microsoft",
  "Microsoft Access",
  "Microsoft Exchange",
  "Microsoft Expression",
  "Microsoft Hololens",
  "Microsoft Office",
  "Microsoft Outlook",
  "Microsoft SQL Server",
  "Microsoft Visio",
  "Microstation",
  "Millwork",
  "Mining Engineering",
  "Minitlab",
  "MLM",
  "MMORPG",
  "Mobile App Testing",
  "Mobile Phone",
  "MODx",
  "MonetDB",
  "Moodle",
  "Mortgage Brokering",
  "Motion Graphics",
  "Moving",
  "MQTT",
  "Mural Painting",
  "Music",
  "MVC",
  "MYOB",
  "MySpace",
  "MySQL",
  "Nanotechnology",
  "Natural Language",
  "Network Administration",
  "Newsletters",
  "Nginx",
  "Ning",
  "Nintex Forms",
  "Nintex Workflow",
  "node.js",
  "Nokia",
  "Norwegian",
  "NoSQL Couch / Mongo",
  "Nutrition",
  "OAuth",
  "Objective C",
  "OCR",
  "Oculus Mobile SDK",
  "Odoo",
  "Online Writing",
  "Open Cart",
  "Open Journal Systems",
  "OpenBravo",
  "OpenCL",
  "OpenGL",
  "OpenSceneGraph",
  "OpenSSL",
  "OpenStack",
  "OpenVMS",
  "Oracle",
  "Order Processing",
  "Organizational Change Management",
  "OSCommerce",
  "Package Design",
  "Packing / Shipping",
  "Painting",
  "Palm",
  "Papiamento",
  "Parallax Scrolling",
  "Parallel Processing",
  "Parallels Automation",
  "Parallels Desktop",
  "Patents",
  "Pattern Making",
  "Pattern Matching",
  "Pavement",
  "PayPal API",
  "Payroll",
  "Paytrace",
  "PCB Layout",
  "PDF",
  "PEGA PRPC",
  "PencilBlue CMS",
  "Pentaho",
  "PeopleSoft",
  "Periscope",
  "Perl",
  "Personal Development",
  "Pest Control",
  "Pet Sitting",
  "Petroleum Engineering",
  "Phone Support",
  "PhoneGap",
  "Photo Editing",
  "Photography",
  "Photoshop",
  "Photoshop Coding",
  "Photoshop Design",
  "PHP",
  "Physics",
  "PICK Multivalue DB",
  "Pickup",
  "Pinterest",
  "Piping",
  "PLC / SCADA",
  "Plesk",
  "Plugin",
  "Plumbing",
  "Poet",
  "Poetry",
  "Polish",
  "Portuguese",
  "Portuguese (Brazil)",
  "Post-Production",
  "Poster Design",
  "PostgreSQL",
  "Powerpoint",
  "Powershell",
  "Pre-production",
  "Presentations",
  "Press Releases",
  "Prestashop",
  "Prezi",
  "Print",
  "Procurement",
  "Product Descriptions",
  "Product Design",
  "Product Management",
  "Product Sourcing",
  "Programming",
  "Project Management",
  "Project Scheduling",
  "Prolog",
  "Proofreading",
  "Property Development",
  "Property Law",
  "Property Management",
  "Proposal/Bid Writing",
  "Protoshare",
  "PSD to HTML",
  "PSD2CMS",
  "Psychology",
  "Public Relations",
  "Publishing",
  "Punjabi",
  "Puppet",
  "Python",
  "QlikView",
  "Qualtrics Survey Platform",
  "Quantum",
  "QuarkXPress",
  "QuickBase",
  "R Programming Language",
  "RapidWeaver",
  "Raspberry Pi",
  "Ray-tracing",
  "React.js",
  "Real Estate",
  "REALbasic",
  "Recruitment",
  "Red Hat",
  "Redis",
  "Redshift",
  "Regular Expressions",
  "Remote Sensing",
  "Removalist",
  "Renewable Energy Design",
  "Report Writing",
  "Research",
  "RESTful",
  "Resumes",
  "Reviews",
  "Risk Management",
  "Robotics",
  "Rocket Engine",
  "Romanian",
  "Roofing",
  "RTOS",
  "Ruby",
  "Ruby on Rails",
  "Russian",
  "RWD",
  "Sales",
  "Salesforce App Development",
  "Salesforce.com",
  "Samsung",
  "Samsung Accessory SDK",
  "SAP",
  "SAS",
  "Scala",
  "Scheme",
  "Scientific Research",
  "Screenwriting",
  "Script Install",
  "Scrum",
  "Scrum Development",
  "Sculpturing",
  "Search Engine Marketing",
  "Sencha / YahooUI",
  "SEO",
  "Serbian",
  "Sewing",
  "Sharepoint",
  "Shell Script",
  "Shopify",
  "Shopify Templates",
  "Shopping",
  "Shopping Carts",
  "Short Stories",
  "Siebel",
  "Sign Design",
  "Silverlight",
  "Simplified Chinese (China)",
  "Slogans",
  "Slovakian",
  "Slovenian",
  "Smarty PHP",
  "Snapchat",
  "Social Engine",
  "Social Media Marketing",
  "Social Networking",
  "Socket IO",
  "Software Architecture",
  "Software Development",
  "Software Testing",
  "Solaris",
  "Solidworks",
  "Sound Design",
  "Spanish",
  "Spanish (Spain)",
  "Spark",
  "Speech Writing",
  "Sphinx",
  "Splunk",
  "Sports",
  "SPSS Statistics",
  "SQL",
  "SQLite",
  "Squarespace",
  "Squid Cache",
  "Startups",
  "Stationery Design",
  "Statistical Analysis",
  "Statistics",
  "Steam API",
  "Sticker Design",
  "Storage Area Networks",
  "Storyboard",
  "Stripe",
  "Structural Engineering",
  "Subversion",
  "SugarCRM",
  "Supplier Sourcing",
  "Surfboard Design",
  "Swedish",
  "Swift",
  "Symbian",
  "Symfony PHP",
  "System Admin",
  "T-Shirts",
  "Tableau",
  "Tally Definition Language",
  "Tamil",
  "TaoBao API",
  "Tattoo Design",
  "Tax",
  "Tax Law",
  "Technical Support",
  "Technical Writing",
  "Tekla Structures",
  "Telecommunications Engineering",
  "Telemarketing",
  "Telephone Handling",
  "Telugu",
  "Templates",
  "Test Automation",
  "Testing / QA",
  "TestStand",
  "Textile Engineering",
  "Thai",
  "Tibco Spotfire",
  "Tiling",
  "Time Management",
  "Titanium",
  "Tizen SDK for Wearables",
  "Traditional Chinese (Hong Kong)",
  "Traditional Chinese (Taiwan)",
  "Training",
  "Transcription",
  "Translation",
  "Travel Writing",
  "Troubleshooting",
  "Tumblr",
  "Turkish",
  "Twitter",
  "Typescript",
  "TYPO3",
  "Typography",
  "Ubuntu",
  "Ukrainian",
  "Umbraco",
  "UML Design",
  "Unit4 Business World",
  "Unity 3D",
  "UNIX",
  "Urdu",
  "Usability Testing",
  "User Experience Design",
  "User Interface / IA",
  "User Interface Design",
  "Valuation / Appraisal",
  "Varnish Cache",
  "VB.NET",
  "vBulletin",
  "Vectorization",
  "Veeam",
  "Vehicle Signage",
  "Verilog",
  "VHDL",
  "VertexFX",
  "Video Broadcasting",
  "Video Editing",
  "Video Production",
  "Video Services",
  "Video Upload",
  "Videography",
  "Vietnamese",
  "Viral Marketing",
  "Virtual Assistant",
  "Virtual Worlds",
  "Virtuemart",
  "Virtuozzo",
  "Visa / Immigration",
  "Visual Arts",
  "Visual Basic",
  "Visual Basic for Apps",
  "Visual Foxpro",
  "Visual Merchandising",
  "Visualization",
  "VMware",
  "Voice Artist",
  "Voice Talent",
  "VoiceXML",
  "VoIP",
  "Volusion",
  "VPS",
  "vTiger",
  "Vuforia",
  "WatchKit",
  "Web Hosting",
  "Web Scraping",
  "Web Search",
  "Web Security",
  "Web Services",
  "webMethods",
  "WebOS",
  "Website Design",
  "Website Management",
  "Website Testing",
  "Weddings",
  "Weebly",
  "Welsh",
  "WHMCS",
  "WIKI",
  "Wikipedia",
  "Windows 8",
  "Windows API",
  "Windows CE",
  "Windows Desktop",
  "Windows Mobile",
  "Windows Phone",
  "Windows Server",
  "Wireframes",
  "Wireless",
  "Wix",
  "Wolfram",
  "WooCommerce",
  "Word",
  "Word Processing",
  "WordPress",
  "Workshops",
  "WPF",
  "Wufoo",
  "x86/x64 Assembler",
  "Xamarin",
  "Xero",
  "XML",
  "XMPP",
  "Xojo",
  "Xoops",
  "XPages",
  "XQuery",
  "XSLT",
  "Yahoo! Store Design",
  "Yard Work / Removal",
  "Yarn",
  "Yiddish",
  "Yii",
  "YouTube",
  "Zbrush",
  "Zen Cart",
  "Zend",
  "Zendesk",
  "Zoho"
].sort())
;
