"use client";

import React from "react";

import { useResponsive } from "@/styles";

import { useContractsListPage } from "../ContractsListPage.context";
import { ContractTab } from "../ContractsListPage.types";
import { StyledNoData } from "../ContractsListPage.styles";

const NoContracts: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { activeTab, contractList, isContractListLoading } =
    useContractsListPage();

  const hasNoContracts = !contractList.length && !isContractListLoading;

  if (!hasNoContracts) {
    return hasNoContracts;
  }

  if (activeTab === ContractTab.Completed) {
    return (
      <StyledNoData
        image="/assets/images/pages/contracts/no_contracts.svg"
        title={<>You don&apos;t have any completed Jobs</>}
        content=""
      />
    );
  }

  return (
    <StyledNoData
      image="/assets/images/pages/contracts/no_contracts.svg"
      title={<>You don&apos;t have any active Jobs</>}
      content={
        isScreenSmallerThanTablet
          ? "Select the Plus (+) icon below to create your first job"
          : "Click create offer above to create your first job"
      }
    />
  );
};

export default NoContracts;
