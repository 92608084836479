import React, { useState } from "react";

import TextField from "@/components/input/TextField";
import Select from "@/components/input/Select";

import { useContractRejectForm } from "../../ContractRejectForm.context";
import { REJECTION_REASONS } from "../../ContractRejectForm.config";

const ReasonStep: React.FC = () => {
  const { values, setFieldValue, touchedAndHasError } = useContractRejectForm();

  const [showCustomReasonInput, setShowCustomReasonInput] = useState(
    !!values.reason &&
      !!REJECTION_REASONS.find(({ value }) => value !== values.reason)
  );

  return (
    <>
      <Select
        placeholder="Reason for rejection"
        value={values.reason || ""}
        onChange={({ label, value }) => {
          if (label === `Other`) {
            setShowCustomReasonInput(true);
            setFieldValue("reason", "");
          } else {
            setShowCustomReasonInput(false);
            setFieldValue("reason", value);
          }
        }}
        options={REJECTION_REASONS}
        drawerProps={{
          canDrag: false,
          drawerMidHeight: "176px",
          headerProps: {
            children: "Rejection reasons?",
            className: "px-3 pb-2",
          },
        }}
      />

      {showCustomReasonInput && (
        <TextField
          variant="background"
          className="mt-3 mb-5"
          placeholder="Reason"
          value={values.reason || ""}
          onChange={(value) => {
            setFieldValue("reason", value);
          }}
          hasError={touchedAndHasError("reason")}
        />
      )}

      <TextField
        textArea
        variant="background"
        className="mt-3"
        placeholder="Details"
        value={values.description || ""}
        onChange={(value) => {
          setFieldValue("description", value);
        }}
        hasError={touchedAndHasError("description")}
      />
    </>
  );
};

export default ReasonStep;
