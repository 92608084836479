"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Body, Label } from "@/components/Typography";
import Button from "@/components/input/Button";

export const StyledCard = styled.div`
  position: relative;
  color: var(--clr-neutral-solid-500, #666d80);
  background: var(--clr-neutral-solid-25, #f6f8fa);
  padding: 0.75rem;
  border-radius: 0.75rem;
  z-index: 0;
`;

export const StyledTitle = styled(Label)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--clr-text-900, #0d0d12);
  padding-right: 2rem;

  & > span {
    flex-grow: 1;
  }
`;

export const StyledContent = styled(Body)`
  color: var(--clr-text-500, #666d80);
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 0.6rem;
  right: 0;
  z-index: 2;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
