import React from "react";
import lodashGet from "lodash/get";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Icon from "@/components/misc/Icon";
import {
  ContractCreatorType,
  getContractManagePageUrl,
  useManageContract,
} from "@/services/ContractsService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";
import { useNavigate } from "@/services/Routing";

import {
  StyledBottomContainer,
  StyledButton,
  StyledChatButton,
  StyledContainer,
  StyledTopContainer,
} from "./ContractPaymentSuccessPage.styles";

const ContractPaymentSuccessPage: React.FC<{ email?: string }> = ({
  email,
}) => {
  const {
    contractDetails,
    isLoading: isLoadingContractDetails,
    contractId,
  } = useManageContract({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
  });
  const { login, isAuthenticated } = useAuth();
  const { navigate } = useNavigate();

  if (isLoadingContractDetails) {
    return <SplashScreen />;
  }

  const milestone1Name = lodashGet(contractDetails, "milestones[0].name", "");
  const contractUrl = getContractManagePageUrl(contractId);

  return (
    <StyledContainer className="container">
      <StyledTopContainer>
        <ImageTitleContent
          title="Payment Received!"
          content={
            <>
              You have successfully funded
              {milestone1Name ? ` ${milestone1Name}` : ""}.
            </>
          }
          image="/assets/images/pages/contracts/payment_success.svg"
        />
      </StyledTopContainer>

      <StyledBottomContainer>
        {isAuthenticated ? (
          <StyledButton
            link={contractUrl}
            onClick={(e) => {
              e.preventDefault();
              navigate(contractUrl, true);
            }}
          >
            View Job <Icon isSrcRelative src="share.svg" size="xxs" />
          </StyledButton>
        ) : (
          <StyledButton
            onClick={() => {
              login({ redirectUrl: contractUrl, email });
            }}
          >
            View Job <Icon isSrcRelative src="share.svg" size="xxs" />
          </StyledButton>
        )}

        <StyledChatButton
          contractId={contractId}
          variant="ghost"
          userType={ContractCreatorType.Payee}
          onboardingEmail={email}
        />
      </StyledBottomContainer>
    </StyledContainer>
  );
};

export default ContractPaymentSuccessPage;
