"use client";

import React, { useCallback, useMemo, useState } from "react";
import * as yup from "yup";

import TextField from "@/components/input/TextField";
import { useUpdateProfile } from "@/services/UserService";
import { useModalState } from "@/components/misc/Modal";
import { useToast } from "@/components/misc/Toast";

import EditFormButton from "./EditFormButtonNew";
import styled from "@emotion/styled";

const MAX_ALLOWED_LENGTH = 50;

const VALIDATION_SCHEMA = yup.object().shape({
  heading: yup.string().required().min(1).max(MAX_ALLOWED_LENGTH),
});

const StyledCount = styled.div`
  display: inline-flex;
  align-items: center;
  height: 3em;
`;

type FormValues = {
  heading: string;
};

const EditHeading: React.FC = () => {
  const { createToast } = useToast();
  const { profileData, updateField } = useUpdateProfile();
  const [isUpdating, setIsUpdating] = useState(false);
  const modalState = useModalState({
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const initialValues = useMemo(
    () =>
      ({
        heading: profileData.heading || "",
      } satisfies FormValues),
    [profileData]
  );

  const apply = useCallback(
    ({ heading }: FormValues) => {
      setIsUpdating(true);

      return updateField("heading", heading)
        .then(() => {
          createToast({
            title: "Heading updated",
            timeoutInMilliseconds: 5000,
          });
          modalState.close();
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [updateField, modalState, createToast]
  );

  return (
    <EditFormButton<FormValues>
      heading="Edit Heading"
      description="Describe what you do in a few words."
      modalState={modalState}
      formProps={{
        initialValues,
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
      }}
      content={({
        context: {
          values: { heading },
          setFieldValue,
          touchedAndHasError,
          setFieldTouched,
        },
      }) => (
        <TextField
          textArea
          autoFocus
          style={{ height: "fit-content" }}
          useFloatingLabel={false}
          variant="background"
          placeholder="Type here..."
          appendContent={
            <StyledCount>
              {heading.length}/{MAX_ALLOWED_LENGTH}
            </StyledCount>
          }
          value={heading}
          onChange={(value) => {
            setFieldValue("heading", value.substring(0, MAX_ALLOWED_LENGTH));
          }}
          hasError={touchedAndHasError("heading")}
          onBlur={() => {
            setFieldTouched("heading");
          }}
        />
      )}
      saveButtonProps={{
        disabled: isUpdating,
      }}
      cancelButtonProps={{
        disabled: isUpdating,
      }}
    />
  );
};

export default EditHeading;
