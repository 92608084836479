"use client";

import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Body, Heading, Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import { ANIMATION_CLASSNAMES, screenLargerThan } from "@/styles";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Tag from "@/components/misc/Tag";
import TagListInput from "@/components/input/TagListInputNew";
import { areStringsSubstring } from "@/utils/string";

import { SKILL_FIELD_URL_HASH, useProfileForm } from "../../ProfileForm.config";
import SKILLS_LIST from "../../content/skills.json";

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  height: 100%;
  gap: 1.5rem;

  & > *:last-child {
    margin-top: auto;
  }
`;

const StyledTagsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledPageWithHeaderLayout = styled(PageWithHeaderLayout)`
  ${screenLargerThan.tablet} {
    height: 40rem;
    max-height: calc(100vh - 4.5rem);
    overflow: hidden;

    main {
      overflow: hidden;
    }
  }
`;

const StyledTagContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const StyledTagButton = styled(Button)`
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const SkillInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, submitForm, setFieldValue } = useProfileForm();
  const [skills, setSkills] = useState<string[]>(values.skills);
  const [search, setSearch] = useState("");
  const modalState = useModalState({
    urlHash: SKILL_FIELD_URL_HASH,
    onOpenChange: (isOpen) => {
      if (isOpen) {
        setSkills(values.skills);
      }
    },
  });

  const handleSave = useCallback(() => {
    setSearch("");
    setFieldValue("skills", skills);

    setTimeout(() => {
      submitForm();
    }, 100);

    modalState.close();
  }, [modalState, submitForm, skills, setFieldValue]);

  const options = useMemo(() => {
    return SKILLS_LIST.filter((name) => !skills.includes(name))
      .filter((name) => areStringsSubstring(name, search, false))
      .sort(
        (a, b) =>
          +Boolean(b.toLowerCase().startsWith(search.toLowerCase())) -
          +Boolean(a.toLowerCase().startsWith(search.toLowerCase()))
      )
      .map((name) => ({
        children: <>{name}</>,
        value: name,
      }));
  }, [search, skills]);

  const inputContentJsx = (
    <>
      <div>
        <Heading
          size="md"
          style={{ color: "var(--clr-neutral-solid-800, #1a1b25)" }}
        >
          What skills and services do you offer?
        </Heading>
        <Body
          size="sm"
          className="mt-2 mb-3"
          style={{ color: "var( --clr-neutral-solid-400, #818898)" }}
        >
          Select up to 10 skills or services to add to your profile
        </Body>
        <TagListInput
          focusOnMount
          variant="outline"
          placeholder="Search"
          inputValue={search}
          onInputChange={setSearch}
          value={skills}
          onChange={setSkills}
        />
      </div>

      <StyledTagContainer>
        {options.map(({ value }, i) => (
          <StyledTagButton
            className={ANIMATION_CLASSNAMES.FADE_IN}
            variant="secondary"
            colorVariant="gray"
            key={value}
            onClick={() => {
              setSearch("");
              setSkills((skills) => {
                skills = [...skills];
                skills.push(value);
                return skills;
              });
            }}
          >
            {value}
          </StyledTagButton>
        ))}
      </StyledTagContainer>

      <Button className="w-100" onClick={handleSave}>
        Apply
      </Button>
    </>
  );

  return (
    <>
      <div className={className}>
        <Label
          size="lg"
          as="label"
          htmlFor="timezone"
          onClick={modalState.open}
        >
          Skills & Services
        </Label>

        {!!values.skills.length && (
          <StyledTagsContainer className="mt-2" onClick={modalState.open}>
            {values.skills.map((skill, i) => (
              <Tag
                key={i}
                className="px-2"
                variant="outlined"
                colorVariant="gray"
                style={{
                  padding: "0.5rem 1rem",
                  minWidth: "5rem",
                  justifyContent: "center",
                }}
              >
                {skill}
              </Tag>
            ))}
          </StyledTagsContainer>
        )}

        <Button
          variant="secondary"
          colorVariant="gray"
          className="w-100 mt-3"
          onClick={modalState.open}
        >
          Add new
        </Button>
      </div>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
        desktopProps={{ width: "768px" }}
      >
        <StyledPageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Skills & Services" },
          }}
        >
          <StyledModalContent>{inputContentJsx}</StyledModalContent>
        </StyledPageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default SkillInput;
