import { useCallback, useEffect, useRef, useState } from "react";

import { AuthMedium, useAuth } from "@/services/Authentication";
import LocalStorage from "@/services/LocalStorage";
import { useNavigate, useParamState, usePathname } from "@/services/Routing";
import { userService } from "@/config/services";
import { getChatRoomPageUrl } from "@/services/ChatServiceNew";
import { SITE_PATHS } from "@/config/routing";

export function checkIsSSOAuthMedium(authMedium: AuthMedium) {
  return (["google", "fb", "linkedin"] as AuthMedium[]).includes(authMedium);
}

function useContactUser({ paypipeId }: { paypipeId: string }) {
  const localStorageRef = useRef(
    new LocalStorage<{
      cache: Record<string, { message: string }>;
    }>("PAYPIPE.CONTACT")
  );

  const initialMessageRef = useRef(
    paypipeId
      ? localStorageRef.current.get(`cache.${paypipeId}.message`) || ""
      : ""
  );
  const [message, setMessage] = useState<string>(initialMessageRef.current);
  // const [isSending, setIsSending] = useState(false);

  const { isAuthenticated } = useAuth();
  const { navigate } = useNavigate();
  const { pathname } = usePathname();

  const isContactFlowFromUrl = pathname === SITE_PATHS.CONTACT_USER_PAGE;
  const isPostAuthContactFlow =
    isAuthenticated && isContactFlowFromUrl && !!initialMessageRef.current;

  const triggeredAutoContactRef = useRef(false);
  const [isSending, setIsSending] = useParamState<boolean>("sending", false, {
    parseJson: true,
  });

  //----------------------------------

  const saveMessageInCache = useCallback(() => {
    localStorageRef.current.set(`cache.${paypipeId}`, { message });
  }, [message, paypipeId]);

  const clearMessageInCache = useCallback(() => {
    localStorageRef.current.remove(`cache.${paypipeId}`);
  }, [paypipeId]);

  const handleContact = useCallback(
    (data: { paypipeId: string; message: string }) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const p = userService.contact(data);
      return p.then((res) => {
        clearMessageInCache();
        navigate(getChatRoomPageUrl(res.data.data.channelId), true);
      });
    },
    [navigate, clearMessageInCache, isSending, setIsSending]
  );

  const triggerContact = useCallback(() => {
    if (isAuthenticated) {
      return handleContact({ paypipeId, message });
    }

    saveMessageInCache();
  }, [handleContact, isAuthenticated, saveMessageInCache, paypipeId, message]);

  //-----------------------

  useEffect(() => {
    if (triggeredAutoContactRef.current) {
      return;
    }

    if (!isPostAuthContactFlow || !message || !paypipeId) {
      return;
    }

    const message_ = localStorageRef.current.get(`cache.${paypipeId}.message`);
    clearMessageInCache();
    if (message_) {
      handleContact({ paypipeId, message: message_ });
    }
  }, [
    isPostAuthContactFlow,
    message,
    paypipeId,
    handleContact,
    clearMessageInCache,
  ]);

  //-----------------------

  return {
    contact: triggerContact,
    message,
    setMessage,
    saveMessageInCache,
    isPostAuthContactFlow,
    isSending,
  };
}

export default useContactUser;
