"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePreventScroll } from "react-aria";

import {
  StyledBackdrop,
  StyledContentSection,
  StyledDrawer,
  StyledHandle,
  StyledHeader,
} from "./Drawer.styles";
import { DrawerPostion, IDrawerProps } from "./Drawer.types";
import {
  ANIMATION_CLASSNAMES,
  getViewPortHeightCssString,
  useResponsive,
} from "@/styles";
import Modal from "@/components/misc/Modal";
import Portal from "@/components/misc/Portal";

import useDrag from "./hooks/useDrag";

const Drawer: React.FC<IDrawerProps> = ({
  state,
  children,
  drawerMidHeight,
  canDrag: canDragFromProps = true,
  headerProps,
  useContentHeightAsMidHeight = true,
  modalProps = {},
  useModalOnDesktop = true,
  includeTabBarHeight = true,
  zIndex,
  fullscreenByDefault,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const [screenSize] = useState({
    w: window.screen.width,
    h: window.screen.height,
  });

  usePreventScroll({ isDisabled: !state.isOpen });

  const elStartHeightRef = useRef(0);

  const backdropRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const contentSectionRef = useRef<HTMLDivElement | null>(null);

  const [contentContainerRef, setContentContainerRef] =
    useState<HTMLDivElement | null>(null);

  const [dragElRef, setDragElRef] = useState<HTMLDivElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const [position, setPosition] = useState<DrawerPostion>(
    state.isOpen ? DrawerPostion.midScreen : DrawerPostion.closed
  );
  const positionRef = useRef<DrawerPostion>(position);

  //---------------

  const canDrag = canDragFromProps || position === DrawerPostion.fullScreen;

  const getContentHeight = useCallback(() => {
    if (!contentContainerRef) return 0;

    const contentHeight = contentContainerRef.getBoundingClientRect().height;

    return contentHeight;
  }, [contentContainerRef]);

  const getHeaderHeight = useCallback(() => {
    if (!headerRef.current) return 0;

    const headerHeight = headerRef.current.getBoundingClientRect().height;

    return headerHeight;
  }, []);

  const setDrawerPosition = useCallback(
    (newPostion: DrawerPostion) => {
      let heightCssValue = "0";

      if (newPostion === DrawerPostion.fullScreen) {
        heightCssValue = getViewPortHeightCssString();
      } else if (newPostion === DrawerPostion.midScreen) {
        heightCssValue = drawerMidHeight || "60vh";

        if (useContentHeightAsMidHeight) {
          const headerHeight = getHeaderHeight() || 32;
          const contentHeight = getContentHeight();
          const tabBarEl = document.getElementById("tab-bar");
          const tabBarHeight =
            includeTabBarHeight && tabBarEl
              ? tabBarEl.getBoundingClientRect().height
              : 0;
          const drawerHeight = headerHeight + contentHeight + tabBarHeight + 2;

          if (contentHeight > screenSize.h) {
            newPostion = DrawerPostion.fullScreen;
            heightCssValue = getViewPortHeightCssString();
          } else {
            heightCssValue = heightCssValue = `${drawerHeight}px`;
          }
        }
      } else {
        if (backdropRef.current) {
          backdropRef.current.style.background = "transparent";
        }

        if (drawerRef.current) {
          drawerRef.current.style.display = "none";
        }
      }

      positionRef.current = newPostion;
      setPosition(newPostion);

      const disableContentSectionScroll = (value = true) => {
        if (contentSectionRef.current) {
          contentSectionRef.current.style.overflow = value ? "hidden" : "auto";
        }
      };

      disableContentSectionScroll();

      setTimeout(() => {
        if (drawerRef.current) {
          drawerRef.current.style.height = heightCssValue;
        }
      }, 50);

      setTimeout(() => {
        disableContentSectionScroll(false);
      }, 1000);

      setTimeout(() => {
        if (newPostion === DrawerPostion.closed) {
          state.close();
        }
      }, 320);
    },
    [
      state,
      drawerMidHeight,
      useContentHeightAsMidHeight,
      getContentHeight,
      getHeaderHeight,
      screenSize.h,
      includeTabBarHeight,
    ]
  );

  const close = useCallback(() => {
    if (state.isOpen) setDrawerPosition(DrawerPostion.closed);
  }, [setDrawerPosition, state]);

  //---------------

  const { isDragging } = useDrag({
    el: dragElRef as any,
    onMoveStart: () => {
      if (!drawerRef.current || !canDrag) {
        return;
      }

      elStartHeightRef.current =
        drawerRef.current.getBoundingClientRect().height;
    },
    onMove: (data) => {
      if (data.y === 0 || !drawerRef.current || !canDrag) {
        return;
      }

      const oldHeight = elStartHeightRef.current;
      const height = oldHeight + -data.deltaY;
      let heightInPercent = (height * 100) / screenSize.h;
      heightInPercent = Math.min(100, heightInPercent);

      if (drawerRef.current)
        drawerRef.current.style.height = `${heightInPercent}vh`;
    },
    onDrop: (data) => {
      if (!drawerRef.current || !canDrag) {
        return;
      }

      const deltaYPercent = (-data.deltaY * 100) / screenSize.h;
      let newPostion = positionRef.current;

      if (positionRef.current === DrawerPostion.midScreen) {
        if (deltaYPercent > 5) {
          newPostion = DrawerPostion.fullScreen;
        } else if (deltaYPercent < -5) {
          newPostion = DrawerPostion.closed;
        }
      } else if (positionRef.current === DrawerPostion.fullScreen) {
        if (deltaYPercent < -10) {
          newPostion = DrawerPostion.closed;
        }
      }

      setDrawerPosition(newPostion);
    },
  });

  //---------------

  useEffect(() => {
    if (state.isOpen && positionRef.current === DrawerPostion.closed) {
      setDrawerPosition(
        fullscreenByDefault ? DrawerPostion.fullScreen : DrawerPostion.midScreen
      );
    } else if (!state.isOpen && positionRef.current !== DrawerPostion.closed) {
      setDrawerPosition(DrawerPostion.closed);
    }
  }, [state, setDrawerPosition, fullscreenByDefault]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          if (positionRef.current === DrawerPostion.midScreen) {
            let heightCssValue = drawerMidHeight || "60vh";
            let newPostion: DrawerPostion = positionRef.current;

            if (useContentHeightAsMidHeight) {
              const headerHeight = getHeaderHeight() || 32;
              const contentHeight = getContentHeight();
              const tabBarEl = document.getElementById("tab-bar");
              const tabBarHeight =
                includeTabBarHeight && tabBarEl
                  ? tabBarEl.getBoundingClientRect().height
                  : 0;
              const drawerHeight =
                headerHeight + contentHeight + tabBarHeight + 2;

              if (contentHeight > screenSize.h) {
                newPostion = DrawerPostion.fullScreen;
                heightCssValue = getViewPortHeightCssString();
              } else {
                heightCssValue = heightCssValue = `${drawerHeight}px`;
              }
            }

            setTimeout(() => {
              if (drawerRef.current) {
                drawerRef.current.style.height = heightCssValue;
              }
            }, 50);

            positionRef.current = newPostion;
            setPosition(newPostion);
          }
        }
      }
    });

    if (contentContainerRef) {
      resizeObserver.observe(contentContainerRef);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    useContentHeightAsMidHeight,
    drawerMidHeight,
    getContentHeight,
    getHeaderHeight,
    screenSize.h,
    contentContainerRef,
    includeTabBarHeight,
  ]);

  //---------------

  if (!state.isOpen) {
    return null;
  }

  if (useModalOnDesktop && !isScreenSmallerThanTablet) {
    return (
      <Modal {...modalProps} state={state}>
        {children}
      </Modal>
    );
  }

  return (
    <Portal>
      <StyledBackdrop
        ref={backdropRef}
        onClick={close}
        style={{ zIndex: zIndex || (includeTabBarHeight ? 3 : 6) }}
      />

      <StyledDrawer
        $isDragging={isDragging}
        $position={position}
        ref={drawerRef}
        $initialHeight={drawerMidHeight}
        style={{ zIndex: zIndex ? zIndex + 1 : includeTabBarHeight ? 4 : 7 }}
      >
        <StyledHeader ref={headerRef} className={headerProps?.className}>
          {headerProps?.children}
          <StyledHandle
            ref={setDragElRef}
            style={{ display: canDrag ? "block" : "none" }}
          />
        </StyledHeader>

        <StyledContentSection ref={contentSectionRef} $isDragging={isDragging}>
          <div
            ref={setContentContainerRef}
            className={ANIMATION_CLASSNAMES.FADE_IN}
          >
            {children}
          </div>
        </StyledContentSection>
      </StyledDrawer>
    </Portal>
  );
};

export default Drawer;
