"use client";

import React, { useMemo } from "react";

import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import { useUserActions } from "@/services/UserService";
import { ActionCardLoading } from "@/components/misc/ActionCard";

import {
  StyledActionCard,
  StyledCardsContainer,
  StyledPage,
} from "./ActionsPage.styles";
import {
  filterOutBrokenContracts,
  useContractList,
} from "@/services/ContractsService";
import ContractCard from "@/features/contracts/ContractCard";

const ActionsPage: React.FC = () => {
  const { actions: actionsFromHook, isLoading: isLoadingActions } =
    useUserActions();
  const { data: contractListFromHook, isLoading: isLoadingContractList } =
    useContractList({
      onlyJobs: true,
      onlyActiveJobs: true,
      onlyWithActions: true,
    });

  const contractlist = useMemo(
    () => filterOutBrokenContracts(contractListFromHook),
    [contractListFromHook]
  );

  const urgentActions = useMemo(
    () => actionsFromHook.filter((item) => item.priority === "urgent"),
    [actionsFromHook]
  );

  const actions = useMemo(
    () => actionsFromHook.filter((item) => item.priority !== "urgent"),
    [actionsFromHook]
  );

  const isLoading = isLoadingActions || isLoadingContractList;

  return (
    <MobilePrimaryLayout headerProps={{ logo: { component: "Actions" } }}>
      <div className="container">
        <StyledPage>
          <StyledCardsContainer>
            {isLoading ? (
              <>
                <ActionCardLoading className="mb-3" />
                <ActionCardLoading className="mb-3" />
                <ActionCardLoading className="mb-3" />
                <ActionCardLoading className="mb-3" />
              </>
            ) : (
              <>
                {urgentActions.map((item, i) => (
                  <StyledActionCard key={i} {...item} />
                ))}
                {contractlist.map((contract) => (
                  <ContractCard
                    showCta
                    key={contract.id}
                    contract={contract}
                    className="mt-3"
                  />
                ))}
                {actions.map((item, i) => (
                  <StyledActionCard key={i} {...item} />
                ))}
              </>
            )}
          </StyledCardsContainer>
        </StyledPage>
      </div>
    </MobilePrimaryLayout>
  );
};

export default ActionsPage;
