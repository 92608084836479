import React from "react";
import styled from "@emotion/styled";

import ImageLayout from "@/features/layouts/ImageLayout";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { SITE_PATHS } from "@/config/routing";
import { Body } from "@/components/Typography";
import { screenSmallerThan, useResponsive } from "@/styles";

import {
  StyledHeading,
  StyledOnboardingContentSection,
} from "../ClaimIdPage.styles";

const POINTS = [
  {
    content: "Customise your Professional page to make it yours",
  },
  {
    content: "Showcase your skills, experiences and services",
  },
  {
    content: "Share your Paypipe ID with clients and your network",
  },
];

const StyledLayout = styled(ImageLayout)`
  text-align: left;

  & > *:first-child {
    display: flex;
    align-items: flex-end;
    padding: 1rem;
    padding-block-end: 6rem;

    .heading {
      color: #fff;
    }
  }
`;

const StyledPoints = styled.div`
  background: #f8fafb;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;

  .heading {
    font-size: 1.25rem !important;
    font-weight: 700;
  }

  ul {
    margin-top: 1rem;

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        line-height: 1.25em;
        flex-grow: 1;
      }
    }

    li + li {
      margin-top: 0.75rem;
    }
  }

  ${screenSmallerThan.tablet} {
    position: relative;
    z-index: 1;
    margin-top: -6.25rem;
  }
`;

const StyledContentSection = styled.div`
  max-width: 720px;
  margin-inline: auto;
  flex-direction: column;
`;

const SuccessScreen: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const pointsJsx = (
    <>
      <Body size="lg" className="heading">
        Here's what's next:
      </Body>
      <ul>
        {POINTS.map(({ content }, i) => (
          <li key={i}>
            <Icon isSrcRelative src="tick_rounded_2.svg" size="sm" />
            <span>{content}</span>
          </li>
        ))}
      </ul>
    </>
  );

  if (isScreenSmallerThanTablet) {
    return (
      <StyledLayout
        bgImageSrc="/assets/pages/paypipe-id/claim_success.jpg"
        imageSectionContent={
          <StyledHeading size="md" className="heading">
            Congrats! your Paypipe ID was created
          </StyledHeading>
        }
      >
        <StyledOnboardingContentSection>
          <StyledPoints>
            {pointsJsx}

            <Button
              borderRadius="default"
              className="w-100 primary-button py-3 mt-4"
              link={SITE_PATHS.EDIT_PROFILE_PAGE}
            >
              Get Started
            </Button>
          </StyledPoints>
        </StyledOnboardingContentSection>
      </StyledLayout>
    );
  }

  return (
    <StyledLayout
      maxContentWidth="100%"
      bgImageSrc="/assets/pages/paypipe-id/claim_success.jpg"
    >
      <StyledOnboardingContentSection>
        <StyledContentSection>
          <StyledHeading size="md">
            Congrats! your Paypipe ID was created
          </StyledHeading>

          <StyledPoints className="mt-4">{pointsJsx}</StyledPoints>

          <Button
            className="w-100 primary-button py-3 mt-5"
            link={SITE_PATHS.EDIT_PROFILE_PAGE}
          >
            Get Started
          </Button>
        </StyledContentSection>
      </StyledOnboardingContentSection>
    </StyledLayout>
  );
};

export default SuccessScreen;
