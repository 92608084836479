"use client";

import React, { useCallback, useState } from "react";

import PageWithHeaderLayoutProps from "@/features/layouts/PageWithHeaderLayout";
import Button from "@/components/input/Button";
import SplashScreen from "@/features/pages/app/SplashScreen";
import {
  getContractRejectPageUrl,
  useAcceptOffer,
  useConfirmFundEmailModal,
  useContractDetails,
  useContractUrlId,
} from "@/services/ContractsService";
import { analytics, appFeatures, contractService } from "@/config/services";
import { useAuth } from "@/services/Authentication";
import { getViewPortHeightCssString, useResponsive } from "@/styles";
import Logo from "@/features/app/Logo";
import OfferSeo from "@/features/contracts/OfferSeo";
import Icon from "@/components/misc/Icon";
import { useNavigate } from "@/services/Routing";

import {
  StyledPage,
  StyledProposalButtonsContainer,
  StyledProposalControls,
  StyledContractPreview,
  StyledProposalStep,
} from "./ContractProposalPage.styles";
import LandingScreen from "./components/LandingScreen";
import AcceptButton from "./components/AcceptButton";

const ContractProposalPage: React.FC = () => {
  const { isAuthenticated, userData } = useAuth();
  const { isScreenSmallerThanTablet } = useResponsive();
  const { back } = useNavigate();

  const { idFromUrl: contractIdFromUrl } = useContractUrlId();
  const [userVerified] = useState(true);
  const { isLoading: isLoadingFromAcceptHook } = useAcceptOffer({
    contractId: contractIdFromUrl,
  });
  const { contractDetails, isLoading: isLoadingContractDetails } =
    useContractDetails({
      redirectIfNotFound: true,
      useContractIdFromUrl: true,
      useAuthToken: false,
    });
  const [showForm, setShowForm] = useState(isAuthenticated);
  const [isLoadingPaymentUrl, setIsLoadingPaymentUrl] = useState(false);

  //-------------------

  const handleAccept = useCallback(() => {
    setIsLoadingPaymentUrl(true);

    if (isLoadingPaymentUrl) {
      return true;
    }

    if (contractDetails) {
      analytics.triggerAccpetOfferEvent({
        contractId: contractDetails.id,
        accepterEmail: userData?.email || "",
      });

      contractService
        .acceptContract(contractDetails)
        .then(() => {
          setTimeout(() => {
            setIsLoadingPaymentUrl(false);
          }, 2000);
        })
        .catch(() => {
          setIsLoadingPaymentUrl(false);
        });
    }
  }, [contractDetails, isLoadingPaymentUrl, userData?.email]);
  const { modalJsx: emailConfirmModalJsx, modalState: emailConfirmModalState } =
    useConfirmFundEmailModal({
      ctaClick: handleAccept,
    });

  //-------------------

  if (
    isLoadingPaymentUrl ||
    isLoadingContractDetails ||
    !contractDetails ||
    isLoadingFromAcceptHook
  ) {
    return <SplashScreen />;
  }

  const seoJsx = <OfferSeo contractId={contractDetails.id} />;

  if (!showForm) {
    return (
      <>
        {seoJsx}

        <LandingScreen
          contractDetails={contractDetails}
          ctaButtonProps={{
            onClick: () => {
              setShowForm(true);
              analytics.triggerViewOfferEvent({
                contractId: contractDetails.id,
              });
              contractService.addOfferView(
                contractDetails.id,
                contractDetails.milestones[0].id
              );
            },
          }}
        />
      </>
    );
  }

  const rejectUrl = getContractRejectPageUrl(contractIdFromUrl);

  const ctaButtons = (
    <StyledProposalButtonsContainer className="mt-3">
      {appFeatures.isSupported("CONTRACT.REJECT") && (
        <Button variant="secondary" colorVariant="danger" link={rejectUrl}>
          Reject
        </Button>
      )}

      {false && (
        <Button onClick={emailConfirmModalState.open}>
          Accept and fund [old]
        </Button>
      )}

      <AcceptButton contractId={contractIdFromUrl} />
    </StyledProposalButtonsContainer>
  );

  const ctaContent = true ? (
    <AcceptButton className="w-100" contractId={contractIdFromUrl} />
  ) : (
    ctaButtons
  );

  return (
    <>
      {emailConfirmModalJsx}

      {seoJsx}

      <PageWithHeaderLayoutProps
        style={{
          background: "var(--clr-background-ukwn-8, #fbfbfb)",
          minHeight: getViewPortHeightCssString(),
        }}
        headerProps={{
          titleProps: { children: userVerified ? "View Offer" : "" },
          backButtonProps: { show: isScreenSmallerThanTablet },
          prependContent: isScreenSmallerThanTablet ? null : (
            <Logo redirectToMainSite />
          ),
          appendContent: (
            <Button variant="ghost" onClick={back}>
              <Icon isSrcRelative src="cross.svg" size="xs" />
            </Button>
          ),
          style: {
            background: isScreenSmallerThanTablet ? "#fff" : "transparent",
          },
        }}
        contentContainerProps={{ style: { display: "flex" } }}
      >
        <StyledPage>
          <StyledProposalStep>
            <StyledContractPreview
              contractDetails={contractDetails}
              ctaContent={ctaContent}
              showCreationDate={false}
            />

            {isScreenSmallerThanTablet && (
              <StyledProposalControls>{ctaContent}</StyledProposalControls>
            )}
          </StyledProposalStep>
        </StyledPage>
      </PageWithHeaderLayoutProps>
    </>
  );
};

export default ContractProposalPage;
