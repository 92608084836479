"use client";

import styled from "@emotion/styled";

export const StyledSvg = styled.svg`
  --width: 2.6rem;
  --height: 1.5rem;
`;

export const StyledFocus = styled.rect`
  width: var(--width);
  height: var(--height);
  fill: none;
  stroke: var(--clr-primary-100, #5f57ff);
  stroke-width: 2;
`;

export const StyledBg = styled.rect`
  width: var(--width);
  height: var(--height);
  rx: 0.85rem;
  transition: fill 120ms ease;
`;

export const StyledBall = styled.circle`
  fill: #fff;
  box-shadow: 0px 3px 4px 0px #0000001c;
  transition: cx 120ms ease;
`;
