"use client";

import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";
import { screenLargerThan } from "@/styles";
import Modal from "@/components/misc/Modal";

export const StyledTextField = styled(TextField)`
  --label-left: 3rem;
`;

export const StyledPlaceholderIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  background: var(--clr-neutral-solid-0, #f8f9fb);
`;

export const StyledContainer = styled.div`
  ${screenLargerThan.tablet} {
    max-height: 680px;
  }
`;

export const StyledModal = styled(Modal)`
  ${screenLargerThan.tablet} {
    overflow: hidden;

    & > * {
      height: unset;
      overflow: hidden;
    }
  }
`;
