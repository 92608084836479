import Icon from "@/components/misc/Icon";
import { usePromptForSubscription } from "@/services/UserService";
import { Body } from "@/components/Typography";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { appFeatures } from "@/config/services";
import { SITE_PATHS } from "@/config/routing";

import {
  StyledCard,
  StyledCloseButton,
  StyledContent,
  StyledTitle,
  StyledLink,
} from "./GetPremiumCard.styles";

const GetPremiumCard: React.FC<{
  forceShow?: boolean;
  onClose?: () => void;
  className?: string;
  openInNewTab?: boolean;
}> = ({ forceShow, onClose, className, openInNewTab }) => {
  const { discountContent, shouldShow, hidePrompt, loading, isSubscribing } =
    usePromptForSubscription();

  if (!appFeatures.isSupported("SUBSCRIPTION")) {
    return null;
  }

  if ((!shouldShow && !forceShow) || loading) {
    return null;
  }

  if (isSubscribing) {
    return <SplashScreen />;
  }

  return (
    <StyledCard className={className}>
      <StyledLink
        to={SITE_PATHS.SUBSCRIPTION_PAGE}
        {...(openInNewTab
          ? ({
              target: "_blank",
              rel: "noopener noreferrer",
            } as any)
          : {})}
      />

      <StyledTitle size="md">
        <Icon src="/assets/images/branding/logo_round_gradient.svg" />

        <span className="capitalize">Upgrade to Premium</span>
      </StyledTitle>

      <StyledCloseButton
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          if (onClose) {
            onClose();
          } else {
            hidePrompt();
          }
        }}
      >
        <Icon
          isSrcRelative
          src="cross.svg"
          customSize="0.65rem"
          colorVariant="white"
        />
      </StyledCloseButton>

      <Body size="sm" className="mt-3">
        Enjoy more features and less fees.
      </Body>

      {!!discountContent && (
        <StyledContent size="md" className="mt-3">
          {discountContent}
          <Icon isSrcRelative src="link_2.svg" customSize="0.75rem" />
        </StyledContent>
      )}
    </StyledCard>
  );
};

export default GetPremiumCard;
