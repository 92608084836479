"use client";

import React, { useState } from "react";
import * as yup from "yup";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Button from "@/components/input/Button";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { SITE_PATHS } from "@/config/routing";
import {
  ContractId,
  useAcceptOffer,
  useConfirmFundEmailModal,
  useContractDetails,
  usePreApproveOffer,
} from "@/services/ContractsService";
import { AuthMedium, useAuth } from "@/services/Authentication";
import Form from "@/components/input/Form";
import TextField from "@/components/input/TextField";
import {
  ANIMATION_CLASSNAMES,
  screenLargerThan,
  useResponsive,
} from "@/styles";
import { appFeatures } from "@/config/services";
import { Body } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import Card from "@/components/data/Card";

import { StyledSmallText } from "../ContractProposalPage.styles";

type AuthData = {
  firstName: string;
  lastName: string;
  email: string;
};

const INITIAL_AUTH_DATA: AuthData = {
  firstName: "",
  lastName: "",
  email: "",
};

const SSO_BUTTONS: {
  authMedium: AuthMedium;
  label: string;
  icon: string;
}[] = [
  {
    authMedium: "google",
    label: "Google",
    icon: "social/google.svg",
  },
  // {
  //   authMedium: "fb",
  //   label: "Facebook",
  //   icon: "social/facebook_2.svg ",
  // },
  // {
  //   authMedium: "linkedin",
  //   label: "Linkedin",
  //   icon: "social/linkedin_2.svg",
  // },
];

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  email: yup.string().email().required(),
});

const StyledForm = styled.form`
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "first-name last-name"
    "email email"
    "cta cta";

  & > *:nth-child(1) {
    grid-area: first-name;
  }

  & > *:nth-child(2) {
    grid-area: last-name;
  }

  & > *:nth-child(3) {
    grid-area: email;
  }

  & > *:nth-child(4) {
    grid-area: cta;
  }
`;

const StyledSocialButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

const USE_NAMED_SSO_BUTTON_VARIANT = true;

const StyledSocialButton = styled(Button)`
  gap: 1rem;
  font-size: 1rem;
  padding: 0;

  span {
    display: none;
  }
`;

const StyledSocialButtonTemp = styled(Button)`
  gap: 1rem;
  font-size: 1rem;
`;

const StyledCard = styled(Card)`
  ${screenLargerThan.tablet} {
    min-height: 100%;
    display: flex;
    align-items: center;
  }
`;

const AcceptButton: React.FC<{
  contractId: ContractId;
  className?: string;
}> = ({ contractId, className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { isAuthenticated } = useAuth();
  const { contractDetails } = useContractDetails({
    useAuthToken: false,
    contractId,
  });
  const { accept, isLoading } = useAcceptOffer({ contractId });

  const [showAuthInput, setShowAuthInput] = useState(false);
  const [formValues, setFormValues] = useState<AuthData>(INITIAL_AUTH_DATA);

  const { modalJsx: emailConfirmModalJsx, modalState: emailConfirmModalState } =
    useConfirmFundEmailModal({
      ctaClick: accept,
    });

  const { preApprove } = usePreApproveOffer({
    contractId: contractId,
    milestoneId: contractDetails?.milestones[0].id || 0,
    userDetails: formValues,
  });

  //-------------------------------

  const tAndCText = (
    <StyledSmallText className={isScreenSmallerThanTablet ? "mb-3" : "mt-3"}>
      By clicking accept, you are agreeing to the terms of this offer and
      Paypipe's <Link to={SITE_PATHS.TERMS_CONDITIONS_PAGE}>Terms of Use</Link>{" "}
      and <Link to={SITE_PATHS.POLICY_PAGE}>Privacy Policy</Link>
    </StyledSmallText>
  );

  const formJsx = (
    <Form<AuthData>
      initialValues={INITIAL_AUTH_DATA}
      yupValidationSchema={VALIDATION_SCHEMA}
      onSubmit={(values) => {
        accept({ values });
      }}
      onChange={setFormValues}
    >
      {({
        context: { values, setFieldValue, submitForm, touchedAndHasError },
      }) => (
        <StyledForm
          className={ANIMATION_CLASSNAMES.FADE_IN}
          onSubmit={submitForm}
        >
          <TextField
            name="First name"
            variant="background"
            placeholder="First name"
            value={values.firstName}
            onChange={(value) => {
              setFieldValue("firstName", value);
            }}
            hasError={touchedAndHasError("firstName")}
            onBlur={preApprove}
          />

          <TextField
            name="Last name"
            variant="background"
            placeholder="Last name"
            value={values.lastName}
            onChange={(value) => {
              setFieldValue("lastName", value);
            }}
            hasError={touchedAndHasError("lastName")}
            onBlur={preApprove}
          />

          <TextField
            name="email"
            type="email"
            variant="background"
            placeholder="Email"
            value={values.email}
            onChange={(value) => {
              setFieldValue("email", value);
            }}
            hasError={touchedAndHasError("email")}
            onBlur={preApprove}
          />

          <Button type="submit" className="w-100 py-3">
            Continue
          </Button>
        </StyledForm>
      )}
    </Form>
  );

  if (isLoading || !contractDetails) {
    return <SplashScreen />;
  }

  if (
    appFeatures.isSupported("CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE")
  ) {
    return (
      <StyledCard className={className}>
        <div>
          {isAuthenticated ? (
            <>
              {isScreenSmallerThanTablet && tAndCText}
              <Button
                className="w-100 py-3"
                onClick={() => {
                  accept();
                }}
              >
                Accept and fund
              </Button>
              {!isScreenSmallerThanTablet && tAndCText}
            </>
          ) : (
            <>
              {showAuthInput ? (
                <div
                  ref={(ref) => {
                    ref?.scrollIntoView({ block: "start" });
                  }}
                >
                  {formJsx}
                  <Body size="md" className="mt-3" style={{ color: "#666D80" }}>
                    Or proceed using:
                  </Body>

                  <StyledSocialButtonsContainer className="mt-3">
                    {SSO_BUTTONS.map(({ authMedium, label, icon }) =>
                      USE_NAMED_SSO_BUTTON_VARIANT ? (
                        <StyledSocialButtonTemp
                          key={authMedium}
                          className="w-100"
                          variant="secondary"
                          colorVariant="gray"
                          onClick={() => {
                            accept({ registerProps: { medium: authMedium } });
                          }}
                        >
                          <Icon isSrcRelative customSize="2.25rem" src={icon} />
                          {<span>{label}</span>}
                        </StyledSocialButtonTemp>
                      ) : (
                        <StyledSocialButton
                          key={authMedium}
                          variant="ghost"
                          colorVariant="gray"
                          onClick={() => {
                            accept({ registerProps: { medium: authMedium } });
                          }}
                        >
                          <Icon isSrcRelative customSize="3rem" src={icon} />
                          {<span>{label}</span>}
                        </StyledSocialButton>
                      )
                    )}
                  </StyledSocialButtonsContainer>

                  <Body size="md" className="mt-2" style={{ color: "#666D80" }}>
                    Already have an account?{" "}
                    <Button
                      variant="ghost"
                      onClick={() => {
                        accept({ login: true });
                      }}
                      style={{ paddingInline: "0" }}
                    >
                      Sign in
                    </Button>
                  </Body>
                </div>
              ) : (
                <>
                  {isScreenSmallerThanTablet && tAndCText}
                  <Button
                    className="w-100 py-3"
                    onClick={() => {
                      setShowAuthInput(true);
                    }}
                  >
                    Accept and fund
                  </Button>
                  {!isScreenSmallerThanTablet && tAndCText}
                </>
              )}
            </>
          )}
        </div>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <div>
        {emailConfirmModalJsx}
        {isScreenSmallerThanTablet && tAndCText}
        <Button className="w-100 py-3" onClick={emailConfirmModalState.open}>
          Accept and fund
        </Button>
        {!isScreenSmallerThanTablet && tAndCText}
      </div>
    </StyledCard>
  );
};

export default AcceptButton;
