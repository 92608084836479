"use client";

import React from "react";

import { SITE_PATHS } from "@/config/routing";
import Redirect from "@/components/navigation/Redirect";
import useManageContract from "@/services/ContractsService/hooks/useManageContract";
import SplashScreen from "@/features/pages/app/SplashScreen";
import ContractManagementPage from "@/features/pages/contract/ContractManagementPage";
import ContractProposalPage from "@/features/pages/contract/ContractProposalPage";
import ContractPreviewPage from "@/features/pages/contract/ContractPreviewPage";
import { useAuth } from "@/services/Authentication";
import ContractPaymentRedirectPage from "@/features/pages/contract/ContractPaymentRedirectPage";
import OnboardingPage from "@/features/pages/app/OnboardingPageNew";
import { useAutoFixContractUrl } from "@/services/ContractsService";

const ContractsPage: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { isLoading, canApprove, canManage, canPreview, isPaymentRedirect } =
    useManageContract({
      redirectIfNotFound: true,
      useContractIdFromUrl: true,
    });
  const { isLoading: isLoadingFromAutofixHook } = useAutoFixContractUrl();

  //------------------------------

  const redirectToHome =
    isAuthenticated &&
    !canApprove &&
    !canManage &&
    !canPreview &&
    !isPaymentRedirect;
  const redirectToLogin =
    !isAuthenticated &&
    !canApprove &&
    !canManage &&
    !canPreview &&
    !isPaymentRedirect;

  const showManagementPage = canManage;
  const showPreviewPage = canPreview;
  const showApprovalPage = canApprove;
  const showPaymentRedirectPage = isPaymentRedirect;

  //------------------------------

  if (isLoading || isLoadingFromAutofixHook) {
    return <SplashScreen />;
  }

  if (redirectToLogin) {
    return <OnboardingPage />;
  }

  if (redirectToHome) {
    return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
  }

  if (showManagementPage) {
    return <ContractManagementPage />;
  }

  if (showApprovalPage) {
    return <ContractProposalPage />;
  }

  if (showPaymentRedirectPage) {
    return <ContractPaymentRedirectPage />;
  }

  if (showPreviewPage) {
    return <ContractPreviewPage />;
  }

  return <div>Unhandled state</div>;
};

export default ContractsPage;
