import { appFeatures } from "@/config/services";
import { Tab } from "@/components/navigation/Tabs";

import AboutSection from "./components/AboutSection";
import ReviewSection from "./components/ReviewSection";
import PackagesSection from "./components/PackagesSection";
import PortfolioSection from "./components/PortfolioSection";
import { Tab as TabName } from "./ProfilePage.types";
import SpecializationSection from "./components/SpecializationSection";

export const PROFILE_TABS: (Omit<Tab, "id"> & { id: TabName; content: any })[] =
  [
    { id: "about", title: "About", content: <AboutSection /> },
    { id: "review", title: "Reviews", content: <ReviewSection /> },
  ];

PROFILE_TABS.push({
  id: "specialization",
  title: "Specializations",
  content: <SpecializationSection />,
});

if (appFeatures.isSupported("ACCOUNT.PORTFOLIOS")) {
  PROFILE_TABS.push({
    id: "portfolios",
    title: "Portfolio",
    content: <PortfolioSection />,
  });
}

if (appFeatures.isSupported("ACCOUNT.PACKAGES")) {
  PROFILE_TABS.push({
    id: "services",
    title: "Packages",
    content: <PackagesSection />,
  });
}
