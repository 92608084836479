import * as yup from "yup";

import { useForm } from "@/components/input/Form";
import { Tab } from "@/components/navigation/Tabs";
import { appFeatures } from "@/config/services";

import { FormData, FormExtraValues, Tab as TabName } from "./ProfileForm.types";
import AboutSection from "./components/AboutSection";
import PackagesInput from "./components/inputs/PackagesInput";
import PortfoliosInput from "./components/inputs/PortfoliosInput";
import SpecializationInput from "./components/inputs/SpecializationInput";

export const VALIDATION_SCHEMA = yup.object().shape({});

export const useProfileForm = useForm<FormData, FormExtraValues>;

export const PROFILE_TABS: (Omit<Tab, "id"> & { id: TabName; content: any })[] =
  [{ id: "about", title: "About", content: <AboutSection /> }];

PROFILE_TABS.push({
  id: "specializations",
  title: "Specializations",
  content: <SpecializationInput />,
});

if (appFeatures.isSupported("ACCOUNT.PORTFOLIOS")) {
  PROFILE_TABS.push({
    id: "portfolios",
    title: "Portfolio",
    content: <PortfoliosInput />,
  });
}

if (appFeatures.isSupported("ACCOUNT.PACKAGES")) {
  PROFILE_TABS.push({
    id: "services",
    title: "Packages",
    content: <PackagesInput />,
  });
}

export const ABOUT_FIELD_URL_HASH = "#about_me";
export const PROFESSION_FIELD_URL_HASH = "#profession";
export const EXPERIENCE_FIELD_URL_HASH = "#experience";
export const SKILL_FIELD_URL_HASH = "#skills";
export const LANGUAGE_FIELD_URL_HASH = "#languages";
