import { ApiResponse } from "@/services/Api";
import { Device } from "@/services/PushNotifications";
import { AddressParts } from "@/services/Location";

export enum ExperienceLevel {
  EntryLevel = 1,
  Intermediate,
  Expert,
}

export type MediaFile = {
  id: number;
  url: string;
  updated_at: string;
  created_at: string;
};

export type Package = {
  id: string | number;
  name: string;
  description: string;
  price: number;
  estimated_duration: number;
  estimated_duration_unit: string;
  attachments: MediaFile[];
};

export type Tech = string;

export type Portfolio = {
  id: string | number;
  name: string;
  description: string;
  attachments: MediaFile[];
  tech: Tech[];
  link?: string;
};

export type SocialLink = {
  name: string;
  url: string;
};

export type Skill = string;

export type SkillDetails = {
  name: string;
  rating: number;
};

export type Specialization = {
  id: number;
  name: string;
  experience_duration: number;
  experience_duration_unit: string;
};

export type UserProfile = {
  heading: string;
  about_me: string;
  timezone: string;
  skills: Skill[];
  skills_new: SkillDetails[];
  languages: string[];
  profession: string[];
  packages: Package[];
  portfolios: Portfolio[];
  links: SocialLink[];
  experience_level: ExperienceLevel;
  misc: {
    canOnboard: boolean;
    seenNotifications: boolean;
    notificationCount: number;
    featuresInterest: Record<
      string,
      {
        notify?: boolean;
        interestRating?: number;
      }
    >;
    pushNotificationsDevices: Device[];
    featuresFeedback: Record<
      string,
      {
        rating?: number;
        content?: string;
      }
    >;
  };
  specializations: Specialization[];
  location: string;
  locationDetails: null | {
    addressParts: AddressParts;
  };
};

export type UserInfo = {
  id: number;
  email: string;
  given_name: string;
  family_name: null | string;
  picture: null | string;
  owner_id: null | string;
  stripe_customer_id: null | string;
  owner_profile: any;
  stripe_connect_data: null;
  updated_at: string;
  created_at: string;
  details: null | string;
  paypipe_id: null | string;
  last_updated_paypipe_id: null | string;
  profile_published: boolean;
};

export type PaymentCountryResponse = {
  supported_countries: string[];
};

export type UserExistsResponse = {
  exist: boolean;
  data?: {
    id: number;
    given_name: string | null;
    family_name: string | null;
    profile_picture: string | null;
  };
};

export type OnboardStripeApiParams = {
  countryCode: string;
  returnUrl?: string;
  refreshUrl?: string;
};

export type UserProfileUpdateFields = {
  email?: string;
  given_name?: string;
  family_name?: string;
  details?: string;
  picture?: string;
};

export type UpdateUserProfileParams = {
  notify?: boolean;
  accountData?: Partial<UserProfileUpdateFields>;
};

export type Timezone = {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
};

//------------------------

export type WalletSummary = {
  id: number;
  owner_id: number;
  balance: string;
  updated_at: string;
  created_at: string;
};

export type UserDetails = {
  id: number;
  email: string;
  given_name: string;
  family_name: string;
  picture: any;
  owner_id: string;
  stripe_customer_id: string;
  owner_profile: string | null;
  stripe_connect_data: any;
  updated_at: string;
  created_at: string;
  wallet: WalletSummary;
  details: null | string;
  paypipe_id: null | string;
  last_updated_paypipe_id: null | string;
  profile_published: boolean;
};

export type UserRegisterResponse = ApiResponse<UserDetails>;

export type UserInfoResponse = ApiResponse<{
  charges_enabled: boolean;
  user: UserDetails;
}>;

export type IpInfo = {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  postal: string;
  timezone: string;
  readme: string;
};

//------------------------

export enum SubscriptionDuration {
  Monthly = "month",
  Yearly = "year",
}

export type SubscribePremiumApiParams = {
  interval: SubscriptionDuration;
  successUrl?: string;
  cancelUrl?: string;
};

export enum SubscriptionStatus {
  NotActive = "not-active",
  Active = "active",
  Cancelled = "cancelled",
  ActiveCancelled = "active-cancelled",
}

export type SubscriptionDetails = {
  has_premium: boolean;
  is_trial: boolean;
  plan_type: SubscriptionDuration | null;
  start_date: Date | null;
  end_date: Date | null;
  status: SubscriptionStatus;
  available_discounts: string[]
};

export type SocialLinkConfig = {
  platformName: string;
  icon: string;
  displayName?: string;
  showInPlaceholder?: boolean;
  placeholderOrder?: number;
  prefix?: string;
};

export enum PayoutOnboardItems {
  VarificationDocuments = "VERIFICATION_DOCUMENTS",
}

export type PayoutOnboardStatus = {
  onboarded: boolean;
  onboardingInitiated: boolean;
  message: string;
  pendingItems: PayoutOnboardItems[];
  country: string | null;
};