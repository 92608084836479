"use client";

import styled from "@emotion/styled";

import Button from "@/components/input/Button";

export const StyledContainer = styled.div`
  display: flex;
  background: var(--clr-background-ukwn-7, #f9f9f9);
  border-radius: 10rem;
  padding: 2px;
  max-width: 100%;
  overflow: auto hidden;
  min-height: 2.75rem;
`;

export const StyledTab = styled(Button)<{ $active: boolean }>`
  flex: 1;
  border-radius: inherit;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;

  color: ${({ $active }) =>
    $active
      ? "var(--clr-text-ukwn-4, #242b42)"
      : "var(--clr-icon-text-200, #818898)"};
  ${({ $active }) =>
    $active
      ? `
          background: var(--clr-background-primary, #fff);
          box-shadow: 0px 2px 10px 0px #A9AAB41A;
        `
      : ""};
`;
