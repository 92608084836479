"use client";

import React, { useState } from "react";

import PageWithHeaderLayoutProps from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";
import { useManageContract } from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";
import { ContractPreviewLoading } from "@/features/contracts/ContractPreview";

import { StyledContractPreview } from "./ContractPreviewPage.styles";

const ContractPreviewPage: React.FC = () => {
  const [userVerified] = useState(true);

  const { contractDetails, isLoading: isLoadingContractDetails } =
    useManageContract({
      redirectIfNotFound: true,
      useContractIdFromUrl: true,
    });

  const content =
    isLoadingContractDetails || !contractDetails ? (
      <ContractPreviewLoading />
    ) : (
      <StyledContractPreview contractDetails={contractDetails} />
    );

  return (
    <PageWithHeaderLayoutProps
      headerProps={{
        titleProps: { children: userVerified ? "View Offer" : "" },
        backButtonProps: {
          children: userVerified ? (
            <Icon isSrcRelative src="cross.svg" customSize="0.75rem" />
          ) : null,
          link: SITE_PATHS.CONTRACTS_PAGE,
        },
      }}
      contentContainerProps={{
        style: { background: "var(--clr-background-ukwn-8, #fbfbfb)" },
      }}
    >
      {content}
    </PageWithHeaderLayoutProps>
  );
};

export default ContractPreviewPage;
