import { ColorVariant } from "@/components/misc/Tag";
import {
  ContractCreatorType,
  ContractMilestoneStatus,
} from "@/services/ContractsService";

export const MILESTONE_STATUS_CONFIG: Partial<
  Record<
    ContractMilestoneStatus,
    {
      content: {
        [ContractCreatorType.Payee]?: {
          tag?: {
            content: React.ReactNode;
            color: ColorVariant;
          };
        };
        [ContractCreatorType.Payer]?: {
          tag?: {
            content: React.ReactNode;
            color: ColorVariant;
          };
        };
        common?: {
          tag?: {
            content: React.ReactNode;
            color: ColorVariant;
          };
        };
      };
    }
  >
> = {
  [ContractMilestoneStatus.Published]: {
    content: {
      common: {
        tag: {
          content: "Active",
          color: "primary",
        },
      },
      [ContractCreatorType.Payee]: {
        tag: {
          content: "Active",
          color: "primary",
        },
      },
    },
  },
  [ContractMilestoneStatus.Accepted]: {
    content: {
      common: {
        tag: {
          content: "Pending Funding",
          color: "primary",
        },
      },
      [ContractCreatorType.Payee]: {
        tag: {
          content: "Active",
          color: "primary",
        },
      },
    },
  },
  [ContractMilestoneStatus.Funded]: {
    content: {
      common: {
        tag: {
          content: "Funded",
          color: "primary",
        },
      },
    },
  },
  [ContractMilestoneStatus.ReadyForWork]: {
    content: {
      common: {
        tag: {
          content: "Active",
          color: "primary",
        },
      },
    },
  },
  [ContractMilestoneStatus.FundRequested]: {
    content: {
      [ContractCreatorType.Payee]: {
        tag: {
          content: "Payment requested",
          color: "yellow",
        },
      },
      [ContractCreatorType.Payer]: {
        tag: {
          content: "Pending Payment",
          color: "yellow",
        },
      },
    },
  },
  [ContractMilestoneStatus.Review]: {
    content: {
      [ContractCreatorType.Payee]: {
        tag: {
          content: "Payment requested",
          color: "yellow",
        },
      },
      [ContractCreatorType.Payer]: {
        tag: {
          content: "Pending Payment",
          color: "yellow",
        },
      },
    },
  },
  [ContractMilestoneStatus.Completed]: {
    content: {
      common: {
        tag: {
          content: "Paid",
          color: "success",
        },
      },
    },
  },
  [ContractMilestoneStatus.FundReleased]: {
    content: {
      common: {
        tag: {
          content: "Paid",
          color: "success",
        },
      },
    },
  },
};
