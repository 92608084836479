import { FormikValues } from "formik/dist/types";

import { FormProvider } from "./Form.context";
import { IFormProps, ExtraFields } from "./Form.types";

function Form<T extends FormikValues, U = ExtraFields>(
  props: IFormProps<T, U>
) {
  return <FormProvider<T, U> {...props} />;
}

export default Form;
