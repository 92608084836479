"use client";

import React from "react";

import { appFeatures } from "@/config/services";

import AblyChatList from "./ChatList.ably";
import StreamChatList from "./ChatList.stream";

const ChatList: React.FC = () => {
  if (appFeatures.isSupported("CHAT.USE_STREAMIO")) {
    return <StreamChatList />;
  }

  return <AblyChatList />;
};

export default ChatList;
