import React from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import { useProfileData, usePublishProfile } from "@/services/UserService";
import {
  ANIMATION_CLASSNAMES,
  BREAKPOINT_NAMES,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import ShareButton from "@/features/media/ShareButton";
import PaypipeIdPage from "@/features/pages/user/PaypipeIdPageNew";
import { EXTERNAL_PATHS } from "@/config/routing";
import PublishStateButton from "@/features/PaypipeId/PublishStateButton";

const FAB_BREAKPOINT = "widescreen" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #d7eff9;
  border-radius: 0.75rem;
  padding: 1rem;

  .unpublish {
    margin-inline-start: auto;
  }

  .publish {
    margin-inline-end: auto;
  }

  .content {
    font-size: 0.875rem;
    font-weight: 400;
    color: #5b5252;
    word-break: break-word;
    line-height: 1.5em;
    flex-grow: 1;

    .loader {
      margin-right: 0.75rem;
    }

    b {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 600;
      color: #000;
    }

    ${screenLargerThan.tablet} {
      font-size: 1rem;

      b {
        font-size: 1rem;
      }
    }

    ${screenSmallerThan.fullhd} {
      span {
        display: none;
      }
    }
  }

  ${screenSmallerThan.tablet} {
    flex-wrap: wrap;
  }

  ${screenLargerThan.tablet} {
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    gap: 0.75rem;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 10rem;
  height: 3.5rem;
  background: #fff;
  border-color: #fff;
  padding-inline: 1rem;
`;

const StyledShareButton = styled(ShareButton)`
  ${screenLargerThan.desktop} {
    border-radius: 10rem;
    height: 3.5rem;
    background: #fff;
    border-color: #fff;
    padding-inline: 1rem;
  }

  ${screenSmallerThan[FAB_BREAKPOINT]} {
    display: none;
  }
`;

const StyledSquareButton = styled(StyledButton)`
  ${squareSizing("3.5rem")};

  ${screenSmallerThan[FAB_BREAKPOINT]} {
    display: none;
  }
`;

const StyledLayout = styled(PageWithHeaderLayout)`
  --content-max-width: 1432px;
`;

const StyledModal = styled(Modal)`
  padding: 0;
`;

const StyledMobileCtaSection = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;

  & > * {
    box-shadow: 0px 4px 24px 0px #0000000f;

    ${screenLargerThan.tablet} {
      padding: 1rem !important;
    }
  }

  ${screenLargerThan[FAB_BREAKPOINT]} {
    display: none;
  }
`;

const Header: React.FC<{ paypipeId: string; className?: string }> = ({
  className,
}) => {
  const previewModalState = useModalState({ urlHash: "#preview" });
  const { published = false } = usePublishProfile();
  const { profileData, accountData } = useProfileData();

  const paypipeId = accountData?.paypipe_id;
  const heading = profileData?.heading;

  return (
    <>
      <StyledContainer className={className}>
        <PublishStateButton />

        <div className="content">
          <span>
            {published ? (
              <b className={ANIMATION_CLASSNAMES.FADE_IN}>
                Your public profile is visible:
              </b>
            ) : (
              <b className={ANIMATION_CLASSNAMES.FADE_IN}>
                Your public profile is not visible:
              </b>
            )}
          </span>
          &nbsp;&nbsp;Paypipe.me/{paypipeId}
        </div>

        <StyledSquareButton
          colorVariant="white-primary"
          onClick={previewModalState.open}
        >
          <Icon isSrcRelative src="eyes.svg" size="sm" colorVariant="gray" />
        </StyledSquareButton>

        <StyledShareButton
          buttonProps={{
            colorVariant: "gray",
            children: (
              <>
                <Icon
                  isSrcRelative
                  src="share_3.svg"
                  size="sm"
                  colorVariant="gray"
                />
                Share
              </>
            ),
          }}
          content={{
            text: heading,
            url: `${EXTERNAL_PATHS.PAYPIPE_ID_SITE}/${paypipeId}`,
          }}
        />
      </StyledContainer>
      <StyledModal fullscreen state={previewModalState}>
        <StyledLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Preview" },
            backButtonProps: { onClick: previewModalState.close },
          }}
          contentContainerProps={{
            style: { background: "#f8f8f8" },
          }}
        >
          {!!accountData && (
            <PaypipeIdPage
              accountData={accountData}
              profileData={profileData}
            />
          )}
        </StyledLayout>
      </StyledModal>

      <StyledMobileCtaSection>
        <ShareButton
          buttonProps={{
            borderRadius: "default",
            children: (
              <>
                Share my Profile
                <Icon
                  src="/assets/pages/paypipe-id/share.svg"
                  size="sm"
                  colorVariant="white"
                />
              </>
            ),
          }}
          content={{
            text: heading,
            url: `${EXTERNAL_PATHS.PAYPIPE_ID_SITE}/${paypipeId}`,
          }}
        />

        <Button
          colorVariant="white-primary"
          borderRadius="default"
          onClick={previewModalState.open}
          style={{
            color: "#000000",
          }}
        >
          <Icon isSrcRelative src="eyes.svg" size="sm" colorVariant="gray" />
          Preview
        </Button>
      </StyledMobileCtaSection>
    </>
  );
};

export default Header;
