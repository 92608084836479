import React from "react";

import FilesInput from "@/features/input/FilesInput";

import { StyledContainer, StyledEditor } from "./ContractWorkInput.styles";
import { IContractWorkInputProps } from "./ContractWorkInput.types";

const ContractWorkInput: React.FC<IContractWorkInputProps> = ({
  value: { content, attachments } = { content: "", attachments: [] },
  onChange,
  className,
  readOnly,
}) => {
  return (
    <StyledContainer className={className}>
      <StyledEditor
        focusOnMount={!readOnly}
        readOnly={readOnly}
        value={{
          markdown: content,
        }}
        onChange={({ markdown }) => {
          if (onChange) onChange({ attachments, content: markdown || "" });
        }}
        placeholder={readOnly ? "" : "Write anything here....."}
      />

      <div className="mt-3 px-3">
        <FilesInput
          disabled={readOnly}
          value={attachments}
          onChange={(attachments) => {
            if (onChange) onChange({ attachments, content });
          }}
          label={
            readOnly
              ? "Attached files"
              : attachments.length > 0
              ? "Attached files"
              : "Tap to attach a file"
          }
          displayFileCount={0}
        />
      </div>
    </StyledContainer>
  );
};

export default ContractWorkInput;
