import { useCallback, useState } from "react";

import { userService } from "@/config/services";

function useManageSubscription() {
  const [isLoading, setIsLoading] = useState(false);

  //-----------------------

  const manage = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    userService
      .manageSubscription()
      .then((res) => {
        const manageUrl = res.data.data.url;

        if (manageUrl) {
          window.location.href = manageUrl;
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [isLoading]);

  //-----------------------

  return {
    manage,
    isLoading,
  };
}

export default useManageSubscription;
