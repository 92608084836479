import packageJson from "@/../package.json";

export const APP_NAME = "PAYPIPE";
export const APP_ENV = process.env.REACT_APP_ENV! as "staging" | "production";
export const IS_PROD_ENV = APP_ENV === "production";
export const IS_STAGING_ENV = APP_ENV === "staging";
export const PAYPIPE_SITE_URL = process.env.REACT_APP_PAYPIPE_SITE_URL!;

export const APP_VERSION = packageJson.version;

export const MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * 5;

export const STRIPE_PAK_ONBOARDING_VIDEO_LINK = 'https://vimeo.com/1034189006?share=copy';
