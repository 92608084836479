import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useForm } from "@/components/input/Form";
import {
  getContractPageUrl,
  getOfferPageUrl,
} from "@/services/ContractsService";
import { useNavigate } from "@/services/Routing";
import { analytics } from "@/config/services";

import {
  ContractRejectFormRef,
  FormValues,
  IContractRejectFormContext,
  IContractRejectFormProps,
  Step,
} from "./ContractRejectForm.types";
import {
  StyledBottomSection,
  StyledButton,
  StyledForm,
  StyledStepsContainer,
} from "./ContractRejectForm.styles";
import { STEPS_CONFIG, STEPS_LIST } from "./ContractRejectForm.config";
import RejectSuccessScreen from "./screens/SuccessScreen/RejectSuccessScreen";

const INITIAL_VALUES = {} as IContractRejectFormContext;

const ContractRejectFormContext = createContext(INITIAL_VALUES);

export const useContractRejectForm = () =>
  useContext(ContractRejectFormContext);

export const ContractRejectFormProvider: React.FC<
  IContractRejectFormProps & { rejected: boolean }
> = ({ getFormRef, onStepChange, rejected, contractId }) => {
  const { navigate } = useNavigate();

  const ref = useRef({} as ContractRejectFormRef);
  const stepContainerElRef = useRef<HTMLDivElement | null>(null);

  const formContextValue = useForm<FormValues>();
  const { submitForm, values } = formContextValue;

  const [currentStep, setCurrentStep] = useState<Step>(Step.Reason);

  const isLastStep = currentStep === STEPS_LIST[STEPS_LIST.length - 1].id;

  // ---------------------

  const renderStep = useCallback(
    () => STEPS_CONFIG[currentStep].component,
    [currentStep]
  );

  const gotToStep = useCallback(
    (step: Step) => {
      setCurrentStep(step);

      if (onStepChange) {
        onStepChange(step);
      }

      if (stepContainerElRef.current) stepContainerElRef.current.scrollTop = 0;
    },
    [onStepChange]
  );

  const getStepIndex = useCallback((step: Step) => {
    const index = STEPS_LIST.findIndex(({ id }) => id === step);

    return index;
  }, []);

  const previousStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === 0) {
      navigate(getContractPageUrl(contractId), true);
      return;
    }

    const nextStepIndex = Math.max(0, currentStepIndex - 1);
    gotToStep(STEPS_LIST[nextStepIndex].id as Step);
  }, [currentStep, gotToStep, getStepIndex, navigate, contractId]);

  const nextStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === STEPS_LIST.length - 1) {
      return;
    }

    const nextStepIndex = Math.min(STEPS_LIST.length - 1, currentStepIndex + 1);
    gotToStep(STEPS_LIST[nextStepIndex].id as Step);
  }, [currentStep, gotToStep, getStepIndex]);

  const handleClick = useCallback(() => {
    if (isLastStep) {
      submitForm();
    } else {
      nextStep();
    }
  }, [nextStep, isLastStep, submitForm]);

  // ---------------------

  useEffect(() => {
    ref.current = {
      nextStep,
      previousStep,
    };

    if (getFormRef) getFormRef(ref.current);
  }, [nextStep, previousStep, getFormRef]);

  // ---------------------

  const disablSubmit = !values.description || !values.reason;

  const form = (
    <StyledForm onSubmit={submitForm}>
      <StyledStepsContainer ref={stepContainerElRef}>
        {renderStep()}
      </StyledStepsContainer>

      <StyledBottomSection>
        <StyledButton
          variant="secondary"
          colorVariant="gray"
          link={getOfferPageUrl(contractId)}
          onClick={() => {
            if (contractId) {
              analytics.triggerRejectOfferEvent("reject_offer_cancel", {
                contractId,
              });
            }
          }}
        >
          Cancel
        </StyledButton>

        <StyledButton
          type="submit"
          onClick={handleClick}
          colorVariant={disablSubmit ? "disabled" : "primary"}
        >
          Submit
        </StyledButton>
      </StyledBottomSection>
    </StyledForm>
  );

  return (
    <ContractRejectFormContext.Provider
      value={{
        ...formContextValue,
        currentStep,
        contractId,
      }}
    >
      {rejected ? <RejectSuccessScreen /> : form}
    </ContractRejectFormContext.Provider>
  );
};
