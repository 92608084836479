"use client";

import React from "react";
import styled from "@emotion/styled";

import { useProfileForm } from "../../ProfileForm.config";
import {
  StyledCard,
  StyledDeleteButton,
  StyledFileName,
  StyledFilesSection,
  StyledFileTile,
  StyledFileValuesContainer,
} from "./PortfolioCard.styles";
import { Body, Heading, Label } from "@/components/Typography";
import EditorField from "@/components/input/EditorField";
import Icon from "@/components/misc/Icon";
import PortfolioDesktopCard from "@/features/user/PortfolioCard";
import { DEVICE_BREAKPOINT, screenSmallerThan, useResponsive } from "@/styles";

const StyledDesktopCard = styled(PortfolioDesktopCard)`
  ${screenSmallerThan.widescreen} {
    padding-bottom: 1rem;
    margin-inline: 1.5rem;
    border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
  }
`;

const PortfolioCard: React.FC<{
  index: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  className?: string;
}> = ({ index, onEdit, onDelete, className }) => {
  const { screenWidth } = useResponsive();
  const { values } = useProfileForm();

  const portfolioDetails = values.portfolios[index];

  return (
    <StyledCard className={className}>
      <StyledDeleteButton
        variant="ghost"
        onClick={(e) => {
          onDelete(index);
        }}
      >
        <Icon isSrcRelative src="delete.svg" size="xs" />
      </StyledDeleteButton>

      <div
        onClick={() => {
          onEdit(index);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            pointerEvents: "none",
          }}
        >
          {screenWidth < DEVICE_BREAKPOINT.widescreen ? (
            <>
              <Heading size="md">{portfolioDetails.name || "-"}</Heading>
              {!!portfolioDetails.attachments?.length && (
                <StyledFilesSection className="mt-3">
                  <Body
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Examples:
                  </Body>
                  <StyledFileValuesContainer>
                    {portfolioDetails.attachments.map((file, i) => (
                      <StyledFileTile key={i}>
                        <Icon isSrcRelative src="file.svg" size="xxs" />
                        <Label size="lg" as="div">
                          <Body
                            size="sm"
                            as="div"
                            style={{
                              display: "inline-block",
                              color: "var(--clr-neutral-solid-400, #808897)",
                            }}
                          >
                            <StyledFileName>{file.url}</StyledFileName>
                          </Body>
                        </Label>
                      </StyledFileTile>
                    ))}
                  </StyledFileValuesContainer>
                </StyledFilesSection>
              )}
              {!!portfolioDetails.description && (
                <>
                  <Body
                    className="mt-3"
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Description:
                  </Body>
                  <EditorField
                    readOnly
                    className="p-0"
                    label=""
                    prependContent={null}
                    value={{ markdown: portfolioDetails.description }}
                    drawerTitle="Description"
                  />
                </>
              )}
            </>
          ) : (
            <StyledDesktopCard useLink={false} portfolio={portfolioDetails} />
          )}
        </div>
      </div>
    </StyledCard>
  );
};

export default PortfolioCard;
