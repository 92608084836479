"use client";

import React from "react";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import ProfileForm from "@/features/user/ProfileForm";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";

import { StyledPage } from "./EditProfilePage.styles";

const EditProfilePage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{ titleProps: { children: "Edit profile" } }}
      >
        <StyledPage>
          <ProfileForm />
        </StyledPage>
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout headerProps={{ logo: { component: "Edit profile" } }}>
      <StyledPage>
        <ProfileForm />
      </StyledPage>
    </DesktopPrimaryLayout>
  );
};

export default EditProfilePage;
