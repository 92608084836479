import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import Timeline from "@/components/misc/Timeline";
import Button from "@/components/input/Button";

export const StyledDisplay = styled.div<{
  $active: boolean;
  $hasError: boolean;
}>`
  --border-color: ${({ $hasError }) =>
    $hasError ? "var(--clr-error-500, #ea5b5b)" : "var(--clr-border-ukwn-5, #dfe1e7)"};
  display: flex;
  align-items: center;
  gap: 1rem;

  color: ${({ $active }) => ($active ? "var(--clr-primary-100, #5F57FF)" : "var(--clr-text-500, #666D80)")};
`;

export const StyledLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid var(--border-color);
  min-height: 50px;
  font-weight: 500;
`;

export const StyledTimeline = styled(Timeline)`
  .button-container {
    padding: 0;

    & > button {
      position: relative;
      top: -1rem;
      left: 0;
    }

    &:after {
      display: none;
    }
  }
`;

export const StyledButton = styled(Button)<{ $active?: boolean }>`
  padding-inline: 0rem;
  margin-inline: -2px;
`;

export const StyledIconContainer = styled.div<{ $active?: boolean }>`
  ${({ $active }) => squareSizing($active ? "1.4rem" : "1.5rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ $active }) =>
    $active ? "var(--clr-primary-100, #5F57FF)" : "var(--clr-background-25,  #f6f8fa)"};

  ${({ $active }) =>
    $active
      ? "border: 1px solid var(--clr-primary-100, #5F57FF);"
      : "border: 1px dashed  var(--clr-ukwn-3, #A4ACB9);"};

  border-radius: 10rem;
`;
