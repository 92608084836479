import React from "react";

import Icon from "@/components/misc/Icon";

import {
  StyledContainer,
  StyledCurrency,
  StyledCurrenyField,
  StyledPriceField,
  StyledUnit,
} from "./PriceCurrenyField.styles";
import { IPriceCurrenyFieldProps } from "./PriceCurrenyField.types";

const PriceCurrenyField: React.FC<IPriceCurrenyFieldProps> = ({
  currency,
  onCurrencyChange,
  price,
  onPriceChange,
  className,
  currenyFieldProps = {},
  priceFieldProps = {},
}) => {
  const showCurreny = !!price;
  const backgroundVariant = priceFieldProps?.variant === "background";

  return (
    <StyledContainer className={className}>
      {false && (
        <StyledCurrenyField
          {...currenyFieldProps}
          // TO-CHECK: Why input's disabled prop not working
          disabled
          value={currency}
          onChange={onCurrencyChange as any}
          label="Currency"
        />
      )}

      <StyledPriceField
        placeholder="Price"
        {...priceFieldProps}
        $currencyOnRight={backgroundVariant}
        $showCurreny={showCurreny}
        aria-label="Price"
        prependContent={
          backgroundVariant ? null : (
            <>
              <Icon isSrcRelative src="currency.svg" size="xs" />
              <StyledCurrency className="currency">USD</StyledCurrency>
            </>
          )
        }
        appendContent={
          backgroundVariant ? (
            <StyledUnit>
              <Icon isSrcRelative src="currency.svg" size="xs" />
              <StyledCurrency
                className="currency"
                style={{ position: "static" }}
              >
                USD
              </StyledCurrency>
            </StyledUnit>
          ) : null
        }
        value={price || ""}
        onChange={(value) => {
          value = value.replace(/\D/g, "");
          onPriceChange(+value);
        }}
        type="number"
        onKeyDown={(e) =>
          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
        }
      />
    </StyledContainer>
  );
};

export default PriceCurrenyField;
