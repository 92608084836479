import { useSearchParams } from "@/services/Routing";

function useStripeSessionUrlId() {
  const { searchParams } = useSearchParams();
  const stripeSessionIdFromUrl =
    searchParams.get("session_id") || searchParams.get("?session_id") || "";

  return {
    stripeSessionIdFromUrl: stripeSessionIdFromUrl,
  };
}

export default useStripeSessionUrlId;
