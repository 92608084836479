import React from "react";

import ContractPaymentSummary from "@/features/contracts/ContractPaymentSummary";
import { ContractCompleteDetails } from "@/services/ContractsService";

import { StyledSectionHeading } from "../ContractPreview.styles";

const PaymentSummary: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  return (
    <div className={className}>
      <StyledSectionHeading>Payment Summary</StyledSectionHeading>

      <ContractPaymentSummary
        className="mt-2"
        contractDetails={contractDetails}
      />
    </div>
  );
};

export default PaymentSummary;
