import React, { useEffect, useMemo } from "react";
import lodashGet from "lodash/get";

import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  isUserChannel as checkIsUserChannel,
  useChatIdFromUrl,
  useChatService,
  useConnectChannel,
} from "@/services/ChatServiceNew";
import { ChatRoomLoading } from "@/features/chat/ChatRoom";
import { useParamState } from "@/services/Routing";

import UserChatRoom from "./ChatRoom.user.stream";
import ContractChatRoom from "./ChatRoom.contract.stream";
import RequestChatRoom from "./ChatRoom.request.stream";

const ChatRoom: React.FC = () => {
  const { idFromUrl: channelId } = useChatIdFromUrl();

  const { isActive, connectUser, isConnecting } = useChatService();
  const { channel } = useConnectChannel({
    contractId: channelId,
  });

  const isUserChannel = useMemo(
    () => checkIsUserChannel({ channelId }),
    [channelId]
  );

  const channelType = lodashGet(
    channel,
    "data.extraData.channelType",
    ""
  ) as ChannelType;

  const [channelTypeFilter, setChannelTypeFilter] = useParamState<string>(
    CHANNEL_TYPE_FILTER_URL_KEY,
    "",
    {
      parseJson: false,
    }
  );

  useEffect(() => {
    if (isConnecting) {
      return;
    }

    if (channelId && !isActive) {
      connectUser();
    }
  }, [isActive, channelId, connectUser, isConnecting]);

  useEffect(() => {
    if (!!channelType && channelType !== channelTypeFilter) {
      setChannelTypeFilter(channelType);
    }
  }, [channelType, channelTypeFilter, setChannelTypeFilter]);

  if (isConnecting) {
    return <ChatRoomLoading />;
  }

  if (channelType === ChannelType.ChatRequest) {
    return <RequestChatRoom />;
  }

  if (isUserChannel) {
    return <UserChatRoom />;
  }

  return <ContractChatRoom />;
};

export default ChatRoom;
