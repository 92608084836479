"use client";

import React from "react";

import { useUnreadCount } from "@/services/ChatServiceNew";

import {
  StyledCount,
  StyledIconContainer,
} from "../DesktopPrimaryLayout.styles";

const ChatItem: React.FC<{ children?: any }> = ({ children }) => {
  const { unreadCount } = useUnreadCount();

  if (!unreadCount) {
    return children;
  }

  return (
    <StyledIconContainer>
      {children}
      <StyledCount>{unreadCount > 99 ? "99+" : unreadCount}</StyledCount>
    </StyledIconContainer>
  );
};

export default ChatItem;
