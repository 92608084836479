"use client";

import styled from "@emotion/styled";
import ActionCard from "@/components/misc/ActionCard";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 2rem 1rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledActionCard = styled(ActionCard)`
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);

  .content {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    & > *:first-child {
      flex: 1;
    }
  }
`;
