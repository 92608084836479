import { useMemo } from "react";
import useSWR from "swr";

import { userService } from "@/config/services";
import { selectUserData, useAppSelector } from "@/services/Store";

import { UserProfile } from "../UserService.types";
import { sanitizeProfileData, parseProfileData } from "../UserService.utils";

function useProfileData() {
  const userDataFromStore = useAppSelector(selectUserData);

  const userDetailsStringified = userDataFromStore?.details || "";
  const profileData = useMemo(
    () => parseProfileData(userDetailsStringified || ""),
    [userDetailsStringified]
  );

  const { isLoading: isProfileDataLoading, mutate } = useSWR(
    `user/${userDataFromStore?.id || "me"}/profile`,
    () =>
      userService.fetchUserInfo()?.then((res) => {
        try {
          let profileData = JSON.parse(
            res.data.data.user.details || "{}"
          ) as UserProfile;
          profileData = sanitizeProfileData(profileData);
          return profileData;
        } catch {
          return {} as UserProfile;
        }
      }),
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      dedupingInterval: 3000,
    }
  );

  //-----------------------

  return {
    profileData,
    isProfileDataLoading,
    accountData: userDataFromStore,
    refresh: () => mutate(),
  };
}

export default useProfileData;
