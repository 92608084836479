"use client";

import React from "react";

import { DEVICE_BREAKPOINT, useResponsive } from "@/styles";

import ContractsListPageMobile from "./ContractsListPage.mobile";
import ContractsListPageDesktop from "./ContractsListPage.desktop";
import { ContractsListPageProvider } from "./ContractsListPage.context";
import { RESPONSEIVE_BREAKPOINT } from "./ContractsListPage.config";

const ContractsListPage: React.FC = () => {
  const { screenWidth } = useResponsive();

  return (
    <ContractsListPageProvider>
      {screenWidth < DEVICE_BREAKPOINT[RESPONSEIVE_BREAKPOINT] ? (
        <ContractsListPageMobile />
      ) : (
        <ContractsListPageDesktop />
      )}
    </ContractsListPageProvider>
  );
};

export default ContractsListPage;
