import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledCard,
  StyledLeftSection,
  StyledBottomContainer,
  StyledRightSection,
  StyledTopContainer,
  StyledDate,
} from "./ChatCard.styles";

const ChatCard: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledCard $active={false} className={className}>
      <StyledTopContainer>
        <StyledLeftSection>
          <ShimmerLoader.Circular size="2rem" />
        </StyledLeftSection>

        <StyledRightSection>
          <ShimmerLoader.Default style={{ width: "6rem", height: "1rem" }} />
          <ShimmerLoader.Default
            className="mt-2"
            style={{ width: "8rem", height: "1rem" }}
          />

          <StyledDate
            as={ShimmerLoader.Default}
            style={{ width: "3rem", height: "1.25rem" }}
          />
        </StyledRightSection>
      </StyledTopContainer>

      <StyledBottomContainer>
        <StyledLeftSection>
          <ShimmerLoader.Circular size="1.25rem" />
        </StyledLeftSection>

        <StyledRightSection>
          <ShimmerLoader.Default
            className="mt-2"
            style={{ width: "8rem", height: "0.75rem" }}
          />
        </StyledRightSection>
      </StyledBottomContainer>
    </StyledCard>
  );
};

export default ChatCard;
