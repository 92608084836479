"use client";

import React from "react";

import { appFeatures } from "@/config/services";

import AboutMeInput from "./components/inputs/AboutMeInput";
import TimezoneInput from "./components/inputs/TimezoneInput";
import LanguagesInput from "./components/inputs/LanguagesInput";
import ProfessionInput from "./components/inputs/ProfessionInput";
import ExperienceInput from "./components/inputs/ExperienceInput/ExperienceInput";
import SkillInput from "./components/inputs/SkillInput";
import PackagesInput from "./components/inputs/PackagesInput";
import SocialLinksInput from "./components/inputs/SocialLinksInput";
import SaveButton from "./components/inputs/SaveButton";
import PortfoliosInput from "./components/inputs/PortfoliosInput";
import ProfileImageInput from "./components/inputs/ProfileImageInput";
import SpecializationInput from "./components/inputs/SpecializationInput";

const ProfileForm: React.FC = () => {
  return (
    <>
      <ProfileImageInput />

      <AboutMeInput className="mt-4" />

      <TimezoneInput className="mt-4" />

      {appFeatures.isSupported("ACCOUNT.EDIT_LANGUAGES") && (
        <LanguagesInput className="mt-4" />
      )}

      {appFeatures.isSupported("ACCOUNT.PROFESSIONS") && (
        <ProfessionInput className="mt-4" />
      )}

      {appFeatures.isSupported("ACCOUNT.EXPERIENCE") && (
        <ExperienceInput className="mt-4" />
      )}

      <SkillInput className="mt-4" />

      <SpecializationInput className="mt-4" />

      {appFeatures.isSupported("ACCOUNT.PACKAGES") && (
        <PackagesInput className="mt-4" />
      )}

      {appFeatures.isSupported("ACCOUNT.PORTFOLIOS") && (
        <PortfoliosInput className="mt-4" />
      )}

      <SocialLinksInput className="mt-4" />

      <SaveButton className="w-100 mt-5" />
    </>
  );
};

export default ProfileForm;
