import {
  ContractCompleteDetails,
  getContractOtherParticipant,
  getContractPayee,
  getContractPayer,
} from "@/services/ContractsService";

import { MediaFileFilters } from "./MediaService.types";

export function mediaFiltersToQueryParams(filters: MediaFileFilters = {}) {
  const queryParts: string[] = [];

  if (filters.contract_ids?.length) {
    queryParts.push(`contract=${filters.contract_ids[0]}`);
  }

  if (filters.file_ids?.length) {
    queryParts.push(`id=${filters.file_ids.join(",")}`);
  }

  return queryParts.join("&");
}

export function filterOutBrokenChats(list: ContractCompleteDetails[]) {
  const filtered = list.filter(
    (c) =>
      getContractPayee(c) !== getContractPayer(c) &&
      !!getContractOtherParticipant(c)
  );

  return filtered;
}
