import React from "react";

import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import Card from "@/components/data/Card";
import { SITE_PATHS } from "@/config/routing";
import { appFeatures } from "@/config/services";
import { ANIMATION_CLASSNAMES } from "@/styles";

import {
  StyledBalanceActionsContainer,
  StyledCardsContainer,
  StyledContractTable,
  StyledLink,
  StyledPage,
  StyledSecondaryHeading,
} from "./HomePage.styles";
import PrimaryActionsSection from "./components/PrimaryActionsSection";
import BalanceSection from "./components/BalanceSection";
import RequiredActionsList from "./components/RequiredActionsList";
import ContractActionCard from "./components/ContractActionCard";
import ContractsOverviewCard from "./components/ContractsOverviewCard";

const HomePage: React.FC = () => {
  return (
    <DesktopPrimaryLayout showBackButton={false}>
      <StyledPage className="no-scrollbar">
        <StyledCardsContainer>
          <StyledBalanceActionsContainer>
            <div>
              <BalanceSection className="h-100" />
              <ContractsOverviewCard className="h-100" />
            </div>

            <PrimaryActionsSection />
          </StyledBalanceActionsContainer>

          <Card>
            <StyledSecondaryHeading $bold>
              Required actions
              <StyledLink to={SITE_PATHS.ACTIONS_PAGE}>View all</StyledLink>
            </StyledSecondaryHeading>

            <RequiredActionsList />
          </Card>

          {appFeatures.isSupported("DASHBOARD.CONTRACT_MANAGEMENT_CARD") && (
            <ContractActionCard />
          )}
        </StyledCardsContainer>

        {appFeatures.isSupported("DASHBOARD.CONTRACTS_TABLE") && (
          <StyledContractTable className={ANIMATION_CLASSNAMES.FADE_IN} />
        )}
      </StyledPage>
    </DesktopPrimaryLayout>
  );
};

export default HomePage;
