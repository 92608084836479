import React, { useMemo } from "react";
import isArray from "lodash/isArray";

import { StyledContainer, StyledDot } from "./DotSeparated.styles";
import { IDotSeparatedProps } from "./DotSeparated.types";

const DotSeparated: React.FC<IDotSeparatedProps> = ({
  children: childrenFromProps,
}) => {
  const children = useMemo(
    () =>
      (isArray(childrenFromProps)
        ? childrenFromProps
        : [childrenFromProps]
      ).filter(Boolean),
    [childrenFromProps]
  );

  if (!children.length) {
    return null;
  }

  return (
    <StyledContainer>
      {children.map((child, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <StyledDot />}
          {child}
        </React.Fragment>
      ))}
    </StyledContainer>
  );
};

export default DotSeparated;
