import { SITE_PATHS } from "@/config/routing";
import Share, { ShareContent } from "@/services/Share";

export function getChatRoomPageUrl(contractId: string) {
  return `${SITE_PATHS.CHAT_PAGE}?id=${contractId}`;
}

//--------------------

export function getOfferShareContent(): ShareContent {
  return {
    title: "Come chat with me on Paypipe.",
    url: Share.getShareUrl(""),
    text: `Chat with me on Paypipe to discuss this job and track my progress.\n\n Paypipe provides secure and safe transactions globally between freelancers and their clients`,
  };
}

export const USER_CHANNEL_REGEX = /messaging:([0-9]{3}_[0-9]{3})/g;
export const USER_CHANNEL_SHORT_REGEX = /([0-9]{3}_[0-9]{3})/g;
export function isUserChannel({ channelId = "" }: { channelId?: string }) {
  return (
    !!channelId.match(USER_CHANNEL_SHORT_REGEX) ||
    !!channelId.match(USER_CHANNEL_REGEX) ||
    false
  );
}
