import React from "react";

import Button from "@/components/input/Button";
import { useToast } from "@/components/misc/Toast";

const Toast: React.FC = () => {
  const { createToast } = useToast();

  return (
    <div>
      <Button
        onClick={() => {
          createToast({
            title: "Something went right",
            description:
              "This is the text that explains to the user what the error is and what they should expect or do, if there is anything to do.",
          });
        }}
      >
        Create toast
      </Button>
    </div>
  );
};

export default Toast;
