import React from "react";

import {
  StyledContainer,
  StyledDescription,
  StyledTitle,
} from "./SettingCard.styles";
import { ISettingCardProps } from "./SettingCard.types";

const SettingCard: React.FC<ISettingCardProps> = ({
  title,
  description,
  input,
  className,
}) => {
  return (
    <StyledContainer className={className}>
      <div>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </div>
      {input}
    </StyledContainer>
  );
};

export default SettingCard;
