"use client";

import React from "react";

import { selectUnreadNotifications, useAppSelector } from "@/services/Store";
import { useNotifications } from "@/services/ChatService";

import { StyledCount, StyledIconContainer } from "../AppPrimaryTabBa.styles";

const NotificationItem: React.FC<{ children?: any }> = ({ children }) => {
  const { seenNotifications, isUpdatingNotifications } = useNotifications();
  const unreadNotifications = useAppSelector(selectUnreadNotifications);
  const unreadCount = unreadNotifications.length;

  if (seenNotifications || isUpdatingNotifications || !unreadCount) {
    return children;
  }

  return (
    <StyledIconContainer>
      {children}
      <StyledCount>{unreadCount > 99 ? "99+" : unreadCount}</StyledCount>
    </StyledIconContainer>
  );
};

export default NotificationItem;
