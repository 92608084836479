import React from "react";

import Button from "@/components/input/Button";
import TextField from "@/components/input/TextField";
import Form, { FormError } from "@/components/input/Form";

import { ShareFormValue } from "./ContractEmailShareForm.types";
import { VALIDATION_SCHEMA } from "./ContractEmailShareForm.config";

const ContractEmailShareForm: React.FC<{
  onSubmit?: (values: ShareFormValue) => void;
  className?: string;
  isSending?: boolean;
  tempSolutionTest?: boolean;
  scrollToTopDelayInMs?: number;
}> = ({
  onSubmit,
  className,
  isSending,
  // tempSolutionTest,
  // scrollToTopDelayInMs,
}) => {
  return (
    <Form<ShareFormValue>
      yupValidationSchema={VALIDATION_SCHEMA}
      initialValues={{ email: "", name: "" }}
      onSubmit={onSubmit}
    >
      {({
        context: {
          submitForm,
          values,
          setFieldValue,
          touchedAndHasError,
          errors,
          setFieldTouched,
        },
      }) => {
        const hasError = !!Object.keys(errors).length;

        return (
          <form className={className} onSubmit={submitForm}>
            <TextField
              autoFocus
              variant="background"
              placeholder="Email address"
              type="email"
              autoComplete="email"
              value={values.email}
              onChange={(value) => setFieldValue("email", value)}
              hasError={touchedAndHasError("email")}
              onBlur={() => {
                setFieldTouched("email");
              }}
              disabled={isSending}
              // scrollToTopDelayInMs={scrollToTopDelayInMs}
              // scrollToTopOnFocus={tempSolutionTest}
            />
            <FormError fieldKey="email" className="mt-2" />

            <TextField
              className="mt-4"
              variant="background"
              placeholder="Client name"
              autoComplete="name"
              value={values.name}
              onChange={(value) => setFieldValue("name", value)}
              hasError={touchedAndHasError("name")}
              onBlur={() => {
                setFieldTouched("name");
              }}
              disabled={isSending}
              // scrollToTopDelayInMs={scrollToTopDelayInMs}
              // scrollToTopOnFocus={tempSolutionTest}
            />
            <FormError fieldKey="name" className="mt-2" />

            <Button
              className="mt-5 w-100"
              type="submit"
              colorVariant={hasError ? "disabled" : "primary"}
              disabled={isSending}
            >
              Send offer
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default ContractEmailShareForm;
