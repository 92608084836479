"use client";
import React from "react";

import { SITE_PATHS } from "@/config/routing";

import {
  StyledLink,
  StyledPrimaryHeading,
  StyledSectionHeadingContainer,
} from "../../HomePage.styles";
import { StyledContainer } from "./RequiredActionsSection.styles";
import RequiredActionsList from "../RequiredActionsList";

const RequiredActionsSection: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <StyledContainer className={className}>
      <StyledSectionHeadingContainer>
        <StyledPrimaryHeading>Required actions</StyledPrimaryHeading>

        <StyledLink to={SITE_PATHS.ACTIONS_PAGE}>View all</StyledLink>
      </StyledSectionHeadingContainer>

      <RequiredActionsList />
    </StyledContainer>
  );
};

export default RequiredActionsSection;
