import Icon from "@/components/misc/Icon";
import ContractChatButton from "@/features/contracts/ContractChatButton";

import { useContractManagement } from "../ContractManagementPage.context";

const ChatButton: React.FC = () => {
  const { contractDetails } = useContractManagement();

  return (
    <ContractChatButton
      variant="secondary"
      colorVariant="gray"
      defaultText="Chat"
      contractId={contractDetails.id}
      prependContent={
        <Icon isSrcRelative src="chat.svg" size="xs" colorVariant="black" />
      }
    />
  );
};

export default ChatButton;
