import React from "react";

import Button from "@/components/input/Button";

import { INavigationProps } from "./Navigation.types";
import { StyledContainer } from "./Navigation.styles";

const Navigation: React.FC<INavigationProps> = ({
  prevButtonProps,
  nextButtonProps,
}) => {
  return (
    <StyledContainer>
      <Button useAria {...prevButtonProps} variant="ghost" colorVariant="black">
        &lt;
      </Button>
      <Button useAria {...nextButtonProps} variant="ghost" colorVariant="black">
        &gt;
      </Button>
    </StyledContainer>
  );
};

export default Navigation;
