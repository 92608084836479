"use client";

import styled from "@emotion/styled";
import QuickActionCard from "@/components/misc/QuickActionCard";

import { screenLargerThan, screenSmallerThan } from "@/styles";

export const StyledContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-inline: 1rem;
  margin-inline: -1rem;

  & > * {
    flex: 1;

    ${screenLargerThan.smallMonitor} {
      aspect-ratio: 1.25/1;
    }
  }

  ${screenLargerThan.tablet} {
    gap: 0.75rem;
  }
`;

export const StyledActionCard = styled(QuickActionCard)`
  max-height: 10rem;
  box-shadow: 0px 1px 0.25rem 0px #0c0c0d0d inset;
  text-align: center;

  ${screenSmallerThan.tablet} {
    min-width: unset !important;
    padding: 0.25rem;
  }
`;
