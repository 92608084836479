import React, { useCallback, useMemo } from "react";

import { IButtonProps } from "@/components/input/Button";
import { joinClassNames } from "@/utils/classNames";
import {
  useOnboardingAction,
  usePayoutOnboardStatus,
} from "@/services/UserService";
import ActionCard from "@/components/misc/ActionCard";
import Modal, { useModalState } from "@/components/misc/Modal";

import { StyledButton } from "../../ContractForm.styles";
import { useContractForm } from "../../ContractForm.context";
import { STEPS_CONFIG } from "../../ContractForm.config";

const StepContinueButton: React.FC<IButtonProps> = (props) => {
  const { onboardingCountry, promptIdentityVerification } =
    usePayoutOnboardStatus();
  const onboardAction = useOnboardingAction();
  const onboardModalState = useModalState();

  const {
    currentStep,
    checkIsStepCompleted,
    nextStep,
    isLastStep,
    submitForm,
    creatingContract,
    setFieldTouched,
    values,
  } = useContractForm();

  const isCurrentStepCompleted = useMemo(
    () => checkIsStepCompleted(currentStep),
    [currentStep, checkIsStepCompleted]
  );

  const handleClick = useCallback(() => {
    const validateStep = STEPS_CONFIG[currentStep]?.validateStep;

    if (validateStep) {
      validateStep({ setFieldTouched, values });
    }

    if (!isCurrentStepCompleted) {
      return;
    }

    if (isLastStep) {
      submitForm();
    } else {
      nextStep();
    }
  }, [
    nextStep,
    isLastStep,
    submitForm,
    isCurrentStepCompleted,
    setFieldTouched,
    values,
    currentStep,
  ]);

  if (
    promptIdentityVerification &&
    onboardingCountry &&
    onboardAction?.priority === "urgent"
  ) {
    return (
      <>
        <Modal
          showCloseButton
          state={onboardModalState}
          contentContainerProps={{ style: { padding: "0" } }}
          closeButtonProps={{
            variant: "ghost",
            style: { background: "transparent" },
          }}
          width="28rem"
        >
          <ActionCard
            {...onboardAction}
            ctaProps={{
              ...onboardAction.ctaProps,
              style: {
                marginTop: "1.25rem",
                width: "100%",
                paddingBlock: "0.75rem",
              },
            }}
          />
        </Modal>
        <StyledButton
          {...props}
          className={joinClassNames("w-100", props.className)}
          onClick={onboardModalState.open}
          colorVariant={isCurrentStepCompleted ? "primary" : "disabled"}
        >
          {props.children || "Continue"}
        </StyledButton>
      </>
    );
  }

  return (
    <StyledButton
      {...props}
      className={joinClassNames("w-100", props.className)}
      onClick={handleClick}
      colorVariant={isCurrentStepCompleted ? "primary" : "disabled"}
      disabled={creatingContract}
    >
      {creatingContract ? "Creating" : props.children || "Continue"}
    </StyledButton>
  );
};

export default StepContinueButton;
