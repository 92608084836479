import React from "react";

import { StyledButtomSmall } from "../../ContractTimeline.styles";
import {
  useConfirmFundEmailModal,
  useFundContractMilestone,
} from "@/services/ContractsService";
import { appFeatures } from "@/config/services";

interface IButtonProps {
  contractId: string;
  milestoneId: number;
  className?: string;
}

const FundMilestoneButton: React.FC<IButtonProps> = ({
  contractId,
  milestoneId,
  className,
}) => {
  const { fund, loading } = useFundContractMilestone({
    contractId,
    milestoneId,
  });
  const { modalJsx: emailConfirmModalJsx, modalState: emailConfirmModalState } =
    useConfirmFundEmailModal({
      ctaClick: fund,
    });

  if (
    appFeatures.isSupported("CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE")
  ) {
    return (
      <StyledButtomSmall
        disabled={loading}
        className={className}
        onClick={fund}
      >
        Fund milestone
      </StyledButtomSmall>
    );
  }

  return (
    <>
      {emailConfirmModalJsx}
      <StyledButtomSmall
        disabled={loading}
        className={className}
        onClick={emailConfirmModalState.open}
      >
        Fund milestone
      </StyledButtomSmall>
    </>
  );
};

export default FundMilestoneButton;
