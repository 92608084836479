import React, { useMemo } from "react";
import styled from "@emotion/styled";

import StatCard from "@/features/PaypipeId/StatCard";

export const StyledContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
`;

const ProfileStats: React.FC<{
  className?: string;
}> = ({ className }) => {
  const statsList = useMemo(() => {
    return [
      {
        title: "Jobs completed",
        icon: "/assets/pages/paypipe-id/jobs_completed.svg",
        // value: 98,
        value: 0,
      },
      {
        title: "Satisfied clients",
        icon: "/assets/pages/paypipe-id/clients.svg",
        // value: 55,
        value: 0,
      },
      {
        title: "Winning referrals",
        icon: "/assets/pages/paypipe-id/referrals.svg",
        // value: 38,
        value: 0,
      },
    ].filter((item) => !!item.value);
  }, []);

  if (!statsList.length) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      {statsList.map(({ title, icon, value }) => (
        <StatCard key={title} icon={icon} title={title} value={value} />
      ))}
    </StyledContainer>
  );
};

export default ProfileStats;
