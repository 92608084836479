import ImageTitleContent from "@/components/data/ImageTitleContent";

const NoOffers: React.FC<{ className?: string }> = ({ className }) => (
  <ImageTitleContent
    className={className}
    image="/assets/images/pages/contracts/no_contracts.svg"
    title={<>You don&apos;t have any offers</>}
    content="Select the Plus (+) icon below to create an offer"
  />
);

export default NoOffers;
