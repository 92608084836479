import { useCallback, useState } from "react";
import lodashGet from "lodash/get";

import { userService } from "@/config/services";

import { SubscriptionDuration } from "../UserService.types";

type Params = {
  interval?: SubscriptionDuration;
};

function useAvailSubscription(params?: Params) {
  const [isSubscribing, setIsSubscribing] = useState(false);

  //-----------------------

  const subscribe = useCallback(() => {
    if (!params?.interval) {
      return;
    }

    setIsSubscribing(true);

    return userService
      .subscribeToPremium({
        interval: params.interval,
      })
      .then((res) => {
        const paymentUrl = lodashGet(res, "data.url", "");

        if (paymentUrl) {
          window.location.href = paymentUrl;
        } else {
          setIsSubscribing(false);
        }
      })
      .catch(() => {
        setIsSubscribing(false);
      });
  }, [params]);

  //-----------------------

  return {
    subscribe,
    isSubscribing,
  };
}

export default useAvailSubscription;
