import React, { useState } from "react";

import { Body } from "@/components/Typography";
import RadioCardGroup from "@/components/input/RadioCardGroup";
import {
  CONTRACT_TYPE_CONFIG,
  ContractType,
} from "@/services/ContractsService";
import { useResponsive } from "@/styles";
import { useNotifyForFeature } from "@/services/AppFeatures";

import {
  StyledBox,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepHeading,
  StyledStepTopContent,
} from "../../../ContractForm.styles";
import { useContractForm } from "../../../ContractForm.context";
import StepContinueButton from "../../inputs/StepContinueButton";
import HeaderLayout from "../../misc/HeaderLayout";
import NotifySoon from "../../misc/NotifySoon";
import StepBackButton from "../../inputs/StepBackButton";

const ContractTypeStep: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { values, setFieldValue } = useContractForm();
  const [selectedOption, setSelectedOption] = useState(
    values.contract_type || ""
  );
  const { modalState, jsx } = useNotifyForFeature({
    featureName: "HOURLY_OFFER",
    onModalOpenChange: (isOpen) => {
      if (!isOpen) {
        setSelectedOption("" as any);
        setFieldValue("contract_type", "");
        setFieldValue("flow", "");
      }
    },
  });

  const {
    info: { title, description } = {
      title: "Choose engagement type",
      description: "Click on the options below for more information.",
    },
  } = CONTRACT_TYPE_CONFIG[selectedOption] || {};

  return (
    <>
      {jsx}
      <HeaderLayout>
        <StyledStep>
          <StyledStepTopContent>
            <StyledStepHeading>{title}</StyledStepHeading>
            <Body
              size="md"
              className="mt-2"
              style={
                isScreenSmallerThanTablet
                  ? {
                      color: "var(--clr-icon-text-200, #818898)",
                      maxWidth: "34ch",
                      marginBottom: "4rem",
                    }
                  : {
                      color: "var(--clr-icon-text-200, #818898)",
                      marginBottom: "2rem",
                    }
              }
            >
              {description}
            </Body>

            <StyledBox
              style={
                isScreenSmallerThanTablet
                  ? {}
                  : {
                      color: "var(--clr-icon-text-200, #818898)",
                      paddingTop: "3.5rem",
                    }
              }
            >
              <RadioCardGroup
                label="contract-type"
                value={selectedOption || ""}
                onChange={(value) => {
                  setSelectedOption(value as ContractType);

                  if (value === ContractType.Hourly) {
                    modalState.open();
                    setFieldValue("contract_type", "");
                    setFieldValue("flow", "");
                  } else {
                    setFieldValue("contract_type", value);
                    setFieldValue("flow", value);
                  }
                }}
                items={[
                  {
                    children: "One-time",
                    value: ContractType.OneTime,
                  },
                  {
                    children: "Milestones",
                    value: ContractType.Milestone,
                  },
                  {
                    children: "Hourly based",
                    value: ContractType.Hourly,
                  },
                ]}
              />

              {false && selectedOption === ContractType.Hourly && (
                <NotifySoon className="mt-3" onClick={modalState.open}>
                  Hourly-based offers are coming soon! Would you like us to
                  notify you when this feature is live?
                </NotifySoon>
              )}

              {!isScreenSmallerThanTablet && (
                <StyledStepFloatingContent
                  style={{ padding: 0, marginTop: "8rem" }}
                >
                  <StepBackButton />
                  <StepContinueButton />
                </StyledStepFloatingContent>
              )}
            </StyledBox>
          </StyledStepTopContent>

          {isScreenSmallerThanTablet && (
            <StyledStepFloatingContent>
              <StepContinueButton />
            </StyledStepFloatingContent>
          )}
        </StyledStep>
      </HeaderLayout>
    </>
  );
};

export default ContractTypeStep;
