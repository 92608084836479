import useSWR from "swr";
import lodashGet from "lodash/get";

import { userService } from "@/config/services";

function useStripeOnboardingCountries() {
  const {
    data = [],
    error,
    isLoading,
  } = useSWR(
    "/payment/supported-countries",
    () =>
      userService.getPaymentSupportedCountries().then((res) => {
        return lodashGet(res, "data.data.supported_countries", []);
      }),
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      dedupingInterval: 20000,
    }
  );

  return {
    data,
    error,
    isLoading,
    isUpdating: !!data && isLoading,
  };
}

export default useStripeOnboardingCountries;
