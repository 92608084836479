"use client";

import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import TextField from "@/components/input/TextField";
import Icon from "@/components/misc/Icon";
import MediaFileTile from "@/features/media/MediaFileTile";
import { squareSizing } from "@/utils/css";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// =======================

export const StyledHeader = styled.div`
  border-bottom: 1px solid var(--clr-border-25, #f6f8fa);
`;

export const StyledTopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 0.5rem;
  background: var(--clr-background-primary, #fff);
`;

export const StyledTopSectionContent = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const StyledTopLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledRoomTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: var(--clr-text-ukwn-6, #1d1e25);

  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledRoomTitleSecondary = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  color: var(--clr-text-ukwn-7, #808d9e);
  margin-top: 0.25rem;
`;

// =======================

export const StyledMessageSection = styled.div`
  flex: 1;
  padding: 1rem;
  overflow: auto;
`;

export const StyledInputSection = styled.div`
  padding: 1rem;
  background: var(--clr-background-primary, #fff);
  box-shadow: 0px 0px 108px 0px #0000000a;
`;

export const StyledInput = styled(TextField)`
  gap: 0;
  padding: 0;
  background: var(--clr-background-primary, #fff);
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 1.15rem;
  height: unset;
  overflow: visible;

  textarea {
    align-self: center;
    padding: 0.75rem 0.5rem;
    margin-block: 0.4rem;
    /* line-height: 20px; */
    /* height: unset !important; */

    width: 300px;
    min-height: 17px;
    border: 1px solid var(--clr-border-ukwn-10, #ccc);
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    max-height: 6.5rem;
  }
`;

export const StyledMessageBubble = styled.div<{ $isCurrentUser: boolean }>`
  position: relative;
  padding: 1rem;
  min-width: 110px;
  width: fit-content;
  max-width: calc(100% - 120px);

  &:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }

  ${({ $isCurrentUser }) =>
    $isCurrentUser
      ? `
      margin-left: auto;
      background: var(--clr-primary-100, #5F57FF);
      color: var(--clr-text-white, #fff);
      border-radius: 1.35rem;
      border-bottom-right-radius: 0;
      padding-bottom: 1.4rem;

      &:not(:first-of-type) {
        border-top-right-radius: 0;
      }
    `
      : `
      background: var(--clr-background-50, #eceff3);
      color: var(--clr-text-900, #0d0d12);
      border-radius: 1.05rem;
      border-top-left-radius: 0;

      &:not(:last-of-type)  {
        border-bottom-left-radius: 0;
      }
  `}
`;

export const StyledMessageText = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const StyledMessageGroup = styled.div<{ $isCurrentUser: boolean }>`
  margin-bottom: 1rem;
`;

export const StyledMessagesContainer = styled.div<{
  $isCurrentUser: boolean;
}>``;

export const StyledDate = styled.div<{ $isCurrentUser: boolean }>`
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  color: var(--clr-ukwn-3, #a4acb9);
  margin-top: 0.5rem;
  text-align: ${({ $isCurrentUser }) => ($isCurrentUser ? "right" : "left")};
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  bottom: 0.25rem;
  right: 0.65rem;
`;

export const StyledInputButton = styled(Button)`
  position: relative;
  padding: 1rem 0.75rem !important;
`;

export const StyledFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`;

export const StyledAttachmentsContainer = styled.div`
  display: flex;
  gap: 1rem;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
`;

export const StyledInputImageContainer = styled.div`
  position: relative;
`;

export const StyledInputImage = styled(MediaFileTile)`
  ${squareSizing("64px")};
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const StyledDeleteButton = styled(Button)`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  padding: 0.25rem;
  --bg-color: rgba(0, 0, 0, 0.1);
`;
