import { useCallback, useState } from "react";

import { contractService, mediaService } from "@/config/services";
import { FileObject } from "@/features/input/FilesInput";
import { useToast } from "@/components/misc/Toast";

import useContractDetails from "./useContractDetails";

function useSubmitContractMilestone(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId } = params;
  const { createToast } = useToast();
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = useCallback(
    (data: { description: string; attachments: FileObject[] }) => {
      if (isSubmitting) {
        return Promise.reject();
      }

      setIsSubmitting(true);
      setIsSubmitted(false);

      const { description, attachments } = data;
      const { contractId, milestoneId } = params;
      const hasWork = !!description || !!attachments?.length;

      return (async function () {
        try {
          const attatchmentsRes = await Promise.all(
            attachments
              .filter(({ file }) => !!file)
              .map(({ file }) => mediaService.uploadFile(file as File))
          );

          const attatchmentIds = attatchmentsRes.map((res) => res.data.data.id);

          const payload = {
            attachments: attatchmentIds,
            description,
            contractId,
            milestoneId,
          };

          await contractService.submitContractMilestone(payload);

          createToast({
            title: hasWork
              ? "Work submitted successfully."
              : "Payment request sent successfully.",
            variant: "success",
            timeoutInMilliseconds: 5000,
          });

          setIsSubmitted(true);
        } catch (err) {
          setIsSubmitted(false);

          createToast({
            title: hasWork
              ? "Something went wrong, Work not submitted."
              : "Something went wrong, request not sent.",
            variant: "error",
            description: `${err}`,
          });
        } finally {
          setIsSubmitting(false);
          reloadContractDetails();
        }
      })();
    },
    [params, isSubmitting, reloadContractDetails, createToast]
  );

  const markAsDone = useCallback(() => {
    return submit({
      attachments: [],
      description: "",
    });
  }, [submit]);

  return {
    submit,
    isSubmitted,
    isSubmitting,
    markAsDone,
  };
}

export default useSubmitContractMilestone;
