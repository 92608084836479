import { useMemo } from "react";

import { useAuth } from "@/services/Authentication";

import useContractDetails, { Params } from "./useContractDetails";
import useStripeSessionUrlId from "./useStripeSessionUrlId";
import { getContractMetaData } from "../utils";
import { ContractMetaData } from "../ContractsService.types";

function useManageContract(params: Params) {
  const { isAuthenticating } = useAuth();
  const {
    contractDetails,
    isLoading: isLoadingContractDetails,
    contractId,
  } = useContractDetails(params);
  const { stripeSessionIdFromUrl: stripeSessionId } = useStripeSessionUrlId();

  const contractMetaData = useMemo(() => {
    let data: ContractMetaData & { isPaymentRedirect: boolean } = {
      amIPayer: false,
      amIPayee: false,
      isApproved: false,
      isCompleted: false,
      nextSubmittableMilestone: null,
      nextReviewableMilestone: null,
      nextFundableMilestone: null,
      //----------------
      canManage: false,
      canApprove: false,
      canPreview: false,
      isPaymentRedirect: !!stripeSessionId,
    };

    if (isAuthenticating || isLoadingContractDetails || !contractDetails) {
      return data;
    }

    const metaData = getContractMetaData(contractDetails);
    data = {
      ...data,
      ...metaData,
    };

    return data;
  }, [
    isAuthenticating,
    isLoadingContractDetails,
    contractDetails,
    stripeSessionId,
  ]);

  const isLoading = isLoadingContractDetails || isAuthenticating;

  //-----------------

  const {
    amIPayer,
    canApprove,
    canManage,
    canPreview,
    isApproved,
    isPaymentRedirect,
    amIPayee,
    isCompleted,
    nextReviewableMilestone,
    nextSubmittableMilestone,
    nextFundableMilestone,
  } = contractMetaData;
  const contractMetaDataMemoized = useMemo(
    () => ({
      amIPayer,
      canApprove,
      canManage,
      canPreview,
      isApproved,
      isPaymentRedirect,
      amIPayee,
      isCompleted,
      nextReviewableMilestone,
      nextSubmittableMilestone,
      nextFundableMilestone,
    }),
    [
      amIPayer,
      canApprove,
      canManage,
      canPreview,
      isApproved,
      isPaymentRedirect,
      amIPayee,
      isCompleted,
      nextReviewableMilestone,
      nextSubmittableMilestone,
      nextFundableMilestone,
    ]
  );

  const output = useMemo(
    () => ({ ...contractMetaDataMemoized, isLoading, contractId }),
    [contractMetaDataMemoized, isLoading, contractId]
  );

  return {
    contractDetails,
    ...output,
  };
}

export default useManageContract;
