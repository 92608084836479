"use client";

import React from "react";

import {
  useSubscriptionDetails,
  useSubscriptionRedirect,
} from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { usePathname } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";

import ActiveSubscription from "./components/ActiveSubscription";
import SubscriptionSuccess from "./components/SubscriptionSuccess";
import SubscriptionPlans from "./components/SubscriptionPlans";
import SwitchPlan from "./components/SwitchPlan";

const SubscriptionPage: React.FC = () => {
  const { pathname } = usePathname();
  const { loadingSubscriptionDetails, subscriptionDetails } =
    useSubscriptionDetails();
  const { subscriptionSucceeded } = useSubscriptionRedirect();

  if (pathname === SITE_PATHS.MANAGE_SUBSCRIPTION_PAGE) {
    return <SwitchPlan />;
  }

  if (subscriptionSucceeded) {
    return <SubscriptionSuccess />;
  }

  if (loadingSubscriptionDetails) {
    return <SplashScreen />;
  }

  if (!subscriptionDetails) {
    return <SplashScreen />;
  }

  if (subscriptionDetails.hasPremium) {
    return <ActiveSubscription />;
  }

  return <SubscriptionPlans />;
};

export default SubscriptionPage;
