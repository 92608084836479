import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "../index";

export interface ChatState {
  hasChatData: boolean;
  unreadCount: number;
}

const initialState: ChatState = {
  hasChatData: false,
  unreadCount: 0,
};

export const chatSlice = createSlice({
  name: "CHAT",
  initialState,
  reducers: {
    setChatState: (state, action: PayloadAction<Partial<ChatState>>) => {
      const { hasChatData, unreadCount } = action.payload;

      if (hasChatData !== undefined) {
        state.hasChatData = hasChatData;
      }

      if (unreadCount !== undefined) {
        state.unreadCount = unreadCount;
      }
    },

    clear: (state) => {
      state.hasChatData = false;
      state.unreadCount = 0;
    },
  },
});

export const selectUnreadCount = (state: AppState) => state.chat.unreadCount;
export const selectChatSummary = (state: AppState) => state.chat;

export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
