"use client";

import React, { useMemo } from "react";

import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import NavMenu from "@/components/navigation/NavMenu";
import { useAuth } from "@/services/Authentication";
import { selectUserData, useAppSelector } from "@/services/Store";
import {
  getProfileCompletionPercentage,
  useProfileData,
  useSubscriptionDetails,
} from "@/services/UserService";
import { useNotifyForFeature } from "@/services/AppFeatures";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { appFeatures } from "@/config/services";
import AppVersion from "@/features/dev/AppVersion";

import {
  StyledAvatar,
  StyledAvatarContainer,
  StyledAvatarLabel,
  StyledEmail,
  StyledName,
  StyledPage,
  StyledUserDetailsContainer,
} from "./ProfileSettingsPage.styles";
import {
  NAV_ITEMS,
  PAYPIPE_ID_ITEM_INDEX,
  PREMIUM_SUBSCRIPTION_ITEM_INDEX,
} from "./ProfileSettingsPage.config";

const ProfileSettingsPage: React.FC = () => {
  const { logoutWithConfirmation } = useAuth();
  const userData = useAppSelector(selectUserData);
  const { profileData } = useProfileData();
  const { modalState: paypipeIdNotifyModalState, jsx: paypipeIdNotifyJsx } =
    useNotifyForFeature({
      featureName: "PAYPIPE_ID",
    });
  const { modalState: premiumNotifyModalState, jsx: premiumIdNotifyJsx } =
    useNotifyForFeature({
      featureName: "PREMIUM_SUBSCRIPTION",
    });

  const { subscriptionDetails, loadingSubscriptionDetails } =
    useSubscriptionDetails();

  const profileCompletionPercent = useMemo(
    () => getProfileCompletionPercentage({ profileData }),
    [profileData]
  );

  const navItems = useMemo(() => {
    const navItems = [...NAV_ITEMS];
    navItems[navItems.length - 1].onClick = logoutWithConfirmation;

    if (appFeatures.isSupported("SUBSCRIPTION")) {
      if (subscriptionDetails?.hasPremium) {
        navItems[PREMIUM_SUBSCRIPTION_ITEM_INDEX].title = "Subscription";
      }
    } else {
      navItems[PREMIUM_SUBSCRIPTION_ITEM_INDEX].onClick =
        premiumNotifyModalState.open;
      navItems[PREMIUM_SUBSCRIPTION_ITEM_INDEX].link = "";
    }

    navItems[PAYPIPE_ID_ITEM_INDEX].onClick = paypipeIdNotifyModalState.open;

    return navItems;
  }, [
    logoutWithConfirmation,
    paypipeIdNotifyModalState,
    premiumNotifyModalState,
    subscriptionDetails,
  ]);

  if (loadingSubscriptionDetails) {
    return <SplashScreen />;
  }

  return (
    <>
      {paypipeIdNotifyJsx}
      {premiumIdNotifyJsx}
      <MobilePrimaryLayout headerProps={{ logo: { component: "Account" } }}>
        <StyledPage>
          <div className="container">
            <StyledUserDetailsContainer>
              <StyledAvatarContainer>
                <StyledAvatar
                  img={userData?.picture || userData?.owner_profile || ""}
                  firstName={userData?.given_name}
                  lastName={userData?.family_name}
                />
                <StyledAvatarLabel>
                  {profileCompletionPercent}% Completed
                </StyledAvatarLabel>
              </StyledAvatarContainer>

              <StyledName>
                {userData?.given_name || ""} {userData?.family_name || ""}
              </StyledName>

              <StyledEmail>
                {(userData?.paypipe_id
                  ? `@${userData?.paypipe_id}`
                  : userData?.email) || ""}
              </StyledEmail>
            </StyledUserDetailsContainer>

            <NavMenu items={navItems} />
          </div>
        </StyledPage>

        <AppVersion />
      </MobilePrimaryLayout>
    </>
  );
};

export default ProfileSettingsPage;
