export const PREDEFNED_PREFIX = "predefined_";

export const SOCIAL_LINKS: {
  platformName: string;
  icon: string;
  displayName?: string;
  showInPlaceholder?: boolean;
  placeholderOrder?: number;
}[] = [
  {
    platformName: `${PREDEFNED_PREFIX}site`,
    displayName: "Personal website",
    icon: "browser.svg",
    showInPlaceholder: true,
    placeholderOrder: 1,
  },
  {
    platformName: `${PREDEFNED_PREFIX}X`,
    displayName: "X [Formly twitter]",
    icon: "social/twitter_x.svg",
    showInPlaceholder: true,
    placeholderOrder: 6,
  },
  {
    platformName: `${PREDEFNED_PREFIX}Behance`,
    icon: "social/behance.svg",
    showInPlaceholder: true,
    placeholderOrder: 2,
  },
  {
    platformName: `${PREDEFNED_PREFIX}Dribble`,
    icon: "social/dribble.svg",
    showInPlaceholder: true,
    placeholderOrder: 5,
  },
  {
    platformName: `${PREDEFNED_PREFIX}Linkedin`,
    icon: "social/linkedin.svg",
    showInPlaceholder: true,
    placeholderOrder: 3,
  },
  {
    platformName: `${PREDEFNED_PREFIX}Instagram`,
    icon: "social/instagram.svg",
    showInPlaceholder: true,
    placeholderOrder: 4,
  },
  {
    platformName: `${PREDEFNED_PREFIX}Facebook`,
    icon: "social/facebook.svg",
  },
  {
    platformName: `${PREDEFNED_PREFIX}TikTok`,
    icon: "social/tiktok.svg",
  },
  {
    platformName: `${PREDEFNED_PREFIX}YouTube`,
    icon: "social/youtube.svg",
  },
];

export const PLACEHOLDER_ICONS = SOCIAL_LINKS.filter(
  (item) => item.showInPlaceholder
)
  .sort((a, b) => (a.placeholderOrder || 0) - (b.placeholderOrder || 0))
  .map((item) => ({
    label: item.platformName.split(PREDEFNED_PREFIX)[1],
    icon: item.icon,
  }));
