"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { squareSizing } from "@/utils/css";
import { transientOptions } from "@/styles";
import Button from "@/components/input/Button";
import { Label } from "@/components/Typography";

export const StyledNav = styled.div`
  --default-option-filter: brightness(0) invert(43%) sepia(6%) saturate(1075%)
    hue-rotate(186deg) brightness(97%) contrast(91%);
  position: relative;
  z-index: 10;
  border-top: 1px solid var(--clr-border-50, #eceff3);
  box-shadow: 0 0 56px rgba(0, 0, 0, 0.04);
`;

export const StyledUl = styled.ul`
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  background: var(--clr-background-primary, #fff);
`;

export const StyledLi = styled.li<{ $primary: boolean }>`
  flex: 5;

  ${({ $primary }) =>
    $primary
      ? `
        flex: 6;
        position: relative;
 
    `
      : ""}
`;

export const StyledIconContainer = styled.div<{ $primary: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $primary }) =>
    $primary
      ? `
      ${squareSizing("3.25rem")};
        position: relative;
        background: var(--clr-primary-100, #5F57FF);
        border-radius: 10rem;

        & > * {
          filter: var(--filter-white)
        }
    `
      : ""}
`;

const getItemStyles = ({
  $primary,
  $active,
}: {
  $primary: boolean;
  $active: boolean;
}) => `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 0;
  height: 100%;
  text-decoration: none;
  padding: ${$primary ? "0.25rem" : "0.375rem"};
  text-align: center;
  width: 100%;

 color: ${
   $primary
     ? "var(--clr-icon-text-200, #818898)"
     : "var(--clr-primary-100, #5F57FF)"
 };
 
  .state-color { ${
    $primary
      ? ""
      : $active
      ? `
            filter: var(--filter-primary-500);
        `
      : `
            filter: var(--default-option-filter);
        `
    }
  }
 
`;

export const StyledLink = styled(Link, transientOptions)<{
  $primary: boolean;
  $active: boolean;
}>`
  ${({ $primary, $active }) => getItemStyles({ $primary, $active })}
`;

export const StyledButton = styled(Button, transientOptions)<{
  $primary: boolean;
  $active: boolean;
}>`
  ${({ $primary, $active }) => getItemStyles({ $primary, $active })};
  border: none !important;
  --color: var(--clr-icon-text-200, #818898);
`;

export const StyledTitle = styled(Label)<{ $active: boolean }>`
  font-weight: ${({ $active }) => ($active ? "700" : "400")} !important;
  min-width: fit-content;
  color: inherit;
  line-height: unset !important;
  margin-top: 0.35rem;
`;
