"use client";

import styled from "@emotion/styled";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Button from "@/components/input/Button";
import { screenLargerThan } from "@/styles";
import Card from "@/components/data/Card";

import ContractList from "./components/ContractList";
import { RESPONSEIVE_BREAKPOINT } from "./ContractsListPage.config";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 2rem 1rem;

  ${screenLargerThan[RESPONSEIVE_BREAKPOINT]} {
    display: flex;
    gap: 0.75rem;
    padding: 1rem;
    height: 100%;
    overflow: hidden;
  }
`;

export const StyledInputsContainer = styled.div`
  position: sticky;
  top: -18px;
  background: var(--clr-background-primary, #fff);
  border-radius: inherit;
  z-index: 1;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > * {
    min-width: 60px;
    padding-block: 0.25rem !important;
  }
`;

export const StyledNoData = styled(ImageTitleContent)`
  margin-top: 4rem;
`;

export const StyledButton = styled(Button)`
  border-width: 1px;
  font-weight: 400;
`;

//------------- Desktop

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  overflow: hidden;

  ${screenLargerThan[RESPONSEIVE_BREAKPOINT]} {
    flex-direction: row;
    gap: 2rem;
  }
`;

export const StyledCardSection = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 23rem;

  ${screenLargerThan.fullhd} {
    width: 26rem;
  }
`;

export const StyledManagmentSection = styled.div`
  flex: 1;
  overflow: hidden;

  & > div {
    height: 100%;
  }

  & > div > main {
    overflow: auto;
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
  }
`;

export const StyledContractList = styled(ContractList)`
  flex: 1;
  overflow: auto;
  margin-inline: -1rem;
  padding-inline: 1rem;
`;
