"use client";

import styled from "@emotion/styled";

import { screenLargerThan, screenSmallerThan } from "@/styles";

import ChatRoomPage from "../ChatRoomPage";
import { RESPONSIVE_BREAKPOINT_NAME } from "./ChatsPage.config";

export const StyledCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden;

  .str-chat__channel-list-messenger__main {
    overflow: hidden auto;
  }

  ${screenLargerThan[RESPONSIVE_BREAKPOINT_NAME]} {
    min-width: 25rem;
  }

  ${screenSmallerThan[RESPONSIVE_BREAKPOINT_NAME]} {
    padding: 1rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  ${screenLargerThan[RESPONSIVE_BREAKPOINT_NAME]} {
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
  }
`;

export const NoChatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledChatList = styled.div`
  flex: 1;
  overflow: auto;

  hr {
    opacity: 0.5;
    margin-block: 0.5rem;
  }

  .str-chat__channel-list-messenger__main {
    padding-inline: 0.75rem;
  }

  .str-chat-channel-list {
    border: none;
  }

  ${screenLargerThan[RESPONSIVE_BREAKPOINT_NAME]} {
    max-width: 25rem;
  }
`;

//------------- Desktop

export const StyledChatRoomSection = styled.div`
  flex: 1;
  border: 1px solid var(--clr-border-25, #f6f8fa);
`;

export const StyledChatRoomPage = styled(ChatRoomPage)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
