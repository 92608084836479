"use client";
import React, { useMemo } from "react";

import UserInfo, { IUserInfoProps } from "@/features/user/UserInfo";

import {
  CONTRACT_CREATOR_TYPE_CONFIG,
  getContractOtherParticipant,
  getContractParticipant,
} from "@/services/ContractsService";
import { getChatRoomPageUrl } from "@/services/ChatService";
import { getUserFullName } from "@/services/UserService";

import { IContractUserInfoProps } from "./ContractUserInfo.types";

const ContractUserInfo: React.FC<IContractUserInfoProps> = ({
  contract,
  userType: userTypeFromProps,
  fallbackUserType,
  className,
  ...otherProps
}) => {
  const userDetails = useMemo(() => {
    const otherUser = userTypeFromProps
      ? getContractParticipant(contract, userTypeFromProps)
      : getContractOtherParticipant(contract) ||
        (fallbackUserType
          ? getContractParticipant(contract, fallbackUserType)
          : null);

    if (!otherUser?.user) return null;

    const { role } = otherUser;
    const { email } = otherUser?.user;

    const image = otherUser?.user?.picture || "";
    const userName =
      getUserFullName(otherUser.user) || email || "";
    const userType = CONTRACT_CREATOR_TYPE_CONFIG[role].displayName;
    const chatUrl = getChatRoomPageUrl(contract.id);

    return {
      image,
      userType,
      userName,
      chatUrl,
      ratingDisplayProps: { rating: 0, reviewCount: 0 },
    } satisfies IUserInfoProps;
  }, [contract, userTypeFromProps, fallbackUserType]);

  if (!userDetails) {
    return null;
  }

  return <UserInfo {...otherProps} {...userDetails} className={className} />;
};

export default ContractUserInfo;
