import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledItem,
  StyledContent,
  StyledSelectedIconContainer,
  StyledLoaderContainer,
} from "./RadioCardGroup.styles";

const RadioCardGroup: React.FC<{
  className?: string;
  itemCount?: number;
  itemStyles?: React.CSSProperties;
}> = ({ className, itemCount = 3, itemStyles }) => {
  return (
    <StyledLoaderContainer className={className}>
      {/* @ts-ignore */}
      {[...Array(itemCount).keys()].map((i) => (
        <StyledItem
          $isSelected={false}
          $disabled={false}
          key={i}
          style={itemStyles}
        >
          <StyledContent $isSelected={false}>
            <ShimmerLoader.Default
              style={{ width: "15rem", height: "1.5rem" }}
            />
          </StyledContent>

          <StyledSelectedIconContainer $isSelected={false} $disabled={false}>
            <ShimmerLoader.Circular size="1rem" />
          </StyledSelectedIconContainer>
        </StyledItem>
      ))}
    </StyledLoaderContainer>
  );
};

export default RadioCardGroup;
