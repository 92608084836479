"use client";

import React from "react";

import {
  StyledContainer,
  StyledContentContainer,
  StyledMain,
} from "./PageWithHeaderLayout.styles";
import { IPageWithHeaderLayoutProps } from "./PageWithHeaderLayout.types";
import Header from "./components/Header";

const PageWithHeaderLayout: React.FC<IPageWithHeaderLayoutProps> = ({
  children,
  headerProps,
  stickyHeader = false,
  className,
  contentContainerProps,
  style,
}) => {
  return (
    <StyledContainer
      $stickyHeader={stickyHeader}
      className={className}
      style={style}
    >
      <Header {...headerProps} />
      <StyledMain
        $stickyHeader={stickyHeader}
        className="fade-in"
        style={contentContainerProps?.style}
      >
        <StyledContentContainer>{children}</StyledContentContainer>
      </StyledMain>
    </StyledContainer>
  );
};

export default PageWithHeaderLayout;
