import React, { useMemo, useState } from "react";

import {
  SubscriptionDuration,
  useCancelSubscription,
  useSubscriptionDetails,
  useUpdateSubscription,
} from "@/services/UserService";
import { formatDate } from "@/utils/date";
import Button from "@/components/input/Button";
import { Body, Display, Label } from "@/components/Typography";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { SITE_PATHS } from "@/config/routing";
import RadioCardGroup from "@/components/input/RadioCardGroup";

import { StyledContainer } from "./SwitchPlan.styles";
import Tag from "@/components/misc/Tag";

const SwitchPlan: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { 
    loadingSubscriptionDetails,
    subscriptionDetails,
  } = useSubscriptionDetails();
  const { isCancelling } = useCancelSubscription();
  const { update, isUpdating } = useUpdateSubscription();
  const [newPlan, setNewPlan] = useState(
    subscriptionDetails?.subscriptionPlanDuration || SubscriptionDuration.Yearly
  );

  const subscriptionEndDateFormatted = useMemo(
    () =>
      subscriptionDetails?.subscriptionEndDate
        ? formatDate( subscriptionDetails.subscriptionEndDate, "DD MMM, YYYY")
        : "",
    [subscriptionDetails]
  );

  const content = (
    <StyledContainer className="container">
      <Display size="lg" className="heading" as="div">
        Switch to a monthly plan
      </Display>

      <div className=" mb-4 mt-4">
        <Body size="md">
          Your plan change come into effect at the start of your next billing
          date, {subscriptionEndDateFormatted}
        </Body>

        <RadioCardGroup
          className="mt-4"
          label="contract-creator-type"
          value={newPlan}
          onChange={(value) => {
            setNewPlan(value as SubscriptionDuration);
          }}
          items={[
            {
              value: SubscriptionDuration.Yearly,
              children: (
                <div className="plan-card">
                  <div className="top">
                    <Label size="md">Annually</Label>
                  </div>

                  <div className="bottom">
                    <s>$240</s> $180
                  </div>
                </div>
              ),
            },
            {
              value: SubscriptionDuration.Monthly,
              children: (
                <div className="plan-card">
                  <div className="top">
                    <Label size="md">Monthly</Label> <Tag colorVariant="primary">Best value - Save $60</Tag>
                  </div>

                  <div className="bottom">
                  $20/ month
                  </div>
                </div>
              ),
            },
          ]}
          itemStyles={{ minHeight: "5rem" }}
        />
      </div>

      <div className="cta-section">
        <Button
          disabled={isUpdating || isCancelling}
          className="w-100"
          colorVariant="gradient-primary"
          onClick={() => {
            update(SubscriptionDuration.Yearly);
          }}
        >
          Switch to Monthly
        </Button>
      </div>
    </StyledContainer>
  );

  // --------------------------------

  if (loadingSubscriptionDetails) {
    return null;
  }

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          backButtonProps: { link: SITE_PATHS.SUBSCRIPTION_PAGE },
          titleProps: {
            children: "Manage Subscription",
          },
        }}
      >
        {content}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout
      headerProps={{
        logo: {
          component: "Manage Subscription",
        },
      }}
    >
      {content}
    </DesktopPrimaryLayout>
  );
};

export default SwitchPlan;
