import React, { useMemo } from "react";

import { formatDate } from "@/utils/date";
import DetailsTable, { Item } from "@/components/data/DetailsTable";
import {
  CONTRACT_TYPE_CONFIG,
  ContractCompleteDetails,
} from "@/services/ContractsService";

import { StyledSectionHeading } from "../ContractPreview.styles";

const DetailsSection: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  const { contract_type, end_date, milestones, created_at } = contractDetails;

  const endDate = end_date || milestones[0]?.end_date;

  const endDateFormatted = useMemo(
    () => (endDate ? formatDate(endDate) : ""),
    [endDate]
  );

  const creationDateFormatted = useMemo(
    () => (created_at ? formatDate(created_at) : ""),
    [created_at]
  );

  const detailFields = useMemo(() => {
    const fields: Item[] = [];

    if (contract_type) {
      fields.push({
        label: "Offer Type",
        value: CONTRACT_TYPE_CONFIG[contract_type].info.title,
      });
    }

    if (creationDateFormatted) {
      fields.push({
        label: "Creation Date",
        value: creationDateFormatted,
      });
    }

    if (endDateFormatted) {
      fields.push({
        label: "Due Date",
        value: endDateFormatted,
      });
    }

    return fields;
  }, [contract_type, creationDateFormatted, endDateFormatted]);

  return (
    <div className={className}>
      <StyledSectionHeading>Details</StyledSectionHeading>
      <DetailsTable className="mt-2" items={detailFields} />
    </div>
  );
};

export default DetailsSection;
