import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isObject from "lodash/isObject";
import { useSearchParams } from "react-router-dom";

type Config = { encode?: boolean; parseJson?: boolean };

const processRawValue = <T>(
  value: string,
  defaultValue: T,
  { parseJson, encode }: Config = {}
) => {
  if (value === null) {
    return defaultValue;
  }

  try {
    let procccedValue = value;
    if (encode) {
      procccedValue = atob(procccedValue);
    }

    if (parseJson) {
      return JSON.parse(procccedValue) as T;
    }

    return value as T;
  } catch {
    return value as T;
  }
};

/**
 * A custom hook that syncs state with a URL search parameter.
 * Supports string, number, boolean, and object values.
 * @param key The search parameter key to sync with.
 * @param defaultValue The default value for the state.
 * @returns A stateful value, and a function to update it.
 */
function useParamState<T>(
  key: string,
  defaultValue: T,
  config: Config = {}
): [T, (newValue?: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);
  const { encode = isObject(defaultValue), parseJson = true } = config;

  const value = useMemo(
    () => processRawValue<T>(paramValue || "", defaultValue, config),
    [paramValue, defaultValue, config]
  );
  const [state, setState] = useState(value);

  const setParamState = useCallback(
    (newValue?: T) => {
      try {
        setState(newValue as T);
        const newSearchParams = new URLSearchParams(searchParams);

        if (newValue === undefined) {
          newSearchParams.delete(key);
        } else {
          let urlValue = parseJson
            ? JSON.stringify(newValue)
            : (newValue as string);

          if (encode) {
            urlValue = btoa(urlValue);
          }

          newSearchParams.set(key, urlValue);
        }

        setSearchParams(newSearchParams);
      } catch {
        const newSearchParams = new URLSearchParams(searchParams);
        if (newValue === undefined) {
          newSearchParams.delete(key);
        } else {
          newSearchParams.set(key, JSON.stringify(newValue));
        }
      }
    },
    [key, encode, searchParams, setSearchParams, parseJson]
  );

  //-------------------

  const stateRef = useRef(state);
  stateRef.current = state;
  useEffect(() => {
    if (stateRef.current !== value) {
      setState(value);
    }
  }, [value]);

  //-------------------

  return [state, setParamState];
}

export default useParamState;
