import { useMemo } from "react";

import {
  amIContractPayer,
  getNextReviewableMilestone,
} from "@/services/ContractsService";
import Icon from "@/components/misc/Icon";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Modal, { useModalState } from "@/components/misc/Modal";
import ReviewSubmission from "@/features/contracts/ReviewSubmission";
import { Label } from "@/components/Typography";

import { StyledCard } from "./ReviewSubmissions.styles";
import { useContractManagement } from "../../ContractManagementPage.context";

const ReviewSubmissions: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { contractDetails } = useContractManagement();
  const modalState = useModalState();

  const amIPayer = useMemo(
    () => (contractDetails ? amIContractPayer(contractDetails) : false),
    [contractDetails]
  );

  const nextReviewableMilestone = useMemo(
    () =>
      contractDetails
        ? getNextReviewableMilestone(contractDetails).milestone
        : null,
    [contractDetails]
  );

  const showReviewSubmission = amIPayer && !!nextReviewableMilestone;

  if (!showReviewSubmission) {
    return null;
  }

  if (nextReviewableMilestone?.has_work === false) {
    return null;
  }

  const { id: contractId } = contractDetails;
  const { id: milestoneId } = nextReviewableMilestone;

  return (
    <>
      <StyledCard className={className} onClick={modalState.open}>
        <Icon isSrcRelative src="file_arrow_right.svg" customSize="1rem" />
        <Label size="lg">Review submissions</Label>
        <Icon isSrcRelative src="chevron_right.svg" customSize="xs" />
      </StyledCard>

      <Modal
        width="768px"
        mobileProps={{ fullscreen: true, className: "p-0" }}
        state={modalState}
      >
        <PageWithHeaderLayout
          headerProps={{
            titleProps: { children: "Review Work" },
            backButtonProps: {
              onClick: modalState.close,
              children: <Icon isSrcRelative src="cross.svg" size="xs" />,
              style: {
                background: "transparent",
              },
            },
          }}
        >
          <ReviewSubmission contractId={contractId} milestoneId={milestoneId} />
        </PageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default ReviewSubmissions;
