"use client";

import React, { useCallback, useRef } from "react";

import { Label } from "@/components/Typography";
import EditorField, { EditorFieldRef } from "@/components/input/EditorFieldNew";
import Button from "@/components/input/Button";
import { ANIMATION_CLASSNAMES } from "@/styles";

import { ABOUT_FIELD_URL_HASH, useProfileForm } from "../../ProfileForm.config";

const AboutMeInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue, submitForm } = useProfileForm();
  const { about_me } = values;
  const editorFieldRef = useRef<EditorFieldRef>(null);

  const handleSave = useCallback(() => {
    setFieldValue("about_me", about_me);

    setTimeout(() => {
      submitForm();
    }, 100);

    if (editorFieldRef.current) {
      editorFieldRef.current.closeEditor();
    }
  }, [submitForm, about_me, editorFieldRef, setFieldValue]);

  //----------------------------

  return (
    <div className={className}>
      <Label size="lg" as="label" htmlFor="about_me">
        About me
      </Label>

      <EditorField
        focusOnMount
        ref={editorFieldRef}
        prependContent={null}
        label=""
        placeholder="Tell clients about yourself"
        value={{ markdown: about_me }}
        onChange={({ markdown }) => {
          setFieldValue("about_me", markdown);
        }}
        drawerTitle="About me"
        placeholderVariant="bg-input"
        layoutProps={{
          headerProps: {
            appendContent: (
              <Button
                className={ANIMATION_CLASSNAMES.FADE_IN_FROM_BOTTOM}
                variant="ghost"
                onClick={handleSave}
              >
                Save
              </Button>
            ),
          },
        }}
        urlHash={ABOUT_FIELD_URL_HASH}
      />
    </div>
  );
};

export default AboutMeInput;
