import React, { createContext, useContext, useMemo } from "react";
import Ably from "ably";
import { AblyProvider } from "ably/react";

import { useAuth } from "@/services/Authentication";

import { ChatServiceContext as ChatServiceContextType } from "./ChatService.types";
import { ABLY_API_KEY } from "./ChatService.config";

const ChatServiceContext = createContext({} as ChatServiceContextType);

export const useChatService = () => useContext(ChatServiceContext);

export const ChatServiceProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated, loggedInUserId } = useAuth();

  const ablyClient = useMemo(
    () =>
      isAuthenticated && loggedInUserId
        ? new Ably.Realtime({
            key: ABLY_API_KEY,
            clientId: `${loggedInUserId}`,
            closeOnUnload: true,
          })
        : undefined,
    [loggedInUserId, isAuthenticated]
  );

  if (!isAuthenticated) {
    return children;
  }

  return (
    <AblyProvider client={ablyClient}>
      <ChatServiceContext.Provider
        value={{ isActive: !ablyClient, ablyClient: ablyClient || null }}
      >
        {children}
      </ChatServiceContext.Provider>
    </AblyProvider>
  );
};
