"use client";

import React from "react";

import { useContractList, useContractUrlId } from "@/services/ContractsService";
import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import ChatRoomPage from "@/features/pages/chat/ChatRoomPage";
import { appFeatures } from "@/config/services";
import { useParamState } from "@/services/Routing";
import { isUserChannel } from "@/services/ChatServiceNew";

import { StyledContainer, StyledTitleContainer } from "./ChatsPage.styles";
import NoChats from "./components/NoChats";
import ChatList from "./components/ChatList";
import SearchInput from "./components/SearchInput";
import { RESPONSIVE_BREAKPOINT } from "./ChatsPage.config";
import ChatTypeFilter from "./components/ChatTypeFilter";
import { Display } from "@/components/Typography";

const ChatsPage: React.FC = () => {
  const { data: contractList } = useContractList();
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();
  const [channelId] = useParamState<string>("id", "");

  const hasNoData = !contractList.length;
  const chatListJsx = <ChatList />;
  const noChatJsx = <NoChats />;

  if (contractIdFromUrl || isUserChannel({ channelId })) {
    return <ChatRoomPage />;
  }

  if (appFeatures.isSupported("CHAT.USE_STREAMIO")) {
    return (
      <MobilePrimaryLayout desktopSwitchBreakpoint={RESPONSIVE_BREAKPOINT}>
        <StyledContainer>
          <StyledTitleContainer className="px-3">
            <Display size="md">Chats</Display> <ChatTypeFilter />
          </StyledTitleContainer>

          {chatListJsx}
        </StyledContainer>
      </MobilePrimaryLayout>
    );
  }

  return (
    <MobilePrimaryLayout desktopSwitchBreakpoint={RESPONSIVE_BREAKPOINT}>
      <StyledContainer>
        <SearchInput />
        {hasNoData ? noChatJsx : chatListJsx}
      </StyledContainer>
    </MobilePrimaryLayout>
  );
};

export default ChatsPage;
