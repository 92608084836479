import React, { useMemo } from "react";

import PayoutOnboarding from "@/features/user/PayoutOnboarding";
import { getContractCreateUrl } from "@/services/ContractsService";

import HeaderLayout from "../misc/HeaderLayout";
import { useContractForm } from "../../ContractForm.context";
import { StyledBox } from "../../ContractForm.styles";

const PayoutOnboardingStep: React.FC = () => {
  const { values } = useContractForm();

  const returnUrl = useMemo(
    () =>
      `${window.location.origin}${getContractCreateUrl({
        contractType: values.contract_type,
        creatorType: values.created_as,
      })}`,
    [values]
  );

  return (
    <StyledBox>
      <HeaderLayout>
        <PayoutOnboarding
          stripeOnboardParams={{
            returnUrl,
            refreshUrl: returnUrl,
          }}
        />
      </HeaderLayout>
    </StyledBox>
  );
};

export default PayoutOnboardingStep;
