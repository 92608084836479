"use client";

import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import { overflowEllipsis } from "@/styles";

export const StyledContainer = styled.div`
  position: relative;

  & + & {
    margin-top: 1.5rem;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const StyledTopRow = styled(StyledRow)`
  /* min-height: 2rem; */
`;

export const StyledPrimaryText = styled(Label)<{
  $disabled?: boolean;
}>`
  color: ${({ $disabled }) =>
    $disabled
      ? "var(--clr-neutral-solid-600, #31353f)"
      : "var(--clr-ukwn-1, #36394A)"};
  ${overflowEllipsis()}
`;

export const StyledDescriptionText = styled(Label)<{
  $disabled?: boolean;
}>`
  color: var(--clr-text-900, #0d0d12);
  word-break: break-word;
  ${overflowEllipsis()}
`;

export const StyledSecondaryText = styled.div`
  color: var(--clr-icon-text-200, #818898);
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  word-break: break-word;

  & > * {
    ${overflowEllipsis()}
  }
`;

export const StyledSmallText = styled.div`
  color: var(--clr-icon-text-200, #818898);
  font-size: 0.6875rem;
  font-weight: 400;
`;

export const StyledSecondayInfo = styled(StyledSecondaryText)`
  & > * {
    min-width: max-content;
  }
`;
