import React from "react";

import Icon from "@/components/misc/Icon";
import PopoverButton, {
  usePopoverState,
} from "@/components/misc/PopoverButton";
import { useHash } from "@/services/Routing";

import { StyledButton, StyledMenu } from "../ChatRoomPage.styles";
import { useChatRoomPage } from "../ChatRoomPage.context";
import {
  CHAT_DETAILS_HASH,
  CONTRACT_DETAILS_HASH,
} from "../ChatRoomPage.config";

const ChatMenuButton: React.FC = () => {
  const menuState = usePopoverState();
  const { getUrlWithHash } = useHash();
  const { viewJobDetails, chatDetailsModalState } = useChatRoomPage();

  return (
    <PopoverButton
      state={menuState}
      buttonProps={{
        variant: "ghost",
        children: <Icon isSrcRelative size="sm" src="info_2.svg" />,
      }}
      popoverProps={{
        placement: "bottom right",
        crossOffset: 16,
        children: (
          <StyledMenu>
            <StyledButton
              className="w-100"
              variant="ghost"
              colorVariant="black"
              onClick={() => {
                viewJobDetails();
                menuState.close();
              }}
              link={getUrlWithHash(CONTRACT_DETAILS_HASH)}
            >
              <Icon isSrcRelative src="eyes.svg" size="xs" />
              <span>View job</span>
            </StyledButton>
            <StyledButton
              className="w-100"
              variant="ghost"
              colorVariant="black"
              onClick={() => {
                chatDetailsModalState.open();
                menuState.close();
              }}
              link={getUrlWithHash(CHAT_DETAILS_HASH)}
            >
              <Icon isSrcRelative src="info_2.svg" size="xs" />
              <span>Chat details</span>
            </StyledButton>
          </StyledMenu>
        ),
      }}
    />
  );
};

export default ChatMenuButton;
