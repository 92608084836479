"use client";

import { screenSmallerThan } from "@/styles";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledContainer = styled.div<{ $hasError?: boolean }>`
  --color-error: var(--clr-error-500, #ea5b5b);
  --color: var(--clr-neutral-solid-500, #525866);
  --label-top: 1rem;
  --label-top-focus: -0.5rem;
  --label-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  outline: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.75rem;
  color: var(--color);
  outline: none;
  overflow: hidden;
  gap: 0.5rem;
  padding-right: 0.75rem;

  ${({ $hasError }) =>
    `   
          background: ${
            $hasError
              ? "var(--clr-background-ukwn-11, #FFF0F3)"
              : "var(--clr-background-25,  #f6f8fa)"
          };
          border: 1.5px solid ${
            $hasError
              ? "var(--color-error)"
              : "var(--clr-border-ukwn-3, #f1f0f3)"
          };
        `}

  & > .input {
    position: relative;
    border: none !important;
    outline: none !important;
    background: transparent !important;
    flex: 1;
    max-width: -webkit-fill-available;
    text-align: inherit;
    overflow: hidden;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    border: none;
    background: transparent;
    flex-grow: 1;
    min-height: 56px;
    outline: none !important;
    padding: 0.75rem 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding-left: 0.75rem;
    cursor: pointer;

    &,
    &:-internal-autofill-selected,
    &::autofill,
    &:-webkit-autofill {
      font-size: inherit;
      font-weight: 500;
      color: inherit;
    }

    &::placeholder {
      color: var(--clr-text-500, #666d80);
    }
  }

  & > .button {
    ${squareSizing("2rem")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const OptionsContainer = styled.div`
  border-radius: 0.75rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 -2px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 0.5rem;

  ul {
    border-radius: inherit;
    overflow: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 15rem;
    padding: 0.25rem;

    ${screenSmallerThan.tablet} {
      max-height: 60vh;
    }
  }

  .option {
    padding: 0.75rem 0.75rem;
    border-radius: 0.75rem;
    margin-bottom: 0.125rem;
    cursor: pointer;

    &.selected {
      background: var(--clr-primary-100, #5f57ff);
      color: #fff;
    }
  }
`;
