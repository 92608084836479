import React, { useMemo } from "react";

import { formatDate } from "@/utils/date";
import {
  ContractCompleteDetails,
  getContractTotalValue,
} from "@/services/ContractsService";
import ShowMore from "@/components/input/ShowMore";

import {
  StyledContractDate,
  StyledContractTitle,
  StyledContractValue,
  StyledDataLabel,
  StyledDataValue,
  StyledMainDetailsContainer,
  StyledWysiwyg,
} from "../ContractPreview.styles";

const MainDetailsSection: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
  showCreationDate?: boolean;
}> = ({ contractDetails, className, showCreationDate = true }) => {
  const { title, description, created_at } = contractDetails;

  const creationDateFormatted = useMemo(
    () => (created_at ? formatDate(created_at) : ""),
    [created_at]
  );

  const totalValue = useMemo(
    () => getContractTotalValue(contractDetails),
    [contractDetails]
  );

  return (
    <div className={className}>
      <StyledMainDetailsContainer>
        <div>
          {showCreationDate && (
            <StyledContractDate className="mb-3">
              {creationDateFormatted}
            </StyledContractDate>
          )}
          <StyledContractTitle>{title}</StyledContractTitle>
        </div>

        <StyledContractValue>${totalValue}</StyledContractValue>
      </StyledMainDetailsContainer>
      <StyledDataLabel className="mt-4">Description</StyledDataLabel>

      <StyledDataValue className="mt-2">
        <ShowMore showMoreOnContentClick maxHeight="3.75em">
          <StyledWysiwyg readOnly value={{ markdown: description }} />
        </ShowMore>
      </StyledDataValue>
    </div>
  );
};

export default MainDetailsSection;
