"use client";

import React, { useMemo } from "react";

import { Body } from "@/components/Typography";

import { NavSection, StyledNavMenu } from "../DesktopPrimaryLayout.styles";
import { NAV_ITEMS } from "../DesktopPrimaryLayout.config";
import NotificationItem from "./NotificationItem";
import ChatItem from "./ChatItem";

const Nav: React.FC<{ sidebarCompact?: boolean }> = ({ sidebarCompact }) => {
  const navitems = useMemo(() => {
    const items = [...NAV_ITEMS];

    items[0].items = [...items[0].items];
    const notificationItem = items[0].items[3];
    items[0].items[3] = {
      ...notificationItem,
      render: ({ iconJsx, titleJsx, renderItemComponent, appendedIconJsx }) =>
        renderItemComponent({
          children: (
            <>
              <NotificationItem>{iconJsx}</NotificationItem>

              {titleJsx}

              {appendedIconJsx}
            </>
          ),
        }),
    };

    const chatItem = items[0].items[2];
    items[0].items[2] = {
      ...chatItem,
      render: ({ iconJsx, titleJsx, renderItemComponent, appendedIconJsx }) =>
        renderItemComponent({
          children: (
            <>
              <ChatItem>{iconJsx}</ChatItem>

              {titleJsx}

              {appendedIconJsx}
            </>
          ),
        }),
    };

    return items;
  }, []);

  return (
    <NavSection>
      {navitems.map(({ heading, items }, i) => (
        <React.Fragment key={i}>
          {!sidebarCompact && (
            <Body
              size="lg"
              style={{ color: "var(--clr-icon-text-200, #818898)" }}
              className={i === 0 ? "mt-4" : "mt-3"}
            >
              {heading}
            </Body>
          )}
          <StyledNavMenu
            className="mt-1"
            items={items}
            varaint="b"
            sidebarCompact={sidebarCompact}
            $sidebarCompact={sidebarCompact}
          />
        </React.Fragment>
      ))}
    </NavSection>
  );
};

export default Nav;
