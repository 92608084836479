"use client";

import styled from "@emotion/styled";

import GlobalSearch from "@/features/app/GlobalSearch";
import { screenLargerThan } from "@/styles";

export const StyledGlobalSearch = styled(GlobalSearch)`
  height: 46rem;

  ${screenLargerThan.tablet} {
    height: 40rem;
    max-height: calc(100vh - 4.5rem);
    overflow: hidden;
  }
`;
