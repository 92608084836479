import { IIconProps } from "@/components/misc/Icon";
import { SubscriptionDuration } from "@/services/UserService";

export const SUBSCRIPTION_FEATURES: {
  icon: string;
  title: string;
  description: string;
  short_description: string;
  iconProps?: Omit<IIconProps, "src">;
  tag?: string;
}[] = [
  {
    icon: "arrow_down.svg",
    title: "Lower Fees",
    short_description: "Pay only 3.5% service fees",
    description: "",
  },
  {
    icon: "support_2.svg",
    title: "Priority support",
    short_description: "Get expert help when you need it",
    description: "",
    iconProps: {
      size: "xs",
    },
  },
  {
    icon: "access.svg",
    title: "First access",
    short_description: "Jump the queue for new features",
    description: "",
    iconProps: {
      customSize: "0.75rem",
    },
  },
  {
    icon: "star_2.svg",
    title: "AI Assistant Penny",
    short_description: "Let Penny do the heavy lifting",
    description: "",
    tag: "Coming soon",
  },
];

export const USE_STATIC_PRICES = true;

export const SUBSCRIPTIONS_CONFIG: Record<
  SubscriptionDuration,
  {
    unitText: string;
    billingText: string;
    cost: number;
    discount?: number;
  }
> = {
  [SubscriptionDuration.Monthly]: {
    unitText: "month",
    billingText: "Billed monthly",
    cost: 20,
  },
  [SubscriptionDuration.Yearly]: {
    unitText: "year",
    billingText: "Billed annualy",
    cost: 200,
    discount: 40,
  },
};
