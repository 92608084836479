import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { InboundMessage, Message, PaginatedResult } from "ably";
import useSWR from "swr";
import lodashGet from "lodash/get";

import { useAuth } from "@/services/Authentication";
import { store, userActions } from "@/services/Store";
import { useUpdateProfile } from "@/services/UserService";

import useConnectChannel from "./useConnectChannel";
import { Notification } from "../ChatService.types";

function useNotifications(userOwnerId?: string) {
  const { userData } = useAuth();

  const userId = userOwnerId || userData?.owner_id || "";
  const channelName = userId ? `Notif:${userId}` : "";
  const { channel, isConnected } = useConnectChannel(channelName);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [paginatedMessages, setPaginagtedMessages] =
    useState<PaginatedResult<InboundMessage> | null>(null);

  const [loadingMessages_, setLoadingMessages] = useState(false);
  const isUpdatingNotifications = loadingMessages_ && !!notifications.length;
  const loadingNotifications = loadingMessages_ && !notifications.length;

  const { profileData, update, isProfileDataLoading } = useUpdateProfile();
  const [updatingSeenNotificationState, setUpdatingSeenNotificationState] =
    useState(false);
  const [updatingNotificationCountState, setUpdatingNotificationCountState] =
    useState(false);

  const updatedSeenStatusRef = useRef(true);

  //-----------

  const isActive = !!channel && isConnected;

  //----------------------------------------

  const seenNotifications = lodashGet(profileData, "misc.seenNotifications");
  const updateSeenNoficationState = useCallback(
    (state: boolean) => {
      if (updatingSeenNotificationState || isProfileDataLoading) {
        return;
      }

      const currentState = lodashGet(profileData, "misc.seenNotifications");

      if (currentState === state) {
        return Promise.resolve();
      }

      const updatedProfileData = JSON.parse(JSON.stringify(profileData));
      updatedProfileData.misc.seenNotifications = state;

      setUpdatingSeenNotificationState(true);
      return update(updatedProfileData, { notify: false }).finally(() => {
        setUpdatingSeenNotificationState(false);
      });
    },
    [profileData, update, updatingSeenNotificationState, isProfileDataLoading]
  );
  const updateSeenNoficationStateRef = useRef(updateSeenNoficationState);
  updateSeenNoficationStateRef.current = updateSeenNoficationState;

  //-----------

  const updateNoficationCountState = useCallback(
    (count: number) => {
      if (updatingNotificationCountState || isProfileDataLoading) {
        return;
      }

      const current = lodashGet(profileData, "misc.notificationCount");
      if (current === count) {
        return Promise.resolve();
      }

      const updatedProfileData = JSON.parse(JSON.stringify(profileData));
      updatedProfileData.misc.notificationCount = count;
      updatedProfileData.misc.seenNotifications = false;

      setUpdatingNotificationCountState(true);
      return update(updatedProfileData, { notify: false }).finally(() => {
        setUpdatingNotificationCountState(false);
      });
    },
    [profileData, update, updatingNotificationCountState, isProfileDataLoading]
  );
  const updateNoficationCountStateRef = useRef(updateNoficationCountState);
  updateNoficationCountStateRef.current = updateNoficationCountState;

  //----------------------------------------

  const handleSetMessages = useCallback(
    (newMessages: Notification[] = [], replace = false) => {
      setNotifications((previousMessages) => {
        if (replace) {
          return newMessages;
        }

        const newMessages_ = newMessages.map((m) => ({
          connectionId: m.connectionId,
          data: m.data,
          encoding: m.encoding,
          extras: m.extras,
          id: m.id,
          name: m.name,
          timestamp: m.timestamp,
        }));
        let updatedMessages = [...previousMessages, ...newMessages_];
        updatedMessages = uniqBy(updatedMessages, "id");

        store.dispatch(
          userActions.setNotitications({ notifications: updatedMessages })
        );

        return updatedMessages;
      });
    },
    []
  );

  const loadChannelMessages = useCallback(() => {
    setLoadingMessages(false);

    if (!isConnected || !channel) {
      return Promise.reject();
    }

    setLoadingMessages(true);

    return channel
      .history({ limit: 100 })
      .then((value) => {
        setPaginagtedMessages(value);
        handleSetMessages(value.items as Notification[]);

        return value;
      })
      .finally(() => {
        setLoadingMessages(false);
      });
  }, [channel, handleSetMessages, isConnected]);

  useEffect(() => {
    if (!channel) {
      return;
    }

    let cancel = false;

    const callback = (message: Message) => {
      if (!cancel) {
        handleSetMessages([message as Notification]);
        updateSeenNoficationStateRef.current(false);
      }
    };

    if (isConnected) {
      channel.subscribe(callback);
    }

    return () => {
      cancel = true;
      channel.unsubscribe(callback);
    };
  }, [channel, handleSetMessages, isConnected]);

  const { mutate } = useSWR<PaginatedResult<InboundMessage> | null>(
    isConnected ? `/chats/${channelName}/messages` : null,
    loadChannelMessages,
    {
      errorRetryInterval: 10000,
      errorRetryCount: 5,
      dedupingInterval: 10000,
      // revalidateOnMount: false,
      revalidateOnFocus: true,
      fallbackData: null,
    }
  );

  const loadNextMessages = useCallback(() => {
    if (paginatedMessages) {
      return paginatedMessages.next().then((value) => {
        mutate(value);
        if (value) handleSetMessages(value.items as Notification[]);

        return value;
      });
    }

    return Promise.reject();
  }, [paginatedMessages, mutate, handleSetMessages]);

  const notificationCount = lodashGet(profileData, "misc.notificationCount");
  const newNotificationCount = useMemo(
    () => notifications.length,
    [notifications]
  );
  useEffect(() => { 
    if (updatedSeenStatusRef.current) {
      return;
    }

    if (isProfileDataLoading || loadingNotifications) {
      return;
    }

    if (newNotificationCount > notificationCount) {
      updatedSeenStatusRef.current = true;
      updateNoficationCountStateRef.current(Math.max(0, newNotificationCount));
    }
  }, [
    newNotificationCount,
    notificationCount,
    isProfileDataLoading,
    loadingNotifications,
  ]);

  return {
    notifications,
    loadNextMessages,
    isActive,
    loadingNotifications,
    isUpdatingNotifications,
    updateSeenNoficationState: updateSeenNoficationStateRef.current,
    seenNotifications,
  };
}

export default useNotifications;
