import { SITE_PATHS } from "@/config/routing";
import { IQuickActionCardProps } from "@/components/misc/QuickActionCard";

export const ACTION_ITEMS: ({ id: string } & IQuickActionCardProps)[] = [
  {
    id: "PAYPIPE_ID",
    title: "Share ID",
    icon: "share_2.svg",
    link: "",
    bgColor: "#F2F1FF",
  },
  {
    id: "CREATE_OFFER",
    title: "Create Offer",
    icon: "create_offer.svg",
    link: SITE_PATHS.CREATE_CONTRACT_PAGE,
    bgColor: "#D1D8FA",
  },
  {
    id: "CHAT_INVITE",
    title: "Invite to chat",
    icon: "chat_arrow.svg",
    link: "",
    bgColor: "#ECDCFD",
  },
];

export const CHAT_INVITE_ITEM_INDEX = ACTION_ITEMS.findIndex(
  (item) => item.id === "CHAT_INVITE"
);

export const PAYPIPE_ID_ITEM_INDEX = ACTION_ITEMS.findIndex(
  (item) => item.id === "PAYPIPE_ID"
);
