import React from "react";

import { IButtonProps } from "@/components/input/Button";
import { ContractCompleteDetails } from "@/services/ContractsService";
import { useResponsive } from "@/styles";

import {
  StyledFeaturesSection,
  StyledContainer,
  StyledCtaButton,
  StyledBottomSection,
  StyledIllustrationBubble,
  StyledDesktopDetailsContainer,
  StyledDesktopTopContainer,
  StyledDesktopIllustrationContainer,
  StyledIllustration,
  StyledDesktopContainer,
  StyledDesktopCardsContainer,
} from "./LandingScreen.styles";
import FeatureCards from "../FeatureCards";
import ContractDetails from "../ContractDetails";

const LandingScreen: React.FC<{
  ctaButtonProps: IButtonProps;
  contractDetails: ContractCompleteDetails;
}> = ({ ctaButtonProps, contractDetails }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <StyledContainer>
      <div>
        <StyledDesktopTopContainer>
          <StyledDesktopIllustrationContainer>
            <StyledIllustration>
              <StyledIllustrationBubble
                src={
                  isScreenSmallerThanTablet
                    ? "/assets/images/pages/contracts/landing/bg_part.svg"
                    : "/assets/images/pages/contracts/landing/bg_part_desktop.svg"
                }
              />
            </StyledIllustration>
          </StyledDesktopIllustrationContainer>

          <StyledDesktopDetailsContainer>
            <ContractDetails contractDetails={contractDetails} />

            <StyledCtaButton
              colorVariant="gradient-primary"
              {...ctaButtonProps}
            >
              View Offer
            </StyledCtaButton>
          </StyledDesktopDetailsContainer>
        </StyledDesktopTopContainer>

        <StyledBottomSection>
          <StyledDesktopContainer>
            <StyledDesktopCardsContainer>
              <StyledFeaturesSection className="mt-5">
                <FeatureCards />
              </StyledFeaturesSection>
            </StyledDesktopCardsContainer>
          </StyledDesktopContainer>
        </StyledBottomSection>
      </div>
    </StyledContainer>
  );
};

export default LandingScreen;
