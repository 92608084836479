"use client";

import React, { useCallback, useState } from "react";

import { Label } from "@/components/Typography";
import TextField from "@/components/input/TextField";
import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import RadioCardGroup from "@/components/input/RadioCardGroup";
import {
  EXPERIENCE_LEVEL_CONFIG,
  ExperienceLevel,
} from "@/services/UserService";
import Button from "@/components/input/Button";

import {
  EXPERIENCE_FIELD_URL_HASH,
  useProfileForm,
} from "../../../ProfileForm.config";
import {
  StyledButtonsContainer,
  StyledHeading,
} from "./ExperienceInput.styles";

const ExperienceInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue } = useProfileForm();
  const drawerState = useDrawerState({ urlHash: EXPERIENCE_FIELD_URL_HASH });

  const [localValue, setLocalValue] = useState(values.experience_level);

  const hasValue = !!values.experience_level;
  const displayValue =
    EXPERIENCE_LEVEL_CONFIG[values.experience_level]?.info?.name;

  const handleApply = useCallback(() => {
    setFieldValue("experience_level", localValue);
    drawerState.close();
  }, [localValue, setFieldValue, drawerState]);

  const handleCancel = useCallback(() => {
    setLocalValue(values.experience_level);
    drawerState.close();
  }, [values.experience_level, drawerState]);

  return (
    <div className={className}>
      <Label size="lg" as="label" htmlFor="experience">
        Experience
      </Label>

      <div onClick={drawerState.toggle}>
        <TextField
          useFloatingLabel={hasValue}
          id="experience"
          className="mt-3"
          variant="background"
          placeholder="Add level of experience"
          value={displayValue}
          style={{
            pointerEvents: "none",
          }}
        />
      </div>

      <Drawer state={drawerState}>
        <div className="p-3">
          <StyledHeading>Experience</StyledHeading>

          <RadioCardGroup
            className="mt-4"
            label="experience_level"
            value={localValue}
            onChange={(value: string) => {
              setLocalValue(value as unknown as ExperienceLevel);
            }}
            items={[
              {
                children: "Expert",
                value: ExperienceLevel.Expert,
              },
              {
                children: "Intermediate",
                value: ExperienceLevel.Intermediate,
              },
              {
                children: "Entry Level",
                value: ExperienceLevel.EntryLevel,
              },
            ]}
          />

          <StyledButtonsContainer className="mt-5">
            <Button
              variant="secondary"
              colorVariant="gray"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button onClick={handleApply}>Apply</Button>
          </StyledButtonsContainer>
        </div>
      </Drawer>
    </div>
  );
};

export default ExperienceInput;
