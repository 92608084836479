export function removeDuplicatesObjects<T extends Record<string, any>>(
  array: T[],
  itemKey = "id"
): T[] {
  const seen: Record<any, boolean> = {};

  return array.filter(function (item: T) {
    return seen.hasOwnProperty(item[itemKey])
      ? false
      : (seen[item[itemKey]] = true);
  });
}

export function removeDuplicatesPrimitives<T = string | number>(
  array: T[]
): T[] {
  const seen: Record<string, boolean> = {};

  return array.filter(function (item: T) {
    return seen.hasOwnProperty(`${item}`) ? false : (seen[`${item}`] = true);
  });
}
