import Modal, { useModalState } from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import Button from "@/components/input/Button";

function useConfirmFundEmailModal({ ctaClick }: { ctaClick: () => void }) {
  const modalState = useModalState();

  const modalJsx = (
    <Modal state={modalState} width="480px">
      <Heading size="lg">Important Reminder</Heading>
      <Body className="mt-3" size="lg">
        To ensure seamless processing and tracking of the job, please use the
        same email address associated with your Paypipe account.
      </Body>
      <Body className="mt-2" size="lg">
        Using a different email may result in delays or issues with your
        transaction.
      </Body>

      <Button className="mt-4 w-100" onClick={ctaClick}>
        Got it
      </Button>
    </Modal>
  );

  return {
    modalJsx,
    modalState,
  };
}

export default useConfirmFundEmailModal;
