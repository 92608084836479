import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import ShimmerLoader from "@/components/misc/ShimmerLoader";
import Icon from "@/components/misc/Icon";
import { getLinkIcon, PREDEFNED_PREFIX, SocialLink } from "@/services/UserService";
import { squareSizing } from "@/utils/css";
import { screenLargerThan } from "@/styles";

//-----------------------------

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${screenLargerThan.widescreen} {
    flex-wrap: wrap;
  }
`;

const StyledLink = styled(Link)`
  ${squareSizing("4rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 0.5rem;

  .icon {
    background: transparent;
    --size: 60%;
  }

  ${screenLargerThan.widescreen} {
    --size: 3.125rem;
  }
`;

//-----------------------------

type ISocialLinksProps = {
  links?: SocialLink[];
  className?: string;
  loading?: boolean;
};

//-----------------------------

const SocialLinks: React.FC<ISocialLinksProps> = ({
  links = [],
  className,
  loading,
}) => {
  if (loading) {
    return (
      <StyledContainer className={className}>
        {/* @ts-ignore */}
        {[...Array(4).keys()].map((_, i) => (
          <ShimmerLoader.Circular
            key={i}
            size="4.5rem"
            style={{ borderRadius: "0.5rem" }}
          />
        ))}
      </StyledContainer>
    );
  }

  if (!links.length) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      {links.map((link, i) => {
        const { name } = link;
        const tooltip = name.includes(PREDEFNED_PREFIX)
          ? name.split(PREDEFNED_PREFIX)[1] || name
          : name;

        return (
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to={link.url}
            key={i}
            title={tooltip}
          >
            <Icon
              isSrcRelative
              className="icon"
              src={getLinkIcon(link)}
              colorVariant="gray"
            />
          </StyledLink>
        );
      })}
    </StyledContainer>
  );
};

export default SocialLinks;
