import React, { useMemo } from "react";

import { joinClassNames } from "@/utils/classNames";
import { useNotifyForFeature } from "@/services/AppFeatures";

import {
  StyledActionCard,
  StyledContainer,
} from "./PrimaryActionsSection.styles";
import {
  ACTION_ITEMS,
  CHAT_INVITE_ITEM_INDEX,
  PAYPIPE_ID_ITEM_INDEX,
} from "./PrimaryActionsSection.config";

const PrimaryActionsSection: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { modalState: paypipeIdNotifyModalState, jsx: paypipeIdNotifyJsx } =
    useNotifyForFeature({
      featureName: "PAYPIPE_ID",
    });
  const { modalState: chatInviteNotifyModalState, jsx: chatInviteNotifyJsx } =
    useNotifyForFeature({
      featureName: "CHAT_INVITE",
    });

  const items = useMemo(() => {
    const items = [...ACTION_ITEMS];

    items[CHAT_INVITE_ITEM_INDEX].onClick = chatInviteNotifyModalState.open;

    items[PAYPIPE_ID_ITEM_INDEX].onClick = paypipeIdNotifyModalState.open;

    return items;
  }, [paypipeIdNotifyModalState, chatInviteNotifyModalState]);

  return (
    <>
      {paypipeIdNotifyJsx}
      {chatInviteNotifyJsx}
      <StyledContainer className={joinClassNames(className, "no-scrollbar")}>
        {items.map((item, i) => (
          <StyledActionCard key={i} {...item} />
        ))}
      </StyledContainer>
    </>
  );
};

export default PrimaryActionsSection;
