import React from "react";

import SingleDateCalendar from "./components/SingleDateCalendar";
import RangeCalendar from "./components/RangeCalendar";
import { CalendarProps } from "./Calendar.types";

const Calendar: React.FC<CalendarProps> = (props) => {
  const { type } = props;

  if (type === "single-date") {
    return <SingleDateCalendar {...props} />;
  }

  if (type === "range") {
    return <RangeCalendar {...props} />;
  }

  return null;
};

export default Calendar;
