import ContractUserInfo, {
  IContractUserInfoProps,
} from "@/features/contracts/ContractUserInfo";

import { useContractManagement } from "../ContractManagementPage.context";

const OtherUserDetails: React.FC<Omit<IContractUserInfoProps, "contract">> = (
  props
) => {
  const { contractDetails } = useContractManagement();

  return <ContractUserInfo {...props} contract={contractDetails} />;
};

export default OtherUserDetails;
