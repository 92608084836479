import React from "react";

import FilesInputComponent from "@/features/input/FilesInput";

import { useContractForm } from "../../ContractForm.context";

const FilesInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue } = useContractForm();

  return (
    <FilesInputComponent
      className={className}
      value={values.files}
      onChange={(value) => {
        setFieldValue("files", value);
      }}
      label="Add Files"
      variant="outline"
      displayFileCount={0}
    />
  );
};

export default FilesInput;
