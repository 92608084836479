"use client";

import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import { overflowEllipsis } from "@/styles";

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem 4rem;
  width: 100%;
  min-height: 82px;
`;

export const StyledStartContainer = styled.div`
  position: absolute;
  left: 1rem;
`;

export const StyledEndContainer = styled.div`
  position: absolute;
  right: 1rem;
`;

export const StyledTitle = styled.div`
  flex: 1;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--clr-text-ukwn-1, #171717);
  text-align: center;
  ${overflowEllipsis()}
`;

export const StyledButton = styled(Button)`
  ${squareSizing("2rem")};
`;

export const StyledBackButton = styled(StyledButton)`
  * > img {
    --size: 0.75rem;
  }
`;
