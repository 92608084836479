import React, { Fragment, useMemo } from "react";
import lodashGet from "lodash/get";
import { useParams } from "react-router-dom";

import { MediaFile, useProfileData } from "@/services/UserService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { useFileSignedUrl } from "@/services/ContractsService";
import { Body, Heading, Label } from "@/components/Typography";
import { useAuth } from "@/services/Authentication";
import Avatar from "@/components/misc/Avatar";
import Icon from "@/components/misc/Icon";
import PackageCard from "@/features/user/PackageCard";

import {
  StyledContactButton,
  StyledFlex,
  StyledSlider,
  StyledUserDetails,
  StyledWysiwyg,
} from "./ServiceDetailsPage.styles";
import Button from "@/components/input/Button";

const Slider: React.FC<{
  file: MediaFile;
  className?: string;
}> = ({ file, className }) => {
  const { url } = useFileSignedUrl(file?.url || "");

  return (
    <StyledSlider
      className={className}
      src={url}
      alt=""
      width={24}
      height={24}
    />
  );
};

const ServiceDetailsPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { profileData } = useProfileData();
  const { userData } = useAuth();
  const params = useParams();
  const { packages } = profileData;

  const serviceId = +lodashGet(params, "service_id", "-1");
  const packageDetails = packages[serviceId];
  const { name, description, attachments, price, estimated_duration } =
    packageDetails;

  const firstImage = attachments.length ? attachments[0] : null;

  const otherPackages = useMemo(
    () => packages.filter((_, i) => i !== serviceId),
    [packages, serviceId]
  );

  const contentJsx = (
    <div className="container pb-5">
      {!!firstImage && <Slider file={firstImage} />}

      <Heading size="md" className="mt-3">
        {name}
      </Heading>

      <Label size="md">About</Label>
      {description && (
        <Body
          size="md"
          className="mt-2"
          style={{ color: "var(--clr-icon-400, #818898)" }}
        >
          <StyledWysiwyg readOnly value={{ markdown: description }} />
        </Body>
      )}

      <StyledFlex className="mt-3">
        <div>
          <Body size="md" style={{ color: "var(--clr-icon-400, #818898)" }}>
            Price:
          </Body>
          <Heading size="sm" className="mt-1">
            ${price}
          </Heading>
        </div>

        <div>
          <Body size="md" style={{ color: "var(--clr-icon-400, #818898)" }}>
            Time:
          </Body>
          <Heading size="sm" className="mt-1">
            {estimated_duration}
          </Heading>
        </div>
      </StyledFlex>

      <hr className="mt-3 mb-3" />

      <StyledUserDetails className="mt-5">
        <Avatar customSize="3.5rem" img={userData?.picture} />
        <Heading size="md" className="mt-2">
          Work with {userData?.given_name}
        </Heading>
        <StyledContactButton className="mt-3">
          <Icon isSrcRelative src="rocket.svg" size="xxs" /> Contact
        </StyledContactButton>
        <Body
          size="sm"
          className="mt-2"
          style={{ color: "var(--clr-neutral-solid-400, #808897)" }}
        >
          Typically responds within 24 hours
        </Body>
      </StyledUserDetails>

      {!!otherPackages.length && (
        <>
          <Body size="sm" className="mt-4">
            <span style={{ color: "var(--clr-neutral-solid-400, #808897)" }}>
              Other packages by
            </span>
            &nbsp;
            <b>{userData?.given_name}</b>
          </Body>

          {otherPackages.map((packageDetails, i) => (
            <Fragment key={i}>
              <PackageCard package={packageDetails} className="mt-3" />
              <hr className="mt-3 mb-4" />
            </Fragment>
          ))}
        </>
      )}
    </div>
  );

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          titleProps: { children: name },
          appendContent: (
            <>
              <Button variant="ghost" className="px-2">
                <Icon isSrcRelative src="share_2.svg" size="sm" />
              </Button>
              <Button variant="ghost" className="px-2">
                <Icon isSrcRelative src="menu_vertical.svg" size="sm" />
              </Button>
            </>
          ),
          endContainerProps: { style: { right: "0" } },
        }}
      >
        {contentJsx}
      </PageWithHeaderLayout>
    );
  }

  return <DesktopPrimaryLayout>{contentJsx}</DesktopPrimaryLayout>;
};

export default ServiceDetailsPage;
