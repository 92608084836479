import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { getViewPortHeightCssString } from "@/styles";
import ContractShareButton from "@/features/contracts/ContractShareButton";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: var(--clr-background-white, #fff);
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  max-width: 768px;
  margin: 0 auto;
`;

export const StyledTopContainer = styled.div`
  flex: 1;
  display: grid;
  place-items: center;

  & > div > p {
    max-width: 32ch;
  }
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  gap: 0.5rem;
  padding-block: 1rem;
`;

export const StyledShareButton = styled(ContractShareButton)`
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
