"use client";
import React, { useCallback } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import { Link } from "react-router-dom";

import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";
import {
  AuthMedium,
  useAuth,
  AuthAction,
  AuthParams,
} from "@/services/Authentication";
import Form from "@/components/input/Form";
import TextField from "@/components/input/TextField";
import { ANIMATION_CLASSNAMES } from "@/styles";
import Icon from "@/components/misc/Icon";

//-------------------------------

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type AuthData = {
  firstName: string;
  lastName: string;
  email: string;
};

const INITIAL_AUTH_DATA: AuthData = {
  firstName: "",
  lastName: "",
  email: "",
};

const SSO_BUTTONS: {
  authMedium: AuthMedium;
  label: string;
  icon: string;
}[] = [
  {
    authMedium: "google",
    label: "Google",
    icon: "social/google.svg",
  },
  // {
  //   authMedium: "fb",
  //   label: "Facebook",
  //   icon: "social/facebook_2.svg ",
  // },
  // {
  //   authMedium: "linkedin",
  //   label: "Linkedin",
  //   icon: "social/linkedin_2.svg",
  // },
];

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  email: yup.string().email().required(),
});

const StyledForm = styled.form`
  display: grid;
  gap: 1.5rem;
  grid-template-areas:
    "first-name last-name"
    "email email"
    "cta cta";

  & > *:nth-child(1) {
    grid-area: first-name;
  }

  & > *:nth-child(2) {
    grid-area: last-name;
  }

  & > *:nth-child(3) {
    grid-area: email;
  }

  & > *:nth-child(4) {
    grid-area: cta;
  }
`;

const StyledSocialButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

const USE_NAMED_SSO_BUTTON_VARIANT = true;

const StyledSocialButton = styled(Button)`
  gap: 1rem;
  font-size: 1rem;
  padding: 0;

  span {
    display: none;
  }
`;

const StyledSocialButtonTemp = styled(Button)`
  gap: 1rem;
  font-size: 1rem;
`;

const StyledSmallText = styled.p`
  font-size: 0.815rem;
  line-height: 160%;
  letter-spacing: -0.02em;
  margin: 0;
  font-weight: 400;
  color: var(--clr-text-500, #666d80);
  text-align: center;

  a {
    color: var(--clr-primary-100, #5f57ff) !important;
    font-weight: 500;
  }
`;

//-------------------------------

type AuthFunctionParams = AuthParams & {
  authAction: AuthAction;
};

interface IAuthFormProps {
  customAuthFunction?: () => Promise<any>;
  className?: string;
}

const AuthForm: React.FC<IAuthFormProps> = ({ className }) => {
  const { register, login } = useAuth();

  //------------------------

  const handleAuth = useCallback(
    ({ authAction, ...params }: AuthFunctionParams) => {
      if (authAction === "login") {
        return login(params);
      }

      if (authAction === "register") {
        return register(params);
      }
    },
    [login, register]
  );

  //------------------------

  return (
    <StyledContainer className={className}>
      <Form
        initialValues={INITIAL_AUTH_DATA}
        yupValidationSchema={VALIDATION_SCHEMA}
        onSubmit={({ email, firstName, lastName }) => {
          handleAuth({
            authAction: "register",
            medium: "email",
            email,
            userInitialData: {
              firstName,
              lastName,
            },
          });
        }}
      >
        {({
          context: { values, setFieldValue, submitForm, touchedAndHasError },
        }) => (
          <StyledForm
            className={ANIMATION_CLASSNAMES.FADE_IN}
            onSubmit={submitForm}
          >
            <TextField
              name="First name"
              variant="background"
              placeholder="First name"
              value={values.firstName}
              onChange={(value) => {
                setFieldValue("firstName", value);
              }}
              hasError={touchedAndHasError("firstName")}
            />

            <TextField
              name="Last name"
              variant="background"
              placeholder="Last name"
              value={values.lastName}
              onChange={(value) => {
                setFieldValue("lastName", value);
              }}
              hasError={touchedAndHasError("lastName")}
            />

            <TextField
              name="email"
              type="email"
              variant="background"
              placeholder="Email"
              value={values.email}
              onChange={(value) => {
                setFieldValue("email", value);
              }}
              hasError={touchedAndHasError("email")}
            />

            <Button type="submit" className="w-100 py-3">
              Continue
            </Button>
          </StyledForm>
        )}
      </Form>

      <Body
        size="md"
        className="mt-4"
        style={{ color: "#666D80", textAlign: "center" }}
      >
        Or proceed using:
      </Body>

      <StyledSocialButtonsContainer className="mt-4">
        {SSO_BUTTONS.map(({ authMedium, label, icon }) =>
          USE_NAMED_SSO_BUTTON_VARIANT ? (
            <StyledSocialButtonTemp
              key={authMedium}
              className="w-100"
              variant="secondary"
              colorVariant="gray"
              onClick={() => {
                handleAuth({ authAction: "register", medium: authMedium });
              }}
            >
              <Icon isSrcRelative customSize="2.25rem" src={icon} />
              {<span>{label}</span>}
            </StyledSocialButtonTemp>
          ) : (
            <StyledSocialButton
              key={authMedium}
              variant="ghost"
              colorVariant="gray"
              onClick={() => {
                handleAuth({ authAction: "register", medium: authMedium });
              }}
            >
              <Icon isSrcRelative customSize="3rem" src={icon} />
              {<span>{label}</span>}
            </StyledSocialButton>
          )
        )}
      </StyledSocialButtonsContainer>

      <StyledSmallText className="mt-3">
        By clicking accept, you are agreeing to the terms of this offer and
        Paypipe's
        <Link to={SITE_PATHS.TERMS_CONDITIONS_PAGE}>Terms of Use</Link> and{" "}
        <Link to={SITE_PATHS.POLICY_PAGE}>Privacy Policy</Link>
      </StyledSmallText>

      <Body
        size="md"
        className="mt-2"
        style={{ color: "#666D80", textAlign: "center" }}
      >
        Already have an account?&nbsp;
        <Button
          variant="ghost"
          style={{ paddingInline: "0" }}
          onClick={() => {
            handleAuth({ authAction: "login" });
          }}
        >
          Sign in
        </Button>
      </Body>
    </StyledContainer>
  );
};

export default AuthForm;
