"use client";

import styled from "@emotion/styled";

export const StyledCard = styled.div`
  padding: 1rem;
  border: 0.5px solid var(--clr-border-ukwn-2, #efefef);
  border-radius: 1.5rem;
  background: var(--clr-background-white, #fff);
  box-shadow: 0px 0.6729323267936707px 2.018796920776367px 0px #00000005,
    0px 4.037593841552734px 6.729323387145996px 0px #b1b1b114;
  overflow: hidden;
`;
