"use client";

import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import lodashGet from "lodash/get";

import { SkillDetails, useUpdateProfile } from "@/services/UserService";
import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import ComboBox from "@/components/input/ComboBox";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import { useModalState } from "@/components/misc/Modal";
import { useForm } from "@/components/input/Form";
import { useToast } from "@/components/misc/Toast";
import { SKILLS_TOOLS_OPTIONS } from "@/data/skillToolOptions";

import EditFormButton from "./EditFormButtonNew";

import { IAddButtonProps } from "./AddButton";

const VALIDATION_SCHEMA = yup.object().shape({
  skills: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().min(1).required(),
        rating: yup.number().min(0).required(),
      })
    )
    .required(),
});

type FormValues = {
  skills: SkillDetails[];
};

const OPTIONS = SKILLS_TOOLS_OPTIONS.map((item) => ({
  label: item,
  value: item,
}));

const StyledSkillInput = styled.div`
  & + & {
    margin-top: 1.25rem;
  }

  & + & {
    margin-top: 1.25rem;
  }

  .field {
    flex-grow: 1;
  }

  .cta-button {
    ${squareSizing("3.5rem")};
    border-radius: 10rem;
  }

  .range-field {
    accent-color: var(--clr-primary-100, #5f57ff);
  }

  ${screenLargerThan.tablet} {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1.25fr 1fr auto;
  }

  ${screenSmallerThan.tablet} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.75rem;

    & + & {
      margin-top: 1.75rem;
    }

    .select-field {
      width: 100%;
    }
  }
`;

const StyledDashedButton = styled(Button)`
  border-style: dashed;
  width: 100%;
  --bg-color: #f9fafb !important;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  padding-block: 1rem;
  margin-top: 1.5rem;

  ${screenSmallerThan.tablet} {
    margin-top: 1.75rem;
  }
`;

const RatingInput: React.FC<{
  index: number;
  className?: string;
}> = ({ index, className }) => {
  const { values, setFieldValue } = useForm<FormValues>();

  const ratingKey = `skills[${index}].rating`;
  const rating = lodashGet(values, ratingKey, 0);

  return (
    <TextField
      type="range"
      label="Skill Rating"
      aria-label="Skill Rating"
      className={className}
      value={rating}
      onChange={(value) => {
        setFieldValue(ratingKey, value);
      }}
      min={1}
      max={100}
      appendContent={<span>{rating}%</span>}
    />
  );
};

const SkillInput: React.FC<{
  index: number;
  className?: string;
}> = ({ index, className }) => {
  const { values, setFieldValue, setFieldTouched, touchedAndHasError } =
    useForm<FormValues>();

  const nameKey = `skills[${index}].name`;
  const name = lodashGet(values, nameKey, "");

  const handleDelete = useCallback(() => {
    const updated = [...values.skills];
    updated.splice(index, 1);
    setFieldValue("skills", updated);
  }, [values.skills, index, setFieldValue]);

  const options = useMemo(
    () =>
      OPTIONS.filter(
        ({ value }) =>
          value === name || !values.skills.find(({ name }) => value === name)
      ),
    [values.skills, name]
  );

  return (
    <StyledSkillInput className={className}>
      <ComboBox
        allowsCustomValue
        placeholder="Select Skill"
        value={name}
        className="field select-field"
        options={options}
        onChange={(value) => {
          setFieldValue(nameKey, value);
        }}
        hasError={touchedAndHasError(nameKey)}
        onBlur={() => {
          setFieldTouched(nameKey);
        }}
      />

      <RatingInput index={index} className="field range-field" />

      <Button
        variant="secondary"
        colorVariant="gray"
        className="cta-button"
        onClick={handleDelete}
      >
        <Icon isSrcRelative src="dustbin.svg" size="sm" colorVariant="black" />
      </Button>
    </StyledSkillInput>
  );
};

const EditSkills: React.FC<{
  className?: string;
  dashedVariantProps?: IAddButtonProps;
}> = ({ className, dashedVariantProps }) => {
  const addButtonRef = useRef<null | HTMLButtonElement>(null);

  const { createToast } = useToast();
  const { profileData, updateField } = useUpdateProfile();
  const [isUpdating, setIsUpdating] = useState(false);
  const modalState = useModalState({
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const initialValues = useMemo(
    () =>
      ({
        skills: profileData.skills_new || [],
      } satisfies FormValues),
    [profileData]
  );

  //---------------------

  const apply = useCallback(
    ({ skills }: FormValues) => {
      setIsUpdating(true);

      return updateField("skills_new", skills)
        .then(() => {
          createToast({
            title: "Skills updated",
            timeoutInMilliseconds: 5000,
          });
          modalState.close();
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [updateField, modalState, createToast]
  );

  //---------------------

  return (
    <EditFormButton<FormValues>
      heading="Add Skills & Tools"
      description="Select the tools or skills specific to your job and rate your ability to use."
      modalState={modalState}
      dashedVariantProps={dashedVariantProps}
      editButtonProps={{ className }}
      saveButtonProps={{
        disabled: isUpdating,
      }}
      cancelButtonProps={{
        disabled: isUpdating,
      }}
      modalProps={{
        width: "916px",
      }}
      formProps={{
        initialValues,
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
      }}
      content={({
        context: {
          values: { skills },
          setFieldValue,
        },
      }) => (
        <div>
          {skills.map((_, i) => (
            <SkillInput key={i} index={i} />
          ))}

          <StyledDashedButton
            ref={addButtonRef}
            colorVariant="gray"
            onClick={() => {
              const updated = [
                ...skills,
                {
                  name: "",
                  rating: 10,
                },
              ];
              setFieldValue("skills", updated);

              if (addButtonRef.current) {
                setTimeout(() => {
                  addButtonRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }, 500);
              }
            }}
          >
            <Icon isSrcRelative src="plus_round.svg" size="sm" />
            {skills.length ? "Add Another" : "Add"}
          </StyledDashedButton>
        </div>
      )}
    />
  );
};

export default EditSkills;
