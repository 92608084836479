"use client";

import styled from "@emotion/styled";

import {
  getViewPortHeightCssString,
  getViewPortWidthCssString,
  screenSmallerThan,
} from "@/styles";

export const StyledContainer = styled.div<{ $stickyHeader: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--clr-background-primary, #fff);
  transition: background 240ms ease-in;
  max-height: 100%;

  ${screenSmallerThan.tablet} {
    width: ${getViewPortWidthCssString()};
    height: ${getViewPortHeightCssString()};
  }

  ${({ $stickyHeader }) =>
    $stickyHeader
      ? `
      overflow: hidden; 
    `
      : `
      overflow: auto; 
    `}
`;

export const StyledContentContainer = styled.div`
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
`;

export const StyledMain = styled.main<{ $stickyHeader: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 1;
    height: 100%;
  }
 
  ${({ $stickyHeader }) =>
  $stickyHeader
    ? `
        overflow: auto;
      `
    : `
      `} 
`;
