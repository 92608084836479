import { DefaultGenerics, StreamChat } from "stream-chat";

export type StreamClient = StreamChat<DefaultGenerics> | null;

export type ChatServiceContext = {
  isActive: boolean;
  streamClient: StreamClient;
  channelListJsx: any;
  connectUser: () => Promise<boolean>;
  disconnectUser: () => Promise<boolean>;
  isConnecting: boolean;
};

export type Notification = any;

export type Message = any;

export enum ChannelType {
  ChatRequest = 'ChatRequest',
  PersonalChat = 'PersonalChat',
  Contract = 'Contract',
};
