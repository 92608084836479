import { useCallback, useState } from "react";

import { userService } from "@/config/services";

import { SubscriptionDuration } from "../UserService.types";
import useSubscriptionDetails from "./useSubscriptionDetails";

function useUpdateSubscription() {
  const { refresh } = useSubscriptionDetails();

  const [isUpdating, setIsUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  //-----------------------

  const update = useCallback(
    (duration: SubscriptionDuration) => {
      if (isUpdating) {
        return;
      }

      setIsUpdating(true);

      return (async function () {
        try {
          await userService.updateSubscription(duration);

          setUpdated(true);

          await refresh();
        } finally {
          setIsUpdating(false);
        }
      })();
    },
    [isUpdating, refresh]
  );

  //-----------------------

  return {
    isUpdating,
    updated,
    update,
  };
}

export default useUpdateSubscription;
