import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";
import { useForm } from "@/components/input/Form";

import { FormValues } from "../SpecializationForm.types";
import {
  FORM_ERRORS,
  MAX_EXPERIENCE_VALUE,
  MIN_EXPERIENCE_VALUE,
} from "../SpecializationForm.config";

export const StyledTextField = styled(TextField)`
  select {
    height: 100%;
    padding: 0.75rem 0.25rem;
    margin-block: 0.25rem;
    background: transparent;
    border-radius: 3px;
  }
`;

const ExperienceDurationInput: React.FC<{
  onChange: () => void;
  className?: string;
}> = ({ onChange, className }) => {
  const { values, setFieldValue, touchedAndHasError, errors } =
    useForm<FormValues>();

  const { experience_duration = 0, experience_duration_unit = "" } = values;

  const usePluralUnit = experience_duration > 1;

  return (
    <>
      <StyledTextField
        className={className}
        variant="background"
        type="number"
        placeholder="Experience"
        value={experience_duration || ""}
        onChange={(value) => {
          setFieldValue("experience_duration", +value);
          onChange();
        }}
        hasError={touchedAndHasError("experience_duration")}
        min={MIN_EXPERIENCE_VALUE}
        max={MAX_EXPERIENCE_VALUE}
        step={1}
        appendContent={
          <select
            style={
              touchedAndHasError("experience_duration_unit")
                ? {
                    border: "1px solid var(--clr-error-500, #ea5b5b)",
                    background: "var(--clr-background-ukwn-11, #FFF0F3)",
                  }
                : {}
            }
            value={experience_duration_unit}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("experience_duration_unit", value);
              onChange();
            }}
          >
            <option value="">Months/Years</option>
            <option value="Months">{usePluralUnit ? "Months" : "Month"}</option>
            <option value="Years">{usePluralUnit ? "Years" : "Year"}</option>
          </select>
        }
      />

      {!!errors.experience_duration && (
        <p className="color-error mt-3">
          {errors.experience_duration === FORM_ERRORS.MIN_EXPERIENCE_ERROR &&
            `Please enter value of more than ${MIN_EXPERIENCE_VALUE - 1}`}

          {errors.experience_duration === FORM_ERRORS.MAX_EXPERIENCE_ERROR &&
            `Please enter value of less than ${MAX_EXPERIENCE_VALUE}`}
        </p>
      )}
    </>
  );
};

export default ExperienceDurationInput;
