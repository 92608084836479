"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledCount = styled.div`
  ${squareSizing("1rem")};
  border-radius: 10rem;
  background-color: var(--clr-error-400, #df1c41);
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #fff;
`;
