import { useSearchParams } from "@/services/Routing";

function useChatIdFromUrl() {
  const { searchParams } = useSearchParams();
  const contractIdFromUrl = searchParams.get("id") || "";

  return {
    idFromUrl: contractIdFromUrl,
  };
}

export default useChatIdFromUrl;
