import { AddressParts, PlaceDetailsResponse } from "./Location.types";
import { extractFullAddressComponents } from "./Location.utils";

class Location {
  _autoCompleteService: google.maps.places.AutocompleteService | null = null;
  _placesService: google.maps.places.PlacesService | null = null;

  constructor() {
    this.init();
  }

  init = async () => {
    try {
      this._autoCompleteService = new google.maps.places.AutocompleteService();

      // @ts-ignore
      const { PlacesService } = await google.maps.importLibrary("places");
      const mapEl = document.getElementById("map");
      if (mapEl) {
        const map = new google.maps.Map(mapEl, {
          center: { lat: -33.867, lng: 151.195 },
          zoom: 15,
        });
        this._placesService = new PlacesService(map);
      }
    } catch (err) {
      console.log("DEBUG:Location:INIT", { err });
    }
  };

  isSupported = () => {
    return !!this._autoCompleteService && !!this._placesService;
  };

  getSuggessions = async (query: string) => {
    return new Promise<{
      predictions: google.maps.places.QueryAutocompletePrediction[];
    }>((resolve) => {
      if (!this._autoCompleteService) {
        return Promise.resolve({
          predictions: [],
        });
      }

      this._autoCompleteService.getQueryPredictions(
        { input: query },
        (predictions) => {
          resolve({ predictions: predictions || [] });
        }
      );
    });
  };

  getPlaceDetailsByID = async (
    placeId: string
  ): Promise<null | {
    details: PlaceDetailsResponse;
    addressParts: AddressParts;
  }> => {
    return new Promise((resolve) => {
      if (!this._placesService) {
        return Promise.resolve(null);
      }

      this._placesService.getDetails({ placeId }, (details) => {
        if (!details) {
          resolve(null);
          return;
        }

        resolve({
          details,
          addressParts: extractFullAddressComponents(details),
        });
      });
    });
  };
}

export default Location;
