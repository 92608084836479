import React from "react";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Modal, { useModalState } from "@/components/misc/Modal";
import { analytics } from "@/config/services";

import { StyledGlobalSearch } from "./GlobalSearchButton.styles";

const GlobalSearchButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const modalState = useModalState({
    onOpenChange(isOpen) {
      if (isOpen) {
        analytics.triggerEvent({
          event: "opened_global_search",
        });
      }
    },
  });

  return (
    <>
      <Button variant="ghost" onClick={modalState.open} className={className}>
        <Icon
          src="/assets/images/icons/search.svg"
          size="sm"
          colorVariant="black"
        />
      </Button>

      <Modal state={modalState} width="768px">
        <StyledGlobalSearch />
      </Modal>
    </>
  );
};

export default GlobalSearchButton;
