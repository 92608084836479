import { PostHogProvider, usePostHog } from "posthog-js/react";

import { POSTHOG_API_HOST, POSTHOG_KEY } from "./Feedback.config";

export const useFeedback = usePostHog;

const options = {
  api_host: POSTHOG_API_HOST,
};

export const FeedbackProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <PostHogProvider options={options} apiKey={POSTHOG_KEY}>
      {children}
    </PostHogProvider>
  );
};
