"use client";

import styled from "@emotion/styled";
import { Variant } from "./Input.types";

export const StyledContainer = styled.div<{
  $hasValue: boolean;
  $hasLabel: boolean;
  $hasError: boolean;
  $useFloatingLabel: boolean;
  $variant: Variant;
}>`
  --color-error: var(--clr-error-500, #ea5b5b);
  --color: var(--clr-neutral-solid-500, #525866);
  --label-top: 1.125rem;
  --label-top-focus: -0.5rem;
  --label-left: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;

  outline: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color);
  outline: none;
  overflow: hidden;

  .input-container {
    transition: all 240ms ease;
  }

  .input-container > *:first-child:not(label) {
    min-height: 56px;
  }

  .input-container > label {
    font-size: 1rem;
  }

  ${({ $variant, $hasError }) =>
    $variant === "background"
      ? `   
          gap: 0.5rem;
          padding: 0 0.75rem;
          border-radius: 0.75rem;
          background: ${
            $hasError
              ? "var(--clr-background-ukwn-11, #FFF0F3)"
              : "var(--clr-background-25,  #f6f8fa)"
          };
          border: 1.5px solid ${
            $hasError
              ? "var(--color-error)"
              : "var(--clr-border-ukwn-3, #f1f0f3)"
          };
        `
      : $variant === "outline"
      ? ` 
          gap: 1rem;

          .input-container {
            border-bottom: 1.5px solid ${
              $hasError
                ? "var(--color-error)"
                : "var(--clr-border-ukwn-5, #dfe1e7)"
            } !important;
          }
      `
      : ""}

  ${({ $useFloatingLabel, $hasError }) =>
    $useFloatingLabel
      ? `
        .input-container > label {
          position: absolute;
          top: var(--label-top);
          left: var(--label-left);
          color: ${
            $hasError
              ? "var(--clr-error-500,  #EA5B5B)"
              : "var(--clr-icon-text-200, #818898)"
          };
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25rem;
          pointer-events: none;
          transition: all 240ms ease;
        }
    `
      : ""}

  ${({ $hasValue, $hasLabel, $hasError, $useFloatingLabel }) => {
    if (!$useFloatingLabel) return "";

    const FOCUSED_INPUT_STYLES = $hasLabel
      ? `
      margin-top: 1.25rem;
    `
      : "";

    const FOCUSED_LABEL_STYLES = `
      color:  ${
        $hasError
          ? "var(--clr-error-500,  #EA5B5B)"
          : "var(--clr-ukwn-3,  #A4ACB9)"
      } !important;
      font-size: 0.75rem !important;
      line-height: 1rem !important;
      font-weight: 400 !important;
      transform: translateY(var(--label-top-focus));
    `;

    return $hasValue
      ? `
                .input-container {
                  ${FOCUSED_INPUT_STYLES}
                }

                .input-container > label {
                  ${FOCUSED_LABEL_STYLES}
                }
              `
      : ` 
              &:focus-within .input-container {
                  ${FOCUSED_INPUT_STYLES}
              } 

              &:focus-within .input-container > label {
                ${FOCUSED_LABEL_STYLES}
              }
            } 
        `;
  }};
`;

export const StyledInputContainer = styled.div<{
  $variant: Variant;
}>`
  flex: 1;
  /* border: 1px dashed green; */
  overflow: hidden;
`;
