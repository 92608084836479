import useSWR from "swr";

import { contractService } from "@/config/services";

type Params = {
  milestoneId: number;
};

function useContractMilestone(params: Params) {
  const { milestoneId } = params;

  const { data, error, isLoading } = useSWR(
    milestoneId ? `/contract/milestone/${milestoneId}` : null,
    () =>
      contractService
        .getContractMilestone({ milestoneId })
        .then((res) => res.data.data),
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      dedupingInterval: 30000,
    }
  );

  const isUpdating = !!data && isLoading;
  const notFound = !data && !isLoading;

  //-----------------------

  return {
    data,
    error,
    isLoading,
    isUpdating,
    notFound,
  };
}

export default useContractMilestone;
