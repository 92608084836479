import { useCallback, useState } from "react";
import lodashGet from "lodash/get";

import { analytics, userService } from "@/config/services";
import {
  selectIsStripeOnboarded,
  selectLoadingIsStripeOnboarded,
  useAppSelector,
} from "@/services/Store";

import { OnboardStripeApiParams } from "../UserService.types";
import useUpdateProfile from "./useUpdateProfile";
import usePayoutOnboardStatus from "./usePayoutOnboardStatus";

function useOnboardStripe() {
  const { profileData, update, isProfileDataLoading } = useUpdateProfile();
  const { loadedStateOnce } = usePayoutOnboardStatus();

  const isOnboarded = useAppSelector(selectIsStripeOnboarded);
  const isLoadingOnboardedState = useAppSelector(
    selectLoadingIsStripeOnboarded
  );

  const [updatingCanOnboardState, setUpdatingCanOnboardState] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const isReady = !isProfileDataLoading && loadedStateOnce;
  const canOnboardState = lodashGet(profileData, "misc.canOnboard");
  const canOnboard = canOnboardState && !isOnboarded;

  //------------------------

  const onboard = useCallback(
    (params: OnboardStripeApiParams) => {
      if (isOnboarding) {
        return;
      }

      analytics.triggerStripeOnboardingEvent({
        countryCode: params.countryCode,
      });

      setIsOnboarding(true);

      return userService
        .onboardStripe(params)
        .then((res) => {
          const stripeOnboardUrl = lodashGet(res, "data.data.url", "");
          if (stripeOnboardUrl) {
            window.location.href = stripeOnboardUrl;
          } else {
            setIsOnboarding(false);
          }
        })
        .catch(() => {
          setIsOnboarding(false);
        });
    },
    [isOnboarding]
  );

  const updateCanOnboardState = useCallback(
    (state: boolean) => {
      if (updatingCanOnboardState || isProfileDataLoading) {
        return;
      }

      const currentState = lodashGet(profileData, "misc.canOnboard");

      if (currentState === state) {
        return Promise.resolve();
      }

      const updatedProfileData = JSON.parse(JSON.stringify(profileData));
      updatedProfileData.misc.canOnboard = state;

      setUpdatingCanOnboardState(true);
      return update(updatedProfileData, { notify: false }).finally(() => {
        setUpdatingCanOnboardState(false);
      });
    },
    [profileData, update, updatingCanOnboardState, isProfileDataLoading]
  );

  return {
    isOnboarded,
    isLoadingOnboardedState,
    isReady,
    onboard,
    isOnboarding,
    updateCanOnboardState,
    canOnboard,
  };
}

export default useOnboardStripe;
