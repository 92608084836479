import React from "react";

import { Label } from "@/components/Typography";
import Tag from "@/components/misc/Tag";

import { ITimelineItemProps } from "./TimelineItem.types";
import {
  StyledContainer,
  StyledDescriptionText,
  StyledPrimaryText,
  StyledRow,
  StyledSecondaryText,
  StyledSecondayInfo,
  StyledTopRow,
} from "./TimelineItem.styles";

const TimelineItem: React.FC<ITimelineItemProps> = ({
  title,
  value,
  tag,
  description,
  secondaryInfo,
  disabled = false,
  children,
  className,
  isMilestoneItem = false,
  isSubItem = false,
}) => {
  const DescriptionComponent = description?.bold
    ? StyledDescriptionText
    : StyledSecondaryText;

  const TitleComponent = title?.bold
    ? StyledDescriptionText
    : StyledPrimaryText;

  if (disabled) {
    return (
      <StyledContainer className={className}>
        <TitleComponent $disabled size="md">
          {title?.content}
        </TitleComponent>
      </StyledContainer>
    );
  }

  const { fullWidth = true } = title || {};

  return (
    <StyledContainer className={className}>
      <StyledTopRow
        style={
          !!tag || isMilestoneItem
            ? { position: "relative", top: "-0.25rem" }
            : {}
        }
      >
        <TitleComponent
          size="md"
          className={fullWidth ? "flex-grow-1" : ""}
          style={
            !title?.bold && isSubItem
              ? { color: "#666D80", fontSize: "0.865rem" }
              : {}
          }
        >
          {title?.content}
        </TitleComponent>

        {!!value && <Label size="md">{value.content}</Label>}

        {!!tag && (
          <Tag dotVariant variant="filled" colorVariant={tag.color}>
            {tag.content}
          </Tag>
        )}
      </StyledTopRow>

      {(!!description || !!secondaryInfo) && (
        <StyledRow className="mt-1">
          {!!description && (
            <DescriptionComponent
              size="md"
              className="flex-grow-1"
              style={
                isMilestoneItem
                  ? { fontWeight: 700, overflow: "hidden" }
                  : { overflow: "hidden" }
              }
            >
              <span>
                {description.content}
              </span>
            </DescriptionComponent>
          )}

          {!!secondaryInfo && (
            <StyledSecondayInfo>
              <span>{secondaryInfo.content}</span>
            </StyledSecondayInfo>
          )}
        </StyledRow>
      )}

      {children}
    </StyledContainer>
  );
};

export default TimelineItem;
