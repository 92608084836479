import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import { StyledTable, StyledTr } from "./DetailsTable.styles";

const DetailsTable: React.FC<{ className?: string; itemCount?: number }> = ({
  className,
  itemCount = 3,
}) => {
  if (!itemCount) {
    return null;
  }

  return (
    <StyledTable className={className}>
      <tbody>
        {[...new Array(itemCount)].map((_, i) => (
          <StyledTr key={i} $border>
            <td>
              <ShimmerLoader.Default
                style={{
                  display: "inline-block",
                  height: "22px",
                  width: "5rem",
                }}
              />
            </td>
            <td>
              <ShimmerLoader.Default
                style={{
                  display: "inline-block",
                  height: "22px",
                  width: "5rem",
                }}
              />
            </td>
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default DetailsTable;
