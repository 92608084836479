"use client";
import React from "react";
import styled from "@emotion/styled";

import { Body, Heading, Label } from "@/components/Typography";
import TextField from "@/components/input/TextField";
import {
  getUserFullName,
  useContactUser,
  usePaypipeIdProfile,
} from "@/services/UserService";
import { useParamState } from "@/services/Routing";
import UserInfo from "@/features/user/UserInfo";
import SplashScreen from "@/features/pages/app/SplashScreen";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";
import AuthForm from "@/features/auth/AuthForm";
import { screenLargerThan, screenSmallerThan, useResponsive } from "@/styles";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Logo from "@/features/app/Logo";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { useAuth } from "@/services/Authentication";

//-------------------------------

const StyledContainer = styled.div`
  padding: 1rem;
  height: 100%;

  ${screenLargerThan.widescreen} {
    display: flex;
    align-items: center;
    padding-top: 2rem;

    & > * {
      display: grid;
      grid-template-columns: 7fr 5fr;
      gap: 3rem;
      flex-grow: 1;

      & > *:first-child {
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 0.5rem;

        .field {
          flex-grow: 1;
          min-height: 16rem;
        }
      }
    }
  }
`;

const StyledUserInfo = styled(UserInfo)`
  background-color: #f8fafb;
  border-radius: 0.75rem;
  padding: 1rem;
`;

const StyledLayout = styled(PageWithHeaderLayout)`
  ${screenSmallerThan.widescreen} {
    header {
      min-height: unset;
    }
  }
`;

const StyledRightSection = styled.div`
  ${screenSmallerThan.widescreen} {
    margin-top: 1.5rem;
  }
`;

const StyledFormSection = styled.div`
  margin-top: 1.5rem;

  ${screenLargerThan.widescreen} {
    margin-top: 2rem;
  }
`;

//-------------------------------

const ContactUserPage: React.FC = () => {
  const [paypipeId] = useParamState<string>("paypipe_id", "");
  const {
    message,
    setMessage,
    contact,
    saveMessageInCache,
    isPostAuthContactFlow,
    isSending,
  } = useContactUser({
    paypipeId,
  });
  const { isLoading, accountData } = usePaypipeIdProfile(paypipeId);
  const { isScreenSmallerThanTablet } = useResponsive();
  const { isAuthenticated, userData } = useAuth();

  if (isLoading || isPostAuthContactFlow || isSending) {
    return <SplashScreen />;
  }

  if (!accountData || !paypipeId) {
    return <Redirect path={SITE_PATHS.HOME_PAGE} />;
  }

  if (`${paypipeId}` === userData?.paypipe_id) {
    return <Redirect replace path={SITE_PATHS.CHAT_PAGE} />;
  }

  const passedMaxLimit = message.length > 50;

  return (
    <StyledLayout
      headerProps={{
        titleProps: { children: "Get In Touch" },
        backButtonProps: {
          style: { display: isScreenSmallerThanTablet ? undefined : "none" },
        },
        prependContent: !isScreenSmallerThanTablet && (
          <Logo variant="gradient" />
        ),
        appendContent: !isScreenSmallerThanTablet && (
          <Button variant="ghost" link={SITE_PATHS.HOME_PAGE}>
            <Icon isSrcRelative src="cross.svg" size="xs" />
          </Button>
        ),
      }}
    >
      <StyledContainer>
        <div>
          <div>
            <Heading size="lg">Hi there, what say you?</Heading>
            <Body size="lg">
              Please describe the project, including any specific requirements,
              timelines, and goals.
            </Body>

            <Label size="lg" className="mt-3">
              Project details*
            </Label>
            <TextField
              textArea
              useFloatingLabel={!passedMaxLimit}
              className="mt-2 mb-3 field"
              variant="background"
              placeholder="Minimim 50 characters"
              value={message}
              onChange={setMessage}
              onBlur={saveMessageInCache}
            />
          </div>

          <StyledRightSection>
            <StyledUserInfo
              showChat={false}
              userType="Sent to"
              userName={getUserFullName(accountData)}
              chatUrl=""
              image={accountData.picture || ""}
            />

            {isAuthenticated && (
              <Button
                className="w-100 py-3 mt-4"
                disabled={!passedMaxLimit}
                onClick={contact}
              >
                Continue
              </Button>
            )}

            {!isAuthenticated && (
              <StyledFormSection
                onClick={() => {
                  saveMessageInCache();
                }}
              >
                <Heading size="lg">Your Details *</Heading>
                <Body size="lg" className="mt-2" style={{ color: "#A4ACB9" }}>
                  You'll need a verified account to send a message
                </Body>
                <AuthForm className="mt-3" />
              </StyledFormSection>
            )}
          </StyledRightSection>
        </div>
      </StyledContainer>
    </StyledLayout>
  );
};

export default ContactUserPage;
