"use client";

import styled from "@emotion/styled";

import { getViewPortHeightCssString } from "@/styles";
import NavMenu from "@/components/navigation/NavMenu";
import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";

import UserDisplay from "./components/UserDisplay";

export const StyledMainContainer = styled.div`
  background: var(--clr-neutral-solid-25);
`;

export const StyledContainer = styled.div<{ $sidebarCompact: boolean }>`
  --sidebar-width: ${({ $sidebarCompact }) =>
    $sidebarCompact ? "5rem" : "18rem"};
  display: flex;
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  overflow: hidden;
  max-width: 1980px;
  margin: 0 auto;
  background: var(--clr-background-primary, #fff);
`;

export const StyledAside = styled.aside<{ $sidebarCompact: boolean }>`
  width: var(--sidebar-width);
  border-right: 1px solid var(--clr-border-50, #eceff3);
  padding-inline: ${({ $sidebarCompact }) =>
    $sidebarCompact ? "0.25rem" : "1.25rem"};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & > *:not(:first-child) {
    border-top: 1px solid var(--clr-border-50, #eceff3);
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1rem;
`;

export const NavSection = styled.div`
  flex: 1;
`;

export const StyledContentSection = styled.div<{ $sidebarCompact?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(100% - var(--sidebar-width));

  ${({ $sidebarCompact }) =>
    $sidebarCompact
      ? ""
      : `
         padding-inline: 2rem;
        `}
`;

export const StyledMain = styled.main`
  flex: 1;
  overflow: auto;
`;

export const StyledNavMenu = styled(NavMenu)<{ $sidebarCompact?: boolean }>`
  ${({ $sidebarCompact }) =>
    $sidebarCompact
      ? ""
      : `
          margin-inline: -1.5rem;

          li {
            padding-inline: 1.5rem;
          }
        `}
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem;
`;

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledCount = styled.div`
  ${squareSizing("1rem")};
  border-radius: 10rem;
  background-color: var(--clr-error-400, #df1c41);
  position: absolute;
  top: 0.125rem;
  right: 0.25rem;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  font-weight: 400;
  color: #fff;
`;

export const StyledUserDisplay = styled(UserDisplay)<{
  $sidebarCompact: boolean;
}>`
  --sidebar-width: ${({ $sidebarCompact }) =>
    $sidebarCompact
      ? `
          padding: 0.25rem;
          display: flex;
          justify-content: center;
        `
      : ""};
`;
