import React from "react";

import Card from "@/components/data/Card";
import { UserInfoLoading } from "@/features/user/UserInfo";
import { DetailsTableLoading } from "@/components/data/DetailsTable";
import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledContainer,
  StyledMainDetailsContainer,
  StyledSectionHeading,
} from "./ContractPreview.styles";

const ContractPreview: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <Card>
        <StyledMainDetailsContainer>
          <div>
            <ShimmerLoader.Default
              style={{ height: "1.25rem", width: "6rem" }}
            />
            <ShimmerLoader.Default
              className="mt-3"
              style={{ height: "3rem", width: "14rem" }}
            />
          </div>

          <ShimmerLoader.Default style={{ height: "1.5rem", width: "3rem" }} />
        </StyledMainDetailsContainer>

        <ShimmerLoader.Default
          className="mt-4"
          style={{ height: "1.25rem", width: "6rem" }}
        />
        <ShimmerLoader.Default
          className="mt-2"
          style={{ height: "1.25rem", width: "100%", maxWidth: "45rem" }}
        />
        <ShimmerLoader.Default
          className="mt-2"
          style={{ height: "1.25rem", width: "100%", maxWidth: "45rem" }}
        />
      </Card>

      <Card className="mt-4">
        <UserInfoLoading />
      </Card>

      <Card className="mt-4">
        <StyledSectionHeading>Details</StyledSectionHeading>
        <DetailsTableLoading />
      </Card>
    </StyledContainer>
  );
};

export default ContractPreview;
