import React, { useMemo } from "react";

import {
  ChannelType,
  isUserChannel as checkIsUserChannel,
} from "@/services/ChatServiceNew";

import { IChatCardProps } from "./ChatCard.types";
import UserChatCard from "./ChatCard.user.stream";
import ContractChatCard from "./ChatCard.contract.stream";
import RequestChatCard from "./ChatCard.request.stream";

const ChatCard: React.FC<IChatCardProps> = (props) => {
  const { channel_id: channelId = "", channelType } = props;

  const isUserChannel = useMemo(
    () => checkIsUserChannel({ channelId }),
    [channelId]
  );

  if (channelType === ChannelType.ChatRequest) {
    return <RequestChatCard {...props} />;
  }

  if (isUserChannel) {
    return <UserChatCard {...props} />;
  }

  return <ContractChatCard {...props} />;
};

export default ChatCard;
