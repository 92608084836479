"use client";

import styled from "@emotion/styled";

import { getViewPortHeightCssString, screenLargerThan } from "@/styles";

export const StyledSucessPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  padding: 1rem;
  padding-block: 2rem;

  .content {
    flex-grow: 5;

    & > * {
      max-width: 30ch;
    }
  }

  .cta-section {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;

    ${screenLargerThan.tablet} {
      flex-grow: 2;
    }
  }

  ${screenLargerThan.tablet} {
    padding-block: 2rem;
  }
`;
