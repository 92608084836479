import React from "react";

import { StyledContainer, StyledItemContainer } from "./Timeline.styles";
import { ITimelineProps, TimelineType } from "./Timeline.types";

const Timeline: React.FC<ITimelineProps> = ({
  items,
  className,
  currentIndex = -1,
  itemMargin,
}) => {
  const timelineType: TimelineType =
    currentIndex === -1 ? "static" : "progress";

  return (
    <StyledContainer $itemMargin={itemMargin} className={className}>
      {items.map(
        (
          {
            children,
            showCounter = true,
            containerClassName,
            showCount = true,
          },
          i
        ) => (
          <StyledItemContainer
            className={containerClassName}
            $timelineType={timelineType}
            key={i}
            $showCounter={showCounter}
            $completed={i < currentIndex}
            $muted={i > currentIndex}
            $showCount={showCount}
          >
            {children}
          </StyledItemContainer>
        )
      )}
    </StyledContainer>
  );
};

export default Timeline;
