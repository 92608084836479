import useSWR from "swr";

import { contractService } from "@/config/services";

type Params = {
  sessionId: string;
};

function useStripeSessionObject(params: Params) {
  const { sessionId } = params;

  const { data, error, isLoading } = useSWR(
    !!sessionId ? `/stripe_session/${sessionId}` : null,
    () => contractService.getStripeSessionFromSessionId(sessionId),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  const isUpdating = !!data && isLoading;
  const notFound = !data && !isLoading;

  //-----------------------

  return {
    stripeSessionObject: data || null,
    error,
    isLoading,
    isUpdating,
    notFound,
  };
}

export default useStripeSessionObject;
