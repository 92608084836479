import React, { useCallback, Context, useMemo, useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import isFunction from "lodash/isFunction";
import lodashGet from "lodash/get";

import {
  IFormProps,
  IFormContext,
  ExtraFields,
  FormValues,
} from "./Form.types";

const INITIAL_VALUES = {} as IFormContext<any>;

const FormContext = React.createContext(INITIAL_VALUES);

export function useForm<T, U = ExtraFields>() {
  return React.useContext(FormContext as Context<IFormContext<T, U>>);
}

export function FormProvider<T extends FormValues, U = ExtraFields>({
  initialValues = {} as T,
  yupValidationSchema,
  children,
  onSubmit,
  extraFields,
  onChange,
}: IFormProps<T, U>) {
  const handleSubmitForm = useCallback(
    (values: any) => {
      if (onSubmit) onSubmit(values);
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: yupValidationSchema,
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit: formikSubmitForm,
    setFieldTouched,
  } = formik;

  const touchedAndHasError = useCallback(
    (key: string) => {
      return (
        !!lodashGet(errors, key, false) && !!lodashGet(touched, key, false)
      );
    },
    [errors, touched]
  );

  const hasError = !!Object.keys(errors).length;
  const value = useMemo(
    () =>
      ({
        values,
        errors,
        touched,
        setFieldValue,
        submitForm: formikSubmitForm,
        setFieldTouched,
        touchedAndHasError,
        hasError,
        ...extraFields,
      } as IFormContext<T, U>),
    [
      values,
      errors,
      touched,
      setFieldValue,
      formikSubmitForm,
      setFieldTouched,
      touchedAndHasError,
      extraFields,
      hasError,
    ]
  );

  //----------------------------

  useEffect(() => {
    if (onChange) {
      onChange(values, { hasError });
    }
  }, [onChange, values, hasError]);

  //----------------------------

  return (
    <FormContext.Provider value={value}>
      <FormikProvider value={formik}>
        {isFunction(children) ? children({ context: value }) : children}
      </FormikProvider>
    </FormContext.Provider>
  );
}
