"use client";

import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import { Label } from "@/components/Typography";

import { Variant } from "./FilesInput.types";

export const StyledLabel = styled.label<{
  $variant: Variant;
  $hasError: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--clr-background-primary, #fff);

  ${({ $variant, $hasError }) =>
    $variant === "background"
      ? `
            box-shadow: 0 4px 7px rgba(177, 177, 177, 0.08), 0 1px 2px rgba(0, 0, 0, 0.02); 
            border: 1px solid var(--clr-border-ukwn-2, #efefef);
            padding: 0.5rem 1rem;
            border-radius: 1rem;
            min-height: 56px;
        `
      : $variant === "outline"
      ? ` 
        `
      : ""}
`;

export const StyledContentSection = styled.div<{
  $variant: Variant;
  $hasError: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  ${({ $variant, $hasError }) =>
    $variant === "outline"
      ? ` 
          min-height: 56px;
          border-bottom: 1.5px solid ${
            $hasError
              ? "var(--clr-border-ukwn-15, #ED8296)"
              : "var(--clr-border-ukwn-5, #dfe1e7)"
          } !important;
        `
      : ""}
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledIcon = styled(Icon)`
  filter: brightness(0) invert(45%) sepia(7%) saturate(1013%) hue-rotate(186deg)
    brightness(91%) contrast(91%);
`;

export const StyledText = styled(Label)`
  flex: 1;
  color: var(--clr-text-900, #0d0d12);

  small {
    opacity: 0.5;
  }
`;

export const StyledCount = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  border-radius: 10rem;
  background: var(--clr-primary-100, #5f57ff);
  color: var(--clr-text-white, #fff);
  border: none;
  height: 36px;
  min-width: 36px;
  padding-inline: 1rem;
`;

export const StyledModelInner = styled.div`
  padding: 1rem;
`;

export const StyledModelText = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--clr-text-ukwn-6, #1d1e25);
`;

export const StyledCardsVariant = styled.div<{ $hasError: boolean }>`
  .cards-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-style: dashed;
    width: 100%;
    background: ${({ $hasError }) =>
      $hasError ? "var(--clr-background-ukwn-11, #FFF0F3)" : "#f9fafb"};
    border: 1px dashed
      ${({ $hasError }) =>
        $hasError ? "var(--clr-error-500, #ea5b5b)" : "rgba(0, 0, 0, 0.17)"};
    color: #737373;
    padding: 1rem;
    height: 7rem;
    border-radius: 0.875rem;
    cursor: pointer;
  }
`;
