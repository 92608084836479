import useSWR from "swr";

import TIMEZONE_LIST from "../temp/timezones.json";

function useTimezoneList() {
  const { data, error, isLoading } = useSWR("/timezones", () =>
    Promise.resolve(TIMEZONE_LIST)
  );

  const isUpdating = data !== undefined && isLoading;

  //-----------------------

  return {
    data: data || [],
    error,
    isLoading,
    isUpdating,
  };
}

export default useTimezoneList;
