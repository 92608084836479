import React, { useMemo } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"; // ES 2015

import Button from "@/components/input/Button";
import useCountdown from "@/hooks/useCountdown";

dayjs.extend(duration);

const TIME = dayjs().add(2, "hours").toDate();

const Timer: React.FC = () => {
  const { start, pause, stop, remainingTimeInMs } = useCountdown({
    targetTime: TIME,
    countUp: true,
    updateByInMs:100
  });

  const displayTime = useMemo(() => {
    const duration = dayjs
      .duration(Math.abs(-remainingTimeInMs), "millisecond")
      .format("D[_days]:H[_hours]:m[_minutes]:s[_seconds]");

    const durationParts = duration.split(":").reduce((map, item) => {
      const [value, key] = item.split("_");
      return { ...map, [key]: +(value || "0") };
    }, {}) as {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    };

    const { days, hours, minutes, seconds } = durationParts;

    const formattedParts: string[] = [];

    if (days) {
      formattedParts.push(`${days} ${days > 1 ? "days" : "day"}`);
    }
    if (days || hours) {
      formattedParts.push(`${hours} ${hours > 1 ? "hours" : "hour"}`);
    }
    if (days || hours || minutes) {
      formattedParts.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
    }
    if (days || hours || minutes || seconds) {
      formattedParts.push(`${seconds} ${seconds > 1 ? "seconds" : "second"}`);
    }

    const formatted = formattedParts.join(", ");
  
    return formatted;
  }, [remainingTimeInMs]);

  return (
    <div className="pt-5 container">
      <Button variant="secondary" onClick={start}>
        Start
      </Button>
      <Button variant="secondary" onClick={pause}>
        Pause
      </Button>
      <Button variant="secondary" onClick={stop}>
        Stop
      </Button>

      <p>{remainingTimeInMs}</p>
      <p>{displayTime}</p>
    </div>
  );
};

export default Timer;
