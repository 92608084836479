import React, { useCallback, useState } from "react";

import Button from "@/components/input/Button";
import { api, pushNotifications } from "@/config/services";

const PushNotification: React.FC = () => {
  const deviceList = pushNotifications._devices;

  const [isSending, setIsSending] = useState(false);

  const handleSend = useCallback(() => {
    if (isSending) {
      return;
    }

    setIsSending(true);

    api.post("/user/test-push-notification").finally(() => {
      setIsSending(false);
    });
  }, [isSending]);

  return (
    <div className="container">
      <Button onClick={handleSend}>
        {isSending
          ? "Sending test push notification..."
          : "Send test push notification"}
      </Button>

      <h2 className="mt-4">Devices</h2>
      <ul className="mt-3">
        {deviceList.map(({ token, date_added, id }, i) => (
          <li key={i} className="mt-4">
            <p>
              <b>#{i + 1}</b> &nbsp;
              {id}
            </p>
            <ul className="mt-2">
              <li className="mt-1">
                <small>
                  <b>Date added</b>: {date_added || "¯\\_(ツ)_/¯"}
                </small>
              </li>
              <li className="mt-1">
                <small>
                  <b>FCM token</b>: {token}
                </small>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PushNotification;
