import { useCallback, useState } from "react";

import { userService } from "@/config/services";
import { useNavigate } from "@/services/Routing";
import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  getChatRoomPageUrl,
} from "@/services/ChatServiceNew";

function useAcceptUserChatRequest({ userId }: { userId: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const { navigate } = useNavigate();

  const accept = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    return userService
      .acceptContactRequest({ userId })
      .then((res) => {
        navigate(
          `${getChatRoomPageUrl(
            res.data.data.channelId
          )}&${CHANNEL_TYPE_FILTER_URL_KEY}=${ChannelType.PersonalChat}`,
          true
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading, userId, navigate]);

  return { isLoading, accept };
}

export default useAcceptUserChatRequest;
