import React from "react";

import Icon from "@/components/misc/Icon";
import { IButtonProps } from "@/components/input/Button";

import {
  StyledButton,
  StyledIconContainer,
  StyledLi,
  StyledLink,
  StyledNav,
  StyledTitle,
  StyledUl,
} from "./TabBar.styles";
import { ITabBarProps } from "./TabBar.types";

const TabBar: React.FC<ITabBarProps> = ({ items, className }) => {
  return (
    <StyledNav id="tab-bar" className={className}>
      <StyledUl>
        {items.map((item) => {
          const {
            id,
            icon,
            title,
            link,
            primary = false,
            active = false,
            onClick,
            iconProps = {},
            render,
          } = item;
          const Component = link ? StyledLink : StyledButton;
          const props: any = link
            ? { to: link }
            : ({
                onClick: () => {
                  if (onClick) onClick(item);
                },
                variant: "ghost",
              } satisfies IButtonProps);

          const renderItemComponent = ({
            children = null,
          }: {
            children?: React.ReactNode;
          }) => (
            <Component $primary={primary} $active={active} {...props}>
              {children}
            </Component>
          );

          const iconJsx = (
            <StyledIconContainer $primary={primary}>
              <Icon
                src={icon}
                alt={title}
                size={primary ? "sm" : "md"}
                customSize={primary ? "" : "1.5rem"}
                className="state-color"
                {...iconProps}
              />
            </StyledIconContainer>
          );

          const titleJsx = !primary && (
            <StyledTitle $active={active} size="sm" className="state-color">
              {title}
            </StyledTitle>
          );

          return (
            <StyledLi $primary={primary} key={id}>
              {render
                ? render({
                    item,
                    itemComponent: Component,
                    iconJsx,
                    titleJsx,
                    renderItemComponent,
                  })
                : renderItemComponent({
                    children: (
                      <>
                        {iconJsx}
                        {titleJsx}
                      </>
                    ),
                  })}
            </StyledLi>
          );
        })}
      </StyledUl>
    </StyledNav>
  );
};

export default TabBar;
