import sortBy from "lodash/sortBy";

import { Message, UserID } from "./ChatRoom.types";

export function groupMessageByDates(messages: Message[]) {
  const groups: {
    messages: Message[];
    date: Date;
  }[] = [];
  messages = sortBy(messages, "date");

  let lastUserId: UserID;
  let lastGroupMessages: Message[] = [];

  const addMessageGroup = (messages: Message[], date: Date) => {
    groups.push({
      messages,
      date: date,
    });
  };

  messages.forEach((message) => {
    if (lastUserId) {
      if (lastUserId === message.userId) {
        lastGroupMessages.push(message);
      } else {
        if (lastGroupMessages.length) {
          addMessageGroup(lastGroupMessages, message.date);
          lastGroupMessages = [message];
        }
      }
    } else {
      lastGroupMessages = [message];
    }

    lastUserId = message.userId;
  });

  if (lastGroupMessages.length) {
    addMessageGroup(lastGroupMessages, lastGroupMessages[0].date);
  }

  return groups;
}
