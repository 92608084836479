import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Providers from "./Providers";
import AppRoutes from "./AppRoutes";

import "@/styles/css/bootstrap/bootstrap-grid.css";
import "@/styles/css/reset.css";
import "@/styles/css/core.css";
import "@/styles/css/typography.css";
import "@/styles/css/color.css";
import "@/styles/css/animations.css";
import "@/styles/css/scroll.css";
import "@/styles/css/sizing.css";
import "@/styles/css/utils.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Router>
      <Suspense>
        <Providers>
          <AppRoutes />
        </Providers>
      </Suspense>
    </Router>
  );
}

export default App;
