"use client";

import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import TagListInput from "@/components/input/TagListInputNew";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { ANIMATION_CLASSNAMES, screenLargerThan } from "@/styles";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import { areStringsSubstring } from "@/utils/string";

import {
  PROFESSION_FIELD_URL_HASH,
  useProfileForm,
} from "../../ProfileForm.config";
import PROFESSIONS_LIST_ from "../../content/professions.json";

// @ts-ignore
const PROFESSIONS_LIST: string[] = [...new Set(PROFESSIONS_LIST_)];

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  height: 100%;
  gap: 1.5rem;

  & > *:last-child {
    margin-top: auto;
  }
`;

const StyledPageWithHeaderLayout = styled(PageWithHeaderLayout)`
  ${screenLargerThan.tablet} {
    height: 40rem;
    max-height: calc(100vh - 4.5rem);
    overflow: hidden;

    main {
      overflow: hidden;
    }
  }
`;

const StyledTagContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const StyledTagButton = styled(Button)`
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const ProfessionInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, submitForm, setFieldValue } = useProfileForm();
  const [profession, setProfession] = useState<string[]>(values.profession);
  const [search, setSearch] = useState("");
  const modalState = useModalState({
    urlHash: PROFESSION_FIELD_URL_HASH,
    onOpenChange: (isOpen) => {
      if (isOpen) {
        setProfession(values.profession);
      }
    },
  });

  const handleSave = useCallback(() => {
    setSearch("");
    setFieldValue("profession", profession);

    setTimeout(() => {
      submitForm();
    }, 100);

    modalState.close();
  }, [modalState, submitForm, profession, setFieldValue]);

  const options = useMemo(() => {
    return PROFESSIONS_LIST.filter((name) => !profession.includes(name))
      .filter((name) => areStringsSubstring(name, search, false))
      .sort(
        (a, b) =>
          +Boolean(b.toLowerCase().startsWith(search.toLowerCase())) -
          +Boolean(a.toLowerCase().startsWith(search.toLowerCase()))
      )
      .map((name) => ({
        children: <>{name}</>,
        value: name,
      }));
  }, [search, profession]);

  const inputContentJsx = (
    <>
      <div>
        <TagListInput
          focusOnMount
          variant="outline"
          placeholder="Search"
          inputValue={search}
          onInputChange={setSearch}
          value={profession}
          onChange={setProfession}
        />
      </div>

      <StyledTagContainer>
        {options.map(({ value }, i) => (
          <StyledTagButton
            className={ANIMATION_CLASSNAMES.FADE_IN}
            variant="secondary"
            colorVariant="gray"
            key={value}
            onClick={() => {
              setSearch("");
              setProfession((profession) => {
                profession = [...profession];
                profession.push(value);
                return profession;
              });
            }}
          >
            {value}
          </StyledTagButton>
        ))}
      </StyledTagContainer>

      <Button className="w-100" onClick={handleSave}>
        Apply
      </Button>
    </>
  );

  return (
    <>
      <div className={className}>
        <Label
          size="lg"
          as="label"
          htmlFor="timezone"
          onClick={modalState.open}
        >
          Profession
        </Label>

        <div onClick={modalState.open}>
          <TagListInput
            disabled
            className="mt-3"
            placeholder="Enter your profession"
            value={values.profession}
            style={{ pointerEvents: "none" }}
          />
        </div>
      </div>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
        desktopProps={{ width: "768px" }}
      >
        <StyledPageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Profession" },
          }}
        >
          <StyledModalContent>{inputContentJsx}</StyledModalContent>
        </StyledPageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default ProfessionInput;
