"use client";

import styled from "@emotion/styled";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 2rem 1rem;
  height: 100%;
  overflow: auto;
`;
