import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Input from "@/components/input/Input";

import { ITagListInputContext, TagListInputProps } from "./TagListInput.types";
import { StyledContainer, StyledInput } from "./TagListInput.styles";
import Tag from "./components/Tag";

const TagListInputContext = createContext({} as ITagListInputContext);

export const useTagListInput = () => useContext(TagListInputContext);

export const TagListInputProvider: React.FC<TagListInputProps> = ({
  value: tagsFromProps,
  onChange: onChangeFromProps,
  placeholder = "",
  allowDuplicates = false,
  inputValue: inputValueFromProps,
  onInputChange,
  style,
  disabled = false,
  className,
  focusOnMount,
  useFloatingLabel,
  allowedOptions: allowedOptionsFromProps = [],
  caseSensitive = false,
  variant,
}) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [tagsFromState, setTags] = useState<string[]>(tagsFromProps || []);
  const [inputValueFromState, setInputValue] = useState("");
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const isControlled = tagsFromProps !== undefined;
  const tags = isControlled ? tagsFromProps : tagsFromState;

  const isInputValueControlled = inputValueFromProps !== undefined;
  const inputValue = isInputValueControlled
    ? inputValueFromProps
    : inputValueFromState;

  const allowedOptions = useMemo(
    () =>
      caseSensitive
        ? allowedOptionsFromProps
        : allowedOptionsFromProps.map((option) => option.toLocaleLowerCase()),
    [allowedOptionsFromProps, caseSensitive]
  );

  //-----------------------------

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);

      if (onInputChange) {
        onInputChange(value);
      }
    },
    [onInputChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      let trimmedInput = inputValue.trim();

      if ([",", "Enter"].includes(key) && trimmedInput.length) {
        let satisfiesDuplicates = true;
        if (!allowDuplicates) {
          satisfiesDuplicates = !tags.includes(trimmedInput);
        }

        let satisfiesAllowedOptions = true;
        if (allowedOptions.length) {
          const trimmedInput_ = caseSensitive
            ? trimmedInput
            : trimmedInput.toLocaleLowerCase();
          const optionIndexInList = allowedOptions.findIndex(
            (option) => option === trimmedInput_
          );

          satisfiesAllowedOptions = optionIndexInList !== -1;
          if (satisfiesAllowedOptions) {
            trimmedInput = allowedOptionsFromProps[optionIndexInList].trim();
          }
        }

        if (satisfiesAllowedOptions && satisfiesDuplicates) {
          e.preventDefault();
          const updated = [...tags, trimmedInput];
          if (onChangeFromProps) {
            onChangeFromProps(updated);
          }
          setTags(updated);

          setInputValue("");
          if (onInputChange) {
            onInputChange("");
          }
        }
      }

      if (
        key === "Backspace" &&
        !inputValue.length &&
        tags.length &&
        isKeyReleased
      ) {
        e.preventDefault();
        const updated = [...tags];
        const poppedTag = updated.pop() || "";

        setTags(updated);
        setInputValue(poppedTag);

        if (onChangeFromProps) {
          onChangeFromProps(updated);
        }
      }

      setIsKeyReleased(false);
    },
    [
      inputValue,
      tags,
      isKeyReleased,
      allowDuplicates,
      onInputChange,
      onChangeFromProps,
      allowedOptions,
      caseSensitive,
      allowedOptionsFromProps,
    ]
  );

  const focusInputElement = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const deleteTag = useCallback(
    (index: number) => {
      if (disabled) {
        return;
      }

      const updated = tags.filter((_, i) => i !== index);
      setTags(updated);
      if (onChangeFromProps) {
        onChangeFromProps(updated);
      }

      focusInputElement();
    },
    [onChangeFromProps, focusInputElement, disabled, tags]
  );

  const updateTag = useCallback(
    (index: number, value: string) => {
      if (disabled) {
        return;
      }

      const updated = [...tags];
      updated[index] = value;
      setTags(updated);
      if (onChangeFromProps) {
        onChangeFromProps(updated);
      }
    },
    [onChangeFromProps, disabled, tags]
  );

  const handleInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      e.target.scrollIntoView({ inline: "start", block: "center" });
    },
    []
  );

  const handleKeyUp = useCallback(() => {
    setIsKeyReleased(true);
  }, []);

  const handleContainerRefUpdate = useCallback(
    (ref: HTMLElement | null) => {
      if (ref) {
        ref.tabIndex = 0;
        ref.onfocus = focusInputElement;
      }
    },
    [focusInputElement]
  );

  //-----------------------------

  useEffect(() => {
    if (focusOnMount) {
      focusInputElement();
    }
  }, [focusOnMount, focusInputElement]);

  //-----------------------------

  const tagsListJsx = tags.map((_, i) => <Tag key={i} index={i} />);

  const hasValue = !!(inputValue || tags.join(""));

  const inputJsx = (
    <StyledInput
      disabled={disabled}
      ref={inputRef}
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onFocus={handleInputFocus}
      placeholder={hasValue || useFloatingLabel ? "" : placeholder}
    />
  );

  return (
    <TagListInputContext.Provider
      value={{ tags, inputValue, updateTag, deleteTag }}
    >
      <Input
        useFloatingLabel={useFloatingLabel}
        style={style}
        hasValue={hasValue}
        label={placeholder}
        className={className}
        variant={variant}
        inputElement={
          <StyledContainer
            className="scroll-container"
            onClick={focusInputElement}
            activationDistance={50}
            innerRef={handleContainerRefUpdate}
          >
            {tagsListJsx}
            {!disabled && inputJsx}
          </StyledContainer>
        }
      />
    </TagListInputContext.Provider>
  );
};

export default TagListInputProvider;
