import React from "react";

import { useParamState } from "@/services/Routing";
import Button from "@/components/input/Button";

const Routing: React.FC = () => {
  const [boolValue, setBoolValue] = useParamState<boolean>("bool", false);
  const [boolStringValue, setBoolStringValue] = useParamState<string>(
    "bool-str",
    "",
    { parseJson: false }
  );
  const [intValue, setIntValue] = useParamState<number>("age", 10);

  return (
    <div className="container">
      <br />
      <br />

      <Button
        variant={boolValue === true ? "primary" : "secondary"}
        onClick={() => {
          setBoolValue(true);
        }}
      >
        True
      </Button>
      <Button
        variant={boolValue === false ? "primary" : "secondary"}
        onClick={() => {
          setBoolValue(false);
        }}
      >
        False
      </Button>
      <Button
        variant={boolValue === undefined ? "primary" : "secondary"}
        onClick={() => {
          setBoolValue();
        }}
      >
        Remove
      </Button>

      <br />
      <br />
      <br />

      <hr />

      <br />
      <br />

      <Button
        variant={boolStringValue === "true" ? "primary" : "secondary"}
        onClick={() => {
          setBoolStringValue("true");
        }}
      >
        True
      </Button>
      <Button
        variant={boolStringValue === "false" ? "primary" : "secondary"}
        onClick={() => {
          setBoolStringValue("false");
        }}
      >
        False
      </Button>
      <Button
        variant={boolStringValue === undefined ? "primary" : "secondary"}
        onClick={() => {
          setBoolStringValue();
        }}
      >
        Remove
      </Button>

      <br />
      <br />
      <br />

      <hr />

      <br />
      <br />

      <Button
        variant={intValue === 1 ? "primary" : "secondary"}
        onClick={() => {
          setIntValue(1);
        }}
      >
        1
      </Button>
      <Button
        variant={intValue === 2 ? "primary" : "secondary"}
        onClick={() => {
          setIntValue(2);
        }}
      >
        2
      </Button>
      <Button
        variant={intValue === undefined ? "primary" : "secondary"}
        onClick={() => {
          setIntValue();
        }}
      >
        Remove
      </Button>
    </div>
  );
};

export default Routing;
