import React from "react";
import styled from "@emotion/styled";

import useToggle from "@/hooks/useToggle";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import { useContractList } from "@/services/ContractsService";
import { getChatRoomPageUrl } from "@/services/ChatService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import { screenLargerThan, screenSmallerThan } from "@/styles";

import { useChatRoomPage } from "../ChatRoomPage.context";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const StyledDrawerContent = styled.div<{ $extendHeight: boolean }>`
  ${screenSmallerThan.tablet} {
    padding: 0 1rem 1rem 1rem;
  }

  ${screenLargerThan.tablet} {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: ${({ $extendHeight }) => ($extendHeight ? "18rem" : "10rem")};
    transition: max-height 420ms ease;
  }
`;

const StyledJobsContainer = styled.div`
  flex: 1;
  max-height: 55vh;
  overflow: auto;
`;

const StyledHeading = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--clr-text-ukwn-2, #1a1d1f);
`;

const StyledContent = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--clr-ukwn-3, #a4acb9);
  text-align: center;
`;

const NewChat: React.FC = () => {
  const { showNewChatFlowState } = useChatRoomPage();

  const {
    state: shouldShowJobs,
    enable: showJobs,
    disable: hideJobs,
  } = useToggle();

  const drawerState = useDrawerState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        hideJobs();
      }
    },
  });

  const { data: jobsList, isLoading: isLoadingJobs } = useContractList({
    onlyJobs: true,
  });

  const menuJsx = (
    <>
      <StyledHeading>What do you want to talk about?</StyledHeading>

      <Button
        className="w-100 mt-4"
        variant="secondary"
        colorVariant="gray"
        onClick={showNewChatFlowState.disable}
      >
        Chat about a new job
      </Button>

      <Button
        className="w-100 mt-2"
        variant="secondary"
        colorVariant="gray"
        onClick={showJobs}
      >
        Chat about a completed job
      </Button>
    </>
  );

  const jobLoadingListJsx = (
    <>
      <ShimmerLoader.Default
        style={{ width: "100%", height: "1.5rem", borderRadius: "3px" }}
      />
      <StyledJobsContainer className="mt-4">
        {[...new Array(3)].map((_, i) => (
          <ShimmerLoader.Default
            key={i}
            className="w-100 mb-2 fade-in"
            style={{ width: "100%", height: "3.125rem", borderRadius: "10rem" }}
          />
        ))}
      </StyledJobsContainer>
    </>
  );

  const jobListJsx = (
    <>
      <StyledHeading className="fade-in">
        Which job do you want to discuss?
      </StyledHeading>

      <StyledJobsContainer
        className="mt-4 fade-in"
        onClick={showNewChatFlowState.disable}
      >
        {jobsList.map(({ id, title }) => (
          <Button
            className="w-100 mb-2"
            variant="secondary"
            colorVariant="gray"
            link={getChatRoomPageUrl(id)}
          >
            {title}
          </Button>
        ))}
      </StyledJobsContainer>
    </>
  );

  return (
    <>
      <StyledContainer>
        <StyledContent>
          Conversations are tied to specific jobs. Talk about a different job by
          starting a new conversation.
        </StyledContent>

        <Button className="w-100" onClick={drawerState.open}>
          <Icon isSrcRelative src="chat_2.svg" colorVariant="white" size="sm" />
          Start a new conversation
        </Button>
      </StyledContainer>

      <Drawer canDrag={false} state={drawerState}>
        <StyledDrawerContent $extendHeight={shouldShowJobs}>
          {shouldShowJobs
            ? isLoadingJobs
              ? jobLoadingListJsx
              : jobListJsx
            : menuJsx}
        </StyledDrawerContent>
      </Drawer>
    </>
  );
};

export default NewChat;
