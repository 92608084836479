import React from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { usePublishProfile } from "@/services/UserService";
import RingLoader from "@/components/misc/RingLoader";
import PulseLoader from "@/components/misc/PulseLoader";
import PopoverButton, {
  usePopoverState,
} from "@/components/misc/PopoverButton";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Label } from "@/components/Typography";

const BUTTON_WIDTH = "7.5rem";

const StyledPopoverButton = styled(PopoverButton)<{ $isOpen: boolean }>`
  width: ${BUTTON_WIDTH};
  transition: none;
  border-radius: 0.75rem !important;

  ${({ $isOpen }) =>
    $isOpen
      ? `
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        `
      : ""}

  span {
    flex-grow: 1;
    text-align: left;
  }
`;

const StyledButton = styled(Button)`
  width: ${BUTTON_WIDTH};
  border-radius: 0.75rem !important;

  span {
    flex-grow: 1;
    text-align: left;
  }
`;

const StyledPopoverMenu = styled.div`
  width: ${BUTTON_WIDTH};

  & > button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
`;

const PublishStateButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    published = false,
    isLoading,
    togglePublish,
    isPublishing,
    isUnpublishing,
  } = usePublishProfile();
  const menuState = usePopoverState();
  const modalState = useModalState();

  //----------------------

  const ringLoaderJsx = <RingLoader size="1.25rem" />;

  const publishStateJsx = (
    <>
      <PulseLoader
        className="loader"
        color="var( --clr-success-500, #1dbf73)"
      />
      <span>Visible</span>
    </>
  );
  const unpublishStateJsx = (
    <>
      <PulseLoader
        className="loader"
        active={false}
        color="var(--clr-error-500, #ea5b5b)"
      />
      <span>Hidden</span>
    </>
  );

  const loadingJsx = (
    <StyledButton className={className}>
      {ringLoaderJsx}
      <span>Loading</span>
    </StyledButton>
  );

  if (isLoading) {
    return loadingJsx;
  }

  const updatingJsx = (
    <StyledButton disabled className={className} colorVariant="white-primary">
      {ringLoaderJsx}
      {isPublishing ? (
        <span>Publishing</span>
      ) : isUnpublishing ? (
        <span>Unpublishing</span>
      ) : (
        <span>Publishing</span>
      )}
    </StyledButton>
  );

  if (isPublishing || isUnpublishing) {
    return updatingJsx;
  }

  return (
    <>
      <StyledPopoverButton
        $isOpen={menuState.isOpen}
        state={menuState}
        buttonProps={{
          className,
          variant: "primary",
          colorVariant: "white-primary",
          children: (
            <>
              {published ? publishStateJsx : unpublishStateJsx}
              <Icon isSrcRelative src="chevron_down.svg" customSize="0.75rem" />
            </>
          ),
        }}
        popoverProps={{
          placement: "bottom left",
          styles: {
            background: "transparent",
            boxShadow: "none",
          },
          children: (
            <StyledPopoverMenu>
              <StyledButton
                colorVariant="white-primary"
                onClick={() => {
                  modalState.open();
                  menuState.close();
                }}
              >
                {published ? unpublishStateJsx : publishStateJsx}
              </StyledButton>
            </StyledPopoverMenu>
          ),
        }}
      />

      <Modal state={modalState} width="auto">
        <Label size="lg">
          Are you sure you want to make Paypipe ID{" "}
          {published ? "hidden" : "visible"}?
        </Label>

        <StyledButtonContainer className="mt-3">
          <StyledButton
            variant="ghost"
            colorVariant="gray"
            onClick={modalState.close}
          >
            Cancel
          </StyledButton>

          <StyledButton
            onClick={() => {
              modalState.close();
              togglePublish();
            }}
          >
            Continue
          </StyledButton>
        </StyledButtonContainer>
      </Modal>
    </>
  );
};

export default PublishStateButton;
