import { useMemo } from "react";
import dayjs from "dayjs";

import useToggle from "@/hooks/useToggle";

import useAvailSubscription from "./useAvailSubscription";
import useSubscriptionDetails from "./useSubscriptionDetails";
import { SubscriptionDuration } from "../UserService.types";

function usePromptForSubscription() {
  const { subscribe, isSubscribing } = useAvailSubscription({
    interval: SubscriptionDuration.Monthly,
  });
  const { loadingSubscriptionDetails, subscriptionDetails } =
    useSubscriptionDetails();
  const { disable: hide, state: shouldShow } = useToggle({
    initialValue: () => {
      const dateString = localStorage.getItem(
        "PAYPIPE:LAST_CLOSED_PREMIUM_PROMPT"
      );
      const date = dateString ? new Date(dateString) : null;

      if (date) {
        const diffInDays = Math.abs(dayjs().diff(dayjs(date), "day"));
        if (diffInDays >= 1) {
          return true;
        }

        return false;
      }

      return true;
    },
    onChange: (state) => {
      if (state === false) {
        localStorage.setItem(
          "PAYPIPE:LAST_CLOSED_PREMIUM_PROMPT",
          new Date().toUTCString()
        );
      }
    },
  });

  const { discountContent, hasCompletedJobCoupon, prelaunchSignupCoupon } =
    useMemo(() => {
      const availableDiscounts = subscriptionDetails?.availableDiscounts || [];
      const prelaunchSignupCoupon = availableDiscounts.includes(
        "PRELAUNCH_SIGNUP_COUPON"
      );
      const hasCompletedJobCoupon = availableDiscounts.includes(
        "HAS_COMPLETED_JOB_COUPON"
      );

      let discountContent = "";
      if (prelaunchSignupCoupon) {
        discountContent = "Upgrade now and get 6 months free";
      }

      if (hasCompletedJobCoupon) {
        discountContent = "Upgrade now and get 3 months free";
      }

      return {
        prelaunchSignupCoupon,
        hasCompletedJobCoupon,
        discountContent,
      };
    }, [subscriptionDetails?.availableDiscounts]);

  return {
    discountContent,
    hasCompletedJobCoupon,
    prelaunchSignupCoupon,
    shouldShow: shouldShow && !subscriptionDetails?.hasPremium && !loadingSubscriptionDetails,
    hidePrompt: hide,
    loading: loadingSubscriptionDetails,
    subscribe,
    isSubscribing,
  };
}

export default usePromptForSubscription;
