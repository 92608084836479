import React, { useCallback, useMemo, useState } from "react";

import TextField from "@/components/input/TextField";
import Icon from "@/components/misc/Icon";
import { areStringsSubstring } from "@/utils/string";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";

import {
  StyledContainer,
  StyledPageWithHeaderLayout,
  StyledRadioCardGroup,
} from "./TimezoneInput.styles";
import { useTimezoneList } from "@/services/UserService";

const TimezoneInput: React.FC<{
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
}> = ({ className, value: selectedTimezoneFromProps, onChange }) => {
  const modalState = useModalState();
  const [search, setSearch] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(
    selectedTimezoneFromProps
  );

  const { data: timezoneListFromHook } = useTimezoneList();

  const timezonesList = useMemo(() => {
    if (!search) {
      return timezoneListFromHook;
    }

    return timezoneListFromHook.filter(
      ({ value, abbr, text }) =>
        areStringsSubstring(value, search) ||
        areStringsSubstring(abbr, search) ||
        areStringsSubstring(text, search)
    );
  }, [search, timezoneListFromHook]);

  const options = useMemo(() => {
    return timezonesList.map(({ text, value }) => ({
      children: text,
      value: value,
    }));
  }, [timezonesList]);

  const selectedTimezoneDetails = useMemo(() => {
    return (
      timezoneListFromHook.find(({ value }) => value === selectedTimezone) ||
      null
    );
  }, [selectedTimezone, timezoneListFromHook]);

  //--------------------------

  const handleApply = useCallback(() => {
    if (onChange) {
      onChange(selectedTimezone || "");
    }

    modalState.close();
  }, [onChange, selectedTimezone, modalState]);

  //--------------------------

  return (
    <>
      <div onClick={modalState.open} className={className}>
        <TextField
          useFloatingLabel
          variant="background"
          placeholder=""
          name='Timezone'
          style={{ pointerEvents: modalState.isOpen ? "all" : "none" }}
          value={selectedTimezoneDetails?.text || ""}
        />
      </div>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
        desktopProps={{ width: "768px" }}
      >
        <StyledPageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Select country" },
            backButtonProps: {
              onClick: modalState.close,
            },
          }}
        >
          <StyledContainer>
            <TextField
              useFloatingLabel={false}
              variant="background"
              type="search"
              placeholder="Search"
              prependContent={
                <Icon
                  src="/assets/images/icons/search.svg"
                  alt="Search"
                  size="sm"
                />
              }
              value={search}
              onChange={setSearch}
            />

            <StyledRadioCardGroup
              label="contract-type"
              value={selectedTimezone}
              onChange={setSelectedTimezone}
              items={options}
              itemStyles={{
                minHeight: "3rem",
                paddingBlock: "0.5rem",
                borderRadius: "4px",
              }}
            />

            <Button disabled={!selectedTimezone} onClick={handleApply}>
              Done
            </Button>
          </StyledContainer>
        </StyledPageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default TimezoneInput;
