import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useComboBox, useFilter } from "react-aria";
import { Item, useComboBoxState } from "react-stately";

import Icon from "@/components/misc/Icon";
import { useResponsive } from "@/styles";

import Popover from "./components/Popover";
import Listbox from "./components/Listbox";
import Button from "./components/Button";
import { IComboBoxProps, Item as ItemType } from "./ComboBox.types";
import { StyledContainer } from "./ComboBox.styles";

// Reuse the ListBox, Popover, and Button from your component library. See below for details.

function ComboBox({
  options = [],
  isDisabled,
  placeholder,
  className,
  label = "",
  value: valueFromProps,
  onChange,
  hasError,
  scrollToTopOnFocus = true,
  scrollToTopDelayInMs = 500,
  onBlur,
  allowsCustomValue = false,
  filterBySearch = true,
  showOpenButton = true,
  // showOptionsOnChange = false,
  onInputChange: onInputChangeFromProps,
  inputValue,
}: IComboBoxProps) {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { startsWith } = useFilter({ sensitivity: "base" });

  const inputValueControlled = useRef(inputValue !== undefined);

  const value = useMemo(
    () => (valueFromProps ? valueFromProps : ""),
    [valueFromProps]
  );

  const [fieldState, setFieldState] = useState<{
    selectedKey: string | null;
    inputValue: string;
    items: ItemType<string>[];
  }>({
    selectedKey: value,
    inputValue: options.find((item) => item.value === value)?.label || value,
    items: options,
  });

  const children = useMemo(
    () =>
      fieldState.items.map(({ label, value }) => (
        <Item key={value}>{label}</Item>
      )),
    [fieldState.items]
  );

  const onSelectionChange = (key: any) => {
    setFieldState((prevState) => {
      let selectedItem = prevState.items.find((option) => option.value === key);

      if (onChange) {
        onChange(selectedItem?.value ?? "");
      }

      return {
        inputValue: selectedItem?.label ?? "",
        selectedKey: key,
        items: filterBySearch
          ? options.filter((item) =>
              startsWith(item.label, selectedItem?.label ?? "")
            )
          : options,
      };
    });

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }, 100);
  };

  // Specify how each of the ComboBox values should change when the input
  // field is altered by the user
  const onInputChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }

    if (onInputChangeFromProps) {
      onInputChangeFromProps(value);
    }

    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === "" ? null : prevState.selectedKey,
      items: filterBySearch
        ? options.filter((item) => startsWith(item.label, value))
        : options,
    }));
  };

  // Show entire list if user opens the menu manually
  const onOpenChange = (isOpen: boolean, menuTrigger: any) => {
    if (menuTrigger === "manual" && isOpen) {
      setFieldState((prevState) => ({
        ...prevState,
        items: options,
      }));
    }
  };

  const state = useComboBoxState({
    children,
    items: fieldState.items,
    selectedKey: fieldState.selectedKey,
    inputValue: fieldState.inputValue,
    onSelectionChange,
    onInputChange,
    onOpenChange,
    isDisabled,
    placeholder,
    allowsCustomValue,
    onBlur,
  });
  // Make menu width match input + button
  const [menuWidth, setMenuWidth] = useState<string | null>(null);
  const [menuLeft, setMenuLeft] = useState<string | null>(null);

  // Setup refs and get props for child elements.
  const containerRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const listBoxRef = useRef<any>(null);
  const popoverRef = useRef<any>(null);

  const canUseAutoScroll =
    scrollToTopOnFocus && !!scrollToTopDelayInMs && isScreenSmallerThanTablet;

  const { buttonProps, inputProps, listBoxProps } = useComboBox(
    {
      // selectedKey: value as any,
      allowsCustomValue,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
      isDisabled,
      "aria-label": label || placeholder,
      items: fieldState.items,
      selectedKey: fieldState.selectedKey,
      inputValue: fieldState.inputValue,
      onSelectionChange,
      onInputChange,
      onOpenChange,
      onFocus: () => {
        if (!canUseAutoScroll) {
          // state.open();
        }
      },
      // onInputChange: (value) => {
      //   if (value && canUseAutoScroll) {
      //     // state.open();
      //   }

      //   if (onChange) {
      //     onChange(`${value}`);
      //   }
      // },
      onBlur,
    },
    state
  );

  //-------------------------------

  useEffect(() => {
    const callback = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const width = rect.width;
        const left = rect.left;
        setMenuWidth(width);
        setMenuLeft(left);
      }
    };

    callback();
    window.addEventListener("resize", callback);

    return () => {
      window.addEventListener("resize", callback);
    };
  }, []);

  useEffect(() => {
    if (!inputValueControlled.current) {
      return;
    }

    setFieldState((prevState) => ({
      ...prevState,
      inputValue: inputValue || "",
    }));
  }, [inputValue]);

  //--------------------------------

  const scrollInToView = useCallback(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }, scrollToTopDelayInMs);
  }, [scrollToTopDelayInMs]);

  useEffect(() => {
    if (!isScreenSmallerThanTablet || !scrollToTopOnFocus) {
      return;
    }

    const containerEl = containerRef.current;
    if (containerEl) {
      containerEl.addEventListener("click", scrollInToView, true);
    }

    const inputEl = inputRef.current;
    if (inputEl) {
      inputEl.addEventListener("focus", scrollInToView, true);
    }

    return () => {
      if (containerEl) {
        containerEl.removeEventListener("click", scrollInToView, true);
      }

      if (inputEl) {
        inputEl.removeEventListener("focus", scrollInToView, true);
      }
    };
  }, [scrollInToView, isScreenSmallerThanTablet, scrollToTopOnFocus]);

  //--------------------------------

  return (
    <StyledContainer
      ref={containerRef}
      className={className}
      $hasError={hasError}
    >
      <input
        {...inputProps}
        ref={inputRef}
        style={{
          height: 24,
          boxSizing: "border-box",
          marginRight: 0,
          fontSize: 16,
        }}
        onClick={(e) => {
          if (inputProps.onClick) {
            inputProps.onClick(e);
          }

          if (!canUseAutoScroll) {
            state.open();
          }
        }}
        className="input"
        placeholder={placeholder}
        value={inputValueControlled.current ? inputValue : inputProps.value}
      />
      {showOpenButton && (
        <Button
          buttonRef={buttonRef}
          {...buttonProps}
          className="button"
          onClick={state.open}
        >
          <Icon isSrcRelative src="chevron_down.svg" size="xxs" />
        </Button>
      )}
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={inputRef}
          popoverRef={popoverRef}
          isNonModal
          placement="bottom start"
          width={`${menuWidth}px`}
          left={`${menuLeft}px`}
        >
          <Listbox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </StyledContainer>
  );
}

export default ComboBox;
