import { useMemo, useState } from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  amIContractPayee,
  amIContractPayer,
  isContractCompleted,
} from "@/services/ContractsService";
import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import Icon from "@/components/misc/Icon";
import { Item } from "@/components/input/RadioCardGroup";

import {
  StyledButtonsContainer,
  StyledContainer,
  StyledRadioCardGroup,
} from "./ActionsButton.styles";
import { useContractManagement } from "../../ContractManagementPage.context";

const ActionsButton: React.FC<IButtonProps> = (props) => {
  const drawerState = useDrawerState();
  const { contractDetails } = useContractManagement();
  const [selectedOption, setSelectedOption] = useState("");

  const { options } = useMemo(() => {
    const isCompleted = isContractCompleted(contractDetails);
    const amIPayer = amIContractPayer(contractDetails);
    const amIPayee = amIContractPayee(contractDetails);

    let options: Item[] = [];

    if (amIPayer && !isCompleted) {
      options = [
        {
          children: "Request a refund",
          value: "refund-request",
          dotColor: "#D08824",
        },
        {
          children: "Cancel job",
          value: "cancel-job",
          dotColor: "#EA5B5B",
        },
      ];
    }

    if (amIPayee && !isCompleted) {
      options = [
        {
          children: "Request additional payment",
          value: "additional-payment-request",
          dotColor: "#1FB263",
        },
        {
          children: "Cancel job",
          value: "cancel-job",
          dotColor: "#EA5B5B",
        },
      ];
    }

    return {
      options,
    };
  }, [contractDetails]);

  if (true || !options.length) {
    return null;
  }

  return (
    <>
      <Button variant="ghost" {...props} onClick={drawerState.open}>
        {props.children || (
          <Icon isSrcRelative src="menu.svg" customSize="1.25rem" />
        )}
      </Button>

      <Drawer state={drawerState}>
        <StyledContainer>
          <StyledRadioCardGroup
            label="contract-type"
            value={selectedOption || ""}
            onChange={setSelectedOption}
            items={options}
            itemStyles={{
              minHeight: "2rem",
              paddingBlock: "0.5rem",
              borderRadius: "4px",
            }}
          />

          <StyledButtonsContainer className="mt-3">
            <Button variant="secondary">Cancel</Button>
            <Button>Continue</Button>
          </StyledButtonsContainer>
        </StyledContainer>
      </Drawer>
    </>
  );
};

export default ActionsButton;
