import React from "react";

import Icon from "@/components/misc/Icon";
import { useHash } from "@/services/Routing";
import { useResponsive } from "@/styles";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";

import { StyledInfoModalContent } from "../ChatRoomPage.styles";
import FilesDisplay from "./FilesDisplay";
import OtherUserDetails from "./OtherUserDetails";
import ChatDetailsMenuButton from "./ChatDetailsMenuButton";

const ChatDetails: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { removehash } = useHash();

  return (
    <PageWithHeaderLayout
      stickyHeader
      headerProps={{
        backButtonProps: {
          onClick: () => {
            removehash(true);
          },
          children: <Icon isSrcRelative src="cross.svg" size="xs" />,
          style: {
            background: "transparent",
          },
          show: isScreenSmallerThanTablet,
        },
        titleProps: {
          children: "Details",
        },
        appendContent: <ChatDetailsMenuButton />,
      }}
    >
      <StyledInfoModalContent>
        <OtherUserDetails />
        <FilesDisplay />
      </StyledInfoModalContent>
    </PageWithHeaderLayout>
  );
};

export default ChatDetails;
