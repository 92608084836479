import { useMemo } from "react";

import FilesInput, { FileObject } from "@/features/input/FilesInput";

import { useContractManagement } from "../ContractManagementPage.context";

const FilesSection: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails } = useContractManagement();
  const { files } = contractDetails;

  const filesObjects: FileObject[] = useMemo(
    () =>
      (files || []).map((file) => ({ mediaFile: file } satisfies FileObject)),
    [files]
  );

  return (
    <FilesInput
      viewMode
      disabled
      className={className}
      value={filesObjects}
      label="Attachments"
      displayFileCount={0}
    />
  );
};

export default FilesSection;
