import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import ContractChatButton from "@/features/contracts/ContractChatButton";
import { getViewPortHeightCssString } from "@/styles";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  padding: 1rem;
  padding-top: 3rem;
`;

export const StyledTopContainer = styled.div`
  flex: 1;
  padding-inline: 1.5rem;
  text-align: center;

  & > div > p {
    max-width: 32ch;
  }
`;

export const StyledBottomContainer = styled.div``;

export const StyledButton = styled(Button)`
  font-weight: 600;
  gap: 0.5rem;
  padding-block: 1rem;
  width: 100%;
`;

export const StyledContentBold = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--clr-text-900, #0d0d12);
  margin: 0;
  margin-top: 4rem;
`;

export const StyledContentSmall = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--clr-icon-text-200, #818898);
  margin: 0;

  b {
    font-weight: inherit;
    color: var(--clr-primary-100, #5f57ff);
  }
`;

export const StyledSmallText = styled.p`
  font-size: 0.875rem;
  line-height: 160%;
  letter-spacing: -0.02em;
  margin: 0;
  font-weight: 400;
  padding-inline: 1.5rem;
  color: var(--clr-text-500, #666d80);

  a {
    color: var(--clr-primary-100, #5f57ff) !important;
    font-weight: 500;
  }
`;

export const StyledChatButton = styled(ContractChatButton)`
  font-weight: 600;
  gap: 0.5rem;
  padding-block: 1rem;
  width: 100%;
  color: var(--clr-text-900, #0d0d12);
`;
