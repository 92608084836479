"use client";

import React from "react";

import TextField from "@/components/input/TextField";
import Icon from "@/components/misc/Icon";
import { useContractList } from "@/services/ContractsService";
import { appFeatures } from "@/config/services";

import { useChatsPage } from "../ChatsPage.context";

const SearchInput: React.FC = () => {
  const { search, setSearch } = useChatsPage();
  const { data: contractList } = useContractList();

  if (
    contractList.length < 5 ||
    appFeatures.isSupported("CHAT.USE_STREAMIO")
  ) {
    return null;
  }

  return (
    <TextField
      useFloatingLabel={false}
      variant="background"
      type="search"
      placeholder="Search for Jobs or Clients"
      prependContent={
        <Icon
          src="/assets/images/icons/search.svg"
          alt="Search"
          size="sm"
          colorVariant="black"
        />
      }
      value={search}
      onChange={setSearch}
    />
  );
};

export default SearchInput;
