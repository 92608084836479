import { useCallback, useEffect, useState } from "react";

import useProfileData from "./useProfileData";
import { userService } from "@/config/services";

function usePublishProfile() {
  const { refresh, accountData, isProfileDataLoading } = useProfileData();
  const [published, setPublished] = useState(accountData?.profile_published);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  //--------------------

  const setPublishStatus = useCallback(
    (publish: boolean) => {
      if (publish && isPublishing) {
        return;
      }

      if (!publish && isUnpublishing) {
        return;
      }

      const loadingStateUpdateFunc = publish
        ? setIsPublishing
        : setIsUnpublishing;

      const dbUpdateFunc = publish
        ? userService.publishProfile
        : userService.unpublishProfile;

      loadingStateUpdateFunc(true);

      return dbUpdateFunc()
        .then((res) => {
          const success = res.data.data;
          if (success) {
            setPublished(publish);
            refresh();
          }
        })
        .finally(() => {
          loadingStateUpdateFunc(false);
        });
    },
    [refresh, isPublishing, isUnpublishing]
  );

  const publish = useCallback(() => {
    return setPublishStatus(true);
  }, [setPublishStatus]);

  const unpublish = useCallback(() => {
    return setPublishStatus(false);
  }, [setPublishStatus]);

  const togglePublish = useCallback(() => {
    if (isUnpublishing || isPublishing) {
      return;
    }

    return setPublishStatus(!published);
  }, [isUnpublishing, isPublishing, published, setPublishStatus]);

  //--------------------

  useEffect(() => {
    setPublished(accountData?.profile_published);
  }, [accountData?.profile_published]);

  //--------------------

  return {
    publish,
    unpublish,
    isLoading: isProfileDataLoading,
    published,
    isPublishing,
    isUnpublishing,
    togglePublish,
    updatingPublishState: isPublishing || isUnpublishing,
  };
}

export default usePublishProfile;
