import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

import FeatureCard from "../FeatureCard";
import { screenLargerThan } from "@/styles";

export const StyledHeading = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--clr-primary-200, #312ba0);
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  overflow: auto;
  padding: 1rem;
  margin-inline: -1rem;
  counter-reset: count;

  ${screenLargerThan.tablet}{
    margin-top: 1rem;
  }
`;

export const StyledFeatureCard = styled(FeatureCard)`
  flex: 1;
  position: relative;

  &:before {
    ${squareSizing("2rem")};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 1rem;
    left: 1rem;

    counter-increment: count;
    content: counter(count);

    background-color: var(--clr-primary-25, #e5e4fb);
    color: var(--clr-primary-200, #312ba0);
    border-radius: 10rem;
    font-weight: 600;
    font-size: 0.875rem;
  }
`;
