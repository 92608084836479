import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Avatar from "@/components/misc/Avatar";
import { selectUserData, useAppSelector } from "@/services/Store";
import { SITE_PATHS } from "@/config/routing";
import { screenLargerThan } from "@/styles";

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;

  ${screenLargerThan.tablet} {
    display: none;
  }
`;

const StyledAvatar = styled(Avatar)`
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

const UserAvatar: React.FC = () => {
  const userData = useAppSelector(selectUserData);

  return (
    <StyledLink to={SITE_PATHS.SETTINGS_PAGE}>
      <StyledAvatar
        img={userData?.picture || userData?.owner_profile || ""}
        firstName={userData?.given_name}
        lastName={userData?.family_name}
        customSize="2.125rem"
      />
    </StyledLink>
  );
};

export default UserAvatar;
