import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { overflowEllipsis, screenSmallerThan } from "@/styles";

export const StyledCard = styled.div`
  position: relative;

  ${screenSmallerThan.widescreen} {
    border-radius: 0.75rem;
    background: var(--clr-neutral-solid-0, #f8f9fb);
    padding: 1rem;
  }
`;

export const StyledDeleteButton = styled(Button)`
  position: absolute;
  top: 0.25rem;
  right: 1.15rem;
`;

export const StyledFilesSection = styled.div`
  display: flex;
  align-items: normal;
  gap: 1.5rem;
`;

export const StyledFileTile = styled.div`
  display: flex;
  align-items: normal;
  gap: 0.5rem;
`;

export const StyledFileName = styled.div`
  ${overflowEllipsis()};
  width: 5rem;
`;

export const StyledFileValuesContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: normal;
  gap: 1rem;
  overflow: hidden;
  margin-top: 0.1rem;
`;
