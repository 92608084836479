import * as amplitude from "@amplitude/analytics-browser";
import TagManager from "react-gtm-module";

import { APP_VERSION, IS_STAGING_ENV } from "@/config/app";

import {
  EventProps,
  ShareOfferEventArgs,
  ShareOfferEvents,
  NotifyEvents,
  NotifyEventsArgs,
  RejectOfferEvents,
  FeedbackEvents,
  FeedbackEventsArgs,
  OfferFormEvents,
  UserDetails,
} from "./Analytics.types";

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY!;
const GTM_KEY = process.env.REACT_APP_GTM_KEY!;

declare const window: Window & {
  gtag: Function;
};

export class Analytics {
  constructor() {
    this.setupGtm();
    this.setupAmplitute();
  }

  //----------------------------

  setUser = (data?: UserDetails) => {
    if (data) {
      this.setGtmUser(data);
      this.setAmplitudeUser(data);
    }
  };

  //----------| GA |-----------

  triggerGtagEvent = ({
    event,
    eventArgs,
    suffixUnderscore = true,
  }: EventProps) => {
    try {
      let eventName: string = event;
      if (suffixUnderscore) {
        eventName = `${eventName}_`;
      }
      window.gtag("event", eventName, eventArgs);

      if (IS_STAGING_ENV) {
        console.log("DEBUG:GTAG", {
          eventName,
          eventArgs,
        });
      }
    } catch {}
  };

  //----------| GTM |-----------

  setupGtm = () => {
    try {
      window.gtag("config", "TAG_ID", {
        send_page_view: true,
      });

      TagManager.initialize({
        gtmId: GTM_KEY, // Replace with your GTM ID
      });
    } catch {}
  };

  setGtmUser = (data?: UserDetails) => {
    if (data) {
      const { id, email, username } = data;

      (window as any).dataLayer.push({
        event: "userLogin",
        userEmail: email,
        userId: id,
        username: username,
      });
    }
  };

  triggerGtmEvent = ({
    event,
    eventArgs = {},
    suffixUnderscore = true,
  }: EventProps) => {
    let eventName: string = event;
    if (suffixUnderscore) {
      eventName = `${eventName}_`;
    }

    if (IS_STAGING_ENV) {
      console.log("DEBUG:GTM", {
        eventName,
        eventArgs,
      });
    }

    (window as any).dataLayer.push({
      event: eventName,
      event_args: eventArgs,
    });
  };

  //----------| AMPLITUDE |-----------

  setupAmplitute = () => {
    amplitude.init(AMPLITUDE_KEY, {
      autocapture: true,
      minIdLength: 1,
    });

    const identify = new amplitude.Identify();
    identify.set("app_version", APP_VERSION);
    amplitude.identify(identify);
  };

  setAmplitudeUser = (data?: UserDetails) => {
    if (data) {
      const { id, email, username } = data;
      if (id) {
        amplitude.setUserId(`${id}`);
      }

      const identify = new amplitude.Identify();
      if (id) identify.set("id", id);
      if (email) identify.set("email", email);
      if (username) identify.set("username", username);
      identify.set("app_version", APP_VERSION);
      amplitude.identify(identify);
    }
  };

  triggerAmplitudeEvent = ({ event, eventArgs }: EventProps) => {
    try {
      amplitude.track(event, eventArgs, {
        time: Date.now(),
      });
    } catch {}
  };

  clearAmplitudeUser = () => {
    amplitude.identify(new amplitude.Identify().clearAll());
    amplitude.setUserId("");
  };

  //----------------------

  clearUser = () => {
    this.clearAmplitudeUser();
  };

  //----------| EVETNS |-----------

  triggerEvent = (data: EventProps) => {
    // this.triggerGtagEvent(data);
    this.triggerAmplitudeEvent(data);
    this.triggerGtmEvent(data);
  };

  //----------------------

  triggerOfferFormEvent = (event: OfferFormEvents) => {
    this.triggerEvent({
      event,
    });
  };

  triggerShareOfferEvent = (
    event: ShareOfferEvents,
    eventArgs?: ShareOfferEventArgs
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerAccpetOfferEvent = (eventArgs: {
    contractId: string;
    accepterEmail: string;
  }) => {
    this.triggerEvent({
      event: "offer_accepted",
      eventArgs,
    });
  };

  triggerViewOfferEvent = (eventArgs: { contractId: string }) => {
    this.triggerEvent({
      event: "offer_viewed",
      eventArgs,
    });
  };

  triggerRejectOfferEvent = (
    event: RejectOfferEvents,
    eventArgs: { contractId: string }
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerFundOfferEvent = ({
    contractId,
    value,
  }: {
    contractId: string;
    value: number;
  }) => {
    this.triggerEvent({
      event: "purchase",
      eventArgs: {
        transaction_id: `OFFER_${contractId}`,
        value,
        currency: "USD",
      },
    });
  };

  //----------------------

  triggerNotifyEvent = (event: NotifyEvents, eventArgs?: NotifyEventsArgs) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerFeedbackEvent = (
    event: FeedbackEvents,
    eventArgs?: FeedbackEventsArgs
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  //----------| EVETNS |-----------

  triggerStripeOnboardingEvent = (eventArgs: { countryCode: string }) => {
    this.triggerEvent({
      event: "stripe_onboarding_started",
      eventArgs,
    });
  };
}
