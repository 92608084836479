import React from "react";

import Icon from "@/components/misc/Icon";
import PopoverButton, {
  usePopoverState,
} from "@/components/misc/PopoverButton";

import {
  StyledButton,
  StyledDangerButton,
  StyledMenu,
} from "../ChatRoomPage.styles";

const ChatDetailsMenuButton: React.FC = () => {
  const menuState = usePopoverState();

  return (
    <>
      <PopoverButton
        state={menuState}
        buttonProps={{
          variant: "ghost",
          children: (
            <Icon isSrcRelative src="menu_vertical.svg" customSize="1.15rem" />
          ),
        }}
        popoverProps={{
          placement: "bottom right",
          crossOffset: 16,
          children: (
            <StyledMenu>
              <StyledButton
                className="w-100"
                variant="ghost"
                colorVariant="black"
                onClick={menuState.close}
              >
                <Icon isSrcRelative src="eyes.svg" size="xs" />
                <span>View profile</span>
              </StyledButton>
              <StyledDangerButton
                className="w-100"
                variant="ghost"
                colorVariant="black"
                onClick={menuState.close}
              >
                <Icon isSrcRelative src="dustbin.svg" size="xs" />
                <span>Delete chat</span>
              </StyledDangerButton>
            </StyledMenu>
          ),
        }}
      />
    </>
  );
};

export default ChatDetailsMenuButton;
