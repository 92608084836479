"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";
import { overflowEllipsis } from "@/styles";

export const StyledCompactCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #f9fafb;
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;

  .image-container {
    ${squareSizing("4.75rem")};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .icon {
    width: 25%;
    height: 25%;
    object-fit: cover;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }

  & > .content {
    flex-grow: 1;
    cursor: pointer;
    ${overflowEllipsis()};
  }

  .button {
    position: absolute;
    top: 0.125rem;
    right: 0;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
`;

export const StyledIconContainer = styled.div`
  ${squareSizing("48px")};
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
`;

export const StyledMiddleContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const StyledPrimaryText = styled.div`
  color: var(--clr-text-ukwn-4, #242b42);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
`;

export const StyledSecondaryText = styled.div`
  color: var(--clr-text-ukwn-5, #7e8ca0);
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  display: flex;
  padding: 0.4rem 1.5rem;
  gap: 0.5rem;
  text-align: left;
  filter: brightness(0) invert(3%) sepia(5%) saturate(4090%) hue-rotate(201deg)
    brightness(95%) contrast(95%);

  span {
    flex: 1;
  }
`;

export const StyledDangerButton = styled(StyledButton)`
  filter: brightness(0) invert(54%) sepia(50%) saturate(3359%)
    hue-rotate(325deg) brightness(94%) contrast(95%);
`;

export const StyledMenu = styled.div`
  padding-block: 0.5rem;
`;
