"use client";

import React from "react";
import styled from "@emotion/styled";

import { Body, Heading, Label } from "@/components/Typography";
import EditorField from "@/components/input/EditorField";
import Icon from "@/components/misc/Icon";
import PackageDesktopCard from "@/features/user/PackageCard";
import { DEVICE_BREAKPOINT, screenSmallerThan, useResponsive } from "@/styles";

import { useProfileForm } from "../../ProfileForm.config";
import {
  StyledCard,
  StyledDeleteButton,
  StyledFileName,
  StyledFilesSection,
  StyledFileTile,
  StyledFileValuesContainer,
} from "./PackageCard.styles";

const StyledDesktopCard = styled(PackageDesktopCard)`
  ${screenSmallerThan.widescreen} {
    padding-bottom: 1rem;
    margin-inline: 1.5rem;
    border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
  }
`;

const PackageCard: React.FC<{
  index: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  className?: string;
}> = ({ index, onEdit, onDelete, className }) => {
  const { screenWidth } = useResponsive();
  const { values } = useProfileForm();

  const packageDetails = values.packages[index];

  const {
    name,
    attachments = [],
    price,
    estimated_duration,
    description,
  } = packageDetails;

  return (
    <StyledCard className={className}>
      <StyledDeleteButton
        variant="ghost"
        onClick={() => {
          onDelete(index);
        }}
      >
        <Icon isSrcRelative src="delete.svg" size="xs" />
      </StyledDeleteButton>

      <div
        onClick={() => {
          onEdit(index);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            pointerEvents: "none",
            cursor: "pointer",
          }}
        >
          {screenWidth < DEVICE_BREAKPOINT.widescreen ? (
            <>
              <Heading size="md">{name || "-"}</Heading>
              {!!attachments?.length && (
                <StyledFilesSection className="mt-3">
                  <Body
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Examples:
                  </Body>
                  <StyledFileValuesContainer>
                    {attachments.map((file, i) => (
                      <StyledFileTile key={i}>
                        <Icon isSrcRelative src="file.svg" size="xxs" />
                        <Label size="lg" as="div">
                          <Body
                            size="sm"
                            as="div"
                            style={{
                              display: "inline-block",
                              color: "var(--clr-neutral-solid-400, #808897)",
                            }}
                          >
                            <StyledFileName>{file.url}</StyledFileName>
                          </Body>
                        </Label>
                      </StyledFileTile>
                    ))}
                  </StyledFileValuesContainer>
                </StyledFilesSection>
              )}
              {!!price && (
                <>
                  <Body
                    className="mt-3"
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Price:
                  </Body>
                  <Label size="lg" className="mt-1">
                    ${}
                    &nbsp; &nbsp;
                    <Body
                      size="sm"
                      style={{
                        display: "inline-block",
                        color: "var(--clr-neutral-solid-400, #808897)",
                      }}
                    >
                      Fixed Price
                    </Body>
                  </Label>
                </>
              )}
              {!!estimated_duration && (
                <>
                  <Body
                    className="mt-3"
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Time:
                  </Body>
                  <Label size="lg" className="mt-1">
                    {estimated_duration}
                  </Label>
                </>
              )}
              {!!description && (
                <>
                  <Body
                    className="mt-3"
                    size="md"
                    style={{
                      color: "var(--clr-neutral-solid-400, #808897)",
                    }}
                  >
                    Description:
                  </Body>
                  <EditorField
                    readOnly
                    className="p-0"
                    label=""
                    prependContent={null}
                    value={{ markdown: description }}
                    drawerTitle="Description"
                  />
                </>
              )}
            </>
          ) : (
            <StyledDesktopCard useLink={false} package={packageDetails} />
          )}
        </div>
      </div>
    </StyledCard>
  );
};

export default PackageCard;
