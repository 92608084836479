const cache: { [key: string]: string } = {};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

export const URL_REGEX =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

// export const EMAIL_REGEX =
//   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function removeSpecialCharacters(word: string = "") {
  if (!word) {
    return "";
  }

  if (cache[word]) return cache[word];

  let modifiedWord = "";

  modifiedWord = `${word}`
    .replace(/[-_&/#,+()$~%.'":*?<>{}\s]/g, "")
    .toLowerCase();

  cache[word] = modifiedWord;

  return modifiedWord;
}

export function areStringsSubstring(
  s1: string,
  s2: string,
  removeSpecialCharacters_ = true
) {
  if (removeSpecialCharacters_) {
    s1 = removeSpecialCharacters(s1);
    s2 = removeSpecialCharacters(s2);
  }

  s1 = s1.toLocaleLowerCase();
  s2 = s2.toLocaleLowerCase();

  return s1.includes(s2) || s2.includes(s1);
}

export function removeUrlTrailingSlash(s: string) {
  try {
    const url = new URL(s);
    const { origin, search } = url;
    let { pathname } = url;

    if (pathname === "/") {
      pathname = "";
    }

    let updatedUrl = `${origin}${pathname}`;
    updatedUrl = updatedUrl.replace(/\/$/, "");
    updatedUrl += search;

    return updatedUrl;
  } catch (err) {
    return "";
  }
}

export function padText(text: any, width: number, char = "") {
  text = text + "";
  return text.length >= width
    ? text
    : new Array(width - text.length + 1).join(char) + text;
}

export function generateRandomString() {
  return Math.random().toString(36).slice(2);
}

export function areUrlDomiansSame(domainA: string, domainB: string) {
  try {
    return new URL(domainA).origin === new URL(domainB).origin;
  } catch {
    return false;
  }
}

export function getMatches(string: string, regex: RegExp, index = 1) {
  var matches = [];
  var match;
  while ((match = regex.exec(string))) {
    matches.push(match[index]);
  }
  return matches;
}
