import { Message } from "ably";

export const ABLY_API_KEY = process.env.REACT_APP_ABLY_API_KEY!;

export const ABLY_SUBSCRIBE_API_KEY =
  process.env.REACT_APP_ABLY_SUBSCRIBE_API_KEY!;

export const DUMMY_MESSAGES: Message[] = [
  // {
  //   connectionId: "00gZVaW_Fz",
  //   data: {
  //     attachements: [],
  //     content: "3",
  //     readStatus: 2,
  //     senderId: "6",
  //     type: "text",
  //   },
  //   encoding: "",
  //   id: "00gZVaW_Fz:0:0",
  //   name: "6",
  //   timestamp: 1714638852676,
  // },
];
