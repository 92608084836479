import React from "react";

import { ContractCompleteDetails } from "@/services/ContractsService";
import MilestonesDisplay from "@/features/contracts/MilestonesDisplay";

import { StyledSectionHeading } from "../ContractPreview.styles";

const MilestonesSection: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  const { milestones } = contractDetails;

  return (
    <div className={className}>
      <StyledSectionHeading>Milestones</StyledSectionHeading>
      <MilestonesDisplay className="mt-3" milestones={milestones} />
    </div>
  );
};

export default MilestonesSection;
