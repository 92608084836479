"use client";

import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { ANIMATION_CLASSNAMES } from "@/styles";
import TagListInput from "@/components/input/TagListInputNew";
import { areStringsSubstring } from "@/utils/string";

import { usePortfolioForm } from "../PortfolioForm.config";
import SKILLS_LIST from "@/features/user/ProfileForm/content/skills.json";

const StyledTagContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: 5rem;
`;

const StyledTagButton = styled(Button)`
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const TechListInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue } = usePortfolioForm();
  const { tech = [] } = values;
  const [search, setSearch] = useState("");

  const options = useMemo(() => {
    return SKILLS_LIST.filter((name) => !tech.includes(name))
      .filter((name) => !tech.includes(name))
      .filter((name) => areStringsSubstring(name, search))
      .map((name) => ({
        children: <>{name}</>,
        value: name,
      }));
  }, [search, tech]);

  return (
    <>
      <TagListInput
        focusOnMount
        className={className}
        variant="outline"
        placeholder="Search"
        inputValue={search}
        onInputChange={setSearch}
        value={tech}
        onChange={(value) => {
          setFieldValue("tech", value);
        }}
      />

      <StyledTagContainer className="mt-3">
        {options.map(({ value }, i) => (
          <StyledTagButton
            className={ANIMATION_CLASSNAMES.FADE_IN}
            variant="secondary"
            colorVariant="gray"
            key={value}
            onClick={() => {
              setSearch("");
              const updated = [...tech];
              updated.push(value);
              setFieldValue("tech", updated);
            }}
          >
            {value}
          </StyledTagButton>
        ))}
      </StyledTagContainer>
    </>
  );
};

export default TechListInput;
