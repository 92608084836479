import React from "react";
import styled from "@emotion/styled";

import GetPremiumCard from "@/features/user/GetPremiumCard";
import Icon from "@/components/misc/Icon";
import { Body, Heading, Label } from "@/components/Typography";
import { screenLargerThan } from "@/styles";

//-----------------------------

type FeatureType = {
  icon: string;
  title: string;
  description: string;
};

const FEATURES: FeatureType[] = [
  {
    icon: "/assets/pages/paypipe-id/lower_fees.svg",
    title: "Lower fees",
    description: "Take home more money with a lower transaction fee of 3.5%.",
  },
  {
    icon: "/assets/pages/paypipe-id/integrations.svg",
    title: "Integrations",
    description:
      "Connect your favourite tools and make your work life even easier.",
  },
  {
    icon: "/assets/pages/paypipe-id/ai_chat.svg",
    title: "Bonus AI credit",
    description: "Leverage the smarts of AI even more with bonus credit",
  },
  {
    icon: "/assets/pages/paypipe-id/box_tick.svg",
    title: "More Portfolio & Packages",
    description:
      "Add more than one portfolio item or package to your Paypipe ID",
  },
];

//-----------------------------

const StyledContainer = styled.div`
  .features-container {
    display: grid;
    gap: 0.75rem;

    ${screenLargerThan.tablet} {
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
  }
`;

//-----------------------------

const StyledFeature = styled.div`
  display: flex;
  gap: 0.75rem;
  background: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;
`;

const Feature: React.FC<FeatureType> = ({ icon, title, description }) => (
  <StyledFeature>
    <Icon src={icon} />
    <div>
      <Label size="md">{title}</Label>
      <Body size="sm" className="mt-2" style={{ color: "#666D80" }}>
        {description}
      </Body>
    </div>
  </StyledFeature>
);

//-----------------------------

const PremiumPrompt: React.FC<{
  onClose?: () => void;
  className?: string;
}> = ({ onClose, className }) => {
  return (
    <StyledContainer className={className}>
      <GetPremiumCard onClose={onClose} />

      <Heading size="md" className="mt-4">
        Premium Includes
      </Heading>
      <div className="features-container mt-3">
        {FEATURES.map((feature, i) => (
          <Feature key={i} {...feature} />
        ))}
      </div>
    </StyledContainer>
  );
};

export default PremiumPrompt;
