"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

import { TimelineType } from "./Timeline.types";

export const StyledContainer = styled.div<{ $itemMargin?: string }>`
  counter-reset: timeline;
  --items-margin: ${({ $itemMargin }) => $itemMargin || "2rem"};
  --counter-size: 1.125rem;
  --counter-gap: 0.75rem;
  --counter-active-bg: var(--clr-primary-200, #5f57ff);
  --counter-active-color: var(--clr-background-white, #fff);
  --counter-active-border: var(--clr-primary-200, #5f57ff);
  --bar-width: 2px;
`;

export const StyledItemContainer = styled.div<{
  $timelineType: TimelineType;
  $showCounter?: boolean;
  $completed?: boolean;
  $active?: boolean;
  $muted?: boolean;
  $showCount?: boolean;
}>`
  position: relative;
  padding-left: calc(var(--counter-size) + var(--counter-gap));

  & + div {
    margin-top: var(--items-margin);
  }

  &:not(:last-child):before {
    content: "";
    height: calc(100% + var(--items-margin));
    width: var(--bar-width);
    position: absolute;
    top: calc(0.5 * var(--counter-size));
    left: calc(0.5 * (var(--counter-size) - var(--bar-width)));
    background: ${({ $completed, $muted, $timelineType }) =>
      $timelineType === "static"
        ? "var(--clr-background-50, #eceff3)"
        : $muted
        ? // TO-CHECK
          "var(--clr-background-50, #eceff3)"
        : $completed
        ? "var(--clr-success-500, #1dbf73)"
        : "var(--clr-background-50, #eceff3)"};
  }

  ${({ $showCounter, $completed, $muted, $timelineType, $showCount }) =>
    $showCounter
      ? `
          &:after {
            ${squareSizing("var(--counter-size)")};

            ${
              $showCount
                ? `
                    counter-increment: timeline;
                    content: counter(timeline);
                    left: 0;
                    top: 0;
                  `
                : `
                  --size: 0.7rem;
                  content:"";
                  left: calc((0.5 * var(--counter-size)) - (0.5 * var(--size)));
                  top: calc((0.5 * var(--counter-size)) - (0.5 * var(--size)) + 1px);
                `
            }
            
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 0.625rem;
            font-weight: 700;
            color:  ${
              $muted && $timelineType !== "static"
                ? "var(--clr-icon-text-200, #818898)"
                : "var(--counter-active-color)"
            };;
            background: ${
              $timelineType === "static"
                ? "var(--clr-background-ukwn-9, #000005)"
                : $muted
                ? "var(--clr-background-white, #fff)"
                : $completed
                ? "var(--clr-success-500, #1dbf73)"
                : "var(--counter-active-bg)"
            };
            border: 1px solid ${
              $timelineType === "static"
                ? "var(--clr-border-ukwn-13, #000005)"
                : $muted
                ? "var(--clr-ukwn-2, #C1C7D0)"
                : $completed
                ? "var(--clr-success-500, #1dbf73)"
                : "var(--counter-active-border)"
            };
            border-radius: 10rem;
          }
        `
      : ""}
`;
