"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  Editor as EditorType,
} from "draft-js";
// @ts-ignore
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

import { IWysiwygProps } from "./Wysiwyg.types";
import { LIB_CONFIG } from "./Wysiwyg.config";
import { StyledEditor } from "./Wysiwyg.styles";

const Wysiwyg: React.FC<IWysiwygProps> = ({
  className,
  value: valueFromProps = { markdown: "" },
  initialValue,
  onChange,
  readOnly = false,
  focusOnMount = false,
  placeholder = "Type something",
}) => {
  const [editorRef, setEditorRef] = useState<EditorType | null>(null);
  const editorRefRef = useRef(editorRef);
  editorRefRef.current = editorRef;

  const [editorState, setEditorState] = useState(() => {
    const markdown = initialValue?.markdown || valueFromProps?.markdown;
    const rawData = markdownToDraft(markdown);
    const contentState = convertFromRaw(rawData);
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
  });
  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  const { markdown } = valueFromProps;

  const [alreadyFocused, setAlreadyFocused] = useState(false);
  // const alreadyFocusedRef = useRef(false);

  const localMarkdown = draftToMarkdown(
    convertToRaw(editorState.getCurrentContent())
  );

  //-------------------------------------

  // const focusToBottom = useCallback(() => {
  //   if (editorRef) {
  //     editorRef.focus();
  //     setEditorState(EditorState.moveFocusToEnd(editorStateRef.current));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editorRef]);
  // const focusToBottomRef = useRef(focusToBottom);

  const runPropsOnChange = useCallback(
    (state: EditorState) => {
      if (onChange) {
        const markdown = draftToMarkdown(
          convertToRaw(state.getCurrentContent())
        );

        if (markdown.length === 1 && editorRefRef.current) {
          editorRefRef.current.focus();
          setEditorState(EditorState.moveFocusToEnd(editorStateRef.current));
        }

        onChange({ markdown });
      }
    },
    [onChange]
  );

  const handleStateChange = useCallback(
    (state: EditorState) => {
      runPropsOnChange(state);
      setEditorState(state);
    },
    [runPropsOnChange]
  );

  //-------------------------------------

  useEffect(() => {
    if (localMarkdown !== markdown) {
      const rawData = markdownToDraft(markdown);
      const contentState = convertFromRaw(rawData);
      const editorState = EditorState.createWithContent(contentState);

      setEditorState(editorState);
    }
  }, [markdown, localMarkdown]);

  useEffect(() => {
    if (!alreadyFocused && editorRef && focusOnMount) {
      editorRef.focus();
      setEditorState(EditorState.moveFocusToEnd(editorState));
      setAlreadyFocused(true);
    }
  }, [editorRef, focusOnMount, editorState, alreadyFocused]);

  //-------------------------------------

  return (
    <StyledEditor
      editorRef={setEditorRef as any}
      className={className}
      editorClassName="editor"
      toolbarClassName="toolbar"
      toolbar={LIB_CONFIG}
      editorState={editorState}
      onEditorStateChange={handleStateChange}
      placeholder={placeholder}
      readOnly={readOnly}
      toolbarHidden={readOnly}
    />
  );
};

export default Wysiwyg;
