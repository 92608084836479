import React from "react";
import lodashGet from "lodash/get";

import { useContractForm } from "../../ContractForm.context";
import { StyledBoldTextField } from "../../ContractForm.styles";

const MilestoneNameInput: React.FC<{
  milestoneIndex: number;
  className?: string;
}> = ({ milestoneIndex, className }) => {
  const { values, setFieldValue, touched, setFieldTouched } = useContractForm();

  const fieldKey = `milestones[${milestoneIndex}].name`;
  const isTouched = lodashGet(touched, fieldKey, false);
  const value = lodashGet(values, fieldKey, "");

  return (
    <StyledBoldTextField
      className={className}
      hasError={!!isTouched && !value}
      placeholder="Milestone Name"
      value={value}
      onBlur={() => {
        setFieldTouched(fieldKey);
      }}
      onChange={(value) => {
        setFieldValue(fieldKey, value);
      }}
      style={
        value
          ? {}
          : ({
              "--color": "#a4acb9",
            } as React.CSSProperties)
      }
    />
  );
};

export default MilestoneNameInput;
