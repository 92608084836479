"use client";
import React, { useMemo } from "react";

import {
  ContractCompleteDetails,
  amIContractParticipant,
  amIContractPayee,
  amIContractPayer,
  getLoggedInUserContractUserType,
  getNextActiveMilestone,
  isContractCompleted,
  isMilestoneFundReleasable,
  isMilestoneFundRequestable,
  isMilestoneFundable,
  isMilestoneFunded,
  isMilestoneReviewable,
  isMilestoneStartable,
  isMilestoneWorkSubmittable,
} from "@/services/ContractsService";
import RequestPaymentButton from "@/features/contracts/RequestPaymentButton";
import ReleasePaymentButton from "@/features/contracts/ReleasePaymentButton";
import { generateTimelineItems } from "@/features/contracts/ContractTimeline";
import { selectContract, useAppSelector } from "@/services/Store";

const ContractNextActionButton: React.FC<{
  contractId: string;
}> = ({ contractId }) => {
  const contractDetails = useAppSelector(
    selectContract(contractId)
  ) as ContractCompleteDetails;
  const {
    showRequestPaymentButton,
    showReleasePaymentButton,
  } = useMemo(() => {
    const { milestone: nextActiveMilestone } =
      getNextActiveMilestone(contractDetails);
    const amIPayer = amIContractPayer(contractDetails);
    const amIPayee = amIContractPayee(contractDetails);
    const isCompleted = isContractCompleted(contractDetails);

    const showNewOfferButton = isCompleted && amIPayee;
    const showHireAgainButton = isCompleted && amIPayer;

    if (!nextActiveMilestone) {
      return {
        canFund: false,
        canSubmit: false,
        canReview: false,
        showRequestPaymentButton: false,
        showReleasePaymentButton: false,
        showChatButton: isCompleted,
        showNewOfferButton,
        showHireAgainButton,
      };
    }

    const isFunded = isMilestoneFunded(nextActiveMilestone);

    const canFund = isMilestoneFundable(nextActiveMilestone) && amIPayer;
    const canReview = isMilestoneReviewable(nextActiveMilestone) && amIPayer;
    const canReleasePayment =
      isMilestoneFundReleasable(nextActiveMilestone) && amIPayer;

    const canSubmit =
      isMilestoneWorkSubmittable(nextActiveMilestone) && amIPayee;
    const canRequestPayment =
      isMilestoneFundRequestable(nextActiveMilestone) && amIPayee;
    const canStart = isMilestoneStartable(nextActiveMilestone) && amIPayee;

    const showRequestPaymentButton = canRequestPayment;
    const showReleasePaymentButton = canReleasePayment;
    const showStartButton = canStart;
    const showChatButton = isCompleted || !isFunded;

    return {
      canFund,
      canSubmit,
      canReview,
      nextActiveMilestone,
      showRequestPaymentButton,
      showReleasePaymentButton,
      showChatButton,
      showNewOfferButton,
      showHireAgainButton,
      canStart,
      showStartButton,
    };
  }, [contractDetails]);

  const { items: timelineItems, currentIndex } = useMemo(() => {
    if (!contractDetails || !amIContractParticipant(contractDetails)) {
      return { items: [], currentIndex: 0 };
    }

    const userType = getLoggedInUserContractUserType(contractDetails);

    if (!userType) {
      return { items: [], currentIndex: 0 };
    }

    return generateTimelineItems({
      contract: contractDetails,
      userType,
    });
  }, [contractDetails]);

  const nextCta = timelineItems[currentIndex]?.ctaButton;

  if (nextCta) {
    return nextCta;
  }

  if (showRequestPaymentButton) {
    return <RequestPaymentButton contractDetails={contractDetails} />;
  }

  if (showReleasePaymentButton) {
    return <ReleasePaymentButton contractDetails={contractDetails} />;
  }

  return null;
};

export default ContractNextActionButton;
