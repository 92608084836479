import { Item } from "@/components/navigation/NavMenu";
import { SITE_PATHS } from "@/config/routing";

export const NAV_ITEMS: Item[] = [
  {
    id: "Upgrade",
    title: "Upgrade to Paypipe Premium",
    icon: "/assets/images/icons/upgrade.svg",
    iconProps: { size: "md", colorVariant: "default" },
    link: SITE_PATHS.SUBSCRIPTION_PAGE,
  },
  // {
  //   id: "Profile",
  //   title: "My Profile",
  //   icon: "/assets/images/icons/user_3.svg",
  //   link: SITE_PATHS.PROFILE_PAGE,
  // },
  {
    id: "Paypipe ID",
    title: "Paypipe.me",
    icon: "/assets/images/icons/user_id.svg",
    iconProps: { customSize: "1.4rem" },
    link: SITE_PATHS.PROFILE_PAGE,
  },
  // {
  //   id: "Transactions",
  //   title: "Transactions",
  //   icon: "/assets/images/icons/currency.svg",
  //   link: SITE_PATHS.TRANSACTION_PAGE,
  // },
  // {
  //   id: "Preference",
  //   title: "Preference",
  //   icon: "/assets/images/icons/settings.svg",
  //   link: SITE_PATHS.PREFERENCES_SETTING_PAGE,
  // },
  // {
  //   id: "Help",
  //   title: "Help Center",
  //   icon: "/assets/images/icons/help.svg",
  //   link: "#",
  // },
  // {
  //   id: "Rate the App",
  //   title: "Rate the App",
  //   icon: "/assets/images/icons/rate.svg",
  //   link: SITE_PATHS.APP_RATING_PAGE,
  // },
  {
    id: "Privacy Policy",
    title: "Privacy Policy",
    icon: "/assets/images/icons/privacy.svg",
    link: SITE_PATHS.POLICY_PAGE,
  },
  // {
  //   id: "Dev",
  //   title: "Dev",
  //   icon: "/assets/images/icons/user_id.svg",
  //   iconProps: { customSize: "1.4rem" },
  //   link: "/dev",
  // },
  // {
  //   id: "Offer",
  //   title: "Offer",
  //   icon: "/assets/images/icons/user_id.svg",
  //   iconProps: { customSize: "1.4rem" },
  //   link: "/offers?id=ad837c03-feb8-4854-b246-045905790e85",
  // },
  {
    id: "Log out",
    title: "Log out",
    icon: "/assets/images/icons/logout.svg",
    titleProps: {
      style: { color: "#EA5B5B" },
    },
  },
];

export const PREMIUM_SUBSCRIPTION_ITEM_INDEX = NAV_ITEMS.findIndex(
  (item) => item.id === "Upgrade"
);

export const PAYPIPE_ID_ITEM_INDEX = NAV_ITEMS.findIndex(
  (item) => item.id === "Paypipe ID"
);
