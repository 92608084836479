import React from "react";

import { DEVICE_BREAKPOINT, useResponsive } from "@/styles";

import { IContractManagementPageProps } from "./ContractManagementPage.types";
import ContractManagementPageMobile from "./ContractManagementPage.mobile";
import ContractManagementPageDesktop from "./ContractManagementPage.desktop";
import { ContractManagementProvider } from "./ContractManagementPage.context";
import { RESPONSEIVE_BREAKPOINT } from "./ContractManagementPage.config";

const ContractManagementPage: React.FC<IContractManagementPageProps> = (
  props
) => {
  const { screenWidth } = useResponsive();

  return (
    <ContractManagementProvider {...props}>
      {screenWidth < DEVICE_BREAKPOINT[RESPONSEIVE_BREAKPOINT] ? (
        <ContractManagementPageMobile />
      ) : (
        <ContractManagementPageDesktop />
      )}
    </ContractManagementProvider>
  );
};

export default ContractManagementPage;
