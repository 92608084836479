import { ContractListFilters, ContractStatus } from "../ContractsService.types";
import useContractList from "./useContractList";

function useOffersList(
  config: {
    filters?: ContractListFilters;
  } = {}
) {
  const { filters = {} } = config;
  const hookData = useContractList({
    filters: {
      ...filters,
      status: ContractStatus.PendingFunding,
    },
  });

  return hookData;
}

export default useOffersList;
