import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";

const MIN_ESTIMATE_VALUE = 1;
const MAX_ESTIMATE_VALUE = 1000;

export const StyledTextField = styled(TextField)`
  select {
    height: 100%;
    padding: 0.75rem 0.25rem;
    margin-block: 0.25rem;
    background: transparent;
    border-radius: 3px;
    border: none;
  }
`;

const DurationInput: React.FC<{
  duration: number;
  unit: string;
  onChange: (data: { duration: number; unit: string }) => void;
  className?: string;
  durationHasError?: boolean;
  onBlur?: () => void;
  placeholder?: string;
}> = ({
  duration,
  unit,
  onChange,
  className,
  durationHasError,
  onBlur,
  placeholder,
}) => {
  const usePluralUnit = duration > 1;

  return (
    <div className={className}>
      <StyledTextField
        aria-label="Estimate"
        variant="background"
        type="number"
        placeholder={placeholder || "Estimated completion time"}
        value={duration || ""}
        onChange={(value) => {
          onChange({
            duration: +value,
            unit,
          });
        }}
        onBlur={onBlur}
        min={MIN_ESTIMATE_VALUE}
        max={MAX_ESTIMATE_VALUE}
        step={1}
        hasError={durationHasError}
        appendContent={
          <select
            value={unit}
            onChange={(e) => {
              const value = e.target.value;
              onChange({
                duration,
                unit: value,
              });
            }}
          >
            <option value="Days">{usePluralUnit ? "Days" : "Day"}</option>
            <option value="Weeks">{usePluralUnit ? "Weeks" : "Week"}</option>
            <option value="Months">{usePluralUnit ? "Months" : "Month"}</option>
          </select>
        }
      />
    </div>
  );
};

export default DurationInput;
