import React from "react";

import MilestoneNameInput from "./MilestoneNameInput";
import MilestonePriceInput from "./MilestonePriceInput";
import MilestoneDateInput from "./MilestoneDateInput";
import MilestoneDescriptionField from "./MilestoneDescriptionField";

const MilestoneForm: React.FC<{
  milestoneIndex: number;
  className?: string;
}> = ({ milestoneIndex, className }) => {
  return (
    <div className={className}>
      <MilestoneNameInput milestoneIndex={milestoneIndex} />
      <MilestonePriceInput milestoneIndex={milestoneIndex} className="mt-2" />
      <MilestoneDateInput milestoneIndex={milestoneIndex} className="mt-2" />
      <MilestoneDescriptionField
        milestoneIndex={milestoneIndex}
        className="mt-4"
      />
    </div>
  );
};

export default MilestoneForm;
