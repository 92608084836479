import React from "react";

import { Label } from "@/components/Typography";

import { StyledContainer, StyledTab } from "./Tabs.styles";
import { ITabsProps } from "./Tabs.types";

const Tabs: React.FC<ITabsProps> = ({
  tabs,
  activeTabId,
  onChange,
  className,
}) => {
  return (
    <StyledContainer className={className}>
      {tabs.map((tab) => {
        const { id, title } = tab;

        return (
          <StyledTab
            key={id}
            variant="ghost"
            $active={activeTabId === id}
            onClick={() => {
              onChange(tab);
            }}
          >
            <Label size="md">{title}</Label>
          </StyledTab>
        );
      })}
    </StyledContainer>
  );
};

export default Tabs;
