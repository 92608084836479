import React, { useRef } from "react";

import ImageLayout from "@/features/layouts/ImageLayout";
import {
  PAYPIPE_ID_SEARCH_PARAM_NAME,
  useAuth,
} from "@/services/Authentication";
import { useParamState } from "@/services/Routing";
import {
  useIsValidPaypipeId,
  useUpdatePaypipeId,
} from "@/services/UserService";
import RingLoader from "@/components/misc/RingLoader";
import Button from "@/components/input/Button";
import SplashScreen from "@/features/pages/app/SplashScreen";
import Icon from "@/components/misc/Icon";
import { EXTERNAL_PATHS, SITE_PATHS } from "@/config/routing";
import Redirect from "@/components/navigation/Redirect";

import {
  StyledAuthForm,
  StyledClaimContentSection,
  StyledContent,
  StyledContentContainer,
  StyledError,
  StyledHeading,
  StyledOnboardingContentSection,
  StyledTextField,
} from "./ClaimIdPage.styles";
import SuccessScreen from "./components/SuccessScreen";

const ClaimIdPage: React.FC = () => {
  const { isAuthenticated, userData } = useAuth();
  const [paypipeId, setPaypipeId] = useParamState<string>(
    PAYPIPE_ID_SEARCH_PARAM_NAME,
    "",
    {
      parseJson: false,
    }
  );
  const {
    data: { isValid, errors },
    isValidating,
  } = useIsValidPaypipeId({ id: paypipeId || "" });
  const { update, isUpdating, hasUpdated } = useUpdatePaypipeId();

  const hasSetIdAlreadyRef = useRef(!!userData?.last_updated_paypipe_id);

  const onboardingJsx = (
    <ImageLayout>
      <StyledOnboardingContentSection>
        <div>
          <StyledContentContainer>
            <StyledHeading size="md">Join Paypipe</StyledHeading>
            <StyledContent size="md">
              Sign up for free and claim your Paypipe ID
            </StyledContent>
          </StyledContentContainer>
          <StyledAuthForm />
        </div>
      </StyledOnboardingContentSection>
    </ImageLayout>
  );

  const claimIdJsx = (
    <ImageLayout maxContentWidth="100%">
      <StyledClaimContentSection>
        <div className="w-100">
          <div>
            <StyledHeading size="md">Choose your ID name</StyledHeading>
            <StyledContent size="md">Claim your free Paypipe ID</StyledContent>
          </div>
          <StyledTextField
            focusOnClick
            className="input"
            useFloatingLabel={false}
            variant="background"
            placeholder="yourname"
            value={paypipeId}
            onChange={setPaypipeId}
            prependContent={
              <span className="prefx">{EXTERNAL_PATHS.PAYPIPE_ID_SITE}/</span>
            }
            style={{ gap: "0" }}
            appendContent={
              (isValidating && (
                <div className="appended">
                  <RingLoader />
                </div>
              )) ||
              (isValid && (
                <div className="appended">
                  <Icon isSrcRelative src="tick_rounded_2.svg" />
                </div>
              ))
            }
          />
          {!isValid &&
            errors.map((error) => (
              <StyledError size="md" key={error} className="mt-2 px-2">
                {error}
              </StyledError>
            ))}
          <Button
            className="w-100 primary-button py-3"
            disabled={isValidating || !isValid}
            onClick={() => {
              update(paypipeId);
            }}
          >
            Continue
          </Button>
        </div>
      </StyledClaimContentSection>
    </ImageLayout>
  );

  if (isUpdating) {
    return <SplashScreen />;
  }

  if (hasUpdated) {
    return <SuccessScreen />;
  }

  if (isAuthenticated && hasSetIdAlreadyRef.current) {
    return (
      <Redirect replace path={`${SITE_PATHS.EDIT_PROFILE_PAGE}#details`} />
    );
  }

  return isAuthenticated ? claimIdJsx : onboardingJsx;
};

export default ClaimIdPage;
