import React from "react";

import {
  StyledPage,
  StyledImageSection,
  StyledContentSection,
  StyledLogo,
} from "./ImageLayout.styles";

const DEFAULT_BG = "/assets/images/pages/onboarding/phone_desktop.jpg";

const ImageLayout: React.FC<{
  children: React.ReactNode;
  bgImageSrc?: string;
  maxContentWidth?: string;
  showLogo?: boolean;
  className?: string;
  imageSectionContent?: React.ReactNode;
}> = ({
  children,
  bgImageSrc = DEFAULT_BG,
  maxContentWidth = "720px",
  showLogo = true,
  className,
  imageSectionContent,
}) => {
  return (
    <StyledPage className={className}>
      <StyledImageSection style={{ backgroundImage: `url(${bgImageSrc})` }}>
        {imageSectionContent}
      </StyledImageSection>
      <StyledContentSection>
        <div style={{ maxWidth: maxContentWidth }}>
          {showLogo && <StyledLogo />}
          {children}
        </div>
      </StyledContentSection>
    </StyledPage>
  );
};

export default ImageLayout;
