import styled from "@emotion/styled";
import React, { useState } from "react";

import Button from "@/components/input/Button";
import TextField from "@/components/input/TextField";
import { ANIMATION_CLASSNAMES, getViewPortHeightCssString } from "@/styles";
import { useShareOfferViaEmail } from "@/services/ContractsService";

const StyledPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  border: 4px dashed red;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledDisplayContent = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;

  main {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
  }

  & > * {
    border: 2px dashed gray;
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
`;

const StyledDvh = styled(StyledPanel)`
  height: 100dvh;
`;

const StyledVh = styled(StyledPanel)`
  height: 100vh;
`;

const StyledCustomVh = styled(StyledPanel)`
  height: ${getViewPortHeightCssString()};
`;

const HeightTest: React.FC = () => {
  const [type, setType] = useState<"" | "dvh" | "vh" | "custom-vh">();
  const { formModalJsx: formModalJsx1, triggerShare: triggerShare1 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "default",
    });
  const { formModalJsx: formModalJsx2, triggerShare: triggerShare2 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "fullscreen",
    });
  const { formModalJsx: formModalJsx3, triggerShare: triggerShare3 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "scroll",
    });
  const { formModalJsx: formModalJsx4, triggerShare: triggerShare4 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "scroll-fullscreen",
    });
  const { formModalJsx: formModalJsx5, triggerShare: triggerShare5 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "scroll-fullscreen",
      scrollToTopDelayInMs: 1000,
    });
  const { formModalJsx: formModalJsx6, triggerShare: triggerShare6 } =
    useShareOfferViaEmail({
      contractDetails: {} as any,
      share_source: "from_dashboard",
      tempSolutionTest: "scroll-fullscreen",
      scrollToTopDelayInMs: 2000,
    });

  const closeButton = (
    <Button
      className="mt-3 w-100"
      variant="secondary"
      onClick={() => {
        setType("");
      }}
    >
      Close
    </Button>
  );

  const dummyForm = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <TextField autoFocus variant="background" placeholder="Field 1" />

      <TextField className="mt-4" variant="background" placeholder="Field 2" />

      <Button className="mt-5 w-100" type="submit">
        Submit
      </Button>
    </form>
  );

  const content = ({
    ContainerComponent,
    title,
  }: {
    ContainerComponent: any;
    title: string;
  }) => {
    return (
      <ContainerComponent className={ANIMATION_CLASSNAMES.FADE_IN}>
        <StyledDisplayContent>
          {/* <header>header</header> */}
          <main>
            <p>{title}</p> {closeButton} {dummyForm}
          </main>
          {/* <footer>footer</footer> */}
        </StyledDisplayContent>
      </ContainerComponent>
    );
  };

  const heightContent = (
    <>
      {type === "dvh" &&
        content({
          ContainerComponent: StyledDvh,
          title: "Solution 1 - DVH",
        })}

      {type === "vh" &&
        content({
          ContainerComponent: StyledVh,
          title: "Solution 2 - VH",
        })}

      {type === "custom-vh" &&
        content({
          ContainerComponent: StyledCustomVh,
          title: "Solution 3 - Custom VH",
        })}
    </>
  );

  const defaultContent = (
    <div>
      <p>
        Click a solution. It will open email share dummy form. Check which works
        best in terms of input focus and keyboard overlaying fix
      </p>
      {/* <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={() => {
          setType("dvh");
        }}
      >
        Solution 1 - DVH
      </Button>
      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={() => {
          setType("vh");
        }}
      >
        Solution 2 - VH
      </Button>
      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={() => {
          setType("custom-vh");
        }}
      >
        Solution 3 - Custom VH
      </Button> */}

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare1}
      >
        Solution 1
      </Button>

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare2}
      >
        Solution 2
      </Button>

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare3}
      >
        Solution 3
      </Button>

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare4}
      >
        Solution 4
      </Button>

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare5}
      >
        Solution 4 - A (1 sec delay)
      </Button>

      <Button
        className="w-100 mt-3"
        variant="secondary"
        onClick={triggerShare6}
      >
        Solution 4 - B (2 sec delay)
      </Button>

      {formModalJsx1}
      {formModalJsx2}
      {formModalJsx3}
      {formModalJsx4}
      {formModalJsx5}
      {formModalJsx6}
    </div>
  );

  return !!type ? heightContent : defaultContent;
};

export default HeightTest;
