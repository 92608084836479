"use client";
import React from "react";

import Icon from "@/components/misc/Icon";

import { StyledCard, StyledLink, StyledTitle } from "./QuickActionCard.styles";
import { IQuickActionCardProps } from "./QuickActionCard.types";

const ActionCard: React.FC<IQuickActionCardProps> = ({
  title,
  icon,
  bgColor,
  link,
  onClick,
  className,
}) => {
  return (
    <StyledCard
      style={{ background: bgColor }}
      onClick={onClick}
      className={className}
    >
      <Icon isSrcRelative size="sm" src={icon} colorVariant="black" />
      <StyledTitle>{title}</StyledTitle>
      <StyledLink to={link} />
    </StyledCard>
  );
};

export default ActionCard;
