import { useCallback, useState } from "react";

import { contractService } from "@/config/services";
import { ContractId, MilestoneId } from "@/services/ContractsService";

function usePreApproveOffer({
  contractId,
  milestoneId,
  userDetails,
}: {
  contractId: ContractId;
  milestoneId: MilestoneId;
  userDetails: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
}) {
  const [isLoading, setIsLoading] = useState(false);

  const preApprove = useCallback(() => {
    if (isLoading || !userDetails.email) {
      return;
    }

    setIsLoading(true);

    return contractService
      .preApproveContract(contractId, milestoneId, userDetails)
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading, contractId, milestoneId, userDetails]);

  return { preApprove, isLoading };
}

export default usePreApproveOffer;
