import { screenLargerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;
  padding-bottom: 2rem;
  max-width: 480px !important;

  .label {
    color: #000;
    font-size: 2rem;
    font-weight: 600;
  }

  ${screenLargerThan.tablet} {
    padding-inline: 0 !important;
    padding-block: 2rem 4rem;
  }

  .cta-section {
    margin-top: auto;
  }

  .plan-card {
    .bottom {
      margin-top: 1rem;
    }

    .top {
      display: flex;
      gap: 1rem;
    }

    .bottom {
      display: flex;
      gap: 1em;
    }
  }
`;
