import styled from "@emotion/styled";

import Card from "@/components/data/Card";

export const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;

  & > :nth-child(2) {
    flex: 1;
    color: var(--clr-text-900, #0d0d12);
  }
`;
