import React from "react";

import Button from "@/components/input/Button";
import useShare from "@/services/Share/hook/useShare";

const Share: React.FC = () => {
  const { share, modalJsx } = useShare({
    content: {
      title: "Example",
      text: "This example shows a simple focusable element which supports copying a string when focused, and another element which supports pasting plain text.",
      url: "/dev",
      isUrlRelative: true,
    },
  });

  return (
    <>
      {modalJsx}
      <div>
        <Button onClick={share}>Share</Button>
      </div>
    </>
  );
};

export default Share;
