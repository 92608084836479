import React, { useMemo } from "react";

import { ContractType } from "@/services/ContractsService";
import { formatDate } from "@/utils/date";
import FilesInput from "@/features/input/FilesInput";
import DetailsTable, { Item } from "@/components/data/DetailsTable";

import { useContractForm } from "../../../ContractForm.context";
import {
  StyledStep,
  StyledStepFloatingContent,
  StyledStepTopContent,
} from "../../../ContractForm.styles";
import StepContinueButton from "../../inputs/StepContinueButton";
import HeaderLayout from "../../misc/HeaderLayout";
import DescriptionField from "../../inputs/TransactionDescriptionField";
import {
  StyledContractTitle,
  StyledContractValue,
  StyledSectionHeading,
} from "./ReviewStep.styles";
import MilestoneInput from "../../inputs/MilestoneInput";
import StepBackButton from "../../inputs/StepBackButton";

const ReviewStep: React.FC = () => {
  const { values } = useContractForm();

  const {
    title,
    milestones,
    client_name,
    contract_type,
    start_date,
    end_date,
  } = values;

  const isMilestoneContract = values.contract_type === ContractType.Milestone;

  const totalValue = useMemo(
    () => milestones.reduce((sum, { value }) => sum + value, 0),
    [milestones]
  );

  const clientDetailFields = useMemo(() => {
    return client_name
      ? [
          {
            label: "Client Name",
            value: client_name,
          },
        ]
      : [];
  }, [client_name]);

  const transactonDetailFields = useMemo(() => {
    let fields: Item[] = [];

    if (contract_type !== ContractType.Milestone) {
      if (start_date) {
        fields.push({
          label: "Start Date",
          value: start_date ? formatDate(start_date) : "-",
        });
      }

      if (end_date) {
        fields.push({
          label: "Due Date",
          value: end_date ? formatDate(end_date) : "-",
        });
      }
    }

    return fields;
  }, [contract_type, start_date, end_date]);

  return (
    <HeaderLayout>
      <StyledStep>
        <StyledStepTopContent>
          <StyledContractTitle>{title}</StyledContractTitle>
          <StyledContractValue className="mt-2">
            ${totalValue}.00
          </StyledContractValue>

          <div className="mt-5">
            <DetailsTable items={clientDetailFields} />

            <StyledSectionHeading className="mt-3">
              Job Details
            </StyledSectionHeading>

            <DetailsTable className="mt-3" items={transactonDetailFields} />

            <DescriptionField readOnly className="mt-4" />
          </div>

          {!!values.files.length && (
            <FilesInput
              disabled
              className="mt-3"
              value={values.files}
              label="Files"
              displayFileCount={0}
            />
          )}

          {isMilestoneContract && <MilestoneInput readOnly className="mt-4" />}
        </StyledStepTopContent>

        <StyledStepFloatingContent>
          <StepBackButton />
          <StepContinueButton />
        </StyledStepFloatingContent>
      </StyledStep>
    </HeaderLayout>
  );
};

export default ReviewStep;
