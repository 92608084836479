import React from "react";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Modal, { useModalState } from "@/components/misc/Modal";
import Icon from "@/components/misc/Icon";
import { SITE_PATHS } from "@/config/routing";
import ContractChatButton from "@/features/contracts/ContractChatButton";

import {
  StyledBottomContainer,
  StyledButtonDark,
  StyledContainer,
  StyledTopContainer,
} from "./RejectSuccessScreen.styles";
import { useContractRejectForm } from "../../ContractRejectForm.context";

const RejectSuccessScreen: React.FC = () => {
  const state = useModalState({ isOpen: true });
  const { contractId } = useContractRejectForm();

  return (
    <Modal fullscreen className="p-0" state={state}>
      <StyledContainer>
        <StyledTopContainer>
          <ImageTitleContent
            title="Transaction rejected!"
            content={
              <>
                You&apos;ve rejected the transaction sent by <b>“Saber Ali”</b>
              </>
            }
            image="/assets/images/pages/contracts/contract_rejected.svg"
          />
        </StyledTopContainer>

        <StyledBottomContainer>
          <ContractChatButton
            contractId={contractId}
            appendContent={<Icon isSrcRelative src="share.svg" size="xxs" />}
          />

          <StyledButtonDark variant="ghost" link={SITE_PATHS.HOME_PAGE}>
            Back to home
          </StyledButtonDark>
        </StyledBottomContainer>
      </StyledContainer>
    </Modal>
  );
};

export default RejectSuccessScreen;
