"use client";

import React, { useMemo, useState } from "react";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { SITE_PATHS } from "@/config/routing";
import {
  SubscriptionDuration,
  useAvailSubscription,
  useSubscriptionPlans,
} from "@/services/UserService";
import { useResponsive } from "@/styles";
import Switch from "@/components/input/Switch";
import { Label } from "@/components/Typography";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import SplashScreen from "@/features/pages/app/SplashScreen";

import {
  StyledCta,
  StyledImageTitleContent,
  StyledPage,
  StyledPlanButton,
  StyledSwitchContainer,
  StyledTag,
} from "./SubscriptionPlans.styles";
import SubscriptionCard from "../SubscriptionCard";
import {
  SUBSCRIPTIONS_CONFIG,
  USE_STATIC_PRICES,
} from "../../SubscriptionPage.config";

const SubscriptionPlans: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const [subscriptionDuration, setSubscriptionDuration] = useState(
    SubscriptionDuration.Yearly
  );

  const { subscribe, isSubscribing } = useAvailSubscription({
    interval: subscriptionDuration,
  });
  const { data: plansData, isLoading } = useSubscriptionPlans();

  const { discount: yearlyPlanBenefitInUsdStatic } =
    SUBSCRIPTIONS_CONFIG[SubscriptionDuration.Yearly];

  const { yearlyPlanBenefitInUsd: yearlyPlanBenefitInUsdCalculated } =
    useMemo(() => {
      const data = {
        monthlyPlanPriceInUsd: 0,
        yearlyPlanPriceInUsd: 0,
        yearlyPlanBenefitInUsd: 0,
      };

      if (!plansData) {
        return data;
      }

      const {
        plans: {
          month: { price_cent: monthlyPlanPriceIncent },
          year: {
            benefit: yearlyPlanBenefit = 0,
            price_cent: yearlyPlanPriceIncent,
          },
        },
      } = plansData;

      data.monthlyPlanPriceInUsd = monthlyPlanPriceIncent / 100;
      data.yearlyPlanPriceInUsd = yearlyPlanPriceIncent / 100;
      data.yearlyPlanBenefitInUsd = yearlyPlanBenefit / 100;

      return data;
    }, [plansData]);

  const yearlyPlanBenefitInUsd = USE_STATIC_PRICES
    ? yearlyPlanBenefitInUsdStatic
    : yearlyPlanBenefitInUsdCalculated;

  //-------------------------------------

  if (isLoading) {
    return <SplashScreen />;
  }

  const content = (
    <StyledPage>
      <div className="container">
        <StyledImageTitleContent
          image="/assets/images/pages/subscription/logo.svg"
          title="Upgrade to Premium"
          content="Say hello to more money in your pocket with lower fees and exclusive first access to new features."
        />
        <StyledSwitchContainer className="mt-2">
          <StyledPlanButton
            variant="ghost"
            colorVariant="black"
            onClick={() => {
              setSubscriptionDuration(SubscriptionDuration.Monthly);
            }}
          >
            <Label size="sm">Monthly</Label>
          </StyledPlanButton>
          <Switch
            value={subscriptionDuration === SubscriptionDuration.Yearly}
            onChange={(value) => {
              setSubscriptionDuration(
                value
                  ? SubscriptionDuration.Yearly
                  : SubscriptionDuration.Monthly
              );
            }}
          />
          <StyledPlanButton
            variant="ghost"
            colorVariant="black"
            onClick={() => {
              setSubscriptionDuration(SubscriptionDuration.Yearly);
            }}
          >
            <Label size="sm">Yearly</Label>

            {!!yearlyPlanBenefitInUsd && (
              <StyledTag size="sm">Save ${yearlyPlanBenefitInUsd}</StyledTag>
            )}
          </StyledPlanButton>
        </StyledSwitchContainer>

        <SubscriptionCard
          subscriptionDuration={subscriptionDuration}
          className="mt-3 mb-4"
        />

        <StyledCta
          disabled={isSubscribing}
          className="w-100 mt-auto pt-3 pb-3"
          onClick={subscribe}
        >
          Upgrade
        </StyledCta>
      </div>
    </StyledPage>
  );

  //---------------------------------------

  if (isSubscribing) {
    return <SplashScreen />;
  }

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          backButtonProps: { link: SITE_PATHS.SETTINGS_PAGE },
          titleProps: {
            children: "Subscription",
          },
        }}
      >
        {content}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout
      headerProps={{
        logo: {
          component: "Subscription",
        },
      }}
    >
      {content}
    </DesktopPrimaryLayout>
  );
};

export default SubscriptionPlans;
