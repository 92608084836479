"use client";

import React from "react";

import NoContent from "@/components/data/ImageTitleContent";

const DEFAULT_TITLE = <>You don&apos;t have any messages.</>;

const NoChats: React.FC<{ title?: React.ReactNode }> = ({
  title = DEFAULT_TITLE,
}) => {
  return (
    <NoContent
      image="/assets/images/pages/chats/no_messages.svg"
      title={title || DEFAULT_TITLE}
      content=""
    />
  );
};

export default NoChats;
