import { useParamState } from "@/services/Routing";

import useSubscriptionDetails from "./useSubscriptionDetails";

function useSubscriptionRedirect() {
  const { subscriptionDetails } = useSubscriptionDetails();
  const [successState] = useParamState<boolean>("success", false);
  const [isSubscriptionCallback] = useParamState<boolean>(
    "subscription_callback",
    false
  );

  const subscriptionSucceeded =
    isSubscriptionCallback === true &&
    successState === true &&
    subscriptionDetails?.hasPremium;
  const subscriptionFailed =
    isSubscriptionCallback === true &&
    successState === false &&
    subscriptionDetails?.hasPremium;

  //-----------------------

  return {
    subscriptionSucceeded,
    subscriptionFailed,
  };
}

export default useSubscriptionRedirect;
