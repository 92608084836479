"use client";
import React from "react";

import EarningsGraph from "@/features/contracts/EarningsGraph";
import { useProfileData, useWallet } from "@/services/UserService";

import {
  StyledCard,
  StyledSecondaryHeading,
  StyledValue,
} from "../HomePage.styles";

const BalanceSection: React.FC<{ className?: string }> = ({ className }) => {
  useProfileData();
  const { balance } = useWallet();

  return (
    <StyledCard className={className}>
      <StyledSecondaryHeading>Earnings</StyledSecondaryHeading>
      <StyledValue>${balance}</StyledValue>

      <EarningsGraph className="mt-3" />
    </StyledCard>
  );
};

export default BalanceSection;
