import React, { useMemo } from "react";
import lodashGet from "lodash/get";

import { useAuth } from "@/services/Authentication";
import {
  ChatRoom as ChatRoomNew,
  useChatIdFromUrl,
  USER_CHANNEL_SHORT_REGEX,
} from "@/services/ChatServiceNew";
import { useResponsive } from "@/styles";
import { getUserFullName, useUserProfile } from "@/services/UserService";
import { getMatches } from "@/utils/string";
import { ChatRoomLoading } from "@/features/chat/ChatRoom";

import { useChatRoomPage } from "../ChatRoomPage.context";
import NewChat from "./NewChat";

const ChatRoom: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { showNewChatFlowState } = useChatRoomPage();

  const { loggedInUserId, userData } = useAuth();

  const { idFromUrl: channelId } = useChatIdFromUrl();

  const userIds: number[] = useMemo(() => {
    if (!channelId) {
      return [];
    }

    const match = channelId.matchAll(USER_CHANNEL_SHORT_REGEX);
    const members = match
      ? (
          lodashGet(getMatches(channelId, USER_CHANNEL_SHORT_REGEX), "[0]") ||
          ""
        )
          .split("_")
          .map(Number)
      : [];

    return members;
  }, [channelId]);

  const otherUserId = useMemo(() => {
    return userIds.find((id) => id !== loggedInUserId) || 0;
  }, [userIds, loggedInUserId]);

  const {
    accountData: otherUserDetails,
    isLoading: isLoadingOtherUserDetails,
  } = useUserProfile(otherUserId);

  const roomUsers = useMemo(
    () =>
      !!otherUserDetails && !!userData
        ? [
            {
              id: userData.id,
              name: getUserFullName(userData),
              image: userData?.picture || "",
              email: userData.email,
              paypipeId: userData?.paypipe_id || "",
            },
            {
              id: otherUserDetails.id,
              name: getUserFullName(otherUserDetails),
              image: otherUserDetails?.picture || "",
              email: otherUserDetails.email,
              paypipeId: userData?.paypipe_id || "",
            },
          ]
        : [],
    [otherUserDetails, userData]
  );

  if (isLoadingOtherUserDetails || !otherUserId) {
    return <ChatRoomLoading />;
  }

  return (
    <ChatRoomNew
      showBackButton={isScreenSmallerThanTablet}
      contractId={channelId}
      currentUserId={`${loggedInUserId}`}
      users={roomUsers.map((u) => ({ ...u, id: `${u.id}` }))}
      hideInputs={showNewChatFlowState.state}
      footer={{
        appendContent: showNewChatFlowState.state ? <NewChat /> : null,
      }}
    />
  );
};

export default ChatRoom;
