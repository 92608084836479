import React from "react";

import {
  StyledCardsContainer,
  StyledFeatureCard,
  StyledHeading,
} from "./FeatureCards.styles";
import { FEATURES_LIST } from "./FeatureCards.content";

const FeatureCards: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <section className={className}>
      <StyledHeading>Here's what to expect next:</StyledHeading>
      <StyledCardsContainer>
        {FEATURES_LIST.map((item, i) => (
          <StyledFeatureCard key={i} {...item} />
        ))}
      </StyledCardsContainer>
    </section>
  );
};

export default FeatureCards;
