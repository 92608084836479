import React, { useMemo } from "react";
import capitalize from "lodash/capitalize";

import Button from "@/components/input/Button";
import {
  getContractChatPageUrl,
  getContractOtherParticipant,
  getContractParticipant,
  useContractDetails,
} from "@/services/ContractsService";

import { IContractChatButtonProps } from "./ContractChatButton.types";
import { useAuth } from "@/services/Authentication";

const ContractChatButton: React.FC<IContractChatButtonProps> = ({
  contractId,
  userType,
  prependContent,
  defaultText,
  appendContent,
  onlyFirstName,
  onboardingEmail,
  ...restProps
}) => {
  const { contractDetails } = useContractDetails({
    contractId,
  });
  const { login, isAuthenticated } = useAuth();

  //--------

  const otherParticipant = useMemo(() => {
    const otherParticipant = contractDetails
      ? userType
        ? getContractParticipant(contractDetails, userType)
        : getContractOtherParticipant(contractDetails)
      : null;

    return otherParticipant;
  }, [contractDetails, userType]);

  const otherParticipantName = useMemo(() => {
    const otherParticipantName = onlyFirstName
      ? otherParticipant?.user?.given_name
      : [
          otherParticipant?.user?.given_name || "",
          otherParticipant?.user?.family_name || "",
        ]
          .filter(Boolean)
          .join(" ");

    return capitalize(otherParticipantName);
  }, [otherParticipant, onlyFirstName]);

  const chatUrl = getContractChatPageUrl(contractDetails?.id || "");

  //----------------------------------------

  if (!isAuthenticated) {
    return (
      <Button
        {...restProps}
        onClick={() => {
          login({ redirectUrl: chatUrl, email: onboardingEmail });
        }}
      >
        {prependContent}
        {otherParticipantName ? (
          <>Chat with {otherParticipantName}</>
        ) : (
          defaultText || "Go to chat"
        )}
        {appendContent}
      </Button>
    );
  }

  return (
    <Button {...restProps} link={chatUrl}>
      {prependContent}
      {otherParticipantName ? (
        <>Chat with {otherParticipantName}</>
      ) : (
        defaultText || "Go to chat"
      )}
      {appendContent}
    </Button>
  );
};

export default ContractChatButton;
