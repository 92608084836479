import { VisuallyHidden, useFocusRing, useSwitch } from "react-aria";
import { ISwitchProps } from "./Switch.types";
import { useToggleState } from "react-stately";
import { useRef } from "react";
import { StyledBall, StyledBg, StyledFocus, StyledSvg } from "./Switch.styles";

const Switch: React.FC<ISwitchProps> = ({ value, ...restProps }) => {
  const updatedProps = {
    ...restProps,
    isSelected: value,
  };
  const state = useToggleState(updatedProps);
  const ref = useRef(null);
  const { inputProps } = useSwitch(updatedProps, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <label
      style={{
        display: "flex",
        alignItems: "center",
        opacity: updatedProps.disabled ? 0.4 : 1,
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <StyledSvg width="2.6rem" height="1.5rem" aria-hidden="true">
        <StyledBg fill={state.isSelected ? "var(--clr-primary-100, #5f57ff)" : "var(--clr-background-ukwn-4, #DFE1E7)"} />
        <StyledBall cx={state.isSelected ? "70%" : "30%"} cy={12} r="0.4rem" />
        {isFocusVisible && <StyledFocus x={1} y={1} rx={11} />}
      </StyledSvg>
    </label>
  );
};

export default Switch;
