"use client";

import React from "react";
import styled from "@emotion/styled";

import {
  DEVICE_BREAKPOINT,
  screenLargerThan,
  screenSmallerThan,
  useResponsive,
} from "@/styles";
import Card from "@/components/data/Card";

import ProfileTabs from "./components/ProfileTabs";
import ProfileFormMobile from "./ProfileForm.mobile";
import ProfileImageInput from "./components/inputs/ProfileImageInput";
import SocialLinksInput from "./components/inputs/SocialLinksInput";
import TimezoneInput from "./components/inputs/TimezoneInput";
import SaveButton from "./components/inputs/SaveButton";
import LanguagesInput from "./components/inputs/LanguagesInput";

export const StyledContainer = styled.div`
  hr {
    opacity: 0.75;
  }

  ${screenSmallerThan.widescreen} {
    padding: 1rem;
  }

  ${screenLargerThan.widescreen} {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    height: 100%;
    overflow: hidden;
    padding-bottom: 1rem;

    & > *:first-child {
      min-width: 24rem;
      width: 24rem;
    }

    & > *:last-child {
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
    }

    .card {
      padding: 2rem;
    }
  }
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  .card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;

    & > *:first-child {
      overflow: auto;
      flex-grow: 1;
      padding-right: 1rem;
      margin-right: -1rem;
    }
  }
`;

const ProfileForm: React.FC = () => {
  const { screenWidth } = useResponsive();

  return (
    <>
      {screenWidth < DEVICE_BREAKPOINT.widescreen ? (
        <>
          <ProfileFormMobile />
        </>
      ) : (
        <StyledContainer>
          <StyledLeftContainer>
            <Card className="card">
              <div>
                <ProfileImageInput />

                <TimezoneInput className="mt-4" />

                <LanguagesInput className="mt-4" />

                <SocialLinksInput className="mt-4" />
              </div>
            </Card>

            <SaveButton className="w-100 mt-3" />
          </StyledLeftContainer>

          <Card className="card">
            <ProfileTabs />
          </Card>
        </StyledContainer>
      )}
    </>
  );
};

export default ProfileForm;
