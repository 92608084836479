import React, { useCallback } from "react";

import RadioGroup, {
  RenderFunctionParams,
} from "@/components/input/RadioGroup";
import Icon from "@/components/misc/Icon";

import { StyledContainer, StyledItem } from "./RatingInput.styles";
import { IRatingInputProps } from "./RatingInput.types";
import { RATINGS } from "./RatingInput.config";

const RatingInput: React.FC<IRatingInputProps> = ({ className }) => {
  const render = useCallback(
    ({
      isSelected,
      labelProps,
      inputProps,
      inputRef,
      icon,
      disabled = false,
    }: RenderFunctionParams & { icon: string }) => {
      return (
        <StyledItem {...labelProps} $isSelected={isSelected}>
          <input {...inputProps} ref={inputRef} disabled={disabled} />
          <Icon src={icon} customSize="1.5rem" />
        </StyledItem>
      );
    },
    []
  );

  return (
    <StyledContainer
      label="rating"
      onChange={() => {}}
      className={className}
      renderRadio={render as any}
    >
      {RATINGS.map((item, i) => (
        <RadioGroup.Radio key={i} {...item} />
      ))}
    </StyledContainer>
  );
};

export default RatingInput;
