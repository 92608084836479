import React from "react";

import { IButtonProps } from "@/components/input/Button";
import ShareButton, { OnClick } from "@/features/media/ShareButton";
import {
  ContractCompleteDetails,
  getOfferShareContent,
} from "@/services/ContractsService";

const ContractShareButton: React.FC<
  {
    contractId: string;
    contractDetails?: ContractCompleteDetails;
    onClick?: OnClick;
  } & Omit<IButtonProps, "onClick">
> = ({
  contractId,
  contractDetails: contractDetailsFromProps,
  onClick,
  ...buttonProps
}) => {
  return (
    <ShareButton
      buttonProps={buttonProps}
      content={getOfferShareContent({ contractId })}
      onClick={onClick}
    />
  );
};

export default ContractShareButton;
