import { useMemo, useCallback, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { Body } from "@/components/Typography";

import useElementDimentions from "./hooks/useElementDimentions";

import {
  CellContentAlignment,
  CellContentVerticalAlignment,
  ITableProps,
} from "./Table.types";
import {
  StyledCellContent,
  StyledColumnResizer,
  StyledIconContainer,
  StyledTHead,
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledTr,
} from "./Table.styles";
import { ConvertToColumnDefs } from "./Table.utils";
import Icon from "@/components/misc/Icon";
import { ANIMATION_CLASSNAMES } from "@/styles";

function Table<T>({
  columns: columnsFromProps,
  data,
  className,
  fullWidth = true,
}: ITableProps<T>) {
  const columns = useMemo(
    () =>
      columnsFromProps
        .map((c) => ({ ...c, enableSorting: c.enableSorting || false }))
        .sort(function (a, b) {
          return +!!b.fixed - +!!a.fixed;
        })
        .map(ConvertToColumnDefs),
    [columnsFromProps]
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: "onChange",
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const [tableRef, setTableRef] = useState<HTMLTableElement | null>(null);
  const { width } = useElementDimentions(tableRef);

  //----------------------------

  const isColumnWidthAuto = useCallback((column: ColumnDef<T, unknown>) => {
    return column.size === undefined && column.maxSize === undefined;
  }, []);

  //----------------------------

  return (
    <StyledTable
      ref={setTableRef}
      className={className}
      style={
        {
          display: fullWidth ? "flex" : "inline-flex",
          "--table-visible-width": `${width}px`,
        } as React.CSSProperties
      }
    >
      <StyledTHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <StyledTr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const columnData: any = header.column.columnDef;
              const cellWidth = header.getSize();
              const cellWidthInRem = `${+cellWidth / 16}rem`;

              const verticalAlignment: CellContentVerticalAlignment =
                columnData.verticalAlignment;
              const alignment: CellContentAlignment = columnData.alignment;
              const alignmentStyles: React.CSSProperties = {};

              if (
                verticalAlignment === "bottom" ||
                verticalAlignment === "middle"
              ) {
                alignmentStyles.display = "flex";
                alignmentStyles.alignItems =
                  verticalAlignment === "middle" ? "center" : "flex-end";
              }

              if (alignment === "center") {
                alignmentStyles.justifyContent = "center";
              }

              const resizable: boolean = columnData.resizable;
              const canSort = header.column.getCanSort();
              const sortType = header.column.getIsSorted() as string;
              const sortIcon =
                sortType === "asc"
                  ? "sort_asc.svg"
                  : sortType === "desc"
                  ? "sort_dsc.svg"
                  : "sort_none.svg";
              const sortIconSize =
                sortType === "asc"
                  ? "0.725rem"
                  : sortType === "desc"
                  ? "0.725rem"
                  : "";

              return (
                <StyledTh
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: cellWidthInRem,
                    minWidth: cellWidthInRem,
                    flexGrow: isColumnWidthAuto(header.column.columnDef)
                      ? "1"
                      : "0",
                    textAlign: columnData.alignment,
                    ...(columnData.fixed && {
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                    }),
                    cursor: canSort ? "pointer" : "",
                    ...alignmentStyles,
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Body
                    size="md"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.75rem",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                    {canSort && (
                      <StyledIconContainer
                        key={sortType}
                        className={ANIMATION_CLASSNAMES.FADE_IN}
                      >
                        <Icon
                          isSrcRelative
                          src={sortIcon}
                          size="xs"
                          colorVariant="gray"
                          customSize={sortIconSize}
                        />
                      </StyledIconContainer>
                    )}

                    {resizable && (
                      <StyledColumnResizer
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                        }}
                      />
                    )}
                  </Body>
                </StyledTh>
              );
            })}
          </StyledTr>
        ))}
      </StyledTHead>

      <StyledTbody>
        {table.getRowModel().rows.map((row) => (
          <StyledTr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const cellWidth = cell.column.getSize();
              const cellWidthInRem = `${+cellWidth / 16}rem`;
              const columnData: any = cell.column.columnDef;

              const verticalAlignment: CellContentVerticalAlignment =
                columnData.verticalAlignment;
              const alignment: CellContentAlignment = columnData.alignment;
              const alignmentStylesStyles: React.CSSProperties = {};

              if (
                verticalAlignment === "bottom" ||
                verticalAlignment === "middle"
              ) {
                alignmentStylesStyles.display = "flex";
                alignmentStylesStyles.alignItems =
                  verticalAlignment === "middle" ? "center" : "flex-end";
              }

              if (alignment === "center") {
                alignmentStylesStyles.justifyContent = "center";
              }

              const fixed: boolean = columnData.fixed;

              return (
                <StyledTd
                  $fixed={fixed}
                  key={cell.id}
                  style={{
                    width: cellWidthInRem,
                    minWidth: cellWidthInRem,
                    flexGrow: isColumnWidthAuto(cell.column.columnDef)
                      ? "1"
                      : "0",
                    textAlign: columnData.alignment,
                    ...(columnData.fixed && {
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                    }),
                    ...alignmentStylesStyles,
                  }}
                >
                  <StyledCellContent size="md" className="w-100">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </StyledCellContent>
                </StyledTd>
              );
            })}
          </StyledTr>
        ))}
      </StyledTbody>
    </StyledTable>
  );
}

export default Table;
