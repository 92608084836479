"use client";

import styled from "@emotion/styled";

import { IStyledTagProps } from "./Tag.types";
import {
  DEFAULT_COLOR_VARIANT,
  DEFAULT_VARIANT,
  TAG_VARAINT_CONFIG,
} from "./Tag.config";
import { squareSizing } from "@/utils/css";

export const StyledContainer = styled.div<IStyledTagProps>`
  ${({ $variant = DEFAULT_VARIANT, $colorVariant = DEFAULT_COLOR_VARIANT }) => {
    const { color, bgColor, borderColor } =
      TAG_VARAINT_CONFIG[$colorVariant][$variant];
    return `
      --color: ${color};
      --border-color: ${borderColor};
      --bg-color: ${bgColor};
    `;
  }};

  ${({ $dotVariant }) =>
    $dotVariant
      ? `
        display: inline-flex;
        align-items: center;
        position: relative;
        font-size: 0.75rem;
        color: var(--bg-color);
        padding: 0.35rem 1rem;
        padding-left: 2rem;
        border-radius: 10rem;
        border: 1px solid var(--clr-border-25, #f6f8fa);
        box-shadow: 0px 0px 0px 1px #12376914, 0px 1px 2px 0px #a4acb93d;

        &:before {
          ${squareSizing("0.75rem")};
          content: "";
          position: absolute;
          top: 50%;
          left: 0.75rem;
          background: var(--bg-color);
          border-radius: 10rem;
          transform: translateY(-50%);
        }
    `
      : `
        color: var(--color);
        background: var(--bg-color);
        border: 2px solid var(--border-color);

        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        font-size: 0.75rem;
        font-weight: 600;
        padding: 2px 0.75rem;
        border-radius: 10rem;
        text-transform: capitalize;
      `};

  & > * {
    line-height: unset !important;
    min-width: max-content;
  }
`;
