"use client";

import { Link } from "react-router-dom";

import { Body, Label } from "@/components/Typography";
import styled from "@emotion/styled";
import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import { overflowEllipsis } from "@/styles";
import ShareButton from "@/features/media/ShareButton";

export const StyledCard = styled.div<{ $active?: boolean }>`
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  background: ${({ $active }) =>
    $active ? "var(--clr-primary-100,#5f57ff)" : "#fff"};

  ${({ $active }) =>
    $active
      ? `
          & > * {
            filter: brightness(0) invert(1);
          }
        `
      : ""}

  button:not(.util-button) {
    position: relative;
    z-index: 3;
    padding: 0.25rem 1rem;
    min-width: fit-content;
    border-radius: 10rem;
  }

  .status {
    padding: 0.25rem 0.5rem;
    padding-left: 1.75rem;
    margin-bottom: 0.25rem;

    &:before {
      left: 0.5rem;
    }
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledTopContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-self: center;
  overflow: hidden;
`;

export const StyledPrimaryText = styled(Label)`
  flex: 1;
  ${overflowEllipsis()};
`;

export const StyledButton = styled(Button)`
  ${squareSizing("1.5rem")};
  position: relative;
  top: -0.25rem;
  border: 1.5px solid var(--clr-border-ukwn-11, #f2f2f2);
  padding: 0;
  z-index: 2;
`;

export const StyledShareButton = styled(ShareButton)`
  ${squareSizing("1.5rem")};
  position: relative;
  border: 1.5px solid var(--clr-border-ukwn-11, #f2f2f2);
  padding: 0;
  z-index: 2;
`;

export const StyledSecodaryText = styled(Body)`
  flex: 1;
  color: var(--clr-icon-text-200, #818898);
  ${overflowEllipsis()};
  max-width: calc(100% - 3.5rem);
`;

export const StyledValue = styled.div`
  position: relative;
  bottom: -0.35rem;
  color: var(--clr-text-900, #0d0d12);
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  margin-top: 0.5rem;
`;
