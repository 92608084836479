"use client";

import React, { useCallback } from "react";
import styled from "@emotion/styled";
import isFunction from "lodash/isFunction";

import Modal, {
  IModalProps,
  useModalState,
  State,
} from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import Button, { IButtonProps } from "@/components/input/Button";
import { screenSmallerThan } from "@/styles";

import EditButton from "./EditButton";
import Form, {
  ExtraFields,
  FormValues,
  IFormProps,
  RenderDataParams,
} from "@/components/input/Form";
import AddButton, { IAddButtonProps } from "./AddButton";

const StyledDescription = styled(Body)`
  color: #64748b;
`;

const StyledModal = styled(Modal)`
  ${screenSmallerThan.tablet} {
    padding: 0;
  }
`;

const StyledCtaSection = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  background: #fff;
  padding-top: 1rem;
  margin-top: auto;

  .cancel-button {
    --bg-color: #f5f5f5 !important;
    --border-color: #f5f5f5 !important;
  }

  button {
    padding: 0.75rem 1.5rem;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    overflow: auto;
  }

  ${screenSmallerThan.tablet} {
    min-height: 100vh;
    overflow: hidden;
    padding-block: 1rem;

    & > * {
      padding-inline: 1rem;
    }
  }
`;

type Content<T, U> =
  | React.ReactNode
  | ((data: RenderDataParams<T, U>) => React.ReactNode);

interface IEditFormButtonProps<T, U> {
  content?: Content<T, U>;
  editButtonProps?: Omit<IButtonProps, "children">;
  cancelButtonProps?: Omit<IButtonProps, "onClick"> & {
    onClick?: () => Promise<any>;
  };
  saveButtonProps?: Omit<IButtonProps, "onClick"> & {
    onClick?: () => Promise<any>;
  };
  heading?: React.ReactNode;
  description?: React.ReactNode;
  modalProps?: Omit<IModalProps, "state">;
  formProps?: IFormProps<T, U>;
  modalState?: State;
  dashedVariantProps?: IAddButtonProps;
}

function EditFormButton<T extends FormValues, U = ExtraFields>({
  content,
  editButtonProps = {},
  cancelButtonProps = {},
  saveButtonProps = {},
  heading = "Edit",
  description = `Make changes to user profile here. Click save when you're done.`,
  modalProps = {},
  formProps,
  modalState: modalStateFromProps,
  dashedVariantProps,
}: IEditFormButtonProps<T, U>) {
  const modalStateLocal = useModalState();
  const modalState = modalStateFromProps || modalStateLocal;

  const renderContent = useCallback(
    (content: Content<T, U>, formProps?: RenderDataParams<T, U>) => (
      <StyledContent>
        {(!!heading || !!description) && (
          <div className="header">
            {!!heading && (
              <Heading size="lg" className="mb-2">
                {heading}
              </Heading>
            )}
            {!!description && (
              <StyledDescription size="md" className="mb-4 description">
                {description}
              </StyledDescription>
            )}
          </div>
        )}

        <div className="content mb-2">
          {/* @ts-ignore */}
          {isFunction(content) && !!formProps ? content(formProps) : content}
        </div>

        <StyledCtaSection>
          <Button
            colorVariant="gray"
            className="cancel-button"
            {...cancelButtonProps}
            type={formProps ? "reset" : "button"}
            disabled={cancelButtonProps.disabled}
            onClick={() => {
              if (cancelButtonProps.onClick) {
                cancelButtonProps.onClick().then(() => {
                  modalState.close();
                });
              } else {
                modalState.close();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            {...saveButtonProps}
            type={formProps ? "submit" : "button"}
            colorVariant={formProps?.context.hasError ? "disabled" : "primary"}
            disabled={
              formProps
                ? formProps.context.hasError
                  ? false
                  : saveButtonProps.disabled
                : saveButtonProps.disabled
            }
            onClick={() => {
              if (formProps) {
                return;
              }

              if (saveButtonProps.onClick) {
                saveButtonProps.onClick().then(() => {
                  modalState.close();
                });
              } else {
                modalState.close();
              }
            }}
          >
            Save
          </Button>
        </StyledCtaSection>
      </StyledContent>
    ),
    [heading, description, cancelButtonProps, saveButtonProps, modalState]
  );

  return (
    <>
      {dashedVariantProps ? (
        <AddButton
          {...editButtonProps}
          {...dashedVariantProps}
          onClick={modalState.open}
        />
      ) : (
        <EditButton {...editButtonProps} onClick={modalState.open} />
      )}

      <StyledModal
        showCloseButton
        {...modalProps}
        allowScroll={false}
        state={modalState}
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        mobileProps={{ fullscreen: true }}
      >
        {modalState.isOpen ? (
          formProps ? (
            <Form<T, U> {...formProps}>
              {(formParams) => {
                return (
                  <form onSubmit={formParams.context.submitForm}>
                    {renderContent(content, formParams)}
                  </form>
                );
              }}
            </Form>
          ) : (
            renderContent(content)
          )
        ) : null}
      </StyledModal>
    </>
  );
}

export default EditFormButton;
