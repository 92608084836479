import React from "react";

import Icon from "@/components/misc/Icon";

import {
  StyledAvatar,
  StyledButton,
  StyledContainer,
  StyledMiddleSection,
  StyledUserName,
  StyledUserType,
} from "./UserInfo.styles";
import { IUserInfoProps } from "./UserInfo.types";
import RatingDisplay from "../RatingDisplay";

const UserInfo: React.FC<IUserInfoProps> = ({
  image,
  userType,
  userName,
  chatUrl,
  className,
  ratingDisplayProps,
  showChat = true,
  email,
}) => {
  const username = userName || email || "";

  return (
    <StyledContainer className={className}>
      <StyledAvatar img={image} firstName={userName || email} />

      <StyledMiddleSection>
        <StyledUserType>{userType}</StyledUserType>
        {!!username && (
          <StyledUserName className="mt-1">{username}</StyledUserName>
        )}
        {!!ratingDisplayProps && <RatingDisplay {...ratingDisplayProps} />}
      </StyledMiddleSection>

      {showChat && (
        <StyledButton link={chatUrl}>
          <Icon isSrcRelative src="chat_gradient.svg" />
        </StyledButton>
      )}
    </StyledContainer>
  );
};

export default UserInfo;
