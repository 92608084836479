import { SocialLink } from "@/services/UserService";

import { SOCIAL_LINKS } from "./SocialLinksInput.config";

const platformNames = Object.values(SOCIAL_LINKS).map(
  (item) => item.platformName
);

export function isSocialCustomLink(link: SocialLink) {
  return !platformNames.includes(link.name);
}

export function generatePredefinedLinksMap(links: SocialLink[]) {
  const map: Record<string, SocialLink & { index: number }> = {};

  links.forEach((link, i) => {
    if (!isSocialCustomLink(link)) {
      map[link.name] = {
        index: i,
        ...link,
      };
    }
  });

  return map;
}

export function generateCustomLinksList(links: SocialLink[]) {
  const customLinks: (SocialLink & { index: number })[] = [];

  links.forEach((link, i) => {
    if (isSocialCustomLink(link)) {
      customLinks.push({
        ...link,
        index: i,
      } satisfies SocialLink & { index: number });
    }
  });

  return customLinks;
}

export function getLinkIcon(link: SocialLink) {
  const icon =
    SOCIAL_LINKS.find(({ platformName }) => platformName === link.name)?.icon ||
    "link.svg";

  return icon;
}
