import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import ContractChatButton from "@/features/contracts/ContractChatButton";
import { getViewPortHeightCssString } from "@/styles";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  padding: 1rem;
  padding-top: 3rem;
`;

export const StyledTopContainer = styled.div`
  flex: 1;

  & > div > p {
    max-width: 32ch;
  }
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  gap: 0.5rem;
  padding-block: 1rem;
`;

export const StyledChatButton = styled(ContractChatButton)`
  font-weight: 600;
  gap: 0.5rem;
  padding-block: 1rem;
  color: var(--clr-text-900, #0d0d12);
`;
