import {
  useOnboardStripe,
  usePayoutOnboardStatus,
} from "@/services/UserService";
import { IActionCardProps } from "@/components/misc/ActionCard";
import { SITE_PATHS } from "@/config/routing";
import Icon from "@/components/misc/Icon";
import SplashScreen from "@/features/pages/app/SplashScreen";

function useOnboardingAction() {
  const { onboardingCountry, promptIdentityVerification } =
    usePayoutOnboardStatus();
  const { isReady, canOnboard, onboard, isOnboarding } = useOnboardStripe();

  if (!isReady || !canOnboard) {
    return null;
  }

  const ctaProps = {
    children: promptIdentityVerification
      ? "Complete verification"
      : "Set up Payments",
    ...(onboardingCountry
      ? { onClick: () => onboard({ countryCode: onboardingCountry }) }
      : {
          link: SITE_PATHS.PAYOUT_ONBOARDING_PAGE,
        }),
  };
  const icon = promptIdentityVerification ? (
    <Icon isSrcRelative src="verification_required.svg" customSize="1.5rem" />
  ) : (
    <Icon isSrcRelative src="alert_round.svg" customSize="3rem" />
  );
  const content = promptIdentityVerification
    ? "You need to complete the identity verification to receive future payouts."
    : "Set up your payment information so you can receive your earnings.";

  return {
    icon,
    title: promptIdentityVerification && "Complete verification",
    content: (
      <>
        {content}
        {isOnboarding && <SplashScreen />}
      </>
    ),
    color: "#F1F5F9",
    ctaProps,
    priority: promptIdentityVerification ? "urgent" : undefined,
  } satisfies IActionCardProps;
}

export default useOnboardingAction;
