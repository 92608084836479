import React from "react";
import styled from "@emotion/styled";

import {
  getPackageDurationFormatted,
  MediaFile,
  Package,
} from "@/services/UserService";
import { useFileSignedUrl } from "@/services/ContractsService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Slider from "@/components/misc/Slider";
import { overflowEllipsis } from "@/styles";
import Modal, { useModalState } from "@/components/misc/Modal";
import PackagePreview from "@/features/PaypipeId/PackagePreview";

//-----------------------------

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background: #ffffff;
  padding: 1rem;

  .actions-section {
    position: relative;
    z-index: 2;
    pointer-events: none !important;

    & > * {
      pointer-events: all;
    }
  }

  .image {
    position: relative;
    height: 17.625rem;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem;
    background-color: #f1f1f1;
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    ${overflowEllipsis()};
  }

  .description {
    font-size: 1.25rem;
    font-weight: 400;
    ${overflowEllipsis()};
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .label {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .value {
    font-size: 2rem;
    font-weight: 400;
    color: var(--clr-primary-100, #5f57ff);
  }

  .actions-section {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.5rem;
  }
`;

const StyledActionButton = styled(Button)`
  ${squareSizing("2.25rem")};
  border-radius: 10rem;
`;

const StyledImage = styled.div`
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44));
  background-size: cover;
  background-position: center;
  height: 17.625rem;
`;

const StyledImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 17.625rem;
  border-radius: inherit;
  z-index: 1;

  & > * {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }
`;

//-----------------------------

type IPackageCardProps = {
  package: Package;
  className?: string;
  canEdit?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  style?: React.CSSProperties;
};

//-----------------------------

const Image: React.FC<{ image: MediaFile }> = ({ image }) => {
  const { url, isLoading } = useFileSignedUrl(image.url || "");

  if (isLoading) {
    return (
      <ShimmerLoader.Default
        style={{ height: "20.625rem", borderRadius: "0.625rem" }}
      />
    );
  }

  return (
    <StyledImage
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44)), url(${url})`,
      }}
    />
  );
};

const Images: React.FC<{ images: MediaFile[] }> = ({ images }) => {
  if (images.length === 1) {
    const firstImage = images[0];
    return (
      <StyledImages>
        <Image key={firstImage.id} image={firstImage} />
      </StyledImages>
    );
  }

  return (
    <StyledImages>
      <Slider>
        {images.map((image) => (
          <Image key={image.id} image={image} />
        ))}
      </Slider>
    </StyledImages>
  );
};

const PackageCard: React.FC<IPackageCardProps> = ({
  package: packageDetails,
  className,
  canEdit,
  onDelete,
  onEdit,
  style,
}) => {
  const modalState = useModalState();
  const { name, attachments = [], description, price } = packageDetails;

  return (
    <>
      {!canEdit && (
        <Modal
          showCloseButton
          state={modalState}
          mobileProps={{ fullscreen: true }}
          width="968px"
        >
          <PackagePreview package={packageDetails} />
        </Modal>
      )}

      <StyledContainer
        className={className}
        onClick={() => {
          if (!canEdit) {
            modalState.open();
          }
        }}
        style={style}
      >
        <div className="image">
          <Images images={attachments} />

          {canEdit && (!!onEdit || !!onDelete) && (
            <div className="actions-section">
              {!!onEdit && (
                <StyledActionButton
                  colorVariant="white-primary"
                  onClick={onEdit}
                >
                  <Icon
                    isSrcRelative
                    src="edit.svg"
                    size="xxs"
                    colorVariant="black"
                  />
                </StyledActionButton>
              )}
              {!!onDelete && (
                <StyledActionButton
                  colorVariant="white-primary"
                  onClick={onDelete}
                >
                  <Icon
                    isSrcRelative
                    src="delete.svg"
                    size="xxs"
                    colorVariant="black"
                  />
                </StyledActionButton>
              )}
            </div>
          )}
        </div>

        <div className="mt-4">
          <div className="title">{name}</div>
          <div className="description mt-2">{description}</div>

          <div className="details mt-3">
            <div>
              <div className="label">Fixed price</div>
              <div className="value mt-2">${price}</div>
            </div>

            <div>
              <div className="label">Delivery Time</div>
              <div className="value mt-2">
                {getPackageDurationFormatted(packageDetails)}
              </div>
            </div>
          </div>
        </div>
      </StyledContainer>
    </>
  );
};

export default PackageCard;
