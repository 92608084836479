import React from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";
import Icon from "@/components/misc/Icon";
import { Label } from "@/components/Typography";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const ProfileSummarySection: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <StyledContainer className={className}>
      <div>
        {true ? (
          <Button
            variant="secondary"
            colorVariant="gray"
            link={SITE_PATHS.EDIT_PROFILE_PAGE}
            style={{ width: "227px", maxWidth: "100%" }}
          >
            <Icon isSrcRelative size="xs" src="edit.svg" /> Edit
          </Button>
        ) : (
          <>
            <Button link="#" style={{ width: "227px", maxWidth: "100%" }}>
              <Icon isSrcRelative size="xs" src="rocket.svg" /> Contact
            </Button>
            <Label
              className="mt-1"
              size="sm"
              style={{
                color: "var(--clr-icon-text-200, #818898)",
                textAlign: "center",
              }}
            >
              Typically responds within 24 hours
            </Label>
          </>
        )}
      </div>
    </StyledContainer>
  );
};

export default ProfileSummarySection;
