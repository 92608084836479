import React from "react";

import styled from "@emotion/styled";
import { squareSizing } from "@/utils/css";

const StyledLoader = styled.div<{
  $size: string;
  $color: string;
  $active: boolean;
}>`
  position: relative;
  display: inline-block;
  border-radius: 10rem;
  background: ${({ $color }) => $color};
  ${({ $size }) => squareSizing($size)}

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: inherit;
    transform: translate(-50%, -50%);
    ${({ $size }) => squareSizing($size)}
    background: ${({ $color }) => $color};
    opacity: 0.3;
    z-index: 0;
    -webkit-animation: pulse 750ms ease infinite;
    animation: pulse 750ms ease infinite;

    ${({ $active }) =>
      $active
        ? `
          `
        : `
            display: none;
          `};
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.35;
    }
    100% {
      transform: translate(-50%, -50%) scale(2.25);
      opacity: 0.05;
    }
  }
`;

const PulseLoader: React.FC<{
  size?: string;
  color?: string;
  className?: string;
  active?: boolean;
}> = ({ size = "0.65rem", color = "red", className, active = true }) => {
  return (
    <StyledLoader
      $size={size}
      $color={color}
      $active={active}
      className={className}
    />
  );
};

export default PulseLoader;
