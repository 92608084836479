"use client";

import React, { useEffect, useMemo } from "react";

import {
  getContractOtherParticipant,
  useContractList,
  useManageContract,
} from "@/services/ContractsService";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import { Display } from "@/components/Typography";
import { SITE_PATHS } from "@/config/routing";
import { getChatRoomPageUrl } from "@/services/ChatService";
import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  isUserChannel as checkIsUserChannel,
  useChatIdFromUrl,
} from "@/services/ChatServiceNew";
import { useHash, useParamState } from "@/services/Routing";
import { IBreadcrumbItemProps } from "@/components/navigation/Breadcrumb";
import { appFeatures } from "@/config/services";
import { getUserFullName } from "@/services/UserService";

import {
  NoChatContainer,
  StyledCardSection,
  StyledChatRoomPage,
  StyledChatRoomSection,
  StyledContainer,
  StyledTitleContainer,
} from "./ChatsPage.styles";
import ChatList from "./components/ChatList";
import SearchInput from "./components/SearchInput";
import NoChats from "./components/NoChats";
import { CHAT_DETAILS_HASH, CONTRACT_DETAILS_HASH } from "../ChatRoomPage";
import ChatTypeFilter from "./components/ChatTypeFilter";

const ChatsPage: React.FC = () => {
  const { hash } = useHash();
  const { data: contractList, isLoading } = useContractList();

  const [channelTypeFilter, setChannelTypeFilter] = useParamState<string>(
    CHANNEL_TYPE_FILTER_URL_KEY,
    "",
    {
      parseJson: false,
    }
  );
  const { idFromUrl: channelId } = useChatIdFromUrl();
  const isUserChannel = useMemo(
    () => checkIsUserChannel({ channelId }),
    [channelId]
  );

  const { contractId: contractIdFromUrl, contractDetails } = useManageContract({
    redirectIfNotFound: false,
    useContractIdFromUrl: !isUserChannel,
  });

  const hasNoData = !contractList.length && !isLoading;

  const breadCrumbs = useMemo(() => {
    const items: IBreadcrumbItemProps[] = [];

    if (contractIdFromUrl) {
      items.push({
        ariaLabel: "Chat list page",
        children: "Chats",
        href: SITE_PATHS.CHAT_PAGE,
      });

      if (contractDetails) {
        const otherParticipant = getContractOtherParticipant(contractDetails);
        let title = "";

        if (otherParticipant) {
          title = getUserFullName(otherParticipant.user);
        }

        if (!title) {
          title = contractDetails?.title || "";
        }

        items.push({
          ariaLabel: "Chat room page",
          children: title,
          href: getChatRoomPageUrl(contractIdFromUrl),
        });

        if (hash) {
          if (hash === CHAT_DETAILS_HASH) {
            items.push({
              ariaLabel: "Chat details",
              children: "Details",
              href: `${getChatRoomPageUrl(
                contractIdFromUrl
              )}${CHAT_DETAILS_HASH}`,
            });
          }

          if (hash === CONTRACT_DETAILS_HASH) {
            items.push({
              ariaLabel: "Job details",
              children: "Job",
              href: `${getChatRoomPageUrl(
                contractIdFromUrl
              )}${CONTRACT_DETAILS_HASH}`,
            });
          }
        }
      }
    }

    return items;
  }, [contractIdFromUrl, contractDetails, hash]);

  useEffect(() => {
    if (!channelId && !channelTypeFilter) {
      setChannelTypeFilter(ChannelType.Contract);
    }
  }, [channelId, channelTypeFilter, setChannelTypeFilter]);

  const noChatJsx = (
    <NoChatContainer>
      <NoChats />
    </NoChatContainer>
  );
  const chatJsx = (
    <StyledContainer>
      <StyledCardSection>
        <StyledTitleContainer>
          <Display size="md">Chats</Display> <ChatTypeFilter />
        </StyledTitleContainer>

        <SearchInput />
        <ChatList />
      </StyledCardSection>

      <StyledChatRoomSection>
        {channelId && <StyledChatRoomPage />}
      </StyledChatRoomSection>
    </StyledContainer>
  );

  return (
    <DesktopPrimaryLayout
      sidebarCompact
      headerProps={{ logo: { component: "Inbox" } }}
      breadcrumbs={breadCrumbs}
    >
      {hasNoData
        ? appFeatures.isSupported("CHAT.USE_STREAMIO")
          ? chatJsx
          : noChatJsx
        : chatJsx}
    </DesktopPrimaryLayout>
  );
};

export default ChatsPage;
