import styled from "@emotion/styled";

import Wysiwyg from "@/components/input/LexicalWysiwyg";

export const StyledContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 20rem;
`;
 
export const StyledEditor = styled(Wysiwyg)`
  position: unset;
  height: unset;
  flex: 1;
  min-height: 50%;
`;
 