import useSWR from "swr";

import { userService } from "@/config/services";

function useClientCountry() {
  const { data, error, isLoading } = useSWR("/client-country", () =>
    userService.getClientCountry().then((res) => res.data.country),
  );

  const isUpdating = data !== undefined && isLoading;

  //-----------------------

  return {
    countryCode: data || null,
    error,
    isLoading,
    isUpdating,
  };
}

export default useClientCountry;
