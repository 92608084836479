import React, { useMemo } from "react";
import lodashGet from "lodash/get";

import Avatar from "@/components/misc/Avatar";
import { getChatRoomPageUrl } from "@/services/ChatService";
import { getUserFullName, useUserProfile } from "@/services/UserService";
import { USER_CHANNEL_SHORT_REGEX } from "@/services/ChatServiceNew";
import { getMatches } from "@/utils/string";
import { useAuth } from "@/services/Authentication";
import ShowMore from "@/components/input/ShowMore";
import { formatDate } from "@/utils/date";

import {
  StyledContent,
  StyledLeftSection,
  StyledBottomContainer,
  StyledRightSection,
  StyledTopContainer,
  StyledLink,
  StyledButton,
  StyledCard,
  StyledUsername,
  StyledDate,
} from "./ChatCard.styles";
import { IChatCardProps } from "./ChatCard.types";

const ChatCard: React.FC<IChatCardProps> = ({
  // hasAttachment,
  unreadMessageCount,
  content,
  className,
  openInNewTab,
  active = false,
  channel_id: channelId = "",
  extraData,
  lastMessageDate,
}) => {
  const { loggedInUserId } = useAuth();

  const userIds: number[] = useMemo(() => {
    if (!channelId) {
      return [];
    }

    const match = channelId.matchAll(USER_CHANNEL_SHORT_REGEX);
    const members = match
      ? (
          lodashGet(getMatches(channelId, USER_CHANNEL_SHORT_REGEX), "[0]") ||
          ""
        )
          .split("_")
          .map(Number)
      : [];

    return members;
  }, [channelId]);

  const otherUserId = useMemo(() => {
    return userIds.find((id) => id !== loggedInUserId) || 0;
  }, [userIds, loggedInUserId]);
  const requestUserId = lodashGet(extraData, "requestUserId", 0) || otherUserId;

  const {
    accountData: otherUserDetails,
    isLoading: isLoadingOtherUserDetails,
  } = useUserProfile(otherUserId);

  const username = otherUserDetails ? getUserFullName(otherUserDetails) : "";
  const avatarImage = otherUserDetails?.picture || "";

  const chatRoomLink = useMemo(() => {
    const match = channelId.matchAll(USER_CHANNEL_SHORT_REGEX);
    const userChannelId = match
      ? lodashGet(getMatches(channelId, USER_CHANNEL_SHORT_REGEX), "[0]")
      : "";

    if (userChannelId) {
      return getChatRoomPageUrl(userChannelId);
    }

    return "";
  }, [channelId]);

  const formattedLastMessageDate = useMemo(
    () =>
      lastMessageDate ? formatDate(lastMessageDate, "H:MM A") : lastMessageDate,
    [lastMessageDate]
  );

  if (isLoadingOtherUserDetails || !otherUserId) {
    return null;
  }

  return (
    <StyledCard $active={active} className={className}>
      <StyledLink
        to={chatRoomLink}
        target={openInNewTab ? "_blank" : "_self"}
      />

      <StyledTopContainer style={{ alignItems: "center" }}>
        <StyledLeftSection>
          <Avatar className="avatar" img={avatarImage} firstName={username} />
        </StyledLeftSection>

        <StyledRightSection>
          <StyledUsername>{username}</StyledUsername>

          {!!formattedLastMessageDate && (
            <StyledDate>{formattedLastMessageDate}</StyledDate>
          )}
        </StyledRightSection>
      </StyledTopContainer>

      {(!!content || !!unreadMessageCount) && (
        <StyledBottomContainer>
          {(!!content || !!unreadMessageCount) && (
            <StyledRightSection>
              {!!content && (
                <StyledContent className="content">
                  <ShowMore maxHeight="4.5em">{content}</ShowMore>
                </StyledContent>
              )}
            </StyledRightSection>
          )}
        </StyledBottomContainer>
      )}

      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <StyledButton
          colorVariant={active ? "white-primary" : "primary"}
          userId={requestUserId}
        >
          Accept & Reply
        </StyledButton>
      </div>
    </StyledCard>
  );
};

export default ChatCard;
