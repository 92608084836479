"use client";

import React from "react";
import { createPortal } from "react-dom";

import { IPortalProps } from "./Portal.types";

const Portal: React.FC<IPortalProps> = ({
  children,
  container = window.document.body,
  // container = window.document.getElementById("portal-container"),
}) => {
  return createPortal(<>{children}</>, container);
};

export default Portal;
