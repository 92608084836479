import { ApiResponse, IApiService } from "@/services/Api";
import { mediaActions, store } from "@/services/Store";
import { MilestoneId } from "@/services/ContractsService";
import { errorReporting } from "@/config/services";

import { MediaFile, MediaFileFilters } from "./MediaService.types";
import { mediaFiltersToQueryParams } from "./MediaService.utils";

class MediaService {
  _apiService: IApiService;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
  }

  //-------------------

  uploadFile = (
    file: File,
    data: {
      contractId?: string;
      milestoneId?: MilestoneId;
    } = {}
  ) => {
    const { contractId, milestoneId } = data;

    const formdata = new FormData();
    formdata.append("image", file, file.name);

    if (contractId) {
      formdata.append("contract_id", contractId);
    }

    if (milestoneId) {
      formdata.append("milestone_id", `${milestoneId}`);
    }

    return this._apiService
      .post<ApiResponse<MediaFile>>("/upload", formdata)
      .catch((err) => {
        try {
          throw new Error("File upload error");
        } catch (err) {
          errorReporting.report({
            err,
            file: {
              name: file.name,
              lastModified: file.lastModified,
              lastModifiedDate: (file as any)?.lastModifiedDate,
              size: file.size,
              type: file.type,
            },
          });
        }

        return err;
      });
  };

  fetchFileList = (filters?: MediaFileFilters) => {
    const query = mediaFiltersToQueryParams(filters);

    return this._apiService
      .get<ApiResponse<MediaFile[]>>(`/media?${query}`)
      .then((res) => {
        store.dispatch(mediaActions.addMediaFiles({ data: res.data.data }));

        return res;
      });
  };

  fetchFilePresignedUrl = (fileKey: string) => {
    return this._apiService.anonPost<ApiResponse<{ url: string }>>(
      "/media/getPresignedUrl",
      {
        key: fileKey,
      }
    );
  };
}

export default MediaService;
