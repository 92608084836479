"use client";

import styled from "@emotion/styled";

import { getViewPortHeightCssString } from "@/styles";
import { getViewportBottom } from "@/utils/css";

export const StyledContainer = styled.div``;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
`;

export const StyledMain = styled.main`
  min-height: ${getViewPortHeightCssString()};
  padding-top: 75px;
  padding-bottom: 63px;
`;

export const StyledHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  transition: transform 180ms ease-out;

  &.scroll-out {
    transform: translateY(-100%);
  }
`;

export const StyledTabBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  bottom: ${getViewportBottom()};
  width: 100%;
  z-index: 5;
  transition: transform 180ms ease-out;

  &.scroll-out {
    transform: translateY(100%);
  }
`;
