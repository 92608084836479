"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { overflowEllipsis } from "@/styles";

export const StyledName = styled.div`
  display: flex;
  align-items: center;

  & > * {
    color: inherit;
    ${overflowEllipsis()};
  }

  b {
    ${overflowEllipsis()};
  }
`;

export const StyledCellLink = styled(Link)`
  position: absolute;
  padding: 1rem 0.75rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
