"use client";

import React from "react";

import {
  getContractPageUrl,
  useAutoFixContractUrl,
  useManageContract,
} from "@/services/ContractsService";
import ContractManagementPage from "@/features/pages/contract/ContractManagementPage";
import { SITE_PATHS } from "@/config/routing";
import ContractPaymentRedirectPage from "@/features/pages/contract/ContractPaymentRedirectPage";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import SplashScreen from "@/features/pages/app/SplashScreen";

import {
  StyledCardSection,
  StyledContractList,
  StyledInputsContainer,
  StyledManagmentSection,
  StyledPage,
} from "./ContractsListPage.styles";
import ContractTabs from "./components/ContractTabs";
import ContractFilters from "./components/ContractFilters";
import NoContracts from "./components/NoContracts";

const ContractsListPage: React.FC = () => {
  const {
    isPaymentRedirect,
    contractId: contractIdFromUrl,
    contractDetails,
  } = useManageContract({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
  });
  const { isLoading: isLoadingFromAutofixHook } = useAutoFixContractUrl();

  const showPaymentRedirectPage = isPaymentRedirect;

  if (showPaymentRedirectPage) {
    return <ContractPaymentRedirectPage />;
  }

  if (isLoadingFromAutofixHook) {
    return <SplashScreen />;
  }

  return (
    <DesktopPrimaryLayout
      headerProps={{ logo: { component: "Jobs" } }}
      breadcrumbs={
        contractIdFromUrl
          ? [
              {
                ariaLabel: "Contract list page",
                children: "Jobs",
                href: SITE_PATHS.CONTRACTS_PAGE,
              },
              {
                ariaLabel: "Contract details page",
                children: contractDetails?.title,
                href: getContractPageUrl(contractIdFromUrl),
              },
            ]
          : []
      }
    >
      <StyledPage>
        <StyledCardSection>
          <StyledInputsContainer className="mb-3">
            <ContractTabs />
            <ContractFilters />
          </StyledInputsContainer>

          <NoContracts />
          <StyledContractList className="scrollbar-md" />
        </StyledCardSection>

        <StyledManagmentSection>
          {!!contractIdFromUrl && (
            <ContractManagementPage
              layoutProps={{
                headerProps: {
                  backButtonProps: {
                    onClick: () => {},
                    link: SITE_PATHS.CONTRACTS_PAGE,
                  },
                },
              }}
            />
          )}
        </StyledManagmentSection>
      </StyledPage>
    </DesktopPrimaryLayout>
  );
};

export default ContractsListPage;
