import React from "react";

import {
  StyledContainer,
  StyledContent,
  StyledPrimaryText,
  StyledRow,
  StyledSecondaryText,
  StyledSmallText,
} from "./ReviewCard.styles";
import Avatar from "@/components/misc/Avatar";

const ReviewCard: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <StyledRow>
        <Avatar initials="FH" />
        <StyledPrimaryText size="md">Francois Hanna</StyledPrimaryText>
      </StyledRow>

      <StyledRow className="mt-2">
        <StyledSecondaryText size="sm">United States</StyledSecondaryText>
        <StyledSmallText>20 Feb 2022</StyledSmallText>
      </StyledRow>

      <StyledContent size="md" className="mt-2">
        So far so good! The only issue that I can notive so far, I'm for the 80%
        of the job, however the team haven't delivered 50% of the job yet.
        Hopefully will be ok at the end
      </StyledContent>
    </StyledContainer>
  );
};

export default ReviewCard;
