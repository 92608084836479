import Modal, { useModalState } from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { StyledContainer, StyledContent } from "./usePwaInstall.styles";
import { Heading } from "@/components/Typography";
import { useHash } from "@/services/Routing";
import { analytics } from "@/config/services";

import { usePwa } from "../Pwa.context";

const usePwaInstall = () => {
  const { canInstall, triggerInstall, isPwaInstalled } = usePwa();
  const { hash, removehash } = useHash();

  const autoInstall = hash === "#install" && canInstall && !isPwaInstalled;
  const modalState = useModalState({
    defaultOpen: autoInstall,
    onOpenChange: (isOpen) => {
      if (isOpen) {
        analytics.triggerEvent({
          event: "opened_pwa_install_prompt",
        });
      } else {
        removehash();
      }
    },
  });

  const jsx = (
    <Modal state={modalState} width="410px" className="p-3">
      <StyledContainer>
        <Icon
          isSrcRelative
          customSize="2.5rem"
          className="icon"
          src="clock_green.svg"
        />
        <Button
          className="close-button"
          variant="ghost"
          onClick={modalState.close}
        >
          <Icon isSrcRelative size="xs" src="cross.svg" />
        </Button>

        <Heading size="md">Install app</Heading>
        <StyledContent size="lg" className="mt-2">
          Get the most out of Paypipe and stay on top of your jobs on the go.
        </StyledContent>

        <Button
          className="mt-4 w-100"
          onClick={() => {
            modalState.close();
            triggerInstall();
            analytics.triggerEvent({
              event: "clicked_install_pwa",
            });
          }}
        >
          Install
        </Button>
      </StyledContainer>
    </Modal>
  );

  return { jsx };
};

export default usePwaInstall;
