"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import lodashGet from "lodash/get";

import { ContractStatus } from "@/services/ContractsService";
import { squareSizing } from "@/utils/css";

import { COLORS, STATUS_CONFIG } from "./ContractsOverview.config";
import Tag from "@/components/misc/Tag";
import { screenSmallerThan } from "@/styles";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-height: 13rem;
`;

export const StyledField = styled.div<{ $status: ContractStatus }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-block: 0.5rem;
  border-bottom: 2px solid
    ${({ $status }) => {
      const color = lodashGet(STATUS_CONFIG, `[${$status}].color`, COLORS.RED);
      return color;
    }};
  margin-bottom: 0.25rem;
  text-transform: capitalize;
`;

export const StyledLabel = styled.div<{ $status: ContractStatus }>`
  position: relative;
  font-size: 0.625rem;
  letter-spacing: -0.02em;
  font-weight: 500;
  padding-left: 1rem;
  color: var(--clr-icon-text-200, #818898);

  &:before {
    content: "";
    ${squareSizing("8px")};
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 4rem;
    background: ${({ $status }) => {
      const color = lodashGet(STATUS_CONFIG, `[${$status}].color`, COLORS.RED);
      return color;
    }};
    transform: translateY(calc(-50% - 1px));
  }
`;

export const StyledFieldsSection = styled.div`
  flex-grow: 1;
`;

export const StyledLink = styled(Link)`
  color: var(--clr-text-900, #0d0d12) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: capitalize;
`;

export const StyledComparisonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.7rem;
  color: var(--clr-text-ukwn-9, #878787);
  font-weight: 500;

  ${screenSmallerThan.smallMobile} {
    font-size: 0.8rem;
  }
`;

export const StyledTag = styled(Tag)`
  gap: 0.25rem;
  background: var(--clr-background-ukwn-12, #e8fff4);
  border-color: var(--clr-border-ukwn-16, #8fe7be);
  padding: 0.25rem;
  border-width: 1px;
  font-size: 0.625rem;
`;
