import React, { useMemo, useState } from "react";

import { getFileConfig } from "@/features/input/FileCard";
import { selectFile, useAppSelector } from "@/services/Store";
import { useFileSignedUrl } from "@/services/ContractsService";
import { getFileExtentionFromName } from "@/utils/files";
import { usePreviewFile } from "@/services/MediaService";

import {
  StyledContainer,
  StyledImage,
  StyledLoadingImage,
} from "./MediaFileTile.styles";
import { IMediaFileTileProps } from "./MediaFileTile.types";

const MediaFileTile: React.FC<IMediaFileTileProps> = ({
  fileUrl: fileUrlFromProps = "",
  mediaId = -1,
  file,
  className,
  style = {},
}) => {
  const fileDetails = useAppSelector(selectFile(mediaId));
  const { url: fileUrlFromHook, isLoading: isLoadingSignedUrl } =
    useFileSignedUrl(fileDetails?.url || "");
  const [isLoading, setIsLoading] = useState(true);

  const fileUrlFromFileObj = file ? URL.createObjectURL(file) : "";
  const fileUrl =
    fileUrlFromProps || fileUrlFromFileObj || fileUrlFromHook || "";
  const { preview, filePreviewJsx } = usePreviewFile({
    fileUrl: fileUrlFromProps || fileUrlFromFileObj || fileDetails?.url || "",
    file,
  });

  const config = useMemo(
    () => getFileConfig(file ? file.name : fileUrl),
    [fileUrl, file]
  );
  const extention = useMemo(
    () => getFileExtentionFromName(file ? file.name : fileUrl),
    [fileUrl, file]
  );

  const { imageUrl, isIcon } = useMemo(() => {
    if (["png", "jpeg", "jpg"].includes(extention || "")) {
      return { isIcon: false, imageUrl: fileUrl };
    }

    return { isIcon: true, imageUrl: config?.styles?.iconUrl || "" };
  }, [extention, fileUrl, config]);

  const iconBgColor = config?.styles?.iconBgColor;

  return (
    <>
      {filePreviewJsx}

      {!isLoadingSignedUrl && !isLoading && isIcon ? (
        <StyledContainer
          style={{ background: iconBgColor, ...style }}
          className={className}
          onClick={preview}
        >
          <img
            src={imageUrl}
            alt=""
            width={24}
            height={24}
            style={{ opacity: isLoading ? 0 : 1 }}
          />
          {isLoading && <StyledLoadingImage />}
        </StyledContainer>
      ) : (
        <StyledContainer
          style={{ background: iconBgColor, ...style }}
          className={className}
          onClick={preview}
        >
          <StyledImage
            src={imageUrl}
            alt=""
            width={64}
            height={64}
            style={{ opacity: isLoading ? 0 : 1 }}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
          {isLoading && <StyledLoadingImage />}
        </StyledContainer>
      )}
    </>
  );
};

export default MediaFileTile;
