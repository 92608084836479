import useSWR from "swr";

import { contractService } from "@/config/services";

function useReleasePaymentStatus(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId, milestoneId } = params;
  const { data, isLoading } = useSWR(
    !contractId || !milestoneId
      ? null
      : `/contract/${contractId}/milestone/${milestoneId}/release-fund-status`,
    () => contractService.getPaymentReleaseStatus(contractId, milestoneId)
  );

  return {
    data,
    isLoading,
  };
}

export default useReleasePaymentStatus;
