import React, { useEffect } from "react";

import { useAuth } from "@/services/Authentication";
import SplashScreen from "@/features/pages/app/SplashScreen";
import Redirect from "@/components/navigation/Redirect";
import { useSearchParams } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";

import { IHeadlessAuthenticationPageProps } from "./HeadlessAuthenticationPage.types";

const DEFAULT_REDIRECT_PAGE = SITE_PATHS.HOME_PAGE;

const HeadlessAuthenticationPage: React.FC<
  IHeadlessAuthenticationPageProps
> = ({ authType, postAuthenticationRedirectLink = DEFAULT_REDIRECT_PAGE }) => {
  const { register, login, isAuthenticated, isAuthenticating } = useAuth();
  const { searchParams } = useSearchParams();
  const redirectPage =
    searchParams.get("redirect") || postAuthenticationRedirectLink;

  useEffect(() => {
    if (!isAuthenticated) {
      if (authType === "regsiter") {
        register();
      } else if (authType === "login") {
        login();
      }
    }
  }, [isAuthenticated, register, login, authType]);

  if (isAuthenticated && !isAuthenticating) {
    return <Redirect path={redirectPage} />;
  }

  return <SplashScreen />;
};

export default HeadlessAuthenticationPage;
