import { BREAKPOINT_NAMES } from "@/styles";

import { Tab } from "./ContractManagementPage.types";

export const TABS = [
  {
    id: Tab.Overview,
    title: "Overview",
  },
  {
    id: Tab.Details,
    title: "Details",
  },
];

export const RESPONSEIVE_BREAKPOINT: BREAKPOINT_NAMES = "widescreen";
