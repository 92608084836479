import lodashGet from "lodash/get";
import lodashSet from "lodash/set";
import lodashUnset from "lodash/unset";

type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

class LocalStorage<Schema> {
  _data: Schema | null = null;
  _parentKey: string = "";

  constructor(parentKey: string) {
    try {
      this._parentKey = parentKey;
      this._data = JSON.parse(
        window.localStorage.getItem(parentKey) || "{}"
      ) as Schema;
    } catch {}
  }

  getKeyString = (key: Paths<Schema>) => {
    return key;
  };

  get = (key: Paths<Schema>, defaultValue: any = null): any | null => {
    if (!key || !this._data) {
      return defaultValue;
    }

    return lodashGet(this._data, this.getKeyString(key), defaultValue);
  };

  set = (key: Paths<Schema>, value: any) => {
    if (!key || !this._data) {
      return false;
    }

    lodashSet(this._data, this.getKeyString(key), value);
    this.sync();
  };

  remove(key: Paths<Schema>) {
    if (!key || !this._data) {
      return false;
    }

    lodashUnset(this._data, this.getKeyString(key));
    this.sync();
  }

  sync = () => {
    window.localStorage.setItem(this._parentKey, JSON.stringify(this._data));
  };

  clear() {
    window.localStorage.removeItem(this._parentKey);
  }
}

export default LocalStorage;
