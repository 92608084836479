import React from "react";
import { useOverlayTrigger } from "react-aria";

import Button from "@/components/input/Button";
import Popover from "@/components/misc/Popover";
import { joinClassNames } from "@/utils/classNames";

import { IPopoverButtonProps } from "./PopoverButton.types";
import { usePopoverState } from "./PopoverButton.utils";

const PopoverButton: React.FC<IPopoverButtonProps> = ({
  buttonProps = {},
  popoverProps = {},
  state: stateFromProps,
  className,
}) => {
  const ref = React.useRef(null);
  const localState = usePopoverState();
  const state = stateFromProps || localState;
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    ref
  );
  const { children } = popoverProps;

  return (
    <>
      <Button
        useAria={false}
        {...buttonProps}
        {...triggerProps}
        className={joinClassNames(className, buttonProps.className)}
        onClick={triggerProps.onPress as any}
        ref={ref}
      />
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} {...popoverProps}>
          {!!children && React.cloneElement(<>{children}</>, overlayProps)}
        </Popover>
      )}
    </>
  );
};

export default PopoverButton;
