import React from "react";
import lodashGet from "lodash/get";

import EditorField from "@/components/input/EditorFieldNew";

import { useContractForm } from "../../ContractForm.context";

const MilestoneDescriptionField: React.FC<{
  milestoneIndex: number;
  className?: string;
  readOnly?: boolean;
  onClick?: any;
  disabled?: boolean;
}> = ({ milestoneIndex, className, readOnly = false, onClick, disabled }) => {
  const { values, setFieldValue, touched } = useContractForm();

  const fieldKey = `milestones[${milestoneIndex}].description`;
  const isTouched = lodashGet(touched, fieldKey, false);
  const value = lodashGet(values, fieldKey, "");

  const milestonesName = lodashGet(
    values,
    `milestones[${milestoneIndex}].name`,
    ""
  );
  const title = milestonesName || `Milestone ${milestoneIndex + 1}`;

  return (
    <EditorField
      showSaveButton
      focusOnMount={!readOnly}
      hasError={!!isTouched && !value}
      disabled={disabled}
      readOnly={readOnly}
      className={className}
      label="Milestone Description"
      placeholder="Add details about the milestone"
      value={{ markdown: value }}
      onChange={({ markdown }) => {
        setFieldValue(fieldKey, markdown);
      }}
      drawerTitle={title}
      onClick={onClick}
    />
  );
};

export default MilestoneDescriptionField;
