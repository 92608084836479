import React, { createContext, useContext, useMemo, useState } from "react";

import {
  ContractListFilters,
  ContractStatus,
  getLoggedInUserContractUserType,
  isContractCompleted,
  useContractList,
} from "@/services/ContractsService";
import { useDrawerState } from "@/components/misc/Drawer";
import { useParamState } from "@/services/Routing";

import {
  ContractTab,
  IContractsListPageContext,
} from "./ContractsListPage.types";
import { generateTimelineItems } from "@/features/contracts/ContractTimeline";

const ContractsListPageContext = createContext({} as IContractsListPageContext);

export const useContractsListPage = () => useContext(ContractsListPageContext);

export const ContractsListPageProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [filters, setFilters] = useState<ContractListFilters>({});
  const [activeTab, setActiveTab] = useState(ContractTab.Active);
  const [showAll] = useParamState("all", false);
  const { data: contractList, isLoading: isContractListLoading } =
    useContractList({
      filters: {
        ...filters,
        status:
          activeTab === "Completed" ? ContractStatus.Completed : filters.status,
      },
      onlyJobs: !showAll,
    });

  const [search, setSearch] = useState("");

  const sortPanelState = useDrawerState();
  const filterPanelState = useDrawerState();

  const contractListSorted = useMemo(
    () =>
      [...contractList]
        .map((contract) => {
          const userType = getLoggedInUserContractUserType(contract);
          let action = "";

          if (userType) {
            const { items, currentIndex } = generateTimelineItems({
              contract,
              userType,
            });
            action = items[currentIndex]?.ctaAction || "";
          }

          return {
            ...contract,
            action,
          };
        })
        .filter(({ action }: any) => action === "submit-work" || !action)
        .sort((a, b) => +isContractCompleted(a) - +isContractCompleted(b))
        .sort((a, b) => +!!b.action - +!!a.action)
        .slice(0, 3),
    [contractList]
  );

  return (
    <ContractsListPageContext.Provider
      value={{
        search,
        setSearch,
        activeTab,
        setActiveTab,
        contractList: true ? contractList : contractListSorted,
        isContractListLoading,
        sortPanelState,
        filterPanelState,
        filters,
        setFilters,
      }}
    >
      {children}
    </ContractsListPageContext.Provider>
  );
};
