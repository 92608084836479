import React, { useCallback, useMemo, useState } from "react";

import TagListInput from "@/components/input/TagListInputNew";
import Modal, { useModalState } from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import { areStringsSubstring } from "@/utils/string";

import {
  StyledContainer,
  StyledPageWithHeaderLayout,
  StyledRadioCardGroup,
  StyledTagListInput,
} from "./LanguageInput.styles";
import LANGUAGE_LIST from "./languages.json";

const LanguageInput: React.FC<{
  value?: string[];
  onChange?: (value: string[]) => void;
  className?: string;
  urlHash?: string;
}> = ({ value = [], onChange, className, urlHash }) => {
  const [languages, setLanguages] = useState<string[]>(value);
  const [search, setSearch] = useState("");
  const modalState = useModalState({
    urlHash,
    onOpenChange: (isOpen) => {
      if (isOpen) {
        setLanguages(value);
      }
    },
  });

  const options = useMemo(() => {
    return LANGUAGE_LIST.filter(({ name }) => !languages.includes(name))
      .filter(({ name }) => areStringsSubstring(name, search))
      .sort(
        ({ name: nameA }, { name: nameB }) =>
          +Boolean(nameB.toLowerCase().startsWith(search.toLowerCase())) -
          +Boolean(nameA.toLowerCase().startsWith(search.toLowerCase()))
      )
      .map(({ name }) => ({
        children: <>{name}</>,
        value: name,
      }));
  }, [search, languages]);

  const languageNames = useMemo(
    () => options.map(({ value }) => value),
    [options]
  );

  //-------------------------------

  const handleApplyClick = useCallback(() => {
    setSearch("");

    if (onChange) {
      onChange(languages);
    }

    modalState.close();
  }, [modalState, onChange, languages]);

  //-------------------------------

  const contentJsx = (
    <>
      <TagListInput
        focusOnMount
        placeholder="Languages"
        inputValue={search}
        onInputChange={setSearch}
        value={languages}
        onChange={setLanguages}
        allowedOptions={languageNames}
      />

      <StyledRadioCardGroup
        label="contract-type"
        value=""
        onChange={(value) => {
          setSearch("");
          setLanguages((languages) => {
            languages = [...languages];
            languages.push(value);
            return languages;
          });
        }}
        items={options}
        itemStyles={{
          minHeight: "3rem",
          paddingBlock: "0.5rem",
          borderRadius: "4px",
        }}
      />
    </>
  );

  return (
    <>
      <div className={className} onClick={modalState.open}>
        <StyledTagListInput
          disabled
          placeholder="Languages"
          value={value}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <Modal
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
        desktopProps={{ width: "768px" }}
      >
        <StyledPageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Select languages" },
            backButtonProps: {
              onClick: modalState.close,
            },
          }}
        >
          <StyledContainer>
            {contentJsx}

            <Button onClick={handleApplyClick}>Apply</Button>
          </StyledContainer>
        </StyledPageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default LanguageInput;
