import { useMemo, useRef } from "react";
import { useCalendarCell } from "react-aria";
import { isSameDay, getDayOfWeek } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";

import { StyledCell, StyledCellButton } from "./CalendarCell.styles";
import { CalendarCellProps, CellHighlightType } from "./CalendarCell.types";
import { HIDE_OTHER_MONTH_DATES } from "../CalendarGrid/CalendarGrid.config";

const CalendarCell: React.FC<CalendarCellProps> = ({ state, date, type }) => {
  const ref = useRef(null);
  const { locale } = useLocale();

  const {
    cellProps,
    buttonProps,
    isSelected: isSelectedFromHook,
    isOutsideVisibleRange,
    formattedDate,
    isDisabled,
  } = useCalendarCell({ date }, state, ref);

  const isRangeCalendar = type === "range";
  const highlightedRange = isRangeCalendar ? state.highlightedRange : null;

  const { isSelected, isSelectionStart, isSelectionEnd, inSelectedRange } =
    useMemo(() => {
      let isSelectionStart = false;
      let isSelectionEnd = false;
      let isSelected = false;
      let inSelectedRange = false;

      if (type === "single-date") {
        isSelected = isSelectedFromHook;
      } else if (type === "range") {
        isSelectionStart = highlightedRange
          ? isSameDay(date, highlightedRange.start)
          : isSelectedFromHook;

        isSelectionEnd = highlightedRange
          ? isSameDay(date, highlightedRange.end)
          : isSelectedFromHook;

        isSelected = isSelectionStart || isSelectionEnd;

        inSelectedRange =
          !!highlightedRange &&
          highlightedRange.start.compare(date) <= 0 &&
          highlightedRange.end.compare(date) >= 0;
      }

      return {
        isSelectionStart,
        isSelectionEnd,
        isSelected,
        inSelectedRange,
        type,
      };
    }, [isSelectedFromHook, highlightedRange, date, type]);

  const { isWeekEnd, isWeekStart } = useMemo(() => {
    const dayOfWeek = getDayOfWeek(date, locale);
    const isWeekEnd = dayOfWeek === 6;
    const isWeekStart = dayOfWeek === 0;

    return {
      isWeekEnd,
      isWeekStart,
    };
  }, [date, locale]);

  const highlightType = useMemo(() => {
    let highlightType: CellHighlightType | undefined;

    if (type === "single-date") {
      return highlightType;
    }

    if (!inSelectedRange) {
      return highlightType;
    }

    const isSingleDateSelected =
      isSelectionStart && isSelectionStart === isSelectionEnd;

    if (isSingleDateSelected) {
      return highlightType;
    }

    if (isSelectionStart && isWeekEnd) {
      return highlightType;
    }

    if (isSelectionEnd && isWeekStart) {
      return highlightType;
    }

    highlightType = "full-width";

    if (isSelectionStart || isWeekStart) {
      highlightType = "start";
    } else if (isSelectionEnd || isWeekEnd) {
      highlightType = "end";
    }

    return highlightType;
  }, [
    isSelectionStart,
    isSelectionEnd,
    inSelectedRange,
    isWeekEnd,
    isWeekStart,
    type,
  ]);

  //-----------------------------

  return (
    <StyledCell {...cellProps} $highlightType={highlightType}>
      {isOutsideVisibleRange && HIDE_OTHER_MONTH_DATES ? null : (
        <StyledCellButton
          {...buttonProps}
          $active={isSelected}
          $muted={isOutsideVisibleRange || isDisabled}
          $showHover={!inSelectedRange && !isSelected}
          ref={ref}
          hidden={isOutsideVisibleRange}
        >
          {formattedDate}
        </StyledCellButton>
      )}
    </StyledCell>
  );
};

export default CalendarCell;
