import useSWR from "swr";
import { useCallback, useEffect, useState } from "react";

import { userService } from "@/config/services";
import {
  selectIsStripeOnboarded,
  selectLoadingIsStripeOnboarded,
  useAppSelector,
} from "@/services/Store";

import { PayoutOnboardItems } from "../UserService.types";

function usePayoutOnboardStatus() {
  const [loadedOnboardingState, setLoadedOnboardingState] = useState(false);
  const isOnboarded = useAppSelector(selectIsStripeOnboarded);
  const loadedState = useAppSelector(selectLoadingIsStripeOnboarded);

  //------------------------

  const handleLoadOnboardingState = useCallback(
    () =>
      userService.isStripeOnboarded().finally(() => {
        setLoadedOnboardingState(true);
      }),
    []
  );

  const { data: onboardStatus, isLoading } = useSWR(
    "/payment/check-onboard-status",
    handleLoadOnboardingState,
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      dedupingInterval: 20000,
    }
  );

  const loadedStateOnce = onboardStatus !== undefined;
  const isReady = loadedOnboardingState || loadedStateOnce;
  const onboardingCountry = onboardStatus?.country || null;
  const onboardingInitiated = onboardStatus?.onboardingInitiated || false;
  const documentVerificationPending = (
    onboardStatus?.pendingItems || []
  ).includes(PayoutOnboardItems.VarificationDocuments);
  const promptIdentityVerification =
    onboardingInitiated && documentVerificationPending && !!onboardingCountry;

  useEffect(() => {
    if (isReady || isOnboarded || loadedState || loadedStateOnce || isLoading) {
      return;
    }

    handleLoadOnboardingState();
  }, [
    isReady,
    isOnboarded,
    loadedState,
    handleLoadOnboardingState,
    loadedStateOnce,
    isLoading,
  ]);

  //------------------------

  return {
    onboardStatus,
    isOnboarded,
    loadedState,
    loadedStateOnce,
    isReady,
    isLoading,
    onboardingCountry,
    onboardingInitiated,
    documentVerificationPending,
    promptIdentityVerification,
  };
}

export default usePayoutOnboardStatus;
