"use client";

import React, { useMemo } from "react";
import styled from "@emotion/styled";

import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
} from "@/services/ChatServiceNew";
import { useParamState } from "@/services/Routing";
import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";
import { joinClassNames } from "@/utils/classNames";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > * {
    border-radius: 10rem !important;
    border: none !important;
    background: #f6f8fa !important;
    color: #a4acb9 !important;
    padding: 0.75rem 1rem;

    &.active {
      background: #5f57ff1a !important;
      color: var(--clr-primary-100, #5f57ff) !important;
    }
  }
`;

const ChatTypeFilter: React.FC = () => {
  const [channelType] = useParamState<string>(CHANNEL_TYPE_FILTER_URL_KEY, "", {
    parseJson: false,
  });

  const options = useMemo(
    () => [
      {
        id: ChannelType.Contract,
        label: "Jobs",
      },
      {
        id: ChannelType.PersonalChat,
        label: "Leads",
      },
      {
        id: ChannelType.ChatRequest,
        label: "Requests",
      },
    ],
    []
  );

  return (
    <StyledContainer>
      {options.map(({ id, label }) => (
        <Button
          key={id}
          link={`${SITE_PATHS.CHAT_PAGE}?${CHANNEL_TYPE_FILTER_URL_KEY}=${id}`}
          className={joinClassNames(id === channelType && "active")}
        >
          {label}
        </Button>
      ))}
    </StyledContainer>
  );
};

export default ChatTypeFilter;
