import { screenLargerThan, screenSmallerThan } from "@/styles";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6rem;
  height: 100%;
  padding-bottom: 1.25rem;
  max-width: 480px !important;

  .label {
    color: #000;
    font-size: 2rem;
    font-weight: 600;
  }

  .features-section {
    flex-grow: 4;
  }

  ${screenSmallerThan.tablet} {
    .card-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .cta-section {
      margin-top: auto;
    }
  }

  ${screenLargerThan.tablet} {
    padding-inline: 0 !important;
    padding-block: 2rem 4rem;
  }
`;

export const StyledCard = styled.div`
  position: relative;
  background: var(--clr-primary-100, #5f57ff);
  background: linear-gradient(100.99deg, #5f57ff 16.93%, #01d8ff 100%);
  color: #fff;
  padding: 1.25rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  min-height: 14.5rem;

  .heading-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .bottom {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: flex-end;
  }

  .content .secondary {
    color: #f6f8fa;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.65rem;
    border-radius: 0.35rem;
    height: fit-content;
  }

  .logo {
    ${squareSizing("10.5rem")};
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
  }
`;
