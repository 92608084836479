"use client";

import styled from "@emotion/styled";

export const StyledText = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--clr-text-900, #0d0d12);
  letter-spacing: 0.008em;
`;
