import React, { useCallback, useMemo } from "react";

import ChatRoomComponent, {
  ChatRoomLoading,
  Message,
  ReadStatus,
  User,
} from "@/features/chat/ChatRoom";
import { useChatRoom } from "@/services/ChatService";
import { getParticipantFullName } from "@/services/ContractsService";
import { useAuth } from "@/services/Authentication";
import { useResponsive } from "@/styles";

import { useChatRoomPage } from "../ChatRoomPage.context";
import { MESSAGES, SHOW_DUMMY_CHAT } from "../ChatRoomPage.config";
import { USER_PROFILE } from "../ChatRoomPage.temp";
import ChatMenuButton from "./ChatMenuButton";
import StatusSection from "./ContractStatus";
import NewChat from "./NewChat";

const ChatRoom: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { contractDetails, showNewChatFlowState } = useChatRoomPage();
  const { id: contractId, channel_id } = contractDetails as any;

  const { loggedInUserId } = useAuth();
  const {
    messages: messagesFromHook,
    sendMessage,
    loadingMessages,
    isActive,
  } = useChatRoom(channel_id);

  const roomUsers = useMemo(
    () =>
      contractDetails
        ? contractDetails.participants.map(
            (p) =>
              ({
                id: p.user_id,
                name: getParticipantFullName(p),
                image: p?.user?.picture || "",
                email: p.user.email,
              } satisfies User)
          )
        : [],
    [contractDetails]
  );

  const handleSend = useCallback(
    ({ content, attachments }: any) => {
      sendMessage({ content, attachments, contractId });
    },
    [sendMessage, contractId]
  );

  const messages = useMemo(
    () =>
      messagesFromHook.map((message) => {
        return {
          userId: +message.data.senderId,
          content: message.data.message || message.data.content,
          date: new Date(message.timestamp || 0),
          readStatus: ReadStatus.Read,
          attachments: message.data.attachements,
        } satisfies Message;
      }),
    [messagesFromHook]
  );

  if (loadingMessages || !isActive || !contractDetails) {
    return <ChatRoomLoading />;
  }

  return (
    <ChatRoomComponent
      showBackButton={isScreenSmallerThanTablet}
      hideInputs={showNewChatFlowState.state}
      header={{
        appendContent: <ChatMenuButton />,
        appendBelowContent: <StatusSection contractDetails={contractDetails} />,
      }}
      currentUserId={loggedInUserId}
      users={
        SHOW_DUMMY_CHAT
          ? [
              { id: 1, name: "Me" },
              {
                id: 2,
                name: "Francois Handa",
                image: USER_PROFILE,
                email: "contact@paypipe.com",
              },
            ]
          : roomUsers
      }
      messages={SHOW_DUMMY_CHAT ? MESSAGES : messages}
      onSend={handleSend}
      footer={{
        appendContent: showNewChatFlowState.state ? <NewChat /> : null,
      }}
    />
  );
};

export default ChatRoom;
