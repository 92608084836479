"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import lodashGet from "lodash/get";

import { Body, Display, Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";
import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import { SocialLink } from "@/services/UserService";

import {
  StyledContainer,
  StyledModal,
  StyledPlaceholderIconsContainer,
  StyledTextField,
} from "./SocialLinksInput.styles";
import {
  PLACEHOLDER_ICONS,
  PREDEFNED_PREFIX,
  SOCIAL_LINKS,
} from "./SocialLinksInput.config";
import {
  generateCustomLinksList,
  generatePredefinedLinksMap,
  getLinkIcon,
} from "./SocialLinksInput.utils";
import { useProfileForm } from "../../../ProfileForm.config";

const SocialLinksInput: React.FC<{ className?: string }> = ({ className }) => {
  const drawerState = useDrawerState();
  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        drawerState.close();
      }
    },
  });

  const { values, setFieldValue } = useProfileForm();
  const { links } = values;

  const [predefinedLinksMap, setPredefinedLinksMap] = useState(
    generatePredefinedLinksMap(links)
  );

  const [customLinksList, setCustomLinksList] = useState(
    generateCustomLinksList(links)
  );

  const [newLinkData, setNewLinkData] = useState<SocialLink>({
    name: "",
    url: "",
  });

  //----------------------

  const handlePredefinedLinkUpdate = useCallback(
    (platformName: string, url: string) => {
      setPredefinedLinksMap((map) => {
        map = { ...map };
        const updatedLinks = [...links];

        if (map[platformName]) {
          map[platformName] = {
            ...map[platformName],
            url,
          };

          updatedLinks[map[platformName].index].url = url;
        } else {
          updatedLinks.push({
            name: platformName,
            url,
          });

          map[platformName] = {
            index: links.length - 1,
            name: platformName,
            url,
          };
        }

        setFieldValue("links", updatedLinks);

        return map;
      });
    },
    [links, setFieldValue]
  );

  const removePredefinedLink = useCallback(
    (platformName: string) => {
      setPredefinedLinksMap((map) => {
        if (map[platformName]) {
          map = { ...map };

          const updatedLinks = [...links];
          updatedLinks.splice(map[platformName].index, 1);
          delete map[platformName];
          setFieldValue("links", updatedLinks);
        }

        return map;
      });
    },
    [links, setFieldValue]
  );

  const handleCustomLinkUpdate = useCallback(
    (index: number, url: string) => {
      setCustomLinksList((value) => {
        const localIndex = value.findIndex((item) => item.index === index);
        value = [...value];

        value[localIndex].url = url;

        const updatedLinks = [...links];
        updatedLinks[index].url = url;
        setFieldValue("links", updatedLinks);

        return value;
      });
    },
    [links, setFieldValue]
  );

  const handleRemoveCustomLink = useCallback(
    (index: number) => {
      setCustomLinksList((value) => {
        value = [...value];
        value.splice(index, 1);

        const updatedLinks = [...links];
        updatedLinks.splice(index, 1);
        setFieldValue("links", updatedLinks);

        return value;
      });
    },
    [links, setFieldValue]
  );

  const handleAdd = useCallback(() => {
    setCustomLinksList((value) => {
      value = [...value];

      if (newLinkData.name && newLinkData.url) {
        const newIndex = value.length;
        value.push({ ...newLinkData, index: newIndex });

        const updatedLinks = [...links];
        updatedLinks.push(newLinkData);
        setFieldValue("links", updatedLinks);
      }

      return value;
    });

    setNewLinkData({
      name: "",
      url: "",
    });

    drawerState.close();
  }, [newLinkData, drawerState, links, setFieldValue]);

  //----------------------

  const linksRef = useRef(links);
  linksRef.current = links;
  useEffect(() => {
    setCustomLinksList(generateCustomLinksList(linksRef.current));
    setPredefinedLinksMap(generatePredefinedLinksMap(linksRef.current));
  }, [links.length]);

  //----------------------

  return (
    <>
      <div className={className} onClick={modalState.open}>
        <Label size="lg" as="label" htmlFor="timezone">
          Links
        </Label>
        <Body
          className="mt-1"
          size="sm"
          style={{
            color: "var(--clr-neutral-solid-400, #808897)",
          }}
        >
          Link to your website and social media accounts
        </Body>

        {links.length ? (
          links.map(({ name, url }, i) => {
            return (
              <StyledTextField
                key={i}
                variant="background"
                className="mt-2"
                placeholder={name}
                value={url}
                style={{ pointerEvents: "none" }}
                prependContent={
                  <Icon
                    isSrcRelative
                    size="sm"
                    src={getLinkIcon({ name, url })}
                  />
                }
              />
            );
          })
        ) : (
          <StyledPlaceholderIconsContainer className="mt-3">
            {PLACEHOLDER_ICONS.map(({ icon, label }) => (
              <Icon isSrcRelative src={icon} alt={label} />
            ))}
          </StyledPlaceholderIconsContainer>
        )}

        <Button variant="secondary" colorVariant="gray" className="w-100 mt-3">
          Add
        </Button>
      </div>

      <StyledModal
        state={modalState}
        mobileProps={{
          fullscreen: true,
          className: "p-0",
        }}
        desktopProps={{
          width: "768px",
        }}
        isKeyboardDismissDisabled={false}
        shouldCloseOnInteractOutside={()=> false}
      >
        <PageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Links" },
            backButtonProps: { onClick: modalState.close },
          }}
        >
          <StyledContainer className="px-3 pb-3">
            <Label size="lg" as="label" htmlFor="timezone">
              Add your links
            </Label>

            <Body className="mb-4 mt-2" size="sm" style={{ color: "#868C98" }}>
              Link to your website and social media accounts
            </Body>

            {SOCIAL_LINKS.map(({ icon, platformName, displayName }, i) => {
              const key = platformName || displayName;
              const value = lodashGet(
                predefinedLinksMap,
                `[${key}].url`,
                ""
              ) as string;
              const label =
                displayName || platformName.split(PREDEFNED_PREFIX)[1];

              return (
                <StyledTextField
                  key={i}
                  variant="background"
                  className="mt-2"
                  placeholder={label}
                  prependContent={<Icon isSrcRelative size="sm" src={icon} />}
                  value={value}
                  onChange={(value) => {
                    handlePredefinedLinkUpdate(platformName, value);
                  }}
                  appendContent={
                    !!value && (
                      <Button
                        variant="ghost"
                        className="m-0"
                        onClick={() => {
                          removePredefinedLink(platformName);
                        }}
                      >
                        <Icon
                          isSrcRelative
                          size="xxs"
                          src="cross.svg"
                          colorVariant="gray"
                        />
                      </Button>
                    )
                  }
                />
              );
            })}

            {customLinksList.map(({ name, url, index }, i) => {
              return (
                <StyledTextField
                  key={i}
                  variant="background"
                  className="mt-2"
                  placeholder={name}
                  prependContent={
                    <Icon isSrcRelative size="sm" src="link.svg" />
                  }
                  value={url}
                  onChange={(value) => {
                    handleCustomLinkUpdate(index, value);
                  }}
                  appendContent={
                    !!url && (
                      <Button
                        variant="ghost"
                        className="m-0"
                        onClick={() => {
                          handleRemoveCustomLink(index);
                        }}
                      >
                        <Icon
                          isSrcRelative
                          size="xxs"
                          src="cross.svg"
                          colorVariant="gray"
                        />
                      </Button>
                    )
                  }
                />
              );
            })}

            <Label
              className="mt-4 text-center d-block"
              size="md"
              as="label"
              htmlFor="timezone"
            >
              Can't find what you're after?
            </Label>

            <Button
              variant="secondary"
              colorVariant="gray"
              className="w-100 mt-3"
              onClick={drawerState.open}
            >
              Add another link
            </Button>
            <br />
            <br />
            <br />
          </StyledContainer>
        </PageWithHeaderLayout>
      </StyledModal>

      <Drawer state={drawerState} zIndex={100}>
        <div className="p-3">
          <Display size="md" as="label" htmlFor="timezone">
            Add a new link
          </Display>

          <StyledTextField
            className="mt-4"
            variant="background"
            placeholder="Link name"
            value={newLinkData.name}
            onChange={(value) => {
              setNewLinkData((data) => ({ ...data, name: value }));
            }}
            prependContent={<Icon isSrcRelative size="sm" src="@.svg" />}
          />

          <StyledTextField
            variant="background"
            className="mt-3"
            placeholder="Link address"
            value={newLinkData.url}
            onChange={(value) => {
              setNewLinkData((data) => ({ ...data, url: value }));
            }}
            prependContent={<Icon isSrcRelative size="sm" src="link.svg" />}
          />

          <Button
            variant="secondary"
            colorVariant="gray"
            className="w-100 mt-4"
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default SocialLinksInput;
