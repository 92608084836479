// const FILE_EXTENTION_REGEX = /\.([^.]+)$/;
const FILE_EXTENTION_REGEX = /\.(\w{3,4})($|\?)/;

export function getFileExtentionFromName(fileName: string) {
  if (!fileName) return "";

  const match = fileName.match(FILE_EXTENTION_REGEX);
  const extention = match ? match["1"] : null;

  return extention;
}

export function formatBytes(bytes = 0, decimals = 2) {
  if (!+bytes) return "";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function openLink(url = "") {
  if (!url) {
    return;
  }

  const newWindow = (window as any).open(url, "_blank");
  if (newWindow.focus) {
    newWindow.focus();
  }
}

export function isBlobUrl(url = "") {
  return url.includes("blob");
}

export function downloadFile(url = "") {
  if (!url) {
    return;
  }

  let link = document.createElement("a");
  link.href = url;
  link.download = "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
