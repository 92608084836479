export const EXPERIENCE_OPTIONS = [
  "2D Animation",
  "3D Modeling and Animation",
  "A/B Testing Analysis",
  "API Development",
  "Academic Writing",
  "Accounting Services",
  "Adobe Creative Suite Proficiency (Photoshop, Illustrator, etc.)",
  "Affiliate Marketing",
  "Article Writing",
  "Artificial Intelligence Consulting",
  "AutoCAD for Architectural Design",
  "Blockchain Development",
  "Blog Writing",
  "Bookkeeping",
  "Brand Strategy Development",
  "Brochure Design",
  "Budget Planning",
  "Business Card Design",
  "Business Development Strategy",
  "Business Intelligence Reporting",
  "Catering Services",
  "Character Design for Games/Animation",
  "Cloud Computing Services",
  "Cloud Security Assessment",
  "Cold Calling Services",
  "Community Management",
  "Content Marketing Strategy",
  "Contract Drafting and Review",
  "Copywriting",
  "Corporate Training Facilitation",
  "Creative Writing",
  "Crisis Communication Planning",
  "Cryptocurrency Consulting",
  "Customer Support Representative",
  "Cybersecurity Consulting",
  "Data Analysis",
  "Data Analysis and Management",
  "Data Entry",
  "Data Visualization",
  "Database Management",
  "Digital Advertising Management",
  "Digital Marketing and SEO",
  "Document Preparation and Formatting",
  "E-commerce Development",
  "Editing",
  "Educational Content Development",
  "Electrical Engineering Consulting",
  "Email Marketing Campaigns",
  "Email Newsletter Writing",
  "Employee Training Programs",
  "Environmental Impact Assessments",
  "Ethical Hacking Services",
  "Event Planning",
  "Excel Data Management",
  "Fashion Design",
  "Fiction Writing",
  "Final Cut Pro Video Editing",
  "Financial Consulting",
  "Fitness Coaching",
  "Full Stack Development",
  "Ghostwriting",
  "Grant Proposal Writing",
  "Grant Writing",
  "Graphic Design",
  "Graphic Design and Multimedia",
  "IT Support Services",
  "IT and Cybersecurity",
  "Illustration (Digital, Traditional)",
  "Influencer Marketing",
  "Infographic Design",
  "Interior Design Consultation",
  "Investment Analysis",
  "Landscape Architecture",
  "Language Translation Services",
  "Lead Generation",
  "Legal Consulting",
  "Live Chat Support Agent",
  "Logo Design",
  "Machine Learning Model Development",
  "Market Research",
  "Mechanical Engineering Consulting",
  "Media Relations Strategy",
  "Mobile App Development (Android)",
  "Mobile App Development (iOS)",
  "Motion Graphics Animation",
  "Music Composition",
  "Network Administration",
  "Non-Profit Fundraising Strategy",
  "Nutrition Consulting",
  "Online Course Creation",
  "Online Research Services",
  "Pay-Per-Click Advertising (PPC)",
  "Penetration Testing",
  "Personal Shopping Assistance",
  "Photo Editing and Retouching",
  "Photography (Portrait, Product, Event)",
  "Podcast Production",
  "Presentation Design (PowerPoint, Keynote)",
  "Press Release Writing",
  "Product Description Writing",
  "Project Management Assistance",
  "Proofreading",
  "Property Management Consulting",
  "Public Speaking Coaching",
  "Real Estate Virtual Tours Creation",
  "Recipe Development",
  "Recruitment Strategy Development",
  "Resume Writing",
  "Risk Assessment Consulting",
  "SQL Database Management",
  "Sales Funnel Optimization",
  "Scheduling and Calendar Management",
  "Scriptwriting for Videos/Films",
  "Search Engine Optimization (SEO)",
  "Social Media Content Creation",
  "Social Media Marketing",
  "Social Media Strategy Development",
  "Software Development",
  "Speech Writing",
  "Statistical Analysis using R or Python",
  "Sustainability Reporting",
  "Tax Preparation Services",
  "Technical Support Documentation",
  "Technical Writing",
  "Technology and Development",
  "Textile Design",
  "Training Program Design",
  "Transcription Services",
  "Travel Planning Services",
  "Tutoring Services",
  "Usability Testing",
  "User Testing Facilitation",
  "Video Editing and Production",
  "Virtual Assistance and Administrative Support",
  "Virtual Assistant Services",
  "Voice-over Acting",
  "Web Analytics using Google Analytics",
  "Web Content Writing",
  "Web Design (UI/UX)",
  "Web Development (Backend)",
  "Web Development (Frontend)",
  "Writing and Content Creation",
  "eBook Writing",
];
