import React from "react";
import styled from "@emotion/styled";

import Timeline from "@/components/misc/Timeline";
import ContractTimeline from "@/features/contracts/ContractTimeline";
import MilestonesDisplay from "@/features/contracts/MilestonesDisplay";
import { CONTRACT_DATA } from "./temo";

const StyledContainer = styled.div`
  display: flex;
  gap: 4rem;

  .timeline {
    flex-grow: 1;
  }
`;

const TimelineTest: React.FC = () => {
  return (
    <StyledContainer className="pt-5 pb-5 container">
      <Timeline
        currentIndex={2}
        items={[
          {
            children: "Test",
          },
          {
            children: "Test 2",
            showCount: false,
          },
          {
            children: "Test f",
          },
          {
            children: "Testfdf",
          },
        ]}
      />

      <MilestonesDisplay milestones={CONTRACT_DATA.milestones} />

      <ContractTimeline
        className="timeline"
        contractId="0563fb7b-51b9-415e-81b3-6a716dae172f"
      />
    </StyledContainer>
  );
};

export default TimelineTest;
