import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";

const NewOfferButton: React.FC = () => {
  return (
    <Button link={SITE_PATHS.CREATE_CONTRACT_PAGE}>Send a new offer</Button>
  );
};

export default NewOfferButton;
