import React from "react";

import { IButtonProps } from "@/components/input/Button";
import { joinClassNames } from "@/utils/classNames";

import { StyledButton } from "../../ContractForm.styles";
import { useContractForm } from "../../ContractForm.context";
import { useResponsive } from "@/styles";

const StepBackButton: React.FC<IButtonProps> = (props) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { previousStep } = useContractForm();

  if (isScreenSmallerThanTablet) {
    return null;
  }

  return (
    <StyledButton
      {...props}
      className={joinClassNames("w-100", props.className)}
      onClick={previousStep}
      colorVariant="gray"
    >
      Back
    </StyledButton>
  );
};

export default StepBackButton;
