import { useCallback, useEffect, useMemo, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { InboundMessage, Message, PaginatedResult } from "ably";
import useSWR from "swr";

import { useAuth } from "@/services/Authentication";
import { ReadStatus } from "@/features/chat/ChatRoom";
import { FileObject } from "@/features/input/FilesInput";
import { mediaService } from "@/config/services";
import { MilestoneId } from "@/services/ContractsService";

import { DUMMY_MESSAGES } from "../ChatService.config";
import useConnectChannel from "./useConnectChannel";

function useChatRoom(roomId: string) {
  const { loggedInUserId } = useAuth();

  const channelName = roomId ? `Chat:${roomId}` : "";
  const { channel, isConnected } = useConnectChannel(channelName);

  const [messages, setMessages] = useState<Message[]>(DUMMY_MESSAGES);
  const [paginatedMessages, setPaginagtedMessages] =
    useState<PaginatedResult<InboundMessage> | null>(null);

  const [loadingMessages_, setLoadingMessages] = useState(false);
  const isUpdatingMessages = loadingMessages_ && !!messages.length;
  const loadingMessages = loadingMessages_ && !messages.length;

  //-----------
 
  const isActive = !!channel && isConnected;

  //-----------
  
  const handleSetMessages = useCallback(
    (newMessages: Message[] = [], replace = false) => {
      setMessages((previousMessages) => {
        if (replace) {
          return newMessages;
        }

        let updatedMessages = [...previousMessages, ...newMessages];
        updatedMessages = uniqBy(updatedMessages, "id");
        // updatedMessages = sortBy(updatedMessages, "timestamp");

        return updatedMessages;
      });
    },
    []
  );

  const loadChannelMessages = useCallback(() => {
    setLoadingMessages(false);

    if (!isConnected || !channel) {
      return Promise.reject();
    }

    setLoadingMessages(true);

    return channel
      .history({ limit: 100 })
      .then((value) => {
        setPaginagtedMessages(value);
        handleSetMessages(value.items);

        return value;
      })
      .finally(() => {
        setLoadingMessages(false);
      });
  }, [channel, handleSetMessages, isConnected]);

  useEffect(() => {
    if (!channel) {
      return;
    }

    const callback = (message: Message) => {
      handleSetMessages([message]);
    };

    if (isConnected) {
      channel.subscribe(callback);
    }

    return () => {
      channel.unsubscribe(callback);
    };
  }, [channel, handleSetMessages, isConnected]);

  useEffect(() => {
    setMessages([]);
  }, [channelName]);

  const { mutate } = useSWR<PaginatedResult<InboundMessage> | null>(
    isConnected ? `/chats/${channelName}/messages` : null,
    loadChannelMessages,
    {
      errorRetryInterval: 10000,
      errorRetryCount: 5,
      dedupingInterval: 20000,
      // revalidateOnMount: false,
      revalidateOnFocus: false,
      fallbackData: null,
    }
  );

  useMemo(() => {
    if (isConnected) mutate();
  }, [isConnected, mutate]);

  const sendMessage = useCallback(
    (data: {
      content: string;
      attachments: FileObject[];
      contractId?: string;
      milestoneId?: MilestoneId;
    }) => {
      if (!channel) {
        return Promise.resolve();
      }

      const { content, attachments, contractId, milestoneId } = data;

      return (async function () {
        try {
          const attatchmentsRes = await Promise.all(
            attachments
              .filter(({ file }) => !!file)
              .map(({ file }) =>
                mediaService.uploadFile(file as File, {
                  contractId,
                  milestoneId,
                })
              )
          );

          const attatchmentIds = attatchmentsRes.map((res) => res.data.data.id);

          channel.publish("SEND_MESSAGE", {
            content,
            senderId: loggedInUserId,
            attachements: attatchmentIds,
            type: "text",
            readStatus: ReadStatus.Read,
          });
        } catch (err) {
        } finally {
        }
      })();
    },
    [channel, loggedInUserId]
  );

  const loadNextMessages = useCallback(() => {
    if (paginatedMessages) {
      return paginatedMessages.next().then((value) => {
        mutate(value);
        if (value) handleSetMessages(value.items);

        return value;
      });
    }

    return Promise.reject();
  }, [paginatedMessages, mutate, handleSetMessages]);

  return {
    sendMessage,
    messages,
    loadNextMessages,
    isActive,
    loadingMessages,
    isUpdatingMessages,
  };
}

export default useChatRoom;
