import React from "react";
import styled from "@emotion/styled";
import Icon from "@/components/misc/Icon";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const StyledValue = styled.div`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--clr-text-900, #0d0d12);
`;

const InfoDisplay: React.FC<{
  icon: string;
  value: React.ReactNode;
  className?: string;
}> = ({ className, icon, value }) => {
  return (
    <StyledContainer className={className}>
      <Icon isSrcRelative src={icon} size="xs" colorVariant="gray" />
      <StyledValue>{value}</StyledValue>
    </StyledContainer>
  );
};

export default InfoDisplay;
