import useSWR from "swr";

import { location } from "@/config/services";

import { PredictedPlace } from "../Location.types";

const DEFAULT_DATA: PredictedPlace[] = [];

function usePlaceSuggessions(query: string) {
  const { data, isLoading } = useSWR(
    query ? `/place/autocomplete/${query}` : null,
    () => location.getSuggessions(query),
    {
      errorRetryInterval: 0,
      errorRetryCount: 5,
      dedupingInterval: 0,
    }
  );

  return {
    data: data?.predictions || DEFAULT_DATA,
    isLoading,
  };
}

export default usePlaceSuggessions;
