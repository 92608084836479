import React, { useState } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";

import TextField from "@/components/input/TextField";
import TagListInput from "@/components/input/TagListInputNew";
import FilesInput, { FileObject } from "@/features/input/FilesInput";
import Button, { IButtonProps } from "@/components/input/Button";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { SKILLS_TOOLS_OPTIONS } from "@/data/skillToolOptions";
import { areStringsSubstring, URL_REGEX } from "@/utils/string";
import Form, { FormError } from "@/components/input/Form";

import { EditingPortfolio } from "./PortfolioSection";

//------------------------

const MAX_ALLOWED_LENGTH = 50;

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().min(1).required().max(MAX_ALLOWED_LENGTH),
  description: yup.string().min(1).required(),
  assets: yup.array().min(1).required(),
  link: yup
    .string()
    .matches(
      URL_REGEX,
      "Please enter a valid URL , i.e https://www.portfolio.com"
    ),
});

//------------------------

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .content {
    flex-grow: 1;
  }
`;

const StyledCtaSection = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  background: #fff;
  padding-top: 1rem;

  button {
    min-width: 6rem;
  }

  .cancel-button {
    --bg-color: #f5f5f5 !important;
    --border-color: #f5f5f5 !important;
  }
`;

const AssetSection = styled.div``;

const StyledTagContainer = styled.div`
  overflow: auto;
  max-height: 8rem;
`;

const StyledTagButton = styled(Button)`
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const EditPortfolio: React.FC<{
  portfolio: EditingPortfolio;
  onSave: (values: EditingPortfolio) => void;
  onCancel: () => void;
  cancelButtonProps?: Omit<IButtonProps, "onClick">;
  saveButtonProps?: Omit<IButtonProps, "onClick">;
  className?: string;
}> = ({
  portfolio,
  onSave,
  onCancel,
  cancelButtonProps,
  saveButtonProps,
  className,
}) => {
  const [search, setSearch] = useState("");

  return (
    <Form<EditingPortfolio>
      initialValues={{
        ...portfolio,
        assets: (portfolio.attachments || []).map(
          (file) => ({ mediaFile: file } as FileObject)
        ),
      }}
      yupValidationSchema={VALIDATION_SCHEMA}
      onSubmit={onSave}
    >
      {({
        context: {
          values: { name, description, assets, tech, link },
          setFieldValue,
          touchedAndHasError,
          setFieldTouched,
          hasError,
          submitForm,
        },
      }) => {
        const tagOptions = SKILLS_TOOLS_OPTIONS.filter((name) => !tech.includes(name))
          .filter((name) => !tech.includes(name))
          .filter((name) => areStringsSubstring(name, search))
          .map((name) => ({
            children: <>{name}</>,
            value: name,
          }))
          .splice(0, 18);

        return (
          <StyledForm className={className} onSubmit={submitForm}>
            <div className="content">
              <AssetSection>
                <FilesInput
                  className="mt-4"
                  displayFileCount={2}
                  label="Upload Portfolio Images"
                  variant="cards"
                  value={assets}
                  onChange={(value) => {
                    setFieldValue("assets", value);
                  }}
                  hasError={touchedAndHasError("assets")}
                />
              </AssetSection>
              <TextField
                className="mt-4"
                variant="background"
                label="Portfolio Title"
                placeholder="Enter Title Here"
                value={name}
                onChange={(value) => {
                  setFieldValue("name", value.substring(0, MAX_ALLOWED_LENGTH));
                }}
                hasError={touchedAndHasError("name")}
                onBlur={() => {
                  setFieldTouched("name");
                }}
                appendContent={
                  <span>
                    {name.length}/{MAX_ALLOWED_LENGTH}
                  </span>
                }
              />
              <TextField
                textArea
                className="mt-4"
                variant="background"
                label="Portfolio Description"
                placeholder="Enter Description Here"
                value={description}
                onChange={(value) => {
                  setFieldValue("description", value);
                }}
                hasError={touchedAndHasError("description")}
                onBlur={() => {
                  setFieldTouched("description");
                }}
              />
              <TextField
                className="mt-4"
                variant="background"
                label="Link"
                placeholder="Add Link Here"
                // value={link}
                value={link ? `https://${link}` : ""}
                onChange={(value) => {
                  if (value.includes("https://")) {
                    value = value.split("https://")[1];
                  }

                  setFieldValue("link", value);
                }}
                hasError={touchedAndHasError("link")}
                onBlur={() => {
                  setFieldTouched("link");
                }}
              />
              <FormError fieldKey="link" className="mt-2" />

              <TagListInput
                useFloatingLabel
                className="mt-4"
                variant="background"
                placeholder="Select Tools"
                inputValue={search}
                onInputChange={setSearch}
                value={tech}
                onChange={(value) => {
                  setFieldValue("tech", value);
                }}
              />
              {!!search && (
                <StyledTagContainer
                  ref={(ref) => {
                    if (ref) ref.scrollIntoView();
                  }}
                  className="mt-4"
                >
                  {tagOptions.map(({ value }) => (
                    <StyledTagButton
                      className={ANIMATION_CLASSNAMES.FADE_IN}
                      variant="secondary"
                      colorVariant="gray"
                      key={value}
                      onClick={() => {
                        setSearch("");
                        setFieldValue("tech", [...tech, value]);
                      }}
                    >
                      {value}
                    </StyledTagButton>
                  ))}
                </StyledTagContainer>
              )}
            </div>

            <StyledCtaSection className="mt-2">
              <Button
                type="reset"
                colorVariant="gray"
                className="cancel-button"
                onClick={onCancel}
                {...cancelButtonProps}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                {...saveButtonProps}
                colorVariant={hasError ? "disabled" : "primary"}
              >
                Save
              </Button>
            </StyledCtaSection>
          </StyledForm>
        );
      }}
    </Form>
  );
};

export default EditPortfolio;
