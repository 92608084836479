import React from "react";

import { useResponsive } from "@/styles";
import OfferSeo from "@/features/contracts/OfferSeo";

import { IContractPreviewProps } from "./ContractPreview.types";
import ContractPreviewMobile from "./ContractPreview.mobile";
import ContractPreviewDesktop from "./ContractPreview.desktop";

const ContractPreview: React.FC<IContractPreviewProps> = (props) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const { contractDetails } = props;

  return (
    <>
      <OfferSeo contractId={contractDetails.id} />

      {isScreenSmallerThanTablet ? (
        <ContractPreviewMobile {...props} />
      ) : (
        <ContractPreviewDesktop {...props} />
      )}
    </>
  );
};

export default ContractPreview;
