import React from "react";

import Button from "@/components/input/Button";
import { usePwa } from "@/services/Pwa";

const Pwa: React.FC = () => {
  const { canInstall, triggerInstall } = usePwa();

  return (
    <div>
      <Button disabled={!canInstall} onClick={triggerInstall}>
        Install
      </Button>
    </div>
  );
};

export default Pwa;
