"use client";

import styled from "@emotion/styled";

import Card from "@/components/data/Card";
import { screenLargerThan } from "@/styles";

export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 1rem;

  ${screenLargerThan.tablet} {
    padding-bottom: 2rem;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  & > * {
    flex: 1;
    padding: 0.75rem 1.25rem;
  }

  & > *:last-child {
    flex: 2;
  }
`;

export const StyledSectionHeading = styled.div`
  color: var(--clr-text-900, #0d0d12);
  font-size: 1rem;
  line-height: 170%;
  font-weight: 700;
`;

export const StyledActionsUserContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  & > *:first-child {
    flex-grow: 10;
  }

  & > *:last-child {
    flex-grow: 1;
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  & > * {
    flex-grow: 1;
    border-radius: 1rem;
    height: 3.5rem;
  }
`;

export const StyledDesktopContainer = styled(Card)`
  padding: 0;
  padding-bottom: 2rem;
  overflow: auto;

  & > *:first-child {
    padding-inline: 1.5rem;
  }
`;

export const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  & > *:first-child {
    flex-grow: 1;
  }
`;
