"use client";

import styled from "@emotion/styled";

import {
  screenLargerThan,
  screenSmallerThan,
  BREAKPOINT_NAMES,
} from "@/styles";
import { Body, Display, Label } from "@/components/Typography";
import AuthForm from "@/features/auth/AuthForm";
import TextField from "@/components/input/TextField";

const SWITCH_BREAKPOINT: BREAKPOINT_NAMES = "smallMonitor";

export const StyledTextField = styled(TextField)`
  gap: 0;
  margin-top: 1.5rem;
  position: relative;

  input {
    padding-inline: 0 0.5rem;
    max-width: calc(100% - 14rem);
    overflow: auto;
  }

  .prefx {
    pointer-events: none;
  }

  .appended {
    position: absolute;
    right: 0;
    background: inherit;
    padding-inline: 0.5rem;
    margin-inline: 0 !important;
  }

  ${screenLargerThan.tablet} {
    margin-top: 2rem;
  }

  ${screenSmallerThan.tablet} {
    margin-bottom: 5rem;
  }
`;

export const StyledContentSection = styled.div`
  flex-grow: 1;

  ${screenLargerThan.tablet} {
    padding-block: 1.5rem;
    display: flex;
    align-items: center;
  }

  ${screenSmallerThan.tablet} {
    height: 100%;
    padding-block-start: 1.5rem;
  }
`;

export const StyledOnboardingContentSection = styled(StyledContentSection)`
  justify-content: center;
  margin-inline: auto;
`;

export const StyledClaimContentSection = styled(StyledContentSection)`
  width: 100%;
  max-width: 640px;
  margin-inline: auto;

  ${screenSmallerThan.tablet} {
    display: flex;
    flex-direction: column;

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }

    .primary-button {
      margin-top: auto !important;
    }
  }

  ${screenLargerThan.tablet} {
    .primary-button {
      margin-top: 2rem;
    }
  }
`;

export const StyledContentContainer = styled.div`
  text-align: center;
`;

export const StyledHeading = styled(Display)`
  color: var(--clr-text-900, #0d0d12);

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 2.25rem !important;
    line-height: 2.75rem !important;
  }
`;

export const StyledContent = styled(Label)`
  color: var(--clr-ukwn-3, #a4acb9);

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 1.25rem !important;
    line-height: 2rem !important;
    margin-top: 1.5rem;
  }

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    margin-top: 1rem;
  }
`;

export const StyledAuthForm = styled(AuthForm)`
  margin-top: 1rem;

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    margin-top: 2.5rem;
  }
`;

export const StyledError = styled(Body)`
  color: #f04438;
`;