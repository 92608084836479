import { Item } from "@/components/navigation/TabBar";
import { SITE_PATHS } from "@/config/routing";

export const TAB_BAR_ITEMS: Item[] = [
  {
    id: "Home",
    icon: "/assets/images/icons/home.svg",
    link: SITE_PATHS.HOME_PAGE,
    title: "Home",
  },
  {
    id: "Projects",
    icon: "/assets/images/icons/contracts.svg",
    link: SITE_PATHS.CONTRACTS_PAGE,
    title: "Jobs",
  },
  {
    id: "New Offer",
    icon: "/assets/images/icons/plus.svg",
    link: SITE_PATHS.CREATE_CONTRACT_PAGE,
    title: "Create Offer",
    primary: true,
    iconProps: {
      customSize: "1.25rem",
    },
  },
  {
    id: "Chat",
    icon: "/assets/images/icons/chat.svg",
    link: SITE_PATHS.CHAT_PAGE,
    title: "Chat",
  },
  {
    id: "Notifications",
    icon: "/assets/images/icons/bell.svg",
    link: SITE_PATHS.NOTIFICATIONS_PAGE,
    title: "Notifications",
  },
];
