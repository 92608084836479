import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";

import Card from "@/components/data/Card";
import { ContractType, amIContractPayee } from "@/services/ContractsService";
import Tabs from "@/components/navigation/Tabs";

import { IContractPreviewProps } from "./ContractPreview.types";
import {
  StyledCardsContainer,
  StyledContainer,
  StyledContentContainer,
  StyledCtaCard,
  StyledCtaSection,
} from "./ContractPreview.styles";
import MainDetailsSection from "./components/MainDetailsSection";
import DetailsSection from "./components/DetailsSection";
import PaymentSummary from "./components/PaymentSummary";
import FilesSection from "./components/FilesSection";
import MilestonesSection from "./components/MilestonesSection";
import CtaSection from "./components/CtaSection";
import OtherUserDetails from "./components/OtherUserDetails";

enum Tab {
  Details = "Details",
  Milestones = "Milestones",
}

const TABS = [
  {
    id: Tab.Details,
    title: "Details",
  },
  {
    id: Tab.Milestones,
    title: "Milestones",
  },
];

const StyledDetailsCard = styled(Card)`
  overflow: hidden;

  & > * {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 24rem;

    & > *:last-child {
      flex: 1;
      overflow: auto;
    }
  }
`;

const ContractPreview: React.FC<IContractPreviewProps> = ({
  contractDetails,
  className,
  ctaContent,
  showCreationDate,
}) => {
  const { contract_type } = contractDetails;
  const [activeTab, setActiveTab] = useState<string>(Tab.Details);

  const isMilestoneContract = contract_type === ContractType.Milestone;

  const amIPayee = useMemo(
    () => amIContractPayee(contractDetails),
    [contractDetails]
  );

  return (
    <StyledContainer className={className}>
      <StyledContentContainer>
        <StyledCardsContainer>
          <div>
            <Card>
              <MainDetailsSection
                contractDetails={contractDetails}
                showCreationDate={showCreationDate}
              />
            </Card>

            <FilesSection contractDetails={contractDetails} />

            {isMilestoneContract ? (
              <StyledDetailsCard style={{ flex: "1" }}>
                <div>
                  <Tabs
                    activeTabId={activeTab}
                    onChange={({ id }) => setActiveTab(id)}
                    tabs={TABS}
                  />
                  {activeTab === Tab.Details && (
                    <DetailsSection 
                    className="mt-3" contractDetails={contractDetails} />
                  )}
                  {activeTab === Tab.Milestones && (
                    <MilestonesSection
                      className="mt-3"
                      contractDetails={contractDetails}
                    />
                  )}
                </div>
              </StyledDetailsCard>
            ) : (
              <Card style={{ flex: "1" }}>
                <DetailsSection contractDetails={contractDetails} />
              </Card>
            )}
          </div>

          <div>
            <Card>
              <OtherUserDetails contractDetails={contractDetails} />
            </Card>

            <Card>
              <PaymentSummary contractDetails={contractDetails} />
            </Card>

            {ctaContent ? (
              <StyledCtaSection>{ctaContent}</StyledCtaSection>
            ) : (
              amIPayee && (
                <StyledCtaCard>
                  <CtaSection
                    className="w-100"
                    contractDetails={contractDetails}
                  />
                </StyledCtaCard>
              )
            )}
          </div>
        </StyledCardsContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default ContractPreview;
