import React, { useCallback, useRef } from "react";

import RadioGroup, {
  RenderFunctionParams,
} from "@/components/input/RadioGroup";
import Icon from "@/components/misc/Icon";

import {
  StyledItem,
  StyledContainer,
  StyledContent,
  StyledIconContainer,
  StyledSelectedIconContainer,
  StyledDot,
} from "./RadioCardGroup.styles";
import { IRadioCardGroupProps, Item } from "./RadioCardGroup.types";

const RadioCardGroup: React.FC<IRadioCardGroupProps> = ({
  label,
  value,
  onChange,
  items,
  className,
  itemStyles = {},
}) => {
  const scrolledToSelectedValueRef = useRef(false);

  const render = useCallback(
    ({
      isSelected,
      children,
      labelProps,
      inputProps,
      inputRef,
      icon,
      disabled = false,
      dotColor,
      value,
    }: RenderFunctionParams & Item) => {
      return (
        <StyledItem
          ref={(ref) => {
            if (isSelected && !scrolledToSelectedValueRef.current) {
              scrolledToSelectedValueRef.current = true;
              ref?.scrollIntoView({ block: "center" });
            }
          }}
          {...labelProps}
          $isSelected={isSelected}
          $disabled={disabled}
          style={itemStyles}
          key={value}
        >
          <input {...inputProps} ref={inputRef} disabled={disabled} />
          {!!dotColor && <StyledDot $dotColor={dotColor} />}

          {!!icon && (
            <StyledIconContainer $isSelected={isSelected}>
              <Icon src={icon} size="sm" />
            </StyledIconContainer>
          )}

          <StyledContent $isSelected={isSelected}>{children}</StyledContent>
          <StyledSelectedIconContainer
            $isSelected={isSelected}
            $disabled={disabled}
          >
            {isSelected && (
              <Icon
                isSrcRelative
                src="check.svg"
                size="xxs"
                customSize="0.75rem"
              />
            )}
          </StyledSelectedIconContainer>
        </StyledItem>
      );
    },
    [itemStyles]
  );

  return (
    <StyledContainer
      label={label}
      value={value}
      onChange={onChange}
      className={className}
      renderRadio={render as any}
    >
      {items.map((item, i) => (
        <RadioGroup.Radio key={i} {...item} />
      ))}
    </StyledContainer>
  );
};

export default RadioCardGroup;
