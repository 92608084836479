import React, { Fragment, useMemo, useState } from "react";

import Tabs from "@/components/navigation/Tabs";
import TextField from "@/components/input/TextField";
import ContractCard, {
  ContractCardLoading,
} from "@/features/contracts/ContractCard";
import {
  getContractOtherParticipant,
  isContractAnOffer,
  useContractList,
} from "@/services/ContractsService";
import ChatCard, {
  ChatCardLoading,
  IChatCardProps,
} from "@/features/chat/ChatCard";

import { StyledContainer, StyledContentContainer } from "./GlobalSearch.styles";
import { areStringsSubstring } from "@/utils/string";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { Label } from "@/components/Typography";

const GlobalSearch: React.FC<{ className?: string }> = ({ className }) => {
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("Offers");

  const { data: contractList, isLoading: isContractListLoading } =
    useContractList();

  const hasNoContracts = !contractList.length && !isContractListLoading;

  const filteredContracts = useMemo(() => {
    return contractList.filter(({ title }) =>
      areStringsSubstring(search, title)
    );
  }, [contractList, search]);

  const chatCardProps = useMemo(() => {
    return contractList
      .map((c) => {
        const otherUser = getContractOtherParticipant(c);

        return {
          contractId: c.id,
          avatarImage: otherUser?.user?.picture || "",
          username:
            otherUser?.user?.given_name ||
            otherUser?.user?.email ||
            "",
          title: c.title,
          content: "Last message missing!",
          lastMessageDate: new Date(),
          isOffer: isContractAnOffer(c),
        } satisfies IChatCardProps;
      })
      .filter(
        ({ title, username }) =>
          areStringsSubstring(search, title) ||
          areStringsSubstring(search, username)
      ) as IChatCardProps[];
  }, [contractList, search]);

  const contractCardsJsx = isContractListLoading ? (
    <>
      <ContractCardLoading />
      <hr />
      <ContractCardLoading />
      <hr />
      <ContractCardLoading />
    </>
  ) : (
    filteredContracts.map((contract, i) => {
      return (
        <Fragment key={i}>
          {i !== 0 && <hr />}
          <ContractCard
            className={ANIMATION_CLASSNAMES.FADE_IN}
            key={contract.id}
            contract={contract}
          />
        </Fragment>
      );
    })
  );

  const contractTabContent = hasNoContracts ? null : contractCardsJsx;

  const chatCardsJsx = isContractListLoading ? (
    <>
      <ChatCardLoading />
      <hr />
      <ChatCardLoading />
      <hr />
      <ChatCardLoading />
    </>
  ) : (
    chatCardProps.map((chat, i) => {
      return (
        <React.Fragment key={i}>
          {i !== 0 && <hr />}
          <ChatCard
            key={i}
            {...chat}
            className={ANIMATION_CLASSNAMES.FADE_IN}
          />
        </React.Fragment>
      );
    })
  );

  const chatTabContent = hasNoContracts ? null : chatCardsJsx;

  //------------------------------------

  return (
    <StyledContainer className={className}>
      <TextField
        useFloatingLabel
        variant="background"
        value={search}
        onChange={setSearch}
        placeholder="Search"
      />

      <Tabs
        activeTabId={activeTab}
        onChange={({ id }) => setActiveTab(id)}
        tabs={[
          {
            id: "Offers",
            title: "Offers",
          },
          {
            id: "Chats",
            title: "Chats",
          },
        ]}
      />

      <StyledContentContainer>
        <Label size="lg">Results</Label>
        {activeTab === "Offers" && contractTabContent}
        {activeTab === "Chats" && chatTabContent}
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default GlobalSearch;
