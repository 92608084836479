"use client";

import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";

export const StyledContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const StyledCurrenyField = styled(TextField)`
  width: 98px;
`;

export const StyledCurrency = styled.span`
  display: none;
  position: absolute;
  left: 2rem;
  color: var(--clr-neutral-solid-600, #31353f);
`;

export const StyledUnit = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
`;


export const StyledPriceField = styled(TextField)<{
  $showCurreny?: boolean;
  $currencyOnRight: boolean;
}>`
  flex: 1;

  &:focus-within {
    ${({ $currencyOnRight }) =>
      $currencyOnRight
        ? ""
        : `
            input {
              padding-left: 2.75rem;
            }
          `}

    .currency {
      display: block;
    }
  }

  ${({ $showCurreny, $currencyOnRight }) =>
    $showCurreny
      ? `
      ${
        $currencyOnRight
          ? ""
          : `
          input {
            padding-left: 2.75rem;
          }
        `
      }
          
          
          .currency{
            display: block;
          }
        `
      : ""}
`;
