import React, { useMemo } from "react";

import DetailsTable, { Item } from "@/components/data/DetailsTable";
import { ContractType } from "@/services/ContractsService";
import { getOrdinalString } from "@/utils/number";

import { IContractPaymentSummaryProps } from "./ContractPaymentSummary.types";
import { StyledHintText, StyledHr } from "./ContractPaymentSummary.styles";

const ContractPaymentSummary: React.FC<IContractPaymentSummaryProps> = ({
  contractDetails,
  className,
}) => {
  const { total_value, milestones, contract_type } = contractDetails;

  const isOneTimeContract = contract_type === ContractType.OneTime;
  const isMilestoneContract = contract_type === ContractType.Milestone;

  const fields: Item[] = useMemo(() => {
    if (isMilestoneContract) {
      const fields: Item[] = milestones.map(({ value }, i) => {
        const isFirstMilestone = i === 0;

        return {
          label: (
            <>
              <span style={{ textTransform: "capitalize" }}>
                {getOrdinalString(i + 1)}
              </span>{" "}
              milestone
            </>
          ),
          value: `$${value}`,
          labelProps: { style: { fontWeight: 600 } },
          valueProps: {
            style: {
              ...(isFirstMilestone
                ? {}
                : { color: "var(--clr-icon-text-200, #818898)" }),
            },
          },
          border: false,
        } satisfies Item;
      });

      fields.push({
        label: (
          <>
            <div
              style={{ fontWeight: 700, color: "var(--clr-text-900, #0d0d12)" }}
            >
              To be paid now
            </div>
            <StyledHintText>
              You can only fund one milestone at a time.
            </StyledHintText>
          </>
        ),
        value: `* $${milestones[0].value}`,
        valueProps: {
          style: { fontWeight: 700 },
        },
      });

      return fields;
    }

    if (isOneTimeContract) {
      const totalValue =
        total_value ||
        milestones.reduce((total, { value }) => total + +(value || "0"), 0);

      return [
        {
          label: "Total Amount Due",
          value: `$${totalValue}`,
          valueProps: { style: { fontWeight: 700 } },
        },
      ];
    }

    return [];
  }, [isOneTimeContract, isMilestoneContract, total_value, milestones]);

  if (!fields.length) {
    return null;
  }

  return (
    <>
      <DetailsTable className={className} items={fields} />

      {isOneTimeContract && (
        <>
          <StyledHintText>
            You may be charged processing fees at time of payment
          </StyledHintText>
          <StyledHr />
        </>
      )}
    </>
  );
};

export default ContractPaymentSummary;
