"use client";

import styled from "@emotion/styled";

export const StyledHeading = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--clr-text-ukwn-8, #0f172a);
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;
