import React from "react";
import lodashGet from "lodash/get";

import PageWithHeaderLayout, {
  HeaderButton,
} from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";

import { useContractForm } from "../../../ContractForm.context";
import { STEPS_CONFIG } from "../../../ContractForm.config";
import { IHeaderLayoutProps } from "./HeaderLayout.types";
import { Step } from "../../../ContractForm.types";
import { useResponsive } from "@/styles";

const HeaderLayout: React.FC<IHeaderLayoutProps> = (props) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { headerProps = {} } = props;

  const { previousStep, currentStep, values } = useContractForm();

  const isDetailsStepActive = currentStep === Step.ContractDetails;
  const titleFromConfig = lodashGet(STEPS_CONFIG, `[${currentStep}].title`, "");
  const stepTitle = isDetailsStepActive
    ? values.title || titleFromConfig
    : titleFromConfig;

  if (!isScreenSmallerThanTablet) {
    return props.children;
  }

  return (
    <PageWithHeaderLayout
      {...props}
      headerProps={{
        ...headerProps,
        titleProps: { children: stepTitle },
        backButtonProps: {
          onClick: previousStep,
        },
        appendContent: (
          <HeaderButton>
            <Icon isSrcRelative src="question.svg" size="xs" />
          </HeaderButton>
        ),
      }}
    />
  );
};

export default HeaderLayout;
