"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  --cell-size: 44px;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-inline: 0.5rem;
`;

export const StyledCalendarSection = styled.div`
  flex: 1;
  margin-inline: calc(-1 * 100% / 14 + var(--cell-size) / 2);
`;

export const StyledTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-text-900, #0d0d12);
  margin: 0;
`;
