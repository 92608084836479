import React from "react";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { usePushNotificationPermission } from "@/services/PushNotifications";

const PushNotificationButton: React.FC = () => {
  const { isEnabled, prompt, isSupported } = usePushNotificationPermission();

  if (!isSupported) {
    return null;
  }

  return (
    <Button variant={isEnabled ? "primary" : "secondary"} onClick={prompt}>
      {isEnabled ? "Push Notifications enabled" : "Enable Push Notifications ?"}

      {isEnabled && (
        <Icon
          isSrcRelative
          src="tick.svg"
          size="xs"
          colorVariant={isEnabled ? "white" : "primary"}
        />
      )}
    </Button>
  );
};

export default PushNotificationButton;
