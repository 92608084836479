import React from "react";

import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";
import ReviewCard from "@/features/user/ReviewCard";
import { appFeatures } from "@/config/services";
import ImageTitleContent from "@/components/data/ImageTitleContent";

import { StyledFiltersContainer } from "../ProfilePage.styles";

const ReviewSection: React.FC = () => {
  if (!appFeatures.isSupported("ACCOUNT.REVIEWS")) {
    return (
      <ImageTitleContent
        className="mt-5 mb-5"
        title="No reviews"
        content={<>You don't have any reviews yet</>}
        image="/assets/images/pages/contracts/no_contracts.svg"
      />
    );
  }

  return (
    <div className="">
      <Body size="md" style={{ color: "var(--clr-icon-text-200, #818898)" }}>
        Overall rating
      </Body>

      <StyledFiltersContainer className="mt-4">
        <Button>All Stars</Button>
        <Button variant="secondary" colorVariant="gray">
          5 Stars
        </Button>
        <Button variant="secondary" colorVariant="gray">
          4 Stars
        </Button>
        <Button variant="secondary" colorVariant="gray">
          3 Stars
        </Button>
        <Button variant="secondary" colorVariant="gray">
          2 Stars
        </Button>
        <Button variant="secondary" colorVariant="gray">
          1 Star
        </Button>
      </StyledFiltersContainer>

      <ReviewCard className="mt-4" />
      <hr className="mt-3" />
      <ReviewCard className="mt-4" />
      <hr className="mt-3" />
      <ReviewCard className="mt-4" />
      <hr className="mt-3" />
      <ReviewCard className="mt-4" />
      <hr className="mt-3" />
      <ReviewCard className="mt-4" />
      <hr className="mt-3" />
      <ReviewCard className="mt-4" />
    </div>
  );
};

export default ReviewSection;
