import { Label } from "@/components/Typography";
import { screenLargerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledCard = styled.div`
  border: 2px solid var(--clr-primary-100, #5f57ff);
  border-radius: 1rem;
  max-width: 290px;
  margin: 0 auto;

  ${screenLargerThan.tablet} {
    max-width: 23rem;
  }
`;

export const StyledHeader = styled.div`
  position: relative;
  padding: 1.5rem 0.5rem;
  z-index: 1;
  background: var(--clr-primary-100, #5f57ff);
  text-align: center;
  color: var(--clr-text-white, #fff);
  border-radius: calc(1rem - 4px);
`;

export const StyledPrimaryContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & > *:first-child {
    /* align-self: flex-start; */
  }
`;

export const StyledContent = styled.div`
  padding: 2rem 1rem;
`;

export const StyledCostContainer = styled.div`
  position: relative;
`;

export const StyledOriginalCost = styled(Label)`
  position: absolute;
  left: 50%;
  top: -1.25rem;
  transform: translate(-50%);
  color: var(--clr-primary-0, #f0efff);
  border-radius: 0.5rem;
  text-decoration: line-through;
`;
