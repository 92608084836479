"use client";

import React from "react";

import Icon from "@/components/misc/Icon";
import Drawer from "@/components/misc/Drawer";
import RadioCardGroup from "@/components/input/RadioCardGroup";
import {
  ContractSortFilters,
  ContractStatus,
} from "@/services/ContractsService";

import {
  StyledButton,
  StyledFiltersContainer,
} from "../ContractsListPage.styles";
import { useContractsListPage } from "../ContractsListPage.context";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { joinClassNames } from "@/utils/classNames";

const ContractFilters: React.FC = () => {
  const { sortPanelState, filterPanelState, filters, setFilters, activeTab } =
    useContractsListPage();

  const { sort, status } = filters;
  const sortText =
    sort === "created_at:asc"
      ? "Creation Date"
      : sort === "title:asc"
      ? "A-Z"
      : "";

  const filterText =
    status === ContractStatus.PendingFunding
      ? "Pending Approval"
      : status === ContractStatus.Active
      ? "In Progress"
      : "All";

  return (
    <>
      <StyledFiltersContainer className="mt-3">
        {activeTab === "Active" && (
          <StyledButton
            variant="secondary"
            colorVariant="gray"
            onClick={filterPanelState.open}
            className={ANIMATION_CLASSNAMES.FADE_IN}
          >
            <Icon isSrcRelative src="sort.svg" size="xs" /> {filterText}
          </StyledButton>
        )}

        <StyledButton
          variant="secondary"
          colorVariant="gray"
          onClick={sortPanelState.open}
          className={joinClassNames("ml-auto", ANIMATION_CLASSNAMES.FADE_IN)}
        >
          Sort by: {sortText}
        </StyledButton>
      </StyledFiltersContainer>

      <Drawer state={sortPanelState} includeTabBarHeight={false}>
        <RadioCardGroup
          className="p-3"
          value={sort}
          label="contract sort by"
          onChange={(value) => {
            setFilters((filters) => ({
              ...filters,
              sort: value as ContractSortFilters,
            }));
            setTimeout(() => {
              sortPanelState.close();
            }, 100);
          }}
          items={[
            {
              value: "title:asc" as ContractSortFilters,
              children: "A-Z",
            },
            {
              value: "created_at:asc" as ContractSortFilters,
              children: "Creation date",
            },
          ]}
        />
      </Drawer>

      <Drawer state={filterPanelState} includeTabBarHeight={false}>
        <RadioCardGroup
          className="p-3"
          value={filters.status || ""}
          label="contract type"
          onChange={(value) => {
            setFilters((filters) => ({
              ...filters,
              status: value as ContractStatus,
            }));
            setTimeout(() => {
              filterPanelState.close();
            }, 100);
          }}
          items={[
            {
              value: "",
              children: "All",
            },
            {
              value: ContractStatus.Active,
              children: "In progress",
            },
            {
              value: ContractStatus.PendingFunding,
              children: "Pending approval",
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default ContractFilters;
