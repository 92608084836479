import React from "react";

import Icon from "@/components/misc/Icon";
import { useNavigate } from "@/services/Routing";

import {
  StyledEndContainer,
  StyledHeader,
  StyledStartContainer,
  StyledTitle,
} from "./Header.styles";
import { IHeaderProps } from "./Header.types";
import Button from "../Button";
import { StyledContentContainer } from "../../PageWithHeaderLayout.styles";

const Header: React.FC<IHeaderProps> = ({
  titleProps = {},
  backButtonProps = {},
  prependContent,
  appendContent,
  endContainerProps = {},
  style,
}) => {
  const { back } = useNavigate();
  const { children } = titleProps;
  const { show = true } = backButtonProps;

  return (
    <StyledContentContainer style={style}>
      <StyledHeader>
        <StyledStartContainer>
          {prependContent}

          {show && (
            <Button
              onClick={backButtonProps?.link ? undefined : back}
              {...backButtonProps}
            >
              {backButtonProps?.children || (
                <Icon
                  isSrcRelative
                  src="chevron_left.svg"
                  customSize="0.75rem"
                  colorVariant="black"
                />
              )}
            </Button>
          )}
        </StyledStartContainer>

        <StyledTitle style={titleProps.style}>{children}</StyledTitle>

        <StyledEndContainer {...endContainerProps}>
          {appendContent}
        </StyledEndContainer>
      </StyledHeader>
    </StyledContentContainer>
  );
};

export default Header;
