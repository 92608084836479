"use client";

import styled from "@emotion/styled";

export const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  height: 100%;

  & > tbody {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & > tr {
      flex: 1;
    }
  }

  tr {
    display: flex;
    justify-content: space-between;
  }

  th {
    flex: 1;
    width: 44px;
    color: var(--clr-text-300, #a4abb8);
    font-weight: 400;
    padding-block: 0.5rem;
    font-size: 0.875rem;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 8px;
  }

  .header h2 {
    flex: 1;
    margin: 0;
  }
`;
