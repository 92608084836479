import React from "react";

import { appFeatures } from "@/config/services";

import AblyChatRoom from "./ChatRoom.ably";
import StreamChatRoom from "./ChatRoom.stream";

const ChatRoom: React.FC = () => {
  if (appFeatures.isSupported("CHAT.USE_STREAMIO")) {
    return <StreamChatRoom />;
  }

  return <AblyChatRoom />;
};

export default ChatRoom;
