import React, { useState } from "react";

import Tabs from "@/components/navigation/Tabs";
import CalendarComponent, {
  SingleDateValue,
  RangeDateValue,
} from "@/components/input/Calendar";
import DateField from "@/components/input/DateField";

const Calendar: React.FC = () => {
  const [activeTabId, setActiveTabId] = useState("single-calendar");

  const [dateFieldSigleValue, setDateFieldSigleValue] =
    useState<SingleDateValue>();
  const [dateFieldRangeValue, setDateFieldRangeValue] =
    useState<RangeDateValue>();

  return (
    <div>
      <Tabs
        activeTabId={activeTabId}
        onChange={({ id }) => setActiveTabId(id)}
        tabs={[
          {
            id: "single-calendar",
            title: "Single date calendar",
          },
          {
            id: "range-calendar",
            title: "Range calendar",
          },
          {
            id: "single-calendar-field",
            title: "Single date calendar",
          },
          {
            id: "range-calendar-field",
            title: "Range calendar",
          },
        ]}
      />

      {activeTabId === "range-calendar" && <CalendarComponent type="range" />}

      {activeTabId === "single-calendar" && (
        <CalendarComponent type="single-date" />
      )}

      {activeTabId === "range-calendar-field" && (
        <DateField
          calendarType="range"
          calendarProps={{
            type: "range",
            rangeValue: dateFieldRangeValue,
            onRangeChange: setDateFieldRangeValue,
          }}
        />
      )}

      {activeTabId === "single-calendar-field" && (
        <DateField
          calendarType="single-date"
          calendarProps={{
            type: "single-date",
            value: dateFieldSigleValue,
            onChange: setDateFieldSigleValue,
          }}
        />
      )}
    </div>
  );
};

export default Calendar;
