import MilestonesDisplay from "@/features/contracts/MilestonesDisplay";

import { useContractManagement } from "../ContractManagementPage.context";
import { StyledSectionHeading } from "../ContractManagementPage.styles";

const MilestonesDetails: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails } = useContractManagement();

  const { milestones } = contractDetails;

  return (
    <div className={className}>
      <StyledSectionHeading>Milestones</StyledSectionHeading>
      <MilestonesDisplay milestones={milestones} className="mt-3" />
    </div>
  );
};

export default MilestonesDetails;
