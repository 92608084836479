import React from "react";

import ContractsOverview from "@/features/contracts/ContractsOverview";

import { StyledCard, StyledSecondaryHeading } from "../HomePage.styles";

const ContractsOverviewCard: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <StyledCard className={className}>
      <StyledSecondaryHeading>Jobs</StyledSecondaryHeading>
      <ContractsOverview />
    </StyledCard>
  );
};

export default ContractsOverviewCard;
