"use client";

import styled from "@emotion/styled";

import { Link } from "react-router-dom";

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 1.25rem;
  min-height: 6.25rem;
`;

export const StyledTitle = styled.div`
  color: var(--clr-text-900, #0d0d12);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;