import Icon from "@/components/misc/Icon";
import useToggle from "@/hooks/useToggle";
import { analytics } from "@/config/services";

import {
  StyledCard,
  StyledCloseButton,
  StyledContent,
  StyledLink,
  StyledTitle,
} from "./GetExpertSupportCard.styles";

const GetExpertSupportCard: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { disable: hide, state: shouldShow } = useToggle({
    initialValue: true,
  });

  if (!shouldShow) {
    return null;
  }

  return (
    <StyledCard
      className={className}
      onClick={() => {
        analytics.triggerEvent({ event: "need_support" });
      }}
    >
      <StyledTitle size="md">
        <Icon isSrcRelative src="headset.svg" size="sm" />

        <span className="capitalize">Need support?</span>
      </StyledTitle>

      <StyledCloseButton variant="ghost" onClick={hide}>
        <Icon isSrcRelative src="cross.svg" customSize="0.65rem" />
      </StyledCloseButton>

      <StyledContent size="md" className="mt-1">
        Contact with one of our experts to get support.
      </StyledContent>

      <StyledLink to="mailto:help@paypipe.io " />
    </StyledCard>
  );
};

export default GetExpertSupportCard;
