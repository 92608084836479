"use client";

import { screenLargerThan, screenSmallerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledPage = styled.div`
  ${screenLargerThan.widescreen} {
    height: 100%;
  }

  ${screenSmallerThan.widescreen} {
    padding: 1rem;
    overflow: auto;
  }
`;
