import styled from "@emotion/styled";

export const StyledFeatureItem = styled.div`
  padding: 1rem;
  padding-top: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4.5px 10px #ecebfc;
  min-width: 16.125rem;
  background: var(--clr-background-white, #fff);
`;

export const StyledFeatureTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--clr-primary-200, #312ba0);
  margin: 0;
`;

export const StyledFeatureContent = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--clr-primary-light, #7e7bc3);
  margin: 0;
`;

export const StyledFeatureImage = styled.img`
  display: block;
  position: relative;
  z-index: 1;
  height: 80px;
  width: auto;
  margin: 0 auto;
`;
