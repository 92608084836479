"use client";

import styled from "@emotion/styled";

export const StyledHr = styled.hr`
  opacity: 0.1;
  margin-bottom: 0;
`;

export const StyledHintText = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  font-weight: 300;
  color: var(--clr-icon-text-200, #818898);
  font-style: italic;
`;
