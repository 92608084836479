import React, { useMemo } from "react";
import styled from "@emotion/styled";

import Tabs from "@/components/navigation/Tabs";
import { joinClassNames } from "@/utils/classNames";
import { ANIMATION_CLASSNAMES, screenLargerThan } from "@/styles";

import { PROFILE_TABS, useProfileForm } from "../ProfileForm.config";
import { Tab } from "../ProfileForm.types";

const StyledContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > *:last-child {
      flex-grow: 1;
      overflow: auto;
      padding-right: 1rem;
      margin-right: -1rem;
    }
  }
`;

const ProfileTabs: React.FC<{ className?: string }> = ({ className }) => {
  const { activeTab, openTab } = useProfileForm();

  const activeContent = useMemo(
    () =>
      PROFILE_TABS.find(
        (item) => item.id.toLocaleLowerCase() === activeTab.toLocaleLowerCase()
      )?.content || null,
    [activeTab]
  );

  return (
    <StyledContainer className={className}>
      <Tabs
        activeTabId={activeTab}
        onChange={({ id }) => openTab(id as Tab)}
        tabs={PROFILE_TABS}
      />
      <div
        className={joinClassNames("mt-3", ANIMATION_CLASSNAMES.FADE_IN)}
        key={activeTab}
      >
        {activeContent}
      </div>
    </StyledContainer>
  );
};

export default ProfileTabs;
