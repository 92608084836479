import React, { useCallback, useState } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";
import { Value } from "@/features/contracts/ContractWorkInput";
import Button from "@/components/input/Button";
import {
  useContractDetails,
  useSubmitContractMilestone,
} from "@/services/ContractsService";

import { StyledButtomSmall } from "../../ContractTimeline.styles";
import {
  StyledBottomSection,
  StyledContainer,
  StyledContent,
  StyledInput,
} from "./SubmitWorkButton.styles";

interface ISubmitWorkButtonProps {
  contractId: string;
  milestoneId: number;
  className?: string;
}

const SubmitWorkButton: React.FC<ISubmitWorkButtonProps> = ({
  contractId,
  milestoneId,
  className,
}) => {
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const modalState = useModalState();

  const [value, setValue] = useState<Value>({
    attachments: [],
    content: "",
  });

  const { submit, isSubmitting } = useSubmitContractMilestone({
    contractId,
    milestoneId,
  });

  const handleSubmit = useCallback(() => {
    submit({
      attachments: value.attachments,
      description: value.content,
    }).then(() => {
      modalState.close();
      reloadContractDetails();
    });
  }, [submit, value, modalState, reloadContractDetails]);

  return (
    <>
      <StyledButtomSmall
        disabled={isSubmitting}
        className={className}
        onClick={modalState.open}
      >
        Submit Work
      </StyledButtomSmall>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
      >
        <PageWithHeaderLayout
          headerProps={{
            backButtonProps: {
              onClick: modalState.close,
              children: <Icon isSrcRelative src="cross.svg" size="xxs" />,
              style: { background: "none" },
            },
            titleProps: {
              children: "Submit Your Work",
            },
          }}
        >
          <StyledContainer>
            <StyledInput value={value} onChange={setValue} />

            <StyledBottomSection>
              <StyledContent>
                By submitting this work you are accepting that the client may
                have revisions before this job is completed.
              </StyledContent>

              <Button
                className="w-100 mt-3"
                onClick={handleSubmit}
                disabled={isSubmitting || !value.content}
              >
                Submit Work
              </Button>
            </StyledBottomSection>
          </StyledContainer>
        </PageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default SubmitWorkButton;
