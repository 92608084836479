import React, { useCallback, useEffect, useMemo, useState } from "react";

import TextField from "@/components/input/TextField";
import Icon from "@/components/misc/Icon";
import { areStringsSubstring } from "@/utils/string";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import {
  OnboardStripeApiParams,
  useClientCountry,
  useOnboardStripe,
  useStripeOnboardingCountries,
} from "@/services/UserService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { Body } from "@/components/Typography";
import { STRIPE_PAK_ONBOARDING_VIDEO_LINK } from "@/config/app";

import {
  StyledButtonsContainer,
  StyledContainer,
  StyledPageWithHeaderLayout,
  StyledRadioCardGroup,
  StyledRadioCardGroupLoading,
} from "./CountryInput.styles";
import { COUNTRY_LIST } from "./CountryInput.content";

import { getCountryDetailsFromCountryCode } from "./CountryInput.utils";
import PakCountryOnboardingPrompt from "./components/PakCountryOnboardingPrompt";

const CountryInput: React.FC<{
  stripeOnboardParams?: Partial<OnboardStripeApiParams>;
  className?: string;
}> = ({ stripeOnboardParams = {}, className }) => {
  const { onboard, isOnboarding } = useOnboardStripe();
  const { isLoading: isLoadingClientCountry, countryCode: clientCountryCode } =
    useClientCountry();

  const modalState = useModalState();
  const confirmationModalState = useModalState();
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(
    clientCountryCode
      ? getCountryDetailsFromCountryCode(clientCountryCode)?.code || ""
      : ""
  );

  const pakOnboardingModalState = useModalState();

  const { data: countryListFromHook, isLoading: isLoadingCountryList } =
    useStripeOnboardingCountries();

  const countryList = useMemo(() => {
    return COUNTRY_LIST.filter(({ code }) =>
      countryListFromHook.includes(code)
    );
  }, [countryListFromHook]);

  const country = useMemo(() => {
    if (!search) {
      return countryList;
    }

    return countryList.filter(
      ({ name, dial_code, code }) =>
        areStringsSubstring(name, search) ||
        areStringsSubstring(dial_code, search) ||
        areStringsSubstring(code, search)
    );
  }, [search, countryList]);

  const options = useMemo(() => {
    return country.map(({ code, flag, name }) => ({
      children: (
        <>
          {flag} {name}
        </>
      ),
      value: code,
    }));
  }, [country]);

  const selectedCountryDetails = useMemo(() => {
    return COUNTRY_LIST.find(({ code }) => code === selectedCountry) || null;
  }, [selectedCountry]);

  //--------------------------

  const handleOnboard = useCallback(() => {
    onboard({ ...stripeOnboardParams, countryCode: selectedCountry });
  }, [onboard, selectedCountry, stripeOnboardParams]);

  //--------------------------

  useEffect(() => {
    if (selectedCountry || !clientCountryCode) {
      return;
    }

    setSelectedCountry(clientCountryCode);
  }, [clientCountryCode, selectedCountry]);

  //--------------------------

  const loadingJsx = (
    <>
      <ShimmerLoader.Default
        style={{ width: "100%", height: "3.5rem", borderRadius: "0.75rem" }}
      />

      <StyledRadioCardGroupLoading
        itemCount={8}
        itemStyles={{
          minHeight: "3rem",
          paddingBlock: "0.5rem",
          borderRadius: "4px",
        }}
      />
    </>
  );

  const contentJsx = (
    <>
      <TextField
        useFloatingLabel={false}
        variant="background"
        type="search"
        placeholder="Search country..."
        prependContent={
          <Icon src="/assets/images/icons/search.svg" alt="Search" size="sm" />
        }
        value={search}
        onChange={setSearch}
      />

      <StyledRadioCardGroup
        label="contract-type"
        value={selectedCountry}
        onChange={setSelectedCountry}
        items={options}
        itemStyles={{
          minHeight: "3rem",
          paddingBlock: "0.5rem",
          borderRadius: "4px",
        }}
      />
    </>
  );

  if (isOnboarding || isLoadingClientCountry) {
    return <SplashScreen />;
  }

  return (
    <>
      <div onClick={modalState.open} className={className}>
        <TextField
          useFloatingLabel={false}
          variant="background"
          type="search"
          placeholder="Search country..."
          prependContent={
            <Icon
              src="/assets/images/icons/search.svg"
              alt="Search"
              size="sm"
            />
          }
          style={{ pointerEvents: modalState.isOpen ? "all" : "none" }}
        />
      </div>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
        desktopProps={{ width: "768px" }}
      >
        <StyledPageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Select country" },
            backButtonProps: {
              onClick: modalState.close,
            },
          }}
        >
          <StyledContainer>
            {isLoadingCountryList ? loadingJsx : contentJsx}

            <Button
              disabled={!selectedCountry || isOnboarding}
              onClick={() => {
                if (selectedCountry.toLocaleLowerCase() === "pk") {
                  pakOnboardingModalState.open();
                } else {
                  confirmationModalState.open();
                }
              }}
            >
              {isOnboarding ? "Loading..." : "Continue"}
            </Button>
          </StyledContainer>
        </StyledPageWithHeaderLayout>
      </Modal>

      <Modal state={confirmationModalState} desktopProps={{ width: "340px" }}>
        <Body size="lg">
          Continue with {selectedCountryDetails?.flag}{" "}
          {selectedCountryDetails?.name} for onboarding?
        </Body>

        <StyledButtonsContainer className="mt-4">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={confirmationModalState.close}
          >
            Cancel
          </Button>

          <Button
            disabled={!selectedCountry || isOnboarding}
            onClick={handleOnboard}
          >
            {isOnboarding ? "Loading..." : "Continue"}
          </Button>
        </StyledButtonsContainer>
      </Modal>

      <Modal showCloseButton state={pakOnboardingModalState} width="760px">
        <PakCountryOnboardingPrompt />
        <StyledButtonsContainer className="mt-4">
          {STRIPE_PAK_ONBOARDING_VIDEO_LINK && (
            <Button
              openInNewTab
              variant="secondary"
              colorVariant="gray"
              link={STRIPE_PAK_ONBOARDING_VIDEO_LINK}
              style={{
                flexGrow: 2,
              }}
            >
              Watch Demo Video
            </Button>
          )}

          <Button
            disabled={!selectedCountry || isOnboarding}
            onClick={handleOnboard}
          >
            {isOnboarding ? "Loading..." : "Proceed"}
          </Button>
        </StyledButtonsContainer>
      </Modal>
    </>
  );
};

export default CountryInput;
