import styled from "@emotion/styled";

import Wysiwyg from "@/components/input/LexicalWysiwyg";
import Button from "@/components/input/Button";

export const StyledSlider = styled.img`
  width: 100%;
  height: 12.5rem;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  background-color: rgba(0, 0, 0, 0.02);
`;

export const StyledWysiwyg = styled(Wysiwyg)`
  height: fit-content;
  background: transparent;
  color: var(--clr-ukwn-1, #36394a);

  .editor {
    background: transparent;
    overflow: hidden;
  }

  .editor > div {
    padding: 0;
    position: static;
  }
`;


export const StyledUserAvatarContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledContactButton = styled(Button)`
  width: 100%;
  max-width: 10rem;
`;
