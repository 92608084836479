import { useMemo } from "react";

import { Body } from "@/components/Typography";

import { getFormattedDuration } from "../useReviewContractMilestone.utils";

const PaymentWait: React.FC<{
  remainingTimeInMs: number;
  waitDays?: number;
}> = ({ remainingTimeInMs, waitDays = 3 }) => {
  const displayTime = useMemo(
    () => getFormattedDuration(remainingTimeInMs),
    [remainingTimeInMs]
  );

  return (
    <div>
      <Body size="lg">
        To keep your transactions safe and secure, we have a {waitDays} business{" "}
        {waitDays > 1 ? "days" : "day"} waiting period before releasing
        payments. This helps us protect you from potential risks.
      </Body>

      <Body size="lg" className="mt-3">
        We understand that waiting can be inconvenient, but rest assured your
        security is our top priority. Please check back in <b>{displayTime}</b>.
      </Body>

      <Body size="lg" className="mt-3">
        Thank you for your patience and understanding.
      </Body>
    </div>
  );
};

export default PaymentWait;
