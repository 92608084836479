import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import { Body } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import { getLinkIcon } from "@/features/user/ProfileForm";
import { squareSizing } from "@/utils/css";

import { useProfilePage } from "../ProfilePage.context";

const StyledLinksContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const StyledIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${squareSizing("2.75rem")};
  border: 1px solid var(--clr-border-50, #eceff3);
  border-radius: 10rem;
`;

const SocialLinksDisplay: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { profileData } = useProfilePage();

  if (!profileData.links.length) {
    return;
  }

  return (
    <>
      <Body
        size="md"
        style={{ color: "var(--clr-icon-text-200, #818898)" }}
        className={className}
      >
        Links
      </Body>

      <StyledLinksContainer className="mt-2">
        {profileData.links.map(({ name, url }, i) => {
          return (
            <Link key={i} target="_blank" to={url}>
              <StyledIconContainer>
                <Icon
                  isSrcRelative
                  size="sm"
                  src={getLinkIcon({ name, url })}
                />
              </StyledIconContainer>
            </Link>
          );
        })}
      </StyledLinksContainer>
    </>
  );
};

export default SocialLinksDisplay;
