import { AddressParts, PlaceDetailsResponse } from "./Location.types";

export function extractFullAddressComponents(place: PlaceDetailsResponse) {
  const addressComponents: AddressParts = {
    streetNumber: "",
    route: "",
    neighborhood: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    formattedAddress: "",
    placeId: place.place_id || "",
  };

  // Loop through the address components
  (place.address_components || []).forEach((component: { types: any; short_name: string; long_name: string; }) => {
    const types = component.types;

    if (types.includes("street_number")) {
      addressComponents.streetNumber = component.short_name;
    }
    if (types.includes("route")) {
      addressComponents.route = component.short_name;
    }
    if (types.includes("neighborhood")) {
      addressComponents.neighborhood = component.short_name;
    }
    if (types.includes("locality") || types.includes("sublocality")) {
      addressComponents.city = component.short_name;
    }
    if (types.includes("administrative_area_level_1")) {
      addressComponents.state = component.short_name;
    }
    if (types.includes("postal_code")) {
      addressComponents.postalCode = component.short_name;
    }
    if (types.includes("country")) {
      addressComponents.country = component.long_name;
    }
  });

  // Construct a formatted address string
  addressComponents.formattedAddress = [
    addressComponents.streetNumber,
    addressComponents.route,
    addressComponents.neighborhood,
    addressComponents.city,
    addressComponents.state,
    addressComponents.postalCode,
    addressComponents.country,
  ]
    .filter((part) => part)
    .join(", ");

  return addressComponents;
}
