import { FC, useCallback, useEffect, useState } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";

import { IShowMoreProps } from "./ShowMore.types";
import {
  StyledButton,
  StyledButtonConainer,
  StyledOuterContainer,
  StyledOverlayButton,
} from "./ShowMore.styles";

const ShowMore: FC<IShowMoreProps> = ({
  children,
  maxHeight = "4rem",
  showMoreOnContentClick = false,
}) => {
  const modalState = useModalState();

  const [outerContainerRef, setOuterContainerRef] =
    useState<HTMLDivElement | null>(null);
  const [contentContainerRef, setContentContainerRef] =
    useState<HTMLDivElement | null>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  const updateState = useCallback(() => {
    if (!outerContainerRef || !contentContainerRef) {
      setHasOverflow(false);
      return;
    }

    const containerHeight = outerContainerRef.getBoundingClientRect().height;
    const contentHeight = contentContainerRef.getBoundingClientRect().height;
    const hasOverflow = contentHeight > containerHeight;

    setHasOverflow(hasOverflow);
  }, [outerContainerRef, contentContainerRef]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateState();
    });

    if (contentContainerRef) {
      resizeObserver.observe(contentContainerRef, { box: "border-box" });
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [contentContainerRef, updateState]);

  return (
    <>
      <div>
        <StyledOuterContainer
          ref={setOuterContainerRef}
          style={{
            maxHeight,
            overflow: "hidden",
          }}
        >
          <div ref={setContentContainerRef}>{children}</div>

          {showMoreOnContentClick && hasOverflow && <StyledOverlayButton onClick={modalState.open} />}
        </StyledOuterContainer>

        {hasOverflow && (
          <StyledButtonConainer>
            <StyledButton
              variant="ghost"
              colorVariant="gray"
              onClick={modalState.open}
            >
              Show more
            </StyledButton>
          </StyledButtonConainer>
        )}
      </div>

      <Modal state={modalState}>{children}</Modal>
    </>
  );
};

export default ShowMore;
