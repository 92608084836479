import React, { useState } from "react";

import Tabs from "@/components/navigation/Tabs";

import WysiwygNew, { Value } from "@/components/input/WysiwygNew";
import EditorField from "@/components/input/EditorField";
import LexicalWysiwyg from "@/components/input/LexicalWysiwyg";
import Icon from "@/components/misc/Icon";
import EditorFieldNew from "@/components/input/EditorFieldNew";

const Editor: React.FC = () => {
  const [activeTabId, setActiveTabId] = useState("lexical");

  const [wysiswygValue, setWysiswygValue] = useState<Value>({ markdown: "" });

  return (
    <div>
      <Tabs
        activeTabId={activeTabId}
        onChange={({ id }) => setActiveTabId(id)}
        tabs={[
          {
            id: "lexical",
            title: "Lexical Editor",
          },
          {
            id: "draft-js",
            title: "Draft JS Editor",
          },
          {
            id: "wysiwyg",
            title: "WYSIWYG",
          },
          {
            id: "editor",
            title: "Editor",
          },
          {
            id: "editor-new",
            title: "Editor New",
          },
        ]}
      />

      <div className="mt-3">
        {activeTabId === "lexical" && (
          <LexicalWysiwyg
            focusOnMount
            initialValue={
              {
                markdown: "- 1\n- 2\n- 3\n",
                asd: 123,
              } as any
            }
            value={
              {
                markdown: "- 1\n- 2\n- 3\n asd",
                asd: 123,
              } as any
            }
            style={{ height: "240px" }}
          />
        )}

        {activeTabId === "wysiwyg" && (
          <WysiwygNew value={wysiswygValue} onChange={setWysiswygValue} />
        )}

        {activeTabId === "editor" && (
          <EditorField
            prependContent={<Icon isSrcRelative src="content.svg" size="xs" />}
            value={wysiswygValue}
            onChange={setWysiswygValue}
            label="Job description"
            placeholder="Add details about the job"
            drawerTitle="Job task"
          />
        )}

        {activeTabId === "editor-new" && (
          <EditorFieldNew
            prependContent={<Icon isSrcRelative src="content.svg" size="xs" />}
            value={wysiswygValue}
            onChange={setWysiswygValue}
            label="Job Description"
            placeholder="Add details about the job"
            drawerTitle="Job task"
          />
        )}
      </div>
    </div>
  );
};

export default Editor;
