import {
  ContractMilestoneStatus,
  ContractStatus,
} from "@/services/ContractsService";

export const COLORS = {
  BLUE: "var(--clr-primary-100, #5f57ff)",
  ORANGE: "#D08824",
  RED: "#EA5B5B",
  GREEN: "#1DBF73",
};

export const STATUS_CONFIG: Record<any, { color: string; label: string }> = {
  [ContractStatus.Published]: {
    color: COLORS.ORANGE,
    label: "Pending Acceptance",
  },
  [ContractStatus.PendingFunding]: {
    color: COLORS.ORANGE,
    label: "Pending Funding",
  },
  [ContractStatus.Review]: {
    color: COLORS.ORANGE,
    label: "Review",
  },
  [ContractStatus.Archive]: {
    color: COLORS.ORANGE,
    label: "Paid",
  },
  [ContractStatus.Paused]: {
    color: COLORS.ORANGE,
    label: "Paid",
  },
  [ContractStatus.Funded]: {
    color: COLORS.ORANGE,
    label: "Paid",
  },
  [ContractStatus.Approved]: {
    color: COLORS.BLUE,
    label: "Paid",
  },
  [ContractStatus.Accepted]: {
    color: COLORS.BLUE,
    label: "Active",
  },
  [ContractStatus.Active]: {
    color: COLORS.BLUE,
    label: "Active",
  },
  [ContractStatus.Dispute]: {
    color: COLORS.RED,
    label: "Disputed",
  },
  [ContractStatus.Reject]: {
    color: COLORS.RED,
    label: "Rejected",
  },
  [ContractStatus.Completed]: {
    color: COLORS.GREEN,
    label: "Paid",
  },
};

export const MILESTONE_STATUS_CONFIG: Record<
  any,
  { color: string; label: string }
> = {
  [ContractMilestoneStatus.Accepted]: {
    color: COLORS.ORANGE,
    label: "Pending Funding",
  },
  [ContractMilestoneStatus.Funded]: {
    color: COLORS.BLUE,
    label: "Funded",
  },
  [ContractMilestoneStatus.FundRequested]: {
    color: COLORS.ORANGE,
    label: "Fund Requested",
  },
  [ContractMilestoneStatus.FundReleased]: {
    color: COLORS.BLUE,
    label: "Fund Released",
  },
};

export const MILESTONE_CLIENT_STATUS_CONFIG: Record<
  any,
  { color: string; label: string }
> = {
  [ContractMilestoneStatus.FundRequested]: {
    color: COLORS.ORANGE,
    label: "Pending approval",
  },
};

export const MILESTONE_CONTRACTOR_STATUS_CONFIG: Record<
  any,
  { color: string; label: string }
> = {
  [ContractMilestoneStatus.FundRequested]: {
    color: COLORS.ORANGE,
    label: "Payment Requested",
  },
};
