"use client";

import React from "react";

import { StyledContainer, StyledInputContainer } from "./Input.styles";
import { IInputProps } from "./Input.types";

const Input: React.FC<IInputProps> = ({
  inputElement,
  prependContent,
  appendContent,
  label,
  hasValue,
  hasError = false,
  useFloatingLabel: useFloatingLabelFromProps = false,
  variant = "background",
  className,
  style,
  onClick,
  onBlur,
}) => {
  const useFloatingLabel = useFloatingLabelFromProps && variant !== "outline";

  return (
    <StyledContainer
      $hasValue={!!hasValue}
      $hasLabel={!!label}
      $hasError={hasError}
      $useFloatingLabel={useFloatingLabel}
      $variant={variant}
      className={className}
      style={style}
      onClick={onClick}
      onBlur={onBlur}
    >
      {prependContent}
      <StyledInputContainer className="input-container" $variant={variant}>
        {inputElement}
        {useFloatingLabel && <label>{label}</label>}
      </StyledInputContainer>
      {appendContent}
    </StyledContainer>
  );
};

export default Input;
