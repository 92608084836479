import React from "react";
import lodashGet from "lodash/get";
import styled from "@emotion/styled";

import { ANIMATION_CLASSNAMES } from "@/styles";
import { Body } from "@/components/Typography";
import { joinClassNames } from "@/utils/classNames";
import { useForm } from "../index";

const StyledError = styled(Body)`
  color: #f04438;
`;

const FormError: React.FC<{
  fieldKey: string;
  className?: string;
}> = ({ fieldKey, className }) => {
  const { touchedAndHasError, errors } = useForm();
  const error = lodashGet(errors, fieldKey);

  if (!error || !touchedAndHasError(fieldKey)) return null;

  return (
    <StyledError
      key={error}
      size="md"
      className={joinClassNames(ANIMATION_CLASSNAMES.FADE_IN, className)}
    >
      {error}
    </StyledError>
  );
};

export default FormError;
