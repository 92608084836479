"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { overflowEllipsis } from "@/styles";

export const StyledContainer = styled.div`
  position: relative;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.5rem 0.5rem 1.5rem 0.25rem;
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  overflow: hidden;

  & > * {
    ${overflowEllipsis()};
    color: inherit;
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
