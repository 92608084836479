import React, { useCallback } from "react";

import RadioGroup, {
  RenderFunctionParams,
} from "@/components/input/RadioGroup";
import Icon from "@/components/misc/Icon";

import { StyledContainer, StyledItem } from "./RatingInput.styles";
import { IRatingInputProps } from "./RatingInput.types";
import { RATINGS } from "./RatingInput.config";

const RatingInput: React.FC<IRatingInputProps> = ({
  value,
  onChange,
  className,
  useNumbers,
  disabled = false,
}) => {
  const render = useCallback(
    ({
      isSelected,
      labelProps,
      inputProps,
      inputRef,
      icon,
      disabled = false,
      value,
    }: RenderFunctionParams & { icon: string }) => {
      return (
        <StyledItem {...labelProps} $isSelected={isSelected}>
          <input {...inputProps} ref={inputRef} disabled={disabled} />
          {useNumbers ? value : <Icon src={icon} customSize="1.5rem" />}
        </StyledItem>
      );
    },
    [useNumbers]
  );

  return (
    <StyledContainer
      label="rating"
      onChange={(value) => {
        if (!disabled) {
          onChange(+value);
        }
      }}
      className={className}
      renderRadio={render as any}
      value={"" + value}
    >
      {RATINGS.map(({ value, ...rest }, i) => (
        <RadioGroup.Radio key={i} {...rest} value={"" + value} />
      ))}
    </StyledContainer>
  );
};

export default RatingInput;
