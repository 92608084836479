import React, { useMemo } from "react";
import lodashGet from "lodash/get";

import { Label } from "@/components/Typography";
import {
  ContractCompleteDetails,
  ContractCreatorType,
  ContractMilestoneStatus,
  ContractStatus as ContractStatusType,
  getLoggedInUserContractUserType,
} from "@/services/ContractsService";

import { StyledContainer } from "./ContractStatus.styles";
import { IContractStatusProps } from "./ContractStatus.types";
import {
  STATUS_CONFIG,
  MILESTONE_STATUS_CONFIG,
  MILESTONE_CONTRACTOR_STATUS_CONFIG,
  MILESTONE_CLIENT_STATUS_CONFIG,
} from "./ContractStatus.config";

function getStatusDetails({
  status,
  contractDetails,
  milestoneStatus,
}: {
  status: ContractStatusType;
  milestoneStatus?: ContractMilestoneStatus;
  contractDetails?: ContractCompleteDetails;
}): { color: string; label: string } {
  const loggedInUserContractUserType = contractDetails
    ? getLoggedInUserContractUserType(contractDetails)
    : null;
  let statusDetails: any = null;

  if (loggedInUserContractUserType && milestoneStatus) {
    if (loggedInUserContractUserType === ContractCreatorType.Payee) {
      statusDetails = MILESTONE_CONTRACTOR_STATUS_CONFIG[milestoneStatus];
    } else if (loggedInUserContractUserType === ContractCreatorType.Payer) {
      statusDetails = MILESTONE_CLIENT_STATUS_CONFIG[milestoneStatus];
    }
  }

  if (!statusDetails) {
    statusDetails =
      lodashGet(MILESTONE_STATUS_CONFIG, `[${milestoneStatus}]`, "") ||
      lodashGet(STATUS_CONFIG, `[${status}]`, "");
  }

  return statusDetails;
}

const ContractStatus: React.FC<IContractStatusProps> = ({
  status,
  milestoneStatus,
  className,
  contractDetails,
}) => {
  const { label: statusName }: any = useMemo(
    () =>
      getStatusDetails({
        status,
        milestoneStatus,
        contractDetails,
      }),
    [status, milestoneStatus, contractDetails]
  );

  return (
    <StyledContainer
      $status={status}
      $milestoneStatus={milestoneStatus}
      className={className}
    >
      <Label size="sm">{statusName}</Label>
    </StyledContainer>
  );
};

export default ContractStatus;
