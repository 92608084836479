import { useCallback, useState } from "react";

import { userService } from "@/config/services";

function useUpdateProfileImage() {
  const [isUpdating, setIsUpdating] = useState(false);

  const update = useCallback((file: File) => {
    setIsUpdating(true);

    return userService.updateUserProfileImage(file).finally(() => {
      setIsUpdating(false);
    });
  }, []);

  return {
    update,
    isUpdating,
  };
}

export default useUpdateProfileImage;
