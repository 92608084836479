import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import TextField from "@/components/input/TextField";
import { screenLargerThan } from "@/styles";

export const StyledButton = styled(Button)`
  padding: 0.75rem;
`;

export const StyledSectionHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledStepHeading = styled.h1`
  font-size: 27px;
  font-weight: 600;
  line-height: 130%;
  margin: 0;
`;

export const StyledSpecialButton = styled(Button)`
  padding: 1rem;
  min-height: 66px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.625rem;
  background: var(--clr-background-ukwn-1, #fafafa);
  border: 1px solid var(--clr-primary-100, #5f57ff);
  border-radius: 1rem;
`;

//-------- STEP COMMON STYLES

export const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const StyledStepTopContent = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const StyledStepFloatingContent = styled.div`
  padding: 1rem;

  ${screenLargerThan.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    & > * {
      flex: 1;
      max-width: 10rem;
    }

    & > *:last-child {
      flex: 3;
      max-width: 20rem;
    }
  }
`;

export const StyledBoldTextField = styled(TextField)`
  font-size: 1.25rem;
  font-weight: 600;
  padding-inline: 0.25rem;

  input::placeholder-shown,
  input::placeholder {
    color: var(--clr-ukwn-3, #a4acb9);
  }
`;

export const StyledBox = styled.div`
  ${screenLargerThan.tablet} {
    padding: 1rem;
    min-height: 20rem;
    border: 1px solid var(--clr-border-ukwn-15, #ecf1f2);
    border-radius: 0.75rem;

    & > * {
      max-width: 36rem;
      margin-inline: auto;
    }
  }
`;

export const StyledSmall = styled.small`
  display: block;
  font-size: 0.625rem;
  font-weight: 500;
  color: var(--clr-ukwn-3, #A4ACB9);
`;
