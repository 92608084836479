"use client";

import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { DEVICE_BREAKPOINT, screenLargerThan, useResponsive } from "@/styles";
import PortfolioForm, {
  FormValues,
  PortfolioDetails,
} from "@/features/contracts/PortfolioForm";
import { mediaService } from "@/config/services";

import { useProfileForm } from "../../ProfileForm.config";
import PortfolioCard from "../PortfolioCard";
import DeleteConfirmation from "../DeleteConfirmation";

const StyledCardContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`;

const StyledButton = styled(Button)`
  ${screenLargerThan.widescreen} {
    min-height: 16rem;
    background: var(--clr-background-25, #f6f8fa);
  }
`;

const PortfoliosInput: React.FC<{ className?: string }> = ({ className }) => {
  const { screenWidth } = useResponsive();
  const { values, setFieldValue, submitForm } = useProfileForm();
  const { portfolios = [] } = values;

  const [editingIndex, setEditingIndex] = useState(-1);

  const [uploadingAssets, setUploadingAssets] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const formModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setEditingIndex(-1);
      }
    },
  });
  const [toDeleteItemIndex, setToDeleteItemIndex] = useState<null | number>(
    null
  );
  const deleteModalState = useModalState({
    onOpenChange: (open) => {
      if (!open) {
        setToDeleteItemIndex(null);
      }
    },
  });

  //---------------------------------------

  const addNewItem = useCallback(() => {
    formModalState.open();
  }, [formModalState]);

  const handleEdit = useCallback(
    (index: number) => {
      setEditingIndex(index);
      formModalState.open();
    },
    [formModalState]
  );

  const handleDelete = useCallback(() => {
    if (toDeleteItemIndex === null) {
      return;
    }

    const updatedPortfolios = [...portfolios];
    updatedPortfolios.splice(toDeleteItemIndex, 1);
    setFieldValue("portfolios", updatedPortfolios);
    deleteModalState.close();
    setTimeout(() => {
      submitForm();
    }, 100);
  }, [
    portfolios,
    setFieldValue,
    toDeleteItemIndex,
    deleteModalState,
    submitForm,
  ]);

  const handleDeleteClick = useCallback(
    (index: number) => {
      setToDeleteItemIndex(index);
      deleteModalState.open();
    },
    [deleteModalState]
  );

  const handleUpdate = useCallback(
    async ({ values }: { values: FormValues }) => {
      if (!values.name || !values.description) {
        return;
      }

      setIsSaving(true);

      (async function () {
        try {
          const { assets } = values;
          const payload = { ...values };
          const hasNewAssetsToUpload = assets.some((file) => !!file);

          if (hasNewAssetsToUpload) {
            setUploadingAssets(true);
          }

          const updatedAttatchments = await Promise.all(
            assets
              .filter(({ file, mediaFile }) => !!file || !!mediaFile)
              .map(({ file, mediaFile }) =>
                mediaFile
                  ? Promise.resolve(mediaFile)
                  : mediaService
                      .uploadFile(file as File)
                      .then((res) => res.data.data)
              )
          );
          setUploadingAssets(false);
          payload.attachments = updatedAttatchments;
          const index = editingIndex === -1 ? portfolios.length : editingIndex;
          setFieldValue(`portfolios[${index}]`, payload);
          setTimeout(() => {
            submitForm();
          }, 100);
        } catch {}
      })().then(() => {
        setIsSaving(false);
        formModalState.close();
      });
    },
    [setFieldValue, submitForm, editingIndex, formModalState, portfolios.length]
  );

  const cardsJsx = useMemo(
    () =>
      portfolios
        .filter(({ name, description }) => !!name && !!description)
        .map((_, i) => (
          <PortfolioCard
            key={i}
            index={i}
            onEdit={handleEdit}
            onDelete={handleDeleteClick}
            className={screenWidth < DEVICE_BREAKPOINT.widescreen ? "mt-4" : ""}
          />
        )),
    [portfolios, handleEdit, handleDeleteClick, screenWidth]
  );

  const desktopAddButton = (
    <StyledButton variant="secondary" colorVariant="gray" onClick={addNewItem}>
      {!!portfolios.length ? "+ Add more" : "+ Add"}
    </StyledButton>
  );

  return (
    <>
      <div className={className}>
        <Label size="lg" as="label" htmlFor="timezone">
          Portfolio
        </Label>

        <StyledCardContainer className="mt-3">
          {screenWidth < DEVICE_BREAKPOINT.widescreen ? (
            <>
              {cardsJsx}

              <StyledButton
                variant="secondary"
                colorVariant="gray"
                className="w-100 mt-3"
                onClick={addNewItem}
              >
                Add new
              </StyledButton>
            </>
          ) : (
            <StyledCardContainer>
              {desktopAddButton}

              {cardsJsx}

              {cardsJsx.length > 4 && desktopAddButton}
            </StyledCardContainer>
          )}
        </StyledCardContainer>
      </div>

      <Modal
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        state={formModalState}
        mobileProps={{
          fullscreen: true,
          className: "p-0",
        }}
        desktopProps={{
          width: "768px",
        }}
      >
        <PageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Portfolios" },
            backButtonProps: { onClick: formModalState.close },
          }}
        >
          <div className="px-3">
            {formModalState.isOpen && (
              <PortfolioForm
                // @ts-ignore
                initialData={
                  editingIndex === -1
                    ? ({} as PortfolioDetails)
                    : portfolios[editingIndex]
                }
                onSubmit={handleUpdate}
                ctaProps={{
                  className: "cta-button w-100 mt-4",
                  children: uploadingAssets
                    ? "Uploading assets"
                    : isSaving
                    ? "Saving"
                    : "",
                }}
              />
            )}
          </div>
        </PageWithHeaderLayout>
      </Modal>

      <Modal state={deleteModalState} desktopProps={{ width: "420px" }}>
        <DeleteConfirmation
          title="Delete potfolio"
          description="Are you sure want to delete this portfolio?"
          onCancel={deleteModalState.close}
          onDelete={handleDelete}
        />
      </Modal>
    </>
  );
};

export default PortfoliosInput;
