import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledContainer = styled.ol`
  display: flex;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none !important;
  color: var(--clr-text-900, #0d0d12);
`;
