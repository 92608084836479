import { useCallback, useState } from "react";
import isFunction from "lodash/isFunction";

export type ToggleState = {
  state: boolean;
  toggle: () => void;
  enable: () => void;
  disable: () => void;
};

const useToggle = (
  params: {
    initialValue?: boolean | (() => boolean);
    onChange?: (value: boolean) => void;
  } = {}
) => {
  const { initialValue = false, onChange } = params;

  const [state, setState] = useState(() => {
    if (isFunction(initialValue)) {
      return initialValue();
    }

    return initialValue;
  });

  const toggle = useCallback(() => {
    setState((state) => {
      const newState = !state;

      if (onChange) {
        onChange(newState);
      }

      return newState;
    });
  }, [onChange]);

  const enable = useCallback(() => {
    setState(true);
    if (onChange) {
      onChange(true);
    }
  }, [onChange]);

  const disable = useCallback(() => {
    setState(false);
    if (onChange) {
      onChange(false);
    }
  }, [onChange]);

  return { state, toggle, enable, disable } satisfies ToggleState;
};

export default useToggle;
