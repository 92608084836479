"use client";
import styled from "@emotion/styled";

import { screenSmallerThan } from "@/styles";
import GlobalSearchButton from "@/features/app/GlobalSearchButton";

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: var(--clr-background-white, #fff);

  ${screenSmallerThan.tablet} {
    padding-right: 0.25rem;
  }
`;

export const StyledLogoContainer = styled.div`
  margin-inline-end: auto;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--clr-text-900, #0d0d12);
  letter-spacing: -0.03em;
`;

export const StyledSearchButton = styled(GlobalSearchButton)`
  padding: 0.5rem;
`;
