"use client";

import React from "react";

import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import { useManageContract } from "@/services/ContractsService";
import ContractPage from "@/features/pages/contract/ContractPage";
import Card from "@/components/data/Card";
import ContractPaymentRedirectPage from "@/features/pages/contract/ContractPaymentRedirectPage";

import { StyledInputsContainer, StyledPage } from "./ContractsListPage.styles";
import ContractList from "./components/ContractList";
import ContractTabs from "./components/ContractTabs";
import ContractFilters from "./components/ContractFilters";
import NoContracts from "./components/NoContracts";

const ContractsListPage: React.FC = () => {
  const { isPaymentRedirect, contractId: contractIdFromUrl } =
    useManageContract({
      redirectIfNotFound: true,
      useContractIdFromUrl: true,
    });

  if (isPaymentRedirect) {
    return <ContractPaymentRedirectPage />;
  }

  if (contractIdFromUrl) {
    return <ContractPage />;
  }

  return (
    <MobilePrimaryLayout
      headerProps={{
        logo: { component: "Jobs" },
      }}
    >
      <StyledPage>
        <Card>
          <StyledInputsContainer>
            <ContractTabs />
            <ContractFilters />
          </StyledInputsContainer>

          <NoContracts />
          <ContractList />
        </Card>
      </StyledPage>
    </MobilePrimaryLayout>
  );
};

export default ContractsListPage;
