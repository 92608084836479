"use client";

import React, { useMemo } from "react";

import {
  getContractOtherParticipant,
  isContractAnOffer,
  useContractList,
  useContractUrlId,
} from "@/services/ContractsService";
import { IChatCardProps } from "@/features/chat/ChatCard";
import { areStringsSubstring } from "@/utils/string";
import ChatCard, { ChatCardLoading } from "@/features/chat/ChatCard";

import { StyledChatList } from "../ChatsPage.styles";
import { useChatsPage } from "../ChatsPage.context";

const ChatList: React.FC = () => {
  const { search } = useChatsPage();

  const { data: contractList, isLoading } = useContractList();
  const { idFromUrl } = useContractUrlId();

  const chatCardProps = useMemo(() => {
    return contractList.map((c) => {
      const otherUser = getContractOtherParticipant(c);

      return {
        contractId: c.id,
        avatarImage: otherUser?.user?.picture || "",
        username: otherUser?.user?.given_name || otherUser?.user?.email || "",
        title: c.title,
        content: "Last message missing!",
        lastMessageDate: new Date(),
        isOffer: isContractAnOffer(c),
        channel_id: c.channel_id,
      } satisfies IChatCardProps;
    }) as IChatCardProps[];
  }, [contractList]);

  const filteredChats = useMemo(() => {
    if (!search) {
      return chatCardProps;
    }

    return chatCardProps.filter(
      ({ title, username }) =>
        areStringsSubstring(search, title) ||
        areStringsSubstring(search, username)
    );
  }, [chatCardProps, search]);

  const chatCardsJsx = isLoading ? (
    <>
      <ChatCardLoading />
      <hr />
      <ChatCardLoading />
      <hr />
      <ChatCardLoading />
    </>
  ) : (
    filteredChats.map((chat, i) => {
      const isActive = chat.contractId === idFromUrl;

      return (
        <div
          key={chat.contractId}
          ref={(ref) => {
            if (isActive && ref) {
              ref.scrollIntoView({ block: "nearest", behavior: "smooth" });
            }
          }}
        >
          {i !== 0 && <hr />}
          <ChatCard key={i} {...chat} active={isActive} className="mt-3 mb-3" />
        </div>
      );
    })
  );

  return <StyledChatList>{chatCardsJsx}</StyledChatList>;
};

export default ChatList;
