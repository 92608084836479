import React from "react";

// import { ChatRoom } from "@/services/ChatServiceNew";

const StreamChat: React.FC = () => {
  return (
    <div>
{/* {false &&      <ChatRoom contractId="0563fb7b-51b9-415e-81b3-6a716dae172f" />} */}
    </div>
  );
};

export default StreamChat;
