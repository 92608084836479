import React from "react";

import { Body, Label } from "@/components/Typography";

import {
  StyledContainer,
  StyledIcon,
  StyledIconContainer,
  StyledIconContainerInner,
  StyledTag,
} from "./Feature.styles";
import { IFeatureProps } from "./Feature.types";

const Feature: React.FC<IFeatureProps> = ({
  icon,
  title,
  description,
  className,
  iconProps = {},
  tag,
}) => {
  return (
    <StyledContainer className={className}>
      <StyledIconContainer>
        <StyledIconContainerInner>
          <StyledIcon isSrcRelative src={icon} size="xxs" {...iconProps} />
        </StyledIconContainerInner>
      </StyledIconContainer>

      <div>
        {!!tag && <StyledTag className="mb-1">{tag}</StyledTag>}
        <Label size="md" style={{ color: "var(--clr-text-900, #0d0d12)" }}>
          {title}
        </Label>
        <Body
          size="sm"
          style={{ color: "var(--clr-icon-text-200, #818898)" }}
          className="mt-1"
        >
          {description}
        </Body>
      </div>
    </StyledContainer>
  );
};

export default Feature;
