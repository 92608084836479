import { createContext, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import lodashGet from "lodash/get";

import { useProfileData } from "@/services/UserService";
import { useNavigate } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";

import { IProfilePageContext, Tab } from "./ProfilePage.types";

const ProfilePageContext = createContext({} as IProfilePageContext);

export const useProfilePage = () => useContext(ProfilePageContext);

export const ProfilePageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { profileData, isProfileDataLoading } = useProfileData();

  const params = useParams();
  const { navigate } = useNavigate();
  const activeTab = lodashGet(params, "tab", "").toLocaleLowerCase() as Tab;

  const openTab = useCallback(
    (tab: Tab) => {
      navigate(`${SITE_PATHS.PROFILE_PAGE}/${tab}`, true);
    },
    [navigate]
  );

  useEffect(() => {
    if (!activeTab) {
      openTab("about");
    }
  }, [activeTab, openTab]);

  return (
    <ProfilePageContext.Provider
      value={{ profileData, isProfileDataLoading, activeTab, openTab }}
    >
      {children}
    </ProfilePageContext.Provider>
  );
};

export default ProfilePageProvider;
