"use client";

import React from "react";

import { Specialization } from "@/services/UserService";
import { Heading } from "@/components/Typography";
import { SITE_PATHS } from "@/config/routing";
import Icon from "@/components/misc/Icon";

import {
  StyledContainer,
  StyledDeleteButton,
  StyledLink,
  StyledTitle,
} from "./SpecializationCard.styles";

const SpecializationCard: React.FC<{
  specialization: Specialization;
  className?: string;
  useLink?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}> = ({ specialization, className, useLink = true, onClick, onDelete }) => {
  const { name, experience_duration, experience_duration_unit } =
    specialization;

  const usePluralUnit = experience_duration > 1;
  const durationUnit = experience_duration_unit
    ? usePluralUnit
      ? experience_duration_unit
      : experience_duration_unit.substring(
          0,
          experience_duration_unit.length - 1
        )
    : "";

  if (!name || !experience_duration || !experience_duration_unit) {
    return null;
  }

  return (
    <StyledContainer className={className} onClick={onClick}>
      {!!onDelete && (
        <StyledDeleteButton variant="ghost" onClick={onDelete}>
          <Icon isSrcRelative src="delete.svg" size="xs" />
        </StyledDeleteButton>
      )}

      {false && useLink && (
        <StyledLink to={`${SITE_PATHS.PROFILE_PAGE}/specializations/0`} />
      )}

      <StyledTitle size="md">{name}</StyledTitle>

      <Heading size="sm" className="mt-3">
        {experience_duration} {durationUnit}
      </Heading>
    </StyledContainer>
  );
};

export default SpecializationCard;
