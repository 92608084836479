import styled from "@emotion/styled";

import { HTML_CONTENT } from "./content";

const StyledArticle = styled.article`
  padding-block: 3rem;
`;

const Page = () => {
  return (
    <div className="container">
      <StyledArticle
        dangerouslySetInnerHTML={{
          __html: HTML_CONTENT,
        }}
      />
    </div>
  );
};

export default Page;
