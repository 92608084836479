"use client";

import React from "react";

import { EditorProps, Editor as ReactDraftEditor } from "react-draft-wysiwyg";

const Editor: React.FC<
  Omit<EditorProps, "wrapperClassName"> & { className?: string }
> = ({ className, ...restProps }) => {
  return <ReactDraftEditor wrapperClassName={className} {...restProps} />;
};

export default Editor;
