import React from "react";

import PayoutOnboarding from "@/features/user/PayoutOnboarding";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { useOnboardStripe } from "@/services/UserService";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";

const PayoutOnboardingPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { isReady, isOnboarded } = useOnboardStripe();

  if (!isReady) {
    return <SplashScreen />;
  }

  if (isOnboarded) {
    return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
  }

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          titleProps: { children: "Onboard" },
        }}
      >
        <PayoutOnboarding />
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout headerProps={{ logo: { component: "Onboard" } }}>
      <div className="container">
        <PayoutOnboarding />
      </div>
    </DesktopPrimaryLayout>
  );
};

export default PayoutOnboardingPage;
