import { BREAKPOINT_NAMES } from "@/styles";
import { ContractTab } from "./ContractsListPage.types";

export const CONTRACT_TAB_OPTIONS = [
  {
    id: ContractTab.Active,
    title: "Active",
  },
  {
    id: ContractTab.Completed,
    title: "Completed",
  },
];

export const RESPONSEIVE_BREAKPOINT: BREAKPOINT_NAMES = "widescreen";
