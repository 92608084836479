import React from "react";
import { Helmet } from "react-helmet-async";

const Seo: React.FC<{
  pageTitle?: string;
  title?: string;
  description?: string;
  name?: string;
  type?: string;
  image?: string;
}> = ({ pageTitle, title, description, name, type, image }) => {
  if (!pageTitle) {
    pageTitle = title;
  }

  if (!title && !description && !name && !type && !image && !pageTitle) {
    return null;
  }

  return (
    <Helmet>
      {/* Standard metadata tags */}
      {!!pageTitle && <title>{pageTitle}</title>}
      {!description && <meta name="description" content={description} />}
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {!!type && <meta property="og:type" content={type} />}
      {!!title && <meta property="og:title" content={title} />}
      {!!description && (
        <meta property="og:description" content={description} />
      )}{" "}
      {!!image && <meta property="og:image" content={image} />}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {!!name && <meta name="twitter:creator" content={name} />}
      {!!type && <meta name="twitter:card" content={type} />}
      {!!title && <meta name="twitter:title" content={title} />}
      {!!description && (
        <meta name="twitter:description" content={description} />
      )}
      {/* End Twitter tags */}
    </Helmet>
  );
};

export default Seo;
