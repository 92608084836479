import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledContainer,
  StyledIconContainer,
  StyledRightSection,
} from "./ActionCard.styles";

const ActionCard: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer
      className={className}
      style={{
        height: "calc(86px + 2rem)",
        paddingInline: "0",
      }}
    >
      <StyledIconContainer>
        <ShimmerLoader.Circular size="3rem" />
      </StyledIconContainer>

      <StyledRightSection>
        <ShimmerLoader.Default style={{ height: "42px" }} />

        <ShimmerLoader.Default
          className="mt-2"
          style={{ width: "120px", borderRadius: "10rem", height: "36px" }}
        />
      </StyledRightSection>
    </StyledContainer>
  );
};

export default ActionCard;
