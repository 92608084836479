import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";
import { useForm } from "@/components/input/Form";

import { FormValues } from "../PackageForm.types";
import {
  FORM_ERRORS,
  MAX_ESTIMATE_VALUE,
  MIN_ESTIMATE_VALUE,
} from "../PackageForm.config";

export const StyledTextField = styled(TextField)`
  select {
    height: 100%;
    padding: 0.75rem 0.25rem;
    margin-block: 0.25rem;
    background: transparent;
    border-radius: 3px;
  }
`;

const DurationInput: React.FC<{ onChange: () => void; className?: string }> = ({
  onChange,
  className,
}) => {
  const {
    values,
    setFieldValue,
    touchedAndHasError,
    errors,
    touched,
    setFieldTouched,
  } = useForm<FormValues>();

  const { estimated_duration = 0, estimated_duration_unit = "" } = values;

  const usePluralUnit = estimated_duration > 1;

  return (
    <div>
      <StyledTextField
        className={className}
        variant="background"
        type="number"
        placeholder="Estimated completion time"
        value={estimated_duration || ""}
        onChange={(value) => {
          setFieldValue("estimated_duration", +value);
          onChange();
        }}
        hasError={touchedAndHasError("estimated_duration")}
        min={MIN_ESTIMATE_VALUE}
        max={MAX_ESTIMATE_VALUE}
        step={1}
        onBlur={() => {
          setFieldTouched("estimated_duration");
        }}
        appendContent={
          <select
            value={estimated_duration_unit}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("estimated_duration_unit", value);
              onChange();
            }}
            style={
              touchedAndHasError("estimated_duration_unit")
                ? {
                    border: "1px solid var(--clr-error-500, #ea5b5b)",
                    background: "var(--clr-background-ukwn-11, #FFF0F3)",
                  }
                : {}
            }
          >
            <option value="Days">{usePluralUnit ? "Days" : "Day"}</option>
            <option value="Weeks">{usePluralUnit ? "Weeks" : "Week"}</option>
            <option value="Months">{usePluralUnit ? "Months" : "Month"}</option>
          </select>
        }
      />

      {!!errors.estimated_duration && !!touched.estimated_duration && (
        <p className="color-error mt-3">
          {errors.estimated_duration === FORM_ERRORS.MIN_ESTIMATE_VALUE &&
            `Please enter value of more than ${MIN_ESTIMATE_VALUE - 1}`}

          {errors.estimated_duration === FORM_ERRORS.MAX_ESTIMATE_VALUE &&
            `Please enter value of less than ${MAX_ESTIMATE_VALUE}`}
        </p>
      )}
    </div>
  );
};

export default DurationInput;
