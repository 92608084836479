import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.125rem;
`;

export const StyledIconContainer = styled.div`
  ${squareSizing("2.8rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--clr-background-25, #f6f8fa);
  border-radius: 10rem;
`;

export const StyledIconContainerInner = styled.div`
  ${squareSizing("1.5rem")};
  position: relative;
  background: var(--clr-primary-100, #5f57ff);
  border-radius: 10rem;
  box-shadow: 0 8px 12px rgba(95, 87, 255, 0.24);
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledTag = styled.div`
  color: #fff;
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 10rem;
  width: max-content;
  background: linear-gradient(90deg, #5f57ff 0%, #393499 100%);
`;
