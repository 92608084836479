import styled from "@emotion/styled";

import Button from "@/components/input/Button";

export const StyledOuterContainer = styled.div`
  position: relative;
`;

export const StyledOverlayButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const StyledButtonConainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  font-size: 0.75rem;
  padding: 0.25rem 0;
`;
