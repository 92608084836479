import * as yup from "yup";

import { FormValues } from "./PortfolioForm.types";
import { useForm } from "@/components/input/Form";

export const DEFAULT_FORM_VALUES = {
  name: "",
  description: "",
  assets: [],
  attachments: [],
  tech: [],
} satisfies FormValues;

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  attachments: yup.array(),
  tech: yup.array(),
});

export const usePortfolioForm = useForm<FormValues>;
