"use client";

import styled from "@emotion/styled";

import { Heading } from "@/components/Typography";

export const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  & > *:first-child {
    flex: 1;
    width: calc(100% - 24px);
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
`;

export const StyledHeading = styled(Heading)<{
  $hasError: boolean;
}>`
  padding-bottom: 0.25rem;
  border-bottom: 1px solid
    ${({ $hasError }) =>
      $hasError ? "var(--clr-error-500, #ea5b5b)" : "transparent"};
`;
