"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import ShimmerLoader from "@/components/misc/ShimmerLoader";

export const StyledContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  ${squareSizing("64px")};
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

export const StyledImage = styled.img`
  display: inline-flex;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  ${squareSizing("64px")};
  border: 1px solid rgba(0, 0, 0, 0.05);
  object-fit: contain;
  transition: opacity 240ms ease;
`;

export const StyledLoadingImage = styled(ShimmerLoader.Default)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;
