import React, { useMemo } from "react";

import { getContractOtherParticipant } from "@/services/ContractsService";

import {
  StyledAvatar,
  StyledEmail,
  StyledName,
  StyledUserDetailsContainer,
} from "../ChatRoomPage.styles";
import { useChatRoomPage } from "../ChatRoomPage.context";

const OtherUserDetails: React.FC = () => {
  const { contractDetails } = useChatRoomPage();

  const otherParticipant = useMemo(
    () =>
      contractDetails ? getContractOtherParticipant(contractDetails) : null,
    [contractDetails]
  );

  if (!otherParticipant) {
    return null;
  }

  return (
    <StyledUserDetailsContainer>
      <StyledAvatar
        img={
          otherParticipant?.user?.picture ||
          otherParticipant?.user?.owner_profile ||
          ""
        }
        firstName={otherParticipant?.user?.given_name || ""}
        lastName={otherParticipant?.user?.family_name || ""}
      />
      <StyledName>
        {otherParticipant?.user?.given_name || ""}{" "}
        {otherParticipant?.user?.family_name || ""}
      </StyledName>
      <StyledEmail>{otherParticipant?.user?.email || ""}</StyledEmail>
    </StyledUserDetailsContainer>
  );
};

export default OtherUserDetails;
