import TabsComponent from "@/components/navigation/Tabs";

import { TABS } from "../ContractManagementPage.config";
import { useContractManagement } from "../ContractManagementPage.context";

const Tabs = () => {
  const { activeTab, setActiveTab } = useContractManagement();

  return (
    <TabsComponent
      activeTabId={activeTab}
      onChange={({ id }) => setActiveTab(id)}
      tabs={TABS}
    />
  );
};

export default Tabs;
