import { FC } from "react";
import { DismissButton, Overlay, usePopover } from "react-aria";
import type { AriaPopoverProps } from "react-aria";
import type { OverlayTriggerState } from "react-stately";
import { OptionsContainer } from "../ComboBox.styles";

interface PopoverProps extends AriaPopoverProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
  width?: string;
  left?: string;
}

const Popover: FC<PopoverProps> = ({
  children,
  state,
  width,
  left,
  ...props
}) => {
  let { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <OptionsContainer
        {...popoverProps}
        ref={props.popoverRef as React.RefObject<HTMLDivElement>}
        style={{
          ...popoverProps.style,
          width,
        }}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </OptionsContainer>
    </Overlay>
  );
};

export default Popover;
