import React, { useEffect, useState } from "react";
import lodashGet from "lodash/get";

import Avatar from "@/components/misc/Avatar";
import Icon from "@/components/misc/Icon";
import { getChatRoomPageUrl, useConnectChannel } from "@/services/ChatService";
import Tag from "@/components/misc/Tag";
import { formatDate, isDateToday } from "@/utils/date";

import {
  StyledCard,
  StyledContent,
  StyledLeftSection,
  StyledBottomContainer,
  StyledRightSection,
  StyledTitle,
  StyledTopContainer,
  StyledUsername,
  StyledMessageCount,
  StyledDate,
  StyledLink,
} from "./ChatCard.styles";
import { IChatCardProps } from "./ChatCard.types";

const ChatCard: React.FC<IChatCardProps> = ({
  contractId,
  avatarImage,
  username,
  title,
  hasAttachment,
  unreadMessageCount,
  // content,
  // lastMessageDate: _,
  className,
  isOffer,
  openInNewTab,
  active,
  channel_id,
}) => {
  const { channel } = useConnectChannel(`Chat:${channel_id}`, {
    useLastMessage: true,
  });
  const [lastMessage, setLastMessage] = useState("");
  const [lastMessageDate, setLastMessageDate] = useState("");

  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.subscribe((message) => {
      const lastMessage = lodashGet(message, "data.content", "");

      const timestamp = lodashGet(message, "timestamp", "");

      if (timestamp) {
        const date = new Date(timestamp);
        const lastMessageDate = formatDate(
          date,
          isDateToday(date) ? "hh:mmA" : undefined
        );
        setLastMessageDate(lastMessageDate);
      }

      setLastMessage(lastMessage);
    });
  }, [channel]);

  return (
    <StyledCard $active={active || !!unreadMessageCount} className={className}>
      <StyledLink
        to={getChatRoomPageUrl(contractId)}
        target={openInNewTab ? "_blank" : "_self"}
      />

      <StyledTopContainer>
        <StyledLeftSection>
          <Avatar img={avatarImage} firstName={username} />
        </StyledLeftSection>

        <StyledRightSection>
          {isOffer && (
            <Tag variant="filled" colorVariant="primary" className="py-1 mb-2">
              Job offer
            </Tag>
          )}
          <StyledUsername>{username}</StyledUsername>
          <StyledTitle>{title}</StyledTitle>

          <StyledDate>{lastMessageDate}</StyledDate>
        </StyledRightSection>
      </StyledTopContainer>

      {(!!hasAttachment || !!lastMessage || !!unreadMessageCount) && (
        <StyledBottomContainer>
          <StyledLeftSection>
            {!!hasAttachment && (
              <Icon
                src="/assets/images/icons/clip.svg"
                alt="attachment"
                size="sm"
              />
            )}
          </StyledLeftSection>

          {(!!lastMessage || !!unreadMessageCount) && (
            <StyledRightSection>
              {!!lastMessage && <StyledContent>{lastMessage}</StyledContent>}

              {!!unreadMessageCount && (
                <StyledMessageCount>{unreadMessageCount}</StyledMessageCount>
              )}
            </StyledRightSection>
          )}
        </StyledBottomContainer>
      )}
    </StyledCard>
  );
};

export default ChatCard;
