import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MediaFile, MediaFileFilters } from "@/services/MediaService";

import { AppState } from "../index";
import { listStateRemover, listStateSetter } from "../utils";

export interface MediaState {
  files: MediaFile[];
}

const initialState: MediaState = {
  files: [],
};

export const mediaSlice = createSlice({
  name: "MEDIA",
  initialState,
  reducers: {
    addMediaFiles: (
      state,
      action: PayloadAction<{
        data: MediaFile[];
        replaceData?: boolean;
        replaceList?: boolean;
      }>
    ) => {
      const { data, replaceData, replaceList } = action.payload;
      state.files = listStateSetter(state.files, data, {
        replaceData,
        replaceList,
      });
    },

    removeMediaFiles: (
      state,
      action: PayloadAction<{
        idList: number[];
      }>
    ) => {
      const { idList } = action.payload;
      state.files = listStateRemover(state.files, idList);
    },
  },
});

export const selectFiles =
  (filters: MediaFileFilters = {}) =>
  (state: AppState) => {
    let files = state.media.files;

    const { file_ids = [], contract_ids = [] } = filters;

    if (file_ids.length) {
      files = files.filter((f) => file_ids.includes(f.id));
    }

    if (contract_ids.length) {
      files = files.filter((f) => contract_ids.includes(f.contract_id));
    }

    return files;
  };

export const selectFile = (id: number) => (state: AppState) =>
  state.media.files.find((f) => f.id === id) || null;

export const mediaActions = mediaSlice.actions;
export const mediaReducer = mediaSlice.reducer;
