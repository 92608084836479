import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import lodashGet from "lodash/get";

import { ContractCompleteDetails } from "@/services/ContractsService";
import { useHash } from "@/services/Routing";

import { useChatRoomPage } from "../ChatRoomPage.context";
import { CONTRACT_DETAILS_HASH, STATUS_CONFIG } from "../ChatRoomPage.config";

export const StyledContainer = styled.div`
  position: relative;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  letter-spacing: -0.04em;
  font-weight: 600;
  color: var(--clr-text-white, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  cursor: pointer;

  & > *:first-child {
    flex: 1;
    width: calc(100% - 24px);
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StatusSection: React.FC<{ contractDetails: ContractCompleteDetails }> = ({
  contractDetails,
}) => {
  const { status, title } = contractDetails;
  const { getUrlWithHash } = useHash();
  const { viewJobDetails } = useChatRoomPage();

  const bgColor = lodashGet(
    STATUS_CONFIG,
    `${status}.color`
  ) as unknown as string;
  const statusText = lodashGet(
    STATUS_CONFIG,
    `${status}.label`
  ) as unknown as string;

  if (!statusText) {
    return null;
  }

  return (
    <StyledContainer style={{ background: bgColor }} onClick={viewJobDetails}>
      <StyledLink to={getUrlWithHash(CONTRACT_DETAILS_HASH)} />
      <span>Job : {title}</span>
      <span>{statusText}</span>
    </StyledContainer>
  );
};

export default StatusSection;
