import useSWR from "swr";

import { chatService } from "@/config/services";
import { selectChatSummary, useAppSelector } from "@/services/Store";

function useChatSummary() {
  const data = useAppSelector(selectChatSummary);

  const { isLoading } = useSWR("/chats/summary", chatService.getChatSummary);

  return {
    data,
    isLoading,
  };
}

export default useChatSummary;
