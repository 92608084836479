"use client";

import styled from "@emotion/styled";

import Editor from "./components/Editor";
import { squareSizing } from "@/utils/css";

export const StyledEditor = styled(Editor)`
  display: flex;
  flex-direction: column;
  background: var(--clr-background-disabled, #f8fafb);
  height: 100%;

  & > .toolbar {
    order: 2;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 2;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }

  & > .toolbar > * {
    margin: 0;
  }

  .public-DraftEditorPlaceholder-root {
    opacity: 0.5;
    padding: 0.25rem;
  }

  .public-DraftStyleDefault-block {
    margin-block: 0;
  }

  .rdw-inline-wrapper:first-of-type .rdw-option-wrapper:first-of-type {
    border-top-left-radius: 0.75rem;
  }

  .rdw-option-wrapper {
    ${squareSizing("52px")};
    width: 52px;
    margin: 0;
    border: none;

    &:hover {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.07);
    }

    &.rdw-option-active {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }
  }

  & > .editor {
    flex: 1;

    overflow: auto;

    span,
    div {
      color: var(--clr-text-900, #0d0d12);
      border: 1px solid transparent;
    }
  }
`;
