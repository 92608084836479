import { useCallback, useRef, useState } from "react";

import Form from "@/components/input/Form";
import TextField from "@/components/input/TextField";
import EditorField from "@/components/input/EditorField";
import FilesInput, { FileObject } from "@/features/input/FilesInput";
import Button from "@/components/input/Button";

import { FormValues, IPackageFormProps } from "./PackageForm.types";
import { DEFAULT_FORM_VALUES, VALIDATION_SCHEMA } from "./PackageForm.config";
import DurationInput from "./components/DurationInput";

const PackageForm: React.FC<IPackageFormProps> = ({
  initialData: initialDataFromProps = DEFAULT_FORM_VALUES,
  onSubmit,
  className,
  ctaProps = {},
}) => {
  const [updated, setUpdated] = useState(false);

  const initialDataRef = useRef({
    ...initialDataFromProps,
    assets: (initialDataFromProps.attachments || []).map(
      (file) => ({ mediaFile: file } as FileObject)
    ),
  });

  //---------------------------

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (onSubmit) {
        onSubmit({ values });
      }
    },
    [onSubmit]
  );

  //---------------------------

  const { show: showCtaButton = true } = ctaProps;

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      initialValues={{ ...DEFAULT_FORM_VALUES, ...initialDataRef.current }}
      yupValidationSchema={VALIDATION_SCHEMA}
    >
      {({
        context: {
          values,
          setFieldValue,
          submitForm,
          touchedAndHasError,
          errors,
        },
      }) => {
        const hasErrors = !!Object.keys(errors).length;

        return (
          <form className={className} onSubmit={submitForm}>
            <TextField
              variant="background"
              placeholder="Package name"
              value={values?.name}
              onChange={(value) => {
                setFieldValue("name", value);
                setUpdated(true);
              }}
              hasError={touchedAndHasError("name")}
            />

            <EditorField
              focusOnMount
              prependContent={null}
              label=""
              placeholder="Describe what the client will be paying for"
              value={{ markdown: values?.description }}
              onChange={({ markdown }) => {
                setFieldValue("description", markdown);
                setUpdated(true);
              }}
              drawerTitle="Description"
              customPlaceholder={
                <TextField
                  textArea
                  className="mt-3"
                  variant="background"
                  placeholder="Description"
                  value={values?.description}
                  hasError={touchedAndHasError("description")}
                />
              }
              hasError={touchedAndHasError("description")}
            />

            <TextField
              className="mt-4"
              variant="background"
              placeholder="Price"
              type="number"
              value={values?.price || ""}
              onChange={(value) => {
                setFieldValue("price", value);
                setUpdated(true);
              }}
              hasError={touchedAndHasError("price")}
            />

            <DurationInput
              className="mt-4"
              onChange={() => {
                setUpdated(true);
              }}
            />

            <FilesInput
              className="mt-4"
              displayFileCount={2}
              label="Add examples"
              value={values.assets}
              onChange={(value) => {
                setFieldValue("assets", value);
                setUpdated(true);
              }}
            />

            {!!showCtaButton && (
              <Button
                type="submit"
                disabled={!hasErrors && !updated}
                colorVariant={hasErrors ? "disabled" : "primary"}
                className="w-100 mt-4"
                {...ctaProps}
              >
                {ctaProps.children || "Save"}
              </Button>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default PackageForm;
