import { DrawerState } from "@/components/misc/Drawer";
import {
  ContractCompleteDetails,
  ContractListFilters,
} from "@/services/ContractsService";

export enum ContractTab {
  Active = "Active",
  Completed = "Completed",
}

export interface IContractsListPageContext {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  activeTab: ContractTab;
  setActiveTab: React.Dispatch<React.SetStateAction<ContractTab>>;
  contractList: ContractCompleteDetails[];
  isContractListLoading: boolean;
  sortPanelState: DrawerState;
  filterPanelState: DrawerState;
  filters: ContractListFilters;
  setFilters: React.Dispatch<React.SetStateAction<ContractListFilters>>;
}
