"use client";

import styled from "@emotion/styled";

import NoOffers from "@/features/contracts/NoOffers";
import ContractCard from "@/features/contracts/ContractCard";

export const StyledContainer = styled.section`
  padding-inline: 1rem;
  margin-inline: -1rem;
`;

export const StyledNoOffers = styled(NoOffers)`
  margin-top: 4rem;
`;

export const StyledContractCard = styled(ContractCard)`
  padding-inline: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
  border-radius: 0;
`;
