import { useCallback, useEffect, useRef, useState } from "react";
import lodashGet from "lodash/get";
import lodashSet from "lodash/set";

import { UserProfile, useUpdateProfile } from "@/services/UserService";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Heading } from "@/components/Typography";
import Button from "@/components/input/Button";
import RatingInput from "@/components/input/RatingInput";
import Icon from "@/components/misc/Icon";
import { analytics } from "@/config/services";
import { NotifyEventsArgs } from "@/services/Analytics";

import {
  StyledContainer,
  StyledContent,
  StyledContentItalic,
} from "./useNotifyForFeature.styles";

type FeatureName =
  | "PREMIUM_SUBSCRIPTION"
  | "PAYPIPE_ID"
  | "CREATE_OFFER_AS_CLIENT"
  | "HOURLY_OFFER"
  | "CHAT_INVITE"
  | "GENERATE_WITH_AI";

type Params = {
  featureName: FeatureName;
  onModalOpenChange?: (isOpen: boolean) => void;
};

function useNotifyForFeature(params: Params) {
  const { featureName, onModalOpenChange } = params;
  const {
    profileData,
    update: updateProfileData,
    isProfileDataLoading,
  } = useUpdateProfile();

  const notifyFromDb = lodashGet(
    profileData,
    `misc.featuresInterest[${featureName}].notify`,
    false
  );
  const interestRatingFromDb = lodashGet(
    profileData,
    `misc.featuresInterest[${featureName}].interestRating`,
    0
  );

  const [notify, setNotify] = useState(notifyFromDb);
  const [interestRating, setInterestRating] = useState(interestRatingFromDb);
  const [isUpdating, setIsUpdating] = useState(false);

  const updatedStateFromDb = useRef(false);

  //-----------------------

  const update = useCallback(
    (notify?: boolean) => {
      if (isUpdating) {
        return;
      }

      if (notify === undefined) {
        notify = notifyFromDb;
      }

      if (notifyFromDb === notify && interestRatingFromDb === interestRating) {
        return Promise.resolve();
      }

      const data: NotifyEventsArgs = {
        feature_name: featureName.toLowerCase(),
        opted_for_notify: notify,
        prev_interest_rating: interestRatingFromDb,
      };
      if (interestRatingFromDb !== interestRating) {
        data.updated_interest_rating = true;
        data.new_interest_rating = interestRating;
      }
      analytics.triggerNotifyEvent("notify_for_feature_launch", data);

      const updatedProfileData = JSON.parse(
        JSON.stringify(profileData)
      ) as UserProfile;
      lodashSet(updatedProfileData, `misc.featuresInterest[${featureName}]`, {
        notify,
        interestRating,
      });

      setIsUpdating(true);
      setNotify(true);
      return updateProfileData(updatedProfileData, { notify: false })
        .catch(() => {
          analytics.triggerEvent({
            event: "notify_for_feature_launch_save_failed",
          });
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [
      featureName,
      isUpdating,
      notifyFromDb,
      interestRatingFromDb,
      interestRating,
      profileData,
      updateProfileData,
    ]
  );

  //-----------------------

  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        update();
      }

      if (onModalOpenChange) {
        onModalOpenChange(isOpen);
      }
    },
  });

  //-----------------------

  useEffect(() => {
    if (isProfileDataLoading || !updatedStateFromDb.current) {
      return;
    }

    setNotify(notifyFromDb);
    setInterestRating(interestRatingFromDb);
  }, [isProfileDataLoading, notifyFromDb, interestRatingFromDb]);

  //-----------------------

  const jsx = (
    <>
      <Modal state={modalState} width="410px" className="p-3">
        <StyledContainer>
          <Icon
            isSrcRelative
            customSize="2.5rem"
            className="icon"
            src="clock_green.svg"
          />
          <Button
            className="close-button"
            variant="ghost"
            onClick={modalState.close}
          >
            <Icon isSrcRelative size="xs" src="cross.svg" />
          </Button>

          <Heading size="md">Coming soon!</Heading>
          <StyledContent size="lg" className="mt-2">
            We're putting the finishing touches on this feature and will be
            rolling it out soon.
          </StyledContent>

          <Heading size="md" className="mt-5">
            How much does this feature mean to you?
          </Heading>
          <RatingInput
            useNumbers
            className="mt-3"
            value={interestRating}
            onChange={setInterestRating}
          />

          {notify ? (
            <StyledContentItalic size="lg" className="mt-5">
              You'll get a notification when this feature is live.
            </StyledContentItalic>
          ) : (
            <>
              <Heading size="md" className="mt-5">
                Be the first to know when it's live.
              </Heading>
              <Button className="mt-3 w-100" onClick={() => update(true)}>
                Notify me
              </Button>
            </>
          )}
        </StyledContainer>
      </Modal>
    </>
  );

  return {
    isLoading: isProfileDataLoading,
    notify,
    interestRating,
    modalState,
    jsx,
  };
}

export default useNotifyForFeature;
