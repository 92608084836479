import React from "react";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import SettingCard from "@/features/app/SettingCard";
import Switch from "@/components/input/Switch";
import { useThemeManager } from "@/styles/ThemeManager";

const ProfileSettingsPage: React.FC = () => {
  const { darkModeEnabled, toggleDarkMode } = useThemeManager();

  return (
    <PageWithHeaderLayout
      headerProps={{ titleProps: { children: "Preferences" } }}
    >
      <div className="p-3">
        <SettingCard
          title="Dark mode"
          input={<Switch value={darkModeEnabled} onChange={toggleDarkMode} />}
        />
      </div>
    </PageWithHeaderLayout>
  );
};

export default ProfileSettingsPage;
