"use client";

import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import lodashGet from "lodash/get";

import {
  SocialLink,
  useIsValidPaypipeId,
  UserProfile,
  UserProfileUpdateFields,
  useUpdatePaypipeId,
  useUpdateProfile,
  useUpdateProfileImage,
} from "@/services/UserService";
import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import ComboBox from "@/components/input/ComboBox";
import { squareSizing } from "@/utils/css";
import {
  SOCIAL_LINKS,
  SOCIAL_LINKS_MAP,
} from "@/services/UserService/UserService.config";
import { useToast } from "@/components/misc/Toast";
import { MAX_FILE_SIZE_IN_BYTES } from "@/config/app";
import { Body } from "@/components/Typography";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import RingLoader from "@/components/misc/RingLoader";
import { useForm } from "@/components/input/Form";
import { useModalState } from "@/components/misc/Modal";
import Avatar from "@/components/misc/Avatar";
import { URL_REGEX } from "@/utils/string";
import LocationSuggessionInput, {
  getAddressDisplayValue,
} from "@/features/input/LocationSuggessionInput";

import EditFormButton from "./EditFormButtonNew";
import { IAddButtonProps } from "./AddButton";
import { AddressParts } from "@/services/Location";
import { joinClassNames } from "@/utils/classNames";

//-----------------------------------

const ERRORS = {
  INVALID_LINK: "INVALID_LINK",
  MAX_LIMIT: "MAX_LIMIT",
};

const VALIDATION_SCHEMA = yup.object().shape({
  givenName: yup.string().required().min(1),
  familyName: yup.string().required().min(1),
  paypipeId: yup.string().required().min(1),
  links: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().max(20, ERRORS.MAX_LIMIT),
        url: yup.string().required().matches(URL_REGEX, ERRORS.INVALID_LINK),
      })
    )
    .required(),
  location: yup.string(),
});

//-----------------------------------

const StyledContainer = styled.div`
  .paypipe-id-field {
    padding-inline-end: 0;
    gap: 0;
    overflow: auto;

    input {
      padding-inline-start: 0;
    }

    .prefix {
      pointer-events: none;
    }
  }

  small {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: #64748b;
  }

  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #64748b;
  }

  .field-row {
    & > * {
      margin-top: 1.25rem;
    }
  }

  ${screenLargerThan.tablet} {
    .field-row {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;

      & > * {
        margin-top: 1.75rem;
        width: 100%;
      }
    }
  }

  .profile-image {
    display: flex;
    align-items: center;
    gap: 1rem;

    .image {
      ${squareSizing("6.25rem")};
      border-radius: 10rem;
      object-fit: cover;
    }

    label {
      position: relative;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
    }

    .button {
      background: #f5f5f5;
      padding: 0.75rem 1rem;
      border: none;
    }
  }
`;

const StyledLinkInput = styled.div`
  .cta-button {
    ${squareSizing("3.25rem")};
    border-radius: 10rem;
  }

  .field {
    position: relative;
    overflow: hidden;
  }

  .url-field {
    padding-inline-end: 0;
    gap: 0;
    overflow: auto;

    input {
      padding-inline-start: 0;
    }

    .prefix {
      pointer-events: none;
    }
  }

  & + & {
    margin-top: 1.25rem;
  }

  ${screenLargerThan.tablet} {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 3fr 5fr;
    gap: 1.25rem;
  }

  ${screenSmallerThan.tablet} {
    gap: 0.75rem;

    .field {
      flex-grow: 1;

      & + & {
        margin-top: 1.75rem;
      }
    }

    .field + .field {
      margin-top: 1.75rem;
    }

    .select-field {
      width: 100%;
    }

    & + & {
      margin-top: 1.75rem;
    }
  }
  .url-field {
    position: static;
    padding-right: 4rem;

    ::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }

    input {
      overflow: visible;
    }

    .cta-button {
      position: absolute;
      right: 0.125rem;
      top: 0.125rem;
      background: inherit;
      border-radius: inherit !important;
      border: 4px solid transparent;
    }
  }
`;

const StyledDashedButton = styled(Button)`
  border-style: dashed;
  width: 100%;
  --bg-color: #f9fafb !important;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  padding-block: 1rem;
`;

const StyledError = styled(Body)`
  color: #f04438;
`;

const StyledGhostButton = styled(Button)`
  display: block;
  text-align: center;
  margin-inline: auto;
`;

//-----------------------------------

type FormValues = {
  givenName: string;
  familyName: string;
  location: string;
  paypipeId: string;
  links: SocialLink[];
  locationDetails: null | {
    addressParts: AddressParts;
  };
};

const SOCIAL_LINKS_OPTION = SOCIAL_LINKS.map((item) => ({
  ...item,
  label: item.displayName || item.platformName || "",
  value: item.platformName,
}));

const LinkInput: React.FC<{
  index: number;
  className?: string;
}> = ({ index, className }) => {
  const { values, setFieldValue, touchedAndHasError, setFieldTouched, errors } =
    useForm<FormValues>();
  const nameKey = `links[${index}].name`;
  const name = lodashGet(values, nameKey, "");
  const isNameMaxError = lodashGet(errors, nameKey, "") === ERRORS.MAX_LIMIT;

  const urlKey = `links[${index}].url`;
  const url = lodashGet(values, urlKey, "") as string;
  const isUrlInvalid = lodashGet(errors, urlKey, "") === ERRORS.INVALID_LINK;

  const handleDelete = useCallback(() => {
    const updatedLinks = [...values.links];
    updatedLinks.splice(index, 1);
    setFieldValue("links", updatedLinks);
  }, [values.links, index, setFieldValue]);

  const { prefix = "" } = SOCIAL_LINKS_MAP[name] || {};

  const urlOptions = useMemo(
    () =>
      SOCIAL_LINKS_OPTION.filter(
        ({ value }) =>
          value === name || !values.links.find(({ name }) => value === name)
      ),
    [values.links, name]
  );

  return (
    <StyledLinkInput className={className}>
      <div className="field">
        <ComboBox
          allowsCustomValue
          scrollToTopOnFocus
          filterBySearch={false}
          placeholder="Select Platform"
          value={name}
          className="link-field"
          label="Social link"
          options={urlOptions}
          onChange={(value) => {
            setFieldValue(nameKey, value);
          }}
          hasError={touchedAndHasError(nameKey)}
          onBlur={() => {
            setFieldTouched(nameKey);
          }}
        />
        {isNameMaxError && (
          <StyledError size="md" className="mt-2 px-2">
            Please limit the link name to 20 characters max
          </StyledError>
        )}
      </div>

      <div className="field">
        <TextField
          focusOnClick
          scrollToTopOnFocus
          useFloatingLabel={false}
          className="field url-field"
          variant="background"
          hasError={touchedAndHasError(urlKey)}
          onBlur={() => {
            setFieldTouched(urlKey);
          }}
          appendContent={
            <Button
              variant="ghost"
              colorVariant="gray"
              className="cta-button"
              onClick={handleDelete}
            >
              <Icon
                isSrcRelative
                src="dustbin.svg"
                size="md"
                colorVariant="gray"
              />
            </Button>
          }
          // prefix=""
          value={
            prefix ? (url.includes(prefix) ? url.split(prefix)[1] : url) : url
          }
          // prependContent={url ? null : <span className="prefix">{prefix}</span>}
          prependContent={<span className="prefix">{prefix}</span>}
          onChange={(value) => {
            if (!prefix) {
              setFieldValue(urlKey, value);
              return;
            }

            if (value.includes(prefix)) {
              value = value.split(prefix)[1];
            }

            if (!value) {
              setFieldValue(urlKey, "");
              return;
            }

            setFieldValue(urlKey, `${prefix}${value}`);
          }}
          placeholder={prefix ? "" : "Enter URL"}
        />
        {isUrlInvalid && (
          <StyledError size="md" className="mt-2 px-2">
            Please enter a valid URL , i.e https://www.portfolio.com
          </StyledError>
        )}
      </div>
    </StyledLinkInput>
  );
};

//-----------------------------------

const EditMainDetails: React.FC<{
  className?: string;
  dashedVariantProps?: IAddButtonProps;
}> = ({ className, dashedVariantProps }) => {
  const addButtonRef = useRef<null | HTMLButtonElement>(null);

  const { createToast } = useToast();
  const { profileData, updateFields, accountData } = useUpdateProfile();
  const { update: updateProfileImage } = useUpdateProfileImage();
  const { update: updatePaypipeId } = useUpdatePaypipeId();

  const [isUpdating, setIsUpdating] = useState(false);
  const modalState = useModalState({
    urlHash: "#details",
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const initialValues = useMemo(
    () =>
      ({
        givenName: accountData?.given_name || "",
        familyName: accountData?.family_name || "",
        paypipeId: accountData?.paypipe_id || "",
        location: profileData?.location || "",
        links: profileData?.links || [],
        locationDetails: profileData?.locationDetails || null,
      } satisfies FormValues),
    [accountData, profileData]
  );

  const [paypipeId, setPaypipeId] = useState(accountData?.paypipe_id || "");
  const { data: paypipeIdValidationData, isValidating: isValidatingPaypipeId } =
    useIsValidPaypipeId({
      id: paypipeId === initialValues.paypipeId ? "" : paypipeId,
    });

  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState(accountData?.picture || "");
  const imageUpdated = !!newImageFile;

  const lastUpdatedPaypipeId = accountData?.last_updated_paypipe_id;

  //----------------------------

  const apply = useCallback(
    (values: FormValues) => {
      if (isUpdating) {
        return;
      }

      const {
        givenName,
        familyName,
        location,
        links,
        paypipeId,
        locationDetails,
      } = values;

      const promises: Promise<any>[] = [];
      if (imageUpdated) {
        promises.push(updateProfileImage(newImageFile));
        setNewImageFile(null);
      }

      const paypipeIdUpdated = paypipeId !== initialValues.paypipeId;
      if (paypipeIdUpdated) {
        promises.push(updatePaypipeId(paypipeId));
      }

      const linksUpdated =
        JSON.stringify(links) !== JSON.stringify(initialValues.links || []);
      const locationUpdated = locationDetails !== initialValues.locationDetails;
      const profileDataUpdated = locationUpdated || linksUpdated;
      const accountDataUpdated =
        givenName !== initialValues.givenName ||
        familyName !== initialValues.familyName;
      if (profileDataUpdated || accountDataUpdated) {
        const updatedProfileData: Partial<UserProfile> = {
          location,
          locationDetails,
        };
        if (linksUpdated) {
          updatedProfileData.links = links.filter(
            (item) => !!item.name && !!item.url
          );
        }

        const updatedAccountData: Partial<UserProfileUpdateFields> = {};
        if (accountDataUpdated) {
          updatedAccountData.given_name = givenName;
          updatedAccountData.family_name = familyName;
        }

        promises.push(
          updateFields(updatedProfileData, { accountData: updatedAccountData })
        );
      }

      setIsUpdating(true);
      return Promise.allSettled(promises)
        .then(() => {
          createToast({
            title: "Details updated",
            timeoutInMilliseconds: 5000,
          });
          modalState.close();
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [
      updateFields,
      imageUpdated,
      updateProfileImage,
      newImageFile,
      updatePaypipeId,
      initialValues,
      isUpdating,
      modalState,
      createToast,
    ]
  );

  //--------

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const [file] = e.target.files;

      if (file) {
        if ((file.size || 0) > MAX_FILE_SIZE_IN_BYTES) {
          createToast({
            variant: "error",
            title: "Please choose files smaller than 5mb",
            timeoutInMilliseconds: 1000 * 5,
          });

          return;
        }

        setImageSrc(URL.createObjectURL(file));
        setNewImageFile(file);
      }
    },
    [createToast]
  );

  //----------------------------

  const paypipeIdUpdated = paypipeId !== initialValues.paypipeId;
  const hasPaypipeIdError = paypipeIdUpdated
    ? isValidatingPaypipeId
      ? false
      : !paypipeIdValidationData.isValid
    : false;
  const disableSave = isValidatingPaypipeId || hasPaypipeIdError || isUpdating;
  const disableCancel = isUpdating;

  return (
    <EditFormButton<FormValues>
      heading="My information"
      description="Make sure your personal information is correct and up-to-date."
      dashedVariantProps={dashedVariantProps}
      modalState={modalState}
      saveButtonProps={{
        disabled: disableSave,
      }}
      cancelButtonProps={{
        disabled: disableCancel,
      }}
      modalProps={{
        width: "860px",
      }}
      editButtonProps={{ className }}
      formProps={{
        initialValues,
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
        onChange: (values) => {
          setPaypipeId(values.paypipeId);
        },
      }}
      content={({
        context: { values, setFieldValue, touchedAndHasError, setFieldTouched },
      }) => {
        const addLink = () => {
          const updatedLinks = [
            ...values.links,
            {
              name: "",
              url: "",
            },
          ];
          setFieldValue("links", updatedLinks);

          if (addButtonRef.current) {
            setTimeout(() => {
              addButtonRef.current?.scrollIntoView({
                behavior: "smooth",
              });
            }, 500);
          }
        };

        return (
          <StyledContainer>
            <div className="profile-image">
              <Avatar
                className="image"
                img={imageSrc}
                firstName={accountData?.given_name || ""}
                lastName={accountData?.family_name || ""}
              />

              <label>
                <Button
                  className="button"
                  colorVariant="gray"
                  disabled={isUpdating}
                >
                  Change Photo
                  <input
                    type="file"
                    onChange={handleChange}
                    accept="image/*"
                    disabled={isUpdating}
                  />
                </Button>
              </label>
            </div>

            <div className="field-row">
              <div>
                <label className="label">First name</label>
                <TextField
                  variant="background"
                  value={values.givenName}
                  onChange={(value) => {
                    setFieldValue("givenName", value);
                  }}
                  hasError={touchedAndHasError("givenName")}
                  onBlur={() => {
                    setFieldTouched("givenName");
                  }}
                />
              </div>

              <div>
                <label className="label">Last name</label>
                <TextField
                  variant="background"
                  value={values.familyName}
                  onChange={(value) => {
                    setFieldValue("familyName", value);
                  }}
                  hasError={touchedAndHasError("familyName")}
                  onBlur={() => {
                    setFieldTouched("familyName");
                  }}
                />
              </div>
            </div>

            <div className="field-row">
              <div>
                <label className="label">Location</label>
                <LocationSuggessionInput
                  placeId={values.locationDetails?.addressParts.placeId}
                  value={
                    values.locationDetails
                      ? getAddressDisplayValue(values.locationDetails)
                      : ""
                  }
                  onChange={({ addressParts }) => {
                    setFieldValue(
                      "location",
                      getAddressDisplayValue({ addressParts })
                    );
                    setFieldValue("locationDetails", {
                      addressParts,
                    });
                  }}
                />
              </div>
              <div>
                <label className="label">Paypipe ID</label>
                <TextField
                  disabled={!!lastUpdatedPaypipeId}
                  className="paypipe-id-field"
                  variant="background"
                  value={values.paypipeId}
                  onChange={(value) => {
                    setFieldValue("paypipeId", value);
                  }}
                  hasError={
                    hasPaypipeIdError || touchedAndHasError("paypipeId")
                  }
                  onBlur={() => {
                    setFieldTouched("paypipeId");
                  }}
                  prependContent={<span className="prefix">@</span>}
                  appendContent={isValidatingPaypipeId && <RingLoader />}
                />

                {!paypipeIdValidationData.isValid &&
                  paypipeIdValidationData.errors.map((error) => (
                    <StyledError size="md" key={error} className="mt-2 px-2">
                      - {error}
                    </StyledError>
                  ))}
                <small className="mt-2">
                  You can only change this once every 6 months
                </small>
              </div>
            </div>

            <hr className="mt-4 mb-4" />

            {!!values.links.length && (
              <StyledLinkInput>
                <div className="field">
                  <label className="label">Platforms</label>
                </div>
                <div className="field">
                  <label className="label">Links</label>
                </div>
              </StyledLinkInput>
            )}
            {values.links.map((_, i) => (
              <LinkInput
                key={i}
                index={i}
                className={joinClassNames("mb-3", i === 0 && "mt-1")}
              />
            ))}

            <StyledDashedButton
              ref={addButtonRef}
              colorVariant="gray"
              onClick={addLink}
            >
              <Icon isSrcRelative src="plus_round.svg" size="sm" />
              {values.links.length ? "Add Another Link" : "Add a Link"}
            </StyledDashedButton>

            <StyledGhostButton
              variant="ghost"
              colorVariant="black"
              className="mt-2"
              onClick={addLink}
            >
              Can't find what you're after?
            </StyledGhostButton>
          </StyledContainer>
        );
      }}
    />
  );
};

export default EditMainDetails;
