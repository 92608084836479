import React from "react";
import { usePress } from "react-aria";
import isString from "lodash/isString";

import { Label } from "@/components/Typography";

import {
  StyledBgContainer,
  StyledContent,
  StyledCta,
  StyledDefaultContainer,
  StyledIcon,
  StyledIconContainer,
  StyledLink,
  StyledRightSection,
  StyledTitle,
  StyledTopContainer,
} from "./ActionCard.styles";
import { IActionCardProps } from "./ActionCard.types";

const ActionCard: React.FC<IActionCardProps> = ({
  icon,
  content,
  ctaProps,
  className,
  color,
  link,
  priority,
  title,
}) => {
  const { pressProps, isPressed } = usePress({});
  const priorityUrgent = priority === "urgent";

  const iconJsx =
    !!icon &&
    (isString(icon) ? (
      <StyledIconContainer style={{ backgroundColor: color }}>
        <StyledIcon isSrcRelative src={icon} customSize="1.3125rem" />
      </StyledIconContainer>
    ) : (
      icon
    ));
  const ctaJsx = !!ctaProps && (
    <StyledCta {...ctaProps} className="mt-3">
      <Label size="md">{ctaProps.children}</Label>
    </StyledCta>
  );

  if (priorityUrgent) {
    return (
      <StyledBgContainer className={className}>
        <StyledTopContainer className="mb-3">
          {iconJsx} <StyledTitle size="lg">{title}</StyledTitle>
        </StyledTopContainer>

        <StyledRightSection>
          <StyledContent size="md">{content}</StyledContent>
          {ctaJsx}
        </StyledRightSection>

        {!!link && <StyledLink to={link} />}
      </StyledBgContainer>
    );
  }

  return (
    <StyledDefaultContainer
      className={className}
      {...(pressProps as any)}
      style={{
        background: isPressed
          ? "var(--clr-background-ukwn-1, #FAFAFA)"
          : "var(--clr-background-white, #fff)",
      }}
    >
      {iconJsx}

      <StyledRightSection>
        <StyledContent size="md">{content}</StyledContent>
        {ctaJsx}
      </StyledRightSection>

      {!!link && <StyledLink to={link} />}
    </StyledDefaultContainer>
  );
};

export default ActionCard;
