import { useCallback, useEffect, useRef, useState } from "react";

function useCountdown({
  targetTime: targetTimeFromProps,
  updateByInMs = 1000,
  countUp = false,
  startOnMount = true,
  syncPropsTargetTime = true,
}: {
  targetTime: Date | null;
  updateByInMs?: number;
  countUp?: boolean;
  startOnMount?: boolean;
  syncPropsTargetTime?: boolean;
}) {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [remainingTimeInMs, setRemainingTimeInMs] = useState(
    targetTimeFromProps
      ? new Date().getTime() - targetTimeFromProps.getTime()
      : 0
  );
  const [isRunning, setIsRunning] = useState(startOnMount);
  const [targetTime_, setTargetTime] = useState(targetTimeFromProps);
  const targetTime = syncPropsTargetTime ? targetTimeFromProps : targetTime_;

  //-------------------------

  const start = useCallback(() => {
    setIsRunning(true);
  }, []);

  const pause = useCallback(() => {
    setIsRunning(false);
  }, []);

  const stop = useCallback(() => {
    setIsRunning(false);
    setRemainingTimeInMs(0);
  }, []);

  const updateTargetTime = useCallback((value: Date | null) => {
    setTargetTime(value);
  }, []);

  //-------------------------

  useEffect(() => {
    if (!!targetTime && isRunning) {
      const countdownInterval = (intervalRef.current = setInterval(() => {
        const targetTimeInMs = targetTime.getTime();
        const currentTime = new Date().getTime();
        let remainingTime = countUp
          ? currentTime - targetTimeInMs
          : targetTimeInMs - currentTime;

        if (countUp) {
          if (remainingTime >= 0) {
            remainingTime = 0;
            clearInterval(countdownInterval);
            stop();
          }
        } else {
          if (remainingTime <= 0) {
            remainingTime = 0;
            clearInterval(countdownInterval);
            stop();
          }
        }

        setRemainingTimeInMs(remainingTime);
      }, updateByInMs));

      return () => {
        clearInterval(countdownInterval);
        stop();
      };
    }
  }, [targetTime, updateByInMs, isRunning, countUp, stop]);

  useEffect(() => {
    setRemainingTimeInMs(
      targetTime
        ? new Date().getTime() - targetTime.getTime()
        : 0
    );
  }, [targetTime]);

  //-------------------------

  return {
    start,
    stop,
    pause,
    remainingTimeInMs,
    remainingTimeInS: Math.round(remainingTimeInMs / 1000),
    updateTargetTime,
  };
}

export default useCountdown;
