import React from "react";

import { formatDate } from "@/utils/date";
import ShowMore from "@/components/input/ShowMore";
import Wysiwyg from "@/components/input/LexicalWysiwyg";

import {
  StyledContainer,
  StyledInputsContainer,
  StyledLabel,
  StyledFieldContainer,
  StyledNameContainer,
  StyledValue,
  StyledValueBold,
  StyledValueLight,
  StyledTopContainer,
  StyledFieldBGContainer,
} from "./MilestonesDisplay.styles";
import { IMilestoneInputProps } from "./MilestonesDisplay.types";

const MilestonesDisplay: React.FC<IMilestoneInputProps> = ({
  milestones = [],
  className,
}) => {
  return (
    <StyledContainer className={className}>
      {milestones.map(({ name, description, value, end_date }, i) => {
        const endDate = end_date ? formatDate(end_date) : "";

        return (
          <StyledInputsContainer $showCounter key={`${name}_${i}`}>
            <StyledTopContainer>
              <StyledNameContainer>
                <StyledLabel>Milestone Name</StyledLabel>
                <StyledValue className="mt-1">{name}</StyledValue>
              </StyledNameContainer>

              <StyledValueBold>${value}</StyledValueBold>
            </StyledTopContainer>

            <StyledFieldBGContainer className="mt-2">
              <StyledLabel>Description</StyledLabel>
              <StyledValueLight className="mt-2">
                <ShowMore showMoreOnContentClick maxHeight="2.5rem">
                  <Wysiwyg
                    unstyled
                    readOnly
                    showFullContent
                    value={{ markdown: description }}
                  />
                </ShowMore>
              </StyledValueLight>
            </StyledFieldBGContainer>

            {!!endDate && (
              <StyledFieldContainer className="mt-3">
                <StyledLabel>Due Date</StyledLabel>
                <StyledValue>{endDate}</StyledValue>
              </StyledFieldContainer>
            )}
          </StyledInputsContainer>
        );
      })}
    </StyledContainer>
  );
};

export default MilestonesDisplay;
