import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import { StyledContainer, StyledMiddleSection } from "./UserInfo.styles";

const UserInfo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <ShimmerLoader.Circular size="62px" style={{ borderRadius: "1rem" }} />

      <StyledMiddleSection>
        <ShimmerLoader.Default
          style={{ height: "0.75rem", width: "5rem" }}
        />
        <ShimmerLoader.Default
        className="mt-2"
          style={{ height: "1rem", width: "12rem" }}
        />
        <ShimmerLoader.Default
        className="mt-2"
          style={{ height: "0.75rem", width: "4rem" }}
        />
      </StyledMiddleSection>

      <ShimmerLoader.Circular size="50px" />
    </StyledContainer>
  );
};

export default UserInfo;
