import React, { useMemo, useRef } from "react";
import { useBreadcrumbItem, useBreadcrumbs } from "react-aria";
import Icon from "@/components/misc/Icon";
import isArray from "lodash/isArray";

import { IBreadcrumbItemProps, IBreadcrumbProps } from "./Breadcrumb.types";
import { StyledContainer, StyledLink } from "./Breadcrumb.styles";

export const BreadcrumbItem: React.FC<IBreadcrumbItemProps> = (props) => {
  let ref = useRef(null);
  let { itemProps } = useBreadcrumbItem({ ...props, elementType: "span" }, ref);

  return (
    <li>
      <StyledLink to={props.href}>
        <span {...itemProps} ref={ref}>
          {props.children}
        </span>
        {!props.isCurrent && (
          <Icon isSrcRelative src="chevron_right.svg" customSize="0.75rem" />
        )}
      </StyledLink>
    </li>
  );
};

const Breadcrumb: React.FC<IBreadcrumbProps> = ({
  children: childrenFromProps = [],
  ariaLabel,
  className,
}) => {
  const { navProps } = useBreadcrumbs({ "aria-label": ariaLabel });

  const children = useMemo(
    () =>
      isArray(childrenFromProps) ? childrenFromProps : [childrenFromProps],
    [childrenFromProps]
  );

  const childCount = React.Children.count(children);

  return (
    <nav {...navProps} className={className}>
      <StyledContainer>
        {React.Children.map(children, (child, i) =>
          React.cloneElement(child, { isCurrent: i === childCount - 1 })
        )}
      </StyledContainer>
    </nav>
  );
};

export default Breadcrumb;
