import React from "react";

import { Body } from "@/components/Typography";

import { StyledTag, StyledTagContainer } from "../ProfilePage.styles";
import { useProfilePage } from "../ProfilePage.context";

const SkillDisplay: React.FC<{ className?: string }> = ({ className }) => {
  const { profileData } = useProfilePage();

  if (!profileData.skills.length) {
    return;
  }

  return (
    <>
      <Body
        size="md"
        style={{ color: "var(--clr-icon-text-200, #818898)" }}
        className={className}
      >
        Skill & Services
      </Body>
      <StyledTagContainer className="mt-2">
        {profileData.skills.map((item, i) => (
          <StyledTag
            key={i}
            variant="outlined"
            colorVariant="gray"
            className="mt-1"
          >
            {item}
          </StyledTag>
        ))}
      </StyledTagContainer>
    </>
  );
};

export default SkillDisplay;
