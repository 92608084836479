"use client";

import React from "react";

import Modal, { useModalState } from "@/components/misc/Modal";
import Icon from "@/components/misc/Icon";
import Wysiwyg from "@/components/input/WysiwygNew";

import {
  StyledContainer,
  StyledInnerContainer,
  StyledLabel,
  StyledMiddleSection,
  StyledPageWithHeaderLayout,
  StyledPlaceholder,
  StyledPlaceholderWysiwyg,
  StyledValueContainer,
} from "./EditorField.styles";
import { IEditorField } from "./EditorField.types";

const EditorField: React.FC<IEditorField> = (props) => {
  const modalState = useModalState();

  const {
    className,
    prependContent = <Icon isSrcRelative src="content.svg" size="xs" />,
    appendContent,
    label = "Add description",
    value,
    placeholder,
    drawerTitle,
    readOnly: readOnlyFromProps,
    disabled,
    onClick,
    hasError = false,
    customPlaceholder,
  } = props;

  const hasValue = !!value?.markdown;

  return (
    <>
      <StyledContainer
        $hasError={hasError}
        className={className}
        onClick={onClick ? onClick : modalState.open}
      >
        <StyledInnerContainer>
          {prependContent}
          <StyledMiddleSection className="middle">
            {(!hasValue && customPlaceholder) || (
              <StyledValueContainer>
                {!!label && <StyledLabel>{label}</StyledLabel>}
                {!hasValue && (
                  <StyledPlaceholder className="mt-1">
                    {placeholder}
                  </StyledPlaceholder>
                )}
              </StyledValueContainer>
            )}
          </StyledMiddleSection>
          {appendContent}
        </StyledInnerContainer>

        {!!hasValue && (
          <StyledPlaceholderWysiwyg
            className="mt-3"
            {...props} 
            readOnly
            $readOnly={readOnlyFromProps}
          />
        )}
      </StyledContainer>

      {!disabled && (
        <Modal
          mobileProps={{ fullscreen: true }}
          desktopProps={{ width: "768px" }}
          className="p-0"
          state={modalState}
        >
          <StyledPageWithHeaderLayout
            headerProps={{
              backButtonProps: { onClick: modalState.close },
              titleProps: {
                children: drawerTitle,
              },
            }}
          >
            {/* TO-CHECK : classname reset to prevent margin in milestone form */}
            <Wysiwyg {...props} className="" />
          </StyledPageWithHeaderLayout>
        </Modal>
      )}
    </>
  );
};

export default EditorField;
