import { useMemo } from "react";
import useSWR from "swr";

import { userService } from "@/config/services";

import { parseProfileData } from "../UserService.utils";

function useUserProfile(id: number) {
  const {
    data: accountData,
    isLoading,
    error,
  } = useSWR(
    id ? `user/by-id/${id}` : null,
    () =>
      userService.getUserById(id).then((res) => {
        return res.data.data;
      }),
    {
      errorRetryInterval: 10000,
      errorRetryCount: 5,
    }
  );

  const profileData = useMemo(
    () => parseProfileData(accountData?.details || ""),
    [accountData]
  );

  return { accountData, profileData, isLoading, hasError: error };
}

export default useUserProfile;
