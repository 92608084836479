import React from "react";

import Portal from "@/components/misc/Portal";

import { StyledLogo, StyledPage } from "./SplashScreen.styles";

const SplashScreen: React.FC = () => {
  return (
    <Portal>
      <StyledPage>
        <StyledLogo src="/assets/images/branding/logo.gif" />
      </StyledPage>
    </Portal>
  );
};

export default SplashScreen;
