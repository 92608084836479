"use client";
import React from "react";

import Card from "@/components/data/Card";
import { Heading } from "@/components/Typography";

import {
  StyledActionsUserContainer,
  StyledDesktopContainer,
  StyledFlexContainer,
  StyledTopContainer,
} from "./ContractManagementPage.styles";
import {
  IContractManagementPageProps,
  Tab,
} from "./ContractManagementPage.types";
import StatusSection from "./components/StatusSection";
import ReviewSubmissions from "./components/ReviewSubmissions";
import { useContractManagement } from "./ContractManagementPage.context";
import OtherUserDetails from "./components/OtherUserDetails";
import FilesSection from "./components/FilesSection";
import Tabs from "./components/Tabs";
import OverviewSection from "./components/OverviewSection";
import ActionsSection from "./components/ActionsSection";
import JobDetails from "./components/JobDetails";
import MilestonesDetails from "./components/MilestonesDetails";
import ActionsButton from "./components/ActionsButton";

const ContractManagementPage: React.FC<IContractManagementPageProps> = () => {
  const { contractDetails, activeTab } = useContractManagement();

  const { title } = contractDetails;

  return (
    <StyledDesktopContainer>
      <Card>
        <StyledTopContainer>
          <Heading size="lg">{title}</Heading>
          <ActionsButton variant="secondary" colorVariant="gray">
            Manage
          </ActionsButton>
        </StyledTopContainer>

        <hr className="mt-3 mb-4" />

        <StatusSection />

        <hr className="mt-4 mb-4" />

        <StyledActionsUserContainer>
          <OtherUserDetails showChat={false} />
          <ActionsSection />
        </StyledActionsUserContainer>
      </Card>

      <Card className="mt-3">
        <Tabs />
        {activeTab === Tab.Overview && <OverviewSection className="mt-4" />}
        {activeTab === Tab.Details && (
          <>
            <>
              <JobDetails className="mt-4" />
              <MilestonesDetails className="mt-3" />
            </>
          </>
        )}
      </Card>

      <StyledFlexContainer className="mt-4">
        <FilesSection />

        <ReviewSubmissions />
      </StyledFlexContainer>
    </StyledDesktopContainer>
  );
};

export default ContractManagementPage;
