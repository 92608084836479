"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Avatar from "@/components/misc/Avatar";
import { SITE_PATHS } from "@/config/routing";
import { FileObject } from "@/features/input/FilesInput";
import MediaFileTile from "@/features/media/MediaFileTile";
import { isMobileClient } from "@/services/Pwa";

import {
  StyledAttachmentsContainer,
  StyledContainer,
  StyledDate,
  StyledDeleteButton,
  StyledHeader,
  StyledIcon,
  StyledInput,
  StyledInputButton,
  StyledInputImage,
  StyledInputImageContainer,
  StyledInputSection,
  StyledMessageBubble,
  StyledMessageGroup,
  StyledMessageSection,
  StyledMessageText,
  StyledMessagesContainer,
  StyledRoomTitle,
  StyledRoomTitleSecondary,
  StyledTopLeftContent,
  StyledTopSection,
  StyledTopSectionContent,
} from "./ChatRoom.styles";
import { IChatRoomProps, ReadStatus } from "./ChatRoom.types";
import { groupMessageByDates } from "./ChatRoom.utils";
import AttachmentButton from "./components/AttachmentButton";

const ChatRoom: React.FC<IChatRoomProps> = ({
  currentUserId,
  messages,
  users,
  className,
  onSend,
  header,
  hideInputs,
  footer,
  showBackButton = true,
}) => {
  const messagesSectionRef = useRef<HTMLDivElement | null>(null);
  const [messageContent, setMessageContent] = useState("");
  const [attachments, setAttachments] = useState<FileObject[]>([]);

  const messageGroups = useMemo(
    () => groupMessageByDates(messages),
    [messages]
  );

  const handleSend = useCallback(() => {
    const trimmedContent = messageContent.trim();

    if (!trimmedContent && !attachments.length) {
      return;
    }

    if (onSend) onSend({ content: trimmedContent, attachments });

    setMessageContent("");
    setAttachments([]);
  }, [onSend, messageContent, attachments]);

  const handleFileChange = useCallback((files: File[]) => {
    const attachments: FileObject[] = files.map((file) => ({
      file,
    }));

    setAttachments((prevValue) => {
      return [...prevValue, ...attachments];
    });
  }, []);

  const removeAttachment = useCallback((index: number) => {
    setAttachments((prevValue) => prevValue.filter((_, i) => i !== index));
  }, []);

  const handleInputEnterKey = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isEnterPressed = e?.keyCode === 13 || e.key === "Enter";
      const isShiftPressed = e.shiftKey;

      if (isEnterPressed && !isShiftPressed) {
        handleSend();
        e.preventDefault();
      }
    },
    [handleSend]
  );

  const usersExceptCurrent = useMemo(
    () => users.filter((user) => user.id !== currentUserId),
    [users, currentUserId]
  );
  const displayUserDetails = usersExceptCurrent[0];

  useEffect(() => {
    if (messagesSectionRef.current) {
      messagesSectionRef.current.scrollTop =
        messagesSectionRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <StyledContainer className={className}>
      <StyledHeader>
        <StyledTopSection>
          <StyledTopLeftContent>
            {showBackButton && (
              <Button variant="ghost" link={SITE_PATHS.CHAT_PAGE}>
                <Icon isSrcRelative size="xxs" src="chevron_left.svg" />
              </Button>
            )}
            <Avatar
              img={displayUserDetails?.image}
              size="lg"
              firstName={displayUserDetails.name}
            />
          </StyledTopLeftContent>

          <StyledTopSectionContent>
            <StyledRoomTitle>{displayUserDetails?.name}</StyledRoomTitle>
            <StyledRoomTitleSecondary>
              {displayUserDetails?.email}
            </StyledRoomTitleSecondary>
          </StyledTopSectionContent>

          {header?.appendContent || null}
        </StyledTopSection>

        {header?.appendBelowContent || null}
      </StyledHeader>

      <StyledMessageSection ref={messagesSectionRef}>
        {messageGroups.map(({ messages }, i) => {
          const isCurrentUser = messages[0].userId === currentUserId;
          return (
            <StyledMessageGroup $isCurrentUser={isCurrentUser} key={i}>
              <StyledMessagesContainer $isCurrentUser={isCurrentUser}>
                {messages.map((message, j) => {
                  const icon =
                    message.readStatus === ReadStatus.Read
                      ? "/assets/images/icons/double_tick.svg"
                      : "/assets/images/icons/tick.svg";
                  const iconAlt =
                    message.readStatus === ReadStatus.Read ? "Read" : "Sent";

                  const attachments = message.attachments || [];

                  return (
                    <StyledMessageBubble $isCurrentUser={isCurrentUser} key={j}>
                      {!!attachments.length && (
                        <StyledAttachmentsContainer>
                          {attachments.map((AttachmentId, i) => (
                            <MediaFileTile
                              key={AttachmentId || i}
                              mediaId={AttachmentId}
                            />
                          ))}
                        </StyledAttachmentsContainer>
                      )}

                      {!!message.content && (
                        <StyledMessageText
                          className={attachments.length ? "mt-2" : ""}
                        >
                          {message.content}
                        </StyledMessageText>
                      )}

                      {isCurrentUser && (
                        <StyledIcon src={icon} alt={iconAlt} size="xs" />
                      )}
                    </StyledMessageBubble>
                  );
                })}
              </StyledMessagesContainer>

              <StyledDate $isCurrentUser={isCurrentUser}>16:08</StyledDate>
            </StyledMessageGroup>
          );
        })}
      </StyledMessageSection>

      {!hideInputs && (
        <StyledInputSection>
          {!!attachments.length && (
            <StyledAttachmentsContainer className="mb-3">
              {attachments.map(({ file }, i) => (
                <StyledInputImageContainer key={i}>
                  <StyledInputImage file={file} style={{ order: -i }} />
                  <StyledDeleteButton
                    variant="ghost"
                    onClick={() => {
                      removeAttachment(i);
                    }}
                  >
                    <Icon isSrcRelative src="cross.svg" size="xxs" />
                  </StyledDeleteButton>
                </StyledInputImageContainer>
              ))}
            </StyledAttachmentsContainer>
          )}

          <StyledInput
            textArea
            variant="background"
            useFloatingLabel={false}
            placeholder="Write your message"
            value={messageContent}
            onChange={setMessageContent}
            prependContent={<AttachmentButton onChange={handleFileChange} />}
            appendContent={
              <>
                <StyledInputButton variant="ghost" onClick={handleSend}>
                  <Icon size="sm" src="/assets/images/icons/send.svg" />
                </StyledInputButton>
              </>
            }
            onKeyUp={isMobileClient() ? undefined : handleInputEnterKey}
          />
        </StyledInputSection>
      )}

      {footer?.appendContent || null}
    </StyledContainer>
  );
};

export default ChatRoom;
