import { useCallback, useState } from "react";

import { userService } from "@/config/services";

function useUpdatePaypipeId() {
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);

  const update = useCallback((id: string) => {
    setIsUpdating(true);

    return userService
      .updatePaypipeId(id)
      .then(() => {
        setHasUpdated(true);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, []);

  return {
    update,
    isUpdating,
    hasUpdated,
  };
}

export default useUpdatePaypipeId;
