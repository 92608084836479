"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  & > *:first-child {
    flex: 1;
  }
`;

export const StyledTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledDescription = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--clr-icon-text-200, #818898);
`;
