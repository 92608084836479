import { useMemo } from "react";

import Table from "@/components/data/Table";
import {
  ContractCompleteDetails,
  getLoggedInUserContractUserType,
  isContractCompleted,
  useContractList,
} from "@/services/ContractsService";
import { generateTimelineItems } from "@/features/contracts/ContractTimeline";

import { TABLE_COLUMNS } from "./ContractTable.config";

const ContractTable: React.FC<{ className?: string }> = ({ className }) => {
  const { data: contractList } = useContractList({
    filters: { sort: "updated_at:asc" },
  });

  const contractListSorted = useMemo(
    () =>
      [...contractList]
        .map((contract) => {
          const userType = getLoggedInUserContractUserType(contract);
          let action = "";

          if (userType) {
            const { items, currentIndex } = generateTimelineItems({
              contract,
              userType,
            });
            action = items[currentIndex]?.ctaAction || "";
          }
 
          return {
            ...contract,
            action,
          };
        })
        .filter(({ action }: any) => action === "submit-work" || !action)
        .sort((a, b) => +isContractCompleted(a) - +isContractCompleted(b))
        .sort((a, b) => +(!!b.action) - +(!!a.action)),
    [contractList]
  );

  if (!contractListSorted.length) {
    return null;
  }

  return (
    <Table<ContractCompleteDetails>
      className={className}
      data={contractListSorted}
      columns={TABLE_COLUMNS}
    />
  );
};

export default ContractTable;
