import React from "react";

import TextField from "@/components/input/TextField";
import LanguageInput from "@/features/input/LanguageInput";
import CountryInput from "@/features/user/PayoutOnboarding/components/CountryInput";

const TagInput: React.FC = () => {
  return (
    <div className="container">
      <TextField variant="background" placeholder="Name" />
      <LanguageInput />
      <CountryInput />
    </div>
  );
};

export default TagInput;
