import { IFeatureCardProps } from "../FeatureCard";

export const FEATURES_LIST: IFeatureCardProps[] = [
  {
    title: "Accept offer and fund job",
    content: "Funds are held securely by Paypipe and powered by Stripe",
    image: "/assets/images/pages/contracts/landing/secure_payment.svg",
  },
  {
    title: "Stay updated",
    content: "All job updates and chats will be in one place ",
    image: "/assets/images/pages/contracts/landing/communication.svg",
  },
  {
    title: "Review work",
    content: "You'll get a notification when it's time to review the job",
    image: "/assets/images/pages/contracts/landing/review.svg",
  },
  {
    title: "Approve and release funds",
    content: "Once you approve the work, funds are automatically released",
    image: "/assets/images/pages/contracts/landing/approved_released.svg",
  },
];
