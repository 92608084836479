"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import { IStyledCellButtonProps, IStyledCellProps } from "./CalendarCell.types";

export const StyledCell = styled.td<IStyledCellProps>`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  text-align: center;

  ${({ $highlightType }) => {
    if (!$highlightType) {
      return "";
    }

    const width =
      $highlightType === "full-width" ? "100%" : "calc(50% + var(--cell-size))";

    let borderRadius = "";
    if ($highlightType === "start") {
      borderRadius = `
              border-top-left-radius: 10rem;
              border-bottom-left-radius: 10rem;
            `;
    } else if ($highlightType === "end") {
      borderRadius = `
              border-top-right-radius: 10rem;
              border-bottom-right-radius: 10rem;
            `;
    }

    let horizontalPosition = "";
    if ($highlightType === "start") {
      horizontalPosition = `
                left: calc(50% - var(--cell-size) / 2);
            `;
    } else if ($highlightType === "end") {
      horizontalPosition = `
                right: calc(50% - var(--cell-size) / 2);
            `;
    }

    return `
              &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 50%;
                ${horizontalPosition};
                transform: translateY(-50%);
                height: 100%;
                width: ${width};
                background: var(--clr-primary-200);
                ${borderRadius};
              }
            `;
  }};
`;

export const StyledCellButton = styled.div<IStyledCellButtonProps>`
  ${squareSizing("var(--cell-size)")};
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--clr-text-900, #0d0d12);

  ${({ $active, $muted }) =>
    $active
      ? `
          background: var(--clr-primary-100, #5F57FF);
          color: var(--clr-text-white, #fff);
        `
      : $muted
      ? `
          color: var(--clr-ukwn-2, #C1C7D0);
          opacity: 0.5;
        `
      : ``};

  ${({ $showHover }) =>
    $showHover
      ? ` 
          &:hover {
            background: var(--clr-primary-25);
          }
        `
      : `
          
        `};
`;
