import { useCallback, useMemo, useState } from "react";

import ComboBox from "@/components/input/ComboBox";
import {
  AddressParts,
  PredictedPlace,
  usePlaceSuggessions,
} from "@/services/Location";
import { location } from "@/config/services";
import { getAddressDisplayValue } from "./LocationSuggessionInput.utils";

const LocationSuggessionInput: React.FC<{
  placeId?: string;
  value?: string;
  onChange?: (value: { addressParts: AddressParts }) => void;
  className?: string;
}> = ({ placeId, value = "", onChange, className }) => {
  const [query, setQuery] = useState(value);
  const { data } = usePlaceSuggessions(query);

  const { options, placeIdToDetailsMap } = useMemo(() => {
    return {
      options: data
        .filter((item) => !!item.place_id)
        .map((item) => ({
          label: item.description,
          value: item.place_id || "",
        })),

      placeIdToDetailsMap: data
        .filter((item) => !!item.place_id)
        .reduce(
          (map, item) => ({ ...map, [item.place_id as string]: item }),
          {}
        ) as Record<string, PredictedPlace>,
    };
  }, [data]);

  const handleChange = useCallback(
    (placeId: string) => {
      if (!placeIdToDetailsMap[placeId]) {
        return;
      }

      location.getPlaceDetailsByID(placeId).then((res) => {
        if (res) {
          if (onChange) {
            const displayValue = getAddressDisplayValue(res); 
            setQuery(displayValue);
            onChange({ addressParts: res.addressParts });
          }
        }
      });
    },
    [placeIdToDetailsMap, onChange]
  );

  return (
    <ComboBox
      allowsCustomValue
      scrollToTopOnFocus
      inputValue={query}
      label="Location"
      placeholder="Location"
      value={placeId || query}
      showOpenButton={false}
      className={className}
      filterBySearch={false}
      options={options}
      onInputChange={setQuery}
      onChange={handleChange}
    />
  );
};

export default LocationSuggessionInput;
