"use client";

import React from "react";

import Tabs from "@/components/navigation/Tabs";

import { useContractsListPage } from "../ContractsListPage.context";
import { ContractTab } from "../ContractsListPage.types";
import { CONTRACT_TAB_OPTIONS } from "../ContractsListPage.config";

const ContractTabs: React.FC = () => {
  const { activeTab, setActiveTab } = useContractsListPage();

  return (
    <Tabs
      activeTabId={activeTab}
      onChange={({ id }) => setActiveTab(id as ContractTab)}
      tabs={CONTRACT_TAB_OPTIONS}
    />
  );
};

export default ContractTabs;
