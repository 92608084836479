import { ContractCreationFlow } from "@/services/ContractsService";

export const CONTRACT_OPTIONS = [
  {
    title: "One-time",
    value: ContractCreationFlow.OneTime,
  },
  {
    title: "Milestones",
    value: ContractCreationFlow.Milestone,
  },
  {
    title: "Hourly based",
    value: ContractCreationFlow.Hourly,
    disabled: true,
  },
];
