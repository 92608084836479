export const RATINGS: {
  value: string;
  icon: string;
}[] = [
  {
    value: "1",
    icon: "/assets/images/pages/rating/rating_1.png",
  },
  {
    value: "2",
    icon: "/assets/images/pages/rating/rating_2.png",
  },
  {
    value: "3",
    icon: "/assets/images/pages/rating/rating_3.png",
  },
  {
    value: "4",
    icon: "/assets/images/pages/rating/rating_4.png",
  },
  {
    value: "5",
    icon: "/assets/images/pages/rating/rating_5.png",
  },
];
