"use client";

import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Label } from "@/components/Typography";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { DEVICE_BREAKPOINT, screenLargerThan, useResponsive } from "@/styles";
import PackageForm, { FormValues } from "@/features/contracts/PackageForm";
import { mediaService } from "@/config/services";

import { useProfileForm } from "../../ProfileForm.config";
import PackageCard from "../PackageCard";
import DeleteConfirmation from "../DeleteConfirmation";

const StyledCardContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`;

const StyledButton = styled(Button)`
  ${screenLargerThan.widescreen} {
    min-height: 16rem;
    background: var(--clr-background-25, #f6f8fa);
  }
`;

const PackagesInput: React.FC<{ className?: string }> = ({ className }) => {
  const { screenWidth } = useResponsive();
  const { values, setFieldValue, submitForm } = useProfileForm();
  const { packages = [] } = values;

  const [editingIndex, setEditingIndex] = useState(-1);

  const [uploadingAssets, setUploadingAssets] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const formModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setEditingIndex(-1);
      }
    },
  });

  const [toDeleteItemIndex, setToDeleteItemIndex] = useState<null | number>(
    null
  );
  const deleteModalState = useModalState({
    onOpenChange: (open) => {
      if (!open) {
        setToDeleteItemIndex(null);
      }
    },
  });

  //---------------------------------------

  const addNewItem = useCallback(() => {
    const updatedPackages = [...packages, {}];
    setFieldValue("packages", updatedPackages);
    setEditingIndex(updatedPackages.length - 1);
    formModalState.open();
  }, [packages, setFieldValue, formModalState]);

  const handleEdit = useCallback(
    (index: number) => {
      setEditingIndex(index);
      formModalState.open();
    },
    [formModalState]
  );

  const handleDelete = useCallback(() => {
    if (toDeleteItemIndex === null) {
      return;
    }

    const updatedPackages = [...packages];
    updatedPackages.splice(toDeleteItemIndex, 1);
    setFieldValue("packages", updatedPackages);
    deleteModalState.close();
    submitForm();
  }, [
    packages,
    setFieldValue,
    toDeleteItemIndex,
    deleteModalState,
    submitForm,
  ]);

  const handleDeleteClick = useCallback(
    (index: number) => {
      setToDeleteItemIndex(index);
      deleteModalState.open();
    },
    [deleteModalState]
  );

  const handleUpdate = useCallback(
    async ({ values }: { values: FormValues }) => {
      if (
        !values.name ||
        !values.description ||
        !values.estimated_duration ||
        !values.estimated_duration_unit ||
        !values.price
      ) {
        return;
      }

      setIsSaving(true);

      (async function () {
        try {
          const { assets } = values;
          const payload = { ...values };
          const hasNewAssetsToUpload = assets.some((file) => !!file);

          if (hasNewAssetsToUpload) {
            setUploadingAssets(true);
          }

          const updatedAttatchments = await Promise.all(
            assets
              .filter(({ file, mediaFile }) => !!file || !!mediaFile)
              .map(({ file, mediaFile }) =>
                mediaFile
                  ? Promise.resolve(mediaFile)
                  : mediaService
                      .uploadFile(file as File)
                      .then((res) => res.data.data)
              )
          );
          setUploadingAssets(false);
          payload.attachments = updatedAttatchments;
          setFieldValue(`packages[${editingIndex}]`, payload);
          submitForm();
        } catch {}
      })().then(() => {
        setIsSaving(false);
        formModalState.close();
      });
    },
    [setFieldValue, submitForm, editingIndex, formModalState]
  );

  //---------------------------------------

  const cardsJsx = useMemo(
    () =>
      packages
        .filter(
          ({
            name,
            description,
            estimated_duration,
            estimated_duration_unit,
            price,
          }) =>
            !!name &&
            !!description &&
            !!estimated_duration &&
            !!estimated_duration_unit &&
            !!price
        )
        .map((_, i) => (
          <PackageCard
            key={i}
            index={i}
            onEdit={handleEdit}
            onDelete={handleDeleteClick}
            className={screenWidth < DEVICE_BREAKPOINT.widescreen ? "mt-4" : ""}
          />
        )),
    [packages, handleEdit, handleDeleteClick, screenWidth]
  );

  const desktopAddButton = (
    <StyledButton variant="secondary" colorVariant="gray" onClick={addNewItem}>
      {!!packages.length ? "+ Add more" : "+ Add"}
    </StyledButton>
  );

  return (
    <>
      <div className={className}>
        <Label size="lg" as="label" htmlFor="timezone">
          Packages
        </Label>

        <StyledCardContainer className="mt-3">
          {screenWidth < DEVICE_BREAKPOINT.widescreen ? (
            <>
              {cardsJsx}
              <StyledButton
                variant="secondary"
                colorVariant="gray"
                className="w-100 mt-3"
                onClick={addNewItem}
              >
                Add new
              </StyledButton>
            </>
          ) : (
            <StyledCardContainer>
              {desktopAddButton}

              {cardsJsx}

              {cardsJsx.length > 4 && desktopAddButton}
            </StyledCardContainer>
          )}
        </StyledCardContainer>
      </div>

      <Modal
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        state={formModalState}
        mobileProps={{
          fullscreen: true,
          className: "p-0",
        }}
        desktopProps={{
          width: "768px",
        }}
      >
        <PageWithHeaderLayout
          stickyHeader
          headerProps={{
            titleProps: { children: "Packages" },
            backButtonProps: { onClick: formModalState.close },
          }}
        >
          <div className="px-3">
            {formModalState.isOpen && (
              <PackageForm
                initialData={packages[editingIndex]}
                onSubmit={handleUpdate}
                ctaProps={{
                  className: "cta-button w-100 mt-4",
                  children: uploadingAssets
                    ? "Uploading assets"
                    : isSaving
                    ? "Saving"
                    : "",
                }}
              />
            )}
          </div>
        </PageWithHeaderLayout>
      </Modal>

      <Modal state={deleteModalState} desktopProps={{ width: "420px" }}>
        <DeleteConfirmation
          title="Delete package"
          description="Are you sure want to delete this package?"
          onCancel={deleteModalState.close}
          onDelete={handleDelete}
        />
      </Modal>
    </>
  );
};

export default PackagesInput;
