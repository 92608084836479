"use client";

import styled from "@emotion/styled";

import {
  screenLargerThan,
  screenSmallerThan,
  BREAKPOINT_NAMES,
} from "@/styles";
import { Display, Label } from "@/components/Typography";
import AuthForm from "@/features/auth/AuthForm";

const SWITCH_BREAKPOINT: BREAKPOINT_NAMES = "smallMonitor";

export const StyledContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;

  ${screenLargerThan.tablet} {
    padding-block: 4rem 1.5rem;
  }
`;

export const StyledContentContainer = styled.div`
  text-align: center;
`;

export const StyledHeading = styled(Display)`
  color: var(--clr-text-900, #0d0d12);

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 2.25rem !important;
    line-height: 2.75rem !important;
  }
`;

export const StyledContent = styled(Label)`
  color: var(--clr-ukwn-3, #a4acb9);

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 1.125rem !important;
    line-height: 1.625rem !important;
    margin-top: 1rem;
  }

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    margin-top: 1rem;
  }
`;

export const StyledAuthForm = styled(AuthForm)`
  margin-top: 1rem;

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    margin-top: 1.5rem;
  }
`;
