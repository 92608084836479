import React from "react";

import {
  StyledFeatureItem,
  StyledFeatureTitle,
  StyledFeatureContent,
  StyledFeatureImage,
} from "./FeatureCard.styles";
import { IFeatureCardProps } from "./FeatureCard.types";

const FeatureCard: React.FC<IFeatureCardProps> = ({
  image,
  title,
  content,
  className,
}) => {
  return (
    <StyledFeatureItem className={className}>
      <StyledFeatureImage src={image} alt="" height={58} />
      <StyledFeatureTitle className="mt-3">{title}</StyledFeatureTitle>
      <StyledFeatureContent className="mt-1">{content}</StyledFeatureContent>
    </StyledFeatureItem>
  );
};

export default FeatureCard;
