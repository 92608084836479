import React, { createContext, useContext, useState } from "react";

import { IChatsPageContext } from "./ChatsPage.types";

const ChatsPageContext = createContext({} as IChatsPageContext);

export const useChatsPage = () => useContext(ChatsPageContext);

export const ChatsPageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [search, setSearch] = useState("");

  return (
    <ChatsPageContext.Provider value={{ search, setSearch }}>
      {children}
    </ChatsPageContext.Provider>
  );
};
