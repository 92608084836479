import React from "react";

import { appFeatures } from "@/config/services";

import { IChatCardProps } from "./ChatCard.types";
import AblyChatCard from "./ChatCard.ably";
import StreamChatCard from "./ChatCard.stream";

const ChatCard: React.FC<IChatCardProps> = (props) => {
  if (appFeatures.isSupported("CHAT.USE_STREAMIO")) {
    return <StreamChatCard {...props} />;
  }

  return <AblyChatCard {...props} />;
};

export default ChatCard;
