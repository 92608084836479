import React from "react";

import {
  ContractCompleteDetails,
  ContractCreatorType,
} from "@/services/ContractsService";
import ContractUserInfo from "@/features/contracts/ContractUserInfo";

const OtherUserDetails: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  return (
    <ContractUserInfo
      showChat={false}
      className={className}
      contract={contractDetails}
      fallbackUserType={ContractCreatorType.Payee}
    />
  );
};

export default OtherUserDetails;
