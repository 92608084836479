"use client";

import React from "react";

import { useContractUrlId } from "@/services/ContractsService";
import ContractPage from "@/features/pages/contract/ContractPage";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";

import { StyledPage } from "./TransactionsPage.styles";

const TransactionsPage: React.FC = () => {
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();

  if (contractIdFromUrl) {
    return <ContractPage />;
  }

  return (
    <PageWithHeaderLayout
      headerProps={{
        backButtonProps: { link: SITE_PATHS.SETTINGS_PAGE },
        titleProps: { children: "Transaction history" },
        appendContent: <Button variant="ghost">Export</Button>,
      }}
    >
      <StyledPage></StyledPage>
    </PageWithHeaderLayout>
  );
};

export default TransactionsPage;
