import React, { useMemo } from "react";

import Avatar from "@/components/misc/Avatar";
import {
  ContractCompleteDetails,
  getContractPayee,
} from "@/services/ContractsService";
import { useResponsive } from "@/styles";

import { StyledContent, StyledTitle } from "./ContractDetails.styles";

const ContractDetails: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const { title } = contractDetails;

  const payee = useMemo(
    () => getContractPayee(contractDetails),
    [contractDetails]
  );

  const { payeeDisplayName, payeeAvatar } = useMemo(() => {
    const payeeName = [payee?.user?.given_name, payee?.user?.family_name]
      .filter(Boolean)
      .join(" ");

    const payeeEmail = payee?.user?.email || "";

    const payeeAvatar = payee?.user?.picture || "";

    return {
      payeeDisplayName: payeeName || payeeEmail || "",
      payeeAvatar,
    };
  }, [payee]);

  return (
    <div className={className}>
      <StyledTitle>{title}</StyledTitle>

      {!!payeeDisplayName && (
        <StyledContent>
          by
          <div>
            <Avatar
              customSize={isScreenSmallerThanTablet ? "1rem" : "2rem"}
              img={payeeAvatar}
              firstName={payeeDisplayName}
            />
            <b>{payeeDisplayName}</b>
          </div>
        </StyledContent>
      )}
    </div>
  );
};

export default ContractDetails;
