import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Wysiwyg from "@/components/input/LexicalWysiwyg";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 12.5rem;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.02);
`;

export const StyledSlider = styled(StyledImage)`
  border-radius: 1.5rem;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  & > * {
    flex: 1;
    max-width: 8rem;
  }
`;

export const StyledWysiwyg = styled(Wysiwyg)`
  height: 4rem;
  background: transparent;

  .editor {
    background: transparent;
    overflow: hidden;
  }

  .editor > div {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
