"use client";

import styled from "@emotion/styled";

import Avatar from "@/components/misc/Avatar";
import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import { overflowEllipsis } from "@/styles";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 3.75rem;
  border-radius: 1rem;
`;

export const StyledMiddleSection = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const StyledUserType = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledUserName = styled.div`
  font-size: 1.125rem;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: var(--clr-text-900, #0d0d12);
  ${overflowEllipsis()}
`;

export const StyledButton = styled(Button)`
  ${squareSizing("3rem")};
  border-radius: 10rem;
`;
