"use client";

import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledContainer,
  StyledDate,
  StyledHeader,
  StyledInputSection,
  StyledMessageBubble,
  StyledMessageGroup,
  StyledMessageSection,
  StyledMessagesContainer,
  StyledTopLeftContent,
  StyledTopSection,
  StyledTopSectionContent,
} from "./ChatRoom.styles";
import { IChatRoomProps } from "./ChatRoom.types";

const ChatRoom: React.FC<
  Omit<IChatRoomProps, "users" | "currentUserId" | "messages" | "onSend">
> = ({ className, header }) => {
  return (
    <StyledContainer className={className}>
      <StyledHeader>
        <StyledTopSection>
          <StyledTopLeftContent>
            <ShimmerLoader.Circular size="3rem" />
          </StyledTopLeftContent>

          <StyledTopSectionContent>
            <ShimmerLoader.Default style={{ width: "5rem", height: "1rem" }} />
            <ShimmerLoader.Default
              className="mt-2"
              style={{ width: "8rem", height: "1rem" }}
            />
          </StyledTopSectionContent>

          {header?.appendContent || null}
        </StyledTopSection>

        {header?.appendBelowContent || null}
      </StyledHeader>

      <StyledMessageSection>
        <StyledMessageGroup $isCurrentUser={false}>
          <StyledMessagesContainer $isCurrentUser={false}>
            <StyledMessageBubble
              $isCurrentUser={false}
              as={ShimmerLoader.Default}
              style={{ height: "3rem" }}
            />
          </StyledMessagesContainer>

          <StyledDate $isCurrentUser as={ShimmerLoader.Default} />
        </StyledMessageGroup>

        <StyledMessageGroup $isCurrentUser>
          <StyledMessagesContainer $isCurrentUser>
            <StyledMessageBubble
              $isCurrentUser
              as={ShimmerLoader.Default}
              style={{ height: "3rem" }}
            />
            <StyledMessageBubble
              $isCurrentUser
              as={ShimmerLoader.Default}
              style={{ height: "3rem", width: "10rem" }}
            />
          </StyledMessagesContainer>

          <StyledDate $isCurrentUser as={ShimmerLoader.Default} />
        </StyledMessageGroup>

        <StyledMessageGroup $isCurrentUser={false}>
          <StyledMessagesContainer $isCurrentUser={false}>
            <StyledMessageBubble
              $isCurrentUser={false}
              as={ShimmerLoader.Default}
              style={{ height: "3rem", width: "10rem" }}
            />
          </StyledMessagesContainer>

          <StyledDate $isCurrentUser as={ShimmerLoader.Default} />
        </StyledMessageGroup>
      </StyledMessageSection>

      <StyledInputSection>
        <ShimmerLoader.Default
          className="mt-2"
          style={{ width: "100%", height: "3.75rem", borderRadius: "1.15rem" }}
        />
      </StyledInputSection>
    </StyledContainer>
  );
};

export default ChatRoom;
