import React from "react";
import styled from "@emotion/styled";
import * as yup from "yup";

import { Package } from "@/services/UserService";
import TextField from "@/components/input/TextField";
import FilesInput, { FileObject } from "@/features/input/FilesInput";
import Form from "@/components/input/Form";
import PriceCurrenyField from "@/features/input/PriceCurrenyField";
import { ContractCurrency } from "@/services/ContractsService";
import Button, { IButtonProps } from "@/components/input/Button";
import { screenLargerThan } from "@/styles";

import DurationInput from "../../../PaypipeIdPage/components/input/DurationInput";

//------------------------

const MAX_ALLOWED_LENGTH = 50;

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().min(1).required().max(MAX_ALLOWED_LENGTH),
  price: yup.number().min(1).required(),
  estimated_duration: yup.number().min(1).required(),
  estimated_duration_unit: yup.string().min(1).required(),
  description: yup.string().min(1).required(),
  assets: yup.array().min(1).required(),
});

//------------------------

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .content {
    flex-grow: 1;
  }

  .field-row {
    & > * {
      margin-top: 1rem;
    }
  }

  ${screenLargerThan.tablet} {
    .field-row {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;

      & > * {
        margin-top: 1.75rem;
      }
    }
  }
`;

const StyledCtaSection = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  background: #fff;
  padding-top: 1rem;

  button {
    min-width: 6rem;
  }

  .cancel-button {
    --bg-color: #f5f5f5 !important;
    --border-color: #f5f5f5 !important;
  }
`;

//------------------------

export type EditingPackage = Package & { assets?: FileObject[] };

const EditPackage: React.FC<{
  package: Package;
  onSave: (values: EditingPackage) => void;
  onCancel: () => void;
  cancelButtonProps?: Omit<IButtonProps, "onClick">;
  saveButtonProps?: Omit<IButtonProps, "onClick">;
  className?: string;
}> = ({
  package: packageDetails,
  onSave,
  onCancel,
  cancelButtonProps,
  saveButtonProps,
  className,
}) => {
  return (
    <Form<EditingPackage>
      initialValues={{
        ...packageDetails,
        assets: (packageDetails.attachments || []).map(
          (file) => ({ mediaFile: file } as FileObject)
        ),
      }}
      yupValidationSchema={VALIDATION_SCHEMA}
      onSubmit={onSave}
    >
      {({
        context: {
          values: {
            name,
            price,
            estimated_duration,
            estimated_duration_unit,
            description,
            assets,
          },
          setFieldValue,
          touchedAndHasError,
          setFieldTouched,
          hasError,
          submitForm,
        },
      }) => (
        <StyledForm className={className} onSubmit={submitForm}>
          <div className="content">
            <FilesInput
              className="mt-4"
              variant="cards"
              displayFileCount={2}
              label="Upload Package Images"
              value={assets}
              onChange={(value) => {
                setFieldValue("assets", value);
              }}
              hasError={touchedAndHasError("assets")}
            />

            <TextField
              scrollToTopOnFocus
              className="mt-4"
              variant="background"
              label="Package Title"
              placeholder="Enter Title Here"
              value={name}
              onChange={(value) => {
                setFieldValue("name", value.substring(0, MAX_ALLOWED_LENGTH));
              }}
              hasError={touchedAndHasError("name")}
              onBlur={() => {
                setFieldTouched("name");
              }}
              appendContent={
                <span>
                  {name.length}/{MAX_ALLOWED_LENGTH}
                </span>
              }
            />

            <div className="field-row">
              <PriceCurrenyField
                // TO-CHECK: Remove hardcode USD currency
                priceFieldProps={{
                  placeholder: "Package Price",
                  variant: "background",
                  hasError: touchedAndHasError("price"),
                  onBlur: () => {
                    setFieldTouched("price");
                  },
                }}
                currency={ContractCurrency.USD}
                onCurrencyChange={() => {}}
                price={price || 0}
                onPriceChange={(value) => {
                  setFieldValue("price", value);
                }}
              />

              <DurationInput
                placeholder="Delivery Time"
                duration={estimated_duration}
                unit={estimated_duration_unit}
                onChange={({ duration, unit }) => {
                  setFieldValue("estimated_duration", duration);
                  setFieldValue("estimated_duration_unit", unit);
                }}
                durationHasError={
                  touchedAndHasError("estimated_duration") ||
                  touchedAndHasError("estimated_duration_unit")
                }
                onBlur={() => {
                  setFieldTouched("estimated_duration");
                  setFieldTouched("estimated_duration_unit");
                }}
              />
            </div>

            <TextField
              textArea
              scrollToTopOnFocus
              className="mt-4"
              variant="background"
              label="Package Description"
              placeholder="Enter Description Here"
              value={description}
              onChange={(value) => {
                setFieldValue("description", value);
              }}
              hasError={touchedAndHasError("description")}
              onBlur={() => {
                setFieldTouched("description");
              }}
            />
          </div>

          <StyledCtaSection className="mt-2">
            <Button
              type="reset"
              colorVariant="gray"
              className="cancel-button"
              onClick={onCancel}
              {...cancelButtonProps}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              {...saveButtonProps}
              colorVariant={hasError ? "disabled" : "primary"}
            >
              Save
            </Button>
          </StyledCtaSection>
        </StyledForm>
      )}
    </Form>
  );
};

export default EditPackage;
