import * as yup from "yup";

import { EMAIL_REGEX } from "@/utils/string";

export const VALIDATION_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Email address is invalid")
    .required("Please provide an email address"),
  name: yup
    .string()
    .min(1, "Client name is required")
    .required("Client name is required"),
});
