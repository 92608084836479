"use client";

import styled from "@emotion/styled";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import Button from "@/components/input/Button";
import Avatar from "@/components/misc/Avatar";
import MediaFileTile from "@/features/media/MediaFileTile";
import ShimmerLoader from "@/components/misc/ShimmerLoader";

export const StyledContainer = styled.div`
  height: ${getViewPortHeightCssString()};
  overflow: hidden;
`;

export const StyledMenu = styled.div`
  padding-block: 0.5rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  padding: 0.4rem 1.25rem;
  gap: 0.5rem;
  text-align: left;

  span {
    flex: 1;
  }
`;

export const StyledDangerButton = styled(StyledButton)`
  filter: brightness(0) invert(54%) sepia(50%) saturate(3359%)
    hue-rotate(325deg) brightness(94%) contrast(95%);
`;

export const StyledInfoModalContent = styled.div`
  padding: 1rem;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 120px;
`;

export const StyledName = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--clr-text-900, #0d0d12);
  margin-top: 1rem;
`;

export const StyledEmail = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--clr-icon-text-200, #818898);
  margin-top: 0.25rem;
`;

export const StyledUserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
`;

export const StyledSectionHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPrimaryHeading = styled.div`
  display: flex;
  align-items: center;
  color: var(--clr-text-900, #0d0d12);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const StyledButtonSmall = styled(Button)`
  color: var(--clr-text-500, #666d80);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  filter: var(--filter-primary-500);
  gap: 0.5rem;
  padding: 0.5rem;
`;

export const StyledFilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  ${screenSmallerThan.tablet} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledMediaFileTile = styled(MediaFileTile)`
  height: 104px;
  width: 100%;

  ${screenLargerThan.tablet} {
    display: grid;
    width: 164px;
    height: 152px;
  }

  img {
    height: inherit;
    width: inherit;
    object-fit: contain;
  }
`;

export const StyledMediaFileLoadingTile = styled(ShimmerLoader.Default)`
  height: 104px;
  width: 100%;
  border-radius: 1rem;

  ${screenLargerThan.tablet} {
    display: grid;
    width: 164px;
    height: 152px;
  }
`;

export const StyledDesktopManagementContainer = styled.div`
  padding-inline: 1rem;
  height: 100%;

  & > div {
    height: 100%;
  }

  & > div > main {
    overflow: auto;
    max-width: 52rem;
    margin: 0 auto;
  }
`;
