"use client";

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import lodashGet from "lodash/get";

import { UserProfile, useUpdateProfile } from "@/services/UserService";
import Form from "@/components/input/Form";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useToast } from "@/components/misc/Toast";
import { useNavigate } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";
import { useResponsive } from "@/styles";

import { VALIDATION_SCHEMA } from "./ProfileForm.config";
import { FormData, FormExtraValues, Tab } from "./ProfileForm.types";
import ProfileFormMobile from "./ProfileForm.mobile";
import ProfileFormDesktop from "./ProfileForm.desktop";

const ProfileForm: React.FC = () => {
  const { profileData, update, isProfileDataLoading, isUpdating } =
    useUpdateProfile();
  const { createToast } = useToast();
  const { isScreenSmallerThanTablet } = useResponsive();

  const params = useParams();
  const { navigate } = useNavigate();
  const activeTab = lodashGet(params, "tab", "").toLocaleLowerCase() as Tab;

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  //-----------------------------

  const handleUpdate = useCallback(
    (values: FormData) => {
      update(values)
        .then(() => {
          createToast({
            title: "Profile updated!",
            description: "The changes you made have been saved",
            timeoutInMilliseconds: 5000,
          });
          setHasUnsavedChanges(false);
        })
        .catch(() => {
          createToast({
            title: "Profile not updated!",
            description:
              "Something went wrong, the changes you made are not saved",
            timeoutInMilliseconds: 5000,
            variant: "error",
          });
        });
    },
    [update, createToast]
  );

  const openTab = useCallback(
    (tab: Tab) => {
      navigate(`${SITE_PATHS.EDIT_PROFILE_PAGE}/${tab}`, true);
    },
    [navigate]
  );

  //-----------------------------

  useEffect(() => {
    if (!activeTab) {
      openTab("about");
    }
  }, [activeTab, openTab]);

  //-----------------------------

  if (isProfileDataLoading) {
    return <SplashScreen />;
  }

  return (
    <Form<UserProfile, FormExtraValues>
      initialValues={profileData}
      onSubmit={handleUpdate}
      yupValidationSchema={VALIDATION_SCHEMA}
      extraFields={{
        isUpdatingProfileData: isUpdating,
        activeTab,
        openTab,
        hasUnsavedChanges,
        setHasUnsavedChanges,
      }}
    >
      {isScreenSmallerThanTablet ? (
        <ProfileFormMobile />
      ) : (
        <ProfileFormDesktop />
      )}
    </Form>
  );
};

export default ProfileForm;
