import React from "react";

import styled from "@emotion/styled";
import { squareSizing } from "@/utils/css";

const StyledLoader = styled.div<{ $size: string }>`
  border: 0.25rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.25rem solid var(--clr-primary-100, #5f57ff);
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  ${({ $size }) => squareSizing($size)}

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const RingLoader: React.FC<{ size?: string; className?: string }> = ({
  size = "1.5rem",
  className,
}) => {
  return <StyledLoader $size={size} className={className} />;
};

export default RingLoader;
