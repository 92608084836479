"use client";

import React from "react";

import { MediaFile, Portfolio } from "@/services/UserService";
import { Body, Heading } from "@/components/Typography";
import { useFileSignedUrl } from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";

import {
  StyledContainer,
  StyledImage,
  StyledLink,
  StyledWysiwyg,
} from "./PortfolioCard.styles";

const PackageImage: React.FC<{
  file: MediaFile;
  className?: string;
}> = ({ file, className }) => {
  const { url } = useFileSignedUrl(file?.url || "");

  return (
    <StyledImage
      className={className}
      src={url}
      alt=""
      width={24}
      height={24}
    />
  );
};

const PackageCard: React.FC<{
  portfolio: Portfolio;
  className?: string;
  useLink?: boolean;
}> = ({ portfolio, className, useLink = true }) => {
  const { name, description, attachments = [] } = portfolio;
  const firstImage = attachments.length ? attachments[0] : null;

  return (
    <StyledContainer className={className}>
      {useLink && <StyledLink to={`${SITE_PATHS.PROFILE_PAGE}/portfolios/0`} />}

      {!!firstImage && <PackageImage file={firstImage} />}

      <Heading size="md" className="mt-3">
        {name}
      </Heading>

      {!!description && (
        <Body
          size="md"
          className="mt-2"
          style={{ color: "var(--clr-icon-400, #818898)" }}
        >
          <StyledWysiwyg readOnly value={{ markdown: description }} />
        </Body>
      )}
    </StyledContainer>
  );
};

export default PackageCard;
