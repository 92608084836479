"use client";

import styled from "@emotion/styled";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
  BREAKPOINT_NAMES,
} from "@/styles";
import Logo from "@/features/app/Logo";

const SWITCH_BREAKPOINT: BREAKPOINT_NAMES = "smallMonitor";

export const StyledPage = styled.div`
  display: flex;
  width: 100vw;
  height: ${getViewPortHeightCssString()};
  flex-direction: row-reverse;
  overflow: hidden auto;

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    flex-direction: column;
  }
`;

export const StyledImageSection = styled.div`
  flex: 5;
  background-color: #f6f6fa;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center 80%;
  background-size: cover;

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    background-position: bottom 20%;
    min-height: 25vh;
  }

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    position: sticky;
    top: 0;
  }
`;

export const StyledContentSection = styled.div`
  flex: 6;
  height: 100%;

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 100%;
    margin-inline: auto;

    ${screenLargerThan[SWITCH_BREAKPOINT]} {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: 2rem 3rem;
    }
  }
`;

export const StyledLogo = styled(Logo)`
  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    display: none;
  }

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    width: 12.75rem;
    height: unset;
  }
`;
