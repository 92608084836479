"use client";

import styled from "@emotion/styled";

import ContractCard from "@/features/contracts/ContractCard";
import ActionCard from "@/components/misc/ActionCard";

export const StyledContractCard = styled(ContractCard)`
  border-radius: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
  padding-inline: 0;
`;

export const StyledActionCard = styled(ActionCard)`
  border-radius: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
  padding-inline: 0;
`;
