import { Item } from "@/components/navigation/NavMenu";
import { SITE_PATHS } from "@/config/routing";

export const NAV_ITEMS: { heading: React.ReactNode; items: Item[] }[] = [
  {
    heading: "Main",
    items: [
      {
        id: "Home",
        icon: "/assets/images/icons/home.svg",
        link: SITE_PATHS.HOME_PAGE,
        title: "Home",
      },
      {
        id: "Projects",
        icon: "/assets/images/icons/contracts.svg",
        link: SITE_PATHS.CONTRACTS_PAGE,
        title: "Jobs",
        iconProps: { colorVariant: "gray" },
      },
      {
        id: "Chat",
        icon: "/assets/images/icons/chat.svg",
        link: SITE_PATHS.CHAT_PAGE,
        title: "Chat",
        iconProps: { colorVariant: "gray" },
      },
      {
        id: "Notifications",
        icon: "/assets/images/icons/bell.svg",
        link: SITE_PATHS.NOTIFICATIONS_PAGE,
        title: "Notifications",
        iconProps: { colorVariant: "gray" },
      },
    ],
  },

  // {
  //   heading: "Other",
  //   items: [
  //     {
  //       id: "Settings",
  //       title: "Earnings",
  //       icon: "/assets/images/icons/currency.svg",
  //       link: SITE_PATHS.TRANSACTION_PAGE,
  //     },
  //     {
  //       id: "Support",
  //       icon: "/assets/images/icons/support.svg",
  //       link: "#",
  //       title: "Support",
  //       iconProps: { colorVariant: "gray" },
  //     },
  //   ],
  // },
];