import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { SITE_PATHS } from "@/config/routing";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import Redirect from "@/components/navigation/Redirect";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";
import {
  ContractCreatorType,
  getContractManagePageUrl,
  getContractPayer,
  useContractDetails,
  useStripeSessionObject,
  useStripeSessionUrlId,
} from "@/services/ContractsService";
import { useUserExists } from "@/services/UserService";
import { useSearchParams } from "@/services/Routing";
import ContractPaymentSuccessPage from "@/features/pages/contract/ContractPaymentSuccessPage";

import {
  StyledBottomContainer,
  StyledButton,
  StyledChatButton,
  StyledContainer,
  StyledContentBold,
  StyledContentSmall,
  StyledSmallText,
  StyledTopContainer,
} from "./ContractPaymentSuccessOnboardPage.styles";

const ContractPaymentSuccessOnboardPage: React.FC = () => {
  const {
    onBoardUserFromStripeSessionId,
    userData,
    isAuthenticating,
    register,
  } = useAuth();

  const { stripeSessionIdFromUrl: stripeSessionId } = useStripeSessionUrlId();
  const { stripeSessionObject, isLoading: isLoadingStripeSessionObject } =
    useStripeSessionObject({
      sessionId: stripeSessionId,
    });
  const { contractDetails, isLoading: isLoadingContractDetails } =
    useContractDetails({
      redirectIfNotFound: true,
      useContractIdFromUrl: true,
    });

  const { searchParams } = useSearchParams();
  const clientEmail = useMemo(() => {
    if (!contractDetails) {
      return "";
    }

    const payee = getContractPayer(contractDetails);

    return payee?.user?.email || "";
  }, [contractDetails]);
  const isSourceEmail = searchParams.get("source") === "email";
  const isContinueOnboard = !!clientEmail && isSourceEmail;

  const isAuthenticated = !!userData;
  const onboardingEmail =
    stripeSessionObject?.customer_details?.email || clientEmail || "";
  const isEmailSameAsOnboarding = userData?.email === onboardingEmail;

  const { exists: userExistsAlready, isLoading: checkUserExistsAlready } =
    useUserExists({
      email: onboardingEmail,
    });

  //--------

  const handleSendVerificationCode = useCallback(() => {
    if (isContinueOnboard) {
      register({
        email: onboardingEmail,
      });
    } else {
      onBoardUserFromStripeSessionId(stripeSessionId);
    }
  }, [
    onBoardUserFromStripeSessionId,
    stripeSessionId,
    isContinueOnboard,
    register,
    onboardingEmail,
  ]);

  if (
    isLoadingContractDetails ||
    isLoadingStripeSessionObject ||
    isAuthenticating ||
    checkUserExistsAlready
  ) {
    return <SplashScreen />;
  }

  if (
    !isLoadingStripeSessionObject &&
    !stripeSessionObject &&
    !isContinueOnboard
  ) {
    return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
  }

  if (userExistsAlready) {
    if (isContinueOnboard) {
      return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
    }

    return <ContractPaymentSuccessPage />;
  }

  if (isAuthenticated) {
    if (isContinueOnboard) {
      return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
    }

    if (isEmailSameAsOnboarding) {
      return (
        <StyledContainer>
          <StyledTopContainer>
            <ImageTitleContent
              title="Account Verified"
              content={
                <>
                  From now on you can login to Paypipe using your email address.
                  To login with social accounts, you can link those in account
                  settings.
                </>
              }
              image="/assets/images/pages/contracts/payment_success.svg"
            />
          </StyledTopContainer>

          <StyledBottomContainer>
            <StyledButton
              link={getContractManagePageUrl(contractDetails?.id || "")}
            >
              Go to Job
            </StyledButton>

            <StyledChatButton
              variant="ghost"
              contractId={contractDetails?.id || ""}
              userType={ContractCreatorType.Payee}
            />
          </StyledBottomContainer>
        </StyledContainer>
      );
    } else {
      return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
    }
  }

  return (
    <StyledContainer>
      <StyledTopContainer>
        <ImageTitleContent
          title="Successfully funded!"
          content={<>You&apos;ve successfully funded the job.</>}
          image="/assets/images/pages/contracts/payment_success.svg"
        />

        <StyledContentBold>
          Last step. We just need to verify your account and you&apos;re good to
          go!
        </StyledContentBold>

        <StyledContentSmall className="mt-3">
          We&apos;ll send you a verification code to <b>{onboardingEmail}</b>
        </StyledContentSmall>
      </StyledTopContainer>

      <StyledBottomContainer>
        <StyledButton onClick={handleSendVerificationCode}>
          Send verification code
        </StyledButton>

        <StyledSmallText className="mt-4">
          By proceeding, you accept our{" "}
          <Link to={SITE_PATHS.TERMS_CONDITIONS_PAGE}>Terms of Use</Link> and{" "}
          <Link to={SITE_PATHS.POLICY_PAGE}>Privacy Policy</Link>
        </StyledSmallText>
      </StyledBottomContainer>
    </StyledContainer>
  );
};

export default ContractPaymentSuccessOnboardPage;
