import { useLocation } from "react-router-dom";
import { Location } from "../Routing.types";

function usePathname() {
  const { pathname, search, hash, key, state } = useLocation();

  const url = `${pathname}${search}`;

  return {
    pathname,
    search,
    url,
    hash,
    key,
    state,
  } as Location;
}

export default usePathname;
