import React from "react";

import ImageLayout from "@/features/layouts/ImageLayout";

import {
  StyledAuthForm,
  StyledContent,
  StyledContentContainer,
  StyledContentSection,
  StyledHeading,
} from "./OnboardingPage.styles";

const OnboardingPage: React.FC = () => {
  return (
    <ImageLayout>
      <StyledContentSection>
        <div>
          <StyledContentContainer>
            <StyledHeading size="md">
              Get paid on time. Every time.
            </StyledHeading>
            <StyledContent size="md">
              Get paid faster, effortlessly with Paypipe. Simplified proposals,
              seamless communication, and on-time payments so you can focus on
              what you do best.
            </StyledContent>
          </StyledContentContainer>

          <StyledAuthForm />
        </div>
      </StyledContentSection>
    </ImageLayout>
  );
};

export default OnboardingPage;
