import React from "react";

import ShimmerLoader from "@/components/misc/ShimmerLoader";

import {
  StyledFilesContainer,
  StyledMediaFileLoadingTile,
  StyledPrimaryHeading,
  StyledSectionHeadingContainer,
} from "../ChatRoomPage.styles";

const FilesDisplayLoading: React.FC = () => {
  return (
    <>
      <section>
        <StyledSectionHeadingContainer>
          <StyledPrimaryHeading>Photos</StyledPrimaryHeading>
        </StyledSectionHeadingContainer>

        <StyledFilesContainer className="mt-3">
          {[...new Array(6)].map((_, i) => (
            <StyledMediaFileLoadingTile key={i} />
          ))}
        </StyledFilesContainer>
      </section>

      <section className="mt-5">
        <StyledSectionHeadingContainer>
          <StyledPrimaryHeading>Shared files</StyledPrimaryHeading>
        </StyledSectionHeadingContainer>

        {[...new Array(3)].map((_, i) => (
          <ShimmerLoader.Default
            className="mt-3"
            key={i}
            style={{
              width: "100%",
              height: "5.125rem",
              borderRadius: "1rem",
            }}
          />
        ))}
      </section>
    </>
  );
};

export default FilesDisplayLoading;
