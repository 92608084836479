"use client";
import React, { useMemo } from "react";

import { useUserActions } from "@/services/UserService";
import { ActionCardLoading } from "@/components/misc/ActionCard";
import {
  filterOutBrokenContracts,
  useContractList,
} from "@/services/ContractsService";
import ActionCard from "@/components/misc/ActionCard";

import {
  StyledActionCard,
  StyledContractCard,
} from "./RequiredActionsList.styles";

const RequiredActionsList: React.FC = () => {
  const { actions: actionsFromHook, isLoading: isLoadingActions } =
    useUserActions();
  const { data: contractListFromHook, isLoading: isLoadingContractList } =
    useContractList({
      onlyJobs: true,
      onlyActiveJobs: true,
      onlyWithActions: true,
    });

  const urgentActions = useMemo(
    () => actionsFromHook.filter((item) => item.priority === "urgent"),
    [actionsFromHook]
  );

  const contractlist = useMemo(
    () =>
      filterOutBrokenContracts(contractListFromHook)
        .slice(0, 3)
        .splice(0, 3 - urgentActions.length),
    [contractListFromHook, urgentActions]
  );

  const actions = useMemo(
    () =>
      contractlist.length >= 3
        ? []
        : actionsFromHook
            .filter((item) => item.priority !== "urgent")
            .splice(0, 3 - contractlist.length),
    [actionsFromHook, contractlist.length]
  );

  if (isLoadingActions || isLoadingContractList) {
    return (
      <>
        <ActionCardLoading className="mt-3" />
        <ActionCardLoading className="mt-3" />
      </>
    );
  }

  return (
    <div>
      {urgentActions.map((item, i) => (
        <StyledActionCard key={i} {...item} className="mt-3" />
      ))}

      {contractlist.map((contract) => (
        <StyledContractCard
          showCta
          key={contract.id}
          contract={contract}
          className="mt-3"
        />
      ))}

      {actions.map((item, i) => (
        <ActionCard key={i} {...item} className="mt-3" />
      ))}
    </div>
  );
};

export default RequiredActionsList;
