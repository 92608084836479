"use client";

import React, { useCallback } from "react";

import { Body, Label } from "@/components/Typography";
import LanguageInputComponent from "@/features/input/LanguageInput";

import {
  LANGUAGE_FIELD_URL_HASH,
  useProfileForm,
} from "../../ProfileForm.config";

const LanguagesInput: React.FC<{ className?: string }> = ({ className }) => {
  const { values, setFieldValue, submitForm } = useProfileForm();

  const handleSave = useCallback(
    (value: string[]) => {
      setFieldValue("languages", value);
      setTimeout(() => {
        submitForm();
      }, 100);
    },
    [setFieldValue, submitForm]
  );

  return (
    <div className={className}>
      <Label size="lg" as="label" htmlFor="languages">
        Languages
      </Label>

      <Body size="sm" style={{ color: "#868C98" }}>
        For multiple entries, please separate with a comma
      </Body>

      <LanguageInputComponent
        className="mt-3"
        value={values.languages}
        onChange={handleSave}
        urlHash={LANGUAGE_FIELD_URL_HASH}
      />
    </div>
  );
};

export default LanguagesInput;
