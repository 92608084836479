"use client";

import styled from "@emotion/styled";

import Wysiwyg from "@/components/input/LexicalWysiwyg";
import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import { screenLargerThan } from "@/styles";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";

export const StyledContainer = styled.div<{ $hasError: boolean }>`
  --border-color: ${({ $hasError }) =>
    $hasError
      ? "var(--clr-error-500, #ea5b5b)"
      : "var(--clr-border-ukwn-5, #dfe1e7)"};
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  position: relative;
  align-items: baseline;
  gap: 1rem;
`;

export const StyledMiddleSection = styled.div`
  flex: 1;
`;

export const StyledValueContainer = styled.div``;

export const StyledLabel = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--clr-text-500, #666d80);
`;

export const StyledPlaceholder = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--clr-icon-text-200, #818898);
  font-weight: 400;
  padding-bottom: 0.5rem;
  border-bottom: 1.5px solid var(--border-color);
`;

export const StyledPlaceholderWysiwyg = styled(Wysiwyg)<{
  $readOnly?: boolean;
}>`
  position: relative;
  height: calc(5em + 2.5rem + 0.75rem);
  border-radius: 1rem;
  padding: 0.5rem;

  ${({ $readOnly }) =>
    `
          margin-top:  ${$readOnly ? "0.5rem" : "0.75rem"};
          padding-bottom:  ${$readOnly ? "2.5rem" : "2.5rem"};

          .editor {
            overflow: auto;
          }

          &:after {
            ${squareSizing("18px")};
            content: "";
            position: absolute;
            bottom: 0.75rem;
            right: 1rem;
            background: url("/assets/images/icons/${
              $readOnly ? "maximize.svg" : "edit_2.svg"
            }");
            background-size: contain;
            background-repeat: no-repeat;
          }
        `}
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 1rem;
`;

export const StyledPageWithHeaderLayout = styled(PageWithHeaderLayout)`
  ${screenLargerThan.tablet} {
    height: 480px;
    max-height: calc(100vh - 2rem);
    border-radius: 1rem;
    overflow: hidden;
  }
`;
