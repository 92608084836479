"use client";

import styled from "@emotion/styled";

import ActionCard from "@/components/misc/ActionCard";

export const StyledContainer = styled.section`
  padding-inline: 1rem;
  margin-inline: -1rem;
`;

export const StyledActionCard = styled(ActionCard)`
  padding-inline: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);
`;
