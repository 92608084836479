import React, { Fragment, useMemo } from "react";
import lodashGet from "lodash/get";
import { useParams } from "react-router-dom";

import {
  getUserFullName,
  MediaFile,
  useProfileData,
} from "@/services/UserService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { useFileSignedUrl } from "@/services/ContractsService";
import { Body, Heading, Label } from "@/components/Typography";
import Avatar from "@/components/misc/Avatar";
import { useAuth } from "@/services/Authentication";
import Icon from "@/components/misc/Icon";
import PortfolioCard from "@/features/user/PortfolioCard";
import Button from "@/components/input/Button";

import {
  StyledContactButton,
  StyledSlider,
  StyledUserAvatarContainer,
  StyledUserDetails,
  StyledWysiwyg,
} from "./PortfolioDetailsPage.styles";

const Slider: React.FC<{
  file: MediaFile;
  className?: string;
}> = ({ file, className }) => {
  const { url } = useFileSignedUrl(file?.url || "");

  return (
    <StyledSlider
      className={className}
      src={url}
      alt=""
      width={24}
      height={24}
    />
  );
};

const PortfolioDetailsPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { profileData } = useProfileData();
  const { userData } = useAuth();
  const params = useParams();
  const { portfolios } = profileData;

  const portfolioId = +lodashGet(params, "porfolio_id", "");
  const details = portfolios[+portfolioId];
  const { name, description, attachments } = details;

  const firstImage = attachments.length ? attachments[0] : null;

  const otherWorks = useMemo(
    () => portfolios.filter((_, i) => i !== portfolioId),
    [portfolios, portfolioId]
  );

  const contentJsx = (
    <div className="container">
      {!!firstImage && <Slider file={firstImage} />}

      <Heading size="lg" className="mt-3">
        {name}
      </Heading>

      {!!userData && (
        <StyledUserAvatarContainer className="mt-2">
          <Avatar
            customSize="1.875rem"
            img={userData?.picture}
            firstName={userData?.given_name}
            lastName={userData?.family_name}
          />
          <Label size="md">{getUserFullName(userData)}</Label>
        </StyledUserAvatarContainer>
      )}

      <hr className="mt-3 mb-3" />

      <Label size="md">About</Label>
      {description && (
        <Body
          size="md"
          className="mt-2"
          style={{ color: "var(--clr-icon-400, #818898)" }}
        >
          <StyledWysiwyg readOnly value={{ markdown: description }} />
        </Body>
      )}

      <hr className="mt-3 mb-3" />

      <StyledUserDetails className="mt-5">
        <Avatar customSize="3.5rem" img={userData?.picture} />
        <Heading size="md" className="mt-2">
          Work with {userData?.given_name}
        </Heading>
        <StyledContactButton className="mt-3">
          <Icon isSrcRelative src="rocket.svg" size="xxs" /> Contact
        </StyledContactButton>
        <Body
          size="sm"
          className="mt-2"
          style={{ color: "var(--clr-neutral-solid-400, #808897)" }}
        >
          Typically responds within 24 hours
        </Body>
      </StyledUserDetails>

      {!!otherWorks.length && (
        <>
          <Body size="sm" className="mt-4">
            <span style={{ color: "var(--clr-neutral-solid-400, #808897)" }}>
              Other work by
            </span>
            &nbsp;
            <b>{userData?.given_name}</b>
          </Body>

          {otherWorks.map((portfolio, i) => (
            <Fragment key={i}>
              <PortfolioCard portfolio={portfolio} className="mt-3" />
              <hr className="mt-3 mb-4" />
            </Fragment>
          ))}
        </>
      )}
    </div>
  );

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          titleProps: { children: name },
          appendContent: (
            <>
              <Button variant="ghost" className="px-2">
                <Icon isSrcRelative src="share_2.svg" size="sm" />
              </Button>
              <Button variant="ghost" className="px-2">
                <Icon isSrcRelative src="menu_vertical.svg" size="sm" />
              </Button>
            </>
          ),
          endContainerProps: { style: { right: "0" } },
        }}
      >
        {contentJsx}
      </PageWithHeaderLayout>
    );
  }

  return <DesktopPrimaryLayout>{contentJsx}</DesktopPrimaryLayout>;
};

export default PortfolioDetailsPage;
