import { useCallback, useState } from "react";

import { userService } from "@/config/services";

import useSubscriptionDetails from "./useSubscriptionDetails";

function useCancelSubscription() {
  const { refresh } = useSubscriptionDetails();

  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const cancel = useCallback(() => {
    if (isCancelling) {
      return;
    }

    setIsCancelling(true);

    return (async function () {
      try {
        await userService.cancelSubscription();

        setCancelled(true);

        await refresh();
      } finally {
        setIsCancelling(false);
      }
    })();
  }, [isCancelling, refresh]);

  //-----------------------

  return {
    isCancelling,
    cancelled,
    cancel,
  };
}

export default useCancelSubscription;
