"use client";

import React, { useMemo } from "react";
//  @ts-ignore
import FileViewer from "react-file-viewer";

import { getFileExtentionFromName } from "@/utils/files";
import Icon from "@/components/misc/Icon";

import { StyledContainer } from "./FilePreviewer.styles";
import { IFilePreviewerProps } from "./FilePreviewer.types";

const USE_NATIVE_ELEMENTS = true;

const FilePreviewer: React.FC<IFilePreviewerProps> = ({
  url,
  className,
  extention: extentionFromProps,
}) => {
  const extention = useMemo(
    () => extentionFromProps || getFileExtentionFromName(url),
    [extentionFromProps, url]
  );

  return (
    <StyledContainer className={className}>
      {USE_NATIVE_ELEMENTS ? (
        <>
          {extention === "pdf" ? (
            <object className="pdf" data={url} width="800" height="500">
              {url}
            </object>
          ) : (
            <>
              <img className="image" src={url} alt="" />
              <a
                download
                rel="noreferrer"
                target="_blank"
                className="download"
                href={url}
              >
                <Icon
                  isSrcRelative
                  className="icon"
                  src="upload.svg"
                  size="sm"
                />
              </a>
            </>
          )}
        </>
      ) : (
        <FileViewer fileType={extention} filePath={url} />
      )}
    </StyledContainer>
  );
};

export default FilePreviewer;
