"use client";
import React from "react";
import styled from "@emotion/styled";

import { ContractCompleteDetails } from "@/services/ContractsService";
import ContractNextActionButton from "@/features/contracts/ContractNextActionButton";

const StyledContainer = styled.div`
  min-width: fit-content;

  button {
    padding: 0.25rem 1rem;
    min-width: fit-content;
    border-radius: 10rem;
  }
`;

const ActionCell: React.FC<{
  contractDetails: ContractCompleteDetails;
}> = ({ contractDetails: { id } }) => {
  const ctaButtonJsx = <ContractNextActionButton contractId={id} />;

  if (!ctaButtonJsx) {
    return "-";
  }

  return <StyledContainer>{ctaButtonJsx}</StyledContainer>;
};

export default ActionCell;
