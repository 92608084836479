import { useCallback, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";

import Form from "@/components/input/Form";
import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";
import { areStringsSubstring } from "@/utils/string";
import { ANIMATION_CLASSNAMES } from "@/styles";

import {
  FormValues,
  ISpecializationFormProps,
} from "./SpecializationForm.types";
import {
  DEFAULT_FORM_VALUES,
  VALIDATION_SCHEMA,
} from "./SpecializationForm.config";
import ExperienceDurationInput from "./components/ExperienceDurationInput";
import PROFESSIONS_LIST_ from "@/features/user/ProfileForm/content/professions.json";

const StyledTagContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: 10rem;
  margin-top: 1rem;
`;

const StyledTagButton = styled(Button)`
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

// @ts-ignore
const PROFESSIONS_LIST: string[] = [...new Set(PROFESSIONS_LIST_)];

const SpecializationForm: React.FC<ISpecializationFormProps> = ({
  initialData: initialDataFromProps = DEFAULT_FORM_VALUES,
  onSubmit,
  className,
  ctaProps = {},
}) => {
  const [updated, setUpdated] = useState(false);
  const [search, setSearch] = useState(initialDataFromProps.name || "");

  const initialDataRef = useRef(initialDataFromProps);

  const options = useMemo(() => {
    return PROFESSIONS_LIST.filter((name) =>
      areStringsSubstring(name, search, false)
    )
      .sort(
        (a, b) =>
          +Boolean(b.toLowerCase().startsWith(search.toLowerCase())) -
          +Boolean(a.toLowerCase().startsWith(search.toLowerCase()))
      )
      .map((name) => ({
        children: <>{name}</>,
        value: name,
      }));
  }, [search]);

  //---------------------------

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (onSubmit) {
        onSubmit({ values });
      }
    },
    [onSubmit]
  );

  //---------------------------

  const { show: showCtaButton = true } = ctaProps;

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      initialValues={{ ...DEFAULT_FORM_VALUES, ...initialDataRef.current }}
      yupValidationSchema={VALIDATION_SCHEMA}
    >
      {({
        context: {
          values,
          setFieldValue,
          submitForm,
          touchedAndHasError,
          errors,
        },
      }) => {
        const hasErrors = !!Object.keys(errors).length;

        return (
          <form className={className} onSubmit={submitForm}>
            <TextField
              variant="background"
              placeholder="Name"
              value={values?.name}
              onChange={(value) => {
                setSearch(value);
                setFieldValue("name", value);
                setUpdated(true);
              }}
              hasError={touchedAndHasError("name")}
            />

            <StyledTagContainer>
              {options.map(({ value }, i) => (
                <StyledTagButton
                  className={ANIMATION_CLASSNAMES.FADE_IN}
                  variant="secondary"
                  colorVariant="gray"
                  key={value}
                  onClick={() => {
                    setFieldValue("name", value);
                  }}
                >
                  {value}
                </StyledTagButton>
              ))}
            </StyledTagContainer>

            <ExperienceDurationInput
              className="mt-4"
              onChange={() => {
                setUpdated(true);
              }}
            />

            {!!showCtaButton && (
              <Button
                type="submit"
                disabled={!hasErrors && !updated}
                className="w-100 mt-4"
                colorVariant={hasErrors ? "disabled" : "primary"}
                {...ctaProps}
              >
                {ctaProps.children || "Save"}
              </Button>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default SpecializationForm;
