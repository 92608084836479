import lodashGet from "lodash/get";

import {
  ContractCompleteDetails,
  ContractCreatorType,
  ContractMilestoneStatus,
  getLoggedInUserContractUserType,
  getNextActiveMilestone,
  isContractCompleted,
  isMilestoneFunded,
  isMilestonePaymentReleased,
} from "@/services/ContractsService";

import { MILESTONE_STATUS_CONFIG } from "./StatusSection.config";

export function calculateContractProgress(contract: ContractCompleteDetails) {
  const isCompleted = isContractCompleted(contract);

  if (isCompleted) {
    return 100;
  }

  const { milestone } = getNextActiveMilestone(contract);

  if (!milestone) {
    return 0;
  }

  const { status } = milestone;

  if (
    [
      ContractMilestoneStatus.Accepted,
      ContractMilestoneStatus.Published,
    ].includes(status)
  ) {
    return 10;
  }

  if (
    [
      ContractMilestoneStatus.ReadyForWork,
      ContractMilestoneStatus.Funded,
    ].includes(status)
  ) {
    return 35;
  }

  if (
    [
      ContractMilestoneStatus.Review,
      ContractMilestoneStatus.FundRequested,
    ].includes(status)
  ) {
    return 75;
  }

  return 0;
}

export function getMilestoneStatusText(
  status: ContractMilestoneStatus,
  userType?: ContractCreatorType
) {
  const statusTextByUser = lodashGet(
    MILESTONE_STATUS_CONFIG,
    `[${status}].content[${userType}].status.content`
  );
  const contractStatusText = lodashGet(
    MILESTONE_STATUS_CONFIG,
    `[${status}].content.common.status.content`
  );

  return statusTextByUser || contractStatusText || "";
}

export function getContractStatusText(contract: ContractCompleteDetails) {
  const isCompleted = isContractCompleted(contract);

  if (isCompleted) {
    return getMilestoneStatusText(ContractMilestoneStatus.Completed);
  }

  const { milestone: nextActiveMilestone } = getNextActiveMilestone(contract);
  const milestone = nextActiveMilestone || contract.milestones[0];
  const userType = getLoggedInUserContractUserType(contract) || undefined;

  const status = milestone?.status || contract.milestones[0].status;

  return getMilestoneStatusText(status, userType);
}

export function getPaymentStatusText(contract: ContractCompleteDetails) {
  const { milestone: nextActiveMilestone } = getNextActiveMilestone(contract);
  const milestone = nextActiveMilestone || contract.milestones[0];
  const isCompleted = isContractCompleted(contract);

  if (isCompleted) {
    return "Paid out";
  }

  if (!milestone) {
    return "";
  }

  const paidOut = isMilestonePaymentReleased(milestone);
  const isFunded = isMilestoneFunded(milestone);

  if (paidOut) {
    return "Paid Out";
  }

  if (isFunded) {
    return "Securely Held";
  }

  return "";
}

export function getNextDueDate(contract: ContractCompleteDetails) {
  const { milestone } = getNextActiveMilestone(contract);

  if (!milestone) {
    return null;
  }

  const { end_date } = milestone;

  return end_date ? new Date(end_date) : null;
}
