import React from "react";

import { StyledContainer } from "./ChatRoomPage.styles";
import { ChatRoomPageProvider } from "./ChatRoomPage.context";
import ChatRoom from "./components/ChatRoom";

const ChatRoomPage: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ChatRoomPageProvider>
      <StyledContainer className={className}>
        <ChatRoom />
      </StyledContainer>
    </ChatRoomPageProvider>
  );
};

export default ChatRoomPage;
