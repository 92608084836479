import React, { useCallback, useMemo, useRef, useState } from "react";
import lodashGet from "lodash/get";

import { Item } from "@/components/misc/Timeline";
import Icon from "@/components/misc/Icon";
import {
  ContractCurrency,
  ContractMilestone,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Button from "@/components/input/Button";
import { useResponsive } from "@/styles";

import { useContractForm } from "../../../ContractForm.context";
import MilestoneCard from "../MilestoneCard";
import {
  StyledButton,
  StyledDisplay,
  StyledIconContainer,
  StyledLabel,
  StyledTimeline,
} from "./MilestoneInput.styles";
import MilestoneForm from "../MilestoneForm";
import MilestoneDeleteConfirmation from "../../misc/MilestoneDeleteConfirmation";
import {
  StyledStep,
  StyledStepFloatingContent,
  StyledStepTopContent,
} from "../../../ContractForm.styles";
import { DATES_OPTIONAL } from "../../../ContractForm.config";

const MilestoneInput: React.FC<{
  className?: string;
  readOnly?: boolean;
}> = ({ className, readOnly }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const {
    values: { milestones = [] },
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
  } = useContractForm();

  const hasMilestone = !!milestones.length;

  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState<
    null | number
  >(null);
  const [toDeleteMilestoneIndex, setToDeleteMilestoneIndex] = useState<
    null | number
  >(null);

  const isEditingMilestoneCompleted = useMemo(
    () => !lodashGet(errors, `milestones.${editingMilestoneIndex}`),
    [errors, editingMilestoneIndex]
  );

  const milestoneFormModalState = useModalState({
    onOpenChange: (open) => {
      if (!open) {
        setEditingMilestoneIndex(null);
      }
    },
  });
  const deleteMilestneModalState = useModalState({
    onOpenChange: (open) => {
      if (!open) {
        setToDeleteMilestoneIndex(null);
      }
    },
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    const el = ref.current;
    if (el) {
      el.scrollIntoView(false);
    }
  }, []);

  const onTriggerMilestoneEdit = useCallback(
    (milestoneIndex: number) => {
      setEditingMilestoneIndex(milestoneIndex);
      milestoneFormModalState.open();
    },
    [milestoneFormModalState]
  );

  const onTriggerMilestoneDelete = useCallback(
    (milestoneIndex: number) => {
      setToDeleteMilestoneIndex(milestoneIndex);
      deleteMilestneModalState.open();
    },
    [deleteMilestneModalState]
  );

  const addMilestone = useCallback(() => {
    const updatedMilestones = [
      ...milestones,
      {
        name: "",
        description: "",
        start_date: null,
        end_date: null,
        currency: ContractCurrency.USD,
        value: 0,
      } as ContractMilestone,
    ];

    setFieldValue("milestones", updatedMilestones);

    setTimeout(scrollToBottom, 50);

    const newMilestoneIndex = updatedMilestones.length - 1;
    const milestoneKey = `milestones[${newMilestoneIndex}]`;

    setFieldTouched(`${milestoneKey}.name`, false);
    setFieldTouched(`${milestoneKey}.description`, false);
    setFieldTouched(`${milestoneKey}.value`, false);

    onTriggerMilestoneEdit(updatedMilestones.length - 1);
  }, [
    milestones,
    setFieldValue,
    scrollToBottom,
    onTriggerMilestoneEdit,
    setFieldTouched,
  ]);

  const timelineItems = useMemo(() => {
    const items: Item[] = milestones.map((_, i) => ({
      children: (
        <MilestoneCard
          readOnly={readOnly}
          milestoneIndex={i}
          onEdit={onTriggerMilestoneEdit}
          onDelete={onTriggerMilestoneDelete}
        />
      ),
    }));

    if (!readOnly && milestones.length) {
      items.push({
        containerClassName: "button-container",
        children: (
          <StyledButton $active variant="ghost" onClick={addMilestone}>
            <StyledIconContainer $active>
              <Icon
                isSrcRelative
                src="plus.svg"
                size="xxs"
                colorVariant="white"
              />
            </StyledIconContainer>
            Add another milestone
          </StyledButton>
        ),
      });
    }

    return items;
  }, [
    milestones,
    addMilestone,
    onTriggerMilestoneEdit,
    onTriggerMilestoneDelete,
    readOnly,
  ]);

  const editingMilestoneName =
    editingMilestoneIndex !== null
      ? `Milestone ${editingMilestoneIndex + 1}`
      : "";

  const handleContinueClick = useCallback(() => {
    const milestoneKey = `milestones[${editingMilestoneIndex}]`;
    setFieldTouched(`${milestoneKey}.name`);
    setFieldTouched(`${milestoneKey}.description`);
    setFieldTouched(`${milestoneKey}.value`);

    if (!DATES_OPTIONAL) {
      setFieldTouched(`${milestoneKey}.start_date`);
      setFieldTouched(`${milestoneKey}.end_date`);
    }

    if (isEditingMilestoneCompleted) {
      milestoneFormModalState.close();
    }
  }, [
    milestoneFormModalState,
    editingMilestoneIndex,
    setFieldTouched,
    isEditingMilestoneCompleted,
  ]);

  return (
    <>
      <StyledDisplay
        $active={hasMilestone}
        className={className}
        $hasError={!!touched.milestones && !milestones.length}
      >
        <Icon
          isSrcRelative
          src="milestones.svg"
          customSize="sm"
          colorVariant={hasMilestone ? "primary" : undefined}
        />
        <StyledLabel>Milestones</StyledLabel>
      </StyledDisplay>

      {!readOnly && !hasMilestone && (
        <StyledButton variant="ghost" onClick={addMilestone}>
          <StyledIconContainer>
            <Icon
              isSrcRelative
              src="plus.svg"
              size="xxs"
              colorVariant="primary"
            />
          </StyledIconContainer>
          Add first milestone
        </StyledButton>
      )}

      <div ref={ref}>
        <StyledTimeline className="mt-4" items={timelineItems} />
      </div>

      {!readOnly && toDeleteMilestoneIndex !== null && (
        <Modal
          state={deleteMilestneModalState}
          desktopProps={{ width: "420px" }}
        >
          <MilestoneDeleteConfirmation
            milestoneIndex={toDeleteMilestoneIndex}
            onCancel={deleteMilestneModalState.close}
            onDelete={deleteMilestneModalState.close}
          />
        </Modal>
      )}

      {!readOnly && editingMilestoneIndex !== null && (
        <Modal
          {...(isScreenSmallerThanTablet
            ? { fullscreen: true, className: "p-0" }
            : { width: "768px" })}
          state={milestoneFormModalState}
          containerProps={{ style: { zIndex: 2 } }}
          shouldCloseOnInteractOutside={() => false}
        >
          <PageWithHeaderLayout
            headerProps={{
              backButtonProps: { onClick: milestoneFormModalState.close },
              titleProps: {
                children: editingMilestoneName,
              },
            }}
          >
            <StyledStep>
              <StyledStepTopContent>
                <MilestoneForm
                  className="px-3"
                  milestoneIndex={editingMilestoneIndex}
                />

                {!isScreenSmallerThanTablet && (
                  <div className="px-3 mt-4">
                    <Button
                      className="w-100"
                      colorVariant={
                        isEditingMilestoneCompleted ? "primary" : "disabled"
                      }
                      onClick={handleContinueClick}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </StyledStepTopContent>

              {isScreenSmallerThanTablet && (
                <StyledStepFloatingContent>
                  <Button
                    className="w-100"
                    colorVariant={
                      isEditingMilestoneCompleted ? "primary" : "disabled"
                    }
                    onClick={handleContinueClick}
                  >
                    Continue
                  </Button>
                </StyledStepFloatingContent>
              )}
            </StyledStep>
          </PageWithHeaderLayout>
        </Modal>
      )}
    </>
  );
};

export default MilestoneInput;
