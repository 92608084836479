"use client";
import React, { useMemo } from "react";

import {
  amIContractPayee,
  amIContractPayer,
  getNextActiveMilestone,
  isContractCompleted,
  isMilestoneFundReleasable,
  isMilestoneFundRequestable,
  isMilestoneFundable,
  isMilestoneFunded,
  isMilestoneReviewable,
  isMilestoneStartable,
  isMilestoneWorkSubmittable,
} from "@/services/ContractsService";
import RequestPaymentButton from "@/features/contracts/RequestPaymentButton";
import ReleasePaymentButton from "@/features/contracts/ReleasePaymentButton";
import { useResponsive } from "@/styles";
import { appFeatures } from "@/config/services";

import { StyledButtonsContainer } from "../ContractManagementPage.styles";
import { useContractManagement } from "../ContractManagementPage.context";
import NewOfferButton from "./NewOfferButton";
import HireAgainButton from "./HireAgainButton";
import ChatButton from "./ChatButton";
import StartMilestoneButton from "@/features/contracts/StartMilestoneButton";

const ActionsSection: React.FC<{ className?: string }> = ({ className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { contractDetails } = useContractManagement();

  const {
    showRequestPaymentButton,
    showReleasePaymentButton,
    showNewOfferButton,
    showHireAgainButton,
    showChatButton,
    showStartButton,
    nextActiveMilestone,
  } = useMemo(() => {
    const { milestone: nextActiveMilestone } =
      getNextActiveMilestone(contractDetails);
    const amIPayer = amIContractPayer(contractDetails);
    const amIPayee = amIContractPayee(contractDetails);
    const isCompleted = isContractCompleted(contractDetails);

    const showNewOfferButton = isCompleted && amIPayee;
    const showHireAgainButton = isCompleted && amIPayer;

    if (!nextActiveMilestone) {
      return {
        canFund: false,
        canSubmit: false,
        canReview: false,
        showRequestPaymentButton: false,
        showReleasePaymentButton: false,
        showChatButton: isCompleted,
        showNewOfferButton,
        showHireAgainButton,
      };
    }

    const isFunded = isMilestoneFunded(nextActiveMilestone);

    const canFund = isMilestoneFundable(nextActiveMilestone) && amIPayer;
    const canReview = isMilestoneReviewable(nextActiveMilestone) && amIPayer;
    const canReleasePayment =
      isMilestoneFundReleasable(nextActiveMilestone) && amIPayer;

    const canSubmit =
      isMilestoneWorkSubmittable(nextActiveMilestone) && amIPayee;
    const canRequestPayment =
      isMilestoneFundRequestable(nextActiveMilestone) && amIPayee;

    const canStart = isMilestoneStartable(nextActiveMilestone) && amIPayee;

    const showRequestPaymentButton = canRequestPayment;
    const showReleasePaymentButton = canReleasePayment;
    const showStartButton = canStart;
    const showChatButton =
      !isScreenSmallerThanTablet || isCompleted || !isFunded;

    return {
      canFund,
      canSubmit,
      canReview,
      nextActiveMilestone,
      showRequestPaymentButton,
      showReleasePaymentButton,
      showChatButton,
      showNewOfferButton,
      showHireAgainButton,
      canStart,
      showStartButton,
    };
  }, [contractDetails, isScreenSmallerThanTablet]);

  if (
    !showChatButton &&
    !showHireAgainButton &&
    !showNewOfferButton &&
    !showRequestPaymentButton &&
    !showReleasePaymentButton
  ) {
    return null;
  }

  return (
    <StyledButtonsContainer className={className}>
      {(showChatButton || !isScreenSmallerThanTablet) && <ChatButton />}
      {showHireAgainButton &&
        appFeatures.isSupported("CONTRACT.HIRE_SP_AGAIN") && (
          <HireAgainButton />
        )}
      {showNewOfferButton && <NewOfferButton />}
      {showRequestPaymentButton && (
        <RequestPaymentButton contractDetails={contractDetails} />
      )}
      {showReleasePaymentButton && (
        <ReleasePaymentButton contractDetails={contractDetails} />
      )}
      {showStartButton && !!nextActiveMilestone && (
        <StartMilestoneButton
          contractId={contractDetails.id}
          milestoneId={nextActiveMilestone.id}
        >
          Start Next Milestone
        </StartMilestoneButton>
      )}
    </StyledButtonsContainer>
  );
};

export default ActionsSection;
