import React, { useMemo } from "react";

import {
  SubscriptionStatus,
  useManageSubscription,
  useSubscriptionDetails,
} from "@/services/UserService";
import { formatDate } from "@/utils/date";
import Button from "@/components/input/Button";
import { Body, Display, Label } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { SITE_PATHS } from "@/config/routing";

import { StyledCard, StyledContainer } from "./ActiveSubscription.styles";
import Feature from "../Feature";

const ActiveSubscription: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { loadingSubscriptionDetails, subscriptionDetails } =
    useSubscriptionDetails();
  const { isLoading, manage } = useManageSubscription();

  const subscriptionEndDateFormatted = useMemo(
    () =>
      subscriptionDetails?.subscriptionEndDate
        ? formatDate(subscriptionDetails.subscriptionEndDate, "DD MMM YY")
        : "",
    [subscriptionDetails]
  );

  const isActiveCancelled =
    subscriptionDetails?.status === SubscriptionStatus.ActiveCancelled;
  const subscriptionEndText = isActiveCancelled
    ? "Cancels on:"
    : subscriptionDetails?.isTrial
    ? "Trial ends on:"
    : "Your next payment is on:";
  const statusText = subscriptionDetails?.isTrial ? "Trial" : "Active";

  const content = (
    <StyledContainer className="container">
      <div className="card-section">
        <StyledCard>
          <div className="heading-container">
            <Display size="lg" className="heading" as="div">
              Premium
            </Display>
            <div className="tag">
              <Icon
                isSrcRelative
                src="tick.svg"
                size="xs"
                colorVariant="white"
              />
              {statusText}
            </div>
          </div>

          <img
            className="logo"
            alt=""
            src="/assets/images/branding/logo_3d_gradient.svg"
          />

          <div className="bottom">
            <div className="content">
              <Body size="sm" className="secondary">
                {subscriptionEndText}
              </Body>
              <Label size="md" className="primary mt-2">
                {subscriptionEndDateFormatted}
              </Label>
            </div>
          </div>
        </StyledCard>
      </div>

      {false && (
        <div className="features-section mb-4 mt-4">
          <div className="label">Here's how your tracking:</div>

          <Feature
            className="mt-3"
            icon="star_2.svg"
            title="Assisted by Penny"
            description="Penny has helped you create 8 offers"
          />

          <Feature
            className="mt-3"
            icon="arrow_down.svg"
            title="Savings on fees"
            description={
              <>
                You have saved <b>$350</b> on service fees
              </>
            }
          />

          <Feature
            className="mt-3"
            icon="access.svg"
            title="First access"
            description={
              <>
                You were one of the first to access:
                <ul>
                  <li>Feature one</li>
                  <li>Feature two</li>
                </ul>
              </>
            }
          />
        </div>
      )}

      <div className="cta-section">
        <div className="w-100">
          <Button
            disabled={isLoading}
            className="w-100"
            colorVariant="black"
            variant="secondary"
            onClick={manage}
          >
            Manage Subscription
          </Button>
        </div>
      </div>
    </StyledContainer>
  );

  // --------------------------------

  if (loadingSubscriptionDetails) {
    return null;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{
          backButtonProps: { link: SITE_PATHS.SETTINGS_PAGE },
          titleProps: {
            children: "Subscription",
          },
        }}
      >
        {content}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout
      headerProps={{
        logo: {
          component: "Subscription",
        },
      }}
    >
      {content}
    </DesktopPrimaryLayout>
  );
};

export default ActiveSubscription;
