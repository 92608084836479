"use client";

import styled from "@emotion/styled";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    min-width: fit-content;
    vertical-align: top;
    padding-block: 0.55rem;
    word-break: break-word;
  }

  td:first-of-type {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.02em;
    color: var(--clr-icon-text-200, #818898);
    text-align: left;
    white-space: nowrap;
  }

  td:last-of-type {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.02em;
    color: var(--clr-text-900, #0d0d12);
    text-align: right;
    font-weight: 500;
  }
`;

export const StyledTr = styled.tr<{ $border: boolean }>`
  ${({ $border }) =>
    $border
      ? `
          &:not(:first-of-type) {
            border-top: 1px solid var(--clr-border-25, #f6f8fa);
          }
        `
      : ``}
`;
