import React, { useMemo } from "react";

import Card from "@/components/data/Card";
import { ContractType, amIContractPayee } from "@/services/ContractsService";

import { IContractPreviewProps } from "./ContractPreview.types";
import { StyledContainer } from "./ContractPreview.styles";
import MainDetailsSection from "./components/MainDetailsSection";
import DetailsSection from "./components/DetailsSection";
import PaymentSummary from "./components/PaymentSummary";
import FilesSection from "./components/FilesSection";
import MilestonesSection from "./components/MilestonesSection";
import CtaSection from "./components/CtaSection";
import OtherUserDetails from "./components/OtherUserDetails";

const ContractPreview: React.FC<IContractPreviewProps> = ({
  contractDetails,
  className,
  showCreationDate,
}) => {
  const { contract_type } = contractDetails;

  const isMilestoneContract = contract_type === ContractType.Milestone;

  const amIPayee = useMemo(
    () => amIContractPayee(contractDetails),
    [contractDetails]
  );

  return (
    <StyledContainer className={className}>
      <Card>
        <MainDetailsSection
          contractDetails={contractDetails}
          showCreationDate={showCreationDate}
        />
      </Card>

      <Card className="mt-4">
        <OtherUserDetails contractDetails={contractDetails} />
      </Card>

      <Card className="mt-4">
        <DetailsSection contractDetails={contractDetails} />
      </Card>

      {isMilestoneContract && (
        <Card className="mt-4">
          <MilestonesSection contractDetails={contractDetails} />
        </Card>
      )}

      <FilesSection className="mt-3 mx-3" contractDetails={contractDetails} />

      <Card className="mt-4">
        <PaymentSummary contractDetails={contractDetails} />
      </Card>

      {amIPayee && (
        <div className="mt-4 px-3">
          <CtaSection
            className="mt-4 w-100"
            contractDetails={contractDetails}
          />
        </div>
      )}
    </StyledContainer>
  );
};

export default ContractPreview;
