import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  height: 100%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.25rem 0 0.25rem 1rem;
  height: 2.25rem;
  border-radius: 10rem;
  min-width: fit-content;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;
