import React, { useMemo } from "react";

import FilesInput, { FileObject } from "@/features/input/FilesInput";
import { ContractCompleteDetails } from "@/services/ContractsService";
import { useResponsive } from "@/styles";

const FilesSection: React.FC<{
  contractDetails: ContractCompleteDetails;
  className?: string;
}> = ({ contractDetails, className }) => {
  const { files } = contractDetails;

  const { isScreenSmallerThanTablet } = useResponsive();

  const filesObjects: FileObject[] = useMemo(
    () => files.map((file) => ({ mediaFile: file } satisfies FileObject)),
    [files]
  );

  if (!filesObjects.length) {
    return null;
  }

  return (
    <FilesInput
      disabled
      className={className}
      displayFileCount={isScreenSmallerThanTablet ? 2 : 0}
      value={filesObjects}
      label="Files"
    />
  );
};

export default FilesSection;
