import { ApiResponse, IApiService } from "@/services/Api";
import { store, chatActions } from "@/services/Store";

class ChatService {
  _apiService: IApiService;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
  }

  getToken = () => {
    return this._apiService
      .get<
        ApiResponse<{
          token: string;
        }>
      >("user/chat/token")
      .then((res) => {
        return res.data.data;
      });
  };

  getChatSummary = () => {
    return this._apiService
      .get<
        ApiResponse<{
          has_chat_data: boolean;
          unread_message_count: number;
        }>
      >("user/chat/summary")
      .then((res) => {
        const data = {
          hasChatData: res.data.data.has_chat_data,
          unreadCount: res.data.data.unread_message_count,
        };

        this.updateStoreState(data);

        return data;
      });
  };

  //--------------------

  updateStoreState = (data: {
    hasChatData?: boolean;
    unreadCount?: number;
  }) => {
    store.dispatch(chatActions.setChatState(data));
  };

  cleanup = () => {
    store.dispatch(chatActions.clear());
  };
}

export default ChatService;