"use client";

import { Body, Label } from "@/components/Typography";
import styled from "@emotion/styled";

export const StyledContainer = styled.div``;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
`;

export const StyledPrimaryText = styled(Label)`
  flex: 1;
`;

export const StyledSecondaryText = styled(Body)`
  color: var(--clr-text-500, #666d80);
`;

export const StyledSmallText = styled.div`
  color: var(--clr-text-500, #666d80);
  font-size: 0.625rem;
  font-weight: 500;
`;

export const StyledContent = styled(Body)``;
