"use client";

import React, { useEffect, useMemo } from "react";
import lodashGet from "lodash/get";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import { IActionCardProps } from "@/components/misc/ActionCard";
import { selectNotifications, useAppSelector } from "@/services/Store";
import { useNotifications } from "@/services/ChatService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import { formatDate, isDateToday } from "@/utils/date";
import { Body } from "@/components/Typography";

import {
  StyledActionCard,
  StyledHeader,
  StyledPage,
} from "./NotificationsPage.styles";
import SortButton from "./components/SortButton";
import PushNotificationButton from "./components/PushNotificationButton";

const NotificationsPage: React.FC = () => {
  const { loadingNotifications, updateSeenNoficationState } =
    useNotifications();
  const notificationsFromHook = useAppSelector(selectNotifications);
  const { isScreenSmallerThanTablet } = useResponsive();

  const notifications = useMemo(
    () =>
      [...notificationsFromHook]
        .sort(
          (a, b) =>
            new Date(b.timestamp || 0).getTime() -
            new Date(a.timestamp || 0).getTime()
        )
        .map((n) => {
          const date = n.timestamp ? new Date(n.timestamp) : "";
          const formattedDate = date
            ? formatDate(
                new Date(date),
                isDateToday(date) ? "hh:mmA" : undefined
              )
            : null;

          return {
            content: (
              <div className="content">
                <div>{lodashGet(n, "message") || n.data?.message}</div>
                {!!formattedDate && <Body size="sm">{formattedDate}</Body>}
              </div>
            ),
            icon: "clock.svg",
            link: n.data?.url,
          } as IActionCardProps;
        }),
    [notificationsFromHook]
  );

  const noContentJsx = (
    <ImageTitleContent
      className="mt-5"
      image="/assets/images/pages/contracts/no_contracts.svg"
      title={<>You don&apos;t have any notifications</>}
      content=""
    />
  );

  const notificationCardJsx = notifications.map((item, i) => (
    <StyledActionCard key={i} {...item} className="mt-1" />
  ));

  const hasNoNotification = loadingNotifications && !notifications.length;

  const content = (
    <StyledPage className="container">
      <StyledHeader className="mb-3">
        <PushNotificationButton />
      </StyledHeader>

      {hasNoNotification ? noContentJsx : notificationCardJsx}

      {false && <SortButton />}
    </StyledPage>
  );

  useEffect(() => {
    updateSeenNoficationState(true);
  }, [updateSeenNoficationState]);

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{ titleProps: { children: "Notifications" } }}
      >
        {content}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout
      headerProps={{ logo: { component: "Notifications" } }}
    >
      {content}
    </DesktopPrimaryLayout>
  );
};

export default NotificationsPage;
