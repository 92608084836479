"use client";

import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .pdf,
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .download {
    ${squareSizing("2.5rem")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    z-index: 1;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.25);

    .icon {
      transform: rotate(180deg);
      filter: brightness(0) invert(1);
    }
  }

  .pg-viewer-wrapper {
    overflow: auto !important;
  }

  .pg-viewer > * {
    width: inherit;
    height: 100%;
    overflow: auto;
  }

  .photo-viewer-container {
    padding: 1rem;
    display: block !important;
  }

  img {
    display: block !important;
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }

  canvas {
    display: block;
    margin-inline: auto;
  }
`;
