import React from "react";
import styled from "@emotion/styled";

import { Body } from "@/components/Typography";
import PortfolioCard from "@/features/user/PortfolioCard";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import { screenLargerThan, screenSmallerThan } from "@/styles";

import { useProfilePage } from "../ProfilePage.context";

const StyledCardContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`;

const StyledCard = styled(PortfolioCard)`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);

  ${screenSmallerThan.widescreen} {
    padding-bottom: 1rem;
    margin-inline: 1.5rem;
  }
`;

const PortfolioSection: React.FC = () => {
  const { profileData } = useProfilePage();
  const { portfolios = [] } = profileData;

  if (!portfolios.length) {
    return (
      <ImageTitleContent
        className="mt-5 mb-5"
        title="No portfolio"
        content={<>You don't have any portfolios</>}
        image="/assets/images/pages/contracts/no_contracts.svg"
      />
    );
  }

  return (
    <div>
      <Body size="md" style={{ color: "var(--clr-icon-text-200, #818898)" }}>
        Portfolio
      </Body>

      <StyledCardContainer className="mt-3">
        {portfolios.map((portfolio, i) => (
          <StyledCard key={i} portfolio={portfolio} />
        ))}
      </StyledCardContainer>
    </div>
  );
};

export default PortfolioSection;
