import React, { Fragment } from "react";

import { useProfilePage } from "../ProfilePage.context";
import InfoDisplay from "./InfoDisplay";

const LanguagesDisplay: React.FC<{ className?: string }> = ({ className }) => {
  const { profileData } = useProfilePage();

  if (!profileData.languages?.length) {
    return null;
  }

  return (
    <InfoDisplay
      className={className}
      icon="language.svg"
      value={profileData.languages.map((language, i) => (
        <Fragment key={i}>
          {i !== 0 && ", "}
          {language}&nbsp;
        </Fragment>
      ))}
    />
  );
};

export default LanguagesDisplay;
