"use client";

import styled from "@emotion/styled";

import RadioCardGroup from "@/components/input/RadioCardGroup";

export const StyledRadioCardGroup = styled(RadioCardGroup)`
  gap: 0.5rem;
`;

export const StyledContainer = styled.div`
  padding: 1rem;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;
