import React, { useCallback, useEffect, useMemo, useRef } from "react";

import FileCard, {
  getFileDetailsFromKey,
  IFileCardProps,
} from "@/features/input/FileCard";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Modal, { useModalState } from "@/components/misc/Modal";
import Icon from "@/components/misc/Icon";
import { SUPPORTED_FILES_INPUT_ACCEPT_VALUE } from "@/services/MediaService";
import { useToast } from "@/components/misc/Toast";
import { MAX_FILE_SIZE_IN_BYTES } from "@/config/app";

import {
  StyledCardsVariant,
  StyledContentSection,
  StyledCount,
  StyledIcon,
  StyledInput,
  StyledLabel,
  StyledModelInner,
  StyledModelText,
  StyledText,
} from "./FilesInput.styles";
import { FileObject, IFilesInputProps } from "./FilesInput.types";

const FilesInput: React.FC<IFilesInputProps> = ({
  value = [],
  onChange,
  className,
  disabled: disabledFromProps = false,
  label = "Attach Files",
  displayFileCount = 2,
  viewMode,
  showInput = true,
  allFilesModelState: allFilesModelStateFromProps,
  variant = "background",
  hasError = false,
}) => {
  const { createToast } = useToast();
  const allFilesModalStateLocal = useModalState();
  const fileCount = value.length;

  const allFilesModalState =
    allFilesModelStateFromProps || allFilesModalStateLocal;

  const filesData = useMemo(() => {
    const items: IFileCardProps[] = value
      .map(({ file, mediaFile }) => {
        if (mediaFile) {
          const fileDetails = getFileDetailsFromKey(mediaFile.url || "");
          let fileName = "";
          let sizeInBytes = 0;

          if (fileDetails) {
            fileName = fileDetails.fileName;
            sizeInBytes = fileDetails.sizeInBytes;
          }

          return {
            name: fileName,
            fileUrl: mediaFile.url,
            sizeInBytes,
            file,
          } satisfies IFileCardProps;
        }

        if (file) {
          return {
            name: file.name,
            fileUrl: URL.createObjectURL(file),
            sizeInBytes: file.size,
            file,
          } satisfies IFileCardProps;
        }

        return null;
      })
      .filter(Boolean) as IFileCardProps[];

    return items;
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      // @ts-ignore
      let newFiles: FileObject[] = [...e.target.files].map((file) => ({
        file,
      }));

      if (
        !!newFiles.find(
          (item) => (item?.file?.size || 0) > MAX_FILE_SIZE_IN_BYTES
        )
      ) {
        newFiles = newFiles.filter(
          (item) => (item?.file?.size || 0) < MAX_FILE_SIZE_IN_BYTES
        );

        createToast({
          variant: "error",
          title: "Please choose files smaller than 5mb",
          timeoutInMilliseconds: 1000 * 5,
        });
      }

      const updatedValue = value.concat(newFiles);

      if (onChange) {
        onChange(updatedValue);
      }
    },
    [value, onChange, createToast]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const updatedValue = value.filter((_, i) => i !== index);

      if (onChange) {
        onChange(updatedValue);
      }
    },
    [value, onChange]
  );

  const disabled = disabledFromProps || viewMode;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      const dataTransfer = new DataTransfer();
      value.forEach(({ file }) => {
        if (file) {
          dataTransfer.items.add(file);
        }
      });

      inputRef.current.files = dataTransfer.files;
    }
  }, [value]);

  if (viewMode && !value.length) {
    return null;
  }

  const isCardsVariant = variant === "cards";
  const inputJsx = (
    <StyledInput
      ref={inputRef}
      multiple
      type="file"
      accept={SUPPORTED_FILES_INPUT_ACCEPT_VALUE}
      onChange={handleChange}
      disabled={disabled}
    />
  );

  if (isCardsVariant) {
    return (
      <StyledCardsVariant $hasError={hasError}>
        {!!filesData.length && (
          <div className="cards-container mb-4">
            {filesData.map((file, i) => (
              <FileCard
                variantCompact
                key={`${file.name}_${i}`}
                {...file}
                file={file.file}
                deleteButtonProps={
                  disabledFromProps
                    ? undefined
                    : {
                        onClick: () => {
                          handleDelete(i);
                        },
                      }
                }
              />
            ))}
          </div>
        )}

        {showInput && (
          <label className="input">
            {inputJsx} <Icon isSrcRelative src="upload_2.svg" />{" "}
            <div>
              {label} {!disabled && <small>(Max size 5 MB)</small>}
            </div>
          </label>
        )}
      </StyledCardsVariant>
    );
  }

  return (
    <>
      <div className={className}>
        {showInput && (
          <StyledLabel $variant={variant} $hasError={false}>
            {inputJsx}

            <StyledIcon isSrcRelative src="clip.svg" customSize="20px" />

            <StyledContentSection $variant={variant} $hasError={false}>
              <StyledText size="lg">
                {label} {!disabled && <small>(Max size 5 MB)</small>}
              </StyledText>

              {!!fileCount && (
                <StyledCount
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    allFilesModalState.open();
                  }}
                >
                  {fileCount} {fileCount === 1 ? "file" : "files"}
                  <Icon
                    isSrcRelative
                    src="eyes_2.svg"
                    size="xs"
                    colorVariant="white"
                  />
                </StyledCount>
              )}
            </StyledContentSection>
          </StyledLabel>
        )}

        {[...filesData].splice(0, displayFileCount).map((file, i) => (
          <FileCard
            key={i}
            {...file}
            className="mt-3"
            file={file.file}
            deleteButtonProps={
              disabledFromProps
                ? undefined
                : {
                    onClick: () => {
                      handleDelete(i);
                    },
                  }
            }
          />
        ))}
      </div>

      <Modal
        mobileProps={{ fullscreen: true, className: "p-0" }}
        className="p-0"
        state={allFilesModalState}
      >
        <PageWithHeaderLayout
          headerProps={{
            backButtonProps: { onClick: allFilesModalState.close },
            titleProps: {
              children: "All attachments",
            },
          }}
        >
          <StyledModelInner>
            <StyledModelText>
              {fileCount} {fileCount > 1 ? "Files" : "File"}
            </StyledModelText>

            {filesData.map((file, i) => (
              <FileCard
                key={i}
                {...file}
                className="mt-3"
                deleteButtonProps={
                  disabledFromProps
                    ? undefined
                    : {
                        onClick: () => {
                          handleDelete(i);
                        },
                      }
                }
              />
            ))}
          </StyledModelInner>
        </PageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default FilesInput;
