"use client";

import styled from "@emotion/styled";

import { Body, Heading } from "@/components/Typography";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";

export const StyledContainer = styled.div`
  padding: 0 1rem 2rem 1rem;
`;

export const StyledHeading = styled(Heading)`
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledBody = styled(Body)`
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledButton = styled(Button)`
  gap: 0.4rem;
  padding: 1rem;
  min-height: 3rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  background: var(--clr-background-primary, #fff);
  border: 1px solid var(--clr-border-ukwn-2, #efefef);
  color: var(--clr-text-900, #0d0d12);
  border-radius: 1rem;

  & + & {
    margin-top: 0.8rem;
  }

  &:disabled,
  &[disabled] {
    color: var(--clr-ukwn-3, #a4acb9);
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const StyledGradientButton = styled(StyledButton)`
  position: relative;
  background-color: #ffffff;
  background-image: url("/assets/images/misc/gradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  svg {
    display: none;
  }
`;

export const StyledGradientButton_ = styled(StyledButton)`
  position: relative;
  background-color: #ffffff;
  background-image: radial-gradient(
    74.78% 86.52% at 16.34% 18.75%,
    rgba(5, 0, 255, 0.2) 7.61%,
    rgba(5, 0, 255, 0.2) 35.14%,
    rgba(253, 91, 0, 0.2) 100%
  );
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
      from 180deg at 50% 50%,
      #ff0000 0deg,
      rgba(255, 255, 255, 0.72) 16.88deg,
      #ff0000 88.12deg,
      rgba(255, 255, 255, 0.72) 151.87deg,
      #ff0000 225deg,
      rgba(255, 255, 255, 0.72) 288.75deg,
      #ff0000 360deg
    );
    z-index: -1;
    background-blend-mode: screen;
  }

  svg {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }
`;

export const StyledIcon = styled(Icon)`
  filter: var(--filter-gray);
`;
