import DateField from "@/components/input/DateField";
import { screenLargerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledTopContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledProgressContainer = styled.div`
  flex: 1;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const StyledLabel = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--clr-icon-text-200, #818898);
`;

export const StyledValue = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--clr-text-900, #0d0d12);
`;

export const StyledHighlightedLabel = styled(StyledLabel)`
  color: var(--clr-primary-100, #5f57ff);
  font-size: 0.75rem;
  font-weight: 600;
`;

export const StyledStatusBar = styled.div<{ $percent: number }>`
  position: relative;
  height: 4px;
  border-radius: 10rem;
  background: var(--clr-background-50, #eceff3);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ $percent = 75 }) => `${$percent}%`};
    background: var(--clr-success-500, #1dbf73);
    border-radius: inherit;
    transition: width 240ms ease;
    object-position: left;
  }

  ${screenLargerThan.tablet} {
    max-width: 20rem;
  }
`;

export const StyledDateField = styled(DateField)`
  input {
    display: none;
  }
`;
