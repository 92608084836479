import styled from "@emotion/styled";

import Button from "@/components/input/Button";

import { IStartMilestoneButtonProps } from "./StartMilestoneButton.types";
import { useStartContractMilestone } from "@/services/ContractsService";

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

const StartMilestoneButton: React.FC<IStartMilestoneButtonProps> = ({
  contractId,
  milestoneId,
  children,
  ...restProps
}) => {
  const { isStarting, start } = useStartContractMilestone({
    contractId,
    milestoneId,
  });

  const buttonText = isStarting ? "Starting..." : children || "Start Next Milestone";

  return (
    <Button {...restProps} disabled={isStarting} onClick={start}>
      {buttonText}
    </Button>
  );
};

export default StartMilestoneButton;
