import useSWR from "swr";

import { mediaService } from "@/config/services";
import { useAppSelector, selectFiles } from "@/services/Store";

import { MediaFileFilters } from "../MediaService.types";
import { mediaFiltersToQueryParams } from "../MediaService.utils";

function useFileList(filters: MediaFileFilters = {}) {
  const files = useAppSelector(selectFiles(filters));

  const { data, error, isLoading } = useSWR(
    `/media?${mediaFiltersToQueryParams(filters)}`,
    () => mediaService.fetchFileList(filters),
    { errorRetryInterval: 2000, errorRetryCount: 5, dedupingInterval: 2000 }
  );

  return {
    data: files,
    error,
    isLoading,
    isUpdating: !!data && isLoading,
    hasNoData: !files.length && !isLoading,
  };
}

export default useFileList;
