import styled from "@emotion/styled";

export const StyledSectionHeading = styled.div`
  font-size: 1rem;
  line-height: 170%;
  font-weight: 700;
  color: var(--clr-text-ukwn-1, #171717);
`;

export const StyledContractTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  color: var(--clr-neutral-solid-600, #31353f);
  word-break: break-word;
`;

export const StyledContractValue = styled.div`
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: -0.03em;
  color: var(--clr-neutral-solid-800, #1a1b25);
  word-break: break-word;
`;

export const StyledSection = styled.div`
  padding: 0.5rem 1rem;
  margin-inline: -1rem;
`;
