import styled from "@emotion/styled";

import ContractPreview from "@/features/contracts/ContractPreview";
import { screenLargerThan } from "@/styles";

export const StyledContractPreview = styled(ContractPreview)`
  ${screenLargerThan.tablet} {
    padding-inline: 1rem;
  }
`;
