import Tag from "@/components/misc/Tag";

import {
  CONTRACT_TYPE_CONFIG,
  ContractType,
} from "@/services/ContractsService";

const CONTRACT_TYPE_COLORS: Partial<
  Record<
    ContractType,
    {
      bgColor: string;
    }
  >
> = {
  [ContractType.Hourly]: {
    bgColor: "var(--clr-extra-purple, #cac2ff)",
  },
  [ContractType.Milestone]: {
    bgColor: "var(--clr-extra-marker-green, #e5f7ad)",
  },
  [ContractType.OneTime]: {
    bgColor: "var(--clr-extra-peach, #ebc5bd)",
  },
};

const ContractTypeDisplay: React.FC<{ contract_type: ContractType }> = ({
  contract_type,
}) => {
  const title = CONTRACT_TYPE_CONFIG[contract_type].info.title;
  const bgColor = CONTRACT_TYPE_COLORS[contract_type]?.bgColor;

  return (
    <Tag
      variant="filled"
      colorVariant="primary"
      style={
        bgColor
          ? ({
              "--color": "var(--clr-text-900, #0d0d12)",
              "--bg-color": bgColor,
              "--border-color": bgColor,
            } as React.CSSProperties)
          : {}
      }
    >
      {title}
    </Tag>
  );
};

export default ContractTypeDisplay;
