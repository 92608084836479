import React, { useState } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";
import ReviewSubmission from "@/features/contracts/ReviewSubmission";

import { StyledButtomSmall } from "../../ContractTimeline.styles";

interface IReviewWorkButtonProps {
  contractId: string;
  milestoneId: number;
  className?: string;
}

const ReviewWorkButton: React.FC<IReviewWorkButtonProps> = ({
  contractId,
  milestoneId,
  className,
}) => {
  const modalState = useModalState();
  const [isApproving, setIsApproving] = useState(false);

  return (
    <>
      <StyledButtomSmall
        disabled={isApproving}
        className={className}
        onClick={modalState.open}
      >
        Review Work
      </StyledButtomSmall>

      <Modal
        className="p-0"
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
      >
        <PageWithHeaderLayout
          headerProps={{
            backButtonProps: {
              onClick: modalState.close,
              children: <Icon isSrcRelative src="cross.svg" size="xxs" />,
              style: { background: "none" },
            },
            titleProps: {
              children: "Review Work",
            },
          }}
        >
          <ReviewSubmission
            contractId={contractId}
            milestoneId={milestoneId}
            onIsApprovingChange={setIsApproving}
            onApprove={(p) => {
              p.then(() => {
                modalState.close();
              });
            }}
          />
        </PageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default ReviewWorkButton;
