"use client";

import styled from "@emotion/styled";

export const StyledContainer = styled.div<{ $active: boolean }>`
  color: ${({ $active }) => ($active ? "var(--clr-primary-100, #5F57FF)" : "var(--clr-text-500, #666D80)")};
`;

export const StyledValueContainer = styled.div<{ $active: boolean }>`
  flex: 1;
  display: flex;

  border-radius: 1rem;
  background-color: var(--clr-background-0, #f8fafb);
  border: 1px solid
    ${({ $active }) => ($active ? "var(--clr-primary-100, #5F57FF)" : "var(--clr-background-0, #f8fafb)")};
  min-height: 3.125rem;
`;

export const StyledValue = styled.div`
  flex: 1;
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
`;
