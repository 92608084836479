"use client";

import React, { useCallback, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ReactPhotoEditor } from "react-photo-editor";

import Avatar from "@/components/misc/Avatar";
import { useAuth } from "@/services/Authentication";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import { useUpdateProfileImage } from "@/services/UserService";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { useToast } from "@/components/misc/Toast";
import { MAX_FILE_SIZE_IN_BYTES } from "@/config/app";

import "react-photo-editor/dist/style.css";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContentContainer = styled.div`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 120px;
  border: 3px solid var(--clr-border-ukwn-12, #f3f3f3);
`;

export const StyledButton = styled(Button)`
  ${squareSizing("2rem")};
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  border-radius: 10rem;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const StyledLabel = styled.label`
  position: relative;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  width: 480px;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  small {
    opacity: 0.5;
    font-size: 1rem;
  }

  .photo-editor-main {
    & > div {
      height: 100%;
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  & > *:first-child {
    flex: 1;
    max-width: 8rem;
  }

  & > *:last-child {
    flex: 1;
  }
`;

export const StyledEditButton = styled(StyledButton)`
  bottom: unset;
  top: 2rem;
  right: 2rem;
`;

const ProfileImageInput: React.FC = () => {
  const { userData } = useAuth();
  const { createToast } = useToast();
  const { isUpdating, update } = useUpdateProfileImage();

  const imgRef = useRef<null | HTMLImageElement>(null);
  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setNewImageFile(null);
      }
    },
  });
  const editorModalState = useModalState();

  const currentProfile = userData?.picture;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const [file] = e.target.files;

      if (file) {
        if ((file.size || 0) > MAX_FILE_SIZE_IN_BYTES) {
          createToast({
            variant: "error",
            title: "Please choose files smaller than 5mb",
            timeoutInMilliseconds: 1000 * 5,
          });

          return;
        }

        if (imgRef.current) {
          imgRef.current.src = URL.createObjectURL(file);
        }
        setNewImageFile(file);
      }
    },
    [createToast]
  );

  const handleRemove = useCallback(() => {
    setNewImageFile(null);
    if (imgRef.current) {
      imgRef.current.src = currentProfile;
    }
  }, [currentProfile]);

  const handleApply = useCallback(() => {
    if (newImageFile) {
      update(newImageFile).then(() => {
        modalState.close();
      });
    }
  }, [newImageFile, update, modalState]);

  //-----------------------------

  return (
    <>
      <StyledContainer>
        <StyledContentContainer>
          <StyledAvatar
            img={userData?.picture || userData?.owner_profile || ""}
            firstName={userData?.given_name}
            lastName={userData?.family_name}
          />

          <StyledButton variant="ghost" onClick={modalState.open}>
            <Icon isSrcRelative colorVariant="black" src="edit.svg" size="xs" />
          </StyledButton>
        </StyledContentContainer>
      </StyledContainer>

      <Modal
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        state={modalState}
        width="480px"
      >
        <StyledModalContent>
          <StyledImage ref={imgRef} src={currentProfile} />

          {!!newImageFile && (
            <StyledEditButton
              variant="ghost"
              className={ANIMATION_CLASSNAMES.FADE_IN_FROM_BOTTOM}
              onClick={editorModalState.open}
            >
              <Icon isSrcRelative src="edit.svg" size="xs" />
            </StyledEditButton>
          )}

          {!!newImageFile && (
            <ReactPhotoEditor
              open={editorModalState.isOpen}
              file={newImageFile}
              onClose={editorModalState.close}
              onSaveImage={(file) => {
                if (file) {
                  if (imgRef.current) {
                    imgRef.current.src = URL.createObjectURL(file);
                  }
                  setNewImageFile(file);
                }
              }}
              allowColorEditing={false}
            />
          )}

          <small>Max size: 5 MB</small>

          <div>
            {!!newImageFile ? (
              <StyledButtonContainer className={ANIMATION_CLASSNAMES.FADE_IN}>
                <Button
                  variant="secondary"
                  colorVariant="gray"
                  onClick={handleRemove}
                  disabled={isUpdating}
                >
                  Remove
                </Button>
                <Button disabled={isUpdating} onClick={handleApply}>
                  Apply
                </Button>
              </StyledButtonContainer>
            ) : (
              <StyledButtonContainer className={ANIMATION_CLASSNAMES.FADE_IN}>
                <Button
                  variant="secondary"
                  colorVariant="gray"
                  onClick={modalState.close}
                  disabled={isUpdating}
                >
                  Cancel
                </Button>

                <StyledLabel>
                  <Button className="w-100" disabled={isUpdating}>
                    Upload
                    <StyledInput
                      type="file"
                      onChange={handleChange}
                      accept="image/*"
                      disabled={isUpdating}
                    />
                  </Button>
                </StyledLabel>
              </StyledButtonContainer>
            )}
          </div>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default ProfileImageInput;
