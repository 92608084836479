"use client";

import styled from "@emotion/styled";

import Avatar from "@/components/misc/Avatar";

export const StyledPage = styled.div`
  padding: 1rem;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 120px;
  border: 3px solid var(--clr-border-ukwn-12, #f3f3f3);
`;

export const StyledAvatarContainer = styled.div`
  position: relative;
`;

export const StyledAvatarLabel = styled.div`
  position: absolute;
  bottom: -0.25rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.625rem;
  color: var(--clr-text-900, #0d0d12);
  font-weight: 600;
  background: var(--clr-background-ukwn-10, #e5f7ad);
  min-width: max-content;
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  z-index: 2;
`;

export const StyledName = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--clr-text-900, #0d0d12);
  margin-top: 1.5rem;
`;

export const StyledEmail = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--clr-icon-text-200, #818898);
  margin-top: 0.25rem;
`;

export const StyledUserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
`;
