import React from "react";
import styled from "@emotion/styled";

import { Body, Display, Heading } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import { screenLargerThan } from "@/styles";

const StyledSection = styled.section`
  background: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;

  .content {
    color: #666d80;
  }
`;

const StyledItem = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: #000;

  ${screenLargerThan.largeMobile} {
    max-width: 16rem;
  }

  .title {
    flex-grow: 1;
    font-size: 0.875rem;
    line-height: 1.5em;
    font-weight: 600;
  }

  .badge {
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1.25em;
    font-weight: 500;
    border-radius: 0.25rem;
    background: #eaeaea;
    padding: 0.5rem;
  }
`;

const Component: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div className={className}>
      <Display size="md" style={{ paddingRight: "2.5rem" }}>
        Here's what to keep in mind while signing up from <br />
        pakistan!
      </Display>

      <StyledSection className="mt-4">
        <img alt="Stripe logo" src="/assets/images/icons/stripe.svg" />
        <Heading size="lg" className="mt-2">
          No need for an existing stripe account
        </Heading>
        <Body size="lg" className="mt-1 content">
          You simply enter your email address and fill out the fields accurately
          to complete the process and start getting paid.
        </Body>
      </StyledSection>

      <StyledSection className="mt-3">
        <Icon isSrcRelative src="bank.svg" />
        <Heading size="lg" className="mt-2">
          Best practice for swift code!
        </Heading>
        <Body size="lg" className="mt-1 content">
          Please ensure that you enter the main bank BIC code, not a
          branch-specific code. See below:
        </Body>

        <StyledItem className="mt-3">
          <Icon
            isSrcRelative
            src="tick_rounded_filled.svg"
            customSize="1.25rem"
          />
          <span className="title">MEZNPKKA</span>
          <span className="badge">Main BIC</span>
        </StyledItem>

        <StyledItem className="mt-3">
          <Icon
            isSrcRelative
            src="cross_rounded_filled.svg"
            customSize="1.25rem"
          />
          <span className="title">MEZNPKKAGRD</span>
          <span className="badge">Branch BIC</span>
        </StyledItem>
      </StyledSection>
    </div>
  );
};

export default Component;
