import React from "react";

import Button from "@/components/input/Button";
import { useHash } from "@/services/Routing";

const Survey: React.FC = () => {
  const { toggleHash } = useHash();

  return (
    <div>
      <Button
        onClick={() => {
          toggleHash("#show");
        }}
      >
        Show
      </Button>
    </div>
  );
};

export default Survey;
